import { all, call, fork, put, take } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  deleteTransactionApi,
  fetchGlAccountsApi,
  requestNOUpdate,
} from "../../apiService";
import { parseError } from "../../utils";

import {
  TRANSACTION,
  FAIL,
  START,
  SUCCESS,
  FETCH_ITEMS,
  DELETE_ITEM,
  GL_ACCOUNTS,
} from "../common";

function* fetchGlAccounts() {
  while (true) {
    yield take(GL_ACCOUNTS + FETCH_ITEMS + START);
    try {
      const data: { data: any } = yield call(fetchGlAccountsApi);
      yield put({ type: GL_ACCOUNTS + FETCH_ITEMS + SUCCESS, payload: data });
    } catch (error) {
      yield put({
        type: GL_ACCOUNTS + FETCH_ITEMS + FAIL,
        payload: parseError(error),
      });
    }
  }
}

function* deleteTransaction() {
  while (true) {
    const { payload } = yield take(TRANSACTION + DELETE_ITEM + START);
    try {
      const data: { data: any } = yield call(deleteTransactionApi, payload);
      if (data.data?.success) {
        yield put({ type: TRANSACTION + DELETE_ITEM + SUCCESS, payload });
        toast.success("Expense has been deleted successfully");
        yield call(requestNOUpdate);
      } else {
        yield put({
          type: TRANSACTION + DELETE_ITEM + FAIL,
          payload: parseError(data.data),
        });
      }
    } catch (error) {
      yield put({
        type: TRANSACTION + DELETE_ITEM + FAIL,
        payload: parseError(error),
      });
    }
  }
}

export function* transactionSagas() {
  yield all([
    fork(deleteTransaction),
    fork(fetchGlAccounts),
  ]);
}
