import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { userGetInventoryTypeSelector } from "src/store/system/selector";
import { updateUserApi } from "src/apiService/modules/system";

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  select: {
    margin: theme.spacing(0, 2),
  },
}));

function InventoryAccountingMethodSelect() {
  const classes = useStyles();
  const inventoryType = useSelector(userGetInventoryTypeSelector);

  return (
    <Box display="flex" alignItems="center">
      <Typography className={classes.label}>
        Inventory Accounting Method:
      </Typography>
      <Select
        className={classes.select}
        value={inventoryType}
        onChange={(event) => {
          const inventoryType = event.target.value;
          updateUserApi({ inventoryType });
        }}
        fullWidth
      >
        <MenuItem value="accrual">Accrual Inventory Method</MenuItem>
        <MenuItem value="cash">Cash Inventory Method</MenuItem>
      </Select>
    </Box>
  );
}

export default InventoryAccountingMethodSelect;
