import type firebase from "firebase";
import { type GridFilterModel } from "@material-ui/x-grid";

// vim-highlight-fix = }

export const DefaultFilterModel = { items: [] };

function normalizeString(str: string | null | undefined) {
  if (!str) str = "";
  if (str.trim) str = str.trim();
  if (str.toLowerCase) str = str.toLowerCase();
  return str;
}

const StringOperators = {
  contains: (item: string = "", value: string = ""): boolean => {
    item = normalizeString(item);
    if (!item.includes) return false;
    return item.includes(normalizeString(value));
  },
  equals: (item: string = "", value: string = ""): boolean => {
    return normalizeString(item) === normalizeString(value);
  },
  startsWith: (item: string = "", value: string = ""): boolean => {
    item = normalizeString(item);
    if (!item.startsWith) return false;
    return item.startsWith(normalizeString(value));
  },
  endsWith: (item: string = "", value: string = ""): boolean => {
    item = normalizeString(item);
    if (!item.endsWith) return false;
    return item.endsWith(normalizeString(value));
  },
  isEmpty: (item: string = ""): boolean => {
    if (item.trim) item = item.trim();
    return !item;
  },
  isNotEmpty: (item: string = ""): boolean => {
    if (item.trim) item = item.trim();
    return !!item;
  },
};

const ArrayStringOperators = {
  contains: (item: string[] = [], value: string = ""): boolean => {
    if (!item || !item?.some) return false;
    const v = normalizeString(value);
    return item.some((i) => {
      i = normalizeString(i);
      if (!i.includes) return false;
      return i.includes(v);
    });
  },
  equals: (item: string[] = [], value: string = ""): boolean => {
    if (!item) return false;
    return (
      item.length === 1 && normalizeString(item[0]) === normalizeString(value)
    );
  },
  startsWith: (item: string[] = [], value: string = ""): boolean => {
    if (!item || !item?.some) return false;
    const v = normalizeString(value);
    return item.some((i) => {
      i = normalizeString(i);
      if (!i.startsWith) return false;
      return i.startsWith(v);
    });
  },
  endsWith: (item: string[] = [], value: string = ""): boolean => {
    if (!item || !item.some) return false;
    const v = normalizeString(value);
    return item.some((i) => {
      i = normalizeString(i);
      if (!i.endsWith) return false;
      return i.endsWith(v);
    });
  },
  isEmpty: (item: any[] = []): boolean => {
    if (!item || item.length === 0) return true;
    if (item.length > 1) return false;
    if (item[0]) {
      if (item[0].trim) return !item[0].trim();
      return false;
    }
    return false;
  },
  isNotEmpty: (item: string[] = []): boolean => {
    if (!item) return false;
    if (!item.length) return false;
    let s = item[0];
    if (s.trim) s = s.trim();
    return !!s;
  },
};

const NumberOperators = {
  "=": (item: number, value: number | undefined): boolean => {
    return value === undefined || +item === +value;
  },
  "!=": (item: number, value: number | undefined): boolean => {
    return value === undefined || +item !== +value;
  },
  ">": (item: number, value: number | undefined): boolean => {
    return value === undefined || +item > +value;
  },
  ">=": (item: number, value: number | undefined): boolean => {
    return value === undefined || +item >= +value;
  },
  "<": (item: number, value: number | undefined): boolean => {
    return value === undefined || +item < +value;
  },
  "<=": (item: number, value: number | undefined): boolean => {
    return value === undefined || +item <= +value;
  },
  isEmpty: (item: number): boolean => {
    return !item;
  },
  isNotEmpty: (item: number): boolean => {
    return !!item;
  },
};

const BooleanOperators = {
  is: (item: boolean, value: "false" | "true" | undefined): boolean => {
    const v = value === "true";
    return value === undefined || !!item === v;
  },
};

function getDateString(
  date: Date | string | firebase.firestore.Timestamp
): string {
  if (!date) return "";
  if (typeof date === "string") return date;
  if ((date as firebase.firestore.Timestamp).toDate)
    date = (date as firebase.firestore.Timestamp).toDate();

  const d = date as Date;

  if (!d.getFullYear || !d.getMonth || !d.getDate) return "Invalid Date";

  return `${d.getFullYear()}-${`${d.getMonth() + 1}`.padStart(
    2,
    "0"
  )}-${`${d.getDate()}`.padStart(2, "0")}`;
}

const DateOperators = {
  is: (item: Date, value: string | undefined): boolean => {
    return !value || getDateString(item) === value;
  },
  not: (item: Date, value: string | undefined): boolean => {
    return !value || getDateString(item) !== value;
  },
  after: (item: Date, value: string | undefined): boolean => {
    return !value || getDateString(item) > value;
  },
  onOrAfter: (item: Date, value: string | undefined): boolean => {
    return !value || getDateString(item) >= value;
  },
  before: (item: Date, value: string | undefined): boolean => {
    return !value || getDateString(item) < value;
  },
  onOrBefore: (item: Date, value: string | undefined): boolean => {
    return !value || getDateString(item) <= value;
  },
  isEmpty: (item: Date): boolean => {
    return !item;
  },
  isNotEmpty: (item: Date): boolean => {
    return !!item;
  },
};

const Operators = {
  string: StringOperators,
  array_string: ArrayStringOperators,
  number: NumberOperators,
  boolean: BooleanOperators,
  date: DateOperators,
};

const LinkOperators = {
  or: (a: boolean, b: boolean): boolean => {
    return a || b;
  },
  and: (a: boolean, b: boolean): boolean => {
    return a && b;
  },
};

interface FilterDefinition<T> {
  operator: keyof typeof Operators;
  column: keyof T;
}

export function filter<T>(
  definitions: Partial<
    Record<keyof T, keyof typeof Operators | FilterDefinition<T>>
  >,
  rows: T[],
  model: GridFilterModel
): T[] {
  const link = model.linkOperator || "and";
  if (!model || !model.items || model.items.length === 0) return rows;
  return rows.filter((row) => {
    let cond = link === "and" ? true : false;
    for (const item of model.items) {
      if (!item.columnField) return true;
      const t = definitions[item.columnField];
      if (!t) {
        console.warn("No definition for columnField", definitions, item);
        return true;
      }
      const operators = Operators[t.operator || t];
      if (!operators || !item.operatorValue) {
        console.warn("No operator for type definition", definitions, item);
        return true;
      }
      const cmp = operators[item.operatorValue];
      if (!cmp) {
        console.warn("No cmp function for type definition", definitions, item);
        return true;
      }
      cond = LinkOperators[link](
        cond,
        cmp(row[t.column || item.columnField], item.value)
      );
      switch (link) {
        case "and":
          if (!cond) return false;
          break;

        case "or":
          if (cond) return true;
          break;
      }
    }

    return cond;
  });
}
