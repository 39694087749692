import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import startCase from "lodash/startCase";

import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import Autocomplete, { AutocompleteProps } from "@material-ui/lab/Autocomplete";

import { inventoriesSelector } from "src/store/inventory/selector";
import { salesSelector } from "src/store/sale/selector";

export interface BrandFieldProps<T>
  extends Omit<
    AutocompleteProps<T, false, false, true>,
    "options" | "renderInput" | "freeSolo" | "autoSelect"
  > {
  TextFieldProps?: Partial<TextFieldProps>;
}

function BrandField({ TextFieldProps, ...props }: BrandFieldProps<string>) {
  const { items: salesItems } = useSelector(salesSelector);
  const { items: inventoryItems } = useSelector(inventoriesSelector);
  const options = useMemo(() => {
    return [
      ...new Set(
        [...salesItems, ...inventoryItems]
          .map((item) => item.brand)
          .filter(Boolean)
          .map((item) => `${item}`.toLowerCase())
      ),
    ].map(startCase);
  }, [salesItems, inventoryItems]);

  return (
    <Autocomplete
      options={options}
      renderInput={(params) => <TextField {...params} {...TextFieldProps} />}
      freeSolo
      autoSelect
      {...props}
    />
  );
}

export default BrandField;
