import { all, call, delay, fork, put, select, take } from "redux-saga/effects";
import { maxBy, sortBy } from "lodash";
import { toast } from "react-toastify";

import {
  fetchBankStatementsApi,
  deleteBankStatementApi,
  updateBulkBankStatementsApi,
  updateBankStatementApi,
  addBankStatementApi,
  requestNOUpdate,
} from "../../apiService";
import { BankStatement } from "../../interfaces";
import { parseError } from "../../utils";

import {
  BANK_STATEMENT,
  FAIL,
  START,
  SUCCESS,
  FETCH_ITEMS,
  UPLOAD_ITEMS,
  DELETE_ITEMS,
  RESET,
  UPDATE_ITEM,
  DELETE_ITEM,
  CREATE_ITEM,
} from "../common";
import { getItems as getBankStatements } from "./selector";
import { getItems as getCashActivities } from "../cashActivity/selector";

export function* reconcileBankStatements() {
  yield delay(2000);
  const cashActivities = yield select(getCashActivities);
  const bankStatements = yield select(getBankStatements);
  const sortedStatements = sortBy(bankStatements, 'bank_statement_date');
  let balance = 0;
  const updatePayload: BankStatement[] = [];
  for (let i = 0; i < sortedStatements.length; i++) {
    const statement = sortedStatements[i];
    const statementCAs = cashActivities.filter((ca) => ca.bank_statement_id === statement.id);
    const unreconciledAmount = statement.bank_statement_name === "Unreconciled" ? balance : 0;
    statementCAs.forEach((ca) => {
      balance += (ca.business_activity || 0) + (ca.other_activity || 0);
    });
    const newUpdate = { ...statement, reconciliation_amount: balance - unreconciledAmount, reconciled: false };
    if (Math.round(balance * 100) / 100 === Math.round(statement.bank_statement_total * 100) / 100) {
      newUpdate.reconciled = true;
    }
    updatePayload.push(newUpdate);
  }
  yield updateBulkBankStatementsApi(updatePayload);
}

function* updateItem() {
  while (true) {
    const { payload } = yield take(BANK_STATEMENT + UPDATE_ITEM + START);
    try {
      const data: { data: any } = yield call(updateBankStatementApi, payload);
      if (data.data?.success) {
        yield put({ type: BANK_STATEMENT + UPDATE_ITEM + SUCCESS, payload });
        toast.success("Bank statement has been updated successfully");
        yield reconcileBankStatements();
        yield call(requestNOUpdate);
      } else {
        yield put({
          type: BANK_STATEMENT + UPDATE_ITEM + FAIL,
          payload: parseError(data.data),
        });
      }
    } catch (error) {
      toast.error(parseError(error));
      yield put({
        type: BANK_STATEMENT + UPDATE_ITEM + FAIL,
        payload: parseError(error),
      });
    }
  }
}

function* createItem() {
  while (true) {
    const { payload } = yield take(BANK_STATEMENT + CREATE_ITEM + START);
    try {
      const data: { data: any } = yield call(addBankStatementApi, payload);
      if (data.data?.success) {
        yield put({ type: BANK_STATEMENT + CREATE_ITEM + SUCCESS, data });
        toast.success("Bank statement has been created successfully");
        yield reconcileBankStatements();
        yield call(requestNOUpdate);
      } else {
        yield put({
          type: BANK_STATEMENT + CREATE_ITEM + FAIL,
          payload: parseError(data.data),
        });
      }
    } catch (error) {
      toast.error(parseError(error));
      yield put({
        type: BANK_STATEMENT + CREATE_ITEM + FAIL,
        payload: parseError(error),
      });
    }
  }
}

function* deleteItem() {
  while (true) {
    const { payload } = yield take(BANK_STATEMENT + DELETE_ITEM + START);
    try {
      const data: { data: any } = yield call(deleteBankStatementApi, payload);
      if (data.data?.success) {
        const bankStatements = yield select(getBankStatements);
        const deletedItem = maxBy(bankStatements.filter((item) => item.name !== "Undefined"), "bank_statement_date");
        yield put({ type: BANK_STATEMENT + DELETE_ITEM + SUCCESS, payload });
        toast.success("Bank statement has been deleted successfully");
        yield reconcileBankStatements();
        yield call(requestNOUpdate);
      } else {
        yield put({
          type: BANK_STATEMENT + DELETE_ITEM + FAIL,
          payload: parseError(data.data),
        });
      }
    } catch (error) {
      toast.error(parseError(error));
      yield put({
        type: BANK_STATEMENT + DELETE_ITEM + FAIL,
        payload: parseError(error),
      });
    }
  }
}

export function* bankStatementSagas() {
  yield all([
    fork(updateItem),
    fork(deleteItem),
    fork(createItem),
  ]);
}
