import type { ComponentType } from "react";

import LockOpenIcon from "@material-ui/icons/LockOpen";
import PersonIcon from "@material-ui/icons/Person";
const base = "/support";

export interface NavigatorEntry {
  id: string;
  title: string;
  Icon: ComponentType;
  href: string;
  forPremium?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  order: number;
}

export const navigation: Array<NavigatorEntry> = [
  {
    id: "user",
    title: "User",
    Icon: PersonIcon,
    href: `${base}/user/`,
    order: 2,
  },
  {
    id: "token",
    title: "Tokens",
    Icon: LockOpenIcon,
    href: `${base}`,
    order: 1,
  },
];
