import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "#launcher": {
      zIndex: `${theme.zIndex.modal - 1} !important`,
    },
  },
}));

export const ZendeskKey =
  process.env.REACT_APP_ZENDESK_KEY || "8beb9288-847e-43e8-935a-cf150528e1f8";

const ZendeskScriptID = "ze-snippet";

// https://developer.zendesk.com/api-reference/widget/core/
const ZendeskClassicName = "webWidget";
// https://developer.zendesk.com/api-reference/widget-messaging/web/core/
const ZendeskMessagingName = "messenger";

function callZendesk(...args) {
  try {
    const zE = (window as any).zE;
    if (zE) {
      zE(() => {
        const zE = (window as any).zE;
        const method =
          zE.widget === "classic" ? ZendeskClassicName : ZendeskMessagingName;
        zE(method, ...args);
      });
    } else {
      console.error("Zendesk: undefined `widnow.zE`");
    }
  } catch (e) {
    console.error("Zendesk", e);
  }
}

interface ZendeskProps {
  isLoggedIn: boolean;
}

function Zendesk({ isLoggedIn }: ZendeskProps) {
  useStyles();
  const ref = useRef(isLoggedIn);
  useEffect(() => {
    ref.current = isLoggedIn;
    if ((window as any).zE) callZendesk(isLoggedIn ? "show" : "hide");
  }, [isLoggedIn]);

  useEffect(() => {
    if (typeof window === "undefined" || typeof document === "undefined")
      return;

    const handleLoad = () => {
      callZendesk(ref.current ? "show" : "hide");
    };

    const existingScript = document.getElementById(ZendeskScriptID);
    if (existingScript) {
      if ((window as any).zE) callZendesk(ref.current ? "show" : "hide");
      else existingScript.addEventListener("load", handleLoad);

      return () => {
        existingScript.removeEventListener("load", handleLoad);
        callZendesk("hide");
      };
    }

    const script = document.createElement("script");
    script.defer = true;
    script.id = ZendeskScriptID;
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZendeskKey}`;
    script.addEventListener("load", handleLoad);
    document.body.appendChild(script);

    return () => {
      script.removeEventListener("load", handleLoad);
      callZendesk("hide");
      // delete (window as any).zE;
      // delete (window as any).zESettings;
      // document.body.removeChild(script);
      // const iframe = document.getElementById('launcher');
      // if (iframe?.parentNode)
      //   iframe.parentNode.removeChild(iframe);
    };
  }, []);
  return null;
}

export class SafeZendesk extends React.Component<ZendeskProps> {
  state = {
    error: false,
  };

  static getDerivedStateFromError(error) {
    return { error: true };
  }

  componentDidCatch(error, info) {
    console.error("SafeZendesk", error, info.componentStack);
  }

  render() {
    if (this.state.error) return null;
    return <Zendesk {...this.props} />;
  }
}

export default Zendesk;
