import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import XGrid from "src/components/CSVFormattedXGrid";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from "@material-ui/x-grid";

import Typography from "@material-ui/core/Typography";
import GetAppIcon from "@material-ui/icons/GetApp";

import { formatCurrency } from "src/utils";
import { StatefulLoadableIconButton } from "src/components/LoadableIconButton";
import { PlatformTransactionReport as PlatformTransactionReportType } from "./report";
import renderReport from "./renderReport";

export interface PlatformTransactionReportProps {
  report: PlatformTransactionReportType;
}

const useValueBoxStyles = makeStyles((theme) => ({
  box: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    minWidth: "15%",
  },
  children: {
    textAlign: "right",
  },
}));

function ValueBox({ title, children }) {
  const classes = useValueBoxStyles();
  return (
    <Paper className={classes.box} variant="outlined">
      <Typography
        variant="button"
        component="p"
        display="block"
        className="text-blue"
      >
        {title}
      </Typography>
      <Typography variant="body2" className={classes.children}>
        {new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(children)}
      </Typography>
    </Paper>
  );
}

const Columns = [
  {
    field: "date",
    headerName: "Date",
    type: "date",
    width: 100,
  },
  {
    field: "transactionId",
    headerName: "Platform ID",
    width: 200,
  },
  {
    field: "title",
    headerName: "Title/Description",
    flex: 1,
  },
  {
    field: "revenue",
    headerName: "Revenue",
    valueFormatter: formatCurrency,
    type: "number",
    width: 115,
  },
  {
    field: "expenses",
    headerName: "Expenses",
    valueFormatter: formatCurrency,
    type: "number",
    width: 120,
  },
];

function Toolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarColumnsButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      borderRight: `1px solid #f0f0f0`,
      padding: "0px 10px",
      fontSize: "12px",
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      borderBottom: `1px solid #f0f0f0`,
    },
    "& .MuiDataGrid-cell": {
      color: `rgba(0,0,0,.85)`,
    },
    "& .MuiDataGrid-iconSeparator": {
      opacity: 0,
    },
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#fafafa",
      borderTop: "1px solid #f0f0f0",
      fontSize: "12px",
    },
    "& .MuiDataGrid-colCell": {
      borderRight: `1px solid #f0f0f0`,
      padding: "0px 10px",
    },
  },
});

function PlatformTransactionReport({ report }: PlatformTransactionReportProps) {
  const classes = useStyles();
  const bodyRef = useRef<HTMLDivElement>(null);
  const handleDownloadReport = async () => {
    if (!bodyRef?.current) return;
    const div = document.createElement("div");
    div.innerHTML = renderReport(
      report.data.reportDetails.titleFull,
      bodyRef.current.outerHTML,
      false,
      "margin: 0 24px"
    );

    for (const ele of div.querySelectorAll("button, a")) {
      if (ele.parentNode) ele.parentNode.removeChild(ele);
    }

    const html2pdf = await import("html2pdf.js");
    await html2pdf
      .default()
      .set({
        filename: report.data.reportDetails.filename,
        jsPDF: {
          orientation: "landscape",
        },
        margin: [2, 0, 2, 0],
      })
      .from(div)
      .save();
  };

  return (
    <Paper className={`mt-5 p-5 h-auto ${classes.root}`}>
      <Box display="flex" alignItems="center">
        <h2 className={`w-full text-left text-blue z-10 flex-1`}>
          {report.data.reportDetails.titleFull}
        </h2>
        <Box display="flex">
          <StatefulLoadableIconButton onClick={handleDownloadReport}>
            <GetAppIcon />
          </StatefulLoadableIconButton>
        </Box>
      </Box>
      <div ref={bodyRef}>
        <Box display="flex" justifyContent="center">
          <ValueBox title="Sales">{report.data.totals.sales}</ValueBox>
          <ValueBox title="Refunds">{report.data.totals.returns}</ValueBox>
          <ValueBox title="Expenses">{report.data.totals.expenses}</ValueBox>
        </Box>
        <Box mt={2}>
          <h2 className="text-blue">Transaction Detail</h2>
          <Box minHeight="75vh">
            <XGrid
              components={{
                Toolbar,
              }}
              density="compact"
              rows={report.data.rows}
              columns={Columns}
              disableSelectionOnClick
              autoHeight
              pageSize={report.data.rows.length}
              hideFooter
              hideFooterPagination
              disableColumnMenu
            />
          </Box>
        </Box>
      </div>
    </Paper>
  );
}

export default PlatformTransactionReport;
