import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import Tour from "reactour";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { userGetInventoryTypeSelector } from "src/store/system/selector";

function getSteps(inventoryType: "cash" | "accrual") {
  const hasInventory = inventoryType !== "cash";
  const total = hasInventory ? 3 : 2;
  const steps = [
    {
      content: (props) => (
        <>
          <DialogTitle>Edit Sale - 1/{total}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You can edit sales by changing any field and clicking “Update”.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.goTo(1)}
            >
              Next
            </Button>
          </DialogActions>
        </>
      ),
    },
  ] as any[];

  if (hasInventory) {
    steps.push({
      selector: "#edit-sale-basic-move-to-inventory-button",
      content: (props) => (
        <>
          <DialogTitle>Edit Sale - 2/3</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To delete a sale and move an item back to inventory, click the
              “Move to Inventory” button
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.goTo(0)}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.goTo(2)}
            >
              Next
            </Button>
          </DialogActions>
        </>
      ),
    });
  }

  steps.push(
    ((step: number) => ({
      selector: "#edit-sale-basic-record-refund-button",
      content: (props) => (
        <>
          <DialogTitle>
            Edit Sale - {step}/{total}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              If you issued a full or partial refund on a sale, click the
              “Record Refund” button.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.goTo(step - 2)}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.close()}
            >
              Done
            </Button>
          </DialogActions>
        </>
      ),
    }))(steps.length + 1)
  );
  return steps;
}

const useStyles = makeStyles((theme) => ({
  helper: {
    maxWidth: "80%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "500px",
    },
    padding: 0,
  },
}));

export interface WalkThroughProps {
  open: boolean;
  onClose: () => void;
}

export function WalkThrough({ open, onClose }: WalkThroughProps) {
  const classes = useStyles();
  const inventoryType = useSelector(userGetInventoryTypeSelector);
  const steps = useMemo(() => getSteps(inventoryType), [inventoryType]);
  return (
    <Tour
      startAt={0}
      closeWithMask={false}
      disableFocusLock
      steps={steps}
      isOpen={open}
      onRequestClose={() => {
        onClose();
      }}
      showButtons={false}
      showCloseButton={false}
      showNavigation={false}
      showNavigationNumber={false}
      showNumber={false}
      disableDotsNavigation
      rounded={5}
      className={classes.helper}
      disableInteraction
    />
  );
}

export default WalkThrough;
