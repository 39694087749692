import React, { useState } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import { StatefulLoadableButton } from "src/components/LoadableButton";

import { MissingExtensionError } from "src/utils/extension";
import { getPoshmarkUser } from "src/utils/extension/poshmark";

import DialogTitle from "./DialogTitle";
import type Step from "./Step";

interface PoshmarkDoYouSellOnPoshmarkProps {
  onClose: () => void;
  onStepChange: (step: Step) => void;
}

type Options = "yes" | "no";

const useStyles = makeStyles((theme) => ({
  content: {
    margin: "auto",
  },
  radioGroup: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(4),
  },
}));

function PoshmarkDoYouSellOnPoshmark({
  onClose,
  onStepChange,
}: PoshmarkDoYouSellOnPoshmarkProps) {
  const classes = useStyles();
  const [value, setValue] = useState<Options>();

  return (
    <>
      <DialogTitle onClose={onClose}>Set Up Questions - 3/8</DialogTitle>
      <MuiDialogContent className={classes.content}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Do you sell on Poshmark?</FormLabel>
          <RadioGroup
            value={value || null}
            onChange={(event) => {
              setValue((event.target as HTMLInputElement).value as Options);
            }}
            className={classes.radioGroup}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </MuiDialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onStepChange("ebay:additional-stores");
          }}
          color="primary"
          autoFocus
        >
          Go Back
        </Button>
        <StatefulLoadableButton
          variant="contained"
          disabled={!value}
          onClick={async () => {
            if (value === "yes") {
              try {
                await getPoshmarkUser();
                onStepChange("poshmark:confirm");
              } catch (e) {
                if (e instanceof MissingExtensionError) {
                  onStepChange("poshmark:add-extension");
                  return;
                }

                toast.error((e as Error).message);
              }
              return;
            }
            onStepChange("mercari:do-you-sell-on-mercari");
          }}
          color="primary"
          autoFocus
        >
          Next
        </StatefulLoadableButton>
      </DialogActions>
    </>
  );
}

export default PoshmarkDoYouSellOnPoshmark;
