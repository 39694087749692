import React, { useRef, useEffect } from "react";
import queryString from "query-string";
import { toast } from "react-toastify";
import { useLocation, useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { exchangeEBayOAuthCode } from "src/apiService/modules/ebay";

function EBayOAuthCallbackPage() {
  const location = useLocation();
  const search = location.search;
  const history = useHistory();
  const historyRef = useRef(history);
  historyRef.current = history;

  useEffect(() => {
    const query: { code?: string; state?: string } = queryString.parse(search);
    if (!query?.code) return;
    const code = query.code;
    let cancel = false;
    (async () => {
      let url = "/";
      try {
        const extra: { integrationId?: string; integrationName?: string } = {};
        if (query.state) {
          try {
            const json = JSON.parse(query.state);
            if (json.id) extra.integrationId = json.id;
            if (json.name) extra.integrationName = json.name;
            if (json.back && json.back.startsWith("/")) url = json.back;
          } catch (e) {
            console.error(e);
          }
        }
        await exchangeEBayOAuthCode({ ...extra, code });
        if (cancel) return;
        toast.success("eBay account connected!");
      } catch (e) {
        console.error(e);
        if (cancel) return;
        toast.error((e as Error).toString());
      }
      historyRef.current.push(url);
    })();

    return () => {
      cancel = true;
    };
  }, [search]);

  return (
    <Container component="main" maxWidth="xs">
      <Box className="flex justify-center items-center h-screen">
        <CircularProgress size={50} />
      </Box>
    </Container>
  );
}

export default EBayOAuthCallbackPage;
