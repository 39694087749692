import React from "react";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
// import Downshift from "downshift";
import Chip from "@material-ui/core/Chip";
import CancelIcon from "@material-ui/icons/Cancel";
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  chipContainer: {
    backgroundColor: "transparent",
    display: "inline-block",
    marginBottom: 10,
    width: "100%",
    textAlign: "left",
  },
  chip: {
    marginTop: 10,
    marginRight: 5
  },
  paper: {
    maxHeight: "150px",
    overflowY: "auto"
  }
}));

interface IMultiChipSelectItem {
  name: string
  id: string
}

interface IMultiChipSelectRenderInputProps {
  classes: any
  selectedItem?: Array<string | number | undefined>
  availableItems: Array<IMultiChipSelectItem>
  InputProps: any
}

interface IMultiChipSelectRenderChipListProps {
  classes: any
  onRemoveItem: Function
  selectedItem: Array<string>
}

interface IMultiChipSelectRenderSuggestionProps {
  item: IMultiChipSelectItem
  index: number | null
  highlightedIndex: number | null
  selectedItem: Array<string>
  itemProps: any
}

const renderInput = (inputProps: IMultiChipSelectRenderInputProps) => {
  const { InputProps, classes, availableItems } = inputProps;

  const allItemSelected = availableItems.length === 0;

  return (
    <TextField
      fullWidth
      label={
        allItemSelected ? "No more item to add" : "Choose an item"
      }
      disabled={allItemSelected}
      variant="outlined"
      margin="dense"
      InputProps={{
        classes: {
          input: classes.input
        },
        ...InputProps
      }}
    />
  );
};

const renderChipList = (inputProps: IMultiChipSelectRenderChipListProps) => {
  const { classes, selectedItem, onRemoveItem } = inputProps;
  return (
    <div className={classes.chipContainer}>
      {selectedItem.length > 0 &&
        selectedItem.map((item: string | number | undefined) => (
          <Chip
            key={item}
            className={classes.chip}
            label={item}
            deleteIcon={<CancelIcon />}
            onDelete={() => onRemoveItem(item)}
            onClick={() => onRemoveItem(item)}
          />
        ))}
    </div>
  );
};

const renderSuggestion = (params: IMultiChipSelectRenderSuggestionProps) => {
  const { item, index, itemProps, highlightedIndex, selectedItem } = params;
  const isHighlighted = highlightedIndex === index;
  const isSelected = selectedItem.indexOf(item.name) > -1;

  return (
    !isSelected && (
      <MenuItem
        {...itemProps}
        key={item.id}
        selected={isHighlighted}
        component="div"
      >
        {item.name}
      </MenuItem>
    )
  );
};

const getSuggestions = (inputValue: string, itemList: IMultiChipSelectItem[]) =>
  itemList.filter(item =>
    item.name.toLowerCase().includes(inputValue?.toLowerCase())
  );

export function MultiChipSelect(props: any) {
  const classes = useStyles();
  const { availableItems, onRemoveItem, ...rest } = props;

  return <div>Disabled to remove downshift dependency.</div>
  // return (
  //   <Downshift {...rest}>
  //     {({
  //       getInputProps,
  //       getItemProps,
  //       inputValue,
  //       selectedItem,
  //       highlightedIndex,
  //       toggleMenu,
  //       isOpen
  //     }) => (
  //       <div>
  //         {renderChipList({
  //           classes,
  //           onRemoveItem,
  //           selectedItem
  //         })}

  //         {renderInput({
  //           classes,
  //           selectedItem,
  //           availableItems,
  //           InputProps: {
  //             ...getInputProps({
  //               onClick: () => toggleMenu()
  //             })
  //           }
  //         })}

  //         {isOpen && (
  //           <Paper className={classes.paper} square>
  //             {getSuggestions(inputValue ? inputValue : "", availableItems).map((item, index) =>
  //               renderSuggestion({
  //                 item,
  //                 index,
  //                 itemProps: getItemProps({
  //                   item: item.name
  //                 }),
  //                 highlightedIndex,
  //                 selectedItem
  //               })
  //             )}
  //           </Paper>
  //         )}
  //       </div>
  //     )}
  //   </Downshift>
  // );
}

