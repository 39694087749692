import React from "react";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

import DialogTitle from "./DialogTitle";
import type Step from "./Step";

interface BulkEditInstructionsProps {
  onClose: () => void;
  onStepChange: (step: Step) => void;
}

function BulkEditInstructions({
  onClose,
  onStepChange,
}: BulkEditInstructionsProps) {
  return (
    <>
      <DialogTitle onClose={onClose}>Important Tip</DialogTitle>
      <MuiDialogContent>
        <Typography align="justify" display="block" gutterBottom paragraph>
          Since you are tracking your inventory using the accrual inventory
          method, you will need to update your purchase dates and purchase
          prices on all your inventory and sales. My Reseller Genie will use
          this data to help you get accurate insight into your cost of goods
          sold and calculate numbers for tax reporting.
        </Typography>
        <Typography align="justify" display="block" gutterBottom paragraph>
          The fastest way to update this information if you have a lot of data
          is using the{" "}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://myresellergenie.zendesk.com/hc/en-us/articles/15047859541787-How-to-bulk-edit-sales-and-or-inventory"
          >
            bulk edit feature
          </Link>
          . You can also double click on sales or inventory to edit each item
          manually.
        </Typography>
      </MuiDialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => onStepChange("bulk-edit:have-you-finished")}
          color="primary"
          autoFocus
        >
          I understand
        </Button>
      </DialogActions>
    </>
  );
}

export default BulkEditInstructions;
