import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import VendorDialog from "src/pages/RootDialogs/ProfileSettings/VendorDialog";
import { getVendors } from "src/store/vendor/selector";

export interface VendorFieldProps {
  value?: string;
  onChange: (vendor?: string) => void;
  label?: string | null;
}

const AddNewVendor = {
  id: "add-new-vendor",
  name: "Add new vendor",
};
const NoVendor = {
  id: "default",
  name: "No vendor",
};

function VendorField({
  className,
  label = "Vendor",
  value,
  onChange,
  disabled,
  ...props
}: VendorFieldProps &
  Partial<Omit<TextFieldProps, "label" | "value" | "onChange">>) {
  const vendors = useSelector(getVendors);
  const [open, setOpen] = useState(false);
  const sortedVendors = useMemo(() => {
    let v: { id: string; name: string }[] = [];
    if (vendors) {
      v = [...vendors];
      v.sort((a, b) => (a.name || "").localeCompare(b.name || ""));
    }
    return [AddNewVendor, ...v, NoVendor];
  }, [vendors]);
  const val = useMemo(() => {
    if (!value || !vendors) return NoVendor;
    return vendors.find((v) => v.id === value);
  }, [vendors, value]);

  return (
    <>
      <VendorDialog
        id={open ? "add-new-vendor" : undefined}
        onClose={(vendor) => {
          if (vendor) onChange(vendor);
          setOpen(false);
        }}
      />
      <Autocomplete
        classes={className ? {
          root: className,
        } : undefined}
        autoSelect
        fullWidth
        onChange={(e, opt) => {
          const value = opt?.id;
          if (value === "default") {
            onChange(undefined);
          } else if (value === "add-new-vendor") {
            setOpen(true);
          } else if (value) {
            onChange(value);
          }
        }}
        disabled={disabled}
        options={sortedVendors}
        getOptionLabel={(opt) => opt.name}
        renderInput={(params) => (
          <TextField label={label} variant="outlined" {...props} {...params} />
        )}
        value={val}
        getOptionSelected={(a, b) => a?.id === b?.id}
      />
    </>
  );
}

export default VendorField;
