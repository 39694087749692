import React from "react";
import { bulkEditInventory } from "src/apiService/modules/inventories";

import CommonBulkEditDialog, {
  BulkEditDialogText,
  BulkEditDialogTextList,
  BulkEditDialogTextListItem,
} from "src/components/BulkEditDialog";

interface BulkEditDialogProps {
  open: boolean;
  onClose: () => void;
}

function BulkEditDialog({ open, onClose }: BulkEditDialogProps) {
  return (
    <CommonBulkEditDialog
      open={open}
      onClose={onClose}
      bulkEdit={bulkEditInventory}
      title="Bulk Edit Inventory"
      label="Click to Upload Inventory File"
    >
      <BulkEditDialogText>
        You can bulk edit inventory in My Reseller Genie by following these
        steps:
      </BulkEditDialogText>
      <BulkEditDialogTextList>
        <BulkEditDialogTextListItem>
          Filter for the inventory you want to edit
        </BulkEditDialogTextListItem>
        <BulkEditDialogTextListItem>
          Download the data by clicking the “Export” button on the table.
        </BulkEditDialogTextListItem>
        <BulkEditDialogTextListItem>
          Open the file and edit any fields that you’d like to change.
        </BulkEditDialogTextListItem>
        <BulkEditDialogTextListItem>
          Save the file and upload below.
        </BulkEditDialogTextListItem>
      </BulkEditDialogTextList>
    </CommonBulkEditDialog>
  );
}

export default BulkEditDialog;
