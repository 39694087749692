import * as yup from "yup";
import Papa from "papaparse";

import {
  transformTimeToDefault,
  transformNumber,
  transformNullToDefault,
} from "src/utils/yup";
import type { SaleRow, SaleUploadRow } from "src/interfaces/sale.interface";

export const PoshmarkRowSchema = yup
  .object({
    "Listing Date": yup.date().required().transform(transformTimeToDefault),
    "Order Date": yup.date().required().transform(transformTimeToDefault),
    SKU: yup.string().default("").transform(transformNullToDefault),
    "Order Id": yup.string().required(),
    "Listing Title": yup.string().required(),
    Department: yup.string().default("").transform(transformNullToDefault),
    Category: yup.string().default("").transform(transformNullToDefault),
    Subcategory: yup.string().default("").transform(transformNullToDefault),
    Brand: yup.string().default("").transform(transformNullToDefault),
    "Cost Price": yup
      .number()
      .default(0)
      .transform(transformNumber)
      .transform(transformNullToDefault),
    "Order Price": yup.number().required().transform(transformNumber),
    "Seller Shipping Discount": yup
      .number()
      .default(0)
      .transform(transformNumber),
    "Upgraded Shipping Label Fee": yup
      .number()
      .default(0)
      .transform(transformNumber)
      .transform(transformNullToDefault),
    "Net Earnings": yup
      .number()
      .default(0)
      .transform(transformNumber)
      .transform(transformNullToDefault),
    "Buyer State": yup.string().required(),
    "Sales Tax (Paid by Buyer)": yup
      .number()
      .default(0)
      .transform((v, ov, c) => {
        return !c.isType(v) && ov === "--" ? 0 : v;
      })
      .transform(transformNumber)
      .transform(transformNullToDefault),
    "Sales Tax (Paid by Seller)": yup
      .number()
      .default(0)
      .transform(transformNumber)
      .transform(transformNullToDefault),
    "Other Info": yup.string().default("").transform(transformNullToDefault),
  })
  .transformKeys((key) => {
    if (key === "Your Earnings") return "Net Earnings";
    return key;
  });

export interface PoshmarkRow {
  "Listing Date": Date;
  "Order Date": Date;
  SKU: string;
  "Order Id": string;
  "Listing Title": string;
  Department: string;
  Category: string;
  Subcategory: string;
  Brand: string;
  "Cost Price": number;
  "Order Price": number;
  "Seller Shipping Discount": number;
  "Upgraded Shipping Label Fee": number;
  "Net Earnings": number;
  "Buyer State": string;
  "Sales Tax (Paid by Buyer)": number;
  "Sales Tax (Paid by Seller)": number;
  "Other Info": string;
}

export function poshmarkToSale(row: PoshmarkRow): SaleRow {
  return {
    "Purchase Date": row["Listing Date"],
    "List Date": row["Listing Date"],
    "Sale Date": row["Order Date"],
    SKU: row["SKU"],
    "Item Title": row["Listing Title"],
    Department: row["Department"],
    Category: row["Category"],
    "Sub-Category": row["Subcategory"],
    Brand: row["Brand"],
    // "Purchase Price": row["Cost Price"],
    "Purchase Price": 0,
    "Sale Price": row["Order Price"],
    "Shipping Cost":
      row["Upgraded Shipping Label Fee"] + row["Seller Shipping Discount"],
    "Transaction Fees":
      row["Order Price"] -
      row["Seller Shipping Discount"] -
      row["Net Earnings"],
    "Sale State": row["Buyer State"],
    "Sales Tax":
      row["Sales Tax (Paid by Buyer)"] + row["Sales Tax (Paid by Seller)"],
    Notes:
      "Purchase Date and Purchase Price are not provided by Poshmark, and are placeholder values. To properly account for this item, please update these fields with accurate information.",
    "Sale Platform": "Poshmark",
    "Liable to Pay": false,
    Location: "",
    "Transaction Id": row["Order Id"],
  };
}

export async function poshmarkToSales(rows: any[]): Promise<SaleRow[]> {
  const sales: SaleRow[] = [];

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    try {
      const validated = (await PoshmarkRowSchema.validate(row, {
        abortEarly: false,
      })) as PoshmarkRow;
      sales.push(poshmarkToSale(validated));
    } catch (e) {
      if (Array.isArray((e as any).inner)) {
        const inner = (e as any).inner;
        throw new Error(
          `${(e as Error).message} at line ${i + 1}: ${inner
            .map((i) => i.message)
            .join(", ")}`
        );
      }

      throw e;
    }
  }

  return sales;
}

export function saleToUpload(row: SaleRow): SaleUploadRow {
  const sale: SaleUploadRow = {
    item_title: row["Item Title"],
    purchase_date: row["Purchase Date"],
    purchase_price: row["Purchase Price"],
    quantity: row["Quantity"] || 1,
    department: row["Department"],
    category: row["Category"],
    sub_category: row["Sub-Category"],
    brand: row["Brand"],
    list_date: row["List Date"],
    sku: row["SKU"],
    location: row["Location"],
    notes: row["Notes"],
    vendor: row["Vendor"] || "",
    sale_price: row["Sale Price"],
    transaction_fees: row["Transaction Fees"],
    shipping_cost: row["Shipping Cost"],
    sale_platform: row["Sale Platform"],
    sales_tax: row["Sales Tax"],
    liable_to_pay: row["Liable to Pay"],
    sale_state: row["Sale State"],
    sale_date: row["Sale Date"],
  };

  if ("Shipping Cost Analytics" in row)
    sale.shipping_cost_analytics = row["Shipping Cost Analytics"];
  if ("Other Fees" in row) sale.other_fees = row["Other Fees"];
  if ("Transaction Id" in row) sale.transaction_id = row["Transaction Id"];

  return sale;
}

export function rawToPoshmarkData(raw: string): any[] {
  const lines = raw.split("\n");
  let start = 0; // 12
  let end = -1; // -3
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i].trim();
    const isEmpty =
      !line ||
      line.startsWith(",,,,,,,,,,,,,,,,,,,,,,,,,,") ||
      line.replace(/["']/g, "").trim() === "";
    if (isEmpty) {
      if (!start) {
        start = i + 1;
      } else if (end <= 0) {
        end = i;
        break;
      }
    }
  }

  return Papa.parse(lines.slice(start, end).filter(Boolean).join("\n"), {
    header: true,
  }).data;
}
