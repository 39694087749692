import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";

import NumberField from "src/components/NumberField";
import SalePlatformField from "src/components/SalePlatformField";
import BrandField from "src/components/BrandField";
import VendorField from "src/components/VendorField";
import InfoTooltip from "src/components/InfoTooltip";

import cleanListWithExtraValue from "src/utils/cleanListWithExtraValue";

import {
  departmentsSelector,
  categoriesSelector,
  subCategoriesSelector,
} from "src/store/itemOptions/selector";

import {
  userPlatformsSelector,
  userGetInventoryTypeSelector,
} from "src/store/system/selector";
import { platformSelector } from "src/store/platform/selector";
import { AnalyticsGroupingItem } from "src/interfaces/systemState.interface";
import AddEditAnalyticsGroupingsDialog from "src/pages/RootDialogs/ProfileSettings/AnalyticsGroupings/AddEditAnalyticsGroupingsDialog";

import ReportSaleItemSoldInput from "./ReportSaleItemSoldInput";

const useStyles = makeStyles({
  padLeft: {
    marginLeft: 34,
  },
});

function ReportSaleBasicAdvancedContent({
  errors,
  values,
  handleChange,
  setValues,
  loading,
  renderOption,
  inventories,
}) {
  const inventoryTypeIsCash =
    useSelector(userGetInventoryTypeSelector) === "cash";
  const classes = useStyles();
  const [openAddAnalyticsGrouping, setOpenAddAnalyticsGrouping] =
    useState<AnalyticsGroupingItem>();
  const initialDepartments = useSelector(departmentsSelector);
  const department = values.department;
  const departments = useMemo(
    () => cleanListWithExtraValue(initialDepartments, department),
    [initialDepartments, department]
  );
  const initialCategories = useSelector((state: any) =>
    categoriesSelector(state, values)
  );
  const category = values.category;
  const categories = useMemo(
    () => cleanListWithExtraValue(initialCategories, category),
    [initialCategories, category]
  );
  const initialSubCategories = useSelector((state: any) =>
    subCategoriesSelector(state, values)
  );
  const subCategory = values.sub_category;
  const subCategories = useMemo(
    () => cleanListWithExtraValue(initialSubCategories, subCategory),
    [initialSubCategories, subCategory]
  );

  const userPlatforms = useSelector(userPlatformsSelector);
  const { items: storePlatforms, loading: platformsLoading } =
    useSelector(platformSelector);
  const salePlatform = values.sale_platform;
  const platforms = useMemo(
    () => cleanListWithExtraValue(storePlatforms, salePlatform),
    [storePlatforms, salePlatform]
  );
  const allPlatforms = useMemo(
    () => [...platforms, ...(userPlatforms || [])],
    [platforms, userPlatforms]
  );
  return (
    <>
      <AddEditAnalyticsGroupingsDialog
        open={!!openAddAnalyticsGrouping}
        initialValue={openAddAnalyticsGrouping}
        onClose={(ag) => {
          setOpenAddAnalyticsGrouping(undefined);
          if (ag)
            setValues((v) => ({
              ...v,
              department: ag.department,
              category: ag.category,
              sub_category: ag.subcategory,
            }));
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} className="flex justify-between">
          <InfoTooltip
            text="Type the name of the item that sold. You can either add a new item or select the item from your current inventory listings. To find an item in your inventory list, you can search by SKU or Item Title."
            id="report-sale-dialog-item-sold-tooltip"
            size={18}
          />
          <ReportSaleItemSoldInput
            inventories={inventories}
            values={values}
            errors={errors}
            setValues={setValues}
            disabled={loading}
            className="flex-1"
          />
        </Grid>
        <Grid item xs={12} sm={4} className="flex justify-between">
          <InfoTooltip text="Source where you purchased the item." size={18} />
          <VendorField
            margin="dense"
            disabled={loading}
            value={values.vendor}
            onChange={(vendor) => {
              const inv = values.inventory;
              setValues((values) => ({
                ...values,
                inventory: {
                  ...inv,
                  vendor: vendor || "",
                },
                vendor: vendor || "",
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            className={classes.padLeft}
            disabled={loading}
            options={["Add new department", ...departments]}
            value={departments.length ? values.department || null : null}
            autoHighlight
            onChange={(_e, value) => {
              const inv = values.inventory;
              const pack = {
                department: "",
                category: "",
                sub_category: "",
              };

              if (value === "Add new department") {
                setOpenAddAnalyticsGrouping({
                  department: "",
                  category: "",
                  subcategory: "",
                });
              } else {
                pack.department = value || "";
              }

              setValues({
                ...values,
                inventory: {
                  ...inv,
                  ...pack,
                },
                ...pack,
                sub_category: "",
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errors.department ? true : false}
                helperText={errors.department}
                margin="dense"
                required
                label="Department"
                className="w-full"
                variant="outlined"
              />
            )}
            renderOption={renderOption}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            className={classes.padLeft}
            disabled={loading}
            options={["Add new category", ...categories]}
            value={categories.length ? values.category || null : null}
            autoHighlight
            onChange={(_e, value) => {
              const inv = values.inventory;
              const pack = {
                category: "",
                sub_category: "",
              };

              if (value === "Add new category") {
                setOpenAddAnalyticsGrouping({
                  department: values.department,
                  category: "",
                  subcategory: "",
                });
              } else {
                pack.category = value || "";
              }

              setValues({
                ...values,
                inventory: {
                  ...inv,
                  ...pack,
                },
                ...pack,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errors.category ? true : false}
                helperText={errors.category}
                margin="dense"
                required
                label="Category"
                className="w-full"
                variant="outlined"
              />
            )}
            renderOption={renderOption}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            className={classes.padLeft}
            disabled={loading}
            options={["Add new sub-category", ...subCategories]}
            value={subCategories.length ? values.sub_category || null : null}
            autoHighlight
            onChange={(_e, value) => {
              const inv = values.inventory;
              let subCategory = "";

              if (value === "Add new sub-category") {
                setOpenAddAnalyticsGrouping({
                  department: values.department,
                  category: values.category,
                  subcategory: "",
                });
              } else {
                subCategory = value || "";
              }

              setValues({
                ...values,
                inventory: {
                  ...inv,
                  sub_category: subCategory,
                },
                sub_category: subCategory,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errors.sub_category ? true : false}
                helperText={errors.sub_category}
                margin="dense"
                required
                label="Sub category"
                className="w-full"
                variant="outlined"
              />
            )}
            renderOption={renderOption}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <BrandField
            className={classes.padLeft}
            value={values.brand}
            onChange={(e, value) => {
              const inv = values.inventory;
              setValues({
                ...values,
                inventory: {
                  ...inv,
                  brand: value || "",
                },
                brand: value || "",
              });
            }}
            disabled={loading}
            TextFieldProps={{
              error: errors.brand ? true : false,
              helperText: errors.brand,
              className: "w-full",
              variant: "outlined",
              margin: "dense",
              label: "Brand",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} className="flex justify-between">
          <InfoTooltip text="Where you have stored the item." size={18} />
          <TextField
            error={errors.location ? true : false}
            helperText={errors.location}
            className="w-full flex-1"
            variant="outlined"
            margin="dense"
            label="Location"
            value={values.location}
            disabled={loading}
            onChange={(event) => {
              const value = event.target.value || "";
              const inv = values.inventory;
              setValues({
                ...values,
                inventory: {
                  ...inv,
                  location: value,
                },
                location: value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <div className={`${classes.padLeft} flex`}>
            <TextField
              error={errors.sku ? true : false}
              helperText={errors.sku}
              className="w-full"
              variant="outlined"
              margin="dense"
              label="SKU"
              value={values.sku}
              disabled={loading}
              onChange={(event) => {
                const value = event.target.value || "";
                const inv = values.inventory;
                setValues({
                  ...values,
                  inventory: {
                    ...inv,
                    sku: value,
                  },
                  sku: value,
                });
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={4} className="flex justify-between">
          <InfoTooltip
            text="The total revenue collected from a customer. This includes the price of the item and additional money collected for shipping. This does not include sales tax."
            size={18}
          />
          <NumberField
            required
            error={errors.sale_price ? true : false}
            helperText={errors.sale_price}
            className="w-full flex-1"
            variant="outlined"
            margin="dense"
            label="Sale Price"
            value={values.sale_price}
            disabled={loading}
            onChange={handleChange("sale_price")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>
        {inventoryTypeIsCash ? null : (
          <Grid item xs={12} sm={4} className="flex justify-between">
            <InfoTooltip
              text="The price at which you purchased the item."
              size={18}
            />
            <NumberField
              error={errors.purchase_price ? true : false}
              helperText={errors.purchase_price}
              className="w-full flex-1"
              variant="outlined"
              margin="dense"
              label="Purchase Price"
              value={values.purchase_price}
              disabled={loading}
              onChange={(event) => {
                const { value } = event.target;
                let inv = values.inventory;
                setValues({
                  ...values,
                  inventory: {
                    ...inv,
                    purchase_price: value,
                  },
                  purchase_price: value,
                });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={4} className="flex justify-between">
          <InfoTooltip
            text="The amount you paid to ship the sale. This amount will be included in the P&L and Management Dashboard. If your shipping is accounted for in the expense detail, don’t add the cost here as well – this will result in duplicate shipping expenses."
            size={18}
          />
          <NumberField
            error={errors.shipping_cost ? true : false}
            helperText={errors.shipping_cost}
            className="w-full flex-1"
            variant="outlined"
            margin="dense"
            label="Shipping Cost"
            value={values.shipping_cost}
            disabled={loading}
            onChange={handleChange("shipping_cost")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} className="flex justify-between">
          <InfoTooltip
            text="The amount the platform charged you to sell the item."
            size={18}
          />
          <NumberField
            error={errors.transaction_fees ? true : false}
            helperText={errors.transaction_fees}
            className="w-full flex-1"
            variant="outlined"
            margin="dense"
            label="Transaction Fees"
            value={values.transaction_fees}
            disabled={loading}
            onChange={handleChange("transaction_fees")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} className="flex justify-between">
          <InfoTooltip text="The sales tax paid by the buyer." size={18} />
          <NumberField
            error={errors.sales_tax ? true : false}
            helperText={errors.sales_tax}
            className="w-full flex-1"
            variant="outlined"
            margin="dense"
            label="Sales Tax"
            value={values.sales_tax}
            disabled={loading}
            onChange={handleChange("sales_tax")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <div className={`${classes.padLeft} flex`}>
            <TextField
              onInput={(e: any) => {
                let value = e.target?.value;
                let res = value ? value : "";
                setValues({ ...values, sale_state: res });
                e.target.value = res;
              }}
              value={values.sale_state}
              margin="dense"
              label="Sale State"
              variant="outlined"
              className="w-full"
              disabled={loading}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={4} className="flex justify-between">
          <InfoTooltip
            text="If you aren’t responsible for filing sales tax, mark this field as ‘No’. If you are responsible for filing sales tax, mark this field as ‘Yes’."
            size={18}
          />
          <Autocomplete
            className="flex-1"
            disabled={loading}
            options={["No", "Yes"]}
            value={values.liable_to_pay ? "Yes" : "No"}
            autoHighlight
            onChange={(_e, value) => {
              setValues({
                ...values,
                liable_to_pay: value === "Yes" ? true : false,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errors.liable_to_pay ? true : false}
                helperText={errors.liable_to_pay}
                margin="dense"
                required
                label="Liable to Pay"
                className="w-full flex-1"
                variant="outlined"
              />
            )}
            renderOption={renderOption}
          />
        </Grid>
        {inventoryTypeIsCash ? null : (
          <Grid item xs={12} sm={8}>
            <Autocomplete
              className={classes.padLeft}
              multiple
              disabled={platformsLoading || loading}
              options={allPlatforms?.sort((a, b) =>
                a?.toLowerCase()?.localeCompare(b?.toLowerCase())
              )}
              value={values.platforms_listed ? values.platforms_listed : []}
              onChange={(event, value) => {
                const inv = values.inventory;
                setValues((values) => ({
                  ...values,
                  inventory: {
                    ...inv,
                    platforms_listed: value || [],
                  },
                  platforms_listed: value || [],
                }));
              }}
              autoHighlight
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  label="Platforms Listed"
                  variant="outlined"
                />
              )}
              renderOption={renderOption}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={4} className="flex justify-between">
          <InfoTooltip
            text={`Record the platform where you sold this item. If the platform doesn't appear in the dropdown, you can add platforms in your profile settings or by clicking "Add New Platform" in the "Sale Platform" dropdown."`}
            size={18}
          />
          <SalePlatformField
            disabled={loading}
            value={allPlatforms?.length ? values.sale_platform || null : null}
            onChange={(value) => {
              setValues((values) => ({
                ...values,
                sale_platform: value || "",
              }));
            }}
            margin="dense"
            error={!!errors.sale_platform}
            helperText={errors.sale_platform}
          />
        </Grid>
        <Grid item xs={12} sm={8} className="flex justify-between">
          <InfoTooltip text="Add notes to your sale." size={18} />
          <TextField
            error={errors.notes ? true : false}
            helperText={errors.notes}
            className="w-full"
            variant="outlined"
            margin="dense"
            label="Sale Notes"
            value={values.notes || ""}
            disabled={loading}
            onChange={(event) => {
              const { value } = event.target;
              let inv = values.inventory;
              setValues({
                ...values,
                inventory: {
                  ...inv,
                  notes: value,
                },
                notes: value || "",
              });
            }}
          />
        </Grid>
        {inventoryTypeIsCash ? null : (
          <Grid item xs={12} sm={4} className="flex justify-between">
            <InfoTooltip
              text="The date that you purchased this item."
              size={18}
            />
            <KeyboardDatePicker
              disableToolbar
              error={!!errors.purchase_date}
              helperText={errors.purchase_date}
              className="w-full flex-1"
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              autoOk={true}
              margin="dense"
              label="Purchase Date"
              required
              value={values.purchase_date || null}
              disabled={loading}
              onChange={(event: any, _) => {
                let inv = values.inventory;
                setValues({
                  ...values,
                  inventory: {
                    ...inv,
                    purchase_date: new Date(event),
                  },
                  purchase_date: new Date(event),
                });
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={4} className="flex justify-between">
          <InfoTooltip
            text="The date that you listed this item for sale."
            size={18}
          />
          <KeyboardDatePicker
            disableToolbar
            className="w-full flex-1"
            variant="inline"
            inputVariant="outlined"
            format="MM/dd/yyyy"
            margin="dense"
            label="List Date"
            autoOk={true}
            value={values.list_date || null}
            disabled={loading}
            onChange={(e: any, t: any) => {
              if (e) {
                setValues({ ...values, list_date: new Date(e) });
              } else {
                const { list_date, ...v } = values;
                setValues(v);
              }
            }}
            error={!!errors.list_date}
            helperText={errors.list_date}
          />
        </Grid>
        <Grid item xs={12} sm={4} className="flex justify-between">
          <InfoTooltip text="The date that you sold this item." size={18} />
          <KeyboardDatePicker
            disableToolbar
            className="w-full flex-1"
            variant="inline"
            inputVariant="outlined"
            format="MM/dd/yyyy"
            margin="dense"
            label="Sale Date"
            autoOk={true}
            required
            error={!!errors.sale_date}
            helperText={errors.sale_date}
            value={values.sale_date || null}
            disabled={loading}
            onChange={(e: any, t: any) => {
              setValues({ ...values, sale_date: new Date(e) });
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} className="flex justify-between">
          <InfoTooltip
            text="If you have already accounted for shipping costs in the “Expense Detail”, you can attach them to the sale here. This will prevent the expense from being counted twice on your P&L statement."
            size={18}
          />
          <NumberField
            error={errors.shipping_cost_analytics ? true : false}
            helperText={errors.shipping_cost_analytics}
            className="w-full flex-1"
            variant="outlined"
            margin="dense"
            label="Shipping Costs (From Expense Detail)"
            value={values.shipping_cost_analytics}
            disabled={loading}
            onChange={handleChange("shipping_cost_analytics", true)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            InputLabelProps={{
              className: "whitespace-nowrap",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} className="flex justify-between">
          <InfoTooltip
            text="If you have already accounted for other costs in the “Expense Detail”, you can attach them to the sale here. This will prevent the expense from being counted twice on your P&L statement."
            size={18}
          />
          <NumberField
            error={errors.other_fees ? true : false}
            helperText={errors.other_fees}
            className="w-full flex-1"
            variant="outlined"
            margin="dense"
            label="Other Costs (From Expense Detail)"
            value={values.other_fees}
            disabled={loading}
            onChange={handleChange("other_fees", true)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            InputLabelProps={{
              className: "whitespace-nowrap",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ReportSaleBasicAdvancedContent;
