import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import SalesPlatformDialog from "src/pages/RootDialogs/ProfileSettings/SalesPlatformDialog";
import { userPlatformsSelector } from "src/store/system/selector";
import { platformSelector } from "src/store/platform/selector";

export interface SalePlatformFieldProps {
  label?: string | null;
  value?: string;
  onChange: (salePlatform?: string) => void;
  helperText?: string;
}

const AddNew = {
  id: "add-new",
  name: "Add Sale Platform",
};
const NoSalePlatform = {
  id: "default",
  name: "No Sale Platform",
};

function SalePlatformField({
  label = "Sale Platform",
  value,
  onChange,
  disabled,
  ...props
}: SalePlatformFieldProps &
  Partial<Omit<TextFieldProps, "value" | "label" | "onChange">>) {
  const { items: systemPlatforms } = useSelector(platformSelector);
  const userPlatforms = useSelector(userPlatformsSelector);
  const platforms = useMemo(() => {
    const platforms = [...(userPlatforms || []), ...(systemPlatforms || [])];
    if (value && !platforms.includes(value)) platforms.push(value);
    platforms.sort((a, b) => a.localeCompare(b));

    return [
      AddNew,
      ...platforms.map((e) => ({ id: e, name: e })),
      NoSalePlatform,
    ];
  }, [userPlatforms, systemPlatforms, value]);
  const [open, setOpen] = useState(false);
  const val = useMemo(() => {
    if (!value) return NoSalePlatform;
    return { id: value, name: value };
  }, [value]);

  return (
    <>
      <SalesPlatformDialog
        open={open}
        onClose={(platform) => {
          if (platform) onChange(platform);
          setOpen(false);
        }}
      />
      <Autocomplete
        fullWidth
        autoSelect
        value={val}
        disabled={disabled}
        options={platforms}
        getOptionLabel={(opt) => opt.name}
        renderInput={(params) => (
          <TextField label={label} variant="outlined" {...props} {...params} />
        )}
        getOptionSelected={(a, b) => a?.id === b?.id}
        onChange={(e, opt) => {
          const value = opt?.id;
          if (value === "default") {
            onChange(undefined);
          } else if (value === "add-new") {
            setOpen(true);
          } else if (value) {
            onChange(value);
          }
        }}
      />
    </>
  );
}

export default SalePlatformField;
