import React from "react";

export interface GradientStop {
  offset: string;
  color: string; // rgb(...)
}
export interface GradientType {
  name: string;
  color: string; // rgb(...)
  stops: GradientStop[];
}

const Gradients: GradientType[] = [
  {
    name: "Green",
    color: "rgb(4, 76, 38)",
    stops: [
      { offset: "0%", color: "rgb(24, 107, 62)" },
      { offset: "50%", color: "rgb(4, 76, 38)" },
      { offset: "100%", color: "rgb(0, 51, 51)" },
    ],
  },
  {
    name: "LightGreen",
    color: "rgb(28, 93, 59)",
    stops: [
      { offset: "0%", color: "rgb(111, 164, 137)" },
      { offset: "100%", color: "rgb(28, 93, 59)" },
    ],
  },
  {
    name: "Blue",
    color: "rgb(24, 68, 97)",
    stops: [
      { offset: "0%", color: "rgb(67, 115, 148)" },
      { offset: "60%", color: "rgb(24, 68, 97)" },
      { offset: "100%", color: "rgb(0, 51, 102)" },
    ],
  },
  {
    name: "LightBlue",
    color: "rgb(68, 112, 147)",
    stops: [
      { offset: "0%", color: "rgb(175, 200, 219)" },
      { offset: "100%", color: "rgb(68, 112, 147)" },
    ],
  },
  {
    name: "Grey",
    color: "rgb(215, 212, 219)",
    stops: [
      { offset: "0%", color: "rgb(215, 212, 219)" },
      { offset: "100%", color: "rgb(178, 169, 189)" },
    ],
  },
  {
    name: "LightGrey",
    color: "rgb(241, 238, 245)",
    stops: [
      { offset: "0%", color: "rgb(255, 255, 255)" },
      { offset: "100%", color: "rgb(241, 238, 245)" },
    ],
  },
];

export const GradientsByName: Record<string, GradientType> = Gradients.reduce(
  (a, g) => {
    a[g.name] = g;
    return a;
  },
  {}
);

export function makeBarFill(gradients: (GradientType | undefined)[]) {
  return gradients.reduce((style, gradient, i) => {
    if (gradient) {
      style[
        `& g.cartesianlayer g.plot g.barlayer.mlayer g.trace.bars:nth-child(${
          i + 1
        }) g.points g.point path`
      ] = {
        fill: `url(#Gradient${gradient.name}) !important`,
      };
    }
    return style;
  }, {});
}

export function makePieFill(gradients: (GradientType | undefined)[]) {
  return gradients.reduce((style, gradient, i) => {
    if (gradient) {
      style[`& g.pielayer g.trace g.slice:nth-child(${i + 1}) path.surface`] = {
        fill: `url(#Gradient${gradient.name}) !important`,
      };
    }
    return style;
  }, {});
}

const defs = Gradients.map(({ name, color, stops }) => (
  <linearGradient
    key={name}
    id={`Gradient${name}`}
    x1="0%"
    x2="0%"
    y1="100%"
    y2="0%"
  >
    {stops.map(({ offset, color }) => (
      <stop
        key={offset}
        offset={offset}
        style={{ stopColor: color } as any}
      />
    ))}
  </linearGradient>
));

function SvgGradientDef() {
  return (
    <svg style={{ width: 0, height: 0, position: "absolute" }}>
      <defs>{defs}</defs>
    </svg>
  );
}

export default SvgGradientDef;
