async function paralelize<T>(
  array: T[],
  job: (element: T, index: number, array: T[]) => Promise<void>,
  n = 20
): Promise<void> {
  let i = 0;
  const run = async () => {
    if (i >= array.length) return;
    const current = i;
    i++;
    await job(array[current], current, array);
    await run();
  };
  const promises: Promise<void>[] = [];
  for (let ii = 0; ii < n; ii++) {
    promises.push(run());
  }

  await Promise.all(promises);
}

export default paralelize;
