import React from "react";
import range from "lodash/range";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import MonthYear from "src/interfaces/monthYear.interface";

interface MonthYearFieldProps {
  children: string;
  value: MonthYear;
  onChange: (change: (d: MonthYear) => MonthYear) => void;
  error?: string;
  disabled?: boolean;
}

const Months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const CurrentMonthYear: MonthYear = (() => {
  const date = new Date();
  return {
    month: (date.getMonth() + 1) as MonthYear["month"],
    year: date.getFullYear(),
  };
})();
const RecentYears = range(CurrentMonthYear.year - 1, CurrentMonthYear.year + 1);

const useStyles = makeStyles((theme) => ({
  error: {
    margin: theme.spacing(0.5, 1, 0),
  },
  placeholder: {
    display: "block",
    height: `${1.66 * 0.75}rem`,
    margin: theme.spacing(0.5, 1, 0),
  },
}));

function MonthYearField({
  children,
  value,
  onChange,
  error,
  disabled,
}: MonthYearFieldProps) {
  const classes = useStyles();
  return (
    <>
      <Typography className="mb-3" variant="body1">
        {children}
      </Typography>
      <Box className="flex">
        <Select
          disabled={disabled}
          error={!!error}
          className="mr-4"
          onChange={(e) => {
            const value = parseInt(
              e.target.value as any,
              10
            ) as MonthYear["month"];
            onChange((d) => ({
              ...d,
              month: value,
            }));
          }}
          variant="outlined"
          value={value.month}
        >
          {Months.map((month, index) => (
            <MenuItem value={index + 1} key={month}>
              {month}
            </MenuItem>
          ))}
        </Select>
        <Select
          disabled={disabled}
          error={!!error}
          onChange={(e) => {
            const value = parseInt(e.target.value as any, 10);
            onChange((d) => ({
              ...d,
              year: value,
            }));
          }}
          variant="outlined"
          value={value.year}
        >
          {RecentYears.map((year) => (
            <MenuItem value={"" + year} key={"" + year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {error ? (
        <Typography
          variant="caption"
          color="error"
          display="block"
          className={classes.error}
        >
          {error}
        </Typography>
      ) : (
        <div className={classes.placeholder} />
      )}
    </>
  );
}

export default MonthYearField;
