import produce from "immer";
import { handleActions } from "redux-actions";

import { secondsToDate } from "../../utils";

import { BankStatementState } from "../../interfaces";
import { METHOD_TYPE } from "../../enums";
import { LOG_OUT } from "../system/actions";
import {
  START,
  FAIL,
  SUCCESS,
  FETCH_ITEMS,
  UPLOAD_ITEMS,
  DELETE_ITEMS,
  FETCH_ITEM,
  CREATE_ITEM,
  DELETE_ITEM,
  UPDATE_ITEM,
  RESET,
  BANK_STATEMENT,
  SET_FILTER,
  SELECT_ITEMS,
  FS_CREATE_ITEMS,
  FS_DELETE_ITEMS,
  FS_UPDATE_ITEMS,
  CLEAR_UPDATE_METHOD,
} from "../common";

const initialState: BankStatementState = {
  items: [],
  total: 0,
  start: 1,
  end: 1,
  limit: 25,

  method: METHOD_TYPE.LIST,
  loading: false,
  loaded: false,
  error: null,
  message: "",

  selected: [],
  filter: {
    items: [],
  }
};

export const bankStatementReducer = handleActions<BankStatementState, any>(
  {
    [BANK_STATEMENT + RESET]: (state) =>
      produce(state, (draft) => {
        draft = initialState;
      }),
    [BANK_STATEMENT + FETCH_ITEMS + START]: (state, {payload: { start }}) =>
      produce(state, (draft) => {
        draft.start = start || initialState.start;
        draft.method = METHOD_TYPE.LIST;
        draft.loading = true;
        draft.error = null;
      }),
    [BANK_STATEMENT + FETCH_ITEMS + SUCCESS]: (state, { payload: { data, length, total } }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = false;
        draft.items = data.map((e: any) => ({
          ...e,
          bank_statement_date: e.bank_statement_date ? secondsToDate(e.bank_statement_date) : undefined,
        })).sort((a, b) => {
          const legend = [-1, 0, 1];
          return legend[+((a?.bank_statement_date as Date) < (b?.bank_statement_date as Date))];
        });
        draft.total = total;
        draft.error = null;
      }),
    [BANK_STATEMENT + FETCH_ITEMS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),
    [BANK_STATEMENT + UPLOAD_ITEMS + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.UPLOAD;
        draft.loading = true;
        draft.loaded = false;
      }),
    [BANK_STATEMENT + UPLOAD_ITEMS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = true;
        draft.error = null;
      }),
    [BANK_STATEMENT + UPLOAD_ITEMS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),
    [BANK_STATEMENT + DELETE_ITEMS + START]: (state) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.DELETE_ALL;
        draft.loading = true;
        draft.loaded = false;
      }),
    [BANK_STATEMENT + DELETE_ITEMS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = true;
        draft.items = [];
      }),
    [BANK_STATEMENT + DELETE_ITEMS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),

    [BANK_STATEMENT + UPDATE_ITEM + START]: (state) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.UPDATE;
        draft.loading = true;
        draft.error = null;
      }),
    [BANK_STATEMENT + UPDATE_ITEM + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items = state.items.map(item => item.id === payload.id ? payload : item);
        draft.loading = false;
        draft.loaded = false;
        draft.error = null;
      }),
    [BANK_STATEMENT + UPDATE_ITEM + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),

    [BANK_STATEMENT + CREATE_ITEM + START]: (state) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.CREATE;
        draft.loading = true;
        draft.error = null;
      }),
    [BANK_STATEMENT + CREATE_ITEM + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = false;
        draft.error = null;
      }),
    [BANK_STATEMENT + CREATE_ITEM + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),

    [BANK_STATEMENT + DELETE_ITEM + START]: (state) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.DELETE;
        draft.loading = true;
        draft.error = null;
      }),
    [BANK_STATEMENT + DELETE_ITEM + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = false;
        draft.error = null;
      }),
    [BANK_STATEMENT + DELETE_ITEM + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),
    [BANK_STATEMENT + SELECT_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.selected = payload;
      }),
    [BANK_STATEMENT + SET_FILTER]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.filter = payload;
      }),
    [CLEAR_UPDATE_METHOD]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.LIST;
      }),
    [BANK_STATEMENT + FS_CREATE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        payload?.sort((a, b) => {
          const legend = [-1, 0, 1];
          if (a?.bank_statement_name === "Unreconciled") return -1;
          if (b?.bank_statement_name === "Unreconciled") return 1;
          return legend[+((a?.bank_statement_date as Date) < (b?.bank_statement_date as Date))];
        });
        draft.items.unshift(...payload.map((e: any) => ({
          ...e,
          bank_statement_date: e.bank_statement_date ? secondsToDate(e.bank_statement_date) : undefined,
        })));
        draft.loading = false;
        draft.loaded = true;
      }),
    [BANK_STATEMENT + FS_UPDATE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items.forEach((item) => {
          const updated = payload.find((newItem) => newItem.id === item.id);
          if (updated) {
            const mapped = {
              ...updated,
              bank_statement_date: updated.bank_statement_date ? secondsToDate(updated.bank_statement_date) : undefined,
            };
            Object.assign(item, mapped);
          }
        });
      }),
    [BANK_STATEMENT + FS_DELETE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items = state.items.filter((item) => !payload.find((deleted) => deleted.id === item.id));
      }),
    [LOG_OUT]: () => initialState,
  },
  initialState
);
