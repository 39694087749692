import React from "react";

import Wrapper from "src/components/Wrapper";

import { CashActivityContent } from "./CashActivityContent";
import { BankStatementContent } from "./BankStatementContent";

function CashPage() {
  return (
    <Wrapper>
      <CashActivityContent />
      <BankStatementContent />
    </Wrapper>
  );
}

export default CashPage;
