import firebase from "firebase/app";
import "firebase/auth";

let instance: firebase.auth.Auth | null = null;

function auth(): firebase.auth.Auth {
  if (!instance) {
    instance = firebase.auth();

    if (
      process.env.NODE_ENV !== "production" &&
      typeof window !== "undefined" &&
      window.location.hostname === "localhost" &&
      process.env.REACT_APP_FIREBASE_FUNCTIONS_URL?.startsWith(
        "http://localhost"
      )
    ) {
      instance.useEmulator("http://127.0.0.1:9099");
    }
  }

  return instance;
}

export default auth;
