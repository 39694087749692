import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import usePrevious from "src/utils/usePrevious";

import { StatefulLoadableButton } from "src/components/LoadableButton";

export interface ConfirmDialogProps {
  reconnect?: boolean;
  username?: string;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
  platform?: string;
}

function ConfirmDialog({
  reconnect = false,
  platform: _platform,
  username,
  onCancel,
  onConfirm,
}: ConfirmDialogProps) {
  const platformMemory = usePrevious(_platform);
  const platform = _platform || platformMemory || "Poshmark";
  const usernameMemory = usePrevious(username);
  const title = reconnect
    ? `Refresh ${platform} Integrations`
    : `Add ${platform} integration`;
  const content = reconnect
    ? `The ${platform} account "${
        username || usernameMemory
      }" will be reconnected.`
    : `The ${platform} account "${username || usernameMemory}" will be added.`;
  const button = reconnect ? "Connect" : "Add";
  return (
    <Dialog open={!!username} onClose={() => onCancel()}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel()}>Cancel</Button>
        <StatefulLoadableButton onClick={() => onConfirm()} color="primary">
          {button}
        </StatefulLoadableButton>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
