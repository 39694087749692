import React, { useState } from "react";
import clsx from "clsx";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles({
  root: {
    position: "relative",
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-6px 0 0 -6px",
  },
});

function DeleteButton({
  onClick,
  errorMessage = "There was an error. Try again later",
  className,
  disabled,
}: {
  onClick: (() => Promise<any>) | (() => void);
  errorMessage?: string;
  className?: string;
  disabled?: boolean;
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  return (
    <IconButton
      className={clsx(classes.root, className)}
      color="secondary"
      disabled={disabled || loading}
      onClick={async () => {
        setLoading(true);
        try {
          await onClick();
        } catch (e) {
          toast.error(errorMessage);
        }
      }}
    >
      <DeleteIcon />
      {loading && <CircularProgress size={12} className={classes.progress} />}
    </IconButton>
  );
}

export default DeleteButton;
