import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { GridCellParams } from "@material-ui/x-grid";

const style: React.CSSProperties = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

function tooltipRenderCell(params: GridCellParams) {
  const value = params?.value;
  return (
    <Tooltip title={value || ""}>
      <span style={style}>{value}</span>
    </Tooltip>
  );
}

export default tooltipRenderCell;
