import React from "react";
import ReactDOM from "react-dom";
import "react-toastify/dist/ReactToastify.css";

import "./index.css";
import * as serviceWorker from "./serviceWorker";
import * as config from "./config";

import App from "./App";

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
