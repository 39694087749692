import * as React from "react";
import { connect } from "react-redux";
import { Formik, FormikHelpers as FormikActions, FormikProps } from "formik";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";

import { Button, CircularProgress, Theme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";

import { AppState } from "../../store";
import { resetPassword } from "../../store/system/actions";
import { LOGO } from "../../assets/svgs";

const styles = (theme: Theme) =>
  createStyles({
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      padding: 12,
    },
  });

interface MyFormValues {
  email: string;
}

class PasswordReset extends React.Component<any> {
  constructor(props: any) {
    super(props);
    this.onFormikSubmit = this.onFormikSubmit.bind(this);
  }

  async onFormikSubmit(values: MyFormValues, { setSubmitting }: FormikActions<MyFormValues>) {
    if (values.email && !this.props.loading) {
      this.props.resetPassword({ email: values.email });
    }
  }

  render() {
    const { classes, loggedIn, loading, error, passwordResetSubmitted } = this.props;
    if (loggedIn) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Box className="mb-5 h-36">
            <img src={LOGO} alt="My Reseller Genie" className="h-full" />
          </Box>
          <Typography component="h1" variant="h5">
            Forgot password
          </Typography>
          {!passwordResetSubmitted &&
            <Formik
              initialValues={{ email: '' }}
              onSubmit={this.onFormikSubmit}
            >
              {(formikBag: FormikProps<MyFormValues>) => (
                <form className={classes.form} noValidate onSubmit={formikBag.handleSubmit}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={formikBag.handleChange}
                    onBlur={formikBag.handleBlur}
                    value={formikBag.values.email}
                  />
                  {error && !loading && <Alert severity="error">{error}</Alert>}
                  <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                    {!loading && "Reset password"}
                    {loading && <CircularProgress color="inherit" className="w-6 h-6" />}
                  </Button>
                  <Grid container>
                    <Grid item>
                      <Link to="/login">
                        {"Return to login"}
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          }
          {!!passwordResetSubmitted &&
            <>
              <Typography variant="h6">Reset request submitted.</Typography>
              <Typography variant="body1">Check your email for a link to set a new password.</Typography>
              <Grid container>
                <Grid item>
                  <Link to="/login">
                    {"Return to login"}
                  </Link>
                </Grid>
              </Grid>
            </>
          }
        </div>
      </Container>
    );
  }
};

const mapStateToProps = ({ system }: AppState) => ({
  loggedIn: system.loggedIn,
  loading: system.loading,
  error: system.error,
  passwordResetSubmitted: system.passwordResetSubmitted,
});

const mapDispatchToProps = {
  resetPassword,
};

export default withStyles(styles)(
  connect<any, any, any, AppState>(
    mapStateToProps,
    mapDispatchToProps
  )(PasswordReset)
);
