import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import Papa from "papaparse";

import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import CircularProgress from "@material-ui/core/CircularProgress";

import InfoTooltip from "../../components/InfoTooltip";
import { getAllSales } from "../../store/sale/selector";
import { getInventory } from "../../store/inventory/selector";
import { getTransactions } from "../../store/transaction/selector";
import { formatDollars } from "../../utils";
import ParametersSelection from "./ParametersSelection";
import { colors } from "../../theme";
import { getGeneralLedgerAccounts } from "../../store/glAccounts/selector";

import {
  selectUser,
  userPlanSelector,
  userGetInventoryTypeSelector,
} from "src/store/system/selector";

import { USER_PLAN } from "src/enums/common.enum";

import {
  buildProfitLossReport,
  ReportTypes,
  Reports,
  reportIsProfitLoss,
  ReportParameters,
  InventoryReport as InventoryReportType,
  buildInventoryReport,
  reportIsInventory,
  reportIsPlatformTransaction,
  buildPlatformTransactionReport,
  reportIsConsignmentCommission,
  buildConsignmentCommissionReport,
} from "./report";
import ProfitLossReport from "./ProfitLossReport";
import PlatformTransactionReport from "./PlatformTransactionReport";
import renderReport from "./renderReport";
import ConsignmentCommissionReport from "./ConsignmentCommissionReport";

const useStyles = makeStyles((theme) => ({
  root: {},
  reportHeader: {
    color: colors.blueColor,
    fontWeight: 600,
    fontSize: 18,
  },
  majorRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 5,
    marginTop: 10,
    width: 420,
  },
  majorText: {
    fontWeight: 500,
  },
  rowLabel: {
    fontWeight: 500,
    width: 310,
  },
  sign: {
    fontWeight: 500,
    width: 20,
  },
  value: {
    fontWeight: 500,
  },
  specialHeader: {
    fontWeight: 500,
    margin: "0 0 0 15px",
  },
  resultsContainer: {
    margin: "0 auto",
    width: 480,
  },
  minorRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 5,
    paddingLeft: 15,
    width: 420,
  },
  minorText: {},
  finalRow: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 5,
    width: 420,
  },
  finalText: {
    color: colors.blueColor,
    fontWeight: 500,
  },
  title: {
    flex: 1,
  },
  reportType: {
    marginRight: theme.spacing(2),
    "& button": {
      lineHeight: 1,
    },
  },
  pointerRow: {
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  highlightedRow: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
}));

const { startOfCurrentMonth, endOfCurrentMonth, currentYear } = (() => {
  const startOfCurrentMonth = new Date();
  startOfCurrentMonth.setDate(1);
  startOfCurrentMonth.setHours(0);
  startOfCurrentMonth.setMinutes(0);
  startOfCurrentMonth.setSeconds(0);
  startOfCurrentMonth.setMilliseconds(0);

  const endOfCurrentMonth = new Date(startOfCurrentMonth);
  endOfCurrentMonth.setMonth(endOfCurrentMonth.getMonth() + 1);
  endOfCurrentMonth.setDate(0);
  endOfCurrentMonth.setHours(23);
  endOfCurrentMonth.setMinutes(59);
  endOfCurrentMonth.setSeconds(59);
  endOfCurrentMonth.setMilliseconds(999);

  return {
    startOfCurrentMonth,
    endOfCurrentMonth,
    currentYear: new Date().getFullYear(),
  };
})();

const formatDate = (date) => {
  if (!date) {
    return "";
  }
  return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
};

const inventoryReportExportFormatter = (item) => ({
  ...item,
  purchase_date: formatDate(item.purchase_date),
  list_date: formatDate(item.list_date),
  sale_date: formatDate(item.sale_date),
  purchase_price: formatDollars(item.purchase_price),
  sale_price: formatDollars(item.sale_price),
  transaction_fees: formatDollars(item.transaction_fees),
  gross_profit: formatDollars(item.gross_profit),
  sales_tax: formatDollars(item.sales_tax),
});

const inventoryReportExportColumns = [
  "item_title",
  "department",
  "category",
  "sub_category",
  "brand",
  "location",
  "sku",
  "purchase_date",
  "list_date",
  "sale_date",
  "days_on_platform",
  "sale_price",
  "purchase_price",
  "shipping_cost",
  "transaction_fees",
  "gross_profit",
  "sale_state",
  "sales_tax",
  "liable_to_pay",
  "sale_platform",
  "notes",
];

export const Content = () => {
  const classes = useStyles();
  const sales = useSelector(getAllSales);
  const inventories = useSelector(getInventory);
  const transactionState = useSelector(getTransactions);
  // const overviews = useSelector(getNumericOverviews);
  const customLedgerAccounts = useSelector(getGeneralLedgerAccounts);
  const user = useSelector(selectUser);
  const userPlan = useSelector(userPlanSelector);
  const [parameters, setParameters] = useState<ReportParameters>({
    startDate: startOfCurrentMonth,
    endDate: endOfCurrentMonth,
    year: currentYear,
  });
  const [reportType, setReportType] = useState<ReportTypes>("profit/loss");
  const [results, setResults] = useState<Reports | null>(null);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const contentRef = useRef<HTMLElement>(null);
  const inventoryTypeIsCash = useSelector(userGetInventoryTypeSelector) === "cash";
  const handleDownloadReport = async () => {
    if (!contentRef?.current) return;
    if (!results) return;
    if (!reportIsInventory(results)) return;
    const div = document.createElement("div");
    div.innerHTML = renderReport(
      results.data.reportDetails.titleFull,
      contentRef.current.innerHTML
    );
    for (const ele of div.querySelectorAll("button, a")) {
      if (ele.parentNode) ele.parentNode.removeChild(ele);
    }
    setDownloadLoading(true);
    const html2pdf = await import("html2pdf.js");
    await html2pdf
      .default()
      .set({
        filename: results.data.reportDetails.filename,
      })
      .from(div)
      .save();
    setDownloadLoading(false);
  };

  const generateReport = (rt = reportType) => {
    switch (rt) {
      case "profit/loss":
        setResults(
          buildProfitLossReport(
            rt,
            parameters,
            customLedgerAccounts,
            sales,
            transactionState.items,
            user
          )
        );
        break;

      case "inventory":
        setResults(buildInventoryReport(rt, parameters, sales, inventories));
        break;

      case "platform-transaction":
        setResults(
          buildPlatformTransactionReport(
            rt,
            parameters,
            sales,
            transactionState.items
          )
        );
        break;

      case "consignment-commission":
        setResults(buildConsignmentCommissionReport(rt, parameters, sales));
        break;
    }
  };

  const renderInventoryDetailLink = (
    key: keyof InventoryReportType["data"]
  ) => {
    if (!results || !reportIsInventory(results)) return;
    const rawData = results.data[key];
    if (!rawData || !Array.isArray(rawData) || !rawData.length) {
      return <Box className="w-12" />;
    }
    const formattedData = rawData.map(inventoryReportExportFormatter);
    const data = Papa.unparse(formattedData, {
      columns: inventoryReportExportColumns,
    });
    const outFile = new Blob([data], { type: "text/csv" });
    const url = URL.createObjectURL(outFile);
    return (
      <IconButton
        className="ml-5"
        color="primary"
        component="a"
        href={url}
        download={`${key}_${results.data.reportDetails.dateRange}.csv`}
        disabled={!results}
      >
        <GetAppIcon />
      </IconButton>
    );
  };

  const displayResults = () => {
    if (!results) return null;

    if (reportIsProfitLoss(results)) {
      return <ProfitLossReport report={results} />;
    }

    if (reportIsInventory(results)) {
      return displayInventoryResults();
    }

    if (reportIsPlatformTransaction(results)) {
      return <PlatformTransactionReport report={results} />;
    }

    if (reportIsConsignmentCommission(results)) {
      return <ConsignmentCommissionReport report={results} />;
    }

    return null;
  };

  const displayInventoryResults = () => {
    if (!results || !reportIsInventory(results)) return null;
    return (
      <Paper className="mt-5 p-5 h-auto">
        <Box display="flex" alignItems="center">
          <h2 className={`w-full text-left text-blue z-10 ${classes.title}`}>
            {results.data.reportDetails.titleFull}
          </h2>
          <Box>
            <IconButton
              onClick={handleDownloadReport}
              disabled={downloadLoading}
            >
              {downloadLoading ? (
                <CircularProgress size={24} />
              ) : (
                <GetAppIcon />
              )}
            </IconButton>
          </Box>
        </Box>
        <Box className={classes.resultsContainer} {...{ ref: contentRef }}>
          <Box className="flex justify-between">
            <Box className={classes.majorRow}>
              <Box className={classes.rowLabel}>
                Inventory balance at beginning of year
              </Box>
              <Box className={classes.sign} />
              <Box className={classes.value}>
                {formatDollars(results.data.yearStartBalance)}
              </Box>
            </Box>
            {renderInventoryDetailLink("yearStartBalanceItems")}
          </Box>
          <Box className="flex justify-between">
            <Box className={classes.majorRow}>
              <Box className={classes.rowLabel}>
                Inventory purchases during the year
              </Box>
              <Box className={classes.sign}>+</Box>
              <Box className={classes.value}>
                {formatDollars(results.data.purchasesDuringYear)}
              </Box>
            </Box>
            {renderInventoryDetailLink("purchasesDuringYearItems")}
          </Box>
          <Box className="flex justify-between">
            <Box className={classes.majorRow}>
              <Box className={classes.rowLabel}>
                Inventory balance at end of year
              </Box>
              <Box className={classes.sign}>-</Box>
              <Box className={classes.value}>
                {formatDollars(results.data.yearEndBalance)}
              </Box>
            </Box>
            {renderInventoryDetailLink("yearEndBalanceItems")}
          </Box>
          <Divider />
          <Box className="flex justify-between align-center">
            <Box className={classes.majorRow}>
              <Box className={classes.rowLabel}>
                Inventory Cost (Cost of Goods Sold)
                <InfoTooltip text="Numbers not adding up? That means that you have items with a purchase date after their own sale date. Download the Inventory Cost (Cost of Goods Sold) to find and correct them." />
              </Box>
              <Box className={classes.sign} />
              <Box className={classes.value}>
                {formatDollars(results.data.inventoryCost)}
              </Box>
            </Box>
            {renderInventoryDetailLink("inventoryCostItems")}
          </Box>
        </Box>
      </Paper>
    );
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Paper className="p-5 h-auto">
          <Box>
            <Box>
              <FormControl variant="outlined" margin="dense">
                <InputLabel id="start-month">Report Type</InputLabel>
                <Select
                  margin="dense"
                  label="Report Type"
                  value={reportType}
                  onChange={(e: React.ChangeEvent<any>) => {
                    setReportType(e.target.value);
                  }}
                >
                  <MenuItem value="profit/loss">Profit/Loss Statement</MenuItem>
                  { inventoryTypeIsCash
                    ? null : (
                    <MenuItem value="inventory">Inventory Report</MenuItem>
                  )}
                  <MenuItem value="platform-transaction">
                    Platform Transactions
                  </MenuItem>
                  {userPlan && userPlan !== USER_PLAN.BASIC ? (
                    <MenuItem value="consignment-commission">
                      Consignment Commission
                    </MenuItem>
                  ) : null}
                </Select>
              </FormControl>
            </Box>
            <ParametersSelection
              parameters={parameters}
              reportType={reportType}
              setParameters={setParameters}
            />
          </Box>
          <Button
            color="primary"
            variant="contained"
            disabled={
              (reportType === "platform-transaction" && !parameters.platform) ||
              (reportType === "consignment-commission" && !parameters.consignor)
            }
            onClick={() => generateReport()}
            className="mt-5"
          >
            Run Report
          </Button>
        </Paper>
        {!!results && displayResults()}
      </Grid>
    </Grid>
  );
};
