import { createAction } from "redux-actions";
import { 
  ERROR_ALERTS,
  START,
  RESET,
  FS_CREATE_ITEMS,
  FS_DELETE_ITEMS,
  FS_UPDATE_ITEMS,
} from "../common";

export const reset = createAction(ERROR_ALERTS + RESET);

export const addErrorAlertRecords = createAction(ERROR_ALERTS + FS_CREATE_ITEMS);
export const updateErrorAlertRecords = createAction(ERROR_ALERTS + FS_UPDATE_ITEMS);
export const deleteErrorAlertRecords = createAction(ERROR_ALERTS + FS_DELETE_ITEMS);
