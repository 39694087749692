import React from "react";

import Wrapper from "src/components/Wrapper";

import { Content } from "./Content";

function TransactionPage() {
  return (
    <Wrapper>
      <Content />
    </Wrapper>
  );
}

export default TransactionPage;
