import React, { useState, useEffect } from "react";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { StatefulLoadableButton } from "src/components/LoadableButton";

import { getPoshmarkUser } from "src/utils/extension/poshmark";
import { poshmarkIntegrationCreate } from "src/apiService/modules/poshmark";

import DialogTitle from "./DialogTitle";
import type Step from "./Step";

interface PoshmarkConfirmProps {
  onClose: () => void;
  onStepChange: (step: Step) => void;
}

function PoshmarkConfirm({ onClose, onStepChange }: PoshmarkConfirmProps) {
  const [poshmarkUserData, setPoshmarkUserData] = useState<
    | {
        username: string;
        jwt: string;
      }
    | { error: string }
    | { loading: true }
  >({ loading: true });

  useEffect(() => {
    let cancel = false;
    (async () => {
      try {
        const data = await getPoshmarkUser();
        if (cancel) return;
        setPoshmarkUserData(data);
      } catch (e) {
        if (cancel) return;
        setPoshmarkUserData({ error: (e as Error).message });
      }
    })();
    return () => {
      cancel = true;
    };
  }, []);

  return (
    <>
      <DialogTitle onClose={onClose}>Add Poshmark integration</DialogTitle>
      <MuiDialogContent>
        <DialogContentText>
          {"username" in poshmarkUserData
            ? `The Poshmark account "${poshmarkUserData.username}" will be added.`
            : "error" in poshmarkUserData
            ? `ERROR: ${poshmarkUserData.error}`
            : "Loading..."}
        </DialogContentText>
      </MuiDialogContent>
      <DialogActions>
        <Button
          onClick={() => onStepChange("poshmark:do-you-sell-on-poshmark")}
        >
          Cancel
        </Button>
        <StatefulLoadableButton
          variant="contained"
          disabled={
            !!(poshmarkUserData as any).error ||
            (poshmarkUserData as any).loading
          }
          onClick={async () => {
            if ("jwt" in poshmarkUserData && poshmarkUserData.jwt) {
              await poshmarkIntegrationCreate({
                jwt: poshmarkUserData.jwt,
                username: poshmarkUserData?.username,
                lastSync: new Date().toISOString(),
              });
              onStepChange("poshmark:import");
            }
          }}
          color="primary"
          autoFocus
        >
          Add
        </StatefulLoadableButton>
      </DialogActions>
    </>
  );
}

export default PoshmarkConfirm;
