import React, { type Dispatch, type SetStateAction } from "react";
import { compose } from "redux";
import { useSelector } from "react-redux";

import { getEtsyIntegrations } from "src/store/plaidIntegration/selector";
import withOnlyDevelopment from "src/utils/withOnlyDevelopment";
import withFeatureFlag from "src/utils/withFeatureFlag";

import type { DialogState } from "../types";
import PlatformIntegrationsTableRow from "../PlatformIntegrationsTableRow";

import { etsyRequestAuthorizationCode } from "src/apiService/modules/etsy";
import EtsyIntegrationTableRow from "./Row";

// fix-vim-highlight = }

interface EtsyProps {
  hideInventory: boolean;
  setDialog: Dispatch<SetStateAction<DialogState>>;
}

function Etsy({ setDialog, hideInventory }: EtsyProps) {
  const etsyIntegrations = useSelector(getEtsyIntegrations);

  const handleEtsyAdd = async () => {
    const authResponse = await etsyRequestAuthorizationCode();
    window.location.href = authResponse.data.etsyURL;
  };

  if (etsyIntegrations.length === 0)
    return (
      <PlatformIntegrationsTableRow
        name="Etsy"
        platform="Etsy"
        isRunning={false}
        sync={false}
        inventory={false}
        onImport={handleEtsyAdd}
        hideInventory={hideInventory}
        onPullDataNow={async () => handleEtsyAdd()}
        onSyncChanged={handleEtsyAdd}
        onAdd={handleEtsyAdd}
      />
    );
  return (
    <>
      {etsyIntegrations.map((integration) => (
        <EtsyIntegrationTableRow
          key={integration.id}
          integration={integration}
          hideInventory={hideInventory}
          setDialog={setDialog}
        />
      ))}
    </>
  );
}

export default compose<typeof Etsy>(
  withOnlyDevelopment,
  withFeatureFlag("etsy")
)(Etsy);
