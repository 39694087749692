import {
  getExtensionId,
  getCookies,
  createWindow,
  waitOnRemoveWindow,
  removeWindow,
  webRequest,
  type Cookie,
} from ".";

// fix-vim-highlight = }

export async function getPoshmarkCookies(
  extensionId: string
): Promise<Cookie[]> {
  return await getCookies(extensionId, {
    domain: "poshmark.com",
  });
}

async function performPoshmarkLogin(extensionId: string) {
  const windowsCreateResponse = await createWindow(extensionId, {
    focused: true,
    type: "popup",
    url: "https://poshmark.com/login",
  });

  const windowId = windowsCreateResponse.result;

  let closed = false;
  let jwt: string | undefined = "";

  await Promise.any([
    waitOnRemoveWindow(extensionId, {
      windowId,
    }),
    new Promise<void>((rs) => {
      const check = async () => {
        if (closed) return rs();
        const cookies = await getPoshmarkCookies(extensionId);
        jwt = cookies.find((c) => c.name === "jwt")?.value;

        if (jwt) {
          removeWindow(extensionId, {
            windowId,
          });
          return rs();
        }

        setTimeout(() => check(), 500);
      };

      check();
    }),
  ]);
  closed = true;
  return jwt;
}

export async function getPoshmarkUser(): Promise<{
  username: string;
  jwt: string;
}> {
  const extensionId = getExtensionId();

  const cookies = await getPoshmarkCookies(extensionId);
  let jwt = cookies.find((c) => c.name === "jwt")?.value;

  if (!jwt) jwt = await performPoshmarkLogin(extensionId);

  if (!jwt) {
    throw new Error("Log into Poshmark to allow My Reseller Genie to connect.");
  }

  let userId = "";

  try {
    userId = JSON.parse(atob(jwt.split(".")[1])).user_id;
  } catch (e) {
    console.error(e);
    throw new Error(
      "There was an error, please log out from Poshmark and log into it again."
    );
  }

  const response = await webRequest(extensionId, {
    url: `https://poshmark.com/vm-rest/users/${userId}`,
    init: {
      headers: {
        Accept: "application/json",
      },
      method: "GET",
    },
  });
  const username = response?.result?.data?.data?.username;

  if (!username) {
    console.error("No username", response);
    throw new Error(
      "There was an error, please log out from Poshmark and log into it again."
    );
  }

  return {
    username,
    jwt,
  };
}
