import React, { cloneElement } from "react";
import { useSelector } from "react-redux";
import Button, { ButtonProps } from "@material-ui/core/Button";

// https://docs.usecsv.com/docs/importer/client-libraries/react/options
import UseCSV from "@usecsv/react";

import { toast } from "react-toastify";

import { userIdSelector } from "src/store/system/selector";
import { uploadSalesFile } from "src/apiService/modules/sales";
import { uploadInventoryFile } from "src/apiService/modules/inventories";
import { uploadExpensesFile } from "src/apiService/modules/transactions";

export type ImporterType = "expenses" | "sales" | "inventory";
export const ImporterKeys: Record<ImporterType, string> = {
  expenses: "3fea6b8a-ef4b-4922-bf4d-a9bc07aabcf2",
  sales: "cf771ca2-e237-429b-b6c9-9eb3ce1dc850",
  inventory: "a6dee185-dfc1-49d0-9977-40906cb8bb96",
};

export interface ErrorEntry {
  message: string;
  index: number;
}
export function mapErrors(
  rows: any[],
  errors: ErrorEntry[]
): { row: any; error: string }[] {
  const errorsByRow: Record<number, string[]> = {};

  for (const error of errors) {
    const index = error.index;
    if (!errorsByRow[index]) errorsByRow[index] = [];
    errorsByRow[index].push(error.message);
  }

  return Object.entries(errorsByRow).map(([key, value]) => ({
    row: rows[key],
    error: value.join(", "),
  }));
}

interface DataEntry {
  isValid: boolean;
  value: any;
}
type Data = Record<string, DataEntry>;

interface RecordHookResponseData {
  message: string;
  level: "error" | "info" | "healing";
  newValue?: string;
}
type Healer = (value: any, data: Data) => RecordHookResponseData | undefined;

const NumberRe = /[^.0-9-()]/g;
function currencyHealer(amount: any): RecordHookResponseData | undefined {
  let value = `${amount}`.replace(NumberRe, "");
  if (value.startsWith("(") && value.endsWith(")"))
    value = `${-+value.substring(1, value.length - 1)}`;
  const invalid = isNaN(value as any);
  if (invalid)
    return {
      message: "Must be a number",
      level: "error",
    };

  return {
    level: "healing",
    message: "Value has been corrected to match the required format",
    newValue: value,
  };
}

const DataRe = /(\d+)\s*[/-]\s*(\d+)\s*[/-]\s*(\d+)/;
const MonthToNumber = [
  { month: "jan", number: "01" },
  { month: "feb", number: "02" },
  { month: "mar", number: "03" },
  { month: "apr", number: "04" },
  { month: "may", number: "05" },
  { month: "jun", number: "06" },
  { month: "jul", number: "07" },
  { month: "aug", number: "08" },
  { month: "sep", number: "09" },
  { month: "oct", number: "10" },
  { month: "nov", number: "11" },
  { month: "dec", number: "12" },
];
function dateHealer(date: any): RecordHookResponseData | undefined {
  if (!date) return;
  date = `${date}`.toLowerCase();
  for (const { month, number } of MonthToNumber) {
    date = date.replace(month, number);
  }
  const match = date.match(DataRe);
  if (match) {
    const first = parseInt(match[1], 10);
    const second = parseInt(match[2], 10);
    const third = parseInt(match[3], 10);
    const array =
      first > 12
        ? // first is year, second month, third day
          [second, third, first]
        : // first is month, second year, third day
          [first, second, third];

    if (
      array.every((n) => n > 0) &&
      array[0] <= 12 &&
      array[1] <= 31 &&
      (100 > array[2] || array[2] >= 1000)
    ) {
      array[2] = array[2] % 1000;
      return {
        level: "healing",
        message: "Value has been corrected to match the required format",
        newValue: array.map((n) => `${n}`.padStart(2, "0")).join("/"),
      };
    }
  }

  return {
    level: "error",
    message: "Invalid date format",
  };
}

function requiredHealer(value: any): RecordHookResponseData | undefined {
  if (value === undefined || value === null || value === "")
    return {
      level: "error",
      message: "",
    };
  return;
}

type NewValueGetter = (data: Data) => string;
function defaultHealerCreator(newValue: string | NewValueGetter): Healer {
  return (value: any, data: Data) =>
    value === undefined || value === null || value === ""
      ? {
          level: "healing",
          message: "Value has been corrected to match the required format",
          newValue: typeof newValue === "string" ? newValue : newValue(data),
        }
      : undefined;
}

function healerRunner(
  healers: Healer[],
  value: any,
  data: Data
): RecordHookResponseData | undefined {
  let ret: RecordHookResponseData | undefined;

  for (const healer of healers) {
    const fix = healer(ret?.level === "healing" ? ret.newValue : value, data);
    if (fix) {
      if (fix.level === "error") return fix;
      ret = fix;
    }
  }

  return ret;
}

const ImporterHealers: Record<ImporterType, Record<string, Healer[]>> = {
  expenses: {
    amount: [currencyHealer, requiredHealer],
    date: [dateHealer, requiredHealer],
  },
  sales: {
    list_date: [dateHealer],
    other_fees: [currencyHealer, defaultHealerCreator("0")],
    purchase_date: [
      defaultHealerCreator((data) => data["sale_date"].value),
      dateHealer,
    ],
    purchase_price: [currencyHealer, defaultHealerCreator("0")],
    sale_date: [dateHealer],
    sale_price: [currencyHealer, defaultHealerCreator("0")],
    sales_tax: [currencyHealer, defaultHealerCreator("0")],
    shipping_cost_analytics: [currencyHealer, defaultHealerCreator("0")],
    shipping_costs: [currencyHealer, defaultHealerCreator("0")],
    transaction_fees: [currencyHealer, defaultHealerCreator("0")],
  },
  inventory: {
    list_date: [dateHealer],
    purchase_date: [dateHealer, requiredHealer],
    purchase_price: [currencyHealer, defaultHealerCreator("0")],
  },
};

interface RecordHandlerRow {
  row: number;
  data: Record<string, RecordHookResponseData[]>;
}

function recordHandler(
  importer: ImporterType,
  row
): RecordHandlerRow | undefined {
  const healers = ImporterHealers[importer];
  const fixes = {};
  for (const [key, hs] of Object.entries(healers)) {
    if (row.data[key]?.isValid === false) {
      const fix = healerRunner(hs as Healer[], row.data[key].value, row.data);
      if (fix) fixes[key] = [fix];
    }
  }

  if (Object.keys(fixes).length > 0) {
    return {
      row: row.row,
      data: fixes,
    };
  }
}

export interface UploaderProps {
  importerType?: ImporterType;
  children?: any;
  onClose?: () => void;
  label?: string;
  uploader?: (
    type: ImporterType,
    rows: any[],
    metadata: any
  ) => Promise<{ success: true } | { errors: ErrorEntry[] }>;
}

function Uploader({
  children,
  importerType,
  onClose,
  label = "Next",
  uploader,
  ...props
}: UploaderProps & Omit<ButtonProps, "onClick">) {
  const userId = useSelector(userIdSelector);

  if (!importerType || !userId) {
    if (children) return cloneElement(children, { disabled: true });
    return (
      <Button disabled color="primary" variant="contained" {...props}>
        {label}
      </Button>
    );
  }

  return (
    <UseCSV
      importerKey={ImporterKeys[importerType]}
      render={(onClick) =>
        children ? (
          cloneElement(children, { onClick })
        ) : (
          <Button
            color="primary"
            onClick={() => onClick()}
            variant="contained"
            {...props}
          >
            {label}
          </Button>
        )
      }
      user={{
        userId,
      }}
      metadata={{
        importerType,
      }}
      onRecordsInitial={({ rows, metadata }) => {
        const importer = metadata?.importerType || importerType;

        const healers = ImporterHealers[importer];
        if (Object.keys(healers).length === 0) return;

        const changes: {
          row: number;
          data: Record<string, RecordHookResponseData[]>;
        }[] = [];

        for (const row of rows) {
          try {
            const change = recordHandler(importer as ImporterType, row);
            if (change) changes.push(change);
          } catch (error) {
            console.error(error, importer, row);
          }
        }

        return changes;
      }}
      onRecordEdit={({ row, metadata }) => {
        const importer = metadata?.importerType || importerType;

        const healers = ImporterHealers[importer];
        if (Object.keys(healers).length === 0) return;

        try {
          const change = recordHandler(importer as ImporterType, row);
          if (change) return change;
        } catch (error) {
          console.error(error, importer, row);
        }
      }}
      onData={async (data) => {
        const importer = data.metadata?.importerType || importerType;
        const metadata = {
          ...data.metadata,
          importerType: importer,
          uploadId: data.uploadId,
          fileName: data.fileName,
          importedRowsCount: data.importedRowsCount,
        };
        const rows = data.rows;

        try {
          if (importer === "sales") {
            const ret = await (uploader
              ? uploader(importer, rows, metadata)
              : uploadSalesFile(rows, metadata));
            if (ret.success) return;
            return {
              errors: mapErrors(rows, ret.errors),
            };
          } else if (importer === "inventory") {
            const ret = await (uploader
              ? uploader(importer, rows, metadata)
              : uploadInventoryFile(rows, metadata));
            if (ret.success) return;
            return {
              errors: mapErrors(rows, ret.errors),
            };
          } else if (importer === "expenses") {
            const ret = await (uploader
              ? uploader(importer, rows, metadata)
              : uploadExpensesFile(rows, metadata));
            if (ret.success) return;
            return {
              errors: mapErrors(rows, ret.errors),
            };
          }
        } catch (e) {
          console.error(e);
          toast.error(`There was an unexpected error: ${(e as Error).message}`);
          return {
            errors: rows.map((row) => ({
              row,
              error: "unexpected error",
            })),
          };
        }
      }}
      onClose={() => {
        if (onClose) onClose();
      }}
    />
  );
}

export default Uploader;
