import { useSelector } from "react-redux";
import { type DataType } from "./ImportDialog";
import { isMercariAllowedToDirectImportSelector } from "src/store/uploads/selector";

export function usePreventImportMercari(dataType: DataType) {
  return !useSelector((state: any) =>
    isMercariAllowedToDirectImportSelector(state, dataType)
  );
}

export default usePreventImportMercari;
