import React, { useMemo } from "react";

import {
  Box,
  Button,
  TextField,
  Grid,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import InfoTooltip from "../../components/InfoTooltip";
import SalePlatformField from "src/components/SalePlatformField";

import NumberField from "src/components/NumberField";

const useStyles = makeStyles({
  itemsHeader: {
    fontSize: 16,
    fontWeight: 500,
    marginTop: 20,
    marginBottom: 20,
  },
  itemTitle: {
    fontWeight: 600,
  },
});

const EditBundleDialog = ({
  errors,
  handleChange,
  onDelete,
  values,
  setValues,
  loading,
  saleStates,
  renderOption,
  platformsLoading,
  allPlatforms,
  allocationValues,
  setAllocationValues,
  goToRoot,
  goToAllocations,
  moveBundleToInventory,
}) => {
  const classes = useStyles();

  const renderItemRow = (currentValue, index) => {
    return (
      <Box className="flex" key={`${currentValue.id}-${index}`}>
        <Typography className={classes.itemTitle}>{currentValue.item_title}</Typography>
      </Box>
    );
  }

  const disableEdit = useMemo(() => {
    for (let i = 0; i < allocationValues.length; i++) {
      if (allocationValues[i].return_id) {
        return true;
      }
    }
    return false;
  }, [allocationValues]);

  return (
    <>
      <DialogTitle id="edit-sale relative">
        Edit Bundle
        <IconButton onClick={onDelete} className="absolute right-5" disabled={loading} color="secondary">
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          component="div"
          className="relative"
          tabIndex={-1}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} className="flex justify-between">
                <InfoTooltip text="The total revenue collected from a customer. This includes the price of the item and additional money collected for shipping. This does not include sales tax." size={18} />
                <NumberField
                  error={errors.sale_price ? true : false}
                  helperText={errors.sale_price}
                  className="w-11/12"
                  variant="outlined"
                  margin="dense"
                  label="Sale Price"
                  required
                  value={values.sale_price}
                  disabled={loading || disableEdit}
                  onChange={handleChange('sale_price')}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="flex justify-between">
                <InfoTooltip text="The amount you paid to ship the sale." size={18} />
                <NumberField
                  error={errors.shipping_cost ? true : false}
                  helperText={errors.shipping_cost}
                  className="w-11/12"
                  variant="outlined"
                  margin="dense"
                  label="Shipping Cost"
                  value={values.shipping_cost}
                  disabled={loading || disableEdit}
                  onChange={handleChange('shipping_cost')}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="flex justify-between">
                <InfoTooltip text="The amount the platform charged you to sell the item." size={18} />
                <NumberField
                  error={errors.transaction_fees ? true : false}
                  helperText={errors.transaction_fees}
                  className="w-11/12"
                  variant="outlined"
                  margin="dense"
                  label="Transaction Fees"
                  value={values.transaction_fees}
                  disabled={loading || disableEdit}
                  onChange={handleChange('transaction_fees')}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="flex justify-between">
                <InfoTooltip text="The sales tax paid by the buyer." size={18} />
                <NumberField
                  error={errors.sales_tax ? true : false}
                  helperText={errors.sales_tax}
                  className="w-11/12"
                  variant="outlined"
                  margin="dense"
                  label="Sales Tax"
                  value={values.sales_tax}
                  disabled={loading || disableEdit}
                  onChange={handleChange('sales_tax')}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="flex justify-end">
                <Autocomplete
                  freeSolo
                  options={saleStates}
                  value={values.sale_state}
                  onChange={(_e, value) => {
                    setValues({ ...values, sale_state: value || "" });
                  }}
                  className="w-11/12"
                  autoHighlight
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      onInput = {(e: any) =>{
                        let value = e.target?.value;
                        let res = value ? value.toUpperCase().substr(0, 2) : "";
                        setValues({ ...values, sale_state: res });
                        e.target.value = res;
                      }}
                      disabled={loading || disableEdit}
                      margin="dense"
                      label="Sale State"
                      className="w-full"
                      variant="outlined"
                    />
                  }
                  disabled={loading || disableEdit}
                  renderOption={renderOption}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="flex justify-between">
                <InfoTooltip text="If you aren’t responsible for filing sales tax, mark this field as ‘No’. If you are responsible for filing sales tax, mark this field as ‘Yes’." size={18} />
                <Autocomplete
                  options={['No', 'Yes']}
                  value={values.liable_to_pay ? "Yes" : "No"}
                  autoHighlight
                  className="w-11/12"
                  onChange={(_e, value) => {
                    setValues({ ...values, liable_to_pay: value === "Yes" ? true : false });
                  }}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      error={errors.liable_to_pay ? true : false}
                      helperText={errors.liable_to_pay}
                      margin="dense"
                      required
                      disabled={loading || disableEdit}
                      label="Liable to Pay"
                      className="w-full"
                      variant="outlined"
                    />
                  }
                  disabled={loading || disableEdit}
                  renderOption={renderOption}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="flex justify-between">
                <InfoTooltip text={`Record the platform where you sold this item. If the platform doesn't appear in the dropdown, you can add platforms in your profile settings or by clicking "Add New Platform" in the "Sale Platform" dropdown."`} size={18} />
                <SalePlatformField
                  disabled={platformsLoading || disableEdit}
                  value={values.sale_platform}
                  onChange={(value) => {
                    setValues(values => ({ ...values, sale_platform: value || "" }));
                  }}
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6} className="flex justify-between">
                <InfoTooltip text="The date that you sold these items." size={18} />
                <KeyboardDatePicker
                  disableToolbar
                  className="w-11/12"
                  format="MM/dd/yyyy"
                  margin="dense"
                  inputVariant="outlined"
                  label="Sale Date"
                  autoOk={true}
                  required
                  error={!!errors.sale_date}
                  helperText={errors.sale_date}
                  value={values.sale_date || null}
                  disabled={loading || disableEdit}
                  onChange={(e: any, t: any) => {
                    setValues({ ...values, sale_date: new Date(e) });
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
          <Typography variant="h3" className={`${classes.itemsHeader} text-blue`}>Line Items</Typography>
          {allocationValues.map(renderItemRow)}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="px-7 py-5">
        <Button onClick={goToRoot} color="secondary" variant="contained">
          Back to Sale
        </Button>
        <Button onClick={moveBundleToInventory} color="primary" variant="contained">
          Move Items To Inventory
        </Button>
        <Button onClick={goToAllocations} color="primary" variant="contained" disabled={!allocationValues.length || !allocationValues[0].item_title}>
          Allocate 
        </Button>
      </DialogActions>
    </>
  );
};

export default EditBundleDialog;
