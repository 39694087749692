import { createAction } from "redux-actions";
import { SALE_STATE, START, FETCH_ITEMS, CREATE_ITEM, RESET } from "../common";
import { saleStateSelector } from "./selector";

export const fetchItems = createAction(SALE_STATE + FETCH_ITEMS + START);

export function fetchItemsIfNeeded() {
  return (dispatch, getState) => {
    const state = getState();
    const { items } = saleStateSelector(state);
    if (!items || !items.length) dispatch(fetchItems());
  };
}

export const createItem = createAction(SALE_STATE + CREATE_ITEM + START);

export const reset = createAction(SALE_STATE + RESET);
