import omit from "lodash/omit";
import capitalize from "lodash/capitalize";

import type { Sale } from "src/interfaces/sale.interface";
import type { Inventory } from "src/interfaces/inventory.interface";
import { calculateGrossProfit, daysOnPlatform } from "../utils";

function fallbackDate(date, fallback) {
  if (!date) return fallback;
  return isNaN(date.valueOf()) ? fallback : date;
}

function round(number, fixed = 2) {
  const e = Math.pow(10, fixed);
  return Math.round(number * e) / e;
}

class Allocator {
  value: number;
  divider: number;
  length: number;
  fixed: number;
  allocated: number;

  /**
   * @param {number} value - total "discount"
   * @param {number} divider - eg.: total price
   * @param {number} length - amount of items
   * @param {number} fixed - decimal rounding
   */
  constructor(value: number, divider: number, length: number, fixed: number) {
    this.value = value;
    this.divider = divider;
    this.length = length;
    this.fixed = fixed;
    this.allocated = 0;
  }

  /**
   * @param {number} dividend - eg.: price
   * @param {number} index - index of element
   * @return {number} allocated value
   */
  getAllocation(dividend, index) {
    if (!this.divider) return this.value;

    const last = index === this.length - 1;
    if (last) return this.value - this.allocated;

    let allocated = (this.value * dividend) / this.divider;

    if (this.fixed) allocated = round(allocated, this.fixed);

    this.allocated += allocated;
    return allocated;
  }
}

export function factorReturnedSale(sale, poshmark): Omit<Sale, "id" | "user"> {
  const ret = {
    ...omit(sale, [
      "id",
      "upload",
      "unreviewed",
      "unmatched",
      "return_id",
      "in_process",
    ]),
    is_return: true,
    sale_price: -(sale.sale_price || 0),
    purchase_price: -(sale.purchase_price || 0),
    shipping_cost: -(sale.shipping_cost || 0),
    extra_shipping_cost: -(sale.extra_shipping_cost || 0),
    extra_transaction_fees: -(sale.extra_transaction_fees || 0),
    shipping_cost_analytics: -(sale.shipping_cost_analytics || 0),
    transaction_fees: -(sale.transaction_fees || 0),
    other_fees: -(sale.other_fees || 0),
    sales_tax: -(sale.sales_tax || 0),
    sale_platform: "Poshmark",
    in_process: false,
  } as Omit<Sale, "id" | "user">;

  if (sale.id) ret.original_id = sale.id;

  const returnDate = new Date(poshmark?.details?.order?.cancelled_on);
  if (!isNaN(returnDate.valueOf())) {
    ret.return_date = returnDate;
  }

  ret.gross_profit = calculateGrossProfit(ret);

  return ret;
}

export function mapSale(poshmark): Omit<Sale, "id" | "user">[] {
  const inventoryBookedAt = new Date(poshmark.inventory_booked_at);
  const status = (
    poshmark.display_status ||
    poshmark.details.order.display_status ||
    ""
  )
    .trim()
    .toLowerCase();
  const state = (poshmark.state || poshmark.details?.order?.state || "")
    .trim()
    .toLowerCase();
  const now = new Date();
  const sale = {
    sale_platform: "Poshmark",
    transaction_id: poshmark.id,
    sales_tax: 0,
    shipping_cost: 0,
    transaction_fees: 0,
    sku: "",
    sale_state:
      poshmark.details.order.shipping_method_info?.delivery_info
        ?.shipping_address?.state || "",
    sale_date: fallbackDate(inventoryBookedAt, now),
    purchase_date: fallbackDate(inventoryBookedAt, now),
    list_date: fallbackDate(inventoryBookedAt, now),
    purchase_price: 0,
    sale_price: 0,
    extra_shipping_cost: 0,
    extra_transaction_fees: 0,
    shipping_cost_analytics: 0,
    other_fees: 0,
    in_process: state !== "cancelled" && status !== "order complete",
    liable_to_pay: false,
  };
  // const total = parseFloat(poshmark.total_price_amount?.val) || 0;
  const total =
    poshmark.details.order.line_items.reduce(
      (t, i) => t + parseFloat(i.price_amount?.val) || 0,
      0
    ) || 0;
  const lineItemsLength = poshmark.details.order.line_items.length;
  const discountAllocator = new Allocator(
    parseFloat(poshmark.details.order.seller_discount_amount?.val) || 0,
    total,
    lineItemsLength,
    2
  );
  const salesTaxAllocator = new Allocator(
    parseFloat(poshmark.details.order.total_tax_amount?.val) || 0,
    total,
    lineItemsLength,
    2
  );
  const shippingCostAllocator = new Allocator(
    parseFloat(poshmark.details.order.seller_shipping_discount_amount?.val) ||
      0,
    total,
    lineItemsLength,
    2
  );
  const transactionFeesAllocator = new Allocator(
    parseFloat(poshmark.details.order.pm_fee_amount?.val) || 0,
    total,
    lineItemsLength,
    2
  );
  return poshmark.details.order.line_items
    .filter((l) => !!l)
    .map((lineItem, index) => {
      const price = parseFloat(lineItem.price_amount?.val) || 0;
      const discount = discountAllocator.getAllocation(price, index);
      const createdAt = new Date(lineItem.product?.data?.created_at);

      const item = {
        ...sale,
        sku: lineItem.sku || sale.sku,
        item_title: lineItem.title,
        sale_price: price - discount,
        sales_tax: salesTaxAllocator.getAllocation(price, index),
        shipping_cost: shippingCostAllocator.getAllocation(price, index),
        transaction_fees: transactionFeesAllocator.getAllocation(price, index),
        category: lineItem.category,
        brand: lineItem.brand,
        sub_category: lineItem.category_features_obj?.[0]?.display || "",
        department: capitalize(
          (lineItem.category_obj?.message_id || "").split("_")[0] || ""
        ),
        purchase_date: fallbackDate(createdAt, sale.purchase_date),
        list_date: fallbackDate(createdAt, sale.list_date),
      } as Omit<Sale, "id" | "user">;

      if (lineItem.product?.data?.first_published_at) {
        const firstPublishedAt = new Date(
          lineItem.product?.data?.first_published_at
        );
        item.purchase_date = fallbackDate(firstPublishedAt, item.purchase_date);
        item.list_date = fallbackDate(firstPublishedAt, item.list_date);
      }

      item.gross_profit = calculateGrossProfit(item);
      const dop = daysOnPlatform(item.sale_date, item.list_date);
      if (dop !== null) item.days_on_platform = dop;

      return item;
    });
}

export function mapInventory(poshmark): Omit<Inventory, "user" | "id">[] {
  const firstPublishedAt = new Date(
    poshmark.first_published_at || poshmark.created_at
  );
  const now = new Date();
  const item = {
    brand: poshmark.brand || "",
    category: poshmark.catalog?.category_obj?.display || "",
    list_date: fallbackDate(firstPublishedAt, now),
    department: poshmark.catalog?.department_obj?.display || "",
    item_title: poshmark.title || "",
    location: "",
    notes: "",
    platforms_listed: ["Poshmark"],
    purchase_date: fallbackDate(firstPublishedAt, now),
    purchase_price: 0,
    sku: "",
    sub_category: poshmark.catalog?.category_feature_objs?.[0]?.display || "",
    // quantity: poshmark.inventory?.size_quantities?.[0].quantity_available || 1,
    quantity: 1,
    listing_id: poshmark.id,
  };

  const items = [] as Omit<Inventory, "user" | "id">[];
  const length = (poshmark.inventory?.size_quantities || []).reduce((a, i) => {
    if (i.quantity_available >= 1) return a + 1;
    return a;
  }, 0);

  if (length) {
    for (const size of poshmark.inventory.size_quantities) {
      if (size.quantity_available < 1) continue;

      const current = {
        ...item,
        quantity: size.quantity_available || 1,
      } as any;

      if (length > 1 && size.size_obj?.display)
        current.item_title = `${current.item_title} - size ${size.size_obj.display}`;
      if (size.size_id) current.size_id = size.size_id;
      if (size.size_system) current.size_system = size.size_system;

      items.push(current);
    }
  }

  return items;
}
