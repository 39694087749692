import React, { useState } from "react";

import { Button, TextField, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";

import InfoTooltip from "src/components/InfoTooltip";
import { colors } from "src/theme";
import { formatDate, formatDollars } from "src/utils";
import NumberField from "src/components/NumberField";
import WalkThrough from "./ManualSalesMatchingWalkThrough";

const useStyles = makeStyles((theme) => ({
  saleDetails: {},
  header: {
    color: colors.blueColor,
    fontSize: 20,
    fontWeight: 500,
  },
  nowrap: {
    whiteSpace: "nowrap",
  },
  purchasePrice: {
    marginLeft: theme.spacing(1),
  },
}));

const infoText = `We couldn't find a perfect inventory match for some of your sales based on item title and/or SKU.
Please match the sales details with an existing inventory item. If the item is not in your inventory, enter the inventory
data for the sale.`;

const ExampleSelectedInventory = {
  item_title: "Fantastic Steel Chair",
  sku: "978-0-08-395315-8",
  purchase_date: (() => {
    const d = new Date();
    d.setMonth(d.getMonth() - 1);
    return d;
  })(),
  purchase_price: Math.round(Math.random() * 1500) / 10,
  quantity: 1,
  purchase_price_total: 150,
};

const ManualSalesMatching = ({
  gotoAddInventory,
  onSave,
  noAutomaticMatchCount,
  gotoTable,
  selectableInventory,
  render,
  unmatchedSales,
  onDelete,
  actions,
  unreviewedSales,
}: any) => {
  const classes = useStyles();

  const [selectedInventory, setSelectedInventory] = useState<any>(null);
  const selectedSale = unmatchedSales[0];

  const save = () => {
    onSave(selectedInventory);
    setSelectedInventory(null);
  };

  return (
    <>
      <WalkThrough
        onSelectInventoryPlaceholder={(placeholder) =>
          setSelectedInventory(placeholder ? ExampleSelectedInventory : null)
        }
      />

      {render({
        title: "Sales Matching",
        main: (
          <>
            <Typography variant="body1">
              Please match these sales with an existing inventory item.
              <InfoTooltip text={infoText} />
            </Typography>
            <div id="manual-sales-matching-sale-inventory">
              <Typography
                variant="h2"
                component="h2"
                className={classes.header}
              >
                Sale
              </Typography>
              <ul className={classes.saleDetails}>
                <li>Item title: {selectedSale.item_title || "(None)"}</li>
                {!!selectedSale.sku && <li>SKU: {selectedSale.sku}</li>}
                <li>
                  Sold Date: {formatDate(selectedSale.sale_date, "MM/DD/YYYY")}
                </li>
                <li>Sale Price: {formatDollars(selectedSale.sale_price)}</li>
              </ul>
              <div id="manual-sales-matching-inventory">
                <Typography
                  variant="h2"
                  component="h2"
                  className={classes.header}
                >
                  Inventory
                </Typography>
                <Autocomplete
                  fullWidth
                  autoSelect
                  options={selectableInventory}
                  getOptionLabel={(item) => {
                    const sku = item.sku || "";
                    const title = item.item_title || "";
                    return [sku, title].filter(Boolean).join(" - ");
                  }}
                  getOptionSelected={(opt, v) => {
                    return opt?.id === v?.id;
                  }}
                  value={selectedInventory || null}
                  onChange={(_e, value) => {
                    setSelectedInventory(
                      value
                        ? {
                            ...value,
                            purchase_price_total: value.purchase_price,
                            purchase_price:
                              Math.round(
                                (value.purchase_price * 100) /
                                  (value.quantity || 1)
                              ) / 100,
                          }
                        : value
                    );
                  }}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      label="Item Sold"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              </div>
              {!!selectedInventory && (
                <ul className={classes.saleDetails}>
                  <li>Item title: {selectedInventory.item_title}</li>
                  <li>SKU: {selectedInventory.sku}</li>
                  <li>
                    Purchase Date:{" "}
                    {formatDate(selectedInventory.purchase_date, "MM/DD/YYYY")}
                  </li>
                  <li>
                    <Box display="flex" alignItems="center">
                      Purchase Price:
                      <FormControl
                        size="small"
                        className={classes.purchasePrice}
                        error={!!selectedInventory?.error}
                      >
                        <NumberField
                          Component={Input as any}
                          value={selectedInventory.purchase_price}
                          onChange={(e) => {
                            const value = e.target.value;
                            const total = Object.values(actions).reduce(
                              (total: number, ele: any) => {
                                if (ele?.inventoryId === selectedInventory.id) {
                                  return total + (ele.purchase_price || 0);
                                }
                                return total;
                              },
                              0
                            );
                            setSelectedInventory((i) => {
                              const newInventory = {
                                ...i,
                                purchase_price: value,
                              };

                              if (
                                (newInventory.quantity || 1) > 1 &&
                                total + (value || 0) >
                                  newInventory.purchase_price_total
                              ) {
                                newInventory.error =
                                  "Cannot allocate more than the current inventory total purchase price.";
                              } else {
                                delete newInventory.error;
                              }

                              return newInventory;
                            });
                          }}
                          {...({
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          } as any)}
                        />
                        {selectedInventory?.error ? (
                          <FormHelperText>
                            {selectedInventory.error}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Box>
                  </li>
                  <li>Quantity: {selectedInventory.quantity || 1}</li>
                  <li>
                    Current Total Inventory Value:{" "}
                    {formatDollars(selectedInventory.purchase_price_total || 0)}
                  </li>
                </ul>
              )}
            </div>
          </>
        ),
        actions: (
          <>
            <Typography className={classes.nowrap}>
              Unmatched Sales: {unmatchedSales.length} /{" "}
              {unreviewedSales.length}
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => onDelete(selectedSale.id)}
              className={classes.nowrap}
            >
              Delete Sale
            </Button>
            <Button
              id="manual-sales-matching-add-inventory-button"
              color="primary"
              variant="contained"
              onClick={gotoAddInventory}
              className={classes.nowrap}
            >
              Add Inventory Details
            </Button>
            <Button
              disabled={!selectedInventory || !!selectedInventory.error}
              color="primary"
              variant="contained"
              onClick={save}
              className={classes.nowrap}
            >
              Match
            </Button>
          </>
        ),
        extraActions:
          noAutomaticMatchCount !== unmatchedSales.length ? (
            <Button color="primary" variant="contained" onClick={gotoTable}>
              Back to Automatic Matching
            </Button>
          ) : null,
      })}
    </>
  );
};

export default ManualSalesMatching;
