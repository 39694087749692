import React from "react";

import { Content } from "src/components/AddPoshmarkExtensionDialog";

import DialogTitle from "./DialogTitle";
import type Step from "./Step";

interface PoshmarkAddExtensionProps {
  onClose: () => void;
  onStepChange: (step: Step) => void;
}

function PoshmarkAddExtension({
  onClose,
  onStepChange,
}: PoshmarkAddExtensionProps) {
  return (
    <>
      <DialogTitle onClose={onClose}>Add Extension</DialogTitle>
      <Content />
    </>
  );
}

export default PoshmarkAddExtension;
