import React from "react";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import DialogTitle from "./DialogTitle";
import type Step from "./Step";

interface InventoryImportantTipProps {
  onClose: () => void;
  onStepChange: (step: Step) => void;
}

function InventoryImportantTip({
  onClose,
  onStepChange,
}: InventoryImportantTipProps) {
  return (
    <>
      <DialogTitle onClose={onClose}>Important Tip</DialogTitle>
      <MuiDialogContent>
        <Typography align="justify" display="block" gutterBottom paragraph>
          Inventory in My Reseller Genie is meant to show you a list of what you
          physically have in inventory.
        </Typography>
        <Typography align="justify" display="block" gutterBottom paragraph>
          If you don’t already have a list of inventory, the easiest way to get
          the bulk of your inventory recorded in My Reseller Genie is by
          importing from one of your sales platforms.
        </Typography>
        <Typography align="justify" display="block" gutterBottom paragraph>
          Since most users cross-list inventory, you should only import
          inventory from one platform. It’s best to choose the platform that has
          the greatest amount of your inventory.
        </Typography>
      </MuiDialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => onStepChange("ebay:do-you-sell-on-ebay")}
          color="primary"
          autoFocus
        >
          I understand
        </Button>
      </DialogActions>
    </>
  );
}

export default InventoryImportantTip;
