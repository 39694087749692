import React from "react";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import { useFormik } from "formik";

import Grid from "@material-ui/core/Grid";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import InputAdornment from "@material-ui/core/InputAdornment";

import { NumericOverview } from "src/interfaces/numericOverview.interface";

import NumberField from "src/components/NumberField";
import LoadableButton from "src/components/LoadableButton";
import { updateNumericOverview } from "src/apiService/modules/numericOverview";
import { getNumericOverview } from "src/store/numericOverview/selector";

interface EditDialogProps {
  id?: string;
  onClose: () => void;
}

interface ContentProps {
  initialValues?: NumericOverview;
  onClose: () => void;
}

function Content({ initialValues, onClose }: ContentProps) {
  const formik = useFormik({
    initialValues: initialValues || ({} as NumericOverview),
    validate: (values) => {
      const errors: Record<string, string> = {};
      if (
        values.cash_in_reselling_app_accounts === undefined ||
        values.cash_in_reselling_app_accounts < 0
      ) {
        errors.cash_in_reselling_app_accounts =
          "Must be bigger than or equal to $0";
      }

      if (
        values.estimated_income_tax_liability_paid === undefined ||
        values.estimated_income_tax_liability_paid < 0
      ) {
        errors.estimated_income_tax_liability_paid =
          "Must be bigger than or equal to $0";
      }

      if (
        values.other_business_costs === undefined ||
        values.other_business_costs < 0
      ) {
        errors.other_business_costs = "Must be bigger than or equal to $0";
      }

      if (values.other_COGS === undefined || values.other_COGS < 0) {
        errors.other_COGS = "Must be bigger than or equal to $0";
      }

      if (
        values.sales_tax_payments === undefined ||
        values.sales_tax_payments < 0
      ) {
        errors.sales_tax_payments = "Must be bigger than or equal to $0";
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        if (values.id) await updateNumericOverview(values.id, values);

        toast.success("Saved successfully!");
        onClose();
      } catch (e) {
        toast.error(`There was an error: ${(e as Error).toString()}`);
      }
    },
  });

  const getInputProps = (name: string) => {
    const error = !!(formik.touched[name] && formik.errors[name]);
    return {
      name,
      disabled: formik.isSubmitting,
      value: formik.values[name],
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      ...(error
        ? {
            error,
            helperText: formik.errors[name],
          }
        : {}),
    };
  };

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      <DialogContent>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <NumberField
                {...getInputProps("cash_in_reselling_app_accounts")}
                fullWidth
                variant="outlined"
                margin="dense"
                label="Cash In Reselling App Accounts"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <NumberField
                {...getInputProps("estimated_income_tax_liability_paid")}
                fullWidth
                variant="outlined"
                margin="dense"
                label="Income Tax Payments"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <NumberField
                {...getInputProps("sales_tax_payments")}
                fullWidth
                variant="outlined"
                margin="dense"
                label="Sales Tax Payments"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <LoadableButton
          type="submit"
          color="primary"
          variant="contained"
          loading={formik.isSubmitting}
          disabled={!formik.dirty}
        >
          Update
        </LoadableButton>
      </DialogActions>
    </form>
  );
}

function EditDialog({ id, onClose }: EditDialogProps) {
  const handleClose = () => {
    onClose();
  };
  const open = !!id;
  const initialValues = useSelector((state) =>
    open ? getNumericOverview(state, id as string) : undefined
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"body"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="scroll-dialog-title relative">
        Edit Numeric Overview
      </DialogTitle>
      <Content initialValues={initialValues} onClose={handleClose} />
    </Dialog>
  );
}

export default EditDialog;
