import React from "react";
import MuiDialog from "@material-ui/core/Dialog";

import DialogContent from "./DialogContent";

export interface DialogProps {
  open: boolean;
  onClose: () => void;
}

function Dialog({ open, onClose }) {
  return (
    <MuiDialog
      open={open}
      fullWidth
      maxWidth="md"
      TransitionProps={
        {
          tabIndex: "none",
        } as object
      }
      disableEnforceFocus
    >
      <DialogContent onClose={onClose} />
    </MuiDialog>
  );
}

export default Dialog;
