import React, { type PropsWithChildren, type ReactNode } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Typography, { type TypographyProps } from "@material-ui/core/Typography";

// fix-vim-highlight = }

export interface DialogTitleWithCloseProps {
  onClose: () => void;
  className?: string;
  disableTypography?: boolean;
  extra?: ReactNode | undefined;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
  },
});

export function Title({ children, ...props }: TypographyProps<"h2">) {
  return (
    <Typography variant="h6" component="h2" {...props}>
      {children}
    </Typography>
  );
}

function DialogTitleWithClose({
  onClose,
  children,
  className,
  disableTypography,
  extra,
}: PropsWithChildren<DialogTitleWithCloseProps>) {
  const classes = useStyles();

  return (
    <DialogTitle disableTypography className={clsx(classes.root, className)}>
      <Box flex={1}>
        {disableTypography ? children : <Title>{children}</Title>}
      </Box>
      {extra}
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
}

export default DialogTitleWithClose;
