import { createAction } from "redux-actions";
import {
  RESET,
  FS_CREATE_ITEMS,
  FS_DELETE_ITEMS,
  FS_UPDATE_ITEMS,
  PLAID_INTEGRATIONS,
  UPDATE_ITEM,
} from "../common";

export const reset = createAction(PLAID_INTEGRATIONS + RESET);

export const addPlaidIntegrationRecords = createAction(
  PLAID_INTEGRATIONS + FS_CREATE_ITEMS
);
export const updatePlaidIntegrationRecords = createAction(
  PLAID_INTEGRATIONS + FS_UPDATE_ITEMS
);
export const deletePlaidIntegrationRecords = createAction(
  PLAID_INTEGRATIONS + FS_DELETE_ITEMS
);

export const updatePlaidIntegrationItem = createAction<{
  id: string;
  loading?: string | undefined;
  salesIsRunning?: number | boolean | undefined;
  inventoryIsRunning?: number | boolean | undefined;
}>(PLAID_INTEGRATIONS + UPDATE_ITEM);
