import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import clsx from 'clsx';

import {
  Grid,
  Paper,
  CircularProgress,
} from "@material-ui/core";

import Plot from 'react-plotly.js';

// import {
//   fetchItems as fetchNumericOverviewItems,
// } from "../../store/numericOverview/actions";

// import {
//   fetchItems as fetchInventories,
// } from "../../store/inventory/actions";

// import {
//   fetchItems as fetchSales,
// } from "../../store/sale/actions";

import InfoTooltip from "../../components/InfoTooltip";
import { numericOverviewSelector } from "../../store/numericOverview/selector";
import { inventoriesSelector } from "../../store/inventory/selector";
import { salesSelector, salesSummarySelector } from "../../store/sale/selector";

import { formatDate } from "../../utils"

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import SvgGradientDef, { makeBarFill, makePieFill, GradientsByName } from "src/components/SvgGradientDef";

const profitabilityChartText = "This chart shows your company’s sales alongside the cost of goods sold (all the costs associated with selling your products). Additionally, you can see your gross profit (sales – cost of goods sold) and net profit (gross profit – other business costs – estimated income tax).";
const inventorySummaryChartText = "Shows the value of your current inventory by department.";
const salesSummaryChartText = "Shows your total revenue (sales) by department.";
const assetsToLiabilitiesChartText = "Shows the total cost of your inventory at the end of the month (Total Inventory) alongside how much money your business has (Cash Balance) and how much money your business is estimated to owe (Total Sales Tax Liability and Income Tax Liability Balance).";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartContainer: {
      height: 450,
      padding: 15,
      position: 'relative',
    },
    plot: {
      '& g.cartesianlayer g.plot g.barlayer.mlayer g.trace.bars g.points g.point path': {
        clipPath: 'inset(0% 0% 0% 0% round 8px 8px 0px 0px)',
      },
    },
    profitabilityPlot: makeBarFill([GradientsByName.Green, GradientsByName.Blue]),
    assetsToLiabilitiesPlot: makeBarFill([GradientsByName.Blue, GradientsByName.LightBlue, GradientsByName.Grey, GradientsByName.Green]),
    piePlot: makePieFill([
      GradientsByName.Green,
      GradientsByName.LightGreen,
      GradientsByName.Blue,
      GradientsByName.LightBlue,
      GradientsByName.Grey,
      GradientsByName.LightGrey,
    ]),
    currentInventoryPiePlot: makePieFill([
      GradientsByName.Blue,
      GradientsByName.LightBlue,
      GradientsByName.Grey,
      GradientsByName.LightGrey,
      GradientsByName.Green,
      GradientsByName.LightGreen,
    ]),
  }),
);

const EmptyMessage = () => (
  <div className="w-full flex items-center justify-center h-full">
    Chart will appear once you enter some data into your Genie
  </div>
)

export const GraphContent = () => {
  const classes = useStyles();
  const saleSummaryData = useSelector(salesSummarySelector);

  const {
    items: numericOverviewItems,
    loading: numericOverviewLoading,
    visible: numericOverviewVisable,
  } = useSelector(numericOverviewSelector);

  const {
    items: inventories,
    loading: inventoryLoading,
  } = useSelector(inventoriesSelector);

  const {
    loading: saleLoading,
  } = useSelector(salesSelector);

  const [profitabilityData, setProfitabilityData] = useState<any[]>([]);
  const [assetsData, setAssetsData] = useState<any[]>([]);
  const [inventorySummaryData, setInventorySummaryData] = useState<any[]>([]);
  const [isProfitabilityEmpty, setIsProfitabilityEmpty] = useState<boolean>(false);
  const [isAssetsDataEmpty, setIsAssetsDataEmpty] = useState<boolean>(false);

  useEffect(() => {
    const rows = numericOverviewItems.filter(e => numericOverviewVisable.indexOf(e.id) > -1);
    const x = rows.map(row => formatDate(row.period, "M/YYYY"));
    if (isEmpty(x)) {
      setIsProfitabilityEmpty(true)
      setIsAssetsDataEmpty(true)
    } else {
      setIsProfitabilityEmpty(false)
      setIsAssetsDataEmpty(false)
    }
    setProfitabilityData([
      {
        x,
        y: rows.map(row => row.sales),
        type: 'bar',
        marker: { color: GradientsByName.Green.color },
        name: 'Total Sales',
        hovertemplate: 'Total Sales<br />%{x}: %{y:$,.2f}<extra></extra>',
      },
      {
        x,
        y: rows.map(row => row.COGS),
        type: 'bar',
        marker: { color: GradientsByName.Blue.color },
        name: 'Total COGS',
        hovertemplate: 'Total COGS<br />%{x}: %{y:$,.2f}<extra></extra>',
      },
      {
        x,
        y: rows.map(row => row.gross_profit),
        type: 'scatter',
        mode: 'lines',
        line: { shape: 'spline' },
        marker: { color: 'rgb(215, 212, 219)' },
        name: 'Total Gross Profit',
        hovertemplate: 'Total Gross Profit<br />%{x}: %{y:$,.2f}<extra></extra>',
      },
      {
        x,
        y: rows.map(row => row.net_profit),
        type: 'scatter',
        mode: 'lines',
        line: { shape: 'spline' },
        marker: { color: 'rgb(68, 112, 147)' },
        name: 'Total Net Profit',
        hovertemplate: 'Total Net Profit<br />%{x}: %{y:$,.2f}<extra></extra>',
      },
    ]);
    setAssetsData([
      {
        x,
        y: rows.map(row => row.sales_tax_liability_balance),
        type: 'bar',
        marker: { color: GradientsByName.Blue.color },
        name: 'Total Sales Tax Liability',
        hovertemplate: 'Total Sales Tax Liability<br />%{x}: %{y:$,.2f}<extra></extra>',
      },
      {
        x,
        y: rows.map(row => row.inventory_balance),
        type: 'bar',
        marker: { color: GradientsByName.LightBlue.color },
        name: 'Total Inventory',
        hovertemplate: 'Total Inventory<br />%{x}: %{y:$,.2f}<extra></extra>',
      },
      {
        x,
        y: rows.map(row => row.estimated_income_tax_liability_balance),
        type: 'bar',
        marker: { color: GradientsByName.Grey.color },
        name: 'Income Tax Liability Balance',
        hovertemplate: 'Income Tax Liability Balance<br />%{x}: %{y:$,.2f}<extra></extra>',
      },
      {
        x,
        y: rows.map(row => row.cash_balance),
        type: 'bar',
        marker: { color: GradientsByName.Green.color },
        name: 'Cash Balance',
        hovertemplate: 'Cash Balance<br />%{x}: %{y:$,.2f}<extra></extra>',
      },
    ]);
  }, [numericOverviewVisable, numericOverviewItems]);

  useEffect(() => {
    let departments: string[] = Array.from(new Set(inventories.map(e => e.department || "Uncategorized")));
    let total = 0;
    let otherTotal = 0;
    let ppByDepartments = departments.map(department => {
      const invs = inventories.filter((e) => e.department === department ||
        (department === "Uncategorized" && !e.department));
      let pp = 0;
      invs.forEach(e => {
        pp += e.purchase_price * 1.0;
        total += e.purchase_price * 1.0;
      });
      return pp;
    });
    const otherIndices = new Set<number>([]);
    ppByDepartments.forEach((pp, index) => {
      if (pp <= total * 0.05) {
        otherIndices.add(index);
        otherTotal += pp;
      }
    });
    if (otherIndices.size) {
      ppByDepartments = ppByDepartments.filter((pp, index) => !otherIndices.has(index));
      ppByDepartments.push(otherTotal);
      departments = departments.filter((department, index) => !otherIndices.has(index));
      departments.push("Other");
    }

    const pair = departments.reduce((acc, label, i) => {
      const value = ppByDepartments[i];
      acc.push({ value, label });
      return acc;
    }, [] as { value: number; label: string }[]);

    pair.sort((a, b) => b.value - a.value);
    const chartData = [{
      values: pair.map(p => p.value),
      labels: pair.map(p => p.label),
      type: 'pie',
      hovertemplate: "%{label}<br />%{value:$,.2f}<br />%{percent}<extra></extra>",
      hole: 0.4,
      marker: {
        colors: [
          GradientsByName.Blue.color,
          GradientsByName.LightBlue.color,
          GradientsByName.Grey.color,
          GradientsByName.LightGrey.color,
          GradientsByName.Green.color,
          GradientsByName.LightGreen.color,
        ],
      },
    }];

    setInventorySummaryData(chartData);
  }, [inventories]);

  return (
    <Grid container spacing={2}>
      <SvgGradientDef />
      <Grid item xs={12}>
        <Paper className={classes.chartContainer}>
          <h2 className="w-full text-left text-blue absolute z-10">
            Profitability<InfoTooltip text={profitabilityChartText} />
          </h2>
          {isProfitabilityEmpty ? <EmptyMessage />
            :
          <>
            {numericOverviewLoading ?
              (<div className="w-full flex items-center justify-center h-full">
                <CircularProgress />
              </div>) :
              (<Plot
                className={clsx(classes.plot, classes.profitabilityPlot)}
                data={profitabilityData}
                config={{
                  displaylogo: false,
                  responsive: true,
                }}
                layout={{
                  autosize: true,
                  yaxis: {
                    tickprefix: "$"
                  }
                }}
                style={{ width: '100%', height: '100%' }}
              />)
            }
            </>
          }
        </Paper>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Paper className={classes.chartContainer}>
          <h2 className="w-full text-left text-blue absolute z-10">
            Current Inventory Summary
            <InfoTooltip text={inventorySummaryChartText} />
          </h2>
          {inventoryLoading ?
            <div className="w-full flex items-center justify-center h-full">
              <CircularProgress />
            </div> :
            <Plot
              className={classes.currentInventoryPiePlot}
              data={inventorySummaryData}
              config={{
                displaylogo: false,
                responsive: true,
              }}
              layout={{ autosize: true }}
              style={{ width: '100%', height: '100%' }}
            />
          }
        </Paper>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Paper className={classes.chartContainer}>
          <h2 className="w-full text-left text-blue absolute z-10">
            Total Sales Summary
            <InfoTooltip text={salesSummaryChartText} />
          </h2>
          {saleLoading ?
            <div className="w-full flex items-center justify-center h-full">
              <CircularProgress />
            </div> :
            <Plot
              data={saleSummaryData as any}
              className={classes.piePlot}
              config={{
                displaylogo: false,
                responsive: true,
              }}
              layout={{ autosize: true }}
              style={{ width: '100%', height: '100%' }}
            />
          }
        </Paper>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Paper className={classes.chartContainer}>
          <h2 className="w-full text-left text-blue z-10 absolute">
            Assets To Liabilities
            <InfoTooltip text={assetsToLiabilitiesChartText} />
          </h2>
          {isAssetsDataEmpty ? <EmptyMessage />
            :
            <>
            {numericOverviewLoading ?
              <div className="w-full flex items-center justify-center h-full">
                <CircularProgress />
              </div> :
              <Plot
                className={clsx(classes.plot, classes.assetsToLiabilitiesPlot)}
                data={assetsData}
                config={{
                  displaylogo: false,
                  responsive: true,
                }}
                layout={{
                  autosize: true,
                  yaxis: {
                    tickprefix: "$"
                  }
                }}
                style={{ width: '100%', height: '100%' }}
              />
            }
            </>
          }
        </Paper>
      </Grid>
    </Grid>
  );
};
