import React, { useRef, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";

import { ImportDialogContent } from "./ImportDialog";
import { getAmazonIntegrations } from "src/store/plaidIntegration/selector";
import {
  featureFlagSelector,
  userGetInventoryTypeSelector,
} from "src/store/system/selector";
import { getUploads } from "src/store/uploads/selector";

import DialogTitle from "./DialogTitle";
import type Step from "./Step";

interface AmazonImportProps {
  onClose: () => void;
  onStepChange: (step: Step) => void;
}

function getHasAlreadyImported(
  integration,
  uploads,
  key: "amazonInventory" | "amazonSales"
) {
  const isRunningKey =
    key === "amazonInventory" ? "inventoryIsRunning" : "salesIsRunning";
  if (integration?.[isRunningKey]) return true;
  const t = key === "amazonInventory" ? "inventory" : "sales";
  return uploads.some((upload) => {
    if (integration?.id && upload.integrationId) {
      if (integration.id !== upload.integrationId) return false;
    }

    return t === upload.type && upload.filename?.includes(key);
  });
}

export function useIntegration() {
  const amazonIntegration = useSelector((s) => {
    const integrations = getAmazonIntegrations(s as any);
    if (integrations) {
      let integration = integrations[0];
      for (const i of integrations) {
        if ((i.createdAt || 0) > (integration.createdAt || 0)) integration = i;
      }
      return integration;
    }
  });
  const integrationId = amazonIntegration?.id;
  const uploads = useSelector(getUploads);
  const loading = amazonIntegration?.loading;
  const hasAlreadyImportedInventory =
    getHasAlreadyImported(amazonIntegration, uploads, "amazonInventory") ||
    loading === "Inventory";
  const hasAlreadyImportedSales =
    getHasAlreadyImported(amazonIntegration, uploads, "amazonSales") ||
    loading === "Sales";

  return {
    integrationId,
    hasAlreadyImportedInventory,
    hasAlreadyImportedSales,
  };
}

function AmazonImport({ onClose, onStepChange }: AmazonImportProps) {
  const {
    integrationId,
    hasAlreadyImportedInventory,
    hasAlreadyImportedSales,
  } = useIntegration();
  const inventoryType = useSelector(userGetInventoryTypeSelector);
  const onCloseRef = useRef(onClose);
  onCloseRef.current = onClose;

  const canEtsyIntegration =
    useSelector((state) => featureFlagSelector(state, "etsy")) &&
    process.env.REACT_APP_MODE === "development";

  const [memory] = useState(() => ({
    hasAlreadyImportedInventory,
    hasAlreadyImportedSales,
  }));

  const dialogTitle = useMemo(() => {
    return (props) => (
      <DialogTitle onClose={() => onCloseRef.current()} {...props} />
    );
  }, []);
  return (
    <>
      <ImportDialogContent
        DialogTitle={dialogTitle}
        onClose={() => {
          onStepChange("amazon:import-in-process");
        }}
        providerName="amazon"
        integrationId={integrationId || ""}
        actions={
          <Button
            color="primary"
            onClick={() => {
              const nextStep = [
                canEtsyIntegration && "etsy:do-you-sell-on-etsy",
                "other:do-you-sell",
              ].filter(Boolean)[0];

              onStepChange(nextStep as Step);
            }}
          >
            Continue Set-up
          </Button>
        }
        disableInventory={
          inventoryType === "cash" || memory.hasAlreadyImportedInventory
        }
        disableSales={memory.hasAlreadyImportedSales}
      />
    </>
  );
}

export default AmazonImport;
