import React, { useState, forwardRef, useRef, useEffect } from "react";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import GetAppIcon from "@material-ui/icons/GetApp";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import { colors } from "src/theme";
import { formatDollars } from "src/utils";
import InfoTooltip from "src/components/InfoTooltip";

import StatementDrill, { StatementDrillType } from "./StatementDrill";
import ReportExplanationText from "./ReportExplanationText";
import renderReport from "./renderReport";
import { ProfitLossReport as ProfitLossReportType } from "./report";

const ComparisonTitle = "Profit/Loss Statement Comparison";
const StatementDrillDownTooltipText = "Click to view details";
const StatementDrillDownCompareTooltipText = "Click in number to view details";

type StatementDrillTypeWithReport =
  | (StatementDrillType & { report?: number })
  | null;

function getSortedKeys(objs: Record<string, any>[]): string[] {
  const keys = [
    ...new Set(
      objs.reduce<string[]>((ks, o) => [...ks, ...Object.keys(o)], [])
    ),
  ];
  keys.sort((a, b) => a.localeCompare(b));

  return keys;
}

const useHeaderStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
  },
  reportType: {
    marginRight: theme.spacing(2),
    "& button": {
      lineHeight: 1,
    },
  },
}));

type ProfitLossType = "summary" | "detail";

interface ProfitLossReportHeaderProps {
  profitLossType: ProfitLossType;
  onProfitLossTypeChange: (t: ProfitLossType) => void;
  showEstimatedIncomeTax: boolean;
  onShowEstimatedIncomeTaxChange: (t: boolean) => void;
  downloading?: boolean;
  onDownloadClick?: () => void;
  report: ProfitLossReportType;
}

function ProfitLossReportHeader({
  report,
  profitLossType,
  onProfitLossTypeChange,
  showEstimatedIncomeTax,
  onShowEstimatedIncomeTaxChange,
  downloading,
  onDownloadClick,
}: ProfitLossReportHeaderProps) {
  const classes = useHeaderStyles();
  const isCompare = report.data.length > 1;

  return (
    <Box display="flex" alignItems="center">
      <h2 className={`w-full text-left text-blue z-10 ${classes.title}`}>
        {isCompare ? ComparisonTitle : report.data[0].reportDetails.titleFull}
        <InfoTooltip text={<ReportExplanationText />} />
      </h2>
      <Box display="flex">
        <ToggleButtonGroup
          className={classes.reportType}
          value={profitLossType}
          exclusive
          onChange={(e, value) => {
            onProfitLossTypeChange(value === "summary" ? "summary" : "detail");
          }}
        >
          <ToggleButton value="detail">Detail</ToggleButton>
          <ToggleButton value="summary">Summary</ToggleButton>
        </ToggleButtonGroup>
        <FormControlLabel
          control={
            <Switch
              checked={showEstimatedIncomeTax}
              onChange={(e) => onShowEstimatedIncomeTaxChange(e.target.checked)}
            />
          }
          label="Toggle Est Tax"
        />
        {onDownloadClick && (
          <IconButton onClick={onDownloadClick} disabled={downloading}>
            {downloading ? <CircularProgress size={24} /> : <GetAppIcon />}
          </IconButton>
        )}
      </Box>
    </Box>
  );
}

const useBodyStyles = makeStyles((theme) => ({
  table: {
    margin: theme.spacing(3, "auto", 4),
    maxWidth: 480,
    width: "100%",
    borderSpacing: 0,
    borderCollapse: "collapse",
    "& td:first-child": {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
      paddingRight: theme.spacing(2),
    },
    "& td:last-child": {
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
    "& td": {
      borderRadius: ({ isCompare }: { isCompare: boolean }) =>
        isCompare ? theme.shape.borderRadius : 0,
    },
  },
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  bold: {
    fontWeight: 500,
  },
  detail: {
    paddingLeft: theme.spacing(2),
  },
  divider: {
    borderBottom: "1px solid #404854",
  },
  blue: {
    color: colors.blueColor,
  },
  nowrap: {
    whiteSpace: "nowrap",
  },
  space: {
    padding: theme.spacing(0, 2),
  },
  pointer: {
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  highlightedRow: {
    "& td": {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
  },
  highlightedCell: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
  title: {
    paddingBottom: theme.spacing(2),
  },
}));

interface ProfitLossReportBodyProps {
  report: ProfitLossReportType;
  profitLossType: ProfitLossType;
  onStatementDrillChange: (st: StatementDrillTypeWithReport) => void;
  statementDrill: StatementDrillTypeWithReport;
  showEstimatedIncomeTax: boolean;
}

const ProfitLossReportBody = forwardRef(
  (
    {
      report,
      profitLossType,
      onStatementDrillChange,
      statementDrill,
      showEstimatedIncomeTax,
    }: ProfitLossReportBodyProps,
    ref
  ) => {
    const isCompare = report.data.length > 1;
    const classes = useBodyStyles({ isCompare });
    const showDetail = profitLossType !== "summary";

    return (
      <Box className={classes.table} {...{ ref }} component="table">
        <thead>
          {isCompare && (
            <tr>
              <th />
              {report.data.map((data, i) => (
                <th
                  className={clsx(
                    "text-blue",
                    classes.nowrap,
                    classes.space,
                    classes.title
                  )}
                  key={i}
                >
                  {data.reportDetails.dateRange}
                </th>
              ))}
            </tr>
          )}
          <tr>
            <th className={clsx(classes.bold, classes.left)}>Income</th>
            {report.data.map((data, i) => (
              <th className={clsx(classes.bold, classes.right)} key={i}>
                {formatDollars(data.totalSales)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {showDetail &&
            getSortedKeys(report.data.map((d) => d.salesByDepartment)).map(
              (department) => {
                const selectedRow =
                  statementDrill?.type === "sales" &&
                  statementDrill.platform === department;
                return (
                  <Tooltip
                    key={department}
                    title={
                      isCompare
                        ? StatementDrillDownCompareTooltipText
                        : StatementDrillDownTooltipText
                    }
                    placement="top"
                  >
                    <tr
                      className={clsx(
                        !isCompare && classes.pointer,
                        !isCompare && selectedRow && classes.highlightedRow
                      )}
                      onClick={(e) => {
                        const report = parseInt(
                          (e.target as HTMLDivElement).getAttribute(
                            "data-index"
                          ) || "",
                          10
                        );

                        onStatementDrillChange(
                          selectedRow &&
                            (!isCompare || report === statementDrill?.report)
                            ? null
                            : {
                                type: "sales",
                                platform: department,
                                ...(isNaN(report) ? {} : { report }),
                              }
                        );
                      }}
                    >
                      <td className={classes.detail}>{department}</td>
                      {report.data.map((data, i) => {
                        const value = data.salesByDepartment[department] || 0;
                        const selected =
                          selectedRow && statementDrill?.report === i;
                        return (
                          <td
                            key={`${i}-${value}`}
                            className={clsx(
                              classes.right,
                              isCompare && selected && classes.highlightedCell,
                              isCompare && classes.pointer
                            )}
                            data-index={i}
                          >
                            {formatDollars(value)}
                          </td>
                        );
                      })}
                    </tr>
                  </Tooltip>
                );
              }
            )}
        </tbody>
        <thead>
          <tr>
            <th className={clsx(classes.bold, classes.left, classes.nowrap)}>
              Cost of Goods Sold
            </th>
            {report.data.map((data, i) => (
              <th className={clsx(classes.bold, classes.right)} key={i}>
                {formatDollars(data.totalCOGS)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {showDetail &&
            getSortedKeys(report.data.map((d) => d.COGSDetail)).map(
              (costType) => {
                const selectedRow =
                  statementDrill?.type === "expense" &&
                  statementDrill.name === costType;

                return (
                  <Tooltip
                    key={costType}
                    title={
                      isCompare
                        ? StatementDrillDownCompareTooltipText
                        : StatementDrillDownTooltipText
                    }
                    placement="top"
                  >
                    <tr
                      className={clsx(
                        !isCompare && classes.pointer,
                        !isCompare && selectedRow && classes.highlightedRow
                      )}
                      onClick={(e) => {
                        const report = parseInt(
                          (e.target as HTMLDivElement).getAttribute(
                            "data-index"
                          ) || "",
                          10
                        );

                        onStatementDrillChange(
                          selectedRow &&
                            (!isCompare || report === statementDrill?.report)
                            ? null
                            : {
                                type: "expense",
                                name: costType,
                                ...(isNaN(report) ? {} : { report }),
                              }
                        );
                      }}
                    >
                      <td className={classes.detail}>{costType}</td>
                      {report.data.map((data, i) => {
                        const value = data.COGSDetail[costType] || 0;
                        const selected =
                          selectedRow && statementDrill?.report === i;

                        return (
                          <td
                            key={`${i}-${value}`}
                            className={clsx(
                              classes.right,
                              isCompare && selected && classes.highlightedCell,
                              isCompare && classes.pointer
                            )}
                            data-index={i}
                          >
                            {formatDollars(value)}
                          </td>
                        );
                      })}
                    </tr>
                  </Tooltip>
                );
              }
            )}
          <tr>
            <td className={classes.divider} colSpan={report.data.length + 1} />
          </tr>
        </tbody>
        <thead>
          <tr>
            <th className={clsx(classes.bold, classes.left, classes.nowrap)}>
              Gross Profit
            </th>
            {report.data.map((data, i) => (
              <th className={clsx(classes.bold, classes.right)} key={i}>
                {formatDollars(data.grossProfit)}
              </th>
            ))}
          </tr>
          <tr>
            <th className={clsx(classes.bold, classes.left, classes.nowrap)}>
              Other Business Expenses
            </th>
            {report.data.map((data, i) => (
              <th className={clsx(classes.bold, classes.right)} key={i}>
                {formatDollars(data.totalExpenses)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {showDetail &&
            getSortedKeys(report.data.map((d) => d.expenseDetail)).map(
              (expenseType) => {
                const selectedRow =
                  statementDrill?.type === "transaction" &&
                  statementDrill.name === expenseType;
                return (
                  <Tooltip
                    key={expenseType}
                    title={
                      isCompare
                        ? StatementDrillDownCompareTooltipText
                        : StatementDrillDownTooltipText
                    }
                    placement="top"
                  >
                    <tr
                      className={clsx(
                        !isCompare && classes.pointer,
                        !isCompare && selectedRow && classes.highlightedRow
                      )}
                      onClick={(e) => {
                        const report = parseInt(
                          (e.target as HTMLDivElement).getAttribute(
                            "data-index"
                          ) || "",
                          10
                        );

                        onStatementDrillChange(
                          selectedRow &&
                            (!isCompare || report === statementDrill?.report)
                            ? null
                            : {
                                type: "transaction",
                                name: expenseType,
                                ...(isNaN(report) ? {} : { report }),
                              }
                        );
                      }}
                    >
                      <td className={classes.detail}>{expenseType}</td>
                      {report.data.map((data, i) => {
                        const value = data.expenseDetail[expenseType] || 0;
                        const selected =
                          selectedRow && statementDrill?.report === i;
                        return (
                          <td
                            key={`${i}-${value}`}
                            className={clsx(
                              classes.right,
                              isCompare && selected && classes.highlightedCell,
                              isCompare && classes.pointer
                            )}
                            data-index={i}
                          >
                            {formatDollars(value)}
                          </td>
                        );
                      })}
                    </tr>
                  </Tooltip>
                );
              }
            )}
        </tbody>
        <thead>
          {showEstimatedIncomeTax && (
            <tr>
              <th className={clsx(classes.bold, classes.left, classes.nowrap)}>
                Estimated Income Tax
              </th>
              {report.data.map((data, i) => (
                <th className={clsx(classes.bold, classes.right)} key={i}>
                  {formatDollars(data.estimatedIncomeTax)}
                </th>
              ))}
            </tr>
          )}
        </thead>
        <tbody>
          <tr>
            <td className={classes.divider} colSpan={report.data.length + 1} />
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th className={clsx(classes.left, classes.bold, classes.blue)}>
              Net Profit
            </th>
            {report.data.map((data, i) => (
              <th
                key={i}
                className={clsx(classes.bold, classes.blue, classes.right)}
              >
                {formatDollars(
                  data[
                    showEstimatedIncomeTax
                      ? "netProfit"
                      : "netProfitNoIncomeTax"
                  ]
                )}
              </th>
            ))}
          </tr>
        </tfoot>
      </Box>
    );
  }
);

export interface ProfitLossReportProps {
  report: ProfitLossReportType;
}

function ProfitLossReport({ report }: ProfitLossReportProps) {
  const [profitLossType, setProfitLossType] =
    useState<ProfitLossType>("detail");
  const [showEstimatedIncomeTax, setShowEstimatedIncomeTax] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [statementDrill, setStatementDrill] =
    useState<StatementDrillTypeWithReport>(null);
  const bodyRef = useRef<HTMLElement>(null);
  const isCompare = report.data.length > 1;

  useEffect(() => {
    setStatementDrill(null);
  }, [report]);

  const handleDownloadReport = async () => {
    if (!bodyRef?.current) return;
    const div = document.createElement("div");
    div.innerHTML = renderReport(
      isCompare ? ComparisonTitle : report.data[0].reportDetails.titleFull,
      bodyRef.current.outerHTML,
      !isCompare
    );
    for (const ele of div.querySelectorAll("button, a")) {
      if (ele.parentNode) ele.parentNode.removeChild(ele);
    }
    setDownloading(true);
    const html2pdf = await import("html2pdf.js");
    await html2pdf
      .default()
      .set({
        filename: report.data[0].reportDetails.filename,
      })
      .from(div)
      .save();
    setDownloading(false);
  };

  return (
    <Paper className="mt-5 p-5 h-auto">
      <ProfitLossReportHeader
        report={report}
        profitLossType={profitLossType}
        onProfitLossTypeChange={(t) => {
          setProfitLossType(t);
          setStatementDrill(null);
        }}
        showEstimatedIncomeTax={showEstimatedIncomeTax}
        onShowEstimatedIncomeTaxChange={(t) => setShowEstimatedIncomeTax(t)}
        downloading={downloading}
        onDownloadClick={() => handleDownloadReport()}
      />
      <ProfitLossReportBody
        ref={bodyRef}
        report={report}
        showEstimatedIncomeTax={showEstimatedIncomeTax}
        statementDrill={statementDrill}
        onStatementDrillChange={(st) => {
          if (st && isCompare && !("report" in st)) return;
          setStatementDrill(st);
        }}
        profitLossType={profitLossType}
      />
      <StatementDrill
        data={report.data[statementDrill?.report || 0]}
        type={statementDrill}
      />
    </Paper>
  );
}

export default ProfitLossReport;
