import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";

import InfoIcon from "@material-ui/icons/Info";
import clsx from "clsx";

export const Color = "#184461";

const useStyles = makeStyles((theme) => ({
  regular: {
    color: Color,
  },
  error: {
    color: theme.palette.error.main,
  },
  small: {
    fontSize: 14,
  },
}));

export interface InfoTooltipProps {
  text?: TooltipProps["title"];
  onClick?: IconButtonProps["onClick"];
  size?: "small" | React.CSSProperties["fontSize"];
  className?: string;
  tabIndex?: number;
  color?: "inherit" | "regular" | "error";
}

function InfoTooltip({
  text,
  onClick,
  size,
  className,
  tabIndex = -1,
  color = "regular",
  ...rest
}: InfoTooltipProps & Omit<IconButtonProps, "size" | "color">) {
  const classes = useStyles();
  const props = {
    ...rest,
    onClick,
    className: clsx(
      className,
      color === "regular" && classes.regular,
      color === "error" && classes.error,
      size === "small" && classes.small
    ),
    tabIndex: tabIndex,
    style: {} as React.CSSProperties,
    color: "default" as IconButtonProps["color"],
  };
  if (size && size !== "small") props.style.fontSize = size;
  if (color === "inherit") props.color = "inherit";

  const button = (
    <IconButton {...props}>
      <InfoIcon fontSize="inherit" />
    </IconButton>
  );

  if (text) return <Tooltip title={text}>{button}</Tooltip>;

  return button;
}

export default InfoTooltip;
