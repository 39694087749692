import { toast } from "react-toastify";
import {
  SupportStateUserToken,
  SupportStateUserData,
} from "src/interfaces/supportState.interface";
import {
  supportGenerateToken,
  supportGetUser,
  supportUpdateUser,
  SupportUpdateUser,
} from "src/apiService/modules/support";
import {
  supportGetToken,
  supportGetUser as selectorSupportGetUser,
} from "./selector";

export const SET_SUPPORT_LOGIN_TOKEN = "SET_SUPPORT_LOGIN_TOKEN";
export const SET_SUPPORT_USER_TOKEN = "SET_SUPPORT_USER_TOKEN";
export const SET_SUPPORT_USER_DATA = "SET_SUPPORT_USER_DATA";

interface SetSupportLoginToken {
  type: typeof SET_SUPPORT_LOGIN_TOKEN;
  token: string;
}

interface SetSupportUserToken {
  type: typeof SET_SUPPORT_USER_TOKEN;
  payload: SupportStateUserToken;
}

interface SetSupportUserData {
  type: typeof SET_SUPPORT_USER_DATA;
  payload: SupportStateUserData;
}

export type SupportActionTypes =
  | SetSupportLoginToken
  | SetSupportUserToken
  | SetSupportUserData;

export function setSupportLoginToken(token = ""): SupportActionTypes {
  return {
    type: SET_SUPPORT_LOGIN_TOKEN,
    token,
  };
}

function setUserToken(payload: SupportStateUserToken): SupportActionTypes {
  return {
    type: SET_SUPPORT_USER_TOKEN,
    payload: payload,
  };
}

export function generateUserToken(username: string) {
  return (dispatch, getState) => {
    dispatch(setUserToken({ username, loading: true }));
    const token = supportGetToken(getState());
    supportGenerateToken(token, username)
      .then(({ data }) => {
        dispatch(
          setUserToken({
            username,
            value: data.token,
            exp: data.exp,
          })
        );
      })
      .catch((err) => {
        if (err.code === "failed-precondition")
          dispatch(setSupportLoginToken());

        dispatch(
          setUserToken({
            username,
            error: err.message,
          })
        );

        toast.error(err.message);
      });
  };
}

function setUserData(payload: SupportStateUserData): SupportActionTypes {
  return {
    type: SET_SUPPORT_USER_DATA,
    payload: payload,
  };
}

export function getUser(username: string) {
  return (dispatch, getState) => {
    dispatch(setUserData({ username, loading: true }));
    const token = supportGetToken(getState());
    supportGetUser(token, username)
      .then(({ data }) => {
        dispatch(
          setUserData({
            ...data.data,
            username,
          })
        );
      })
      .catch((err) => {
        if (err.code === "failed-precondition")
          dispatch(setSupportLoginToken());

        dispatch(
          setUserData({
            username,
            error: err.message,
          })
        );

        toast.error(err.message);
      });
  };
}

export function updateUser(username: string, data: SupportUpdateUser) {
  return (dispatch, getState) => {
    const user = selectorSupportGetUser(getState(), username);
    const token = supportGetToken(getState());
    dispatch(setUserData({ ...user, username, loading: true }));
    return supportUpdateUser(token, username, data)
      .then(({ data }) => {
        dispatch(
          setUserData({
            ...data.data,
            username,
          })
        );
      })
      .catch((err) => {
        if (err.code === "failed-precondition")
          dispatch(setSupportLoginToken());

        dispatch(
          setUserData({
            username,
            error: err.message,
          })
        );

        toast.error(err.message);
      });
  };
}
