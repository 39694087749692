import React from "react";
import { toast } from "react-toastify";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";

import { StatefulLoadableButton } from "src/components/LoadableButton";
import { getAmazonOAuthUrl } from "src/apiService/modules/amazon";

import DialogTitle from "./DialogTitle";
import type Step from "./Step";

interface AmazonAddNewAmazonStoreProps {
  onClose: () => void;
  onStepChange: (step: Step) => void;
  previous: Step;
}

function AmazonAddNewAmazonStore({
  onClose,
  onStepChange,
  previous,
}: AmazonAddNewAmazonStoreProps) {
  return (
    <>
      <DialogTitle onClose={onClose}>Add New Amazon Store</DialogTitle>
      <MuiDialogContent>
        <DialogContentText>
          We’re going to connect your Amazon store to My Reseller Genie so we
          can pull in your data. Add a name for the store (all your information
          pulled from this store will have this name attached as the “Sale
          Platform”).
        </DialogContentText>
      </MuiDialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onStepChange("mercari:do-you-sell-on-mercari");
          }}
          color="primary"
          autoFocus
        >
          Go Back
        </Button>
        <StatefulLoadableButton
          variant="contained"
          color="primary"
          onClick={async () => {
            try {
              const {
                data: { url },
              } = await getAmazonOAuthUrl({
                back: "/get-started#amazon:import",
              });
              window.location = url;
            } catch (e) {
              toast.error(`There was an error: ${(e as Error).toString()}`);
            }
          }}
        >
          Connect
        </StatefulLoadableButton>
      </DialogActions>
    </>
  );
}

export default AmazonAddNewAmazonStore;
