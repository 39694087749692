import { handleActions } from "redux-actions";
import { LOG_OUT } from "../system/actions";

import { PlaidIntegrationState } from "../../interfaces/plaidIntegrationState.interface";
import {
  FS_CREATE_ITEMS,
  FS_DELETE_ITEMS,
  FS_UPDATE_ITEMS,
  PLAID_INTEGRATIONS,
  UPDATE_ITEM,
} from "../common";
import produce from "immer";

const initialState: PlaidIntegrationState = {
  items: [],
};

export const plaidIntegrationReducer = handleActions<
  PlaidIntegrationState,
  any
>(
  {
    [PLAID_INTEGRATIONS + FS_CREATE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items.push(...payload);
      }),
    [PLAID_INTEGRATIONS + FS_DELETE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items = state.items.filter(
          (item) => !payload.find((deleted) => deleted.id === item.id)
        );
      }),
    [PLAID_INTEGRATIONS + FS_UPDATE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        for (const newItem of payload) {
          const index = draft.items.findIndex(item => item.id === newItem.id);
          if (index !== -1)
            draft.items[index] = newItem;
        }
      }),
    [PLAID_INTEGRATIONS + UPDATE_ITEM]: (state, { payload }) =>
      produce(state, (draft) => {
        for (const item of draft.items) {
          if (item.id !== payload.id) continue;
          if ("loading" in payload) item.loading = payload.loading;
          if ("salesIsRunning" in payload)
            (item as any).salesIsRunning = payload.salesIsRunning;
          if ("inventoryIsRunning" in payload)
            (item as any).inventoryIsRunning = payload.inventoryIsRunning;
        }
      }),
    [LOG_OUT]: () => initialState,
  },
  initialState
);
