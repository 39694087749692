import React from "react";

import { Content } from "src/components/AddPoshmarkExtensionDialog";

import DialogTitle from "./DialogTitle";
import type Step from "./Step";

interface MercariAddExtensionProps {
  onClose: () => void;
  onStepChange: (step: Step) => void;
  update?: boolean;
}

function MercariAddExtension({
  onClose,
  onStepChange,
  update,
}: MercariAddExtensionProps) {
  return (
    <>
      <DialogTitle onClose={onClose}>Add Extension</DialogTitle>
      <Content platform="Mercari" update={update} />
    </>
  );
}

export default MercariAddExtension;
