import { createAction } from "redux-actions";
import {
  VENDORS,
  FS_CREATE_ITEMS,
  FS_UPDATE_ITEMS,
  FS_DELETE_ITEMS,
} from "../common";

export const addVendors = createAction(VENDORS + FS_CREATE_ITEMS);
export const updateVendors = createAction(VENDORS + FS_UPDATE_ITEMS);
export const deleteVendors = createAction(VENDORS + FS_DELETE_ITEMS);
