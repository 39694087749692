class Cacher<A, R> {
  cache: Map<A, Promise<R>>;

  constructor() {
    this.cache = new Map<A, Promise<R>>();
  }

  run(arg: A, job: (arg: A) => Promise<R>): Promise<R> {
    if (this.cache.has(arg)) {
      const value = this.cache.get(arg);
      if (value !== undefined) return value;
    }

    const ret = new Promise<R>(async (rs, rj) => {
      try {
        rs(await job(arg));
      } catch (e) {
        rj(e);
      }
    });
    this.cache.set(arg, ret);

    return ret;
  }
}

export default Cacher;
