import produce from "immer";
import { handleActions } from "redux-actions";
import { LOG_OUT as LogOut } from "../system/actions";

import { UpdateSyncStatus } from "./actions";
import type { IntegrationSyncState, SyncStatusAction } from "./types";

const InitialState: IntegrationSyncState = {};

export const integrationSyncReducer = handleActions<IntegrationSyncState, any>(
  {
    [UpdateSyncStatus]: (state, { payload }: { payload: SyncStatusAction }) => {
      return produce(state, (draft) => {
        if (payload.status) draft[payload.integration] = payload.status;
        else delete draft[payload.integration];
      });
    },
    [LogOut]: () => InitialState,
  },
  InitialState
);
