import isEqual from "lodash/isEqual";
import { createAction } from "redux-actions";
import type { GridFilterModel } from "@material-ui/x-grid";
import { 
  SALE,
  RETURN_SALE,
  BUNDLED_SALES,
  START,
  FETCH_ITEMS,
  DELETE_ITEMS,
  FETCH_ITEM,
  CREATE_ITEM,
  DELETE_ITEM,
  UPDATE_ITEM,
  MOVE_ITEM,
  RESET,
  SELECT_ITEMS,
  SET_FILTER,
  FS_CREATE_ITEMS,
  FS_DELETE_ITEMS,
  FS_UPDATE_ITEMS,
} from "../common";

import { salesTimeFilterSelector } from './selector';

export const SET_TIME_FILTER = "SET_TIME_FILTER";
export const SET_PENDING_UPLOADS = "SET_PENDING_UPLOADS";
export const UPDATE_PENDING_UPLOAD = "UPDATE_PENDING_UPLOAD";
export const SHIFT_PENDING_UPLOAD = "SHIFT_PENDING_UPLOAD";
export const SET_UPLOAD_FILENAME = "SET_UPLOAD_FILENAME";
export const LOAD_SYNC_SALES = "LOAD_SYNC_SALES";
export const SET_UPLOADS_FROM_EBAY = "SET_UPLOADS_FROM_EBAY";
export const SET_EBAY_EXTRA_ROWS = "SET_EBAY_EXTRA_ROWS";

export const fetchItems = createAction(SALE + FETCH_ITEMS + START);
export const deleteItems = createAction(SALE + DELETE_ITEMS + START);

export const fetchItem = createAction(SALE + FETCH_ITEM + START);
export const createItem = createAction(SALE + CREATE_ITEM + START);
export const addBundledSales = createAction(BUNDLED_SALES + CREATE_ITEM + START);
export const deleteItem = createAction(SALE + DELETE_ITEM + START);
export const deleteBundledItems = createAction(BUNDLED_SALES + DELETE_ITEM + START);
export const updateItem = createAction(SALE + UPDATE_ITEM + START);
export const updateBundledItems = createAction(BUNDLED_SALES + UPDATE_ITEM + START);
export const moveItem = createAction(SALE + MOVE_ITEM + START);
export const moveBundledItems = createAction(BUNDLED_SALES + MOVE_ITEM + START);
export const recordReturn = createAction(RETURN_SALE + CREATE_ITEM + START);
export const setPendingUploads = createAction(SET_PENDING_UPLOADS);
export const updatePendingUpload = createAction(UPDATE_PENDING_UPLOAD);
export const setUploadFilename = createAction(SET_UPLOAD_FILENAME);
export const shiftPendingUpload = createAction(SHIFT_PENDING_UPLOAD);
export const loadNewSyncSales = createAction(LOAD_SYNC_SALES + START);
export const setUploadsFromEbay = createAction(SET_UPLOADS_FROM_EBAY);
export const setEbayExtraRows = createAction(SET_EBAY_EXTRA_ROWS);

export const reset = createAction(SALE + RESET);

export const selectItems = createAction(SALE + SELECT_ITEMS);
export const setFilter = createAction<GridFilterModel>(SALE + SET_FILTER);

export const setTimeFilter = createAction(SALE + SET_TIME_FILTER);
export function setTimeFilterIfNecessary(filter) {
  return (dispatch, getState) => {
    const timeFilter = salesTimeFilterSelector(getState());
    if (!isEqual(filter, timeFilter)) return dispatch(setTimeFilter(filter));
  };
}

export const addSalesRecords = createAction(SALE + FS_CREATE_ITEMS);
export const updateSalesRecords = createAction(SALE + FS_UPDATE_ITEMS);
export const deleteSalesRecords = createAction(SALE + FS_DELETE_ITEMS);
