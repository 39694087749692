import { createAction } from "redux-actions";
import { PLATFORM, START, FETCH_ITEMS, RESET } from "../common";
import { platformSelector } from "./selector";

export const fetchItems = createAction(PLATFORM + FETCH_ITEMS + START);

export function fetchItemsIfNeeded() {
  return (dispatch, getState) => {
    const state = getState();
    const { items } = platformSelector(state);
    if (!items || !items.length) dispatch(fetchItems());
  };
}

export const reset = createAction(PLATFORM + RESET);
