import React, { useEffect, useState } from "react";
import { capitalize } from "lodash";
import { useDispatch /*, useSelector */ } from "react-redux";
import { PlaidLinkOptions, usePlaidLink } from "react-plaid-link";

import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { setActiveDialog } from "src/store/adminHtml/actions";
import {
  fetchPlaidUpdateModeLinkToken,
  transferPlaidPublicToken,
} from "src/apiService/modules/plaid";

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    plaidButton: {
      position: "relative",
      color: "transparent",
    },
    plaidSpinner: {
      color: "white",
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: "-7px",
      marginLeft: "-7px",
    },
    clearDateButton: {
      alignSelf: "center",
    },
  })
);

const BaseConfig = {
  onSuccess: () => {},
  onExit: () => {},
  token: "",
};

function PlaidButton({
  config,
  ...props
}: { config: PlaidLinkOptions } & ButtonProps) {
  const { open: openPlaid } = usePlaidLink(config);

  const openPlaidLink = () => {
    openPlaid();
  };

  return (
    <Button
      onClick={openPlaidLink}
      color="primary"
      variant="contained"
      {...props}
    />
  );
}

function UpdateIntegrationAccounthDialogContent() {
  const [loading, setLoading] = useState(false);
  // const [token, setToken] = useState(null);
  // const [integrationId, setIntegrationId] = useState(null);
  const [config, setConfig] = useState<PlaidLinkOptions>();
  const classes = useStyles();
  const text = `Reconnect ${capitalize(
    localStorage.getItem("type") as string
  )} Account`;

  useEffect(() => {
    const _type = localStorage.getItem("type");
    fetchPlaidUpdateModeLinkToken().then((data) => {
      // setToken(data?.link_token);
      // setIntegrationId(data?.integration_id);
      localStorage.setItem("link_token", data?.link_token);
      localStorage.setItem("integration_id", data?.integration_id);
      const config: PlaidLinkOptions = {
        ...BaseConfig,
        onSuccess: (public_token, _) => {
          setLoading(true);
          transferPlaidPublicToken(
            public_token,
            _type as ("cash" | "expense"),
            data?.integration_id
          ).then((result) => {
            setLoading(false);
            localStorage.removeItem("integration_id"); // remove the integration id to clear space for future/other integrations
          });
        },
        onExit: (err, _) => {
          console.log(err);
          if (err) {
            console.log(`Plaid encounters an error: ${err}`);
          }
        },
        token: data?.link_token,
      };
      setConfig(config);
    });
  }, []);

  return (
    <DialogContent style={{ paddingBottom: "30px" }}>
      {loading || !config ? (
        <Button
          color="primary"
          variant="contained"
          className={classes.plaidButton}
        >
          <CircularProgress size={14} className={classes.plaidSpinner} />
          {text}
        </Button>
      ) : (
        <PlaidButton config={config}>{text}</PlaidButton>
      )}
    </DialogContent>
  );
}

const UpdateIntegrationAccounthDialog = ({ open }: any) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setActiveDialog(""));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={true}
      maxWidth={"sm"}
      TransitionProps={{
        tabIndex: 'none'
      } as object}
    >
      <DialogTitle className="relative">Reconnect Account</DialogTitle>
      <UpdateIntegrationAccounthDialogContent />
    </Dialog>
  );
};

export default UpdateIntegrationAccounthDialog;
