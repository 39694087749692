import React from "react";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";

function ConditionalTooltip({
  condition,
  children,
  ...props
}: { condition: boolean } & TooltipProps) {
  if (condition) return <Tooltip {...props}>{children}</Tooltip>;
  return children;
}

export default ConditionalTooltip;
