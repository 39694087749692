import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import { requestNOUpdate } from "src/apiService";

import {
  setEbayExtraRows,
  setPendingUploads,
  updatePendingUpload,
} from "src/store/sale/actions";
import {
  getMatchableInventory,
  /* getPendingSalesUploads, */
  getUnmatchedUploads,
} from "src/store/sale/selector";
import { setActiveDialog } from "src/store/adminHtml/actions";

import AddMatchingInventory from "./AddMatchingInventory";
import CompleteUpload from "./CompleteUpload";
import ManualSalesMatching from "./ManualSalesMatching";
import SalesMatchingTable from "./SalesMatchingTable";
import { MatchedSale } from "./interface";

const SalesMatchingDialog = ({ open }: any) => {
  const dispatch = useDispatch();

  const unmatchedSales: any[] = useSelector(getUnmatchedUploads);
  const selectableInventory = useSelector(getMatchableInventory);
  // const totalUploads = useSelector(getPendingSalesUploads);

  const [step, setStep] = useState(0);
  const [selectedIndices, setSelectedIndices] = useState<Set<number>>(
    new Set([])
  );
  const selectedSale = unmatchedSales.length ? unmatchedSales[0] : null;
  const cleanUp = (open: boolean) => {
    if (!open) {
      setStep(0);
      setSelectedIndices(new Set([]));
      dispatch(setPendingUploads([]));
      dispatch(setEbayExtraRows({ extraRows: [], returns: [] }));
    }
  };

  const handleClose = () => {
    dispatch(setActiveDialog(""));
    cleanUp(false);
  };

  // In case someone closes the dialog dispatching "setActiveDialog", we need to clean things up
  // This is a problem caused by how this component was built. Generarly is preferable to separate
  // all this state into a "Content" component, that gets mount only when the dialog is opened.
  useEffect(() => {
    cleanUp(open);
  }, [open]);

  const toggleSelectedIndex = (index) =>
    setSelectedIndices((existing) => {
      const newSet = new Set(existing);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });

  const {
    inventoryMatches,
    automaticMatches,
    inventoryMatchesByItemTitle,
    inventoryMatchesByItemSKU,
  } = useMemo(() => {
    // console.log(totalUploads);
    // const result: any[] = Array(unmatchedSales.length);
    const inventoryMatches: MatchedSale[] = [];
    const inventoryMatchesByItemTitle: MatchedSale[] = [];
    const inventoryMatchesByItemSKU: MatchedSale[] = [];
    const availableInventories = new Set(selectableInventory);
    const inventoryQuantity: Record<string, number> = {};

    for (const sale of unmatchedSales) {
      const inventoryArray = Array.from(availableInventories);
      let matchedInventory = inventoryArray.find(
        (inventory) => inventory.item_title === sale.item_title
      );
      let inventoryMatchesByType = inventoryMatchesByItemTitle;

      if (!matchedInventory) {
        matchedInventory = inventoryArray.find(
          (inventory) => !!sale.sku && `${inventory.sku}` === `${sale.sku}`
        );
        inventoryMatchesByType = inventoryMatchesByItemSKU;
      }

      if (matchedInventory) {
        const currentQuantity =
          matchedInventory.id in inventoryQuantity
            ? inventoryQuantity[matchedInventory.id]
            : matchedInventory.quantity || 1;

        if (currentQuantity <= 1) availableInventories.delete(matchedInventory);
        inventoryQuantity[matchedInventory.id] = currentQuantity - 1;

        const matchedSale = { sale, inventory: matchedInventory };
        inventoryMatches.push(matchedSale);
        inventoryMatchesByType.push(matchedSale);
      }
    }

    const automaticMatches = inventoryMatches
      .map((item, index) => !!item)
      .filter((item) => !!item);

    return {
      inventoryMatches,
      automaticMatches,
      inventoryMatchesByItemTitle,
      inventoryMatchesByItemSKU,
    };
  }, [unmatchedSales, selectableInventory]);

  const noAutomaticMatchCount = unmatchedSales.length - automaticMatches.length;

  const toggleSelectAll = (clear: boolean, matches: MatchedSale[]) =>
    setSelectedIndices((existing) => {
      if (!clear && !existing.size) {
        return new Set(
          matches
            .map((item, index) => (!!item ? index : -1))
            .filter((item) => item !== -1)
        );
      }
      return new Set([]);
    });

  const gotoTable = () => setStep(0);
  const gotoManualMatch = () => setStep(1);
  const gotoAddInventory = () => setStep(2);

  const returnToTable = () => {
    setSelectedIndices(new Set([]));
    setStep(0);
  };

  const saveManualMatch = (inventory) => {
    if (!selectedSale) {
      return returnToTable();
    }
    const update = {
      ...selectedSale,
      ...inventory,
      unmatched: false,
      inventoryId: inventory.id,
    };
    Object.keys(update).forEach((key) => {
      if (key === undefined) {
        console.log("Undefined key", { key, selectedSale, inventory });
      }
    });
    dispatch(updatePendingUpload(update));
    requestNOUpdate();
  };

  const saveAllPendingSales = () => {
    const now = new Date();
    for (const unmatched of unmatchedSales) {
      dispatch(
        updatePendingUpload({
          ...unmatched,
          purchase_date: now,
          purchase_price: 0,
          unmatched: false,
        })
      );
    }
    requestNOUpdate();
  };

  const render = ({ main, actions, extraActions, title }) => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"body"}
        aria-labelledby="sales-matching"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
        maxWidth={unmatchedSales.length ? "md" : "sm"}
      >
        <DialogTitle id="sales-matching relative">{title}</DialogTitle>
        <DialogContent>{main}</DialogContent>
        <DialogActions className="px-7 py-5">{actions}</DialogActions>
        {!!extraActions && (
          <DialogActions className="px-7 pt-0 pb-5">
            {extraActions}
          </DialogActions>
        )}
      </Dialog>
    );
  };

  if (!unmatchedSales.length) {
    return <CompleteUpload render={render} />;
  }

  switch (step) {
    case 2:
      return (
        <AddMatchingInventory
          saleValues={selectedSale}
          render={render}
          goBack={gotoManualMatch}
          onSave={saveManualMatch}
        />
      );
    case 1:
      return (
        <ManualSalesMatching
          render={render}
          onSave={saveManualMatch}
          gotoAddInventory={gotoAddInventory}
          gotoTable={gotoTable}
          noAutomaticMatchCount={noAutomaticMatchCount}
          selectableInventory={selectableInventory}
          inventoryMatches={inventoryMatches}
          onSaveAll={saveAllPendingSales}
        />
      );
    case 0:
    default:
      return (
        <SalesMatchingTable
          render={render}
          gotoManualMatch={gotoManualMatch}
          inventoryMatches={inventoryMatches}
          unmatchedSales={unmatchedSales}
          automaticMatches={automaticMatches}
          noAutomaticMatchCount={noAutomaticMatchCount}
          selectedIndices={selectedIndices}
          toggleSelectAll={toggleSelectAll}
          toggleSelectedIndex={toggleSelectedIndex}
          inventoryMatchesByItemTitle={inventoryMatchesByItemTitle}
          inventoryMatchesByItemSKU={inventoryMatchesByItemSKU}
        />
      );
  }
};

export default SalesMatchingDialog;
