import firebase from "firebase/app";
import "firebase/functions";
import axiosClient from "../axiosClient";
import { BankStatement } from "../../interfaces";
import { formatDate } from "../../utils";


export const fetchBankStatementsApi = async (payload: any) => {
  const { data } = await axiosClient.get(`bankstatements?&limit=0`);
  return data;
};

export const uploadBankStatementsApi = async (payload: any) => { // TODO: type
  const { data } = await axiosClient.post("bankstatements", payload);
  return data;
};

export const deleteBankStatementsApi = async () => { // TODO: type
  const { data } = await axiosClient.delete("bankstatements");
  return data;
};

export const addBankStatementApi = async (payload: BankStatement) => {
  const { data } = await axiosClient.post(`bankstatement`, {
    bank_statement_date: payload.bank_statement_date ? formatDate(payload.bank_statement_date, 'YYYY-MM-DD') : undefined,
    bank_statement_name: payload.bank_statement_name,
    bank_statement_total: payload.bank_statement_total * 1,
  });
  return data;
};

export const updateBankStatementApi = async (payload: BankStatement) => {
  const { data } = await axiosClient.put(`bankstatement`, {
    id: payload.id,
    bank_statement_date: payload.bank_statement_date ? formatDate(payload.bank_statement_date, 'YYYY-MM-DD') : undefined,
    bank_statement_name: payload.bank_statement_name,
    bank_statement_total: payload.bank_statement_total,
  });
  return data;
};

export const updateBulkBankStatementsApi = async (payload: BankStatement[]) => {
  const { data } = await axiosClient.put(`bulk_bankstatements`, { items: payload.map((item) => ({
    id: item.id,
    bank_statement_date: item.bank_statement_date ? formatDate(item.bank_statement_date, 'YYYY-MM-DD') : undefined,
    bank_statement_name: item.bank_statement_name,
    bank_statement_total: item.bank_statement_total,
    reconciled: item.reconciled,
    reconciliation_amount: item.reconciliation_amount,
  })) });
  return data;
};

export const deleteBankStatementApi = async (payload: string) => {
  const { data } = await axiosClient.delete(`bankstatement?&id=${payload}`);
  return data;
};

export async function bankStatementDeleteLast() {
  const f = firebase
    .functions()
    .httpsCallable("bankStatementDeleteLast");
  const { data } = await f();
  return data;
}
