import React, { useState } from "react";
import { toast } from "react-toastify";
import firebase from "firebase/app";
import moment from "moment";

import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { Sale } from "src/interfaces/sale.interface";
import { Inventory } from "src/interfaces/inventory.interface";
import { StatefulLoadableButton } from "src/components/LoadableButton";
import { formatDollars } from "src/utils/formatter";
import { colors } from "src/theme";
import { requestNOUpdate } from "src/apiService";
import firestore from "src/apiService/firestore";

import WalkThrough from "./WalkThrough";

function valueGenerator<T>(value: T): T | null {
  if (typeof value != "undefined" && value != null) return value;
  else return null;
}

const useStyles = makeStyles((theme) => ({
  list: {
    color: theme.palette.text.primary,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    color: colors.blueColor,
  },
  radioGroup: {
    margin: theme.spacing(0, 3, 2, 3),
    "&:last-child": {
      marginBottom: 0,
    },
  },
  actions: {
    margin: theme.spacing(0, 1, 1, 0),
  },
  questions: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(1, 0),
  },
  formControl: {
    marginTop: theme.spacing(-0.5),
    marginBottom: theme.spacing(-0.5),
  },
}));

function ConfirmReturnDetails({
  onClose,
  unreviewedReturn,
  unreviewedReturns,
  onGoToSale,
  onSave,
}: {
  onClose: () => void;
  unreviewedReturn: Sale;
  unreviewedReturns?: number;
  onGoToSale: (ret: Sale) => void;
  onSave?: () => void;
}) {
  const classes = useStyles();
  const [firstQuestion, setFirstQuestion] = useState<string | null>(null);
  const [secondQuestion, setSecondQuestion] = useState<string | null>(null);

  const handleReviewReturn = async (returnItem) => {
    try {
      if (secondQuestion === "1") {
        await firestore().runTransaction(async (transaction) => {
          const updatedReturn: Partial<Sale> = {
            unreviewed: false,
            purchase_date: returnItem.return_date,
          };
          const inventory: Partial<Inventory> = {
            brand: valueGenerator(returnItem.brand),
            item_option: null,
            department: valueGenerator(returnItem.department),
            category: valueGenerator(returnItem.category),
            sub_category: valueGenerator(returnItem.sub_category),
            item_title: valueGenerator(returnItem.item_title),
            list_date: valueGenerator(returnItem.list_date),
            location: valueGenerator(returnItem.location),
            notes: valueGenerator(returnItem.notes),
            platforms_listed: valueGenerator(returnItem.platforms_listed),
            purchase_date: returnItem.return_date,
            sku: valueGenerator(returnItem.sku),
            sale_state: valueGenerator(returnItem.sale_state),
            purchase_price: 0,
            user: returnItem.user,
          };

          if (returnItem.original_id) {
            let sale: firebase.firestore.DocumentSnapshot | null = null;

            try {
              sale = await transaction.get(
                firestore().collection("Sales").doc(returnItem.original_id)
              );
            } catch (e) {
              console.warn("Original sale doesnt exist", e);
            }

            if (sale?.exists) {
              updatedReturn.purchase_price = -(
                sale.data()?.purchase_price || 0
              );
              inventory.purchase_price = sale.data()?.purchase_price || 0;

              updatedReturn.item_title = valueGenerator(
                sale.data()?.item_title
              );
              inventory.item_title = valueGenerator(sale.data()?.item_title);

              updatedReturn.department = valueGenerator(
                sale.data()?.department
              );
              inventory.department = valueGenerator(sale.data()?.department);

              updatedReturn.category = valueGenerator(sale.data()?.category);
              inventory.category = valueGenerator(sale.data()?.category);

              updatedReturn.sub_category = valueGenerator(
                sale.data()?.sub_category
              );
              inventory.sub_category = valueGenerator(
                sale.data()?.sub_category
              );

              updatedReturn.brand = valueGenerator(sale.data()?.brand);
              inventory.brand = valueGenerator(sale.data()?.brand);

              updatedReturn.location = valueGenerator(sale.data()?.location);
              inventory.location = valueGenerator(sale.data()?.location);

              updatedReturn.sku = valueGenerator(sale.data()?.sku);
              inventory.sku = valueGenerator(sale.data()?.sku);

              updatedReturn.platforms_listed = valueGenerator(
                sale.data()?.platforms_listed
              );
              inventory.platforms_listed = valueGenerator(
                sale.data()?.platforms_listed
              );

              updatedReturn.notes = valueGenerator(sale.data()?.notes);
              inventory.notes = valueGenerator(sale.data()?.notes);

              updatedReturn.sale_state = valueGenerator(
                sale.data()?.sale_state
              );
              inventory.sale_state = valueGenerator(sale.data()?.sale_state);

              updatedReturn.list_date = valueGenerator(sale.data()?.list_date);
              inventory.list_date = valueGenerator(sale.data()?.list_date);

              if (sale.data()?.vendor) {
                updatedReturn.vendor = sale.data()?.vendor;
                inventory.vendor = sale.data()?.vendor;
              }

              if (!sale.data()?.return_id)
                transaction.update(sale.ref, { return_id: returnItem.id });
            }
          }

          transaction.update(
            firestore().collection("Sales").doc(returnItem.id),
            updatedReturn
          );

          const inventoryDoc = firestore().collection("Inventory").doc();
          transaction.set(inventoryDoc, {
            ...inventory,
            id: inventoryDoc.id,
          });
        });
      } else {
        await firestore().runTransaction(async (transaction) => {
          const updatedReturn: Partial<Sale> = {
            unreviewed: false,
            purchase_price: 0,
            purchase_date: returnItem.return_date,
          };

          if (returnItem.original_id) {
            let sale: firebase.firestore.DocumentSnapshot | null = null;

            try {
              sale = await transaction.get(
                firestore().collection("Sales").doc(returnItem.original_id)
              );
            } catch (e) {
              console.warn("Original sale doesnt exist", e);
            }

            if (sale?.exists) {
              updatedReturn.item_title = valueGenerator(
                sale.data()?.item_title
              );
              updatedReturn.department = valueGenerator(
                sale.data()?.department
              );
              updatedReturn.category = valueGenerator(sale.data()?.category);
              updatedReturn.category = valueGenerator(sale.data()?.category);
              updatedReturn.sub_category = valueGenerator(
                sale.data()?.sub_category
              );
              updatedReturn.brand = valueGenerator(sale.data()?.brand);
              updatedReturn.location = valueGenerator(sale.data()?.location);
              updatedReturn.sku = valueGenerator(sale.data()?.sku);
              updatedReturn.platforms_listed = valueGenerator(
                sale.data()?.platforms_listed
              );
              updatedReturn.notes = valueGenerator(sale.data()?.notes);
              updatedReturn.sale_state = valueGenerator(
                sale.data()?.sale_state
              );
              updatedReturn.list_date = valueGenerator(sale.data()?.list_date);
              if (sale.data()?.vendor)
                updatedReturn.vendor = sale.data()?.vendor;

              if (!sale.data()?.return_id)
                transaction.update(sale.ref, { return_id: returnItem.id });
            }
          }

          transaction.update(
            firestore().collection("Sales").doc(returnItem.id),
            updatedReturn
          );
        });
      }

      await requestNOUpdate();

      setFirstQuestion(null);
      setSecondQuestion(null);
      if (onSave) onSave();
    } catch (error) {
      toast.error(`There was an error: ${(error as Error).toString()}`);
    }
  };

  return (
    <>
      <WalkThrough />
      <DialogTitle>Confirm Refund Details</DialogTitle>
      <DialogContent>
        <Typography variant="h2" component="h2" className={classes.title}>
          Information for Refunded Sale
        </Typography>
        <ul className={classes.list}>
          <li>Item Title: {unreviewedReturn.item_title}</li>
          <li>
            Original Sale Date:{" "}
            {moment(unreviewedReturn.sale_date).format("YYYY-MM-DD")}
          </li>
          <li>Refund Amount: {formatDollars(unreviewedReturn.sale_price)}</li>
          <li>
            Refund Date:{" "}
            {moment(unreviewedReturn.return_date).format("YYYY-MM-DD")}
          </li>
        </ul>
        <div className={classes.questions}>
          <Box id="review-returns-dialog-updated-sale-question" mb={1}>
            <Typography variant="body1">
              Have you updated the sale this refund is for completely? It’s
              important to finish this step before finalizing refund.
            </Typography>
            <RadioGroup
              className={classes.radioGroup}
              value={firstQuestion}
              onChange={(e) => setFirstQuestion(e.target.value)}
            >
              <FormControlLabel
                className={classes.formControl}
                value="1"
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                className={classes.formControl}
                value="0"
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </Box>
          <div id="review-returns-dialog-received-item-question">
            <Typography variant="body1">
              Did you receive this item back?
            </Typography>
            <RadioGroup
              className={classes.radioGroup}
              value={secondQuestion}
              onChange={(e) => setSecondQuestion(e.target.value)}
            >
              <FormControlLabel
                className={classes.formControl}
                value="1"
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                className={classes.formControl}
                value="0"
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </div>
        </div>
        {!!unreviewedReturns && (
          <Typography className="mt-4">
            {unreviewedReturns} Refund{unreviewedReturns > 1 ? "s" : ""} to
            Review
          </Typography>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          disabled={!unreviewedReturn.original_id}
          onClick={() => {
            if (unreviewedReturn.original_id) onGoToSale(unreviewedReturn);
          }}
        >
          Go To Sale
        </Button>
        <StatefulLoadableButton
          disabled={firstQuestion !== "1" || !secondQuestion}
          color="primary"
          variant="contained"
          onClick={() => handleReviewReturn(unreviewedReturn)}
          id="review-returns-dialog-confirm-button"
        >
          Confirm
        </StatefulLoadableButton>
      </DialogActions>
    </>
  );
}

export default ConfirmReturnDetails;
