import React from "react";
import { useDispatch } from "react-redux";

import { EditDialog } from "src/pages/TransactionPage/EditDialog";
import { setActiveDialog } from "src/store/adminHtml/actions";

function AddExpenseDialog({ open }: { open: boolean }) {
  const dispatch = useDispatch();
  const handleClose = () => dispatch(setActiveDialog(""));

  return <EditDialog open={open} onClose={handleClose} onDelete={() => {}} />;
}

export default AddExpenseDialog;
