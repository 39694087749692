import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveDialog } from "../../store/adminHtml/actions";
import {
  setCashActivitysReviewed,
  updateCashActivityType,
} from "../../apiService";
import { getUnreviewedCashActivities } from "../../store/cashActivity/selector";
import {
  GridRowId,
  XGrid,
  GridCellEditCommitParams,
} from "@material-ui/x-grid";
import { getItems } from "../../store/bankStatement/selector";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import moment from "moment/moment";

import LoadableButton from "src/components/LoadableButton";
import { upsertCashActivity, updateCashActivityDescription } from "src/apiService/modules/cashActivity";

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    activityType: {
      width: 260,
    },
    bankStatement: {
      width: 260,
    },
  })
);

function Content({ onClose }: { onClose: () => void }) {
  const onCloseRef = useRef(onClose);
  onCloseRef.current = onClose;
  const classes = useStyles();
  const unreviewedCashActivities = useSelector(getUnreviewedCashActivities);
  const bankStatements = useSelector(getItems);
  const [loading, setLoading] = useState(false);
  const [selectedCashActivities, setSelectedCashActivities] = useState<
    GridRowId[]
  >([]);

  useEffect(() => {
    if (unreviewedCashActivities.length === 0) onCloseRef.current();
  }, [unreviewedCashActivities.length]);

  const toggleRow = (transactionId) => {
    const spot = selectedCashActivities.indexOf(transactionId);
    if (spot === -1) {
      setSelectedCashActivities((oldArray) => [...oldArray, transactionId]);
    } else {
      const newSelected = [...selectedCashActivities];
      newSelected.splice(spot, 1);
      setSelectedCashActivities(newSelected);
    }
  };

  const reviewSelected = () => {
    setLoading(true);
    setCashActivitysReviewed(selectedCashActivities).then((_) => {
      setLoading(false);
    });
    setSelectedCashActivities([]);
  };

  const handleCellCommit = async (params: GridCellEditCommitParams) => {
    if (params.field === "description")
      await updateCashActivityDescription(params.id, String(params.value || ""));
  };

  const handleActivityTypeChange = async (event, transactionId) => {
    event.stopPropagation();
    const newType = event.target.value;
    await updateCashActivityType(transactionId, newType);
  };

  const handleBankStatementChange = async (event, transactionId) => {
    event.stopPropagation();
    const newStatementId = event.target.value;
    await upsertCashActivity({ id: transactionId, bank_statement_id: newStatementId });
  };

  const makeSelection = () => {
    if (selectedCashActivities.length > 0) {
      setSelectedCashActivities([]);
    } else {
      unreviewedCashActivities.forEach((transaction) => {
        toggleRow(transaction.id);
      });
    }
  };

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 110,
      editable: false,
      sortable: true,
      valueFormatter: (params) => {
        const valueFormatted = moment(params.value).format("YYYY-MM-DD");
        return `${valueFormatted} `;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 140,
      editable: false,
      sortable: true,
    },
    {
      field: "name",
      headerName: "Charge Description",
      width: 260,
      editable: false,
      sortable: true,
    },
    {
      field: "activity_type",
      headerName: "Type of Activity",
      width: 260,
      editable: false,
      sortable: true,
      renderCell: (params) => (
        <Select
          value={params.value}
          label="Age"
          onChange={(event) => handleActivityTypeChange(event, params.id)}
          className={classes.activityType}
        >
          <MenuItem
            value={"Business Activity"}
            className={classes.activityType}
          >
            Business Activity
          </MenuItem>
          <MenuItem value={"Other Activity"} className={classes.activityType}>
            Other Activity
          </MenuItem>
        </Select>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      width: 240,
      editable: true,
      sortable: true,
    },
    {
      field: "bank_statement_id",
      headerName: "Bank Statement",
      width: 220,
      editable: false,
      sortable: true,
      renderCell: (params) => (
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={params.value}
          label="Age"
          onChange={(event) => handleBankStatementChange(event, params.id)}
          className={classes.bankStatement}
        >
          {bankStatements.map((bankStatement) => (
            <MenuItem
              key={bankStatement.bank_statement_name}
              value={bankStatement.id}
              className={classes.bankStatement}
            >
              {bankStatement.bank_statement_name}
            </MenuItem>
          ))}
        </Select>
      ),
    },
  ];

  return (
    <>
      <DialogContent>
        <div style={{ height: 600, width: "100%" }}>
          <XGrid
            rows={unreviewedCashActivities}
            columns={columns}
            headerHeight={35}
            rowHeight={35}
            selectionModel={selectedCashActivities}
            onSelectionModelChange={(e) => toggleRow(e[0])}
            onCellEditCommit={handleCellCommit}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={makeSelection}>
          {selectedCashActivities.length === 0 && <span>Select All</span>}
          {selectedCashActivities.length !== 0 && <span>Deselect All</span>}
        </Button>
        <LoadableButton
          color="primary"
          variant="contained"
          disabled={selectedCashActivities.length === 0}
          onClick={reviewSelected}
          loading={loading}
        >
          Review
        </LoadableButton>
      </DialogActions>
    </>
  );
}

function AddCashActivitiesDialog({ open }: { open: boolean }) {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setActiveDialog(""));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="body"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>Review Cash Activities</DialogTitle>
      <Content onClose={handleClose} />
    </Dialog>
  );
}

export default AddCashActivitiesDialog;
