import { createAction } from "redux-actions";

// actions suffix
export const START = "_START";
export const SUCCESS = "_SUCCESS";
export const FAIL = "_FAIL";

// actions prefix
export const INVENTORY = "INVENTORY_";
export const SALE = "SALE_";
export const RETURN_SALE = "RETURN_SALE_";
export const BUNDLED_SALES = "BUNDLED_SALES_";
export const NUMERIC_OVERVIEW = "NUMERIC_OVERVIEW_";
export const CASH_ACTIVITY = "CASH_ACTIVITY_";
export const BANK_STATEMENT = "BANK_STATEMENT_";

export const ITEM_OPTIONS = "ITEM_OPTIONS_";
export const SALE_STATE = "SALE_STATES_";
export const PLATFORM = "PLATFORMS_";
export const TRANSACTION = "TRANSACTION_";
export const GL_ACCOUNTS = "GL_ACCOUNTS_";
export const UPLOAD = "UPLOAD_";
export const DOWNLOAD = "DOWNLOAD_";
export const ERROR_ALERTS = "ERROR_ALERTS_"
export const PLAID_INTEGRATIONS = "PLAID_INTEGRATIONS_";
export const VENDORS = "VENDORS_";

// action body
export const FETCH_ITEMS = "FETCH_ITEMS";
export const UPLOAD_ITEMS = "UPLOAD_ITEMS";
export const DELETE_ITEMS = "DELETE_ITEMS";

export const FETCH_ITEM = "FETCH_ITEM";
export const CREATE_ITEM = "CREATE_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const MOVE_ITEM = "MOVE_ITEM";

export const FS_CREATE_ITEMS = "CREATE_ITEMS";
export const FS_DELETE_ITEMS = "DELETE_ITEMS";
export const FS_UPDATE_ITEMS = "UPDATE_ITEMS";

export const SELECT_ITEMS = "SELECT_ITEMS";
export const SET_FILTER = "SET_FILTER";
export const SET_PAGE_SIZE = "SET_PAGE_SIZE"
export const SET_VISIBLE = "SET_VISIBLE";
export const CLEAR_UPDATE_METHOD = "CLEAR_UPDATE_METHOD";

export const RESET = "RESET";

export const SET_INTEGRATION_SYNC = "SET_INTEGRATION_SYNC";

export const clearUpdateMethod = createAction(CLEAR_UPDATE_METHOD);

export const handleSuccess = (state) => ({
  ...state,
  error: null,
  loaded: true,
  loading: false,
});

export const handleError = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: false,
});
