import { createAction } from "redux-actions";
import { ITEM_OPTIONS, START, FETCH_ITEMS, RESET } from "../common";
import { itemOptionsSelector } from "./selector";

export const fetchItems = createAction(ITEM_OPTIONS + FETCH_ITEMS + START);

export function fetchItemsIfNeeded() {
  return (dispatch, getState) => {
    const state = getState();
    const { items, loading } = itemOptionsSelector(state);
    if (!loading && (!items || !items.length)) dispatch(fetchItems());
  };
}

export const reset = createAction(ITEM_OPTIONS + RESET);
