import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";

import LoadableButton, {
  StatefulLoadableButton,
} from "src/components/LoadableButton";
import { getEBayIntegrations } from "src/store/plaidIntegration/selector";
import { allowedEBayIntegrationsSelector } from "src/store/system/selector";
import useStripeLink from "src/utils/useStripeLink";
import { getEBayOAuthUrl } from "src/apiService/modules/ebay";

export function WantToAddAdditionalEBayStoresContent({
  onClose,
}: {
  onClose: () => void;
}) {
  const link = useStripeLink();

  return (
    <>
      <DialogTitle>Want to add additional eBay stores?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Ultimate users can connect additional eBay stores for an extra
          $5/month/store. In the portal, set your plan to ”Ultimate” and
          increase the quantity of the "Ultimate" plan to the number of stores
          you'd like to integrate. Use the "Upgrade" button below to access the
          customer billing portal.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>No thanks</Button>
        <LoadableButton
          color="primary"
          autoFocus
          variant="contained"
          loading={!link}
          href={link}
          {...({ target: "_blank" } as any)}
        >
          Upgrade
        </LoadableButton>
      </DialogActions>
    </>
  );
}

function CanAddNewIntegrationContent({ onClose }: { onClose: () => void }) {
  const [name, setName] = useState("");
  return (
    <>
      <DialogTitle>Add New eBay Integration</DialogTitle>
      <DialogContent>
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          label="Name"
          variant="outlined"
          fullWidth
          required
        />
      </DialogContent>
      <Box mt={1} mx={3} mb={2}>
        <StatefulLoadableButton
          fullWidth
          variant="contained"
          color="primary"
          disabled={!name}
          onClick={async () => {
            try {
              const {
                data: { url },
              } = await getEBayOAuthUrl({ integrationName: name });
              window.location = url;
              onClose();
            } catch (e) {
              toast.error(`There was an error: ${(e as Error).toString()}`);
            }
          }}
        >
          Connect
        </StatefulLoadableButton>
      </Box>
    </>
  );
}

export function useCanAddMoreEBayIntegrations() {
  const integrations = useSelector(getEBayIntegrations);
  const allowedEBayIntegrations = useSelector(allowedEBayIntegrationsSelector);
  const canAddIntegrations = integrations.length < allowedEBayIntegrations;
  return canAddIntegrations;
}

function AddNewIntegrationContent({ onClose }: { onClose: () => void }) {
  const canAddIntegrations = useCanAddMoreEBayIntegrations();

  return canAddIntegrations ? (
    <CanAddNewIntegrationContent onClose={onClose} />
  ) : (
    <WantToAddAdditionalEBayStoresContent onClose={onClose} />
  );
}

export interface AddNewIntegrationProps {
  open: boolean;
  onClose: () => void;
}

function AddNewIntegration({ open, onClose }: AddNewIntegrationProps) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <AddNewIntegrationContent onClose={onClose} />
    </Dialog>
  );
}

export default AddNewIntegration;
