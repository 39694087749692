import React, { useState } from "react";
import Tour from "reactour";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import { userDontShowDialogsSelector } from "src/store/system/selector";
import DontShowThisMessageAgainCheckbox from "src/components/DontShowThisMessageAgainCheckbox";

const Key = "walk_through_manual_matching_sales";

const Steps = [
  {
    selector: "#manual-sales-matching-inventory",
    content: (props) => (
      <>
        <DialogTitle>Manually Matching Sales - 1/3</DialogTitle>
        <DialogContent>
          <DialogContentText>
            On this page, you can manually search your inventory for the item
            that sold. You can search for the item by either SKU or Item Title.
          </DialogContentText>
          <DialogContentText>
            The items in the search bar will appear in the format “SKU - Item
            Title”.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(1)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#manual-sales-matching-add-inventory-button",
    content: (props) => (
      <>
        <DialogTitle>Manually Matching Sales - 2/3</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you can’t find the item in your inventory, click the “Add
            Inventory Details” button to attach inventory information like
            “Purchase Price” and “Purchase Date” to the sale.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(0)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(2)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#manual-sales-matching-sale-inventory",
    content: (props) => (
      <>
        <DialogTitle>Manually Matching Sales - 3/3</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Once you have selected an inventory item to match to the sale, you
            will see details for the inventory item below. If the correct
            inventory item has been selected, click the “Match” button.
          </DialogContentText>
          <DontShowThisMessageAgainCheckbox dialog={Key} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(1)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.close()}
          >
            Close
          </Button>
        </DialogActions>
      </>
    ),
  },
];

const useStyles = makeStyles((theme) => ({
  helper: {
    maxWidth: "80%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "500px",
    },
    padding: 0,
  },
}));

export interface ManualSalesMatchingWalkThroughProps {
  onSelectInventoryPlaceholder: (placeholder: boolean) => void;
}

function WalkThrough({
  onSelectInventoryPlaceholder,
}: ManualSalesMatchingWalkThroughProps) {
  const [open, setOpen] = useState(true);
  const userDontShowDialogs = useSelector(userDontShowDialogsSelector);

  const classes = useStyles();
  const isOpen =
    open && userDontShowDialogs && !userDontShowDialogs?.includes(Key);

  return (
    <Tour
      startAt={0}
      closeWithMask={false}
      disableFocusLock
      steps={Steps}
      isOpen={isOpen}
      onRequestClose={() => {
        setOpen(false);
        onSelectInventoryPlaceholder(false);
      }}
      showButtons={false}
      showCloseButton={false}
      showNavigation={false}
      showNavigationNumber={false}
      showNumber={false}
      disableDotsNavigation
      rounded={5}
      className={classes.helper}
      disableInteraction
      getCurrentStep={(step) => {
        onSelectInventoryPlaceholder(step === 2);
      }}
    />
  );
}

export default WalkThrough;
