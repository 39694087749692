import firebase from "firebase/app";
import "firebase/functions";

export async function checkCouponApi(coupon: string) {
  const checkCouponFunction = firebase.functions().httpsCallable("checkCoupon");
  return await checkCouponFunction({ coupon });
}

export async function subscriptionApi({
  pm_id,
  plan,
  coupon,
  firstName,
  lastName,
  ebayIntegrationsQuantity,
}: {
  pm_id: string;
  plan: string;
  coupon?: string;
  firstName: string;
  lastName: string;
  ebayIntegrationsQuantity: number;
}) {
  const subscribeFunction = firebase
    .functions()
    .httpsCallable("updateSubscription");
  return await subscribeFunction({
    pm_id,
    plan,
    coupon,
    firstName,
    lastName,
    ebayIntegrationsQuantity,
  });
}

export async function getSubscription() {
  const f = firebase.functions().httpsCallable("stripeGetSubscription");

  return (await f()).data;
}
