import axiosClient from "../axiosClient";
import { Transaction } from "../../interfaces/transaction.interface";

import firebase from "firebase/app";
import Bluebird from "bluebird";
import { reduce, values } from "lodash";
import chunk from "lodash/chunk";
import { getUserIdSync, getUserId } from "src/config/storage";
import { requestNOUpdate } from "./numericOverview";
import { MaximumBatchSize } from "src/config/firestore";
import firestore from "src/apiService/firestore";

const db = firestore();

export const fetchTransactionsApi = async (payload: any) => {
  const { data } = await axiosClient.get(`transaction?&limit=0`);
  return data;
};

export const fetchGlAccountsApi = async () => {
  const { data } = await axiosClient.get(`glaccounts`);
  return data.data;
};

export const deleteTransactionApi = async (payload: string) => {
  const { data } = await axiosClient.delete(`transaction/${payload}`);
  return data;
};

export const deleteTransaction = async (id: string) => {
  return db.collection("Transactions").doc(id).delete();
};

export async function deleteTransactions(ids: string[]) {
  if (!ids.length) return;

  for (const c of chunk(ids, MaximumBatchSize)) {
    const batch = db.batch();
    for (const id of c) {
      const doc = db.collection("Transactions").doc(id);
      batch.delete(doc);
    }
    await batch.commit();
  }

  await requestNOUpdate();
}

export async function addTransaction(
  transaction: Partial<Transaction>
): Promise<string> {
  const doc = transaction.id
    ? db.collection("Transactions").doc(transaction.id)
    : db.collection("Transactions").doc();

  const user = await getUserId();
  await doc.set(
    {
      ...transaction,
      id: doc.id,
      user,
    },
    { merge: true }
  );

  await requestNOUpdate();

  return doc.id;
}

export const setTransactionsReviewed = async (transactionIds: any) => {
  await transactionIds.forEach((transactionId) => {
    const ref = db.collection("Transactions").doc(transactionId);
    ref
      .set({ reviewed: true }, { merge: true })
      .then((result) => {
        console.log("Transactions reviewed.");
      })
      .catch((err) => {
        console.log(err);
      });
  });
  await requestNOUpdate();
  return true;
};

export const updateTransactionDescription = async (
  transactionId: any,
  description: string
) => {
  const ref = db.collection("Transactions").doc(transactionId);
  return await ref.set({ description: description }, { merge: true });
};

export const updateTransactionAccount = async (
  transactionId: any,
  account: string
) => {
  const ref = db.collection("Transactions").doc(transactionId);
  return await ref.set({ account: account }, { merge: true });
};

export const updateTransactionVendor = async (
  transactionId: string,
  vendor?: string
) => {
  const ref = db.collection("Transactions").doc(transactionId);
  return await ref.update({
    vendor:
      vendor !== undefined ? vendor : firebase.firestore.FieldValue.delete(),
  });
};

export const updateTransactionHasRule = async (
  transactionId: any,
  hasRule: boolean
) => {
  const ref = db.collection("Transactions").doc(transactionId);
  return await ref.set({ has_rule: hasRule }, { merge: true });
};

// given a list of expenses  [{ user: expense.user, shipping_amount: 0, other_amount: 0, transaction_id: orderId }, ...]
// update corresponding sale's shipping_cost_analytics and other_fees
export const processExpenseToSalesUpdate = async (ebayExpensesArray: any) => {
  const user = getUserIdSync();
  const expensesByOrderId = reduce(
    ebayExpensesArray,
    (acc, expense) => {
      const orderId = expense.orderId;
      if (orderId && user === expense.user) {
        const isShipping = expense?.account === "Shipping Fees";
        const isOther =
          expense?.account === "Listing/Platform Fees" &&
          ["Adjustment", "Other Fees"].includes(expense?.transactionType);
        if (!acc[orderId]) {
          acc[orderId] = {
            user: expense.user,
            shipping_amount: 0,
            other_amount: 0,
            transaction_id: orderId,
          };
        }
        if (isShipping) {
          acc[orderId].shipping_amount += isNaN(+expense.amount)
            ? 0
            : +expense.amount;
        } else if (isOther) {
          acc[orderId].other_amount += isNaN(+expense.amount)
            ? 0
            : +expense.amount;
        }
      }
      return acc;
    },
    {}
  );
  return Bluebird.map(
    values(expensesByOrderId),
    async (expense) => {
      if (expense.shipping_amount || expense.other_amount) {
        const salesSnapshot = await db
          .collection("Sales")
          .where("user", "==", expense.user)
          .where("transaction_id", "==", expense.transaction_id)
          .limit(1)
          .get();
        const sales = salesSnapshot.docs.map((doc) => doc.data());
        if (sales.length === 1 && sales[0]?.id) {
          const sale = sales[0];
          const saleRef = db.collection("Sales").doc(sale.id);
          const previousSaleShippingCostAnalytics = isNaN(
            +sale.shipping_cost_analytics
          )
            ? 0
            : +sale.shipping_cost_analytics;
          const expenseShippingAmount = isNaN(+expense.shipping_amount)
            ? 0
            : +expense.shipping_amount;
          const previousSaleOtherFee = isNaN(+sale.other_fees)
            ? 0
            : +sale.other_fees;
          const expenseOtherAmount = isNaN(+expense.other_amount)
            ? 0
            : +expense.other_amount;
          await saleRef.set(
            {
              shipping_cost_analytics:
                previousSaleShippingCostAnalytics + expenseShippingAmount,
              other_fees: previousSaleOtherFee + expenseOtherAmount,
            },
            {
              merge: true,
            }
          );
        }
      }
    },
    { concurrency: 10 }
  );
};

export async function uploadExpensesFile(rows, metadata = {}) {
  const f = firebase.functions().httpsCallable("upload-uploadExpenses");

  return (await f({ rows, metadata })).data;
}

export async function bulkEditExpenses(rows, metadata = {}) {
  const f = firebase.functions().httpsCallable("upload-bulkEditExpenses");
  return (await f({ rows, metadata })).data;
}
