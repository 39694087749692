import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { toast } from "react-toastify";

import Button, { ButtonProps } from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

export interface LoadableButtonProps extends ButtonProps {
  loading?: boolean;
}

const useStyles = makeStyles({
  root: {
    position: "relative",
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-12px 0 0 -12px",
  },
});

function LoadableButton({
  loading,
  disabled,
  children,
  className,
  ...props
}: LoadableButtonProps) {
  const classes = useStyles();
  return (
    <Button
      {...props}
      disabled={loading || disabled}
      className={clsx(classes.root, className)}
    >
      {loading && <CircularProgress className={classes.progress} size={24} />}
      {children}
    </Button>
  );
}

export interface StatefulLoadableButtonProps
  extends Omit<LoadableButtonProps, "onClick"> {
  onClick: () => Promise<string | undefined | void>;
}

export function StatefulLoadableButton({
  onClick,
  ...props
}: StatefulLoadableButtonProps) {
  const [loading, setLoading] = useState(false);
  const unmountRef = useRef(false);
  useEffect(() => {
    unmountRef.current = false;
    return () => {
      unmountRef.current = true;
    };
  }, []);

  return (
    <LoadableButton
      {...props}
      loading={loading}
      onClick={async () => {
        setLoading(true);
        try {
          const ret = await onClick();
          if (ret) toast.success(ret);
        } catch (e) {
          toast.error((e as Error).toString());
          console.error(e);
        }
        if (!unmountRef.current) setLoading(false);
      }}
    />
  );
}

export default LoadableButton;
