import firebase from "firebase/app";
import "firebase/firestore";
import { MaximumBatchSize } from "src/config/firestore";

class FirebaseBatcher {
  batch: firebase.firestore.WriteBatch | undefined = undefined;
  length: number = 0;
  limit: number = MaximumBatchSize;
  pending: Promise<void>;

  constructor(limit: number = MaximumBatchSize) {
    this.limit = limit;
    this.pending = Promise.resolve();
  }

  async _do(method: "set" | "delete" | "update", ...args: any[]) {
    if (this.length % this.limit === 0 && this.length > 0) await this.commit();

    if (!this.batch) this.batch = firebase.firestore().batch();

    (this.batch[method] as unknown as (...a: any[]) => void)(...args);
    this.length++;
  }

  async set(...args: Parameters<firebase.firestore.WriteBatch["set"]>) {
    await this._do("set", ...args);
  }

  async delete(...args: Parameters<firebase.firestore.WriteBatch["delete"]>) {
    await this._do("delete", ...args);
  }

  async update(
    doc: firebase.firestore.DocumentReference<any>,
    update: firebase.firestore.UpdateData
  ) {
    await this._do("update", doc, update);
  }

  async commit() {
    const length = this.length;
    this.length = 0;

    if (this.batch && length) {
      const batch = this.batch;
      this.batch = undefined;
      const commit = batch.commit();
      this.pending = this.pending.then(() => commit)
      await commit;
    }
  }

  async wait() {
    await this.pending;
  }
}

export default FirebaseBatcher;
