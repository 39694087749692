import firebase from "firebase/app";
import "firebase/functions";
import firestore from "src/apiService/firestore";
import { getUserId } from "src/config/storage";
import { USER_PLAN as UserPlan } from "src/enums/common.enum";

export async function mercariIntegrationCreate(args: {
  cookies: Record<string, string>;
  integrationId?: string;
  username?: string;
  lastSync?: string;
}) {
  const f = firebase.functions().httpsCallable("mercari-integrationCreate");
  return await f(args);
}

export async function mercariIntegrationDelete(id: string) {
  const userId = await getUserId();
  const db = firestore();

  await db.runTransaction(async (transaction) => {
    const ref = db.collection("Plaid_Integrations").doc(id);
    const doc = await transaction.get(ref);
    if (!doc.exists) return;
    if (doc.data()?.user !== userId) return;
    await transaction.delete(ref);
  });
}

export async function mercariIntegrationSyncChange(
  id: string,
  sync: boolean,
  inventory: boolean = true
) {
  const userId = await getUserId();
  const db = firestore();

  await db.runTransaction(async (transaction) => {
    const user = await transaction.get(db.collection("Users").doc(userId));
    await transaction.update(db.collection("Plaid_Integrations").doc(id), {
      sync: sync && user.data()?.plan === UserPlan.ULTIMATE,
      inventory: sync && inventory,
    });
  });
}
