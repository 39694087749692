import React, { useState, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Button,
  TextField,
  Grid,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useSelector } from "react-redux";

import AddEditAnalyticsGroupingsDialog from "src/pages/RootDialogs/ProfileSettings/AnalyticsGroupings/AddEditAnalyticsGroupingsDialog";
import { AnalyticsGroupingItem } from "src/interfaces/systemState.interface";
import { getSaleByOriginalId } from "src/store/sale/selector";
import BrandField from "src/components/BrandField";
import VendorField from "src/components/VendorField";
import SalePlatformField from "src/components/SalePlatformField";
import cleanListWithExtraValue from "src/utils/cleanListWithExtraValue";
import NumberField from "src/components/NumberField";
import { userGetInventoryTypeSelector } from "src/store/system/selector";
import InfoTooltip from "src/components/InfoTooltip";
import GoldIconButton from "src/components/GoldIconButton";

import WalkThrough from "./EditSaleBasicWalkThrough";

const useStyles = makeStyles({
  padLeft: {
    marginLeft: 34,
  },
  flexGrid: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const EditSaleBasicDialog = ({
  handleDelete,
  loading,
  errors,
  values,
  setValues,
  handleChange,
  itemOptionsLoading,
  departments: initialDepartments,
  renderOption,
  categories: initialCategories,
  subCategories: initialSubCategories,
  saleStates,
  platformsLoading,
  allPlatforms: platforms,
  handleMove,
  goToBundle,
  goToReturn,
  handleSave,
  editReturn,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const isInventoryDisabled = useSelector(
    (s: any) => userGetInventoryTypeSelector(s) === "cash"
  );
  const [openAddAnalyticsGrouping, setOpenAddAnalyticsGrouping] =
    useState<AnalyticsGroupingItem>();
  const returnSale = useSelector((s: any) => getSaleByOriginalId(s, values.id));
  const hasReturn = !!returnSale || !!values.return_id || !!values.original_id;
  const hasReviewedReturn = hasReturn && !returnSale?.unreviewed;
  const salePlatform = values.sale_platform;
  const allPlatforms = useMemo(
    () => cleanListWithExtraValue(platforms, salePlatform),
    [platforms, salePlatform]
  );
  const department = values.department;
  const departments = useMemo(
    () => cleanListWithExtraValue(initialDepartments, department),
    [initialDepartments, department]
  );
  const category = values.category;
  const categories = useMemo(
    () => cleanListWithExtraValue(initialCategories, category),
    [initialCategories, category]
  );
  const subCategory = values.sub_category;
  const subCategories = useMemo(
    () => cleanListWithExtraValue(initialSubCategories, subCategory),
    [initialSubCategories, subCategory]
  );

  return (
    <>
      <WalkThrough open={open} onClose={() => setOpen(false)} />
      <AddEditAnalyticsGroupingsDialog
        open={!!openAddAnalyticsGrouping}
        initialValue={openAddAnalyticsGrouping}
        onClose={(ag) => {
          setOpenAddAnalyticsGrouping(undefined);
          if (ag)
            setValues((v) => ({
              ...v,
              department: ag.department,
              category: ag.category,
              sub_category: ag.subcategory,
            }));
        }}
      />
      <DialogTitle>
        Edit Sale{hasReturn ? " (Refund Attached)" : ""}
        {!values.bundle_id && !hasReturn && (
          <IconButton
            onClick={handleDelete}
            className="absolute right-5"
            disabled={loading}
            color="secondary"
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="relative" tabIndex={-1} component="div">
          <form noValidate autoComplete="off">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <div className={classes.padLeft}>
                    <TextField
                      error={errors.item_title ? true : false}
                      helperText={errors.item_title}
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      label="Item Title"
                      value={values.item_title}
                      disabled={loading}
                      onChange={handleChange("item_title")}
                      required
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.flexGrid}>
                  <InfoTooltip
                    text="Source where you purchased the item."
                    size={18}
                  />
                  <VendorField
                    margin="dense"
                    disabled={loading}
                    value={values.vendor}
                    onChange={(vendor) => {
                      setValues((values) => ({
                        ...values,
                        vendor: vendor || "",
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    className={classes.padLeft}
                    disabled={itemOptionsLoading}
                    options={["Add new department", ...departments]}
                    value={
                      departments.length ? values.department || null : null
                    }
                    autoHighlight
                    onChange={(_e, value) => {
                      if (value === "Add new department") {
                        setOpenAddAnalyticsGrouping({
                          department: "",
                          category: "",
                          subcategory: "",
                        });
                        setValues({
                          ...values,
                          department: "",
                          category: "",
                          sub_category: "",
                        });
                        return;
                      }
                      setValues({
                        ...values,
                        department: value || "",
                        category: "",
                        sub_category: "",
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors.department ? true : false}
                        helperText={errors.department}
                        margin="dense"
                        required
                        label="Department"
                        fullWidth
                        variant="outlined"
                      />
                    )}
                    renderOption={renderOption}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    className={classes.padLeft}
                    disabled={itemOptionsLoading}
                    fullWidth
                    options={["Add new category", ...categories]}
                    value={categories.length ? values.category || null : null}
                    autoHighlight
                    onChange={(_e, value) => {
                      if (value === "Add new category") {
                        setOpenAddAnalyticsGrouping({
                          department: values.department,
                          category: "",
                          subcategory: "",
                        });
                        setValues({
                          ...values,
                          category: "",
                          sub_category: "",
                        });
                        return;
                      }
                      setValues({
                        ...values,
                        category: value || "",
                        sub_category: "",
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors.category ? true : false}
                        helperText={errors.category}
                        margin="dense"
                        required
                        label="Category"
                        fullWidth
                        variant="outlined"
                      />
                    )}
                    renderOption={renderOption}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    className={classes.padLeft}
                    disabled={itemOptionsLoading}
                    fullWidth
                    options={["Add new sub-category", ...subCategories]}
                    value={
                      subCategories.length ? values.sub_category || null : null
                    }
                    autoHighlight
                    onChange={(_e, value) => {
                      if (value === "Add new sub-category") {
                        setOpenAddAnalyticsGrouping({
                          department: values.department,
                          category: values.category,
                          subcategory: "",
                        });
                        setValues({ ...values, sub_category: "" });
                        return;
                      }
                      setValues({ ...values, sub_category: value || "" });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors.sub_category ? true : false}
                        helperText={errors.sub_category}
                        margin="dense"
                        required
                        label="Sub category"
                        fullWidth
                        variant="outlined"
                      />
                    )}
                    renderOption={renderOption}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <BrandField
                    className={classes.padLeft}
                    value={values.brand}
                    onChange={(e, value) =>
                      handleChange("brand")({ target: { value } })
                    }
                    disabled={loading}
                    TextFieldProps={{
                      error: errors.brand ? true : false,
                      helperText: errors.brand,
                      fullWidth: true,
                      variant: "outlined",
                      margin: "dense",
                      label: "Brand",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} className={classes.flexGrid}>
                  <InfoTooltip
                    text="Where you have stored the item."
                    size={18}
                  />
                  <TextField
                    error={errors.location ? true : false}
                    helperText={errors.location}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    label="Location"
                    value={values.location}
                    disabled={loading}
                    onChange={handleChange("location")}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div className={classes.padLeft}>
                    <TextField
                      error={errors.sku ? true : false}
                      helperText={errors.sku}
                      className="w-full"
                      variant="outlined"
                      margin="dense"
                      label="SKU"
                      value={values.sku}
                      disabled={loading}
                      onChange={handleChange("sku")}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.flexGrid}>
                  <InfoTooltip
                    text="The total revenue collected from a customer. This includes the price of the item and additional money collected for shipping. This does not include sales tax."
                    size={18}
                  />
                  <NumberField
                    required
                    error={errors.sale_price ? true : false}
                    helperText={errors.sale_price}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    label="Sale Price"
                    value={
                      values.bundle_id ? values.sale_price : values.sale_price
                    }
                    disabled={loading || !!values.bundle_id}
                    onChange={handleChange("sale_price", true)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    fixed={2}
                  />
                </Grid>
                {isInventoryDisabled ? null : (
                  <Grid item xs={12} sm={4} className={classes.flexGrid}>
                    <InfoTooltip
                      text="The price at which you purchased the item."
                      size={18}
                    />
                    <NumberField
                      error={errors.purchase_price ? true : false}
                      helperText={errors.purchase_price}
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      label="Purchase Price"
                      value={values.purchase_price}
                      disabled={loading}
                      onChange={handleChange("purchase_price", true)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      fixed={2}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={4} className={classes.flexGrid}>
                  <InfoTooltip
                    text="The amount you paid to ship the sale. This amount will be included in the P&L and Management Dashboard. If your shipping is accounted for in the expense detail, don’t add the cost here as well – this will result in duplicate shipping expenses."
                    size={18}
                  />
                  <NumberField
                    error={errors.shipping_cost ? true : false}
                    helperText={errors.shipping_cost}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    label="Shipping Cost"
                    value={
                      values.bundle_id
                        ? values.shipping_cost
                        : values.shipping_cost
                    }
                    disabled={loading || !!values.bundle_id}
                    onChange={handleChange("shipping_cost", true)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    fixed={2}
                  />
                </Grid>
                <Grid item xs={12} sm={4} className={classes.flexGrid}>
                  <InfoTooltip
                    text="The amount the platform charged you to sell the item."
                    size={18}
                  />
                  <NumberField
                    error={errors.transaction_fees ? true : false}
                    helperText={errors.transaction_fees}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    label="Transaction Fees"
                    value={
                      values.bundle_id
                        ? values.transaction_fees
                        : values.transaction_fees
                    }
                    disabled={loading || !!values.bundle_id}
                    onChange={handleChange("transaction_fees", true)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    fixed={2}
                  />
                </Grid>
                <Grid item xs={12} sm={4} className={classes.flexGrid}>
                  <InfoTooltip
                    text="The sales tax paid by the buyer."
                    size={18}
                  />
                  <NumberField
                    error={errors.sales_tax ? true : false}
                    helperText={errors.sales_tax}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    label="Sales Tax"
                    value={
                      values.bundle_id ? values.sales_tax : values.sales_tax
                    }
                    disabled={loading || !!values.bundle_id}
                    onChange={handleChange("sales_tax", true)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    fixed={2}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <div className={classes.padLeft}>
                    <TextField
                      onInput={(e: any) => {
                        let value = e.target?.value;
                        let res = value ? value : "";
                        setValues({ ...values, sale_state: res });
                        e.target.value = res;
                      }}
                      value={values.sale_state}
                      margin="dense"
                      label="Sale State"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.flexGrid}>
                  <InfoTooltip
                    text="If you aren’t responsible for filing sales tax, mark this field as ‘No’. If you are responsible for filing sales tax, mark this field as ‘Yes’."
                    size={18}
                  />
                  <Autocomplete
                    fullWidth
                    disabled={itemOptionsLoading || !!values.bundle_id}
                    options={["No", "Yes"]}
                    value={values.liable_to_pay ? "Yes" : "No"}
                    autoHighlight
                    onChange={(_e, value) => {
                      setValues({
                        ...values,
                        liable_to_pay: value === "Yes" ? true : false,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors.liable_to_pay ? true : false}
                        helperText={errors.liable_to_pay}
                        margin="dense"
                        required
                        label="Liable to Pay"
                        fullWidth
                        variant="outlined"
                      />
                    )}
                    renderOption={renderOption}
                  />
                </Grid>
                {isInventoryDisabled ? null : (
                  <Grid item xs={12} sm={8}>
                    <Autocomplete
                      className={classes.padLeft}
                      multiple
                      disabled={platformsLoading}
                      options={allPlatforms?.sort((a, b) =>
                        a?.toLowerCase()?.localeCompare(b?.toLowerCase())
                      )}
                      value={
                        values.platforms_listed ? values.platforms_listed : []
                      }
                      onChange={handleChange("platforms_listed")}
                      autoHighlight
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="dense"
                          label="Platforms Listed"
                          variant="outlined"
                        />
                      )}
                      renderOption={renderOption}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={4} className={classes.flexGrid}>
                  <InfoTooltip
                    text={`Record the platform where you sold this item. If the platform doesn't appear in the dropdown, you can add platforms in your profile settings or by clicking "Add New Platform" in the "Sale Platform" dropdown."`}
                    size={18}
                  />
                  <SalePlatformField
                    disabled={itemOptionsLoading || !!values.bundle_id}
                    value={
                      allPlatforms?.length ? values.sale_platform || null : null
                    }
                    onChange={(value) => {
                      setValues((values) => ({
                        ...values,
                        sale_platform: value || "",
                      }));
                    }}
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={8} className={classes.flexGrid}>
                  <InfoTooltip text="Add notes to your sale." size={18} />
                  <TextField
                    error={errors.notes ? true : false}
                    helperText={errors.notes}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    label="Notes"
                    value={values.notes || ""}
                    disabled={loading}
                    onChange={handleChange("notes")}
                  />
                </Grid>
                {isInventoryDisabled ? null : (
                  <Grid item xs={12} sm={4} className={classes.flexGrid}>
                    <InfoTooltip
                      text="The date that you purchased this item."
                      size={18}
                    />
                    <KeyboardDatePicker
                      disableToolbar
                      error={!!errors.purchase_date}
                      helperText={errors.purchase_date}
                      fullWidth
                      variant="inline"
                      inputVariant="outlined"
                      format="MM/dd/yyyy"
                      autoOk={true}
                      margin="dense"
                      label="Purchase Date"
                      required
                      value={values.purchase_date || null}
                      disabled={loading}
                      onChange={(e: any, t: any) => {
                        setValues({ ...values, purchase_date: new Date(e) });
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={4} className={classes.flexGrid}>
                  <InfoTooltip
                    text="The date that you listed this item for sale."
                    size={18}
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    fullWidth
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    margin="dense"
                    label="List Date"
                    autoOk={true}
                    value={values.list_date || null}
                    disabled={loading}
                    onChange={(e: any, t: any) => {
                      if (e) {
                        setValues({ ...values, list_date: new Date(e) });
                      } else {
                        const { list_date, ...v } = values;
                        setValues({
                          ...v,
                          list_date: null,
                        });
                      }
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} className={classes.flexGrid}>
                  <InfoTooltip
                    text="The date that you sold this item."
                    size={18}
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    margin="dense"
                    label="Sale Date"
                    autoOk={true}
                    required
                    error={!!errors.sale_date}
                    helperText={errors.sale_date}
                    value={values.sale_date || null}
                    disabled={loading || !!values.bundle_id}
                    onChange={(e: any, t: any) => {
                      setValues({ ...values, sale_date: new Date(e) });
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} className={classes.flexGrid}>
                  <InfoTooltip
                    text="If you have already accounted for shipping costs in the “Expense Detail”, you can attach them to the sale here. This will prevent the expense from being counted twice on your P&L statement."
                    size={18}
                  />
                  <NumberField
                    error={errors.shipping_cost_analytics ? true : false}
                    helperText={errors.shipping_cost_analytics}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    label="Shipping Costs (From Expense Detail)"
                    value={values.shipping_cost_analytics}
                    disabled={loading}
                    onChange={handleChange("shipping_cost_analytics", true)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      className: "whitespace-nowrap",
                    }}
                    fixed={2}
                  />
                </Grid>
                <Grid item xs={12} sm={4} className={classes.flexGrid}>
                  <InfoTooltip
                    text="If you have already accounted for other costs in the “Expense Detail”, you can attach them to the sale here. This will prevent the expense from being counted twice on your P&L statement."
                    size={18}
                  />
                  <NumberField
                    error={errors.other_fees ? true : false}
                    helperText={errors.other_fees}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    label="Other Costs (From Expense Detail)"
                    value={values.other_fees}
                    disabled={loading}
                    onChange={handleChange("other_fees", true)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      className: "whitespace-nowrap",
                    }}
                    fixed={2}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </form>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box mt={-1.5}>
          <Tooltip title="Need help? Click here.">
            <GoldIconButton onClick={() => setOpen(true)} />
          </Tooltip>
        </Box>
        <Box flex={1} />
        {!values.bundle_id &&
          !hasReturn &&
          !values.original_id &&
          !isInventoryDisabled && (
            <Button
              onClick={handleMove}
              color="primary"
              variant="contained"
              disabled={loading || !!values.bundle_id || values.return_id}
              id="edit-sale-basic-move-to-inventory-button"
            >
              Move to inventory
            </Button>
          )}
        {!!values.bundle_id && !hasReturn && (
          <Button
            onClick={goToBundle}
            color="secondary"
            variant="contained"
            disabled={loading}
          >
            Edit Bundle
          </Button>
        )}
        {!hasReturn && !values.original_id && (
          <Button
            onClick={goToReturn}
            color="primary"
            variant="contained"
            disabled={loading}
            id="edit-sale-basic-record-refund-button"
          >
            Record Refund
          </Button>
        )}
        {!!hasReturn && (
          <Button
            onClick={() => editReturn(values, !hasReviewedReturn)}
            color="primary"
            variant="contained"
          >
            {hasReviewedReturn ? "Edit Refund" : "Finalize Refund"}
          </Button>
        )}
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          Update
        </Button>
      </DialogActions>
    </>
  );
};

export default EditSaleBasicDialog;
