import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { GridCellParams, getGridDefaultColumnTypes } from "@material-ui/x-grid";

export type Checker = (params: GridCellParams) => boolean;
export type Getter = (params: GridCellParams) => string;

const style: React.CSSProperties = {
  width: "100%",
};

function withTooltipRendererCell(column, getTitle: Getter, checker: Checker) {
  const typeRenderCell = getGridDefaultColumnTypes()[column.type]?.renderCell;

  return {
    ...column,
    renderCell: (params: GridCellParams) => {
      const renderCell = column.renderCell || typeRenderCell;
      if (!checker(params)) {
        if (renderCell) return renderCell(params);
        return null;
      }

      return (
        <Tooltip title={getTitle(params)}>
          {renderCell ? (
            <div>{renderCell(params)}</div>
          ) : (
            <div style={style}>{params.formattedValue}</div>
          )}
        </Tooltip>
      );
    },
  };
}

export default withTooltipRendererCell;
