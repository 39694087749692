import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { STRIPE_PUBLIC_KEY } from "./config";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";

import configureStore from "./store";
import BaseRouter from "./router";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const splitLocation = document.location.href.split("?");
// console.log({ loc: document.location });
if (splitLocation.length > 1) {
  const query = splitLocation[1].split("=");
  if (query.length > 1) {
    const referralCode = query[1];
    if (referralCode.slice(0, 3) !== "v^1") {
      console.log(`got referral code ${referralCode}`);
      localStorage.setItem("referralCode", referralCode);
    }
  }
} else {
  localStorage.removeItem("referralCode");
}

const App = () => {
  const [rootStore, setRootStore] = useState<any | undefined>(undefined);

  useEffect(() => {
    const initStore = async () => {
      const { store, persistor } = await configureStore();
      setRootStore({ store, persistor });
    };
    initStore();
  }, []);

  if (!rootStore) {
    return null;
  }
  return (
    <Provider store={rootStore.store}>
      <PersistGate loading={null} persistor={rootStore.persistor}>
        <ThemeProvider theme={theme}>
          <Elements
            stripe={stripePromise}
            options={{
              fonts: [
                {
                  cssSrc:
                    "https://fonts.googleapis.com/css2?family=Roboto&display=swap",
                },
              ],
            }}
          >
            <CssBaseline />
            <BaseRouter />
          </Elements>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
