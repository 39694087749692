import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import Wrapper from "src/components/Wrapper";
import useUserEffect from "src/utils/useUserEffect";
import { setActiveDialogIfCanBeShown } from "src/store/adminHtml/actions";
import {
  alertsSelector,
  activeDialogSelector,
} from "src/store/adminHtml/selector";

import { GraphContent } from "./GraphContent";
import { TableContent } from "./TableContent";

function Dispatcher() {
  const dispatch = useDispatch();
  const hasAlerts = useSelector((state) => !!alertsSelector(state)?.length);
  const isMaintenanceActivitiesDialogOpen = useSelector(
    (state: any) =>
      activeDialogSelector(state) === "maintenance_activities_dialog"
  );
  const hasAlertsRef = useRef(hasAlerts);
  hasAlertsRef.current = hasAlerts;

  // Opens maintenance dialog
  useUserEffect(() => {
    const opened = dispatch(
      setActiveDialogIfCanBeShown("maintenance_activities_dialog")
    );

    if (!opened && hasAlertsRef.current)
      dispatch(setActiveDialogIfCanBeShown("walk_through_notifications_icon"));
  }, []);

  // Opens notification icon walkthrough when the maintenance dialog has been closed
  useEffect(() => {
    if (!isMaintenanceActivitiesDialogOpen) return;

    return () => {
      if (hasAlertsRef.current)
        dispatch(
          setActiveDialogIfCanBeShown("walk_through_notifications_icon")
        );
    };
  }, [isMaintenanceActivitiesDialogOpen, dispatch]);

  // Opens notiification icon walkthrough when notifications were loaded
  useEffect(() => {
    if (hasAlerts)
      dispatch(
        setActiveDialogIfCanBeShown("walk_through_notifications_icon", true)
      );
  }, [hasAlerts, dispatch]);

  return null;
}

function ManagementPage() {
  return (
    <Wrapper>
      <Dispatcher />
      <GraphContent />
      <TableContent />
    </Wrapper>
  );
}

export default ManagementPage;
