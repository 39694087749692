import { createSelector } from "reselect";
import { AppState } from "..";

const getVendorState = (state: AppState) => state.vendor;

export const getVendors = createSelector(
  getVendorState,
  (state) => state.items
);
export const getVendor = createSelector(
  [getVendors, (_, id) => id],
  (vendors, id) => (id ? vendors.find((v) => v.id === id) : undefined)
);
export const getConsignors = createSelector(getVendors, (vendors) =>
  vendors.filter((v) => v.consignor)
);
