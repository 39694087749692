import React, { useState } from "react";
import { useSelector } from "react-redux";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";

import {
  featureFlagSelector,
  userGetInventoryTypeSelector,
} from "src/store/system/selector";

import DialogTitle from "./DialogTitle";
import type Step from "./Step";
import { useIntegration } from "./AmazonImport";

interface AmazonImportInProcessProps {
  onClose: () => void;
  onStepChange: (step: Step) => void;
}

function AmazonImportInProcess({
  onClose,
  onStepChange,
}: AmazonImportInProcessProps) {
  const canEtsyIntegration = useSelector((state) => featureFlagSelector(state, "etsy")) &&
  process.env.REACT_APP_MODE === "development";
  const inventoryType = useSelector(userGetInventoryTypeSelector);
  const bag = useIntegration();
  const [memory] = useState(bag);

  // cash
  let text =
    "Congrats! Your sales import from Amazon has started. Sales imports from Amazon also import eBay fees and refunds. Click continue set-up for your next steps.";

  const nextStep = [
    canEtsyIntegration && "etsy:do-you-sell-on-etsy",
    "other:do-you-sell",
  ].filter(Boolean)[0];

  const primaryButton = {
    label: "Continue Set-Up",
    action: nextStep as Step,
  };

  let secondaryButton = "";

  if (inventoryType === "accrual") {
    if (memory.hasAlreadyImportedInventory && memory.hasAlreadyImportedSales) {
      text =
        "Congrats! Your imports from Amazon have started. Click continue set-up for your next steps.";
    } else if (memory.hasAlreadyImportedInventory) {
      text =
        "Congrats! Your inventory import from Amazon has started. Would you like to import sales next?";
      primaryButton.label = "Yes";
      primaryButton.action = "amazon:import";
      secondaryButton = "No";
    } else {
      text =
        "Congrats! Your sales import from Amazon has started. Sales imports from Amazon also import eBay fees and refunds. Would you like to import inventory next?";
      primaryButton.label = "Yes";
      primaryButton.action = "amazon:import";
      secondaryButton = "No";
    }
  }

  return (
    <>
      <DialogTitle onClose={onClose}>Import in Process</DialogTitle>
      <MuiDialogContent>
        <DialogContentText>{text}</DialogContentText>
      </MuiDialogContent>
      <DialogActions>
        {secondaryButton ? (
          <Button
            onClick={() => {

              const nextStep = [
                canEtsyIntegration && "etsy:do-you-sell-on-etsy",
                "other:do-you-sell",
              ].filter(Boolean)[0];

              onStepChange(nextStep as Step);
            }}
            color="primary"
            autoFocus
          >
            {secondaryButton}
          </Button>
        ) : null}
        <Button
          onClick={() => {
            onStepChange(primaryButton.action);
          }}
          color="primary"
          autoFocus
        >
          {primaryButton.label}
        </Button>
      </DialogActions>
    </>
  );
}

export default AmazonImportInProcess;
