import React, { type PropsWithChildren } from "react";
import { makeStyles } from "@material-ui/core/styles";

// fix-vim-highlight = }

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    zIndex: theme.zIndex.snackbar,
    left: theme.spacing(1),
    right: theme.spacing(1),
    top: 0,
    [theme.breakpoints.up("sm")]: {
      left: "50%",
      right: "auto",
      transform: "translateX(-50%)",
    },
  },
}));

function StatusesContainer({ children }: PropsWithChildren<{}>) {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
}

export default StatusesContainer;
