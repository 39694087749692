import React, { useState } from "react";
import { toast } from "react-toastify";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import { StatefulLoadableButton } from "src/components/LoadableButton";
import { getEBayOAuthUrl } from "src/apiService/modules/ebay";

import DialogTitle from "./DialogTitle";
import type Step from "./Step";

interface EBayAddNewEBayStoreProps {
  onClose: () => void;
  onStepChange: (step: Step) => void;
  previous: Step;
}

function EBayAddNewEBayStore({
  onClose,
  onStepChange,
  previous,
}: EBayAddNewEBayStoreProps) {
  const [name, setName] = useState("");

  return (
    <>
      <DialogTitle onClose={onClose}>Add New eBay Store</DialogTitle>
      <MuiDialogContent>
        <DialogContentText>
          We’re going to connect your eBay store to My Reseller Genie so we can
          pull in your data. Add a name for the store (all your information
          pulled from this store will have this name attached as the “Sale
          Platform”).
        </DialogContentText>
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          label="Store Name"
          variant="outlined"
          fullWidth
          required
        />
      </MuiDialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onStepChange(
              previous === "ebay:additional-stores"
                ? previous
                : "ebay:do-you-sell-on-ebay"
            );
          }}
          color="primary"
          autoFocus
        >
          Go Back
        </Button>
        <StatefulLoadableButton
          variant="contained"
          color="primary"
          disabled={!name}
          onClick={async () => {
            try {
              const {
                data: { url },
              } = await getEBayOAuthUrl({
                integrationName: name,
                back: "/get-started#ebay:import",
              });
              window.location = url;
            } catch (e) {
              toast.error(`There was an error: ${(e as Error).toString()}`);
            }
          }}
        >
          Connect
        </StatefulLoadableButton>
      </DialogActions>
    </>
  );
}

export default EBayAddNewEBayStore;
