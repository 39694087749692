import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { toast } from "react-toastify";

import { deleteUploadsApi, requestNOUpdate } from "src/apiService";
import { Upload } from "src/interfaces";
import { getUploads } from "src/store/uploads/selector";
import { setActiveDialog } from "src/store/adminHtml/actions";
import { StatefulLoadableButton as LoadableButton } from "src/components/LoadableButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectedRow: {
      backgroundColor: "#ccccff",
    },
  })
);

const generateDisplayName = (fileName, fileType) => {
  if (fileName.includes("ebaySales")) {
    if (fileType === "sales") {
      return "ebay Sales";
    } else if (fileType === "transactions") {
      return "ebay Transactions";
    } else if (fileType === "returns") {
      return "ebay Returns";
    }
  }
  if (fileName.includes("ebayInventory")) return "ebay Inventory";
  if (fileName.includes("ebayFee")) return "ebay Fees";
  if (fileName.includes("poshmarkInventory")) return "poshmark Inventory";
  if (fileName.includes("poshmarkSales")) {
    if (fileType === "sales") {
      return "poshmark Sales";
    } else if (fileType === "transactions") {
      return "poshmark Transactions";
    } else if (fileType === "returns") {
      return "poshmark Returns";
    }
  }
  if (fileName.includes("mercariInventory")) return "mercari Inventory";
  if (fileName.includes("mercariSales")) return "mercari Sales";
  return fileName;
};

function ManageUploadsDialogContent({ onClose }: { onClose: () => void }) {
  const classes = useStyles();
  const unsortedUploads: Upload[] = useSelector(getUploads);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const uploads = useMemo(() => {
    const uploads = [...unsortedUploads];
    uploads.sort((a, b) => (b?.timestamp || 0) - (a?.timestamp || 0));
    return uploads;
  }, [unsortedUploads]);

  const toggleRow = (index: number) => {
    return setSelectedIndex((current) => {
      if (current === index) {
        return -1;
      }
      return index;
    });
  };

  const handleDeleteClick = async () => {
    await deleteUploadsApi([uploads[selectedIndex].id]);
    requestNOUpdate();
    toast.success("Items successfully deleted.");
    onClose();
  };

  return (
    <>
      <DialogTitle>Review Uploads</DialogTitle>
      <DialogContent>
        <DialogContentText className="relative" tabIndex={-1} component="div">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Filename</TableCell>
                <TableCell>Upload time</TableCell>
                <TableCell>Number of lines</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uploads.map((item, index) => (
                <TableRow
                  key={item.id}
                  className={index === selectedIndex ? classes.selectedRow : ""}
                  onClick={() => toggleRow(index)}
                >
                  <TableCell>
                    {generateDisplayName(item.filename, item.type)}
                  </TableCell>
                  <TableCell>
                    {new Date(item.timestamp || 0).toLocaleString()}
                  </TableCell>
                  <TableCell>{item.n}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="px-7 py-5">
        <LoadableButton
          color="primary"
          variant="contained"
          disabled={selectedIndex === -1}
          onClick={handleDeleteClick}
        >
          Delete Uploaded Items
        </LoadableButton>
      </DialogActions>
    </>
  );
}

function ManageUploadsDialog({ open }: { open: boolean }) {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setActiveDialog(""));
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"sm"}>
      <ManageUploadsDialogContent onClose={handleClose} />
    </Dialog>
  );
}

export default ManageUploadsDialog;
