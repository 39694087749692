import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Button, Grid, Paper, ClickAwayListener } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { LicenseInfo, GridSelectionModel } from "@material-ui/x-grid";
import XGrid from "src/components/ConfiguredXGrid";
import CSVFormattedXGrid from "src/components/CSVFormattedXGrid";
import { createTableToolbar } from "src/components/TableToolbar";

import { makeStyles } from "@material-ui/core/styles";

import ConfirmDialog from "src/components/ConfirmDialog";
import { EditDialog } from "./EditDialog";
import InfoTooltip from "../../components/InfoTooltip";

import { clearUpdateMethod } from "../../store/common";
import {
  setFilter,
  selectItems,
} from "../../store/transaction/actions";

import { formatCurrency } from "../../utils";
import { METHOD_TYPE } from "../../enums";
import { X_GRID_LICENSE_KEY } from "../../config";
import { getTransactions } from "../../store/transaction/selector";
import { deleteTransactions } from "../../apiService";

import { valueGetterWithDefault } from "src/utils/valueGetter";
import vendorRenderCell from "src/utils/vendorRenderCell";
import renderHeaderDescriptionLabel from "src/utils/renderHeaderLabel";

import BulkEditDialog from "./BulkEditDialog";

LicenseInfo.setLicenseKey(X_GRID_LICENSE_KEY);

const TableToolbar = createTableToolbar({
  options: { fileName: "My Reseller Genie - Expenses" },
});

const tableColumnInfo = {
  account:
    "The general ledger account is a tag that categorizes an expense. This is used to build accounting reports like the Profit & Loss Statement.",
};

const renderHeaderLabel = (params) => {
  const tooltip = tableColumnInfo[params.field];
  return (
    <div className="MuiDataGrid-columnHeaderTitle">
      {params.colDef.headerName}
      {!!tooltip && <InfoTooltip size="small" text={tooltip} />}
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      borderRight: `1px solid #f0f0f0`,
      padding: "0px 10px",
      fontSize: "12px",
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      borderBottom: `1px solid #f0f0f0`,
    },
    "& .MuiDataGrid-cell": {
      color: `rgba(0,0,0,.85)`,
    },
    "& .MuiDataGrid-iconSeparator": {
      opacity: 0,
    },
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#fafafa",
      borderTop: "1px solid #f0f0f0",
      fontSize: "12px",
    },
    "& .MuiDataGrid-colCell": {
      borderRight: `1px solid #f0f0f0`,
      padding: "0px 10px",
    },
  },
});

const Columns = [
  {
    field: "id",
    hide: true,
  },
  {
    field: "account",
    headerName: "General Ledger Account",
    width: 250,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "name",
    headerName: "Charge Description",
    width: 200,
    type: "string",
  },
  // { field: "amount", headerName: "Amount", width: 200 },
  {
    field: "amount",
    headerName: "Amount",
    width: 200,
    type: "number",
    valueFormatter: formatCurrency,
  },
  {
    field: "vendor",
    headerName: "Vendor",
    width: 200,
    renderCell: vendorRenderCell,
    renderHeader: renderHeaderDescriptionLabel,
    description: "The vendor is where you purchased the item (ex. Goodwill).",
    valueGetter: (params) => params.row.vendorName || params.value || "",
  },
  { field: "date", headerName: "Date", width: 200, type: "date" },
  { field: "description", headerName: "Description", width: 250 },
  {
    field: "integration_name",
    headerName: "Bank Account",
    width: 200,
    valueGetter: valueGetterWithDefault(""),
  },
];

export const Content = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    items: initialItems,
    method,
    loading,
    error,
    filter: filterModel,
  } = useSelector(getTransactions);
  const items = initialItems || [];

  const descriptionElementRef = useRef<HTMLElement>(null);

  const [openEdit, setOpenEdit] = useState<string | boolean>(false);
  const [showDelete, setShowDelete] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [openBulkEdit, setOpenBulkEdit] = useState(false);

  useEffect(() => {
    if (openEdit) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openEdit]);

  useEffect(() => {
    const isModalAction =
      method === METHOD_TYPE.UPDATE ||
      method === METHOD_TYPE.DELETE ||
      method === METHOD_TYPE.CREATE;
    if (!error && !loading && isModalAction) {
      setOpenEdit(false);
      dispatch(clearUpdateMethod());
    }
  }, [loading]);

  const handleCloseEdit = () => {
    if (
      (method === METHOD_TYPE.UPDATE || method === METHOD_TYPE.DELETE) &&
      loading
    )
      return;
    setOpenEdit(false);
  };

  const handleSelectItem = (selectionModel: GridSelectionModel) => {
    setSelectionModel(selectionModel);
  };

  const handleSelectAndEdit = (e: any) => {
    setOpenEdit(`${e.row.id}`);
  };

  const handleChangeFilterModel = (e: any) => {
    dispatch(setFilter(e));
  };

  const handleDeleteSelected = () => {
    setShowDelete(false);
    if (selectionModel.length > 0) {
      deleteTransactions(selectionModel.map(String));
      setSelectionModel([]);
      setOpenEdit(false);
    }
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <ClickAwayListener
          onClickAway={(e) => {
            const element = e.target as HTMLElement;
            if (element.tagName !== "BODY") {
              dispatch(selectItems([]));
            }
          }}
        >
          <div>
            <Paper className="p-5 h-auto">
              <div className="flex justify-between flex-wrap mb-3">
                <h2 className="text-left text-blue mb-0">Expense Detail</h2>
                <div className={"flex items-center"}>
                  <Button
                    variant="contained"
                    color="primary"
                    className="h-9 ml-3 mt-1"
                    onClick={() => setOpenBulkEdit(true)}
                  >
                    Bulk Edit Expenses
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className="h-9 ml-3 mt-1"
                    disabled={loading || selectionModel.length === 0}
                    onClick={() => {
                      setShowDelete(true);
                    }}
                    style={{ marginRight: 10 }}
                  >
                    Delete Selected
                  </Button>
                  <Autocomplete
                    style={{ width: 300 }}
                    value={null}
                    options={items}
                    getOptionLabel={(item) => {
                      return [item.description, item.name]
                        .filter(Boolean)
                        .join(" - ");
                    }}
                    disabled={loading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Items to Edit..."
                        variant="outlined"
                        margin="dense"
                      />
                    )}
                    onChange={(e, item) => {
                      if (item) setOpenEdit(item.id);
                    }}
                  />
                </div>
              </div>

              <XGrid
                checkboxSelection
                checkboxSelectionVisibleOnly
                tableName="transaction"
                Component={CSVFormattedXGrid}
                className="h-table"
                rows={items}
                columns={Columns}
                headerHeight={35}
                rowHeight={35}
                pageSize={pageSize}
                onPageSizeChange={(pageSize: number) => setPageSize(pageSize)}
                rowsPerPageOptions={[10, 25, 50, 100]}
                pagination
                loading={loading && method === METHOD_TYPE.LIST}
                components={{
                  Toolbar: TableToolbar,
                }}
                filterModel={filterModel}
                selectionModel={selectionModel}
                onSelectionModelChange={handleSelectItem}
                onCellDoubleClick={handleSelectAndEdit}
                onFilterModelChange={handleChangeFilterModel}
              />
              <EditDialog
                data={
                  typeof openEdit === "string"
                    ? items.find((item) => item.id === openEdit)
                    : undefined
                }
                open={!!openEdit}
                onClose={handleCloseEdit}
                onDelete={() => {
                  if (typeof openEdit === "string") {
                    setSelectionModel([openEdit]);
                    setShowDelete(true);
                  }
                }}
                loading={loading}
              />
              <ConfirmDialog
                open={showDelete}
                title="Confirm"
                text={
                  "Are you sure you want to delete " +
                  selectionModel.length +
                  " transaction(s)?"
                }
                onCancel={() => {
                  setShowDelete(false);
                  setSelectionModel([]);
                }}
                onConfirm={handleDeleteSelected}
              />
              <BulkEditDialog
                open={openBulkEdit}
                onClose={() => setOpenBulkEdit(false)}
              />
            </Paper>
          </div>
        </ClickAwayListener>
      </Grid>
    </Grid>
  );
};
