import produce from "immer";
import { handleActions } from "redux-actions";


import { ItemOptionsState } from "../../interfaces";

import {
  START,
  FAIL,
  SUCCESS,
  FETCH_ITEMS,
  RESET,
  ITEM_OPTIONS,
} from "../common";

const initialState: ItemOptionsState = {
  items: [],
  departments: [],

  selected: {
    category: "",
    depertment: "",
    sub_category: "",
  },

  loading: false,
  loaded: false,
  error: null,
};

export const itemOptionsReducer = handleActions<ItemOptionsState, any>(
  {
    [ITEM_OPTIONS + RESET]: (state) =>
      produce(state, (draft) => {
        draft = initialState;
      }),
    [ITEM_OPTIONS + FETCH_ITEMS + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.error = null;
      }),
    [ITEM_OPTIONS + FETCH_ITEMS + SUCCESS]: (state, { payload: { data, is_department } }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = false;
        draft.error = null;

        if (is_department) {
          draft.departments = data
            .map((option:any) => option.Department)
            .filter((department: string, index0: number) => {
              const index1 = data.findIndex(e => e.Department === department);
              return index0 === index1;
            });
        }
        draft.items = data;
      }),
    [ITEM_OPTIONS + FETCH_ITEMS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),

  },
  initialState
);
