import { all, call, fork, put, take } from "redux-saga/effects";
import { fetchSaleStatesApi, addSaleStateApi } from "../../apiService";
import { parseError } from "../../utils";

import {
  SALE_STATE,
  FAIL,
  START,
  SUCCESS,
  FETCH_ITEMS,
  CREATE_ITEM,
} from "../common";

function* fetchItems() {
  while (true) {
    yield take(SALE_STATE + FETCH_ITEMS + START);
    try {
      const data: { data: any } = yield call(fetchSaleStatesApi);
      yield put({ type: SALE_STATE + FETCH_ITEMS + SUCCESS, payload: data });
    } catch (error) {
      yield put({
        type: SALE_STATE + FETCH_ITEMS + FAIL,
        payload: parseError(error),
      });
    }
  }
}
 
function* createItem() {
  while (true) {
    const { payload } = yield take(SALE_STATE + CREATE_ITEM + START);
    try {
      const data = yield call(addSaleStateApi, payload);
      yield put({ type: SALE_STATE + CREATE_ITEM + SUCCESS, payload: payload });
    } catch (error) {
      yield put({
        type: SALE_STATE + CREATE_ITEM + FAIL,
        payload: (error as any)?.response?.data,
      });
    }
  }
}
 
export function* saleStateSagas() {
  yield all([
    fork(fetchItems),
    fork(createItem),
  ]);
}
