import { Box, Container, Typography } from "@material-ui/core";
import React from "react";
import GridLoader from "react-spinners/GridLoader";

export const AppLoader = ({text}: {text?: string}) => {
  return (
    <Container className="h-full justify-center flex items-center">
      <Box className="flex flex-col items-center">
        <GridLoader size={15} margin={2} color={"rgb(68, 112, 147)"} />
        <Typography className="mt-3 text-primary text-sm">{text || "Loading..."}</Typography>
      </Box>
    </Container>
  );
};
