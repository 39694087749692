import React, { useRef, useEffect } from "react";
import firebase from "firebase/app";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import type { DontShowDialog } from "src/interfaces/systemState.interface";
import { updateUserApi } from "src/apiService/modules/system";

export interface DontShowThisMessageAgainCheckboxProps {
  dialog: DontShowDialog;
  justify?: "start" | "center";
}
const useStyles = makeStyles((theme) => ({
  formControlLabelLabel: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
  },
}));

function DontShowThisMessageAgainCheckbox({
  dialog,
  justify = "start",
}: DontShowThisMessageAgainCheckboxProps) {
  const classes = useStyles();
  const ref = useRef({
    checked: false,
    dialog,
  });

  ref.current.dialog = dialog;

  useEffect(
    () => () => {
      if (ref.current.checked) {
        updateUserApi({
          dontShowDialogs: firebase.firestore.FieldValue.arrayUnion(
            ref.current.dialog
          ),
        });
      }
    },
    []
  );

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent={justify === "center" ? "center" : "flex-start"}
      alignItems="center"
    >
      <FormControlLabel
        control={<Checkbox />}
        label="Don’t Show This Message Again"
        classes={{ label: classes.formControlLabelLabel }}
        onChange={(event) => {
          ref.current.checked = (event.target as any).checked;
        }}
      />
    </Box>
  );
}

export default DontShowThisMessageAgainCheckbox;
