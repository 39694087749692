import produce from "immer";
import { handleActions } from "redux-actions";
import { ZendeskState } from "src/interfaces/zendeskState.interface";
import { FAIL, START, SUCCESS } from "../common";
import { FetchArticles } from "./actions";

const initialState: ZendeskState = {
  articles: {},
  searches: [],
};

export const zendeskReducer = handleActions<ZendeskState, any>(
  {
    [FetchArticles + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.searches = state.searches.filter(
          (s) => s.query !== payload.query || s.page !== payload.page
        );
        draft.searches.push({
          query: payload.query,
          page: payload.page,
          loading: true,
        });
      }),
    [FetchArticles + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.searches = state.searches.filter(
          (s) => s.query !== payload.query || s.page !== payload.page
        );
        draft.searches.push({
          query: payload.query,
          page: payload.page,
          results: payload.results.map((a) => a.id),
          hasNext: payload.hasNext,
        });

        for (const article of payload.results) {
          draft.articles[article.id] = article;
        }
      }),
    [FetchArticles + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.searches = draft.searches.filter(
          (s) => s.query !== payload.query || s.page !== payload.page
        );
        draft.searches.push({
          query: payload.query,
          page: payload.page,
          error: true,
        });
      }),
  },
  initialState
);
