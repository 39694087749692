import { AdminHtmlState } from "../../interfaces";
import {
  AdminHtmlActionTypes,
  SET_ACTIVE_DIALOG,
  SET_WALKTHROUGH_ID,
  TOGGLE_MOBILE_OPEN,
} from "./actions";

const initialState: AdminHtmlState = {
  mobileOpen: false,
  walkthroughId: "",

};

export const adminHtmlReducer = (state = initialState, action: AdminHtmlActionTypes): AdminHtmlState => {
  switch (action.type) {
    case TOGGLE_MOBILE_OPEN:
      return {
        ...state,
        mobileOpen: !state.mobileOpen,
      };
    case SET_ACTIVE_DIALOG:
        return {
          ...state,
          activeDialog: action.activeDialog,
        };
    case SET_WALKTHROUGH_ID:
        return {
          ...state,
          walkthroughId: action.walkthroughId,
        };
    default:
      return state;
  }
};
