import React, { useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import firebase from "firebase/app";

import InventoryCashVsAccrual from "src/pages/GetStarted/InventoryCashVsAccrual";
import ConfirmDeleteAccountDataDialog from "src/pages/RootDialogs/ProfileSettings/ConfirmDeleteAccountDataDialog";
import { StatefulLoadableButton } from "src/components/LoadableButton";
import {
  updateUserApi,
  deleteAccountData,
} from "src/apiService/modules/system";

import { userShowSetUpFeatureSelector } from "src/store/system/selector";

const useStyles = makeStyles({
  fieldset: {
    display: "flex",
    alignItems: "center",
  },
});

type Step = "set-up" | "dont-need-help" | "need-help" | "cash-vs-accrual";

function SetUp({ onChangeStep }: { onChangeStep: (step: Step) => void }) {
  const [value, setValue] = useState<"yes" | "no">();
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    if (value === "yes" || value === "no") setValue(value);
  };
  return (
    <>
      <DialogTitle>Set Up Feature</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Hello! My Reseller Genie has just pushed a new feature to help users
          get set up. It looks like you’ve started getting set up already. Could
          you let us know where you’re at in the process?
        </DialogContentText>
        <FormControl component="fieldset" className={classes.fieldset}>
          <RadioGroup value={value || null} onChange={handleChange}>
            <FormControlLabel
              value="no"
              control={<Radio />}
              label="I don’t need help with set up"
            />
            <FormControlLabel
              value="yes"
              control={<Radio />}
              label="I’d like to use the new set up feature"
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={!value}
          variant="contained"
          onClick={() => {
            onChangeStep(value === "yes" ? "need-help" : "dont-need-help");
          }}
        >
          Next
        </Button>
      </DialogActions>
    </>
  );
}

function DontNeedHelp({
  onChangeStep,
}: {
  onChangeStep: (step: Step) => void;
}) {
  const [value, setValue] = useState<"accrual" | "cash" | "dont-know">();
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    if (value === "accrual" || value === "cash" || value === "dont-know")
      setValue(value);
  };
  return (
    <>
      <DialogTitle>Inventory Method</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Glad to hear you’re set up. Another aspect of this update is that we
          are trying to make the software easier to navigate based on how you
          account for inventory.
        </DialogContentText>
        <DialogContentText>
          If you use the cash inventory method, My Reseller Genie will remove
          inventory specific features to simplify the interface.
        </DialogContentText>
        <DialogContentText>
          If you use the accrual inventory method, My Reseller Genie will keep
          all the inventory features available for you.
        </DialogContentText>
        <FormControl component="fieldset" className={classes.fieldset}>
          <FormLabel component="legend">
            How do you account for your inventory?
          </FormLabel>
          <RadioGroup value={value || null} onChange={handleChange}>
            <FormControlLabel
              value="accrual"
              control={<Radio />}
              label="Accrual inventory method"
            />
            <FormControlLabel
              value="cash"
              control={<Radio />}
              label="Cash inventory method"
            />
            <FormControlLabel
              value="dont-know"
              control={<Radio />}
              label="I don’t know, what’s the difference?"
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            onChangeStep("set-up");
          }}
        >
          Go Back
        </Button>
        <StatefulLoadableButton
          color="primary"
          disabled={!value}
          variant="contained"
          onClick={async () => {
            if (value === "dont-know") {
              onChangeStep("cash-vs-accrual");
              return;
            }

            await updateUserApi({
              checklist_completed: firebase.firestore.FieldValue.delete(),
              checklist_items: firebase.firestore.FieldValue.delete(),
              inventoryType: value === "cash" ? "cash" : "accrual",
              getStartedStep: "complete-set-up",
            });
          }}
        >
          Next
        </StatefulLoadableButton>
      </DialogActions>
    </>
  );
}

function NeedHelp({ onChangeStep }: { onChangeStep: (step: Step) => void }) {
  const [value, setValue] = useState<"yes" | "no">();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    if (value === "yes" || value === "no") setValue(value);
  };
  return (
    <>
      <ConfirmDeleteAccountDataDialog
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={async () => {
          try {
            const p = deleteAccountData();
            history.push("/get-started");
            await p;
            toast.success("Data deleted successfully");
          } catch (e) {
            toast.error(
              `Error: ${(e as Error).message || (e as Error).toString()}`
            );
          }
        }}
      />
      <DialogTitle>Start Fresh?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Great, we’ll make the new set up feature available to you. Do you want
          to delete all the data from your Genie so you can start fresh?
        </DialogContentText>
        <FormControl component="fieldset" className={classes.fieldset}>
          <RadioGroup value={value || null} onChange={handleChange}>
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            onChangeStep("set-up");
          }}
        >
          Go Back
        </Button>
        <StatefulLoadableButton
          color="primary"
          disabled={!value}
          variant="contained"
          onClick={async () => {
            if (value === "yes") {
              setOpen(true);
              return;
            }

            await updateUserApi({
              checklist_completed: firebase.firestore.FieldValue.delete(),
              checklist_items: firebase.firestore.FieldValue.delete(),
            });
            history.push("/get-started");
          }}
        >
          Next
        </StatefulLoadableButton>
      </DialogActions>
    </>
  );
}

function Content() {
  const [step, setStep] = useState<Step>("set-up");
  const handleChangeStep = (step: Step) => {
    setStep(step);
  };

  switch (step) {
    case "dont-need-help":
      return <DontNeedHelp onChangeStep={handleChangeStep} />;

    case "need-help":
      return <NeedHelp onChangeStep={handleChangeStep} />;

    case "cash-vs-accrual":
      return (
        <InventoryCashVsAccrual
          onStepChange={() => setStep("dont-need-help")}
        />
      );

    default:
      return <SetUp onChangeStep={handleChangeStep} />;
  }
}

function SetUpFeatureDialog() {
  const open = useSelector(userShowSetUpFeatureSelector);

  return (
    <Dialog open={open}>
      <Content />
    </Dialog>
  );
}

export default SetUpFeatureDialog;
