import axiosClient from "../axiosClient";

export const fetchSaleStatesApi = async () => {
  const { data } = await axiosClient.get(`salestates`);
  return data;
};

export const addSaleStateApi = async (payload: string) => {
  const { data } = await axiosClient.post(`salestates`, {
    "saleState": payload
  });
  return data;
};

