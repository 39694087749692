import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { eBayIntegrationDelete } from "src/apiService/modules/ebay";
import { StatefulLoadableButton } from "src/components/LoadableButton";
import { EBayIntegration } from "src/interfaces/plaidIntegration.interface";
import usePrevious from "src/utils/usePrevious";

export interface DeleteIntegrationDialogContentProps {
  integration: Pick<EBayIntegration, "id" | "name">;
  onClose: () => void;
  deleteIntegration?: (id: string) => Promise<void>;
}

function DeleteIntegrationDialogContent({
  integration,
  onClose,
  deleteIntegration = eBayIntegrationDelete,
}: DeleteIntegrationDialogContentProps) {
  return (
    <>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete the {integration.name} integration?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <StatefulLoadableButton
          variant="contained"
          onClick={async () => {
            await deleteIntegration(integration.id);
            onClose();
          }}
          color="secondary"
          autoFocus
        >
          Delete
        </StatefulLoadableButton>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </>
  );
}

export interface DeleteIntegrationDialogProps {
  integration?: Pick<EBayIntegration, "id" | "name">;
  onClose: () => void;
  deleteIntegration?: DeleteIntegrationDialogContentProps["deleteIntegration"];
}

function DeleteIntegrationDialog({
  integration,
  onClose,
  deleteIntegration,
}: DeleteIntegrationDialogProps) {
  const prev = usePrevious(integration);
  return (
    <Dialog open={!!integration} onClose={onClose}>
      <DialogTitle>Delete Integration</DialogTitle>
      {!!prev && (
        <DeleteIntegrationDialogContent
          integration={prev}
          onClose={onClose}
          deleteIntegration={deleteIntegration}
        />
      )}
    </Dialog>
  );
}
export default DeleteIntegrationDialog;
