import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import Step from "../Step";
import DialogTitle from "../DialogTitle";
import { useSelector } from "react-redux";
import { featureFlagSelector } from "../../../store/system/selector";

interface EtsyDoYouSellOnEtsyProps {
  onClose: () => void;
  onStepChange: (step: Step) => void;
}

type Options = "yes" | "no";

const useStyles = makeStyles((theme) => ({
  content: {
    margin: "auto",
  },
  radioGroup: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(4),
  },
}));

function EtsyDoYouSellOnEtsy({
  onClose,
  onStepChange,
}: EtsyDoYouSellOnEtsyProps) {
  const classes = useStyles();
  const [value, setValue] = useState<Options>();

  const canAmazonIntegration =
    useSelector((state) => featureFlagSelector(state, "amazon")) &&
    process.env.REACT_APP_MODE === "development";

  const canUseMercariIntegration =
    useSelector((state) => featureFlagSelector(state, "mercari")) &&
    process.env.REACT_APP_MODE === "development";

  return (
    <>
      <DialogTitle onClose={onClose}>Set Up Questions - 6/11</DialogTitle>
      <MuiDialogContent className={classes.content}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Do you sell on Etsy?</FormLabel>
          <RadioGroup
            value={value || null}
            onChange={(event) => {
              setValue((event.target as HTMLInputElement).value as Options);
            }}
            className={classes.radioGroup}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </MuiDialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            const previousStep = [
              canAmazonIntegration && "amazon:do-you-sell-on-amazon",
              canUseMercariIntegration && "mercari:do-you-sell-on-mercari",
              "poshmark:do-you-sell-on-poshmark",
            ].filter(Boolean)[0];

            onStepChange(previousStep as Step);
          }}
          color="primary"
          autoFocus
        >
          Go Back
        </Button>
        <Button
          variant="contained"
          disabled={!value}
          onClick={() => {
            onStepChange(
              value === "yes" ? "etsy:add-new-store" : "other:do-you-sell"
            );
          }}
          color="primary"
          autoFocus
        >
          Next
        </Button>
      </DialogActions>
    </>
  );
}

export default EtsyDoYouSellOnEtsy;
