import { createAction } from "redux-actions";
import { 
  NUMERIC_OVERVIEW,
  SELECT_ITEMS,
  SET_FILTER,
  SET_VISIBLE,
  FS_CREATE_ITEMS,
  FS_DELETE_ITEMS,
  FS_UPDATE_ITEMS,
} from "../common";

export const selectItems = createAction(NUMERIC_OVERVIEW + SELECT_ITEMS);
export const setFilter = createAction(NUMERIC_OVERVIEW + SET_FILTER);
export const setVisible = createAction(NUMERIC_OVERVIEW + SET_VISIBLE);

export const addNumericOverviewRecords = createAction(NUMERIC_OVERVIEW + FS_CREATE_ITEMS);
export const updateNumericOverviewRecords = createAction(NUMERIC_OVERVIEW + FS_UPDATE_ITEMS);
export const deleteNumericOverviewRecords = createAction(NUMERIC_OVERVIEW + FS_DELETE_ITEMS);
