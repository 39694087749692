import React, { useEffect, useState, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";

import Autocomplete from "@material-ui/lab/Autocomplete";

import { toast } from "react-toastify";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import InputAdornment from "@material-ui/core/InputAdornment";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { platformSelector } from "../../store/platform/selector";
import { itemOptionsSelector } from "../../store/itemOptions/selector";

import { fetchItemsIfNeeded as fetchPlatforms } from "../../store/platform/actions";
import { fetchItemsIfNeeded as fetchItemOptions } from "../../store/itemOptions/actions";

import { Inventory, InventoryFormError } from "../../interfaces";
import { userPlatformsSelector } from "../../store/system/selector";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

import AddEditAnalyticsGroupingsDialog from "src/pages/RootDialogs/ProfileSettings/AnalyticsGroupings/AddEditAnalyticsGroupingsDialog";
import { AnalyticsGroupingItem } from "src/interfaces/systemState.interface";
import BrandField from "src/components/BrandField";
import VendorField from "src/components/VendorField";
import NumberField from "src/components/NumberField";
import {
  departmentsSelector,
  categoriesSelector,
  subCategoriesSelector,
} from "src/store/itemOptions/selector";
import cleanListWithExtraValue from "src/utils/cleanListWithExtraValue";
import InfoTooltip from "src/components/InfoTooltip";
import GoldIconButton from "src/components/GoldIconButton";
import WalkThrough from "./EditDialogWalkThrough";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
  })
);

interface EditDialogProps {
  data: Inventory | undefined;
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onSave: Function;
  onDelete: Function;
}

const initFormValues: Inventory = {
  id: "",
  brand: "",
  category: "",
  department: "",
  item_title: "",
  location: "",
  notes: "",
  platforms_listed: [],
  sku: "",
  sub_category: "",
  purchase_price: 0,
  purchase_date: undefined,
  list_date: undefined,
  quantity: 1,
};

function EditDialogContent({
  data,
  loading,
  onSave,
  onDelete,
}: Omit<EditDialogProps, "open" | "onClose">) {
  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const userPlatforms = useSelector(userPlatformsSelector);
  const { items: platforms } = useSelector(platformSelector);
  const { loading: itemOptionsLoading } = useSelector(itemOptionsSelector);

  const [values, setValues] = React.useState<Inventory>(initFormValues);
  const [errors, setErrors] = React.useState<InventoryFormError>({});
  const [, setHasChanged] = React.useState<boolean>(false);

  const initialDepartments = useSelector(departmentsSelector);
  const department = values.department;
  const departments = useMemo(
    () => cleanListWithExtraValue(initialDepartments, department),
    [initialDepartments, department]
  );
  const initialCategories = useSelector((state: any) =>
    categoriesSelector(state, values)
  );
  const category = values.category;
  const categories = useMemo(
    () => cleanListWithExtraValue(initialCategories, category),
    [initialCategories, category]
  );
  const initialSubCategories = useSelector((state: any) =>
    subCategoriesSelector(state, values)
  );
  const subCategory = values.sub_category;
  const subCategories = useMemo(
    () => cleanListWithExtraValue(initialSubCategories, subCategory),
    [initialSubCategories, subCategory]
  );

  const [openAddAnalyticsGrouping, setOpenAddAnalyticsGrouping] =
    useState<AnalyticsGroupingItem>();

  useEffect(() => {
    setValues(
      data
        ? {
            ...data,
            quantity: data.quantity || 1,
          }
        : initFormValues
    );
    setErrors({});
    setHasChanged(false);

    dispatch(fetchPlatforms());
    dispatch(fetchItemOptions());
  }, [data, dispatch]);

  const handleDelete = () => {
    onDelete();
  };

  const handleSave = () => {
    if (validateForm()) {
      onSave(values);
    } else {
      toast.error("Some required information is missing or incomplete");
    }
  };

  const handleChange =
    (prop: keyof Inventory, numeric?: true) =>
    (event: React.ChangeEvent<any> | { target: { value?: any } }, t?: any) => {
      setHasChanged(true);
      let value = event.target.value;
      if (numeric) {
        value = +value || 0;
      }
      if ("department" === prop) {
        value = departments[Number(value)];
      } else if ("category" === prop) {
        value = categories[Number(value)];
      } else if ("sub_category" === prop) {
        value = subCategories[Number(value)];
      } else if ("platforms_listed" === prop) {
        value = t;
      }
      setValues({ ...values, [prop]: value });
    };

  const isValidDate = (d: any) => {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d.getTime())) {
        // d.valueOf() could also work
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const validateForm = () => {
    let hasError = false;
    /* rules */
    let newErrors: InventoryFormError = { ...errors };
    if (values.item_title === undefined || values.item_title.trim() === "") {
      hasError = true;
      newErrors.item_title = "Required field!";
    } else {
      newErrors.item_title = undefined;
    }

    if (!isValidDate(values.purchase_date)) {
      hasError = true;
      newErrors.purchase_date = "Invalid date format";
    } else {
      newErrors.purchase_date = undefined;
    }

    if (!values.quantity || values.quantity <= 0) {
      hasError = true;
      newErrors.quantity = "Quantity must be a number greater or equal than 1";
    } else {
      newErrors.quantity = undefined;
    }

    setErrors(newErrors);

    return !hasError;
  };

  const renderOption = (option, { inputValue }) => {
    const matches = match(option, inputValue);
    const parts = parse(option, matches);

    return (
      <div>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    );
  };

  return (
    <>
      <WalkThrough open={open} onClose={() => setOpen(false)} />
      <AddEditAnalyticsGroupingsDialog
        open={!!openAddAnalyticsGrouping}
        initialValue={openAddAnalyticsGrouping}
        onClose={(ag) => {
          setOpenAddAnalyticsGrouping(undefined);
          if (ag)
            setValues((v) => ({
              ...v,
              department: ag.department,
              category: ag.category,
              sub_category: ag.subcategory,
            }));
        }}
      />
      <DialogTitle id="edit-inventory relative">
        Edit Inventory
        <IconButton
          onClick={handleDelete}
          className="absolute right-5"
          disabled={loading}
          color="secondary"
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText className="relative" tabIndex={-1} component="div">
          <form className={classes.root} noValidate autoComplete="off">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} className="flex justify-end">
                  <TextField
                    error={errors.item_title ? true : false}
                    helperText={errors.item_title}
                    className="w-11/12"
                    variant="outlined"
                    margin="dense"
                    label="Item Title"
                    value={values.item_title}
                    disabled={loading}
                    onChange={handleChange("item_title")}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="flex justify-between">
                  <InfoTooltip
                    text="Source where you purchased the item."
                    size={18}
                  />
                  <VendorField
                    margin="dense"
                    disabled={loading}
                    value={values.vendor}
                    onChange={(vendor) => {
                      setValues((values) => ({
                        ...values,
                        vendor: vendor || "",
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="flex justify-between">
                  <InfoTooltip
                    text="Departments help to organize your inventory, they are the largest grouping to set inventory/sales apart."
                    size={18}
                  />
                  <Autocomplete
                    className="w-11/12"
                    disabled={itemOptionsLoading}
                    options={["Add new department", ...departments]}
                    value={values.department || null}
                    autoHighlight
                    onChange={(_e, value) => {
                      if (value === "Add new department") {
                        setOpenAddAnalyticsGrouping({
                          department: "",
                          category: "",
                          subcategory: "",
                        });
                        setValues({
                          ...values,
                          department: "",
                          category: "",
                          sub_category: "",
                        });
                        return;
                      }
                      setValues({
                        ...values,
                        department: value || "",
                        category: "",
                        sub_category: "",
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors.department ? true : false}
                        helperText={errors.department}
                        margin="dense"
                        required
                        label="Department"
                        className="w-full"
                        variant="outlined"
                      />
                    )}
                    renderOption={renderOption}
                  />
                </Grid>

                <Grid item xs={12} sm={6} className="flex justify-between">
                  <InfoTooltip
                    text="Categories help to organize your inventory, they are the second largest grouping to set inventory/sales apart."
                    size={18}
                  />
                  <Autocomplete
                    disabled={itemOptionsLoading}
                    options={["Add new category", ...categories]}
                    value={values.category || null}
                    autoHighlight
                    className="w-11/12"
                    onChange={(_e, value) => {
                      if (value === "Add new category") {
                        setOpenAddAnalyticsGrouping({
                          department: values.department,
                          category: "",
                          subcategory: "",
                        });
                        setValues({
                          ...values,
                          category: "",
                          sub_category: "",
                        });
                        return;
                      }
                      setValues({
                        ...values,
                        category: value || "",
                        sub_category: "",
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors.category ? true : false}
                        helperText={errors.category}
                        margin="dense"
                        required
                        label="Category"
                        className="w-full"
                        variant="outlined"
                      />
                    )}
                    renderOption={renderOption}
                  />
                </Grid>

                <Grid item xs={12} sm={6} className="flex justify-between">
                  <InfoTooltip
                    text="Sub-categories help to organize your inventory, they are the most specific grouping to set inventory/sales apart."
                    size={18}
                  />
                  <Autocomplete
                    disabled={itemOptionsLoading}
                    options={["Add new sub-category", ...subCategories]}
                    value={values.sub_category || null}
                    autoHighlight
                    className="w-11/12"
                    onChange={(_e, value) => {
                      if (value === "Add new sub-category") {
                        setOpenAddAnalyticsGrouping({
                          department: values.department,
                          category: values.category,
                          subcategory: "",
                        });
                        setValues({ ...values, sub_category: "" });
                        return;
                      }
                      setValues({ ...values, sub_category: value || "" });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors.sub_category ? true : false}
                        helperText={errors.sub_category}
                        margin="dense"
                        required
                        label="Sub category"
                        className="w-full"
                        variant="outlined"
                      />
                    )}
                    renderOption={renderOption}
                  />
                </Grid>

                <Grid item xs={12} sm={6} className="flex justify-end">
                  <BrandField
                    className="w-11/12"
                    value={values.brand}
                    disabled={loading}
                    onChange={(e, value) =>
                      (handleChange("brand") as any)({ target: { value } })
                    }
                    TextFieldProps={{
                      error: errors.brand ? true : false,
                      helperText: errors.brand,
                      className: "w-full",
                      variant: "outlined",
                      margin: "dense",
                      label: "Brand",
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} className="flex justify-between">
                  <InfoTooltip
                    text="Where you have stored the item."
                    size={18}
                  />
                  <TextField
                    error={errors.location ? true : false}
                    helperText={errors.location}
                    className="w-11/12"
                    variant="outlined"
                    margin="dense"
                    label="Location"
                    value={values.location}
                    disabled={loading}
                    onChange={handleChange("location")}
                  />
                </Grid>

                <Grid item xs={12} sm={6} className="flex justify-end">
                  <TextField
                    error={errors.sku ? true : false}
                    helperText={errors.sku}
                    className="w-11/12"
                    variant="outlined"
                    margin="dense"
                    label="SKU"
                    value={values.sku}
                    disabled={loading}
                    onChange={handleChange("sku")}
                  />
                </Grid>

                <Grid item xs={12} sm={6} className="flex justify-between">
                  <InfoTooltip
                    text="The amount you paid for the inventory."
                    size={18}
                  />
                  <NumberField
                    error={errors.purchase_price ? true : false}
                    helperText={errors.purchase_price}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    label="Purchase Price"
                    value={values.purchase_price}
                    disabled={loading}
                    onChange={handleChange("purchase_price", true)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    fixed={2}
                  />
                </Grid>

                <Grid item xs={12} sm={6} className="flex justify-end">
                  <Autocomplete
                    multiple
                    className="w-11/12"
                    options={[...platforms, ...(userPlatforms || [])]?.sort(
                      (a, b) =>
                        a?.toLowerCase()?.localeCompare(b?.toLowerCase())
                    )}
                    value={
                      values.platforms_listed ? values.platforms_listed : []
                    }
                    onChange={handleChange("platforms_listed")}
                    autoHighlight
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="dense"
                        label="Platforms Listed"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="flex justify-between">
                  <InfoTooltip
                    text="Enter any other information that you need in here."
                    size={18}
                  />
                  <TextField
                    error={errors.notes ? true : false}
                    helperText={errors.notes}
                    className="w-full"
                    variant="outlined"
                    margin="dense"
                    label="Notes"
                    value={values.notes}
                    disabled={loading}
                    onChange={handleChange("notes")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="flex justify-between">
                  <InfoTooltip
                    text="The date that you purchased this item."
                    size={18}
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    className="w-11/12"
                    inputVariant="outlined"
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="dense"
                    label="Purchase Date"
                    required
                    autoOk={true}
                    value={values.purchase_date || null}
                    disabled={loading}
                    onChange={(e: any, t: any) => {
                      setValues({ ...values, purchase_date: new Date(e) });
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="flex justify-between">
                  <InfoTooltip
                    text="The date that you listed this item for sale."
                    size={18}
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    className="w-11/12"
                    inputVariant="outlined"
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="dense"
                    label="List Date"
                    autoOk={true}
                    value={values.list_date || null}
                    disabled={loading}
                    onChange={(e: any, t: any) => {
                      setHasChanged(true);
                      if (e) {
                        setValues({ ...values, list_date: new Date(e) });
                      } else {
                        const { list_date, ...v } = values;
                        setValues({
                          ...v,
                          list_date: null,
                        });
                      }
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="flex justify-between">
                  <InfoTooltip
                    text="The number of these items you’d like to create."
                    size={18}
                  />
                  <NumberField
                    error={errors.quantity ? true : false}
                    helperText={errors.quantity}
                    className="w-11/12"
                    variant="outlined"
                    margin="dense"
                    required
                    label="Quantity"
                    type="number"
                    value={values.quantity}
                    disabled={loading}
                    onChange={handleChange("quantity")}
                    InputProps={{
                      inputProps: { min: 1 },
                    }}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </form>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box mt={-1.5}>
          <Tooltip title="Need help? Click here.">
            <GoldIconButton onClick={() => setOpen(true)} />
          </Tooltip>
        </Box>
        <Box flex={1} />
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          {loading && <CircularProgress size={15} className="mr-3" />}
          Update
        </Button>
      </DialogActions>
    </>
  );
}

function EditDialog({ open, onClose, ...props }: EditDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="md">
      <EditDialogContent {...props} />
    </Dialog>
  );
}

export default EditDialog;
