import { getUserIdSync } from "src/config/storage";
import { TableConfiguration } from "src/interfaces/systemState.interface";
import firestore from "src/apiService/firestore";

export async function updateTableConfiguration(
  tableName: string,
  config: TableConfiguration
) {
  const user = getUserIdSync();
  const userRef = firestore().collection("Users").doc(user);
  await firestore().runTransaction(async (t) => {
    const doc = await t.get(userRef);
    if (!doc?.exists) throw new Error("User doesn't exist");
    const data = doc.data();
    if (!data) throw new Error("User doesn't exist");
    const tablesConfiguration = data.tables_configuration || {};
    tablesConfiguration[tableName] = JSON.stringify(config);

    return await t.update(userRef, {
      tables_configuration: tablesConfiguration,
    });
  });
}
