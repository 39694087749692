import lodashGet from "lodash/get";
import { GridValueGetterParams, GridCellValue } from "@material-ui/x-grid";

function valueGetter(params: GridValueGetterParams): GridCellValue {
  return lodashGet(params.row, params.field);
}

export function valueGetterWithDefault(def: GridCellValue = '') {
  return (params: GridValueGetterParams): GridCellValue => {
    return valueGetter(params) || def;
  };
}

export default valueGetter;
