import React, { useRef, useMemo, useState } from "react";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";

import { PlaidAddIntegrationDialogContent } from "src/components/PlaidAddIntegrationDialog";

import DialogTitle from "./DialogTitle";
import type Step from "./Step";

interface PlaidAddNewProps {
  onClose: () => void;
  onStepChange: (step: Step) => void;
}

function InProcess({
  onClose,
  onStepChange,
  accountName,
  onAnother,
}: PlaidAddNewProps & { accountName: string; onAnother: () => void }) {
  return (
    <>
      <DialogTitle onClose={onClose}>Import in Porcess</DialogTitle>
      <MuiDialogContent>
        <DialogContentText>
          Congrats! Your import from {accountName} has started. Add another
          account or click continue set-up below.
        </DialogContentText>
      </MuiDialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            onAnother();
          }}
          color="primary"
        >
          Connect Another Account
        </Button>
        <Button
          variant="contained"
          color="primary"
          autoFocus
          onClick={() => {
            onStepChange("expenses:do-you-have-other");
          }}
        >
          Continue Set-up
        </Button>
      </DialogActions>
    </>
  );
}

function cleanName(name?: string) {
  return name ? decodeURIComponent(name) : name;
}

function PlaidAddNew({ onClose, onStepChange }: PlaidAddNewProps) {
  const [accountName, setAccountName] = useState(
    cleanName(document.location.hash.split("#")[2]) || ""
  );
  const onCloseRef = useRef(onClose);
  onCloseRef.current = onClose;
  const dialogTitle = useMemo(() => {
    return (props) => (
      <DialogTitle onClose={() => onCloseRef.current()} {...props} />
    );
  }, []);

  return accountName ? (
    <InProcess
      onClose={onClose}
      onStepChange={onStepChange}
      accountName={accountName}
      onAnother={() => setAccountName("")}
    />
  ) : (
    <PlaidAddIntegrationDialogContent
      type="expense"
      DialogTitle={dialogTitle}
      onGoBack={() => {
        onStepChange("plaid:would-you-like-to-connect");
      }}
      onFinish={(accountName) => setAccountName(accountName)}
      redirect="/get-started#plaid:add-new#{name}"
    />
  );
}

export default PlaidAddNew;
