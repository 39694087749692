import * as React from "react";
import { useDispatch } from "react-redux";
import { useFormik, FormikHelpers as FormikActions } from "formik";

import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { LOGO } from "../../assets/svgs";

import { supportLogin } from "src/apiService/modules/support";
import { setSupportLoginToken } from "src/store/support/actions";

interface LoginFormValues {
  username: string;
  password: string;
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: 12,
    position: "relative",
  },
  loading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-12px",
    marginLeft: "-12px",
  },
}));

export const LoginPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: async (
      values: LoginFormValues,
      { setSubmitting, setStatus }: FormikActions<LoginFormValues>
    ) => {
      if (!values.username && !values.password) return;
      try {
        const result = await supportLogin(values.username, values.password);
        if (result.data.token) {
          dispatch(setSupportLoginToken(result.data.token));
        } else {
          setStatus({ error: "Invalid username / password" });
        }
      } catch (e) {
        setStatus({ error: "System error" });
      }
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Box className="mb-5 h-36">
          <img src={LOGO} alt="My Reseller Genie" className="h-full" />
        </Box>
        <Typography component="h1" variant="h5">
          Support Sign in
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.username}
            disabled={formik.isSubmitting}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            disabled={formik.isSubmitting}
          />
          {formik.status?.error && !formik.isSubmitting && (
            <Alert severity="error">{formik.status?.error}</Alert>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={formik.isSubmitting}
              >
                Sign In
                {formik.isSubmitting && (
                  <CircularProgress
                    className={classes.loading}
                    size={24}
                    color="inherit"
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default LoginPage;
