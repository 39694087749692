import React, { useState } from "react";

import { fetchStripeLink } from "src/apiService/modules/system";
import { StatefulLoadableButton } from "src/components/LoadableButton";

import StripeDialog from "./StripeDialog";

function ManageSubscriptionButton() {
  const [link, setLink] = useState<string | undefined>(undefined);

  return (
    <>
      <StatefulLoadableButton
        variant="contained"
        color="primary"
        onClick={async () => {
          const { data } = await fetchStripeLink();
          setLink(data.url);
        }}
      >
        Manage Subscription
      </StatefulLoadableButton>
      <StripeDialog
        open={!!link}
        handleClose={() => {
          setLink(undefined);
        }}
        link={link}
      />
    </>
  );
}

export default ManageSubscriptionButton;
