import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, TextField, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";

import Autocomplete from "@material-ui/lab/Autocomplete";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

import { makeStyles } from "@material-ui/core/styles";

import InfoTooltip from "src/components/InfoTooltip";
import { shiftPendingUpload } from "src/store/sale/actions";
import {
  getPendingSalesUploads,
  getUnmatchedUploads,
} from "src/store/sale/selector";
import { colors } from "src/theme";
import { formatDate, formatDollars } from "src/utils";
import NumberField from "src/components/NumberField";

interface ConfirmDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

function ConfirmDialogContent({
  onCancel,
  onConfirm,
}: Omit<ConfirmDialogProps, "open">) {
  return (
    <>
      <DialogTitle>Add All Pending Sales</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to add all these items without matching to
          inventory?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="contained" onClick={onCancel}>
          No
        </Button>
        <Button color="primary" variant="contained" onClick={onConfirm}>
          Yes
        </Button>
      </DialogActions>
    </>
  );
}

function ConfirmDialog({ open, onCancel, onConfirm }: ConfirmDialogProps) {
  return (
    <Dialog open={open} onClose={onCancel}>
      <ConfirmDialogContent onCancel={onCancel} onConfirm={onConfirm} />
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  saleDetails: {},
  header: {
    color: colors.blueColor,
    fontSize: 20,
    fontWeight: 500,
  },
  nowrap: {
    whiteSpace: "nowrap",
  },
  purchasePrice: {
    marginLeft: theme.spacing(1),
  },
}));

const infoText = `We couldn't find a perfect inventory match for some of your sales based on item title and/or SKU.
Please match the sales details with an existing inventory item. If the item is not in your inventory, enter the inventory
data for the sale.`;

const ManualSalesMatching = ({
  gotoAddInventory,
  onSave,
  noAutomaticMatchCount,
  gotoTable,
  selectableInventory,
  render,
  onSaveAll,
}: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [selectedInventory, setSelectedInventory] = useState<any>(null);
  const unmatchedSales: any[] = useSelector(getUnmatchedUploads);
  const totalUploads = useSelector(getPendingSalesUploads);
  const selectedSale = unmatchedSales[0];
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const save = () => {
    onSave(selectedInventory);
    setSelectedInventory(null);
  };

  const removeItem = () => dispatch(shiftPendingUpload());

  return (
    <>
      <ConfirmDialog
        open={openConfirmDialog}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => {
          onSaveAll();
        }}
      />
      {render({
        title: "Sales Matching",
        main: (
          <>
            <Typography variant="body1">
              Please match these sales with an existing inventory item.
              <InfoTooltip text={infoText} />
            </Typography>
            <Typography variant="h2" component="h2" className={classes.header}>
              Sale
            </Typography>
            <ul className={classes.saleDetails}>
              <li>Item title: {selectedSale.item_title || "(None)"}</li>
              {!!selectedSale.sku && <li>SKU: {selectedSale.sku}</li>}
              <li>
                Sold Date: {formatDate(selectedSale.sale_date, "MM/DD/YYYY")}
              </li>
              <li>Sale Price: {formatDollars(selectedSale.sale_price)}</li>
            </ul>
            <Typography variant="h2" component="h2" className={classes.header}>
              Inventory
            </Typography>
            <Autocomplete
              autoSelect
              options={selectableInventory}
              getOptionLabel={(item) => {
                const sku = item.sku;
                const title = item.item_title;
                return [sku, title].filter(Boolean).join(" - ");
              }}
              getOptionSelected={(opt, v) => {
                return opt?.id === v?.id;
              }}
              value={selectedInventory || null}
              onChange={(_e, value) => {
                setSelectedInventory(
                  value
                    ? {
                        ...value,
                        purchase_price_total: value.purchase_price,
                        purchase_price:
                          Math.round(value.purchase_price * 100 / (value.quantity || 1)) / 100,
                      }
                    : value
                );
              }}
              className="w-11/12"
              autoHighlight
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  label="Item Sold"
                  className="w-full"
                  variant="outlined"
                />
              )}
            />
            {!!selectedInventory && (
              <ul className={classes.saleDetails}>
                <li>Item title: {selectedInventory.item_title}</li>
                <li>SKU: {selectedInventory.sku}</li>
                <li>
                  Purchase Date:{" "}
                  {formatDate(selectedInventory.purchase_date, "MM/DD/YYYY")}
                </li>
                <li>
                  <Box display="flex" alignItems="center">
                    Purchase Price:
                    <FormControl
                      size="small"
                      className={classes.purchasePrice}
                      error={!!selectedInventory?.error}
                    >
                      <NumberField
                        Component={Input as any}
                        value={selectedInventory.purchase_price}
                        onChange={(e) => {
                          const value = e.target.value;
                          const total = totalUploads.reduce((t, sale) => {
                            if (selectedSale.uuid === sale.uuid)
                              return t + (value || 0);
                            if (sale.inventoryId === selectedInventory.id)
                              return t + sale.purchase_price;
                            return t;
                          }, 0);
                          setSelectedInventory((i) => {
                            const newInventory = {
                              ...i,
                              purchase_price: value,
                            };

                            if (
                              (newInventory.quantity || 1) > 1 &&
                              total + (value || 0) >
                                newInventory.purchase_price_total
                            ) {
                              newInventory.error =
                                "Cannot allocate more than the current inventory total purchase price.";
                            } else {
                              delete newInventory.error;
                            }

                            return newInventory;
                          });
                        }}
                        {...({
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        } as any)}
                      />
                      {selectedInventory?.error ? (
                        <FormHelperText>
                          {selectedInventory.error}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Box>
                </li>
                <li>Quantity: {selectedInventory.quantity || 1}</li>
                <li>
                  Current Total Inventory Value:{" "}
                  {formatDollars(selectedInventory.purchase_price_total || 0)}
                </li>
              </ul>
            )}
          </>
        ),
        actions: (
          <>
            <Typography className={classes.nowrap}>
              Unmatched Sales: {unmatchedSales.length}/{totalUploads.length}
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              onClick={removeItem}
              className={classes.nowrap}
            >
              Remove Sale From Upload
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setOpenConfirmDialog(true);
              }}
              className={classes.nowrap}
            >
              Add All Sales Without Matching
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={gotoAddInventory}
              className={classes.nowrap}
            >
              Add Inventory Details
            </Button>
            <Button
              disabled={!selectedInventory || !!selectedInventory.error}
              color="primary"
              variant="contained"
              onClick={save}
              className={classes.nowrap}
            >
              Match
            </Button>
          </>
        ),
        extraActions:
          noAutomaticMatchCount !== unmatchedSales.length ? (
            <Button color="primary" variant="contained" onClick={gotoTable}>
              Back to Automatic Matching
            </Button>
          ) : null,
      })}
    </>
  );
};

export default ManualSalesMatching;
