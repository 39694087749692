import React, { useRef } from "react";
import { useSelector } from "react-redux";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import XGrid from "src/components/CSVFormattedXGrid";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from "@material-ui/x-grid";

import Typography from "@material-ui/core/Typography";
import GetAppIcon from "@material-ui/icons/GetApp";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { StatefulLoadableIconButton } from "src/components/LoadableIconButton";
import { valueGetterWithDefault } from "src/utils/valueGetter";
import { formatCurrency } from "src/utils/formatter";
import vendorRenderCell from "src/utils/vendorRenderCell";
import tooltipRenderCell from "src/utils/tooltipRenderCell";
import { userGetInventoryTypeSelector } from "src/store/system/selector";

import { ConsignmentCommissionReport as ConsignmentCommissionReportType } from "./report";
import renderReport from "./renderReport";

interface ConsignmentCommissionReportProps {
  report: ConsignmentCommissionReportType;
}

const useValueBoxStyles = makeStyles((theme) => ({
  box: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    minWidth: "15%",
  },
  children: {
    textAlign: "right",
  },
}));

function ValueBox({ title, children }) {
  const classes = useValueBoxStyles();
  return (
    <Paper className={classes.box} variant="outlined">
      <Typography
        variant="button"
        component="p"
        display="block"
        className="text-blue"
      >
        {title}
      </Typography>
      <Typography variant="body2" className={classes.children}>
        {new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(children)}
      </Typography>
    </Paper>
  );
}

const Columns = [
  {
    field: "transaction_id",
    headerName: "Platform ID",
    width: 150,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "item_title",
    headerName: "Item Title",
    minWidth: 200,
    flex: 1,
    renderCell: tooltipRenderCell,
  },
  {
    field: "sale_date",
    headerName: "Sale Date",
    type: "date",
    width: 110,
  },
  {
    field: "sale_price",
    headerName: "Sale Price",
    type: "number",
    width: 145,
    valueFormatter: formatCurrency,
  },
  {
    field: "purchase_price",
    headerName: "Purchase Price",
    type: "number",
    width: 145,
    valueFormatter: formatCurrency,
  },
  {
    field: "shipping_cost",
    headerName: "Shipping Costs",
    type: "number",
    width: 145,
    valueFormatter: formatCurrency,
  },
  {
    field: "shipping_cost_analytics",
    headerName: "Shipping Costs (From Expense Detail)",
    type: "number",
    width: 265,
    valueFormatter: formatCurrency,
  },
  {
    field: "transaction_fees",
    headerName: "Transaction Fees",
    type: "number",
    width: 155,
    valueFormatter: formatCurrency,
  },
  {
    field: "other_fees",
    headerName: "Other Costs (From Expense Detail)",
    type: "number",
    width: 250,
    valueFormatter: formatCurrency,
  },
  {
    field: "gross_profit",
    headerName: "Gross profit",
    type: "number",
    width: 125,
    valueFormatter: formatCurrency,
  },
  {
    field: "sale_platform",
    headerName: "Sale Platform",
    width: 140,
    renderCell: tooltipRenderCell,
  },
  {
    field: "return_date",
    headerName: "Return Date",
    type: "date",
    width: 125,
  },
  {
    field: "extra_shipping_cost",
    headerName: "Return Shipping Cost",
    type: "number",
    width: 175,
    valueFormatter: formatCurrency,
  },
  {
    field: "extra_transaction_fees",
    headerName: "Return Transaction Fees",
    type: "number",
    width: 195,
    valueFormatter: formatCurrency,
  },
  {
    field: "vendor",
    headerName: "Vendor",
    width: 130,
    renderCell: vendorRenderCell,
    valueGetter: (params) => params.row.vendorName || params.value || "",
  },
];
const ColumnsCashIgnored = [
  "purchase_date",
  "purchase_price",
  "platforms_listed",
];
const ColumnsCash = Columns.filter(
  ({ field }) => !ColumnsCashIgnored.includes(field)
);

function formatDate({ value }) {
  return value ? new Intl.DateTimeFormat("en-US").format(value) : "";
}
const PrintColumns = [
  { field: "item_title", headerName: "Item Title" },
  {
    field: "sale_date",
    headerName: "Sale Date",
    valueFormatter: formatDate,
  },
  {
    field: "return_date",
    headerName: "Return Date",
    valueFormatter: formatDate,
  },
  {
    field: "gross_profit",
    headerName: "Gross profit",
    valueFormatter: formatCurrency,
  },
];

function Toolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarColumnsButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      borderRight: `1px solid #f0f0f0`,
      padding: "0px 10px",
      fontSize: "12px",
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      borderBottom: `1px solid #f0f0f0`,
    },
    "& .MuiDataGrid-cell": {
      color: `rgba(0,0,0,.85)`,
    },
    "& .MuiDataGrid-iconSeparator": {
      opacity: 0,
    },
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#fafafa",
      borderTop: "1px solid #f0f0f0",
      fontSize: "12px",
    },
    "& .MuiDataGrid-colCell": {
      borderRight: `1px solid #f0f0f0`,
      padding: "0px 10px",
    },
  },
});

function PrintTable({ rows }) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {PrintColumns.map(({ field, headerName }) => (
              <TableCell key={field}>{headerName}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              {PrintColumns.map((c) => {
                let value = row[c.field] || "";
                if (c.valueFormatter) value = c.valueFormatter({ value });
                return <TableCell key={c.field}>{value}</TableCell>;
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function ConsignmentCommissionReport({
  report,
}: ConsignmentCommissionReportProps) {
  const classes = useStyles();
  const bodyRef = useRef<HTMLDivElement>(null);
  const inventoryTypeIsCash =
    useSelector(userGetInventoryTypeSelector) === "cash";
  const handleDownloadReport = async () => {
    if (!bodyRef?.current) return;
    const div = document.createElement("div");
    div.innerHTML = renderReport(
      report.data.reportDetails.titleFull,
      bodyRef.current.outerHTML,
      false,
      "margin: 0 24px"
    );

    for (const ele of div.querySelectorAll("button, a")) {
      if (ele.parentNode) ele.parentNode.removeChild(ele);
    }

    // We are going to remove the XGrid table and manually write it
    // It isn't the best option, but, the easiest one.
    // Ideally we would render the pdf using a real render (not this one that just prints an image)
    const table = div.querySelector(".consignment-commission-report-table");
    if (table) {
      await new Promise<void>((rs) =>
        ReactDOM.render(<PrintTable rows={report.data.sales} />, table, () =>
          rs()
        )
      );
    }

    const html2pdf = await import("html2pdf.js");
    await html2pdf
      .default()
      .set({
        filename: report.data.reportDetails.filename,
        jsPDF: {
          orientation: "landscape",
        },
        margin: [2, 0, 2, 0],
      })
      .from(div)
      .save();
    if (table) ReactDOM.unmountComponentAtNode(table);
  };

  return (
    <Paper className={`mt-5 p-5 h-auto ${classes.root}`}>
      <Box display="flex" alignItems="center">
        <h2 className={`w-full text-left text-blue z-10 flex-1`}>
          {report.data.reportDetails.titleFull}
        </h2>
        <Box display="flex">
          <StatefulLoadableIconButton onClick={handleDownloadReport}>
            <GetAppIcon />
          </StatefulLoadableIconButton>
        </Box>
      </Box>
      <div ref={bodyRef}>
        <Box display="flex" justifyContent="center">
          <ValueBox title="Sales">{report.data.totals.sales}</ValueBox>
          <ValueBox title="Refunds">{report.data.totals.returns}</ValueBox>
          <ValueBox title="Expenses">{report.data.totals.expenses}</ValueBox>
          <ValueBox title="Gross Profit">
            {report.data.totals.grossProfit}
          </ValueBox>
          <ValueBox title="Commission Due">
            {report.data.totals.commission}
          </ValueBox>
        </Box>
        <Box mt={2}>
          <h2 className="text-blue">Transaction Detail</h2>
          <Box minHeight="75vh" className="consignment-commission-report-table">
            <XGrid
              components={{
                Toolbar,
              }}
              density="compact"
              rows={report.data.sales}
              columns={inventoryTypeIsCash ? ColumnsCash : Columns}
              disableSelectionOnClick
              autoHeight
              pageSize={report.data.sales.length}
              hideFooter
              hideFooterPagination
              disableColumnMenu
            />
          </Box>
        </Box>
      </div>
    </Paper>
  );
}

export default ConsignmentCommissionReport;
