export enum SALE_FILTER {
  DAY,
  WEEK,
  MONTH,
  YEAR,
  ALL_TIME,
  CUSTOM,
}

export interface SaleFilter {
  filter: SALE_FILTER;
  period: {
    start: Date;
    end: Date;
  };
}

export function isSalesFilterValid(sales_filter: SALE_FILTER | SaleFilter) {
  switch (sales_filter) {
    case SALE_FILTER.DAY:
    case SALE_FILTER.WEEK:
    case SALE_FILTER.MONTH:
    case SALE_FILTER.YEAR:
    case SALE_FILTER.ALL_TIME:
      return true;
    default:
      break;
  }

  if ((sales_filter as SaleFilter)?.filter === SALE_FILTER.CUSTOM) {
    const filter = sales_filter as SaleFilter;
    if (
      filter?.period?.start?.getTime &&
      !isNaN(filter.period.start.getTime()) &&
      filter?.period?.end?.getTime &&
      !isNaN(filter.period.end.getTime())
    ) {
      if (filter.period.start <= filter.period.end) return true;
    }
  }

  return false;
}

export function fixSalesFilter(sales_filter: SALE_FILTER | SaleFilter) {
  switch (sales_filter) {
    case SALE_FILTER.DAY:
    case SALE_FILTER.WEEK:
    case SALE_FILTER.MONTH:
    case SALE_FILTER.YEAR:
    case SALE_FILTER.ALL_TIME:
      return sales_filter;
    default:
      break;
  }

  if ((sales_filter as SaleFilter)?.filter === SALE_FILTER.CUSTOM) {
    const filter = sales_filter as any;

    if (!isNaN(filter?.period?.start?.seconds))
      filter.period.start = new Date(filter.period.start.seconds * 1000);

    if (!isNaN(filter?.period?.end?.seconds))
      filter.period.end = new Date(filter.period.end.seconds * 1000);

    if (
      filter?.period?.start?.getTime &&
      !isNaN(filter.period.start.getTime()) &&
      filter?.period?.end?.getTime &&
      !isNaN(filter.period.end.getTime())
    ) {
      if (filter.period.start <= filter.period.end) return filter;
    }
  }

  return SALE_FILTER.ALL_TIME;
}
