import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { XGrid, GridSelectionModel } from "@material-ui/x-grid";

import { setActiveDialog } from "src/store/adminHtml/actions";
import { getUnreviewedSales } from "src/store/sale/selector";
import { formatCurrency } from "src/utils";
import { reviewManySales } from "src/apiService/modules/sales";
import { StatefulLoadableButton } from "src/components/LoadableButton";

// import SalesMatchingDialog from "src/pages/RootDialogs/SalesMatching";
import SalesMatchingDialog from "./SalesMatching";
import VendorField from "src/components/VendorField";
import WalkTrhough from "./WalkThrough";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "80vh",
    display: "relative",
  },
}));

const Columns = [
  {
    field: "item_title",
    headerName: "Item Title",
    flex: 1,
  },
  {
    field: "sku",
    headerName: "SKU",
    width: 200,
  },
  {
    field: "sale_date",
    headerName: "Sale Date",
    type: "date",
    width: 150,
  },
  {
    field: "sale_price",
    headerName: "Sale Price",
    width: 150,
    valueFormatter: formatCurrency,
  },
];

function SalesReviewContent({
  onMatchToInventoryClick,
}: {
  onMatchToInventoryClick: (sales: any[]) => void;
}) {
  const [vendors, setVendors] = useState<Record<string, string>>({});
  const classes = useStyles();
  const unreviewedSales = useSelector(getUnreviewedSales);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const handlersRef = useRef({
    vendors,
    setVendors,
  });

  handlersRef.current.vendors = vendors;
  handlersRef.current.setVendors = setVendors;

  const columns = useMemo(() => {
    const [title, ...rest] = Columns;
    return [
      title,
      {
        field: "vendor",
        headerName: "Vendor",
        width: 200,
        renderCell: (params) => {
          return (
            <VendorField
              label={null}
              margin="dense"
              value={handlersRef.current.vendors[params.id]}
              onChange={(vendor) => {
                handlersRef.current.setVendors((v) => ({
                  ...v,
                  [params.id]: vendor,
                }));
              }}
            />
          );
        },
      },
      ...rest,
    ];
  }, []);

  // Close when not loading and unreviewedSales.length === 0

  return (
    <>
      <DialogTitle>Review Sales</DialogTitle>
      <DialogContent className={classes.container}>
        <XGrid
          selectionModel={selectionModel}
          onSelectionModelChange={(sm) => setSelectionModel(sm)}
          checkboxSelection
          disableSelectionOnClick
          columns={columns}
          rows={unreviewedSales}
          className="review-sales-dialog"
        />
      </DialogContent>
      <DialogActions>
        <div
          id="review-sales-walkthough-actions"
          className="MuiDialogActions-spacing"
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              if (selectionModel?.length)
                onMatchToInventoryClick(
                  selectionModel.map((id) =>
                    unreviewedSales.find((s) => s.id === id)
                  )
                );
            }}
            disabled={
              unreviewedSales.length === 0 || selectionModel.length === 0
            }
          >
            Match to Inventory
          </Button>
          <StatefulLoadableButton
            color="primary"
            variant="contained"
            disabled={selectionModel.length === 0}
            onClick={async () => {
              await reviewManySales(
                selectionModel.map((id) => `${id}`),
                false,
                vendors
              );
              setSelectionModel([]);
            }}
          >
            Mark Reviewed
          </StatefulLoadableButton>
        </div>
      </DialogActions>
    </>
  );
}

export interface SalesReviewProps {
  open: boolean;
}

function SalesReview({ open }: SalesReviewProps) {
  const dispatch = useDispatch();
  const [unreviewedSales, setUnreviewedSales] = useState<any[] | undefined>(
    undefined
  );
  useEffect(() => {
    if (!open) setUnreviewedSales(undefined);
  }, [open]);
  const openMatching = !!unreviewedSales;
  return (
    <>
      <Dialog
        open={open && !openMatching}
        onClose={() => dispatch(setActiveDialog(""))}
        fullWidth={true}
        maxWidth="lg"
      >
        <SalesReviewContent
          onMatchToInventoryClick={(sales) => {
            setUnreviewedSales(sales);
          }}
        />
        <WalkTrhough />
      </Dialog>
      <SalesMatchingDialog
        open={open && openMatching}
        unreviewedSales={unreviewedSales}
      />
    </>
  );
}

export default SalesReview;
