import React, { useEffect, useState, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Button, Grid, Paper } from "@material-ui/core";

import {
  LicenseInfo,
  GridSelectionModel,
} from "@material-ui/x-grid";
import XGrid from "src/components/CSVFormattedXGrid";
import { createTableToolbar } from "src/components/TableToolbar";

import { makeStyles } from "@material-ui/core/styles";

import InfoTooltip from "../../components/InfoTooltip";
import ConfirmDialog from "src/components/ConfirmDialog";
import EditCashActivityDialog from "./EditCashActivityDialog";

import { setFilter } from "../../store/cashActivity/actions";
import { clearUpdateMethod } from "../../store/common";
import { cashActivitySelector } from "../../store/cashActivity/selector";
import { bankStatementSelector } from "../../store/bankStatement/selector";

import { formatCurrency } from "../../utils";
import { CashActivity } from "../../interfaces";
import { METHOD_TYPE } from "../../enums";

import { X_GRID_LICENSE_KEY } from "../../config";
import { deleteCashActivity } from "src/apiService/modules/cashActivity";
LicenseInfo.setLicenseKey(X_GRID_LICENSE_KEY);

const tableColumnInfo = {
  bank_statement: "Matches the cash activity to a bank statement.",
  other_activity:
    "If you’re using a personal bank account for your business, you can record personal activity here.",
  business_activity:
    "All cash activity for the business should be recorded here.",
};

const renderHeaderLabel = (params) => {
  const tooltip = tableColumnInfo[params.field];
  return (
    <div className="MuiDataGrid-columnHeaderTitle">
      {params.colDef.headerName}
      {!!tooltip && <InfoTooltip size="small" text={tooltip} />}
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-root": {
      minHeight: "300px",
      height: "calc(50vh - 300px)",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      borderRight: `1px solid #f0f0f0`,
      padding: "0px 10px",
      fontSize: "12px",
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      borderBottom: `1px solid #f0f0f0`,
    },
    "& .MuiDataGrid-cell": {
      color: `rgba(0,0,0,.85)`,
    },
    "& .MuiDataGrid-iconSeparator": {
      opacity: 0,
    },
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#fafafa",
      borderTop: "1px solid #f0f0f0",
      fontSize: "12px",
    },
    "& .MuiDataGrid-colCell": {
      borderRight: `1px solid #f0f0f0`,
      padding: "0px 10px",
    },
  },
});

const TableToolbar = createTableToolbar({
  options: { fileName: "My Reseller Genie - Cash Activities" }
});

const Columns = [
  {
    field: "id",
    hide: true,
  },
  {
    field: "bank_statement",
    headerName: "Bank Statement",
    width: 220,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "other_activity",
    headerName: "Other Activity",
    type: "number",
    width: 200,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "business_activity",
    headerName: "Business Activity",
    type: "number",
    width: 220,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  { field: "date", headerName: "Date", type: "date", width: 150 },
  {
    field: "name",
    headerName: "Charge Description",
    width: 200,
    type: "string",
  },
  { field: "description", headerName: "Description", type: "date", width: 300 },
];

export const CashActivityContent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    items: initialItems,
    method,
    loading,
    error,
    filter: filterModel,
  } = useSelector(cashActivitySelector);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const { items: bankStatements } = useSelector(bankStatementSelector);

  const [editEntry, setEditEntry] = useState<string | boolean>(false);
  const [showDelete, setShowDelete] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [showLastDelete, setShowLastDelete] = useState(false);

  const items = useMemo(() => {
    const items = [...initialItems] || [];
    items.sort((a, b) => {
      const legend = [-1, 0, 1];
      return legend[+((a.date as Date) < (b.date as Date))];
    });

    return items.map((item) => {
      const bankStatementId = item.bank_statement_id || "";
      const bankStatement = bankStatements.find(
        (bs) => bs.id === bankStatementId
      );

      return {
        ...item,
        bank_statement: bankStatement?.bank_statement_name || "Unreconciled",
      };
    });
  }, [initialItems, bankStatements]);

  useEffect(() => {
    if (
      !error &&
      !loading &&
      (method === METHOD_TYPE.DELETE ||
        method === METHOD_TYPE.UPDATE ||
        method === METHOD_TYPE.CREATE)
    ) {
      setEditEntry(false);
      dispatch(clearUpdateMethod());
    }
  }, [loading]);

  const handleClickOpenEdit = () => {
    if (selectionModel[0]) setEditEntry(String(selectionModel[0]));
  };

  const handleClickOpenCreate = () => {
    setEditEntry(true);
  };

  const handleCloseEdit = () => {
    setEditEntry(false);
  };

  const handleSelectItem = (e: any) => {
    setSelectionModel(e);
  };

  const handleSelectAndEdit = (e: any) => {
    setSelectionModel([e.row.id]);
    setEditEntry(e.row.id);
  };

  const handleChangeFilterModel = (e: any) => {
    dispatch(setFilter(e));
  };

  const handleDeleteSelected = async () => {
    setShowDelete(false);
    if (selectionModel.length > 0) {
      await deleteCashActivity(selectionModel.map(item => `${item}`));
      setSelectionModel([]);
      setEditEntry(false);
      setShowLastDelete(false);
    }
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Paper className="p-5 h-auto">
          <div className="flex justify-between flex-wrap mb-3">
            <h2 className="text-left text-blue mb-0">
              Cash Activities
              <InfoTooltip text="A detail of the money going into and coming out of your bank account." />
            </h2>
            <div className={"flex items-center"}>
              <Button
                variant="contained"
                color="primary"
                className="h-9 ml-3 mt-1"
                onClick={handleClickOpenCreate}
              >
                Add
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="h-9 ml-3 mt-1"
                disabled={
                  loading ||
                  !selectionModel ||
                  !selectionModel[0] ||
                  selectionModel.length > 1
                }
                onClick={handleClickOpenEdit}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                color="secondary"
                disabled={!items.length || loading}
                className="h-9 ml-3 mt-1"
                onClick={() => {
                  if (!items.length) return;
                  const last = items.reduce(
                    (last: CashActivity, current: CashActivity): CashActivity =>
                      (last.date as Date) > (current.date as Date)
                        ? last
                        : current,
                    items[0]
                  );
                  setSelectionModel([last.id]);
                  setShowDelete(true);
                  setShowLastDelete(true);
                }}
              >
                Delete Last Entry
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className="h-9 ml-3 mt-1"
                disabled={loading || selectionModel.length === 0}
                onClick={() => {
                  setShowDelete(true);
                  setShowLastDelete(false);
                }}
                style={{ marginRight: 10 }}
              >
                Delete Selected
              </Button>
            </div>
          </div>

          <XGrid
            checkboxSelection
            checkboxSelectionVisibleOnly
            rows={items}
            columns={Columns}
            headerHeight={35}
            rowHeight={35}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => {
              setPageSize(newPageSize);
            }}
            rowsPerPageOptions={[10, 25, 50, 100]}
            pagination
            loading={loading && method === METHOD_TYPE.LIST}
            components={{
              Toolbar: TableToolbar,
            }}
            filterModel={filterModel}
            selectionModel={selectionModel}
            onSelectionModelChange={handleSelectItem}
            onCellDoubleClick={handleSelectAndEdit}
            onFilterModelChange={handleChangeFilterModel}
          />
          <EditCashActivityDialog
            id={editEntry}
            onClose={handleCloseEdit}
          />
          <ConfirmDialog
            open={showDelete}
            title="Confirm"
            text={
              selectionModel.length > 0 && !showLastDelete
                ? "Are you sure you want to delete " +
                  selectionModel.length +
                  " transaction(s)?"
                : "Are you sure you want to delete the last Activity?"
            }
            onCancel={() => {
              setShowDelete(false);
              setSelectionModel([]);
            }}
            onConfirm={handleDeleteSelected}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
