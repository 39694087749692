import { createSelector } from "reselect";
import { AppState } from "..";
import { CashActivityState } from "../../interfaces/cashActivityState.interface";

const getCashActivityState = (state: AppState) => state.cashActivity;

export const cashActivitySelector = createSelector(getCashActivityState, (cashActivity: CashActivityState) => cashActivity);
export const getItems = createSelector(getCashActivityState, (state: CashActivityState) => state.items);
export const getUnreviewedCashActivities = createSelector(getCashActivityState, (cashActivityState) => {
    return cashActivityState.items.filter((item) => !item.reviewed && item.reviewed != null);
});
export const getCashActivity = createSelector([getItems, (_, id: string) => id], (items, id) => items?.find((c) => c.id === id));
