import axios from "axios";
import { getAuthToken } from "../config";

const API_ENDPOINT = process.env.REACT_APP_API_URL;

const Client = axios.create({
  baseURL: API_ENDPOINT,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});


const unauthenticatedUrls = [
  '/auth/ebay/url',
  '/bubblecheck', // get user status
  '/bubblereset',
];

Client.interceptors.request.use(
  async (config) => {
    if (unauthenticatedUrls.indexOf(config.url as string) >= 0 ||
      (config.url as string).indexOf('bubblereset') >= 0 ||
      (config.url as string).indexOf('bubblecheck') >= 0) {
      return config;
    }
    const token = await getAuthToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete Client.defaults.headers.common.Authorization;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export default Client;
