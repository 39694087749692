import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import firebase from "firebase/app";
import capitalize from "lodash/capitalize";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import DialogTitle from "src/pages/GetStarted/DialogTitle";
import { userPlanSelector } from "src/store/system/selector";
import { setActiveDialog } from "src/store/adminHtml/actions";
import useUserEffect from "src/utils/useUserEffect";
import { userDontShowDialogsSelector } from "src/store/system/selector";
import { USER_PLAN as UserPlan } from "src/enums/common.enum";
import { updateUserApi } from "src/apiService/modules/system";

import DontShowThisMessageAgainCheckbox from "src/components/DontShowThisMessageAgainCheckbox";

const Links = {
  [UserPlan.BASIC]:
    "https://myresellergenie.zendesk.com/hc/en-us/articles/14824842036123-Daily-Use-Basic-Subscription",
  [UserPlan.PREMIUM]:
    "https://myresellergenie.zendesk.com/hc/en-us/articles/14824966205211-Daily-Use-Premium-Subscription",
  [UserPlan.ULTIMATE]:
    "https://myresellergenie.zendesk.com/hc/en-us/articles/14825115256987-Daily-Use-Ultimate-Subscription",
};

const Key = "maintenance_activities_dialog" as const;

const useStyles = makeStyles((theme) => ({
  ul: {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
  },
  formControlLabelLabel: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
  },
}));

function Content({ onClose }: { onClose: () => void }) {
  const checkedRef = useRef(false);
  const plan = useSelector(userPlanSelector);
  const classes = useStyles();

  useEffect(
    () => () => {
      if (checkedRef.current) {
        updateUserApi({
          dontShowDialogs: firebase.firestore.FieldValue.arrayUnion(Key),
        });
      }
    },
    []
  );

  return (
    <>
      <DialogTitle onClose={onClose}>Keeping Up With Your Books</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Congrats on finishing set up! Moving forward, you need to maintain
          your books. From a big picture perspective, there’s three categories
          that you need to maintain:
        </DialogContentText>
        <ul className={classes.ul}>
          <li>Inventory</li>
          <li>Sales and Refunds</li>
          <li>Expenses</li>
        </ul>
        <DialogContentText>
          Learn more by reading the daily use article for your plan. Click the
          button below to access this article.
        </DialogContentText>
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            href={Links[plan as any] || Links[UserPlan.BASIC]}
            variant="contained"
            color="primary"
            target="_blank"
          >
            Daily Use: {capitalize(plan || "basic")} Subscription
          </Button>
        </Box>
        <DontShowThisMessageAgainCheckbox dialog={Key} justify="center" />
      </DialogContent>
    </>
  );
}

function MaintenanceActivitiesDialog({ open }) {
  const userDontShowDialogs = useSelector(userDontShowDialogsSelector);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setActiveDialog(""));
  };

  useUserEffect((user) => {
    if (user.dontShowDialogs?.includes(Key)) dispatch(setActiveDialog(""));
  }, []);

  return (
    <Dialog
      open={open && userDontShowDialogs && !userDontShowDialogs.includes(Key)}
      fullWidth
      maxWidth="md"
      disableEnforceFocus
    >
      <Content onClose={handleClose} />
    </Dialog>
  );
}

export default MaintenanceActivitiesDialog;
