import firebase from "firebase/app";
import "firebase/functions";
import "firebase/auth";
import {
  firebaseConfig,
  functionsEmulatorURL,
  authEmulatorURL,
} from "./firebase";

export * from "./storage";

export const initializeFirebase = () => {
  firebase.initializeApp(firebaseConfig);
  if (functionsEmulatorURL)
    firebase.functions().useFunctionsEmulator(functionsEmulatorURL);
  if (authEmulatorURL) firebase.auth().useEmulator(authEmulatorURL);
};

initializeFirebase();

export const AppConfig = {
  title: "My Reseller Genie",
};

export const STRIPE_PUBLIC_KEY: string = process.env.REACT_APP_STRIPE_PK || "";
export const TAPFILIATE_ACCOUNT_ID: string =
  process.env.REACT_APP_TAPFILIATE_ACCOUNT_ID || "";
export const X_GRID_LICENSE_KEY: string =
  process.env.REACT_APP_X_GRID_LICENSE_KEY || "";
