import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";

import { USER_PLAN as UserPlan } from "src/enums/common.enum";
import { userPlanSelector } from "src/store/system/selector";
import ConditionalTooltip from "src/components/ConditionalTooltip";

import DialogTitle from "./DialogTitle";
import type Step from "./Step";

interface PlaidWouldYouLikeToConnectProps {
  onClose: () => void;
  onStepChange: (step: Step) => void;
}

type Options = "yes" | "no";

const useStyles = makeStyles((theme) => ({
  content: {
    margin: "auto",
  },
  radioGroup: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(4),
  },
}));

function PlaidWouldYouLikeToConnect({
  onClose,
  onStepChange,
}: PlaidWouldYouLikeToConnectProps) {
  const classes = useStyles();
  const [value, setValue] = useState<Options>();
  const plan = useSelector(userPlanSelector);
  const isBasic = plan === UserPlan.BASIC;

  return (
    <>
      <DialogTitle onClose={onClose}>Set Up Questions - 6/8</DialogTitle>
      <MuiDialogContent className={classes.content}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            Would you like to connect a bank account or credit card to My
            Reseller Genie?
          </FormLabel>
          <RadioGroup
            value={value || null}
            onChange={(event) => {
              setValue((event.target as HTMLInputElement).value as Options);
            }}
            className={classes.radioGroup}
          >
            <ConditionalTooltip
              title="As a Basic user, you can’t connect a bank or credit card to your account. If you’d like this functionality, you can upgrade by clicking the “Upgrade” button in the bottom left of the screen."
              condition={isBasic}
            >
              <FormControlLabel
                value="yes"
                control={<Radio />}
                label="Yes"
                disabled={isBasic}
              />
            </ConditionalTooltip>
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </MuiDialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onStepChange("other:do-you-have-additional");
          }}
          color="primary"
          autoFocus
        >
          Go Back
        </Button>
        <Button
          variant="contained"
          disabled={!value}
          onClick={() => {
            onStepChange(
              value === "yes" ? "plaid:add-new" : "expenses:do-you-have-other"
            );
          }}
          color="primary"
          autoFocus
        >
          Next
        </Button>
      </DialogActions>
    </>
  );
}

export default PlaidWouldYouLikeToConnect;
