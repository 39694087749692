import React from "react";

import { useSelector } from "react-redux";
import { useFormik } from "formik";

import { TextField, Grid } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { toast } from "react-toastify";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import InputAdornment from "@material-ui/core/InputAdornment";

import { bankStatementSelector } from "../../store/bankStatement/selector";

import { CashActivity } from "../../interfaces";
import { Autocomplete } from "@material-ui/lab";

import NumberField from "src/components/NumberField";
import { getCashActivity } from "src/store/cashActivity/selector";
import LoadableButton from "src/components/LoadableButton";

import { upsertCashActivity } from "src/apiService/modules/cashActivity";

export interface EditCashActivityDialogProps {
  id: boolean | string;
  onClose: () => void;
}

interface ContentProps {
  isUpdate: boolean;
  initialValues: any; // TODO
  onClose: () => void;
}

const initFormValues: Partial<CashActivity> = {
  other_activity: undefined,
  bank_statement_id: undefined,
  business_activity: undefined,
  description: "",
  id: "",
  date: undefined,
};

function isValidDate(d: any) {
  if (Object.prototype.toString.call(d) === "[object Date]") {
    // it is a date
    if (isNaN(d.getTime())) {
      // d.valueOf() could also work
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function Content({ isUpdate, initialValues, onClose }: ContentProps) {
  const { items: bankStatements } = useSelector(bankStatementSelector);

  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors: Record<string, string> = {};
      if (!values.bank_statement_id)
        errors.bank_statement_id = "Required field!";

      if (values.business_activity === undefined)
        errors.business_activity = "Required field!";

      if (values.other_activity === undefined)
        errors.other_activity = "Required field!";

      if (!isValidDate(values.date)) errors.date = "Invalid date format";

      return errors;
    },
    onSubmit: async (values) => {
      try {
        await upsertCashActivity(values);
        toast.success("Saved successfully!");
        onClose();
      } catch (e) {
        toast.error(`There was an error: ${(e as Error).toString()}`);
      }
    },
  });

  const getErrorProps = (name: string) => {
    const error = !!(formik.touched[name] && formik.errors[name]);
    if (error)
      return {
        error,
        helperText: formik.errors[name],
      };
    return undefined;
  };

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      <DialogTitle>
        {isUpdate ? "Edit Cash Activity" : "Add Cash Activity"}
      </DialogTitle>

      <DialogContent>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disabled={formik.isSubmitting}
                options={bankStatements.map((bs) => bs.id)}
                getOptionLabel={(id) =>
                  bankStatements.find((bs) => bs.id === id)
                    ?.bank_statement_name || ""
                }
                value={formik.values.bank_statement_id || null}
                onChange={(ev, value) => {
                  formik.setFieldValue("bank_statement_id", value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="bank_statement_id"
                    onBlur={formik.handleBlur}
                    margin="dense"
                    label="Bank Statement"
                    placeholder="Bank Statement"
                    className="w-full"
                    variant="outlined"
                    {...getErrorProps("bank_statement_id")}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <NumberField
                {...getErrorProps("business_activity")}
                onBlur={formik.handleBlur}
                fullWidth
                variant="outlined"
                margin="dense"
                label="Business Activity"
                value={formik.values.business_activity}
                disabled={formik.isSubmitting}
                name="business_activity"
                onChange={formik.handleChange}
                allowNegative
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <NumberField
                {...getErrorProps("other_activity")}
                onBlur={formik.handleBlur}
                fullWidth
                variant="outlined"
                margin="dense"
                label="Other Activity"
                value={formik.values.other_activity}
                disabled={formik.isSubmitting}
                required
                name="other_activity"
                onChange={formik.handleChange}
                allowNegative
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                {...getErrorProps("description")}
                onBlur={formik.handleBlur}
                fullWidth
                variant="outlined"
                margin="dense"
                label="Description"
                value={formik.values.description}
                disabled={formik.isSubmitting}
                name="description"
                onChange={formik.handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <KeyboardDatePicker
                name="date"
                {...getErrorProps("date")}
                disableToolbar
                fullWidth
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                margin="dense"
                label="Date"
                autoOk={true}
                required
                value={formik.values.date || null}
                disabled={formik.isSubmitting}
                onChange={(e: any, t: any) => {
                  formik.setFieldValue("date", new Date(e));
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <LoadableButton
          type="submit"
          color="primary"
          variant="contained"
          disabled={!formik.dirty}
          loading={formik.isSubmitting}
        >
          {isUpdate ? "Update" : "Add"}
        </LoadableButton>
      </DialogActions>
    </form>
  );
}

function EditCashActivityDialog({ id, onClose }: EditCashActivityDialogProps) {
  const handleClose = () => {
    onClose();
  };
  const open = !!id;
  const isUpdate = id !== open;
  const initialValues =
    useSelector((state) =>
      open && isUpdate ? getCashActivity(state, id as string) : undefined
    ) || initFormValues;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="body"
      fullWidth
      maxWidth="sm"
    >
      <Content
        initialValues={initialValues}
        onClose={handleClose}
        isUpdate={isUpdate}
      />
    </Dialog>
  );
}

export default EditCashActivityDialog;
