import {
  getExtensionId,
  getCookies,
  createWindow,
  waitOnRemoveWindow,
  removeWindow,
  webRequest,
  checkExtensionVersion,
  type Cookie,
} from ".";

// fix-vim-highlight = }

export async function getMercariCookies(
  extensionId: string
): Promise<Cookie[]> {
  return await getCookies(extensionId, {
    domain: "mercari.com",
  });
}

const RequiredExtensionVersion = "1.0.2";

export function checkMercariRequiredExtensionVersion() {
  return checkExtensionVersion(RequiredExtensionVersion);
}

const RequiredCookies = ["_mwus", "_mwus.sig"];

function getRequiredCookies(cookies: Cookie[]): Record<string, string> | null {
  const requiredCookies = RequiredCookies.reduce((c, name) => {
    const cookie = cookies.find((c) => c.name === name)?.value;
    if (cookie) c[name] = cookie;
    return c;
  }, {} as Record<string, string>);

  return Object.keys(requiredCookies).length === RequiredCookies.length
    ? requiredCookies
    : null;
}

function isLoggedInMercari(cookies: Cookie[]): boolean {
  const mwus = cookies.find((c) => c.name === "_mwus")?.value;

  if (mwus) {
    try {
      const payload = JSON.parse(atob(mwus));
      return !!payload.userId;
    } catch (e) {}
  }

  return false;
}

async function performMercariLogin(
  extensionId: string
): Promise<Record<string, string> | null> {
  const windowsCreateResponse = await createWindow(extensionId, {
    focused: true,
    type: "popup",
    url: "https://www.mercari.com/login/",
  });

  const windowId = windowsCreateResponse.result;

  let closed = false;

  await Promise.any([
    waitOnRemoveWindow(extensionId, {
      windowId,
    }),
    new Promise<void>((rs) => {
      const check = async () => {
        if (closed) return rs();
        const cookies = await getMercariCookies(extensionId);

        if (isLoggedInMercari(cookies)) {
          removeWindow(extensionId, {
            windowId,
          });
          return rs();
        }

        setTimeout(() => check(), 500);
      };

      check();
    }),
  ]);
  closed = true;

  const cookies = await getMercariCookies(extensionId);

  return isLoggedInMercari(cookies) ? getRequiredCookies(cookies) : null;
}

export async function getMercariUser(): Promise<{
  username: string;
  cookies: Record<string, string>;
}> {
  checkMercariRequiredExtensionVersion();

  const extensionId = getExtensionId();

  let cookies = await getMercariCookies(extensionId);

  if (!isLoggedInMercari(cookies)) {
    await performMercariLogin(extensionId);
    cookies = await getMercariCookies(extensionId);

    if (!isLoggedInMercari(cookies)) {
      throw new Error("Log into Mercari to allow My Reseller Genie to connect.");
    }
  }

  const response = await webRequest(extensionId, {
    url: "https://www.mercari.com/v1/initialize",
    init: {
      headers: {
        Accept: "application/json",
      },
      method: "GET",
    },
  });
  const username = response?.result?.data?.user?.name;

  if (!username) {
    console.error("No username", response);
    throw new Error(
      "There was an error, please log out from Mercari and log into it again."
    );
  }

  return {
    username,
    cookies: getRequiredCookies(cookies) || {},
  };
}
