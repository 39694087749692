import React from "react";
import { useDispatch } from "react-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import { updateUserApi } from "src/apiService/modules/system";
import { setActiveDialogIfCanBeShown } from "src/store/adminHtml/actions";

import type Step from "./Step";

interface CompleteSetUpProps {
  onClose: () => void;
  onStepChange: (step: Step) => void;
}

function CompleteSetUp({ onClose, onStepChange }: CompleteSetUpProps) {
  const dispatch = useDispatch();
  return (
    <>
      <DialogTitle>Complete Set-Up</DialogTitle>
      <MuiDialogContent>
        <DialogContentText>
          Congratulations! You’ve completed the set-up process in My Reseller
          Genie. Now you can start enjoying the benefits of being on top of your
          numbers!
        </DialogContentText>
      </MuiDialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            onClose();
            updateUserApi({ getStartedEnd: new Date() });
            dispatch(setActiveDialogIfCanBeShown("maintenance_activities_dialog"));
          }}
          color="primary"
          autoFocus
        >
          Level Up
        </Button>
      </DialogActions>
    </>
  );
}

export default CompleteSetUp;
