import { useState, useEffect } from "react";
import {getUserId} from "../../config";
import firebase from "firebase/app";
import "firebase/storage";
import firestore from "src/apiService/firestore";

const db = firestore();

export type PlaidIntegrationType = "expense" | "cash";
export interface PlaidLinkData {
  type: PlaidIntegrationType;
  name: string;
  date: Date;
  redirect?: string;
}
export interface PlaidLinkBag {
  linkToken?: string ;
  data?: PlaidLinkData;
  error?: string;
}

export const fetchPlaidLinkToken = () => {
    const getPlaidLinkFunction = firebase.functions().httpsCallable('getPlaidLinkToken', {timeout: 10000});
    return getUserId().then((user_id) => {
        return getPlaidLinkFunction({user_id: user_id}).then((result) => {
            return result.data;
        }).catch((err) => {
            console.log(err);
        })
    });
}

export const PlaidLinkKey = "__MRG_PlaidLinkKey__";
function getStoredLinkData(): PlaidLinkBag {
  try {
    const stored = window.localStorage.getItem(PlaidLinkKey);
    const json = JSON.parse(stored || "{}");
    if (json.data?.date)
      json.data.date = new Date(json.data.date);
    return json;
  } catch (e) {
    return { error: (e as Error).toString() };
  }
}

function updateStoredLinkData(bag: PlaidLinkBag) {
  let mergedBag: PlaidLinkBag = {};
  const stored = getStoredLinkData();
  if (!stored.error) mergedBag = stored;
  window.localStorage.setItem(PlaidLinkKey, JSON.stringify({
    ...mergedBag,
    ...bag,
  }));
}

export function usePlaid(data?: Partial<PlaidLinkData>): PlaidLinkBag {
  const [link, setLink] = useState<PlaidLinkBag>({});

  useEffect(() => {
    if (window.location.pathname.endsWith('/auth/plaid/callback')) {
      setLink(getStoredLinkData());
      return;
    }

    let cancel = false;
    (async () => {
      const linkToken = await fetchPlaidLinkToken();
      if (cancel) return;
      setLink(l => ({ ...l, linkToken }));
      updateStoredLinkData({ linkToken });
    })();

    return () => {
      cancel = true;
    };
  }, []);

  // XXX: this isn't the best solution, but, well, it works.
  //      should return a setter instead.
  useEffect(() => {
    if (data?.type && data?.name && data?.date) {
      updateStoredLinkData({
        data: {
          type: data.type,
          name: data.name,
          date: data.date,
          redirect: data.redirect,
        },
      });
    }
  }, [data?.type, data?.name, data?.date, data?.redirect]);

  return link;
}

export const fetchPlaidUpdateModeLinkToken = () => {
    const getPlaidUpdateModeLinkFunction = firebase.functions().httpsCallable('getPlaidUpdateModeLinkToken', {timeout: 10000});
    return getUserId().then((user_id) => {
        return getPlaidUpdateModeLinkFunction({user_id: user_id}).then((result) => {
            return result.data;
        }).catch((err) => {
            console.log(err);
        })
    });
}

export const transferPlaidPublicToken = (public_token: string,  type: PlaidIntegrationType | null, integrationId?: string | null) => {
    return getUserId().then(async (result) => {
        const exchangePlaidToken = firebase.functions().httpsCallable(
            "exchangePlaidPublicToken", {timeout: 10000});
        try {
            const data = await exchangePlaidToken({public_token: public_token, "user": result, type, integration_id: integrationId });
            return data.data;
        } catch(err) {
            console.log(err);
            return "";
        }
    });
}

export const addAccountName = (docId, name, date) => {
    const ref = db.collection("Plaid_Integrations").doc(docId);
    if(!date){
        date = new Date();
    }
    return ref.set({ account_name: name, date: date }, { merge: true }).then((results) => {
        return syncAccount(docId).then((results) => {
            return results;
        })
    })
}

export const deleteIntegration = async (integrationId) => {
    const deleteIntegration = firebase.functions().httpsCallable("deletePlaidIntegration");
    try {
        const data = await deleteIntegration({integration_id: integrationId});
        return data.data;
    } catch(err) {
        console.log(err);
        return "";
    }
}

export const deleteTransactions = () => {
    return getUserId().then(async (userId) => {
        const ref = db.collection("Transactions").where("user", "==", userId);
        ref.get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                doc.ref.delete();
            });
        });
    });
}

export const deleteCashActivities = () => {
    return getUserId().then(async (userId) => {
        const ref = db.collection("Cash_Activities").where("user", "==", userId);
        ref.get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                doc.ref.delete();
            });
        });
    });
}

export const syncAccount = (doc_id) => {
    const fetchTransactions = firebase.functions().httpsCallable("getPlaidTransactions", {timeout: 50000});
    return fetchTransactions({doc_id: doc_id}).then((result) => result.data)
}
