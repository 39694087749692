import React, { useState } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { AnalyticsGroupingItem } from "src/interfaces/systemState.interface";
import { analyticsGroupingsSelector } from "src/store/system/selector";
import {
  updelsertAnalyticGrouping,
  normalize,
  capitalize,
} from "src/apiService/modules/analyticsGroupings";
import { updateAnalyticsGroupings } from "src/store/system/actions";
import DeleteButton from "../DeleteButton";

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  button: {
    position: "relative",
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-6px 0 0 -6px",
  },
}));

function AddEditDialogContent({
  item,
  onClose,
  initialValue,
}: {
  item?: AnalyticsGroupingItem;
  onClose: (g?: AnalyticsGroupingItem) => void;
  initialValue?: AnalyticsGroupingItem;
}) {
  const classes = useStyles();
  const [data, setData] = useState<Partial<AnalyticsGroupingItem>>(
    item || initialValue || {}
  );
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();
  const analyticsGroupings = useSelector(analyticsGroupingsSelector);
  return (
    <>
      {item && (
        <DeleteButton
          disabled={saving}
          className={classes.deleteButton}
          onClick={async () => {
            await updelsertAnalyticGrouping({ delete: item });
            onClose();
          }}
        />
      )}
      <DialogTitle>{`${item ? "Edit" : "Add"} Analytics Grouping`}</DialogTitle>
      <DialogContent>
        <Box display="flex">
          <Autocomplete
            value={data.department || ""}
            style={{ width: 202 }}
            freeSolo
            options={Object.keys(analyticsGroupings).map(capitalize)}
            renderInput={(params) => (
              <TextField {...params} label="Department" />
            )}
            onInputChange={(ev, value, reason) => {
              if (reason === "clear")
                setData((d) => ({
                  ...d,
                  department: "",
                  category: "",
                  subcategory: "",
                }));
              else setData((d) => ({ ...d, department: value || "" }));
            }}
            disabled={saving}
          />
          <Autocomplete
            value={data.category || ""}
            style={{ width: 202, marginLeft: 8, marginRight: 8 }}
            freeSolo
            options={
              data.department && analyticsGroupings[normalize(data.department)]
                ? Object.keys(
                    analyticsGroupings[normalize(data.department)]
                  ).map(capitalize)
                : []
            }
            renderInput={(params) => <TextField {...params} label="Category" />}
            onInputChange={(ev, value, reason) => {
              if (reason === "clear")
                setData((d) => ({ ...d, category: "", subcategory: "" }));
              else setData((d) => ({ ...d, category: value || "" }));
            }}
            disabled={saving}
          />
          <TextField
            disabled={saving}
            value={data.subcategory || ""}
            label="Sub-Category"
            onChange={(e) => {
              const text = e.target.value || "";
              setData((d) => ({ ...d, subcategory: text }));
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          disabled={
            saving ||
            !("department" in data) ||
            !data["department"] ||
            !("category" in data) ||
            !data["category"] ||
            !("subcategory" in data) ||
            !data["subcategory"]
          }
          onClick={async () => {
            if (!data.department || !data.category || !data.subcategory) return;
            const insert = data as AnalyticsGroupingItem;
            if (
              analyticsGroupings[normalize(data.department)]?.[
                normalize(data.category)
              ]?.includes(normalize(data.subcategory))
            ) {
              toast.error(
                "This grouping already exists, change the sub-category to a unique option to create a new grouping"
              );
              return;
            }
            setSaving(true);
            try {
              const ret = await dispatch(
                updateAnalyticsGroupings({
                  insert,
                  delete: item,
                })
              );
              toast.success(
                `Grouping ${item ? "updated" : "created"} successfully`
              );
              if (ret) onClose(ret as unknown as AnalyticsGroupingItem);
              else onClose();
            } catch (e) {
              toast.error(`There was an error: ${(e as Error).toString()}`);
            }
            setSaving(false);
          }}
        >
          {item ? "Save" : "Add"}
          {saving && (
            <CircularProgress size={12} className={classes.progress} />
          )}
        </Button>
      </DialogActions>
    </>
  );
}

function AddEditDialog({
  open,
  onClose,
  initialValue,
}: {
  open: AnalyticsGroupingItem | boolean;
  onClose: (g?: AnalyticsGroupingItem) => void;
  initialValue?: AnalyticsGroupingItem;
}) {
  return (
    <Dialog open={!!open} onClose={() => onClose()}>
      <AddEditDialogContent
        initialValue={initialValue}
        onClose={onClose}
        item={
          typeof open === "object" &&
          "department" in open &&
          "category" in open &&
          "subcategory" in open
            ? open
            : undefined
        }
      />
    </Dialog>
  );
}

export default AddEditDialog;
