import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { persistStore, persistReducer } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import rootSaga from "./rootSaga";
import { systemReducer } from "./system/reducers";
import { adminHtmlReducer } from "./adminHtml/reducers";
import { createBrowserHistory, History } from "history";

import { inventoryReducer } from "./inventory/reducers";
import { saleReducer } from "./sale/reducers";
import { numericOverviewReducer } from "./numericOverview/reducers";
import { cashActivityReducer } from "./cashActivity/reducers";
import { bankStatementReducer } from "./bankStatement/reducers";
import { itemOptionsReducer } from "./itemOptions/reducers";
import { platformReducer } from "./platform/reducers";
import { saleStateReducer } from "./saleState/reducers";
import { transactionReducer } from "./transaction/reducers";
import { uploadReducer } from "./uploads/reducers";
import { errorAlertsReducer } from "./errorAlerts/reducers";
import { plaidIntegrationReducer } from "./plaidIntegration/reducers";
import { generalLedgerAccountReducer } from "./glAccounts/reducers";
import { supportReducer } from "./support/reducers";
import { zendeskReducer } from "./zendesk/reducers";
import { vendorReducer } from "./vendor/reducers";
import { integrationSyncReducer } from "./integrationSync/reducers";

export const history = createBrowserHistory();

const rootReducer = (history: History) => {
  const systemPersistConfig = {
    key: "system",
    storage: storage,
    whitelist: ["user", "loggedIn"],
  };
  return combineReducers({
    system: persistReducer(systemPersistConfig, systemReducer),
    adminHtml: adminHtmlReducer,

    inventory: inventoryReducer,
    sale: saleReducer,
    numericOverview: numericOverviewReducer,
    cashActivity: cashActivityReducer,
    bankStatement: bankStatementReducer,
    itemOptions: itemOptionsReducer,
    platform: platformReducer,
    saleState: saleStateReducer,
    uploads: uploadReducer,
    transaction: transactionReducer,
    errorAlerts: errorAlertsReducer,
    plaidIntegration: plaidIntegrationReducer,
    glAccounts: generalLedgerAccountReducer,
    support: persistReducer(
      { key: "support", storage, whitelist: ["token"] },
      supportReducer
    ),
    zendesk: zendeskReducer,
    vendor: vendorReducer,
    integrationSync: integrationSyncReducer,

    router: connectRouter(history),
  });
};

const rootReducerInstance = rootReducer(history);

export type AppState = ReturnType<typeof rootReducerInstance>;

const configureStore = async () => {
  const persistConfig = {
    key: "root",
    storage,
    whitelist: [],
  };

  const sagaMiddleware = createSagaMiddleware();

  const persistedReducer = persistReducer(persistConfig, rootReducerInstance);
  const composeEnhancers =
    process.env.NODE_ENV !== "production" &&
    typeof window === "object" &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
          actionSanitizer: (action) => {
            if (action.type.endsWith("CREATE_ITEMS")) {
              if (action.payload?.length > 5)
                return {
                  ...action,
                  payload: "<<< many elements >>>",
                };
            }

            return action;
          },
          stateSanitizer: ({ ...state }) => {
            for (const k of [
              "sale",
              "inventory",
              "numericOverview",
              "cashActivity",
              "bankStatement",
              "uploads",
              "transaction",
              "errorAlerts",
            ]) {
              if (state[k]?.items?.length > 15) {
                state[k] = {
                  ...state[k],
                  items: "<<< many elements >>>",
                };
              }
            }

            if (state.sale?.visible?.length > 15) {
              state.sale = {
                ...state.sale,
                visible: "<<< many elements >>>",
              };
            }

            return state;
          },
        })
      : compose;

  const store = createStore(
    persistedReducer,
    composeEnhancers(
      applyMiddleware(thunk, sagaMiddleware, routerMiddleware(history))
    )
  );

  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store);

  return { store, persistor };
};

export default configureStore;
