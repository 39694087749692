import { all, call, fork, put, take } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  fetchInventoriesApi,
  deleteInventoriesApi,
  updateInventory as updateInventoryApi,
  deleteInventoryApi,
  addInventory,
  requestNOUpdate,
} from "../../apiService";
import { parseError } from "../../utils";

import {
  INVENTORY,
  FAIL,
  START,
  SUCCESS,
  FETCH_ITEMS,
  DELETE_ITEMS,
  RESET,
  UPDATE_ITEM,
  DELETE_ITEM,
  CREATE_ITEM,
} from "../common";

function* fetchInventories() {
  while (true) {
    const { payload } = yield take(INVENTORY + FETCH_ITEMS + START);
    try {
      const data: { data: any } = yield call(fetchInventoriesApi, payload);
      yield put({ type: INVENTORY + FETCH_ITEMS + SUCCESS, payload: data });
    } catch (error) {
      toast.error(parseError(error));
      yield put({
        type: INVENTORY + FETCH_ITEMS + FAIL,
        payload: parseError(error),
      });
    }
  }
}

function* deleteInventories(): any {
  while (true) {
    const { payload } = yield take(INVENTORY + DELETE_ITEMS + START);
    try {
      const data = yield call(deleteInventoriesApi);
      if (data.data?.success) {
        yield put({ type: INVENTORY + DELETE_ITEMS + SUCCESS, payload });
        toast.success("Inventory has been deleted successfully");
        yield call(requestNOUpdate);
      } else {
        yield put({
          type: INVENTORY + DELETE_ITEMS + FAIL,
          payload: parseError(data.data),
        });
      }
    } catch (error) {
      toast.error(parseError(error));
      yield put({
        type: INVENTORY + DELETE_ITEMS + FAIL,
        payload: parseError(error),
      });
    }
    yield put({ type: INVENTORY + RESET });
  }
}


function* updateInventory() {
  while (true) {
    const { payload } = yield take(INVENTORY + UPDATE_ITEM + START);
    try {
      yield call(updateInventoryApi, payload);
      yield put({ type: INVENTORY + UPDATE_ITEM + SUCCESS, payload });
      toast.success("Inventory has been updated successfully");
      yield call(requestNOUpdate);
    } catch (error) {
      toast.error(parseError(error));
      yield put({
        type: INVENTORY + UPDATE_ITEM + FAIL,
        payload: parseError(error),
      });
    }
  }
}

function* deleteInventory() {
  while (true) {
    const { payload } = yield take(INVENTORY + DELETE_ITEM + START);
    try {
      const deleteResult: any = yield call(deleteInventoryApi, payload);
      yield put({ type: INVENTORY + DELETE_ITEM + SUCCESS, payload });
      toast.success("Inventory has been deleted successfully");
      yield call(requestNOUpdate);
    } catch (error) {
      toast.error(parseError(error));
      yield put({
        type: INVENTORY + DELETE_ITEM + FAIL,
        payload: parseError(error),
      });
    }
  }
}

function* createInventory() {
  while (true) {
    const { payload } = yield take(INVENTORY + CREATE_ITEM + START);
    try {
      yield call(addInventory, payload);
      toast.success("Inventory has been added successfully");
      yield put({ type: INVENTORY + CREATE_ITEM + SUCCESS, payload });
      yield call(requestNOUpdate);
    } catch (error) {
      toast.error(parseError(error));
      yield put({
        type: INVENTORY + CREATE_ITEM + FAIL,
        payload: parseError(error),
      });
    }
  }
}

export function* inventorySagas() {
  yield all([
    fork(fetchInventories),
    fork(deleteInventories),
    fork(updateInventory),
    fork(deleteInventory),
    fork(createInventory),
  ]);
}
