import produce from "immer";
import { handleActions } from "redux-actions";

import { secondsToDate } from "../../utils";

import { CashActivityState } from "../../interfaces";
import { METHOD_TYPE } from "../../enums";
import { LOG_OUT } from "../system/actions";
import {
  CASH_ACTIVITY,
  SET_FILTER,
  FS_CREATE_ITEMS,
  FS_DELETE_ITEMS,
  FS_UPDATE_ITEMS,
  CLEAR_UPDATE_METHOD,
} from "../common";

const initialState: CashActivityState = {
  items: [],
  total: 0,
  start: 1,
  end: 1,
  limit: 25,

  method: METHOD_TYPE.LIST,
  loading: false,
  loaded: false,
  error: null,
  message: "",

  selected: [],
  filter: {
    items: [],
  }
};

export const cashActivityReducer = handleActions<CashActivityState, any>(
  {
    [CASH_ACTIVITY + SET_FILTER]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.filter = payload;
      }),
    [CLEAR_UPDATE_METHOD]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.LIST;
      }),
    [CASH_ACTIVITY + FS_CREATE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items.push(...payload.map((e: any) => ({
          ...e,
          date: secondsToDate(e.date),
        })));
        draft.loading = false;
        draft.loaded = true;
      }),
    [CASH_ACTIVITY + FS_UPDATE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items.forEach((item) => {
          const updated = payload.find((newItem) => newItem.id === item.id);
          if (updated) {
            const mapped = {
              ...updated,
              date: secondsToDate(updated.date),
            };
            Object.assign(item, mapped);
          }
        });
      }),
    [CASH_ACTIVITY + FS_DELETE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items = state.items.filter((item) => !payload.find((deleted) => deleted.id === item.id));
      }),
    [LOG_OUT]: () => initialState,
  },
  initialState
);
