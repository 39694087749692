import React, { useEffect, useState } from "react";

import {
  Button,
  TextField,
  Grid,
  CircularProgress,
} from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { toast } from "react-toastify";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import InputAdornment from "@material-ui/core/InputAdornment";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { BankStatement, BankStatementFormError } from "../../interfaces";

import NumberField from "src/components/NumberField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
  }),
);

interface IEditBankStatementDialogProps {
  data: BankStatement | undefined
  open: boolean
  loading: boolean
  onClose: Function
  onSave: Function
  onDelete: Function
}

const initFormValues: BankStatement = {
  id: "",
  bank_statement_name: "",
  bank_statement_total: 0,
  bank_statement_date: undefined,
  reconciled: false,
  reconciliation_amount: 0,
  cash_activities: [],
}

export const EditBankStatementDialog = ({ data, open, loading, onClose, onSave, onDelete } : IEditBankStatementDialogProps) => {

  const classes = useStyles();

  const [values, setValues] = useState<BankStatement>(initFormValues);
  const [errors, setErrors] = useState<BankStatementFormError>({});
  const [hasChanged, setHasChanged] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      setValues(data || initFormValues);
      setErrors({});
      setHasChanged(false);
    }

  }, [data, open]);

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    if (validateForm()) {
      onSave(values, !!data);
    } else {
      toast.error("Some required information is missing or incomplete");
    }
  };

  const handleChange = (prop: keyof BankStatement, numeric?: boolean) => (event, t?: any) => {
    setHasChanged(true);
    let value = event.target.value;
    if (numeric) {
      value = +value || 0;
    }
    setValues({ ...values, [prop]: value  });
  };


  const isValidDate = (d: any) => {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d.getTime())) {  // d.valueOf() could also work
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  const validateForm = () => {
    let hasError = false;
    /* rules */
    let newErrors : BankStatementFormError = {...errors}
    if (values.bank_statement_name === undefined ||
      values.bank_statement_name.trim() === "") {
      newErrors.bank_statement_name = "Required Field!";
      hasError = true;
    } else {
      newErrors.bank_statement_name = undefined
    }
    if (values.bank_statement_total === undefined) {
      newErrors.bank_statement_total = "Required Field!";
      hasError = true;
    } else {
      newErrors.bank_statement_total = undefined
    }
    if (!isValidDate(values.bank_statement_date)) {
      hasError = true;
      newErrors.bank_statement_date = "Invalid date format";
    } else {
      newErrors.bank_statement_date = undefined
    }

    setErrors(newErrors);

    return !hasError;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"body"}
      aria-labelledby="edit-back_statement"
      aria-describedby="scroll-dialog-description"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="edit-back_statement relative">
        {data ? "Edit Bank Statement" : "Add Bank Statement"}
      </DialogTitle>

      <DialogContent>
        <DialogContentText
          className="relative"
          tabIndex={-1}
        >
          <form className={classes.root} noValidate autoComplete="off">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    error={errors.bank_statement_name ? true : false}
                    helperText={errors.bank_statement_name}
                    className="w-full"
                    variant="outlined"
                    margin="dense"
                    label="Bank Statement Name"
                    value={values.bank_statement_name}
                    disabled={loading}
                    onChange={handleChange("bank_statement_name")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <NumberField
                    required
                    error={errors.bank_statement_total ? true : false}
                    helperText={errors.bank_statement_total}
                    className="w-full"
                    variant="outlined"
                    margin="dense"
                    label="Bank Statement Total"
                    value={values.bank_statement_total}
                    disabled={loading}
                    onChange={handleChange("bank_statement_total", true)}
                    allowNegative
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KeyboardDatePicker
                    error={!!errors.bank_statement_date}
                    helperText={errors.bank_statement_date}
                    disableToolbar
                    className="w-full"
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    margin="dense"
                    required
                    label="Bank Statement Date"
                    autoOk={true}
                    value={values.bank_statement_date || null}
                    disabled={loading}
                    onChange={(e: any, t: any) => {
                      setHasChanged(true);
                      setValues({ ...values, bank_statement_date: new Date(e)});
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </form>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="px-7 py-5">
        <Button onClick={handleSave} color="primary" variant="contained" disabled={loading || !hasChanged}>
          {loading && <CircularProgress size={15} className="mr-3" />}
          {data ? "Update" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
