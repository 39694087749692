import React from "react";
import capitalize from "lodash/capitalize";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { StatefulLoadableButton } from "src/components/LoadableButton";
import { getPoshmarkUser } from "src/utils/extension/poshmark";
import { getMercariUser } from "src/utils/extension/mercari";
import usePrevious from "src/utils/usePrevious";

function Content({ onClose, platform: _platform }) {
  const platformMemory = usePrevious(_platform);
  const platform = _platform || platformMemory || "poshmark";
  return (
    <>
      <DialogTitle>Log In to {platform}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To import your data from {platform}, you’ll need to be logged into
          {capitalize(platform)}. Click below to Log In.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <StatefulLoadableButton
          color="primary"
          autoFocus
          onClick={async () => {
            if (platform === "poshmark") await getPoshmarkUser();
            else if (platform === "mercari") await getMercariUser();
            onClose();
          }}
        >
          Log In
        </StatefulLoadableButton>
      </DialogActions>
    </>
  );
}

function PoshmarkLogInDialog({
  open,
  onClose,
  platform = "poshmark",
}: {
  open: boolean;
  onClose: () => void;
  platform?: "poshmark" | "mercari";
}) {
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <Content onClose={onClose} platform={platform} />
    </Dialog>
  );
}

export default PoshmarkLogInDialog;
