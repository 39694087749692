import React from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import LoadableButton from "src/components/LoadableButton";
import { userPlatformsSelector } from "src/store/system/selector";
import { updateUserApi } from "src/apiService/modules/system";
import { platformSelector } from "src/store/platform/selector";

function SalesPlatformDialogContent({
  onClose,
}: {
  onClose: (vendorId?: string) => void;
}) {
  const { items: systemPlatforms } = useSelector(platformSelector);
  const platforms = useSelector(userPlatformsSelector);
  const formik = useFormik({
    initialValues: {
      title: "",
    },
    onSubmit: async (values) => {
      if (!values.title) return;
      try {
        if (!values.title) return;
        if (
          platforms?.includes(values.title) ||
          systemPlatforms?.includes(values.title)
        ) {
          toast.error(
            `A platform with the title '${values.title} already exists`
          );
          return;
        }

        await updateUserApi({
          platforms: platforms ? [...platforms, values.title] : [values.title],
        });
        onClose(values.title);
        toast.success(`Platform saved successfully.`);
      } catch (e) {
        toast.error(`There was an error: ${(e as Error).toString()}`);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogTitle>Add Sale Platform</DialogTitle>
      <DialogContent>
        <TextField
          disabled={formik.isSubmitting}
          margin="dense"
          label="Platform Title"
          value={formik.values.title}
          name="title"
          autoComplete="off"
          onChange={formik.handleChange}
          fullWidth
          required
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose()}
          color="primary"
          disabled={formik.isSubmitting}
        >
          Cancel
        </Button>
        <LoadableButton
          color="primary"
          type="submit"
          variant="contained"
          loading={formik.isSubmitting}
        >
          Save
        </LoadableButton>
      </DialogActions>
    </form>
  );
}

function SalesPlatformDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: (salePlatform?: string) => void;
}) {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={() => onClose()}>
      <SalesPlatformDialogContent onClose={onClose} />
    </Dialog>
  );
}

export default SalesPlatformDialog;
