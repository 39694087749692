const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
const messagingSenderId = process.env.REACT_APP_FIREBASE_PROJECT_NUMBER;
const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const storageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
const authDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
const appId = process.env.REACT_APP_FIREBASE_APP_ID;
const measurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
export const functionsEmulatorURL =
  process.env.NODE_ENV !== "production" &&
  process.env.REACT_APP_FIREBASE_FUNCTIONS_URL;
export const authEmulatorURL = functionsEmulatorURL
  ? "http://127.0.0.1:9099"
  : undefined;

export const firebaseConfig: any = {
  apiKey,
  authDomain,
  projectId,
  storageBucket,
  messagingSenderId,
};

if (appId) {
  firebaseConfig.appId = appId;
}

if (measurementId) {
  firebaseConfig.measurementId = measurementId;
}
