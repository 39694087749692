import React from "react";
import Tour from "reactour";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

const Steps = [
  {
    content: (props) => (
      <>
        <DialogTitle>Add Inventory - 1/3</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This form allows you to record new inventory items manually or by
            uploading them.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(1)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#add-inventory-dialog-item-title-tooltip",
    content: (props) => (
      <>
        <DialogTitle>Add Inventory - 2/3</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Learn more about each field by hovering over the info icons.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(0)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(2)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#add-inventory-dialog-bulk-upload-button",
    content: (props) => (
      <>
        <DialogTitle>Add Inventory - 3/3</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To upload a list of new inventory items, click here. Do not upload
            items that are already in My Reseller Genie, because this will
            create duplicates.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(1)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.close()}
          >
            Done
          </Button>
        </DialogActions>
      </>
    ),
  },
];

const useStyles = makeStyles((theme) => ({
  helper: {
    maxWidth: "80%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "500px",
    },
    padding: 0,
  },
}));

export interface WalkThroughProps {
  open: boolean;
  onClose: () => void;
}

export function WalkThrough({ open, onClose }: WalkThroughProps) {
  const classes = useStyles();
  return (
    <Tour
      startAt={0}
      closeWithMask={false}
      disableFocusLock
      steps={Steps}
      isOpen={open}
      onRequestClose={() => {
        onClose();
      }}
      showButtons={false}
      showCloseButton={false}
      showNavigation={false}
      showNavigationNumber={false}
      showNumber={false}
      disableDotsNavigation
      rounded={5}
      className={classes.helper}
      disableInteraction
    />
  );
}

export default WalkThrough;
