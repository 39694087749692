import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { fetchStripeLink } from "src/apiService/modules/system";

function useStripeLink() {
  const [link, setLink] = useState("");

  useEffect(() => {
    let cancel = false;
    fetchStripeLink()
      .then((data) => {
        if (cancel) return;
        if (!data?.data?.url) return Promise.reject();
        setLink(data?.data?.url);
      })
      .catch(() => {
        toast.error(
          "There was an error connecting with Stripe, please try again later..."
        );
      });

    return () => {
      cancel = true;
    };
  }, []);

  return link;
}

export default useStripeLink;
