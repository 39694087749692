import { all, call, fork, put, select, take } from "redux-saga/effects";
import { toast } from "react-toastify";
import { uniqBy } from "lodash";

import {
  getJSONFileApi,
  deleteSaleApi,
  addInventory,
  updateSaleApi,
  updateBundledSalesApi,
  deleteSalesApi,
  deleteBundledSalesApi,
  addSaleApi,
  moveSaleApi,
  moveBundledSalesApi,
  requestNOUpdate,
} from "src/apiService";
import { addSaleFromInventory, addBundledSales } from "src/apiService/modules/sales";
import { setEbayExtraRows, setPendingUploads, setUploadsFromEbay } from "./actions";
import { getAllSales } from "./selector";
import { parseError } from "src/utils";
import { transforms, transformSale } from "src/utils/uploadTransform";

import {
  SALE,
  BUNDLED_SALES,
  RETURN_SALE,
  FAIL,
  START,
  SUCCESS,
  DELETE_ITEMS,
  RESET,
  UPDATE_ITEM,
  DELETE_ITEM,
  CREATE_ITEM,
  MOVE_ITEM,
} from "../common";
import { LOAD_SYNC_SALES } from "./actions";
import { getNewSyncSalesDownloads } from "../uploads/selector";
import {getTransactions} from "../transaction/selector";

function* deleteSales(): any {
  while (true) {
    yield take(SALE + DELETE_ITEMS + START);
    try {
      const data = yield call(deleteSalesApi);
      yield put({ type: SALE + DELETE_ITEMS + SUCCESS, payload: data });
      toast.success("Sales have been deleted successfully");
      yield call(requestNOUpdate);
    } catch (error) {
      toast.error(parseError(error));
      yield put({
        type: SALE + DELETE_ITEMS + FAIL,
        payload: parseError(error),
      });
    }
    yield put({ type: SALE + RESET });
  }
}


function* updateSale() {
  while (true) {
    const { payload } = yield take(SALE + UPDATE_ITEM + START);
    try {
      yield call(updateSaleApi, payload);
      yield put({ type: SALE + UPDATE_ITEM + SUCCESS, payload });
      toast.success("Sale has been updated successfully");
      yield call(requestNOUpdate);
    } catch (error) {
      toast.error(parseError(error));
      yield put({
        type: SALE + UPDATE_ITEM + FAIL,
        payload: parseError(error),
      });
    }
  }
}


function* updateBundledSales() {
  while (true) {
    const { payload } = yield take(BUNDLED_SALES + UPDATE_ITEM + START);
    try {
      yield call(updateBundledSalesApi, payload);
      yield put({ type: SALE + UPDATE_ITEM + SUCCESS, payload });
      toast.success("Sale has been updated successfully");
      yield call(requestNOUpdate);
    } catch (error) {
      toast.error(parseError(error));
      yield put({
        type: SALE + UPDATE_ITEM + FAIL,
        payload: parseError(error),
      });
    }
  }
}

function* createSale() {
  while (true) {
    const { payload } = yield take(SALE + CREATE_ITEM + START);
    try {
      if (payload && payload.id) {
        yield call(addSaleFromInventory, payload.id, payload);
      } else {
        yield call(addSaleApi, payload);
      }
      yield put({ type: SALE + CREATE_ITEM + SUCCESS, payload });
      toast.success("Sale has been added successfully!");
      yield call(requestNOUpdate);
    } catch (error) {
      toast.error(parseError(error));
      yield put({
        type: SALE + CREATE_ITEM + FAIL,
        payload: parseError(error),
      });
    }
  }
}

function* createBundledSales() {
  while (true) {
    const { payload } = yield take(BUNDLED_SALES + CREATE_ITEM + START);
    try {
      yield call(addBundledSales, payload);
      yield put({ type: SALE + CREATE_ITEM + SUCCESS, payload });
      toast.success("Sale has been added successfully!");
      yield call(requestNOUpdate);
    } catch (error) {
      toast.error(parseError(error));
      yield put({
        type: SALE + CREATE_ITEM + FAIL,
        payload: parseError(error),
      });
    }
  }
}

function* createReturn() {
  while (true) {
    const { payload } = yield take(RETURN_SALE + CREATE_ITEM + START);
    try {
      const data: { id: string } = yield call(addSaleApi, payload.returnRecord);
      yield call(updateSaleApi, { ...payload.sale, return_id: data.id });
      if (payload.inventory) {
        yield call(addInventory, { ...payload.inventory, return_id: data.id });
      }
      yield put({ type: SALE + CREATE_ITEM + SUCCESS, payload });
      toast.success("Sale has been updated successfully");
      yield call(requestNOUpdate);
    } catch (error) {
      toast.error(parseError(error));
      yield put({
        type: SALE + CREATE_ITEM + FAIL,
        payload: parseError(error),
      });
    }
  }
}

function* deleteSale() {
  while (true) {
    const { payload } = yield take(SALE + DELETE_ITEM + START);
    try {
      yield call(deleteSaleApi, payload);
      yield put({ type: SALE + DELETE_ITEM + SUCCESS, payload });
      toast.success("Sale has been deleted successfully");
      yield call(requestNOUpdate);
    } catch (error) {
      toast.error(parseError(error));
      yield put({
        type: SALE + DELETE_ITEM + FAIL,
        payload: parseError(error),
      });
    }
  }
}

function* deleteBundledSales() {
  while (true) {
    const { payload } = yield take(BUNDLED_SALES + DELETE_ITEM + START);
    try {
      yield call(deleteBundledSalesApi, payload);
      yield put({ type: SALE + DELETE_ITEM + SUCCESS, payload });
      toast.success("Sale has been deleted successfully");
      yield call(requestNOUpdate);
    } catch (error) {
      toast.error(parseError(error));
      yield put({
        type: SALE + DELETE_ITEM + FAIL,
        payload: parseError(error),
      });
    }
  }
}

function* moveToInventory() {
  while (true) {
    const { payload } = yield take(SALE + MOVE_ITEM + START);
    try {
      yield call(moveSaleApi, payload);
      yield put({ type: SALE + MOVE_ITEM + SUCCESS, payload });
      toast.success("Sale has been updated successfully");
      yield call(requestNOUpdate);
    } catch (error) {
      toast.error(parseError(error));
      yield put({
        type: SALE + MOVE_ITEM + FAIL,
        payload: parseError(error),
      });
    }
  }
}

function* moveBundleToInventory() {
  while (true) {
    const { payload } = yield take(BUNDLED_SALES + MOVE_ITEM + START);
    try {
      yield call(moveBundledSalesApi, payload);
      yield put({ type: SALE + MOVE_ITEM + SUCCESS, payload });
      toast.success("Sale has been updated successfully");
      yield call(requestNOUpdate);
    } catch (error) {
      toast.error(parseError(error));
      yield put({
        type: SALE + MOVE_ITEM + FAIL,
        payload: parseError(error),
      });
    }
  }
}

function* loadSyncSales() {
  while (true) {
    yield take(LOAD_SYNC_SALES + START);
    try {
      const existingSales = yield select(getAllSales);
      const existingTransactions = yield select(getTransactions);
      const downloads = yield select(getNewSyncSalesDownloads);
      const rawData = yield all(downloads.map((download) => getJSONFileApi(download.filename)));
      const flatRawData = rawData.flat();
      const uniqueRawData = uniqBy(flatRawData, "transactionId");
      console.log({ flatRawData, uniqueRawData });
      const transformed = uniqueRawData.map(transforms.ebaySalesDirectImport);
      const flattened = transformed.flat();
      const mapped = flattened.map(transformSale);
      const filtered = mapped.filter((newItem) => !existingSales.find((item) =>
        item.transaction_id === newItem.transaction_id));
      const uniqued = uniqBy(filtered, "transaction_id");
      const finalMap = uniqued.map((item, uploadIndex) => ({
        ...item,
        uploadIndex,
      }));
      const extraRows: any[] = uniqueRawData.map(transforms.ebaySalesExtraRows).flat();
      let removedExistingTransactions = extraRows;
      console.log(existingTransactions);
      if(existingTransactions.items.length > 0) {
        removedExistingTransactions = extraRows.filter((newTransaction) => !existingTransactions.items.find((item) =>
            item.transactionId === newTransaction.transactionId));
      }
      const uniqueExtraRows = uniqBy(removedExistingTransactions, "transactionId");
      const returns: any[] = uniqueRawData.map(transforms.ebaySalesReturns).flat();
      const uniqueReturns = uniqBy(returns, "Return ID");
      yield put(setPendingUploads(finalMap));
      yield put(setEbayExtraRows({ extraRows: uniqueExtraRows, returns: uniqueReturns }));
      yield put(setUploadsFromEbay(true));
    } catch {

    }
  }
}

export function* saleSagas() {
  yield all([
    fork(deleteSales),
    fork(updateSale),
    fork(updateBundledSales),
    fork(deleteSale),
    fork(deleteBundledSales),
    fork(createSale),
    fork(createBundledSales),
    fork(createReturn),
    fork(moveToInventory),
    fork(moveBundleToInventory),
    fork(loadSyncSales),
  ]);
}
