import chunk from "lodash/chunk";
import { Inventory } from "src/interfaces/inventory.interface";
import { MaximumBatchSize } from "src/config/firestore";
import firestore from "src/apiService/firestore";
import storage from "src/apiService/storage";

const db = firestore();

export const deleteUploadsApi = async (ids: string[]) => {
  if (!ids.length) return;

  for (const c of chunk(ids, MaximumBatchSize)) {
    const batch = db.batch();
    for (const id of c) {
      const doc = db.collection("Uploads").doc(id);
      batch.delete(doc);
    }
    await batch.commit();
  }
};

export async function deleteDownloadsDocuments(ids: string[]) {
  if (!ids.length) return;

  for (const c of chunk(ids, MaximumBatchSize)) {
    const batch = db.batch();
    for (const id of c) {
      const doc = db.collection("Downloads").doc(id);
      batch.delete(doc);
    }
    await batch.commit();
  }
}

export const getJSONFileApi = async (filename: string) => {
  const storageRef = storage().ref(filename);
  return storageRef.getDownloadURL()
  .then((url) => fetch(url)
  .then((response) => response.json()))
};

export const markDownloadViewed = async (downloadId: string) => {
  const ref = db.collection("Downloads").doc(downloadId);
  return ref.set({ new: false }, { merge: true });
};

export const createUploadApi = async (uploadDoc) => {
  const ref = db.collection("Uploads").doc();
  const id = ref.id;
  return ref.set({ ...uploadDoc, id: ref.id })
  .then(() => id);
};

export const uploadInventoryApi = async (inventory) => {
  const ref = db.collection("Inventory").doc();
  const id = ref.id;
  return ref.set({ ...inventory, id });
};

export async function uploadInventoryItems(items: Inventory[]): Promise<void> {
  if (!items.length) return;
  for (const c of chunk(items, MaximumBatchSize)) {
    const batch = db.batch();
    for (const item of c) {
      const doc = item.id
        ? db.collection("Inventory").doc(item.id)
        : db.collection("Inventory").doc();
      batch.set(doc, { ...item, id: doc.id });
    }

    await batch.commit();
  }
}
