import firebase from "firebase/app";
import "firebase/functions";
import firestore from "src/apiService/firestore";
import { getUserId } from "src/config/storage";
import { Vendor } from "src/interfaces/vendor.interface";

export async function addVendor(vendor: Partial<Vendor>) {
  if (!vendor) return;
  const user = await getUserId();
  const db = firestore();
  const ref = db.collection("Vendors").doc();

  await ref.set({
    name: "",
    address: "",
    phone: [],
    email: [],
    ...vendor,
    id: ref.id,
    user,
  });

  return ref.id;
}

export async function updateVendor(id: Vendor["id"], vendor: Partial<Vendor>) {
  if (!id || !vendor) return;
  const db = firestore();
  const ref = db.collection("Vendors").doc(id);
  const { user, ...data } = vendor;
  return await ref.update(data);
}

export async function deleteVendor(
  vendorId: string
): Promise<{ error?: boolean | string[] }> {
  if (!vendorId) return { error: true };
  const fn = firebase.functions().httpsCallable("deleteVendor");
  const result = await fn({ vendorId });
  return result?.data;
}
