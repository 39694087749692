import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import isEqual from "lodash/isEqual";
import Alert, { type AlertProps } from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";

// fix-vim-highlight = }

export interface StatusProps {
  open: boolean;
  severity?: AlertProps["severity"];
  message?: AlertProps["children"];
  onClose?: AlertProps["onClose"];
}

interface Props {
  props: StatusProps;
  open: boolean;
  alert: AlertProps;
}

const InitialProps: Props = {
  props: {
    open: false,
  },
  open: false,
  alert: {},
};

const useStyles = makeStyles((theme) => ({
  alertRoot: {
    ...theme.typography.caption,
    padding: theme.spacing(0, 1.5),
    marginTop: theme.spacing(1),
  },
  alertMessage: {
    padding: theme.spacing(0.5, 0),
    display: "flex",
    alignItems: "center",
  },
}));

function Status({ onClose, ..._statusProps }: StatusProps) {
  const classes = useStyles();
  const [props, setProps] = useState<Props>(InitialProps);
  const propsRef = useRef(props);
  propsRef.current = props;
  const statusPropsRef = useRef(_statusProps);
  if (!isEqual(_statusProps, statusPropsRef.current))
    statusPropsRef.current = _statusProps;

  const statusProps = statusPropsRef.current;

  useEffect(() => {
    if (statusProps.open && isEqual(statusProps, propsRef.current.props))
      return;
    if (!statusProps.open && !propsRef.current.open) return;

    setProps((props) => {
      if (!statusProps.open) {
        return {
          ...props,
          props: {
            open: false,
          },
          open: false,
        };
      }

      return {
        props: statusProps,
        open: true,
        alert: {
          icon: statusProps.severity ? undefined : (
            <CircularProgress size={22} />
          ),
          children: statusProps.message,
          severity: statusProps.severity || "info",
        },
      };
    });
  }, [statusProps]);

  if (!props.open) return null;

  return (
    <Alert
      {...props.alert}
      onClose={onClose}
      classes={{
        root: classes.alertRoot,
        message: classes.alertMessage,
      }}
    />
  );
}

function WrapperStatus({ open, onClose, ...props }: StatusProps) {
  const [forceClose, setForceClose] = useState(false);
  useEffect(() => {
    setForceClose(false);
  }, [open]);

  return (
    <Status
      {...props}
      open={open && !forceClose}
      onClose={onClose || (() => setForceClose(true))}
    />
  );
}

export default WrapperStatus;
