import React, { useState } from "react";
import clsx from "clsx";
import { toast } from "react-toastify";

import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

export interface LoadableIconButtonProps extends IconButtonProps {
  loading?: boolean;
}

const useStyles = makeStyles({
  root: {
    position: "relative",
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-12px 0 0 -12px",
  },
});

function LoadableIconButton({
  loading,
  disabled,
  children,
  className,
  ...props
}: LoadableIconButtonProps) {
  const classes = useStyles();
  return (
    <IconButton
      {...props}
      disabled={loading || disabled}
      className={clsx(classes.root, className)}
    >
      {loading && <CircularProgress className={classes.progress} size={24} />}
      {children}
    </IconButton>
  );
}

export interface StatefulLoadableIconButtonProps
  extends Omit<LoadableIconButtonProps, "onClick"> {
  onClick: () => Promise<string | undefined | void>;
}

export function StatefulLoadableIconButton({
  onClick,
  ...props
}: StatefulLoadableIconButtonProps) {
  const [loading, setLoading] = useState(false);
  return (
    <LoadableIconButton
      {...props}
      loading={loading}
      onClick={async () => {
        setLoading(true);
        try {
          const ret = await onClick();
          if (ret) toast.success(ret);
        } catch (e) {
          toast.error((e as Error).toString());
        }
        setLoading(false);
      }}
    />
  );
}

export default LoadableIconButton;
