import { toast } from "react-toastify";
import firebase from "firebase/app";

import firestore from "src/apiService/firestore";
import storage from "src/apiService/storage";
import { getUserId } from "src/config/storage";
import {
  userPlanSelector,
  userGetInventoryTypeSelector,
} from "src/store/system/selector";
import { getMercariIntegrations } from "src/store/plaidIntegration/selector";
import { requestNOUpdate } from "src/apiService/modules/numericOverview";
import { mercariIntegrationCreate } from "src/apiService/modules/mercari";
import {
  getExtensionId,
  MissingExtensionError,
  OutdatedExtensionError,
} from "src/utils/extension";
import { getMercariUser } from "src/utils/extension/mercari";
import type { MercariIntegration } from "src/interfaces/plaidIntegration.interface";
import type MonthYear from "src/interfaces/monthYear.interface";
import paralelize from "src/utils/paralelize";
import Cacher from "src/utils/Cacher";
import FirebaseBatcher from "src/utils/FirebaseBatcher";

import type { Dispatcher, SyncStatus, ErrorSyncStatusCode } from "../../types";
import { updateSyncStatus as _updateSyncStatus } from "../actions";
import {
  saveSyncDate,
  parseDate,
  getLastSyncDate,
  canRunSync,
  setRunningInterval,
  clearIsRunning,
  setBeforeUnloadHandler,
  clearBeforeUnloadHandler,
  ReSyncInterval,
  RunningKeys,
  isUserAllowedToDirectImport,
} from "../utils";

import { mapInventory, mapSale } from "./mappings";
import MercariClient, { NotLoggedError } from "./MercariClient";

const ParalelizeJobs = 10;

async function updateIntegration(integrationId: string) {
  try {
    const data = await getMercariUser();

    if (data.cookies && data.username) {
      await mercariIntegrationCreate({
        integrationId,
        cookies: data.cookies,
        username: data.username,
      });
    }
  } catch (err) {
    console.error("There was an error:", err);
  }
}

function updateSyncStatus(status: SyncStatus) {
  return _updateSyncStatus({
    integration: "mercari" as const,
    status,
  });
}

async function syncSale(
  uid: string,
  client: MercariClient,
  transformed: ReturnType<typeof mapSale>,
  start: string
) {
  const db = firestore();

  const transactionId = transformed.transaction_id;

  if (transactionId) {
    const snapshot = await db
      .collection("Sales")
      .where("user", "==", uid)
      .where("transaction_id", "==", transactionId)
      .get();

    if (!snapshot.empty) return;
  }

  const doc = db.collection("Sales").doc();
  await doc.set({
    ...transformed,
    id: doc.id,
    user: uid,
    unreviewed: true,
    sync_start: start,
  });

  if (transformed.sale_price_check_error) {
    await db.collection("Error_Alerts").doc().set({
      user: uid,
      sale_id: doc.id,
      new: true,
      type: "mercari-sale-price-check-error",
      date: new Date(),
      message:
        "There was an error importing some of your Mercari data. Please reach out to support@myresellergenie.com so we can help resolve this issue.",
    });
  }
}

async function syncSales(
  client: MercariClient,
  integration: MercariIntegration,
  start: string
): Promise<void> {
  const uid = await getUserId();
  const lastDate = getLastSyncDate(
    integration,
    "mercariLastSyncDateTransactions"
  );

  for await (const { sales } of client.userItemsQueryFull({
    sortBy: "updated",
  })) {
    let stop = false;
    await paralelize(
      sales,
      async (sale: (typeof sales)[number]) => {
        const updated = new Date(sale.updated * 1000).toISOString();
        // Optimization:
        //   Always: sale_date <= updated,
        //   so if updated < lastDate, then sale_date < lastDate
        // This will prevent us making some requests
        if (updated < lastDate) {
          stop = true;
          return;
        }

        const [orderStatus, product] = await Promise.all([
          client.orderStatusConsolidated(sale.id),
          client.productQuery(sale.id),
        ]);
        if (orderStatus.orderState !== "order_complete") return;

        sale.orderStatus = orderStatus;
        sale.product = product;
        const transformed = mapSale(sale);
        if (!transformed.sale_date) return;
        if (transformed.sale_date.toISOString() < lastDate) return;
        await syncSale(uid, client, transformed, start);
      },
      ParalelizeJobs
    );

    if (stop) break;
  }
}

async function syncInventory(
  client: MercariClient,
  integration: MercariIntegration,
  start: string
): Promise<void> {
  const db = firestore();
  const lastDate = getLastSyncDate(integration, "mercariLastSyncDateInventory");
  const uid = await getUserId();
  const its = [] as ReturnType<typeof mapInventory>[];

  for await (const { items } of client.publicProfileSearchQueryFull()) {
    await paralelize(
      items,
      async (item: (typeof items)[number]) => {
        const product = await client.productQuery(item.id);
        if (product.status !== "on_sale") return;
        const inventory = mapInventory(product);
        if (
          !inventory ||
          (inventory.list_date && inventory.list_date.toISOString() < lastDate)
        )
          return;
        its.push(inventory);
      },
      ParalelizeJobs
    );
  }

  if (its.length) {
    const timestamp = Date.now();
    const filename = `${uid}/mercariInventory/${timestamp}_${integration.id}.json`;
    const storageRef = storage().ref();
    const file = storageRef.child(filename);
    const task = await file.put(
      new Blob([JSON.stringify(its)], { type: "application/json" })
    );
    if (task.state !== firebase.storage.TaskState.SUCCESS)
      throw new Error(`Couldnt upload file ${filename}: ${task.state}`);
    const date = new Date(timestamp);
    const doc = db.collection("Downloads").doc();
    await doc.set({
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      filename,
      new: true,
      timestamp,
      type: "mercariInventory",
      user: uid,
      integrationId: integration.id,
    });
  }
}

function syncDaily(integrationId: string, inventory: boolean) {
  return async (dispatch, getState) => {
    const db = firestore();
    const start = new Date();
    const canRun = await canRunSync(integrationId, RunningKeys);

    if (!canRun) {
      console.warn("Sync is required, but cannot run it");
      return;
    }

    // is syncing timer
    const interval = setRunningInterval(integrationId, RunningKeys);

    try {
      const getIntegration = () => {
        const integration = getMercariIntegrations(getState()).find(
          ({ id }) => id === integrationId
        );

        if (!integration) throw Error(`Integration not found ${integrationId}`);

        return integration;
      };
      const client = new MercariClient();

      const cookieUserId = (await client.getCredentials()).userId;
      if (cookieUserId !== getIntegration().uid) throw new NotLoggedError();

      // Sales
      dispatch(updateSyncStatus({ type: "syncing", step: "sales" }));
      await syncSales(client, getIntegration(), start.toISOString());

      await saveSyncDate(
        integrationId,
        "mercariLastSyncDateTransactions",
        start.toISOString()
      );

      await requestNOUpdate();

      if (inventory) {
        dispatch(updateSyncStatus({ type: "syncing", step: "inventory" }));
        await syncInventory(client, getIntegration(), start.toISOString());
        await saveSyncDate(
          integrationId,
          "mercariLastSyncDateInventory",
          start.toISOString()
        );
      }

      await db.collection("Plaid_Integrations").doc(integrationId).update({
        lastSync: start.toISOString(),
      });
      await updateIntegration(integrationId);
    } finally {
      clearInterval(interval);
      await clearIsRunning(integrationId, RunningKeys);
    }
  };
}

export function mercariSync(
  integration: MercariIntegration,
  dispatcher?: Dispatcher
) {
  return async (dispatch, getState) => {
    let extensionId;
    try {
      extensionId = getExtensionId();
    } catch (e) {
      if (e instanceof MissingExtensionError) {
        dispatch(
          updateSyncStatus({
            type: "error",
            message:
              "Looks like you don’t have the My Reseller Genie extension installed. Go to the “Integrations” tab and click the “Pull Data Now” button for “Mercari”. Follow the prompt to download the extension.",
            dispatcher,
            code: "install-extension",
          })
        );
        return;
      } else if (e instanceof OutdatedExtensionError) {
        dispatch(
          updateSyncStatus({
            type: "error",
            message:
              "Looks like you have an outdated My Reseller Genie extension installed. Go to the “Integrations” tab and click the “Pull Data Now” button for “Mercari”. Follow the prompt to download the new extension.",
            dispatcher,
            code: "install-extension",
          })
        );
        return;
      }

      dispatch(
        updateSyncStatus({
          type: "error",
          message: (e as Error).message || (e as Error).toString(),
          dispatcher,
          code: "unknown",
        })
      );
    }

    if (extensionId) {
      const inventoryType = userGetInventoryTypeSelector(getState());
      const shouldDisableInventory = inventoryType === "cash";

      const inventory =
        integration.inventory !== false && !shouldDisableInventory;

      setBeforeUnloadHandler();

      try {
        await dispatch(syncDaily(integration.id, inventory));
        dispatch(updateSyncStatus(undefined));
      } catch (e) {
        console.error(e);
        let code: ErrorSyncStatusCode = "unknown";

        if (e instanceof NotLoggedError) code = "user-not-logged-in";

        dispatch(
          updateSyncStatus({
            type: "error",
            message: (e as Error).message || (e as Error).toString(),
            code,
            dispatcher,
          })
        );
      } finally {
        clearBeforeUnloadHandler();
      }
    }
  };
}

export function mercariSyncWithCheck(
  integrationId: string,
  noTimeCheck = false
) {
  return async (dispatch, getState) => {
    const state = getState();
    const plan = userPlanSelector(state);

    if (plan === "ultimate") {
      const integration = getMercariIntegrations(state)?.find(
        ({ id }) => id === integrationId
      );

      if (
        integration &&
        integration.type === "mercari" &&
        integration.sync &&
        !integration.error
      ) {
        const lastSync = parseDate(
          integration.lastSync,
          new Date("2000-01-01T01:00:00.000Z")
        );

        if (noTimeCheck || Date.now() - lastSync.getTime() >= ReSyncInterval) {
          await dispatch(
            mercariSync(integration, noTimeCheck ? "manual" : "auto")
          );
        }
      }
    }
  };
}

export function mercariSyncInventory(integrationId: string) {
  return async (
    dispatch,
    getState
  ): Promise<{ error?: true; success?: true }> => {
    const db = firestore();
    const state = getState();

    if (!isUserAllowedToDirectImport(state, "mercariInventory"))
      return { error: true };
    const inventoryType = userGetInventoryTypeSelector(getState());
    if (inventoryType === "cash") return { error: true };

    const integration = getMercariIntegrations(state)?.find(
      ({ id }) => id === integrationId
    );

    if (!integration || integration.type !== "mercari" || integration.error)
      return { error: true };
    let extensionId;

    try {
      extensionId = getExtensionId();
    } catch (e) {
      if (e instanceof MissingExtensionError) {
        dispatch(
          updateSyncStatus({
            type: "error",
            message:
              "Looks like you don’t have the My Reseller Genie extension installed. Go to the “Integrations” tab and click the “Pull Data Now” button for “Mercari”. Follow the prompt to download the extension.",
            dispatcher: "manual",
            code: "install-extension",
          })
        );
        return { error: true };
      } else if (e instanceof OutdatedExtensionError) {
        dispatch(
          updateSyncStatus({
            type: "error",
            message:
              "Looks like you have an outdated My Reseller Genie extension installed. Go to the “Integrations” tab and click the “Pull Data Now” button for “Mercari”. Follow the prompt to download the new extension.",
            dispatcher: "manual",
            code: "install-extension",
          })
        );
        return { error: true };
      }
      toast.error((e as Error).message);
      return { error: true };
    }

    if (!extensionId) return { error: true };

    const client = new MercariClient();
    let cookieUserId;
    try {
      cookieUserId = (await client.getCredentials()).userId;
    } catch (e) {
      console.warn(e);
    }

    if (cookieUserId !== integration.uid) {
      dispatch(
        updateSyncStatus({
          type: "error",
          message: "You must be logged in to Mercari with the correct user",
          code: "user-not-logged-in",
          dispatcher: "manual",
        })
      );
      return { error: true };
    }

    const runningKeys = ["inventoryIsRunning" as const];
    const canRun = await canRunSync(integrationId, runningKeys);

    if (!canRun) {
      console.warn("Sync is required, but cannot run it");
      toast.warning("Please try again in a few minutes.");
      return { error: true };
    }

    // is syncing timer
    const interval = setRunningInterval(integrationId, runningKeys);
    setBeforeUnloadHandler();

    try {
      dispatch(
        updateSyncStatus({ type: "syncing", step: "direct-import-inventory" })
      );
      const uid = await getUserId();
      const timestamp = Date.now();
      const filename = `${uid}/mercariInventory/${timestamp}_${integrationId}.jsonl`;
      const upload = db.collection("Uploads").doc();
      await upload.set({
        user: uid,
        type: "inventory",
        provider: "mercari",
        filename,
        n: 0,
        timestamp,
        id: upload.id,
      });
      let docs = 0;

      const batch = new FirebaseBatcher();
      const cache = new Cacher<string, boolean>();

      for await (const { items } of client.publicProfileSearchQueryFull()) {
        await paralelize(
          items,
          // eslint-disable-next-line no-loop-func
          async (item: (typeof items)[number]) => {
            const product = await client.productQuery(item.id);
            if (product.status !== "on_sale") return;
            const inventory = mapInventory(product);
            const listingId = inventory.listing_id;

            if (listingId) {
              const isEmpty = await cache.run(listingId, async (listingId) => {
                const snapshot = await db
                  .collection("Inventory")
                  .where("user", "==", uid)
                  .where("listing_id", "==", listingId)
                  .get();
                return snapshot.empty;
              });

              if (!isEmpty) return;
            }

            const doc = db.collection("Inventory").doc();
            await batch.set(doc, {
              ...inventory,
              id: doc.id,
              user: uid,
              upload: upload.id,
            });
            docs++;
          },
          ParalelizeJobs
        );
      }

      await batch.wait();
      await batch.commit();

      await upload.update({
        n: docs,
      });
      await requestNOUpdate();
    } catch (e) {
      console.error(e);
      toast.error(`Error: ${(e as Error).message}`);
      return { error: true };
    } finally {
      dispatch(updateSyncStatus(undefined));
      clearBeforeUnloadHandler();
      clearInterval(interval);
      await clearIsRunning(integrationId, runningKeys);
    }

    return { success: true };
  };
}

export function mercariSyncSales(
  integrationId: string,
  { start, end }: { start: MonthYear; end: MonthYear }
) {
  return async (
    dispatch,
    getState
  ): Promise<{ success?: boolean; error?: boolean }> => {
    const db = firestore();
    const state = getState();

    if (!isUserAllowedToDirectImport(state, "mercariSales"))
      return { error: true };
    const integration = getMercariIntegrations(state)?.find(
      ({ id }) => id === integrationId
    );

    if (!integration || integration.type !== "mercari" || integration.error)
      return { error: true };
    let extensionId;

    try {
      extensionId = getExtensionId();
    } catch (e) {
      if (e instanceof MissingExtensionError) {
        dispatch(
          updateSyncStatus({
            type: "error",
            message:
              "Looks like you don’t have the My Reseller Genie extension installed. Go to the “Integrations” tab and click the “Pull Data Now” button for “Mercari”. Follow the prompt to download the extension.",
            dispatcher: "manual",
            code: "install-extension",
          })
        );
        return { error: true };
      } else if (e instanceof OutdatedExtensionError) {
        dispatch(
          updateSyncStatus({
            type: "error",
            message:
              "Looks like you have an outdated My Reseller Genie extension installed. Go to the “Integrations” tab and click the “Pull Data Now” button for “Mercari”. Follow the prompt to download the new extension.",
            dispatcher: "manual",
            code: "install-extension",
          })
        );
        return { error: true };
      }
      toast.error((e as Error).message);
      return { error: true };
    }

    if (!extensionId) return { error: true };

    const client = new MercariClient();
    let cookieUserId;
    try {
      cookieUserId = (await client.getCredentials()).userId;
    } catch (e) {
      console.warn(e);
    }

    if (cookieUserId !== integration.uid) {
      dispatch(
        updateSyncStatus({
          type: "error",
          message: "You must be logged in to mercari with the correct user",
          code: "user-not-logged-in",
          dispatcher: "manual",
        })
      );
      return { error: true };
    }

    const runningKeys = ["salesIsRunning" as const];
    const canRun = await canRunSync(integrationId, runningKeys);

    if (!canRun) {
      console.warn("Sync is required, but cannot run it");
      toast.warning("Please try again in a few minutes.");
      return { error: true };
    }

    // is syncing timer
    const interval = setRunningInterval(integrationId, runningKeys);
    setBeforeUnloadHandler();

    try {
      dispatch(
        updateSyncStatus({
          type: "syncing",
          step: "direct-import-sales",
        })
      );
      // Sync
      const uid = await getUserId();
      const timestamp = Date.now();
      const filename = `${uid}/mercariSales/${timestamp}_${integrationId}.jsonl`;
      const upload = db.collection("Uploads").doc();
      await upload.set({
        user: uid,
        type: "sales",
        provider: "mercari",
        filename,
        n: 0,
        timestamp,
        id: upload.id,
      });
      let docs = 0;

      const startDate = new Date();
      startDate.setFullYear(start.year);
      startDate.setMonth(start.month - 1);
      startDate.setDate(1);
      startDate.setHours(0, 0, 0, 0);

      const endDate = new Date();
      endDate.setFullYear(end.year);
      endDate.setMonth(end.month);
      endDate.setDate(0);
      endDate.setHours(23, 59, 59, 999);

      const batch = new FirebaseBatcher();
      const cache = new Cacher<string, boolean>();

      for await (const { sales } of client.userItemsQueryFull()) {
        await paralelize(
          sales,
          // eslint-disable-next-line no-loop-func
          async (sale: (typeof sales)[number]) => {
            const updated = new Date(sale.updated * 1000);
            // Optimization:
            //   Always: sale_date <= updated,
            //   so if updated < startDate, then sale_date < startDate
            // This will prevent us making some requests
            if (updated < startDate) return;

            const [orderStatus, product] = await Promise.all([
              client.orderStatusConsolidated(sale.id),
              client.productQuery(sale.id),
            ]);
            if (orderStatus.orderState !== "order_complete") return;

            sale.orderStatus = orderStatus;
            sale.product = product;
            const transformed = mapSale(sale);

            if (!transformed.sale_date) return;

            if (
              transformed.sale_date < startDate ||
              endDate < transformed.sale_date
            )
              return;

            const transactionId = transformed.transaction_id;
            if (transactionId) {
              const isEmpty = await cache.run(
                transactionId,
                async (transactionId: string) => {
                  const snapshot = await db
                    .collection("Sales")
                    .where("user", "==", uid)
                    .where("transaction_id", "==", transactionId)
                    .get();
                  return snapshot.empty;
                }
              );

              if (!isEmpty) return;
            }

            const doc = db.collection("Sales").doc();
            const s = {
              ...transformed,
              id: doc.id,
              user: uid,
              upload: upload.id,
            };
            await batch.set(doc, s);
            docs++;
          },
          ParalelizeJobs
        );
      }

      await batch.wait();
      await batch.commit();

      await requestNOUpdate();
      await upload.update({
        n: docs,
      });
    } catch (e) {
      console.error(e);
      toast.error(`Error: ${(e as Error).message}`);
      return { error: true };
    } finally {
      dispatch(updateSyncStatus(undefined));
      clearBeforeUnloadHandler();
      clearInterval(interval);
      await clearIsRunning(integrationId, runningKeys);
    }

    return { success: true };
  };
}
