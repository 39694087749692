import React from "react";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

export function getOptionLabel(item) {
  if (typeof item === "string") return item;
  if (!item.id) return item.item_title;
  const sku = item.sku;
  const title = item.item_title;
  const quantity = `Qty ${item.quantity || 1}`;
  return [sku, title, quantity].filter(Boolean).join(" - ");
}

function ReportSaleItemSoldInput({
  inventories,
  values,
  setValues,
  errors = {},
  className,
  disabled = false,
  fullWidth = false,
}: {
  inventories: any[];
  values: any;
  setValues: (f: (v: any) => any) => void;
  errors?: { item_title?: string };
  className?: string;
  disabled?: boolean;
  fullWidth?: boolean;
}) {
  return (
    <Autocomplete
      fullWidth={fullWidth}
      freeSolo
      autoSelect
      blurOnSelect
      options={inventories}
      getOptionSelected={(opt, v) => {
        if (typeof v === "string") return getOptionLabel(opt) === v;
        return opt.id === v.id;
      }}
      getOptionLabel={getOptionLabel}
      value={values.inventory || null}
      onChange={(e, v, r, opt) => {
        // r - is the reason, perhaps instead of doing all this custom logic it would be better
        //     to have a ref that tracks if the textfield has changed. If this `onChange` is called
        //     with a `blur` reason, but, the textfield hasn't changed, perhaps we could ignore the event
        //     (Have to check the case of arrow selecting without hitting enter)
        let value = v;
        if (typeof v === "string") {
          if (
            values.inventory &&
            (v === values.inventory.item_title ||
              v === getOptionLabel(values.inventory))
          )
            return;
          value = {
            item_title: v,
            purchase_price: 0,
            purchase_date: new Date(),
            platforms_listed: [],
            sku: "",
            brand: "",
            category: "",
            sub_category: "",
            department: "",
            location: "",
          };
        } else if (v) {
          value = {
            ...v,
            purchase_price:
              Math.round((v.purchase_price * 100) / (v.quantity || 1)) / 100,
            purchase_price_total: v.purchase_price,
          };
        }

        setValues(({ purchase_price, ...values }) => {
          if (value) {
            return {
              ...values,
              ...value,
              inventory: value,
            };
          }

          return {
            ...values,
            list_date: null,
            item_title: "",
            purchase_price: 0,
            purchase_date: new Date(),
            sku: "",
            brand: "",
            category: "",
            sub_category: "",
            department: "",
            id: "",
            item_option: null,
            location: "",
            platforms_listed: [],
            upload: "",
            inventory: null,
          };
        });
      }}
      className={className || ""}
      autoHighlight
      renderInput={(params) => (
        <TextField
          {...params}
          disabled={disabled}
          margin="dense"
          label="Item Sold"
          fullWidth
          variant="outlined"
          error={errors.item_title ? true : false}
          helperText={errors.item_title}
          autoFocus
        />
      )}
    />
  );
}

export default ReportSaleItemSoldInput;
