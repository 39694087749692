import React, { useState } from "react";
import Tour from "reactour";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import { userDontShowDialogsSelector } from "src/store/system/selector";
import DontShowThisMessageAgainCheckbox from "src/components/DontShowThisMessageAgainCheckbox";
import { useWalkThroughMarker } from "src/utils/WalkThroughMarker";

const Key = "walk_through_review_sales";

const Steps = [
  {
    content: (props) => (
      <>
        <DialogTitle>Review Sales - 1/3</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Reviewing sales helps you to stay engaged with your business. During
            this step, you can match your sales to inventory (if you track
            inventory) or you can view your new sales and mark them as reviewed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(1)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#review-sales-walkthough-checks",
    content: (props) => (
      <>
        <DialogTitle>Review Sales - 2/3</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select sales individually by checking the boxes on the left or
            select them all by checking the box in the header of the table.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(0)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(2)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#review-sales-walkthough-actions",
    content: (props) => (
      <>
        <DialogTitle>Review Sales - 3/3</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Once you’ve selected a sale, you can click either “Match to
            Inventory” or “Mark Reviewed”.
          </DialogContentText>
          <DialogContentText>
            “Match to Inventory” will open the automatic matching tool to help
            you attach existing inventory items to the sales you selected and
            remove them from inventory. All selected sales must be matched to
            inventory for the match to be completed. Once this is done, the
            sales will be marked as “reviewed”.
          </DialogContentText>
          <DialogContentText>
            “Mark Reviewed” will mark the sales as reviewed. Once the sales are
            “reviewed”, they will no longer show up on the “Review Sales”
            screen.
          </DialogContentText>
          <DontShowThisMessageAgainCheckbox dialog={Key} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(1)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.close()}
          >
            Done
          </Button>
        </DialogActions>
      </>
    ),
  },
];

const useStyles = makeStyles((theme) => ({
  helper: {
    maxWidth: "80%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "500px",
    },
    padding: 0,
  },
}));

function WalkThrough() {
  const [open, setOpen] = useState(true);
  const userDontShowDialogs = useSelector(userDontShowDialogsSelector);

  const classes = useStyles();
  const isOpen =
    open && userDontShowDialogs && !userDontShowDialogs?.includes(Key);

  useWalkThroughMarker(
    !!isOpen,
    "review-sales-walkthough-checks",
    "div > .MuiDataGrid-columnHeaderCheckbox, div > .MuiDataGrid-cellCheckbox:first-child",
    ".review-sales-dialog"
  );

  return (
    <Tour
      startAt={0}
      closeWithMask={false}
      disableFocusLock
      steps={Steps}
      isOpen={isOpen}
      onRequestClose={() => {
        setOpen(false);
      }}
      showButtons={false}
      showCloseButton={false}
      showNavigation={false}
      showNavigationNumber={false}
      showNumber={false}
      disableDotsNavigation
      rounded={5}
      className={classes.helper}
      disableInteraction
    />
  );
}

export default WalkThrough;
