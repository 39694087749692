import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { XGrid } from "@material-ui/x-grid";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";

import { analyticsGroupingsListSelector } from "src/store/system/selector";
import { updelsertAnalyticGrouping } from "src/apiService/modules/analyticsGroupings";
import { AnalyticsGroupingItem } from "src/interfaces/systemState.interface";

import DeleteButton from "../DeleteButton";
import AddEditDialog from "./AddEditAnalyticsGroupingsDialog";

const useStyles = makeStyles({
  bold: {
    fontWeight: "bold",
  },
});

function AnalyticsGroupings({ className }) {
  const classes = useStyles();
  const [open, setOpen] = useState<AnalyticsGroupingItem | boolean>(false);
  const analyticsGroupingsList = useSelector(analyticsGroupingsListSelector);
  const columns = useMemo(
    () => [
      {
        field: "department",
        headerName: "Department",
        minWidth: 250,
        flex: 1,
        editable: false,
        sortable: true,
      },
      {
        field: "category",
        headerName: "Category",
        minWidth: 250,
        flex: 1,
        editable: false,
        sortable: true,
      },
      {
        field: "subcategory",
        headerName: "Sub-Category",
        minWidth: 250,
        flex: 1,
        editable: false,
        sortable: true,
      },
      {
        field: "delete",
        headerName: " ",
        width: 100,
        editable: false,
        sortable: false,
        filterable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
        renderCell: (params) => (
          <>
            <IconButton onClick={() => setOpen(params.row)}>
              <EditIcon />
            </IconButton>
            <DeleteButton
              onClick={async () =>
                await updelsertAnalyticGrouping({ delete: params.row })
              }
            />
          </>
        ),
      },
    ],
    [setOpen]
  );

  return (
    <>
      <AddEditDialog open={open} onClose={() => setOpen(false)} />
      <Grid item xs={12} className={className}>
        <Typography className={classes.bold}>Analytics Groupings</Typography>
      </Grid>
      <Grid item xs={12}>
        <XGrid
          getRowId={(r) => `${r.department}-${r.category}-${r.subcategory}`}
          rows={analyticsGroupingsList}
          columns={columns}
          headerHeight={35}
          rowHeight={35}
          style={{ height: 300, width: "100%" }}
          disableSelectionOnClick
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={() => setOpen(true)}
          color="primary"
          variant="contained"
          style={{
            backgroundColor: "green",
          }}
        >
          Add
        </Button>
      </Grid>
    </>
  );
}

export default AnalyticsGroupings;
