import { handleActions } from "redux-actions";
import { LOG_OUT } from "../system/actions";

import {FS_CREATE_ITEMS, FS_DELETE_ITEMS, FS_UPDATE_ITEMS, GL_ACCOUNTS} from "../common";
import produce from "immer";
import {GeneralLedgerAccountState} from "../../interfaces/generalLedgerAccountState.interface";

const initialState: GeneralLedgerAccountState = {
    items: [],
};

export const generalLedgerAccountReducer = handleActions<GeneralLedgerAccountState, any>(
    {
        [GL_ACCOUNTS + FS_CREATE_ITEMS]: (state, { payload }) =>
            produce(state, (draft) => {
                draft.items.push(...payload);
            }),
        [GL_ACCOUNTS + FS_DELETE_ITEMS]: (state, { payload }) =>
            produce(state, (draft) => {
                draft.items = state.items.filter((item) => !payload.find((deleted) => deleted.id === item.id));
            }),
        [GL_ACCOUNTS + FS_UPDATE_ITEMS]: (state, { payload }) =>
            produce(state, (draft) => {
                draft.items.forEach((item) => {
                    const updated = payload.find((newItem) => {
                        return newItem.id === item.id;
                    });
                    if (updated) {
                        Object.assign(item, updated);
                    }
                });
            }),
        [LOG_OUT]: () => initialState,
    },
    initialState
);
