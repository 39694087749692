import {
  Button,
  TextField,
  IconButton,
  Grid,
  CircularProgress,
} from "@material-ui/core";

import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const StripeDialog = ({ open, handleClose, link }) => {
	return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"body"}
      aria-labelledby="goto-stripe"
      aria-describedby="scroll-dialog-description"
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle id="goto-stripe relative">Leaving page</DialogTitle>
      <DialogContent>
        <DialogContentText
          className="relative"
          tabIndex={-1}
        >
          <p>You are leaving this page for the Stripe customer portal to securely manage your subscription. Proceed?</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="px-7 py-5">
        <Button onClick={handleClose} color="primary" variant="contained">
          Cancel
        </Button>
        <Button
          component="a"
          color="secondary"
          variant="contained"
          disabled={!link}
          href={link}
        >
          {!link && <CircularProgress size={15} className="mr-3" />}
          {!link ? 'Connecting to Stripe...' : 'Go to Stripe'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default StripeDialog;
