import React, { type PropsWithChildren } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import InfoTooltip from "src/components/InfoTooltip";
import NumberField from "src/components/NumberField";

function TooltipWrapper({ children }: PropsWithChildren<{}>) {
  return (
    <Box
      display="flex"
      minWidth="34px"
      justifyContent="center"
      alignItems="center"
    >
      {children}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  tooltip: {
    padding: theme.spacing(0.5),
  },
  titleTooltip: {
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  bold: {
    fontWeight: 500,
  },
}));

const RecordReturnDialog = ({
  loading,
  handleSave,
  values,
  setValues,
  handleChange,
  handleChangeNegative,
  handleDelete,
}) => {
  const classes = useStyles();

  return (
    <>
      <DialogTitle>Edit Refund</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" className={classes.bold}>
              Refund Details
            </Typography>
            <Typography variant="body2" gutterBottom>
              ‎
            </Typography>
            <Box display="flex">
              <TooltipWrapper />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  autoOk={true}
                  margin="dense"
                  label="Return Date"
                  required
                  value={values.return_date || null}
                  disabled={loading}
                  onChange={(e: any, t: any) => {
                    setValues({ ...values, return_date: new Date(e) });
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Box display="flex">
              <TooltipWrapper>
                <InfoTooltip
                  text="The amount that you refunded to the customer (enter as a positive number)."
                  size={18}
                  className={classes.tooltip}
                />
              </TooltipWrapper>
              <NumberField
                fixed={2}
                fullWidth
                variant="outlined"
                margin="dense"
                label="Refund Amount"
                required
                value={-values.sale_price}
                disabled={loading}
                onChange={handleChangeNegative("sale_price", true)}
                allowNegative
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box display="flex">
              <TooltipWrapper>
                <InfoTooltip
                  text="Any fees refunded to you by the platform (enter as a positive number)."
                  size={18}
                  className={classes.tooltip}
                />
              </TooltipWrapper>
              <NumberField
                fixed={2}
                fullWidth
                variant="outlined"
                margin="dense"
                label="Fees Refunded"
                value={-values.transaction_fees}
                disabled={loading}
                onChange={handleChangeNegative("transaction_fees", true)}
                allowNegative
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box display="flex">
              <TooltipWrapper>
                <InfoTooltip
                  text="If your shipping costs were refunded, enter them here (enter as a positive number)."
                  size={18}
                  className={classes.tooltip}
                />
              </TooltipWrapper>
              <NumberField
                fixed={2}
                fullWidth
                variant="outlined"
                margin="dense"
                label="Shipping Cost Refunded"
                value={-values.shipping_cost}
                disabled={loading}
                onChange={handleChangeNegative("shipping_cost", true)}
                allowNegative
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box display="flex">
              <TooltipWrapper>
                <InfoTooltip
                  text="Sales tax that was refunded to the customer (enter as a positive number)."
                  size={18}
                  className={classes.tooltip}
                />
              </TooltipWrapper>
              <NumberField
                fixed={2}
                fullWidth
                variant="outlined"
                margin="dense"
                label="Sales Tax Refunded"
                value={-values.sales_tax}
                disabled={loading}
                onChange={handleChangeNegative("sales_tax", true)}
                allowNegative
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography variant="subtitle1" className={classes.bold}>
                Additional Fees
              </Typography>
              <Typography variant="body2" gutterBottom>
                Any additional costs related to this refund
              </Typography>
            </Box>
            <NumberField
              fixed={2}
              fullWidth
              variant="outlined"
              margin="dense"
              label="Shipping Cost"
              value={values.extra_shipping_cost}
              disabled={loading}
              onChange={handleChange("extra_shipping_cost", true)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <NumberField
              fixed={2}
              fullWidth
              variant="outlined"
              margin="dense"
              label="Transaction Fees"
              value={values.extra_transaction_fees}
              disabled={loading}
              onChange={handleChange("extra_transaction_fees", true)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleDelete}
          color="secondary"
          variant="contained"
          disabled={loading}
        >
          Delete
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          Update
        </Button>
      </DialogActions>
    </>
  );
};

export default RecordReturnDialog;
