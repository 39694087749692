import React from "react";
import firebase from "firebase/app";
import { useSelector } from "react-redux";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";

import { userDontShowDialogsSelector } from "src/store/system/selector";
import { updateUserApi } from "src/apiService/modules/system";
import type { DontShowDialog } from "src/interfaces/systemState.interface";

const ListEntries: { primary: string; key: DontShowDialog }[] = [
  { primary: "Maintaining your Genie", key: "maintenance_activities_dialog" },
  { primary: "Notifications", key: "walk_through_notifications_icon" },
  {
    primary: "Review New Sales",
    key: "walk_through_notifications_review_new_sales",
  },
  {
    primary: "Review Pending Inventory",
    key: "walk_through_notifications_review_pending_inventory",
  },
  {
    primary: "Review New Expenses",
    key: "walk_through_notifications_review_new_expenses",
  },
  {
    primary: "Review New Funds",
    key: "walk_through_notifications_review_new_funds",
  },
  {
    primary: "Review Sales",
    key: "walk_through_review_sales",
  },
  {
    primary: "Auto-Matching Sales",
    key: "walk_through_auto_matching_sales",
  },
  {
    primary: "Manually Matching Sales",
    key: "walk_through_manual_matching_sales",
  },
  {
    primary: "Duplicate Checker",
    key: "walk_through_duplicate_inventory_checker",
  },
  {
    primary: "Inventory Import",
    key: "walk_through_inventory_import",
  },
  {
    primary: "Categorize Expenses",
    key: "walk_through_categorize_expenses",
  },
  {
    primary: "Review Refunds",
    key: "walk_through_review_refunds",
  },

  // { primary: "Recording Sales", key: "recording_sales_dialog" },
];
const CustomSwitch = withStyles({
  checked: {},
  track: {},
  switchBase: {
    color: "#f44336",
    "&$checked": {
      color: "#08bc0c",
    },
    "&$checked + $track": {
      backgroundColor: "#08bc0c",
    },
  },
})(Switch);

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    ...theme.typography.body1,
    padding: 0,
    fontWeight: "bold",
  },
}));

function WalkThroughTopicsList() {
  const classes = useStyles();
  const userDontShowDialogs = useSelector(userDontShowDialogsSelector) || [];
  return (
    <List
      dense
      disablePadding
      subheader={
        <ListSubheader className={classes.listSubheader}>
          Walk Through Topics
        </ListSubheader>
      }
    >
      <ListItem dense>
        <ListItemText
          disableTypography
          primary={<Typography variant="overline">Topic</Typography>}
        />
        <ListItemSecondaryAction>
          <Typography variant="overline">Turn On</Typography>
        </ListItemSecondaryAction>
      </ListItem>
      {ListEntries.map(({ primary, key }) => (
        <ListItem key={key} dense>
          <ListItemText primary={primary} />
          <ListItemSecondaryAction>
            <CustomSwitch
              checked={!userDontShowDialogs.includes(key as any)}
              edge="end"
              onChange={(e) => {
                const checked = !e.target.checked;
                updateUserApi({
                  dontShowDialogs:
                    firebase.firestore.FieldValue[
                      checked ? "arrayUnion" : "arrayRemove"
                    ](key),
                });
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
}

export default WalkThroughTopicsList;
