import { getUserId } from "../../config";
import firestore from "src/apiService/firestore";
import { GeneralLedgerAccount } from "src/interfaces/generalLedgerAccount.interface";

const db = firestore();

export async function addGeneralLedgerAccount(
  accountName: string,
  accountType: string
): Promise<GeneralLedgerAccount> {
  const ref = db.collection("GL_Accounts").doc();
  const userId = await getUserId();
  const value = {
    name: accountName,
    type: accountType,
    id: ref.id,
    user: userId,
  };
  await ref.set(value);

  return value;
}

export const updateGeneralLedgerAccount = async (accountId, value, field) => {
  const ref = db.collection("GL_Accounts").doc(accountId);
  let docData = await ref.get();
  let glAccount = docData.data();
  if (glAccount) {
    glAccount[field] = value;
    return await ref.set(glAccount, { merge: true });
  }
  return false;
};

export const deleteGeneralLedgerAccount = async (accountId) => {
  const ref = db.collection("GL_Accounts").doc(accountId);
  return await ref.delete();
};
