import { createTheme, createStyles, Theme as DefaultTheme, ThemeOptions } from "@material-ui/core/styles";

const lightColor = "rgba(241, 238, 255, 0.7)";
const lightGrayColor = "rgb(241, 238, 245)";
// const grayColor = "rgb(215, 212, 219)";
const lightBlueColor = "rgb(68, 112, 147)";
const blueColor = "rgb(24, 68, 97)";
const lightGreenColor = "rgb(28, 93, 59)";
const greenColor = "rgb(4, 76, 38)";
const errorColor = "rgb(255, 69, 58)";

export const colors = {
  blueColor,
  lightColor,
  lightGrayColor,
  lightBlueColor,
  lightGreenColor,
  greenColor,
  errorColor,
};

const Colors = {
  blue: blueColor, // theme.palette.primary.dark
  light: lightColor,
  lightGray: lightGrayColor,
  lightBlue: lightBlueColor, // theme.palette.primary.main
  lightGreen: lightGreenColor,
  green: greenColor,
  error: errorColor,
};

export interface Theme extends DefaultTheme {
  palette: DefaultTheme["palette"] & { colors: typeof Colors };
};

export const drawerWidth = 256;

export let theme = createTheme({
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  palette: {
    primary: {
      light: "#63ccff",
      main: lightBlueColor,
      dark: blueColor,
    },
    colors: Colors,
  },
  shape: {
    borderRadius: 8,
  },
} as ThemeOptions);

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#fff",
      },
    },
    MuiButton: {
      label: {
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none",
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
        margin: "0 16px",
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up("md")]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#404854",
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: 500,
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: 0,
        "& svg": {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48,
    },
  },
};

export const styles = (theme: DefaultTheme) =>
  createStyles({
    root: {
      display: "flex",
      minHeight: "100vh",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appContent: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    mainContent: {
      flex: 1,
      padding: "48px 36px 0",
      background: "#eaeff1",
    },
  });

export default theme;
