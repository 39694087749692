import { all, call, fork, put, take } from "redux-saga/effects";
import { toast } from "react-toastify";

import { fetchPlatformsApi } from "../../apiService";
import { parseError } from "../../utils";

import {
  PLATFORM,
  FAIL,
  START,
  SUCCESS,
  FETCH_ITEMS,
} from "../common";

function* fetchItems() {
  while (true) {
    yield take(PLATFORM + FETCH_ITEMS + START);
    try {
      const data: { data: any } = yield call(fetchPlatformsApi);
      yield put({ type: PLATFORM + FETCH_ITEMS + SUCCESS, payload: { data } });
    } catch (error) {
      toast.error(parseError(error));
      yield put({
        type: PLATFORM + FETCH_ITEMS + FAIL,
        payload: parseError(error),
      });
    }
  }
}
 
 
export function* platformSagas() {
  yield all([
    fork(fetchItems),
  ]);
}
