import React, { useState } from "react";
import { toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import { deleteAccountData } from "src/apiService/modules/system";
import ConfirmDeleteAccountDataDialog from "./ConfirmDeleteAccountDataDialog";

function RestartButton() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ConfirmDeleteAccountDataDialog
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={async () => {
          try {
            await deleteAccountData();
            toast.success("Data deleted successfully");
          } catch (e) {
            toast.error(
              `Error: ${(e as Error).message || (e as Error).toString()}`
            );
          }
          setOpen(false);
        }}
      />
      <Typography component="p" variant="subtitle1" align="center">
        Need to restart? No problem, click the “Delete Account Data” button
        below.
      </Typography>
      <Box display="flex" width="100%" justifyContent="center" my={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setOpen(true)}
        >
          Delete Account Data
        </Button>
      </Box>
    </>
  );
}

export default RestartButton;
