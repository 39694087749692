import firebase from "firebase/app";
import "firebase/functions";
import firestore from "src/apiService/firestore";
import { getUserId } from "src/config/storage";

export async function poshmarkIntegrationCreate(args: {
  jwt: string;
  integrationId?: string;
  username?: string;
  lastSync?: string;
}) {
  const f = firebase.functions().httpsCallable("poshmark-integrationCreate");
  return await f(args);
}

export async function poshmarkIntegrationSyncChange(
  id: string,
  sync: boolean,
  inventory: boolean = true
) {
  const userId = await getUserId();
  const db = firestore();

  await db.runTransaction(async (transaction) => {
    const ref = db.collection("Plaid_Integrations").doc(id);
    const doc = await transaction.get(ref);
    if (!doc.exists) return;
    if (doc.data()?.user !== userId) return;
    await transaction.update(ref, { sync, inventory: sync && inventory });
  });
}

export async function poshmarkIntegrationDelete(id: string) {
  const userId = await getUserId();
  const db = firestore();

  await db.runTransaction(async (transaction) => {
    const ref = db.collection("Plaid_Integrations").doc(id);
    const doc = await transaction.get(ref);
    if (!doc.exists) return;
    if (doc.data()?.user !== userId) return;
    await transaction.delete(ref);
  });
}

/*
export async function poshmarkSyncInventory(integrationId: string) {
  const f = firebase.functions().httpsCallable("poshmark-syncInventory");

  const result = await f({ integrationId });
  return result.data;
}

export async function poshmarkSyncSales(
  integrationId: string,
  start: MonthYear,
  end: MonthYear
) {
  const f = firebase.functions().httpsCallable("poshmark-syncSales");

  const result = await f({
    integrationId,
    start,
    end,
    timezoneOffset: new Date().getTimezoneOffset(),
  });
  return result.data;
}

export async function poshmarkSyncDailyNow(integrationId: string) {
  const f = firebase.functions().httpsCallable("poshmark-syncDailyNow");
  const result = await f({
    integrationId,
  });
  return result.data;
}
*/
