import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function HomeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="60 40 480 520">
      <defs>
        <linearGradient
          id="a"
          x1={316.35}
          x2={361.61}
          y1={436.54}
          y2={173.82}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#af812d" />
          <stop offset={1} stopColor="#f7cd40" />
        </linearGradient>
        <linearGradient
          xlinkHref="#a"
          id="f"
          x1={375.54}
          x2={197.42}
          y1={403.97}
          y2={376.75}
        />
        <linearGradient
          id="c"
          x1={324.98}
          x2={276.2}
          y1={445.08}
          y2={391.12}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f7cd40" />
          <stop offset={1} stopColor="#f7cd40" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          xlinkHref="#a"
          id="g"
          x1={334.46}
          x2={307.07}
          y1={203.3}
          y2={174.67}
        />
        <linearGradient
          xlinkHref="#a"
          id="i"
          x1={37.64}
          x2={77.07}
          y1={390.54}
          y2={161.66}
        />
        <linearGradient
          id="e"
          x1={312.67}
          x2={282.4}
          y1={384.4}
          y2={196.25}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#af812d" />
          <stop offset={1} stopColor="#f7cd40" />
        </linearGradient>
        <linearGradient
          id="j"
          x1={188.73}
          x2={474.81}
          y1={73.65}
          y2={622.65}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#af812d" stopOpacity={0} />
          <stop offset={0.35} stopColor="#9a7127" stopOpacity={0.14} />
          <stop offset={0.73} stopColor="#5d4518" stopOpacity={0.57} />
          <stop offset={0.99} stopColor="#1f1708" />
        </linearGradient>
        <linearGradient
          xlinkHref="#a"
          id="k"
          x1={56.17}
          x2={84.92}
          y1={292.66}
          y2={223.66}
        />
        <linearGradient
          xlinkHref="#a"
          id="l"
          x1={341.13}
          x2={240.12}
          y1={318.42}
          y2={199.51}
        />
        <linearGradient
          xlinkHref="#a"
          id="m"
          x1={379.24}
          x2={230.24}
          y1={247.7}
          y2={247.7}
        />
        <linearGradient
          xlinkHref="#a"
          id="n"
          x1={363.55}
          x2={274.8}
          y1={208.94}
          y2={208.94}
        />
        <linearGradient
          id="o"
          x1={521.91}
          x2={454.66}
          y1={237.41}
          y2={358.37}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#af812d" />
          <stop offset={0.46} stopColor="#f7cd40" />
          <stop offset={1} stopColor="#af812d" />
        </linearGradient>
        <linearGradient
          id="b"
          x1={381.83}
          x2={357.37}
          y1={166.01}
          y2={297.28}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#1f1708" />
          <stop offset={0.52} stopColor="#604718" stopOpacity={0.54} />
          <stop offset={1} stopColor="#af812d" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          xlinkHref="#b"
          id="p"
          x1={674.56}
          x2={233.1}
          y1={226.83}
          y2={204.76}
        />
        <linearGradient
          xlinkHref="#b"
          id="q"
          x1={672.65}
          x2={231.2}
          y1={264.94}
          y2={242.87}
        />
        <linearGradient
          id="d"
          x1={322.72}
          x2={283.77}
          y1={311.92}
          y2={246.9}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#184461" />
          <stop offset={0.19} stopColor="#184461" stopOpacity={0.66} />
          <stop offset={0.39} stopColor="#184461" stopOpacity={0.37} />
          <stop offset={0.59} stopColor="#184461" stopOpacity={0.16} />
          <stop offset={0.8} stopColor="#184461" stopOpacity={0.04} />
          <stop offset={1} stopColor="#184461" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="t"
          x1={93.81}
          x2={88.29}
          y1={211.09}
          y2={257.74}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#1f1708" />
          <stop offset={0.36} stopColor="#3f2e10" stopOpacity={0.78} />
          <stop offset={0.89} stopColor="#997027" stopOpacity={0.15} />
          <stop offset={1} stopColor="#af812d" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          xlinkHref="#b"
          id="u"
          x1={397.77}
          x2={325.67}
          y1={577.28}
          y2={325.42}
        />
        <linearGradient
          xlinkHref="#b"
          id="v"
          x1={351.24}
          x2={355.09}
          y1={263.83}
          y2={437.1}
        />
        <linearGradient
          id="w"
          x1={461.7}
          x2={433.34}
          y1={330.53}
          y2={300.22}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f7cd40" />
          <stop offset={1} stopColor="#f7cd40" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          xlinkHref="#c"
          id="x"
          x1={478.65}
          x2={217.25}
          y1={323.33}
          y2={688.05}
        />
        <linearGradient
          xlinkHref="#c"
          id="z"
          x1={218.58}
          x2={309.96}
          y1={81.04}
          y2={236.68}
        />
        <linearGradient
          xlinkHref="#d"
          id="A"
          x1={335.12}
          x2={290.99}
          y1={359.78}
          y2={394.04}
        />
        <linearGradient
          xlinkHref="#c"
          id="B"
          x1={258.26}
          x2={255.43}
          y1={316.84}
          y2={250.12}
        />
        <linearGradient
          xlinkHref="#c"
          id="C"
          x1={329.11}
          x2={288.1}
          y1={264.29}
          y2={242.1}
        />
        <linearGradient
          xlinkHref="#e"
          id="D"
          x1={540.32}
          x2={351.65}
          y1={295.21}
          y2={79.58}
        />
        <linearGradient
          xlinkHref="#e"
          id="E"
          x1={247.74}
          x2={110.73}
          y1={493.41}
          y2={338.43}
        />
        <radialGradient
          id="h"
          cx={317.52}
          cy={370.9}
          r={77.94}
          fx={317.52}
          fy={370.9}
          gradientTransform="matrix(0 .96 -1.59 0 906.39 1.69)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.39} stopColor="#1f1708" />
          <stop offset={0.69} stopColor="#291e0a" stopOpacity={0.93} />
          <stop offset={0.84} stopColor="#4d3913" stopOpacity={0.68} />
          <stop offset={0.95} stopColor="#8a6623" stopOpacity={0.25} />
          <stop offset={1} stopColor="#af812d" stopOpacity={0} />
        </radialGradient>
        <radialGradient
          id="r"
          cx={428.77}
          cy={192.34}
          r={309.37}
          fx={428.77}
          fy={192.34}
          gradientTransform="matrix(.96 0 0 .96 11.79 -49.59)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#af812d" stopOpacity={0} />
          <stop offset={0.48} stopColor="#a4792a" stopOpacity={0.07} />
          <stop offset={0.73} stopColor="#805e21" stopOpacity={0.32} />
          <stop offset={0.92} stopColor="#433111" stopOpacity={0.75} />
          <stop offset={0.99} stopColor="#1f1708" />
        </radialGradient>
        <radialGradient
          id="s"
          cx={481.54}
          cy={470.91}
          r={173.94}
          fx={481.54}
          fy={470.91}
          gradientTransform="matrix(.96 0 0 .96 11.79 -49.59)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#af812d" stopOpacity={0} />
          <stop offset={0.49} stopColor="#a4792a" stopOpacity={0.07} />
          <stop offset={0.73} stopColor="#805e21" stopOpacity={0.32} />
          <stop offset={0.92} stopColor="#433111" stopOpacity={0.75} />
          <stop offset={1} stopColor="#1f1708" />
        </radialGradient>
        <radialGradient
          id="y"
          cx={420.69}
          cy={220.68}
          r={175.23}
          fx={420.69}
          fy={220.68}
          gradientTransform="matrix(.96 0 0 .96 11.79 -49.59)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f7cd40" />
          <stop offset={1} stopColor="#f7cd40" stopOpacity={0} />
        </radialGradient>
      </defs>
      <g data-name="Layer 9">
        <path
          d="M246.23 417.17v13.17c22.6 4.05 46.95 6.26 72.32 6.26s49.72-2.21 72.32-6.26v-13.17c-22.6 4.06-46.95 6.27-72.32 6.27-25.37 0-49.72-2.21-72.32-6.27Z"
          style={{
            fill: "url(#a)",
          }}
        />
        <path
          d="M317.19 367.07c-.43 0-.87 0-1.31-.02-4.03-.08-7.93-.36-11.73-.81-1.13-.13-2.24-.28-3.35-.44-.71-.1-1.43-.2-2.13-.31-10.5 23.03-29.24 41.51-52.45 51.66s0 .01 0 .01c22.6 4.06 46.95 6.27 72.32 6.27 25.37 0 49.72-2.21 72.32-6.27-23.31-10.21-42.11-28.81-52.58-51.98-2.45.48-4.93.87-7.43 1.17-4.04.48-8.14.73-12.31.73-.46 0-.91-.01-1.36-.02Z"
          style={{
            fill: "url(#f)",
          }}
        />
        <path
          d="M318.55 378.78v27.17c0 9.72-8.08 17.53-17.79 17.13-6.56-.27-13.03-.69-19.41-1.25a393.275 393.275 0 0 1-35.12-4.66c19.52-8.55 35.88-22.98 46.83-41.05 1.84.4 3.72.75 5.61 1.06.7.12 1.42.2 2.13.31 1.11.16 2.22.31 3.35.44 3.8.45 7.71.73 11.73.81.44 0 .88 0 1.31.02.45 0 .89.02 1.35.02Z"
          style={{
            fill: "url(#c)",
          }}
        />
        <path
          d="M323.83 184.45c0-2.39-1.12-4.51-2.88-5.86a7.248 7.248 0 0 0-4.53-1.56c-4.1 0-7.41 3.32-7.41 7.42s3.31 7.41 7.41 7.41c.16 0 .32 0 .48-.03 3.87-.23 6.93-3.45 6.93-7.38Z"
          style={{
            fill: "url(#g)",
          }}
        />
        <path
          d="M343.77 375.64a101.647 101.647 0 0 1-12.91 2.4c-4.04.49-8.14.74-12.31.74-.46 0-.9-.02-1.35-.02-.44 0-.88 0-1.31-.02-4.03-.08-7.93-.36-11.73-.81-1.13-.13-2.24-.28-3.35-.44-.71-.11-1.43-.19-2.13-.31-1.9-.31-3.77-.66-5.61-1.06a101.4 101.4 0 0 0 5.61-10.62c.7.12 1.42.2 2.13.31 1.11.17 2.22.31 3.35.44 3.8.45 7.71.73 11.73.81.44 0 .88 0 1.31.02.45 0 .89.02 1.35.02 4.16 0 8.27-.25 12.31-.74 2.51-.29 4.98-.68 7.43-1.17a100.9 100.9 0 0 0 5.48 10.46Z"
          style={{
            fill: "url(#h)",
          }}
        />
        <path
          d="M60.77 256.15h.05c-.02 0-.04 0-.06.02Z"
          style={{
            fill: "url(#i)",
          }}
        />
        <path
          d="M510.65 316.58c-20.56 31.01-59.38 51.84-75.29 33.27-7.94-9.26-6.01-26.53.22-41.13 3.89-9.12 10.67-19.7 15.83-23.82 1.16-.92 2.85-.66 3.64.57l7.5 13.53c.55.87.52 1.98-.09 2.81-12.08 16.34-23.79 34.97-16.85 43.38.88 1.05 1.9 1.83 3.06 2.35.05.02.11.05.16.07 16.22 7.35 39.51-8.39 55.03-32.07 19.99-30.51 11.65-60.44-19.07-57.54-.91.03-1.85.09-2.8.19-43.06 4.57-58.29 60.08-107 92.04a102.156 102.156 0 0 1-36.71 14.92c-2.45.48-4.93.87-7.43 1.17-4.04.49-8.14.74-12.31.74-.46 0-.9-.02-1.36-.02-.43 0-.87 0-1.3-.02-4.03-.08-7.93-.37-11.73-.81-1.13-.14-2.24-.28-3.35-.44-.71-.11-1.43-.2-2.13-.31-7.21-1.18-14-2.99-20.45-5.29-6.47-2.32-12.59-5.14-18.45-8.37-25.49-13.97-46.13-35.39-68.52-55.04-19.29-16.92-39.73-32.34-65.46-40.01-15.14-4.51-32.1-6.35-51.74-4.26-3.99.42-8.09 1.01-12.31 1.76 3-.9 6.32-2.03 9.84-3.4 2.36-.92 4.82-1.96 7.33-3.07.49-.21.97-.43 1.47-.65 2.51-1.15 4.92-2.32 7.21-3.49.07-.04.14-.08.2-.12.16-.08.31-.16.47-.23 2.96-1.52 27.59-14.78 59.34-6.18a82.21 82.21 0 0 1 24.09 10.88c.24.16.48.31.72.48.98.65 1.97 1.29 2.99 1.91-.2-.12-.41-.23-.6-.36 44.05 28.44 97.27 55.65 141.64 55.65 38.1 0 69.71-14.82 92.57-34.83 52.67-44.94 98.42-39.3 110.7-15.4 10.17 19.76 3.44 42.32-9.04 61.13Z"
          style={{
            fill: "url(#e)",
          }}
        />
        <path
          d="M511.57 269.98c-4.45-8.37-13.53-13.21-26.77-11.96-.91.03-1.85.09-2.8.19-43.06 4.57-58.29 60.08-107 92.04a102.156 102.156 0 0 1-36.71 14.92c-2.45.48-4.93.87-7.43 1.17-4.04.49-8.14.74-12.31.74-.46 0-.9-.02-1.36-.02-.43 0-.87 0-1.3-.02-4.03-.08-7.93-.37-11.73-.81-1.13-.14-2.24-.28-3.35-.44-.71-.11-1.43-.2-2.13-.31-7.21-1.18-14-2.99-20.45-5.29-6.47-2.32-12.59-5.14-18.45-8.37-25.49-13.97-46.13-35.39-68.52-55.04-19.29-16.92-39.73-32.34-65.46-40.01-15.14-4.51-32.1-6.35-51.74-4.26-3.99.42-8.09 1.01-12.31 1.76 3-.9 6.32-2.03 9.84-3.4 2.36-.92 4.82-1.96 7.33-3.07.49-.21.97-.43 1.47-.65.14-.06.27-.13.4-.18 16.02-.8 30.21 1.11 43.07 4.94 25.73 7.68 46.17 23.1 65.46 40.01 22.39 19.65 43.03 41.07 68.52 55.04 5.87 3.23 11.99 6.05 18.45 8.37a115.15 115.15 0 0 0 20.45 5.29c.7.11 1.42.2 2.13.31 1.11.16 2.22.3 3.35.44 3.8.44 7.71.73 11.73.81.44 0 .88 0 1.3.02.46 0 .9.02 1.36.02 4.16 0 8.27-.25 12.31-.74 2.5-.3 4.98-.69 7.43-1.17a102.41 102.41 0 0 0 36.71-14.92c48.7-31.96 63.94-87.47 107-92.04.95-.11 1.89-.17 2.8-.19 15.63-1.48 25.47 5.55 28.72 16.82Z"
          style={{
            fill: "url(#j)",
          }}
        />
        <path
          d="M71.58 250.88c2.37-.92 4.82-1.95 7.33-3.07.49-.22.97-.43 1.47-.66 2.51-1.15 4.92-2.32 7.21-3.49.07-.04.14-.07.21-.11.15-.08.31-.16.46-.24 12.82-6.69 21.42-13.45 20.14-16.23-1.52-3.32-16.52.28-33.51 8.04-16.98 7.76-29.52 16.75-28.01 20.07.91 2 6.68 1.5 14.81-.9h.01-.01c3.02-.89 6.36-2.04 9.89-3.41Z"
          style={{
            fill: "url(#k)",
          }}
        />
        <path
          d="M408.99 270.86c-22.86 20.01-54.47 34.83-92.57 34.83-44.37 0-97.59-27.21-141.64-55.65a99.85 99.85 0 0 0 9.97 5.44c.05.02.11.05.17.08.78.36 1.57.72 2.36 1.07.13.05.25.11.38.17 2.85 1.24 5.73 2.32 8.63 3.24 20.13 6.5 40.95 5.88 58.79.32 3.3-1.03 6.67-1.98 10.1-2.84.13-.03.25-.07.38-.1 0 0 .03.02.07.03l.25.05 50.53 8.97 51.47-9.86h.05c.03-.02.05-.03.05-.04 11.13 2.58 21.79 5.93 31.69 10.07 3.18 1.33 6.28 2.74 9.3 4.23Z"
          style={{
            fill: "url(#l)",
          }}
        />
        <path
          d="m265.64 257.45 50.78 9.01 51.48-9.85s.03-.01.04-.02c.03-.02.05-.03.05-.04-.76-3.78-2.8-7.1-5.63-9.52-.67-.56-1.38-1.08-2.13-1.54l-.05-.04-20.83-16.51-22.93.97-22.93-.97-20.27 16.69.02.02c-1.05.66-2.01 1.43-2.88 2.31a16.81 16.81 0 0 0-4.57 8.35c-.07.36-.15.73-.2 1.1 0 0 .03.02.07.04Z"
          style={{
            fill: "url(#m)",
          }}
        />
        <path
          d="m316.42 226.02 22.93-.97v-10.26c0-12.51-10.01-22.68-22.47-22.92-.15-.01-.31-.01-.47-.01-12.66 0-22.93 10.26-22.93 22.93v10.26l22.93.97Z"
          style={{
            fill: "url(#n)",
          }}
        />
        <path
          d="M503.86 315.56c-15.52 23.68-38.81 39.41-55.03 32.07 11.56 4.93 35.96-14.82 49.12-37.61 16.86-29.22 9.71-52.61-13.16-51.99 30.72-2.91 39.06 27.03 19.07 57.54Z"
          style={{
            fill: "url(#o)",
          }}
        />
        <path
          d="M448.68 347.56s.11.05.16.07"
          style={{
            fill: "none",
            stroke: "#184461",
            strokeMiterlimit: 10,
          }}
        />
        <path
          d="M408.99 270.86c-22.86 20.01-54.47 34.83-92.57 34.83v-39.23l51.47-9.85h.05c.03-.02.05-.03.05-.04 11.13 2.58 21.79 5.93 31.69 10.07 3.18 1.33 6.28 2.74 9.3 4.23Z"
          style={{
            fill: "url(#b)",
          }}
        />
        <path
          d="M339.35 214.78v10.26l-22.93.97v-34.17h.47c12.45.24 22.46 10.41 22.46 22.92Z"
          style={{
            fill: "url(#p)",
          }}
        />
        <path
          d="M367.99 256.56s-.02.02-.05.04h-.05l-51.47 9.86v-36.54l22.93-.97 20.83 16.51.05.04c.75.46 1.46.97 2.13 1.54 2.83 2.42 4.87 5.74 5.63 9.52Z"
          style={{
            fill: "url(#q)",
          }}
        />
        <path
          d="M408.99 270.86c-22.86 20.01-54.47 34.83-92.57 34.83-44.37 0-97.59-27.21-141.64-55.65a99.85 99.85 0 0 0 9.97 5.44c.05.02.11.05.17.08.78.36 1.57.72 2.36 1.07.13.05.25.11.38.17 2.85 1.24 5.73 2.32 8.63 3.24 40.34 21.34 85.09 38.74 122.59 36.98 32.58-1.54 59.89-13.7 80.81-30.38 3.18 1.33 6.28 2.74 9.3 4.23Z"
          style={{
            fill: "url(#d)",
          }}
        />
        <path
          d="M408.99 270.86c-22.86 20.01-54.47 34.83-92.57 34.83-44.37 0-97.59-27.21-141.64-55.65a99.85 99.85 0 0 0 9.97 5.44c.05.02.11.05.17.08.78.36 1.57.72 2.36 1.07.13.05.25.11.38.17 2.85 1.24 5.73 2.32 8.63 3.24 40.34 21.34 85.09 38.74 122.59 36.98 32.58-1.54 59.89-13.7 80.81-30.38 3.18 1.33 6.28 2.74 9.3 4.23Z"
          style={{
            fill: "url(#r)",
          }}
        />
        <path
          d="M503.86 315.56c-15.52 23.68-38.81 39.41-55.03 32.07 11.56 4.93 35.96-14.82 49.12-37.61 16.86-29.22 9.71-52.61-13.16-51.99 30.72-2.91 39.06 27.03 19.07 57.54Z"
          style={{
            fill: "url(#s)",
          }}
        />
        <path
          d="M117.97 234.66c-5.7 3.07-12.23 6.19-19.33 9.17-.3.13-.61.26-.91.39-.14.06-.27.12-.41.18-4.54 1.88-9.3 3.69-14.22 5.43-.97.34-1.92.66-2.87.98-1.61.54-3.19 1.07-4.76 1.58-.47.04-.93.09-1.41.14-3.99.42-8.09 1.01-12.31 1.76 3-.9 6.32-2.03 9.84-3.4 2.36-.92 4.82-1.96 7.33-3.07.49-.21.97-.43 1.47-.65 2.51-1.15 4.92-2.32 7.21-3.49.07-.04.14-.08.2-.12.16-.08.31-.16.47-.23 1.95-.99 13.22-7.04 29.71-8.66Z"
          style={{
            fill: "url(#t)",
          }}
        />
        <path
          d="M390.87 417.17v13.17c-22.6 4.05-46.95 6.26-72.32 6.26v-13.15c12.69 0 25.12-.55 37.2-1.61s23.82-2.63 35.12-4.66Z"
          style={{
            fill: "url(#u)",
          }}
        />
        <path
          d="M390.87 417.16c-11.3 2.04-23.04 3.61-35.12 4.67s-24.52 1.61-37.2 1.61v-56.35c4.16 0 8.27-.25 12.31-.74 2.5-.3 4.98-.69 7.43-1.17 10.47 23.16 29.27 41.77 52.58 51.97Z"
          style={{
            fill: "url(#v)",
          }}
        />
        <path
          d="M462.47 301.83c-.1.14-.19.26-.29.4l-7.12-12.85c-.79-1.24-2.48-1.5-3.64-.57-5.16 4.12-11.94 14.7-15.83 23.82-3.06 7.19-5.1 15.02-5.41 22.23-.4-8.2 1.78-17.62 5.41-26.12 3.89-9.12 10.67-19.7 15.83-23.82 1.16-.92 2.85-.66 3.64.57l7.5 13.53c.55.87.52 1.98-.09 2.81Z"
          style={{
            fill: "url(#w)",
          }}
        />
        <path
          d="M503.86 319.45c-15.52 23.68-38.81 39.41-55.03 32.07-.05-.02-.11-.05-.16-.07a8.447 8.447 0 0 1-3.06-2.35c-1.81-2.19-2.35-5.08-1.95-8.43.25 1.72.88 3.26 1.95 4.54.88 1.05 1.9 1.83 3.06 2.35.05.02.11.05.16.07.16.07.3.14.46.18 16.2 6.87 39.2-8.79 54.57-32.25 6.82-10.4 10.34-20.74 10.8-29.73.51 9.84-2.98 21.69-10.8 33.62Z"
          style={{
            fill: "url(#x)",
          }}
        />
        <path
          d="M524.6 278.18c-.25-6.41-1.79-12.75-4.91-18.82-12.29-23.9-57.96-30.4-110.7 15.4-22.94 19.92-54.47 34.83-92.57 34.83s-79.59-18.28-111.84-37.18c-24.63-14.44-44.8-30.04-56.99-33.31-14.7-3.99-26.44-4.73-36.97-3.39 10.04-1.88 22.79-2.4 36.97 1.44a82.21 82.21 0 0 1 24.09 10.88c.24.16.48.31.72.48.98.65 1.97 1.29 2.99 1.91-.2-.12-.41-.23-.6-.36 44.05 28.44 97.27 55.65 141.64 55.65 38.1 0 69.71-14.82 92.57-34.83 52.67-44.94 98.42-39.3 110.7-15.4 3.76 7.3 5.2 14.98 4.91 22.72Z"
          style={{
            fill: "url(#y)",
          }}
        />
        <path
          d="M524.6 278.18c-.25-6.41-1.79-12.75-4.91-18.82-12.29-23.9-57.96-30.4-110.7 15.4-22.94 19.92-54.47 34.83-92.57 34.83s-79.59-18.28-111.84-37.18c-24.63-14.44-44.8-30.04-56.99-33.31-14.7-3.99-26.44-4.73-36.97-3.39 10.04-1.88 22.79-2.4 36.97 1.44a82.21 82.21 0 0 1 24.09 10.88c.24.16.48.31.72.48.98.65 1.97 1.29 2.99 1.91-.2-.12-.41-.23-.6-.36 44.05 28.44 97.27 55.65 141.64 55.65 38.1 0 69.71-14.82 92.57-34.83 52.67-44.94 98.42-39.3 110.7-15.4 3.76 7.3 5.2 14.98 4.91 22.72Z"
          style={{
            fill: "url(#z)",
          }}
        />
        <path
          d="M343.77 375.64a101.647 101.647 0 0 1-12.91 2.4c-4.04.49-8.14.74-12.31.74-.46 0-.9-.02-1.35-.02-.44 0-.88 0-1.31-.02-4.03-.08-7.93-.36-11.73-.81-1.13-.13-2.24-.28-3.35-.44-.71-.11-1.43-.19-2.13-.31-1.9-.31-3.77-.66-5.61-1.06a101.4 101.4 0 0 0 5.61-10.62c.7.12 1.42.2 2.13.31 1.11.17 2.22.31 3.35.44 3.8.45 7.71.73 11.73.81.44 0 .88 0 1.31.02.45 0 .89.02 1.35.02 4.16 0 8.27-.25 12.31-.74 2.51-.29 4.98-.68 7.43-1.17a100.9 100.9 0 0 0 5.48 10.46Z"
          style={{
            fill: "url(#A)",
          }}
        />
        <path
          d="M315.73 273.16c-8.48 35.91-92.28.17-119.43-13.13 20.13 6.5 40.95 5.88 58.79.32l.13-.04c6.33-1.98 13.04-2.4 19.57-1.24l35.74 6.35c3.61.64 6.04 4.17 5.2 7.74Z"
          style={{
            fill: "url(#B)",
          }}
        />
        <path
          d="M316.42 229.92v16.09c0 10.67-9.64 18.74-20.14 16.87l-30.71-5.46c.6-3.78 2.29-6.97 4.78-9.45.87-.88 1.83-1.64 2.88-2.31l-.02-.02 20.27-16.69 22.94.97Z"
          style={{
            fill: "url(#C)",
          }}
        />
      </g>
      <g data-name="Layer 10">
        <path
          d="M462.92 166.83c-19.29 0-34.93 15.64-34.93 34.93 0-19.29-15.64-34.93-34.93-34.93 19.29 0 34.93-15.64 34.93-34.93 0 19.29 15.64 34.93 34.93 34.93Z"
          style={{
            fill: "url(#D)",
          }}
        />
        <path
          d="M187.82 386.13c-19.29 0-34.93 15.64-34.93 34.93 0-19.29-15.64-34.93-34.93-34.93 19.29 0 34.93-15.64 34.93-34.93 0 19.29 15.64 34.93 34.93 34.93Z"
          style={{
            fill: "url(#E)",
          }}
        />
      </g>
    </SvgIcon>
  );
}
export default HomeIcon;
