import React from "react";
import { useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";

import NumberField from "src/components/NumberField";
import InfoTooltip from "src/components/InfoTooltip";
import SalePlatformField from "src/components/SalePlatformField";
import { userGetInventoryTypeSelector } from "src/store/system/selector";
import ReportSaleItemSoldInput from "./ReportSaleItemSoldInput";

function ReportSaleBasicQuickContent({
  values,
  setValues,
  loading,
  errors,
  handleChange,
  inventories,
  renderOption,
}) {
  const inventoryTypeIsCash =
    useSelector(userGetInventoryTypeSelector) === "cash";
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} className="flex justify-between">
        <InfoTooltip
          text="Type the name of the item that sold. You can either add a new item or select the item from your current inventory listings. To find an item in your inventory list, you can search by SKU or Item Title."
          size={18}
          id="report-sale-dialog-item-sold-tooltip"
        />
        <ReportSaleItemSoldInput
          className="w-11/12"
          inventories={inventories}
          values={values}
          errors={errors}
          setValues={setValues}
          disabled={loading}
        />
      </Grid>
      <Grid item xs={12} sm={6} className="flex justify-between">
        <InfoTooltip
          text="The total revenue collected from a customer. This includes the price of the item and additional money collected for shipping. This does not include sales tax."
          size={18}
        />
        <NumberField
          error={errors.sale_price ? true : false}
          helperText={errors.sale_price}
          className="w-11/12"
          variant="outlined"
          margin="dense"
          label="Sale Price"
          required
          value={values.sale_price}
          disabled={loading}
          onChange={handleChange("sale_price")}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} className="flex justify-between">
        <InfoTooltip
          text="The amount you paid to ship the sale. This amount will be included in the P&L and Management Dashboard. If your shipping is accounted for in the expense detail, don’t add the cost here as well – this will result in duplicate shipping expenses."
          size={18}
        />
        <NumberField
          error={errors.shipping_cost ? true : false}
          helperText={errors.shipping_cost}
          className="w-11/12"
          variant="outlined"
          margin="dense"
          label="Shipping Cost"
          value={values.shipping_cost}
          disabled={loading}
          onChange={handleChange("shipping_cost")}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} className="flex justify-between">
        <InfoTooltip
          text="The amount the platform charged you to sell the item."
          size={18}
        />
        <NumberField
          error={errors.transaction_fees ? true : false}
          helperText={errors.transaction_fees}
          className="w-11/12"
          variant="outlined"
          margin="dense"
          label="Transaction Fees"
          value={values.transaction_fees}
          disabled={loading}
          onChange={handleChange("transaction_fees")}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} className="flex justify-between">
        <InfoTooltip text="The sales tax paid by the buyer." size={18} />
        <NumberField
          error={errors.sales_tax ? true : false}
          helperText={errors.sales_tax}
          className="w-11/12"
          variant="outlined"
          margin="dense"
          label="Sales Tax"
          value={values.sales_tax}
          disabled={loading}
          onChange={handleChange("sales_tax")}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} className="flex justify-end">
        <TextField
          onInput={(e: any) => {
            let value = e.target?.value;
            let res = value ? value : "";
            setValues({ ...values, sale_state: res });
            e.target.value = res;
          }}
          margin="dense"
          label="Sale State"
          className="w-11/12"
          variant="outlined"
          disabled={loading}
        />
      </Grid>
      <Grid item xs={12} sm={6} className="flex justify-between">
        <InfoTooltip
          text="If you aren’t responsible for filing sales tax, mark this field as ‘No’. If you are responsible for filing sales tax, mark this field as ‘Yes’."
          size={18}
        />
        <Autocomplete
          options={["No", "Yes"]}
          value={values.liable_to_pay ? "Yes" : "No"}
          autoHighlight
          className="w-11/12"
          onChange={(_e, value) => {
            setValues({
              ...values,
              liable_to_pay: value === "Yes" ? true : false,
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              disabled={loading}
              error={errors.liable_to_pay ? true : false}
              helperText={errors.liable_to_pay}
              margin="dense"
              required
              label="Liable to Pay"
              className="w-full"
              variant="outlined"
            />
          )}
          renderOption={renderOption}
        />
      </Grid>
      <Grid item xs={12} sm={6} className="flex justify-between">
        <InfoTooltip
          text={`Record the platform where you sold this item. If the platform doesn't appear in the dropdown, you can add platforms in your profile settings or by clicking "Add New Platform" in the "Sale Platform" dropdown."`}
          size={18}
        />
        <SalePlatformField
          value={values.sale_platform}
          error={!!errors.sale_platform}
          helperText={errors.sale_platform}
          onChange={(value) => {
            setValues((values) => ({
              ...values,
              sale_platform: value || "",
            }));
          }}
          margin="dense"
          disabled={loading}
        />
      </Grid>
      <Grid item xs={12} sm={6} className="flex justify-between">
        <InfoTooltip
          text="The date that you listed this item for sale."
          size={18}
        />
        <KeyboardDatePicker
          disableToolbar
          className="w-11/12"
          format="MM/dd/yyyy"
          margin="dense"
          inputVariant="outlined"
          label="List Date"
          autoOk={true}
          error={!!errors.list_date}
          helperText={errors.list_date}
          value={values.list_date || null}
          disabled={loading}
          onChange={(e: any, t: any) => {
            if (e) {
              setValues({ ...values, list_date: new Date(e) });
            } else {
              const { list_date, ...v } = values;
              setValues(v);
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} className="flex justify-between">
        <InfoTooltip text="The date that you sold this item." size={18} />
        <KeyboardDatePicker
          disableToolbar
          className="w-11/12"
          format="MM/dd/yyyy"
          margin="dense"
          inputVariant="outlined"
          label="Sale Date"
          autoOk={true}
          required
          error={!!errors.sale_date}
          helperText={errors.sale_date}
          value={values.sale_date || null}
          disabled={loading}
          onChange={(e: any, t: any) => {
            setValues({ ...values, sale_date: new Date(e) });
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </Grid>
      {inventoryTypeIsCash ? null : (
        <>
          <Grid item xs={12} sm={6} className="flex justify-between">
            <InfoTooltip
              text="The price at which you purchased the item."
              size={18}
            />
            <NumberField
              error={errors.purchase_price ? true : false}
              helperText={errors.purchase_price}
              className="w-11/12"
              variant="outlined"
              margin="dense"
              label="Purchase Price"
              required
              value={values.purchase_price}
              disabled={loading}
              onChange={(event) => {
                const { value } = event.target;
                let inv = values.inventory;
                setValues({
                  ...values,
                  inventory: {
                    ...inv,
                    purchase_price: value,
                  },
                  purchase_price: value,
                });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="flex justify-between">
            <InfoTooltip
              text="The date that you purchased this item."
              size={18}
            />
            <KeyboardDatePicker
              disableToolbar
              className="w-11/12"
              format="MM/dd/yyyy"
              margin="dense"
              inputVariant="outlined"
              label="Purchase Date"
              autoOk={true}
              required
              error={!!errors.purchase_date}
              helperText={errors.purchase_date}
              value={values.purchase_date || null}
              disabled={loading}
              onChange={(event: any, _) => {
                let inv = values.inventory;
                setValues({
                  ...values,
                  inventory: {
                    ...inv,
                    purchase_date: new Date(event),
                  },
                  purchase_date: new Date(event),
                });
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={12} className="flex justify-between">
        <InfoTooltip text="Add notes to your sale." size={18} />
        <TextField
          margin="dense"
          label="Sale Notes"
          className="w-full"
          variant="outlined"
          disabled={loading}
          value={values.notes || ""}
          onChange={(event) => {
            const { value } = event.target;
            let inv = values.inventory;
            setValues({
              ...values,
              inventory: {
                ...inv,
                notes: value,
              },
              notes: value || "",
            });
          }}
        />
      </Grid>
    </Grid>
  );
}

export default ReportSaleBasicQuickContent;
