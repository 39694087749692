import React from "react";
import Tour from "reactour";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

const Steps = [
  {
    selector: ".report-sale-bundle-page-2-table-header:last-child",
    highlightedSelectors: [
      ".report-sale-bundle-page-2-table-header",
      ".report-sale-bundle-page-2-table-value",
    ],
    content: (props) => (
      <>
        <DialogTitle>Bundle Sales Allocations - 1/2</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sale Price, Transaction Fees, and Shipping Cost will be allocated
            based on the Purchase Price of each item. You can change any of the
            fields as needed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(1)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: ".report-sale-bundle-page-2-purchase-price-header",
    highlightedSelectors: [
      ".report-sale-bundle-page-2-purchase-price-header",
      ".report-sale-bundle-page-2-purchase-price-field",
    ],
    content: (props) => (
      <>
        <DialogTitle>Bundle Sales Allocations - 2/2</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you change the purchase price, the other fields will reallocate
            based on the new purchase price.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(0)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.close()}
          >
            Done
          </Button>
        </DialogActions>
      </>
    ),
  },
];

const useStyles = makeStyles((theme) => ({
  helper: {
    maxWidth: "80%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "500px",
    },
    padding: 0,
  },
}));

export interface WalkThroughProps {
  open: boolean;
  onClose: () => void;
}

export function WalkThrough({ open, onClose }: WalkThroughProps) {
  const classes = useStyles();
  return (
    <Tour
      startAt={0}
      closeWithMask={false}
      disableFocusLock
      steps={Steps}
      isOpen={open}
      onRequestClose={() => {
        onClose();
      }}
      showButtons={false}
      showCloseButton={false}
      showNavigation={false}
      showNavigationNumber={false}
      showNumber={false}
      disableDotsNavigation
      rounded={5}
      className={classes.helper}
      disableInteraction
    />
  );
}

export default WalkThrough;
