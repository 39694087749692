import React, { useRef, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {
  XGrid,
  GridSelectionModel,
  type GridColumns,
} from "@material-ui/x-grid";

import { setActiveDialog } from "src/store/adminHtml/actions";
import {
  isNewSyncInventoryDownloadsLoading,
  getNewSyncInventoryDownloadsCompareData,
} from "src/store/uploads/selector";
import { getNewInventoryDownloads } from "src/store/uploads/actions";
import valueGetter from "src/utils/valueGetter";
import { Inventory } from "src/interfaces/inventory.interface";
import { userIdSelector } from "src/store/system/selector";
import { updateInventoryItems } from "src/apiService/modules/inventories";

import { InventorySyncDialogContent } from "src/pages/RootDialogs/InventorySyncDialog";
import omit from "lodash/omit";
import DialogTitle from "src/components/DialogTitleWithClose";
import WalkThrough from "./WalkThrough";
import InventoryImportWalkThrough from "./InventoryImportWalkThrough";

// fix-vim-highligh = }

const ColumnsTitle: GridColumns = [
  {
    valueGetter,
    field: "current.item_title",
    headerName: "Item Title",
    flex: 1,
  },
  {
    valueGetter,
    field: "current.list_date",
    headerName: "Duplicate List Date",
    description:
      "This is the list date of the item currently in inventory that matches the title of a new eBay listing",
    type: "date",
    width: 150,
  },
  {
    valueGetter,
    field: "new.list_date",
    headerName: "New Item List Date",
    description:
      "This is the list date of the new listing that we received from eBay",
    type: "date",
    width: 150,
  },
  {
    valueGetter,
    field: "current.quantity",
    headerName: "Current Item Count",
    description:
      "This is the total number of items with this item title in your current inventory",
    width: 150,
  },
  {
    valueGetter,
    field: "new.quantity",
    headerName: "New Item Count",
    description:
      "This is the number of items being brought in with the same item title as an item in the inventory",
    width: 150,
  },
];
const ColumnSkuCurrentListDateDescription =
  "This is the list date of the item currently in inventory that matches the SKU of a new eBay listing.";
const ColumnSkuCurrentItemCount =
  "This is the total number of items with this sku in your current inventory.";
const ColumnSkuNewItemCount =
  "This is the number of items being brought in with the same sku as an item in your inventory.";
const ColumnsSku: GridColumns = [
  {
    valueGetter,
    field: "current.item_title",
    headerName: "Current Item Title",
    flex: 1,
  },
  {
    valueGetter,
    field: "new.item_title",
    headerName: "New Item Title",
    flex: 1,
  },
  ...ColumnsTitle.reduce((cs, c) => {
    if (c.field !== "current.item_title") {
      if (c.field === "current.list_date") {
        c = {
          ...c,
          description: ColumnSkuCurrentListDateDescription,
        };
      } else if (c.field === "current.quantity") {
        c = {
          ...c,
          description: ColumnSkuCurrentItemCount,
        };
      } else if (c.field === "new.quantity") {
        c = {
          ...c,
          description: ColumnSkuNewItemCount,
        };
      }
      cs.push(c);
    }
    return cs;
  }, [] as GridColumns),
];

const useStyles = makeStyles((theme) => ({
  content: {
    height: "80vh",
  },
}));

function DuplicateInventoryCheckerContent({
  onClose,
  initialReviewAll = true,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [reviewAll, setReviewAll] = useState(initialReviewAll);
  const { matchesTitle, matchesSku, not } = useSelector((s) =>
    getNewSyncInventoryDownloadsCompareData(s, reviewAll)
  );
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const isLoading = useSelector(isNewSyncInventoryDownloadsLoading);
  const userId = useSelector(userIdSelector);
  const [actions, setActions] = useState<
    Record<string, Inventory | { remove: true }>
  >({});
  const { pendingMatchesTitle, pendingMatchesSku } = useMemo(() => {
    return {
      pendingMatchesTitle: matchesTitle.filter(({ id }) => !actions[id]),
      pendingMatchesSku: matchesSku.filter(({ id }) => !actions[id]),
    };
  }, [matchesTitle, matchesSku, actions]);

  useEffect(() => {
    dispatch(getNewInventoryDownloads());
  }, [dispatch]);

  const pendingMatches = pendingMatchesTitle.length
    ? pendingMatchesTitle
    : pendingMatchesSku;
  const matches = pendingMatchesTitle.length ? matchesTitle : matchesSku;
  const Columns = pendingMatchesTitle.length ? ColumnsTitle : ColumnsSku;

  const syncDialogOpen =
    pendingMatchesTitle.length === 0 &&
    pendingMatchesSku.length === 0 &&
    !isLoading;
  const data = useMemo(() => {
    if (!syncDialogOpen) return [];
    return [
      ...Object.values(actions).filter(
        (item) => !("remove" in item && item.remove)
      ),
      ...not,
    ].map((d) =>
      "purchase_price" in d && d.purchase_price
        ? d
        : omit(d, ["purchase_price"])
    );
  }, [syncDialogOpen, actions, not]);

  const downloadTimestamp = reviewAll
    ? undefined
    : (not[0] as any)?.download_timestamp ||
      (matchesTitle[0] as any)?.new?.download_timestamp ||
      (matchesSku[0] as any)?.new?.download_timestamp;

  const onCloseRef = useRef(onClose);
  onCloseRef.current = onClose;
  useEffect(() => {
    if (isLoading || reviewAll) return;
    if (
      matchesTitle.length === 0 &&
      matchesSku.length === 0 &&
      not.length === 0
    )
      onCloseRef.current({});
  }, [isLoading, reviewAll, matchesTitle, matchesSku, not]);

  if (syncDialogOpen) {
    const switchDisabled = matchesTitle.length > 0 || matchesSku.length > 0;
    return (
      <>
        <InventoryImportWalkThrough />
        <InventorySyncDialogContent
          downloadTimestamp={downloadTimestamp}
          title={
            <DialogTitle
              onClose={onClose}
              extra={
                <FormControlLabel
                  id="inventory-import-review-all-switch"
                  label="Review All"
                  disabled={switchDisabled}
                  control={
                    <Switch
                      color="primary"
                      disabled={switchDisabled}
                      checked={reviewAll}
                      onChange={(e) => {
                        setReviewAll(e.target.checked);
                      }}
                    />
                  }
                />
              }
            >
              Inventory Import
            </DialogTitle>
          }
          data={data}
          onClose={() => {
            onClose({}, !reviewAll ? "partialReview" : undefined);
          }}
        />
      </>
    );
  }

  return (
    <>
      <WalkThrough loading={isLoading} />
      <DialogTitle
        onClose={onClose}
        extra={
          <FormControlLabel
            id="duplicate-inventory-checker-review-all-switch"
            label="Review All"
            control={
              <Switch
                color="primary"
                checked={reviewAll}
                onChange={(e) => {
                  setReviewAll(e.target.checked);
                  setSelectionModel([]);
                }}
              />
            }
          />
        }
      >
        Duplicate Inventory Checker{" "}
        {pendingMatchesTitle.length ? "(Title Matches)" : "(SKU Matches)"}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <XGrid
          className="duplicate-inventory-checker-x-grid"
          selectionModel={selectionModel}
          onSelectionModelChange={(sm) => setSelectionModel(sm)}
          checkboxSelection
          disableSelectionOnClick
          columns={Columns}
          rows={pendingMatches}
          loading={isLoading}
          density="compact"
        />
      </DialogContent>
      <DialogActions>
        <div
          id="duplicate-inventory-checker-actions"
          className="MuiDialogActions-spacing"
        >
          <Button
            color="secondary"
            variant="contained"
            disabled={isLoading || selectionModel.length === 0}
            onClick={() => {
              if (!selectionModel.length) return;
              const selected = selectionModel.reduce((a, v) => {
                a[v] = { remove: true };
                return a;
              }, {});
              setActions((a) => ({ ...a, ...selected }));
              updateInventoryItems(
                selectionModel.reduce((acc, id) => {
                  const matched = matches.find((m) => m.id === id);
                  if (matched) {
                    acc.push({
                      id: matched.current.id,
                      listing_id: matched.new.listing_id,
                    });
                  }
                  return acc;
                }, [] as Pick<Inventory, "id" | "listing_id">[])
              );
              setSelectionModel([]);
            }}
          >
            Remove from Import
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              const selected = selectionModel.reduce((a, v) => {
                const ele = matches.find(({ id }) => id === v);
                if (ele) {
                  a[v] = { user: userId, ...ele.new };
                }
                return a;
              }, {});
              setActions((a) => ({ ...a, ...selected }));
              setSelectionModel([]);
            }}
            disabled={isLoading || selectionModel.length === 0}
          >
            Add to Import
          </Button>
        </div>
      </DialogActions>
    </>
  );
}

export interface DuplicateInventoryCheckerProps {
  open: boolean;
}

function DuplicateInventoryChecker({ open }: DuplicateInventoryCheckerProps) {
  const dispatch = useDispatch();
  const [key, setKey] = useState(0);
  const handleClose = (e?, reason?: string) => {
    if (reason) {
      if (reason === "partialReview") setKey((k) => k + 1);
      return;
    }
    dispatch(setActiveDialog(""));
    setKey(0);
  };

  return (
    <Dialog
      key={key}
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="xl"
      disableEscapeKeyDown
    >
      <DuplicateInventoryCheckerContent
        onClose={handleClose}
        initialReviewAll={key === 0}
      />
    </Dialog>
  );
}

export default DuplicateInventoryChecker;
