import { useState, useEffect } from "react";

function checkIsRunningThreshold(
  now: number,
  isRunning: any,
  threshold: number
): boolean {
  if (typeof isRunning === "string") {
    const t = parseInt(isRunning, 10);
    if (!isNaN(t)) isRunning = t;
  }

  if (typeof isRunning === "number") {
    return now - isRunning < threshold;
  }

  return !!isRunning;
}

function useIsRunning(
  salesIsRunning: any,
  inventoryIsRunning: any,
  isRunningThreshold: number
): boolean {
  const [now, setNow] = useState(Date.now());

  const isRunning =
    checkIsRunningThreshold(now, salesIsRunning, isRunningThreshold) ||
    checkIsRunningThreshold(now, inventoryIsRunning, isRunningThreshold);

  useEffect(() => {
    let cancel = false;
    let timeout;
    if (isRunning) {
      const check = () => {
        if (cancel) return;
        setNow(Date.now());
        timeout = setTimeout(check, 60000);
      };

      check();
    }

    return () => {
      cancel = true;
      if (timeout) clearTimeout(timeout);
    };
  }, [isRunning]);
  return isRunning;
}

export default useIsRunning;
