import ServiceClient from "src/utils/extension/ServiceClient";
import { getMercariCookies } from "src/utils/extension/mercari";

export class NotLoggedError extends Error {
  constructor() {
    super("You must be logged in Mercari to perform the sync process.");
  }
}

const MercariURL = "https://www.mercari.com";

export interface UserItemsQueryParameters {
  status?: "sold_out" | "on_sale" | "trading";
  sortBy?: "created" | "updated" | "name" | "price" | "num_likes";
  sortType?: "asc" | "desc";
  page?: number;
}

export const enum MercariItemStatus {
  ForSale = 1,
  InProgress = 2,
  Complete = 3,
}

export const enum MercariSortBy {
  NewestListed = 2,
  LowestPrice = 3,
  HighestPrice = 4,
}

function buildURL(
  base: string,
  path: string,
  searchParams: Record<string, string> = {}
): string {
  const url = new URL(path, base);

  for (const [key, value] of Object.entries(searchParams)) {
    url.searchParams.set(key, value);
  }

  return url.toString();
}

class MercariClient extends ServiceClient {
  async getCredentials(): Promise<{ accessToken: string; userId: string }> {
    const cookies = await getMercariCookies(this.extensionId);
    const mwus = cookies.find((c) => c.name === "_mwus")?.value;
    if (!mwus) throw new NotLoggedError();
    let payload: { accessToken: string; userId: string };
    try {
      payload = JSON.parse(atob(mwus)); // accessToken, refreshToken, csrfSecret, userId
    } catch (e) {
      throw new Error("Invalid poshmark JWT", e as Error);
    }

    if (!payload.userId) throw new NotLoggedError();
    return payload;
  }

  async orderStatusConsolidated(referenceId: string) {
    const credentials = await this.getCredentials();
    const url = buildURL(MercariURL, "/v1/api", {
      operationName: "OrderStatusConsolidated",
      variables: JSON.stringify({
        input: {
          referenceId,
          referenceType: "item",
        },
      }),
      extensions: JSON.stringify({
        persistedQuery: {
          version: 1,
          sha256Hash:
            "2167e7da2113deda29af203c30f47b050d93dfd15540e58e059341e5e699b109",
        },
      }),
    });
    const res = await this.fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${credentials.accessToken}`,
        "X-Platform": "web",
      },
    });

    if (!res.ok) {
      console.error(res);
      console.error("(mercari) orderStatusConsolidated failed (status code)", {
        url,
        body: res.data,
        status: res.status,
      });

      throw new Error("(mercari) orderStatusConsolidated Failed (status code)");
    }

    const json = res.data;
    if (json.errors) {
      console.error(
        "(mercari) orderStatusConsolidated failed (errors property)",
        {
          url,
          json,
        }
      );

      throw new Error(
        "(mercari) orderStatusConsolidated Failed (errors property)"
      );
    }

    return json.data.orderStatus.contents;
  }

  async userItemsQuery({
    status = "sold_out",
    sortBy = "created",
    sortType = "desc",
    page = 1,
  }: UserItemsQueryParameters = {}) {
    const credentials = await this.getCredentials();
    const url = buildURL(MercariURL, "/v1/api", {
      operationName: "userItemsQuery",
      variables: JSON.stringify({
        userItemsInput: {
          sellerId: credentials.userId,
          status,
          sortBy,
          sortType,
          page,
        },
      }),
      extensions: JSON.stringify({
        persistedQuery: {
          version: 1,
          sha256Hash:
            "09ae8d0bf96add97f28f61824f8c0c9021e6c10f5a65921e4baf443b9d50f2a9",
        },
      }),
    });
    const res = await this.fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${credentials.accessToken}`,
      },
    });

    if (!res.ok) {
      console.error(res);
      console.error("(mercari) userItemsQuery failed (status code)", {
        url,
        body: res.data,
        status: res.status,
      });

      throw new Error("(mercari) userItemsQuery Failed (status code)");
    }

    const json = res.data;

    if (json.errors) {
      console.error("(mercari) userItemsQuery failed (errors property)", {
        url,
        json,
      });

      throw new Error("(mercari) userItemsQuery Failed (errors property)");
    }

    if (!json.data.userItems) {
      console.error(
        "(mercari) userItemsQuery failed (no data.userItems property)",
        {
          url,
          json,
        }
      );

      throw new Error(
        "(mercari) userItemsQuery Failed (no data.userItems property)"
      );
    }

    return json.data.userItems;
  }

  async *userItemsQueryFull(args?: Omit<UserItemsQueryParameters, "page">) {
    for (let page = 1; ; page++) {
      const res = await this.userItemsQuery({ ...args, page });

      yield {
        sales: res.items,
        pagination: res.pagination,
      };

      if (!res.pagination.hasNext) break;
    }
  }

  async productQuery(id: string) {
    const credentials = await this.getCredentials();
    const url = buildURL(MercariURL, "/v1/api", {
      operationName: "productQuery",
      variables: JSON.stringify({
        id,
        includeSimilarItems: false,
      }),
      extensions: JSON.stringify({
        persistedQuery: {
          version: 1,
          sha256Hash:
            "67250a2b94677c783bed79b39c56d198761298622b121a1a3f86fac57ee1e794",
        },
      }),
    });
    const res = await this.fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${credentials.accessToken}`,
      },
    });

    if (!res.ok) {
      console.error(res);
      console.error("(mercari) productQuery failed (status code)", {
        url,
        body: res.data,
        status: res.status,
      });

      throw new Error("(mercari) productQuery Failed (status code)");
    }

    const json = res.data;
    if (json.errors) {
      console.error("(mercari) productQuery failed (errors property)", {
        url,
        json,
      });

      throw new Error("(mercari) productQuery Failed (errors property)");
    }

    return json.data.item;
  }

  async publicProfileSearchQuery({
    sellerId,
    length = 100,
    offset = 0,
    itemStatuses = [MercariItemStatus.ForSale],
    query = "",
    sortBy = MercariSortBy.NewestListed,
  }: {
    sellerId: number;
    length?: number;
    offset?: number;
    itemStatuses?:
      | [MercariItemStatus.ForSale]
      | [MercariItemStatus.InProgress, MercariItemStatus.Complete] // Sold
      | [
          MercariItemStatus.ForSale,
          MercariItemStatus.InProgress,
          MercariItemStatus.Complete
        ];
    query?: string;
    sortBy?: MercariSortBy;
  }) {
    const credentials = await this.getCredentials();
    const url = buildURL(MercariURL, "/v1/api", {
      operationName: "publicProfileSearchQuery",
      variables: JSON.stringify({
        criteria: {
          sellerIds: [sellerId],
          offset,
          itemStatuses,
          query,
          sortBy,
          facets: [1, 2, 3, 9, 18],
          hashtags: [],
        },
        userId: sellerId,
      }),
      extensions: JSON.stringify({
        persistedQuery: {
          version: 1,
          sha256Hash:
            "56bd97c8869ce51d9ddb7796a024e0f4f1573aa29586bb4d2cc62ffa2eeafe58",
        },
      }),
    });
    const res = await this.fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${credentials.accessToken}`,
      },
    });

    if (!res.ok) {
      console.error(res);
      console.error("(mercari) publicProfileSearchQuery failed (status code)", {
        url,
        body: res.data,
        status: res.status,
      });

      throw new Error(
        "(mercari) publicProfileSearchQuery Failed (status code)"
      );
    }

    const json = res.data;

    if (json.errors) {
      console.error(
        "(mercari) publicProfileSearchQuery failed (errors property)",
        {
          url,
          json,
        }
      );

      throw new Error(
        "(mercari) publicProfileSearchQuery Failed (errors property)"
      );
    }

    return json.data.search; // { itemList, page: { count } }
  }

  async *publicProfileSearchQueryFull() {
    let offset = 0;
    let count = 0;
    const sellerId = parseInt((await this.getCredentials()).userId, 10) || -1;

    do {
      const res = await this.publicProfileSearchQuery({ sellerId, offset });
      count = res.count;

      yield {
        items: res.itemsList,
        pagination: {
          count,
          offset,
        },
      };

      offset += res.itemsList.length;
    } while (offset < count);
  }
}

export default MercariClient;
