import { type AnySchema } from "yup";

// fix-vim-highlight = }

export function transformNumber(
  value: number,
  originalValue: string | undefined | null,
  context: AnySchema
): number {
  if (!context.isType(value)) {
    if (originalValue === "") return context.getDefault();

    if (originalValue) {
      const clean = originalValue.replace(/[,$ ]/g, "");
      const match = clean.match(/^\(([0-9.]+)\)$/);
      if (match) return -1 * +match[1];
      return +clean;
    }
  }
  return value;
}

export function transformTimeToDefault(
  value: Date,
  original: string | undefined | null,
  context: AnySchema
) {
  if (value && context.isType(value)) {
    const v = new Date(value);
    v.setUTCHours(12);
    v.setUTCMinutes(0);
    v.setUTCSeconds(0);
    v.setUTCMilliseconds(0);
    return v;
  }

  return value;
}

export function transformNullToDefault<T>(
  value: T | null,
  originalValue: string,
  context: AnySchema
): T {
  if (value === null) return context.getDefault();
  return value;
}
