import { all, call, fork, put, take } from "redux-saga/effects";
import { fetchItemOptionsApi } from "../../apiService";
import { parseError } from "../../utils";

import {
  ITEM_OPTIONS,
  FAIL,
  START,
  SUCCESS,
  FETCH_ITEMS,
} from "../common";

function* fetchItems() {
  while (true) {
    yield take(ITEM_OPTIONS + FETCH_ITEMS + START);
    try {
      const data = yield call(fetchItemOptionsApi);
      yield put({ type: ITEM_OPTIONS + FETCH_ITEMS + SUCCESS, payload: {
        data,
      }});
    } catch (error) {
      yield put({
        type: ITEM_OPTIONS + FETCH_ITEMS + FAIL,
        payload: parseError(error),
      });
    }
  }
}
 
export function* itemOptionsSagas() {
  yield all([
    fork(fetchItems),
  ]);
}
