import firebase from "firebase/app";
import "firebase/functions";

import { CashActivity } from "../../interfaces";

import {requestNOUpdate} from "./numericOverview";
import firestore from "src/apiService/firestore";
import FirebaseBatcher from "src/utils/FirebaseBatcher";

const db = firestore();

export async function upsertCashActivity({ bank_statement, ...payload }: Partial<CashActivity>) {
  const f = firebase
    .functions()
    .httpsCallable("cashActivitiesUpsert");

  const result = await f({ data: payload });
  await requestNOUpdate();
  return result.data;
}

export async function deleteCashActivity(ids: string[]) {
  const f = firebase
    .functions()
    .httpsCallable("cashActivitiesDelete");

  const result = await f({ ids });
  await requestNOUpdate();
  return result.data;
}

export async function setCashActivitysReviewed(transactionIds: any) {
  const batch = new FirebaseBatcher();
  for (const id of transactionIds) {
    const doc = db.collection("Cash_Activities").doc(id);
    await batch.update(doc, { reviewed: true });
  }
  await batch.commit();
  await requestNOUpdate();
  return true;
}

export const updateCashActivityDescription = async (transactionId: any, description: string) => {
  const ref = db.collection("Cash_Activities").doc(transactionId);
  return await ref.set({ description: description}, { merge: true });
}

export const updateCashActivityType = async (transactionId: any, activity_type: string) => {
  const ref = db.collection("Cash_Activities").doc(transactionId);
  ref.get().then((result) => {
    let transaction = result.data();
    if(transaction){
      if(transaction.activity_type === "Business Activity"){
        if(activity_type === "Other Activity"){
          transaction.activity_type = activity_type;
          transaction.other_activity = transaction.amount;
          transaction.business_activity = 0;
        }
      } else{
        if(activity_type === "Business Activity"){
          transaction.activity_type = activity_type;
          transaction.business_activity = transaction.amount;
          transaction.other_activity = 0;
        }
      }
      return ref.set(transaction, { merge: true});
    }
  })
}
