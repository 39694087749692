import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";

import DialogTitle from "./DialogTitle";
import type Step from "./Step";
import { updateUserApi } from "src/apiService/modules/system";

interface InventoryHowDoYouAccountProps {
  onClose: () => void;
  onStepChange: (step: Step) => void;
}

type Options = "accrual" | "cash" | "n/a";

const useStyles = makeStyles((theme) => ({
  content: {
    margin: "auto",
  },
  radioGroup: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(4),
  },
}));

function InventoryHowDoYouAccount({
  onClose,
  onStepChange,
}: InventoryHowDoYouAccountProps) {
  const classes = useStyles();
  const [value, setValue] = useState<Options>();

  return (
    <>
      <DialogTitle onClose={onClose}>Set Up Questions - 1/8</DialogTitle>
      <MuiDialogContent className={classes.content}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            How do you account for your inventory?
          </FormLabel>
          <RadioGroup
            name="inventory-method"
            value={value || null}
            onChange={(event) => {
              setValue((event.target as HTMLInputElement).value as Options);
            }}
            className={classes.radioGroup}
          >
            <FormControlLabel
              value="accrual"
              control={<Radio />}
              label="Accrual inventory method"
            />
            <FormControlLabel
              value="cash"
              control={<Radio />}
              label="Cash inventory method"
            />
            <FormControlLabel
              value="n/a"
              control={<Radio />}
              label="I don't know, what's the difference?"
            />
          </RadioGroup>
        </FormControl>
      </MuiDialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={!value}
          onClick={() => {
            if (value === "accrual" || value === "cash") {
              updateUserApi({ inventoryType: value });
            }

            onStepChange(
              value === "accrual"
                ? "inventory:important-tip"
                : value === "cash"
                ? "ebay:do-you-sell-on-ebay"
                : "inventory:cash-vs-accrual-inventory"
            );
          }}
          color="primary"
          autoFocus
        >
          Continue
        </Button>
      </DialogActions>
    </>
  );
}

export default InventoryHowDoYouAccount;
