import React, { useRef, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";

import { ImportDialogContent } from "./ImportDialog";
import { getEBayIntegrations } from "src/store/plaidIntegration/selector";
import { userGetInventoryTypeSelector } from "src/store/system/selector";
import { getUploads } from "src/store/uploads/selector";

import DialogTitle from "./DialogTitle";
import type Step from "./Step";

interface EBayImportProps {
  onClose: () => void;
  onStepChange: (step: Step) => void;
}

function getHasAlreadyImported(
  integration,
  uploads,
  key: "ebayInventory" | "ebaySales"
) {
  const isRunningKey =
    key === "ebayInventory" ? "inventoryIsRunning" : "salesIsRunning";
  if (integration?.[isRunningKey]) return true;
  const t = key === "ebayInventory" ? "inventory" : "sales";
  return uploads.some((upload) => {
    if (integration?.id && upload.integrationId) {
      if (integration.id !== upload.integrationId) return false;
    }

    return t === upload.type && upload.filename?.includes(key);
  });
}

export function useIntegration() {
  const ebayIntegration = useSelector((s) => {
    const integrations = getEBayIntegrations(s);
    if (integrations) {
      let integration = integrations[0];
      for (const i of integrations) {
        if ((i.createdAt || 0) > (integration.createdAt || 0)) integration = i;
      }
      return integration;
    }
  });
  const integrationId = ebayIntegration?.id;
  const uploads = useSelector(getUploads);
  const loading = ebayIntegration?.loading;
  const hasAlreadyImportedInventory =
    getHasAlreadyImported(ebayIntegration, uploads, "ebayInventory") ||
    loading === "Inventory";
  const hasAlreadyImportedSales =
    getHasAlreadyImported(ebayIntegration, uploads, "ebaySales") ||
    loading === "Sales";

  return {
    integrationId,
    hasAlreadyImportedInventory,
    hasAlreadyImportedSales,
  };
}

function EBayImport({ onClose, onStepChange }: EBayImportProps) {
  const {
    integrationId,
    hasAlreadyImportedInventory,
    hasAlreadyImportedSales,
  } = useIntegration();
  const inventoryType = useSelector(userGetInventoryTypeSelector);
  const onCloseRef = useRef(onClose);
  onCloseRef.current = onClose;

  const [memory] = useState(() => ({
    hasAlreadyImportedInventory,
    hasAlreadyImportedSales,
  }));

  const dialogTitle = useMemo(() => {
    return (props) => (
      <DialogTitle onClose={() => onCloseRef.current()} {...props} />
    );
  }, []);
  return (
    <>
      <ImportDialogContent
        DialogTitle={dialogTitle}
        onClose={() => {
          onStepChange("ebay:import-in-process");
        }}
        providerName="eBay"
        integrationId={integrationId || ""}
        actions={
          <Button
            color="primary"
            onClick={() => onStepChange("ebay:additional-stores")}
          >
            Continue Set-up
          </Button>
        }
        disableInventory={
          inventoryType === "cash" || memory.hasAlreadyImportedInventory
        }
        disableSales={memory.hasAlreadyImportedSales}
      />
    </>
  );
}

export default EBayImport;
