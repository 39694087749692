import { createAction } from "redux-actions";
import {
  RESET,
  GL_ACCOUNTS, FS_CREATE_ITEMS, FS_UPDATE_ITEMS, FS_DELETE_ITEMS,
} from "../common";

export const reset = createAction(GL_ACCOUNTS + RESET);

export const addGeneralLedgerAccounts = createAction(GL_ACCOUNTS + FS_CREATE_ITEMS);
export const updateGeneralLedgerAccounts = createAction(GL_ACCOUNTS + FS_UPDATE_ITEMS);
export const deleteGeneralLedgerAccounts = createAction(GL_ACCOUNTS + FS_DELETE_ITEMS);
