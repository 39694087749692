import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import { ConnectedRouter } from "connected-react-router";
import { ToastContainer } from "react-toastify";

import LogInPage from "../pages/LogInPage";
import PasswordResetPage from "../pages/PasswordResetPage";
import SignupPage from "../pages/SignupPage";
import AuthCallbackPage from "../pages/AuthCallbackPage"; // TODO: deprecate
import EBayOAuthCallbackPage from "src/pages/EBayOAuthCallbackPage";
import AmazonOAuthCallbackPage from "src/pages/AmazonOAuthCallbackPage";
import PrivateRouter from "./PrivateRouter";
import SupportRouter from "./SupportRouter";

import { history } from "../store";
import {
  authorizingSelector,
  isLoggedInSelector,
  verifyingLoginSelector,
} from "../store/system/selector";
import { verifyLogin } from "../store/system/actions";
import { AppLoader } from "../components";
import PlaidAuthCallbackPage from "../pages/PlaidAuthCallbackPage";
import { SafeZendesk as Zendesk } from "src/components/Zendesk";
import EtsyAuthCallbackPage from "../pages/EtsyAuthCallbackPage/EtsyAuthCallbackPage";

const Router = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const authorizing = useSelector(authorizingSelector);
  const verifying = useSelector(verifyingLoginSelector);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(verifyLogin());
    }
  }, [isLoggedIn, dispatch]);

  if (authorizing) {
    return (
      <AppLoader
        text={verifying ? "" : "We're setting up your new profile..."}
      />
    );
  }

  return (
    <>
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path="/login" component={LogInPage} />
          <Route exact path="/signup" component={SignupPage} />
          <Route exact path="/forgotpassword" component={PasswordResetPage} />
          <Route
            exact
            path="/auth/ebay/callback"
            component={AuthCallbackPage}
          />
          <Route
            exact
            path="/auth/etsy/callback"
            component={EtsyAuthCallbackPage}
          />
          <Route
            exact
            path="/oauth/ebay/callback"
            component={EBayOAuthCallbackPage}
          />
          <Route
            exact
            path="/auth/plaid/callback"
            component={PlaidAuthCallbackPage}
          />
                <Route
            exact
            path="/oauth/amazon/callback"
            component={AmazonOAuthCallbackPage}
          />
          <Route path="/support" component={SupportRouter} />
          {!authorizing && isLoggedIn && <PrivateRouter />}
          {!authorizing && !isLoggedIn && <Redirect to="/login" />}
        </Switch>
        <Zendesk isLoggedIn={isLoggedIn} />
      </ConnectedRouter>
      <ToastContainer />
    </>
  );
};

export default Router;
