import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import omit from "lodash/omit";

import { Dialog, DialogActions, DialogContent } from "@material-ui/core";

import { requestNOUpdate } from "src/apiService";

import {
  getMatchableInventory,
  getUnreviewedSales,
} from "src/store/sale/selector";
import { setActiveDialog } from "src/store/adminHtml/actions";

import AddMatchingInventory from "./AddMatchingInventory";
import CompleteUpload from "./CompleteUpload";
import ManualSalesMatching from "./ManualSalesMatching";
import SalesMatchingTable from "./SalesMatchingTable";
import { MatchedSale, RenderFunctionProps } from "./interface";
import { daysOnPlatform } from "src/utils";
import { userIncomeTaxRateSelector } from "src/store/system/selector";
import { Sale } from "src/interfaces/sale.interface";
import FirebaseBatcher from "src/utils/FirebaseBatcher";
import firestore from "src/apiService/firestore";
import DialogTitleWithClose from "src/components/DialogTitleWithClose";
import ConfirmDialog from "src/components/ConfirmDialog";

const db = firestore();

function SalesMatchingDialogContent({
  onClose,
  unreviewedSales: unreviewedSalesProp,
}) {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const incomeTaxRate = useSelector(userIncomeTaxRateSelector);

  const allUnreviewedSales = useSelector(getUnreviewedSales);
  const unreviewedSales = unreviewedSalesProp || allUnreviewedSales;
  const [actions, setActions] = useState<
    Record<string, Sale | { delete: true }>
  >({});
  const unmatchedSales = useMemo(() => {
    return unreviewedSales.filter(({ id }) => !actions[id]);
  }, [actions, unreviewedSales]);
  const selectableInventory = useSelector(getMatchableInventory);

  const unamtchedSelectableInventory = useMemo(() => {
    const matchedInventory = Object.values(actions).reduce((acc, ele) => {
      if (ele && "inventoryId" in ele && ele.inventoryId)
        acc[ele.inventoryId] = (acc[ele.inventoryId] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);
    return selectableInventory.filter(
      (i) => (i.quantity || 1) > (matchedInventory[i.id] || 0)
    );
  }, [selectableInventory, actions]);

  const [step, setStep] = useState(0);
  const [selectedIndices, setSelectedIndices] = useState<Set<number>>(
    new Set([])
  );
  const currentSale = unmatchedSales.length ? unmatchedSales[0] : null;

  const toggleSelectedIndex = (index) =>
    setSelectedIndices((existing) => {
      const newSet = new Set(existing);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });

  const {
    inventoryMatches,
    automaticMatches,
    inventoryMatchesByItemTitle,
    inventoryMatchesByItemSKU,
  } = useMemo(() => {
    const inventoryMatches: MatchedSale[] = [];
    const inventoryMatchesByItemTitle: MatchedSale[] = [];
    const inventoryMatchesByItemSKU: MatchedSale[] = [];
    const availableInventories = new Set(unamtchedSelectableInventory);
    const inventoryQuantity: Record<string, number> = {};

    for (const sale of unmatchedSales) {
      const inventoryArray = Array.from(availableInventories);
      let matchedInventory = inventoryArray.find(
        (inventory) => inventory.item_title === sale.item_title
      );
      let inventoryMatchesByType = inventoryMatchesByItemTitle;

      if (!matchedInventory && !!sale.sku) {
        matchedInventory = inventoryArray.find(
          (inventory) => !!sale.sku && `${inventory.sku}` === `${sale.sku}`
        );
        inventoryMatchesByType = inventoryMatchesByItemSKU;
      }

      if (matchedInventory) {
        const currentQuantity =
          matchedInventory.id in inventoryQuantity
            ? inventoryQuantity[matchedInventory.id]
            : matchedInventory.quantity || 1;

        if (currentQuantity <= 1) availableInventories.delete(matchedInventory);
        inventoryQuantity[matchedInventory.id] = currentQuantity - 1;

        const matchedSale = { sale, inventory: matchedInventory };
        inventoryMatches.push(matchedSale);
        inventoryMatchesByType.push(matchedSale);
      }
    }

    const automaticMatches = inventoryMatches
      .map((item, index) => !!item)
      .filter((item) => !!item);

    return {
      inventoryMatches,
      automaticMatches,
      inventoryMatchesByItemTitle,
      inventoryMatchesByItemSKU,
    };
  }, [unmatchedSales, unamtchedSelectableInventory]);

  const noAutomaticMatchCount = unmatchedSales.length - automaticMatches.length;

  const toggleSelectAll = (clear: boolean, matches: MatchedSale[]) =>
    setSelectedIndices((existing) => {
      if (!clear && !existing.size) {
        return new Set(
          matches
            .map((item, index) => (!!item ? index : -1))
            .filter((item) => item !== -1)
        );
      }
      return new Set([]);
    });

  const gotoTable = () => setStep(0);
  const gotoManualMatch = () => setStep(1);
  const gotoAddInventory = () => setStep(2);

  const returnToTable = () => {
    setSelectedIndices(new Set([]));
    setStep(0);
  };

  const saveManualMatch = async (inventory, selectedSale = currentSale) => {
    if (!selectedSale) return returnToTable();
    // TODO: really dont know the real typing this was copied from the old SalesMatching/CompleteUpload
    const sale: any = {
      ...selectedSale,
      ...omit(inventory, ["return_id", "id", "item_title"]), // return_id is from inventory and should be omitted
      unmatched: false,
      unreviewed: false,
      inventoryId: inventory.id,
      id: selectedSale.id,
    };

    for (const k of [
      "sale_price",
      "purchase_price",
      "transaction_fees",
      "other_fees",
      "shipping_cost",
      "shipping_cost_analytics",
      "other_business_costs",
    ]) {
      if (!sale[k]) sale[k] = 0;
    }

    const grossProfit =
      sale.sale_price -
      sale.purchase_price -
      sale.transaction_fees -
      sale.other_fees -
      sale.shipping_cost -
      sale.shipping_cost_analytics;
    const estimatedTax = grossProfit * +(incomeTaxRate || 0);

    sale.days_on_platform = daysOnPlatform(
      sale.sale_date?.valueOf() || 0,
      sale.list_date?.valueOf() || 0
    );
    sale.estimated_income_tax = estimatedTax;
    sale.gross_profit = grossProfit;
    sale.net_profit = grossProfit - estimatedTax - sale.other_business_costs;

    for (const key of Object.keys(sale)) {
      if (sale[key] === undefined) {
        console.warn(`key '${key}' of sale is undefined, will delete it`, sale);
        delete sale[key];
      }
    }

    setActions((a) => ({
      ...a,
      [sale.id]: sale,
    }));
  };

  const render = ({
    main,
    actions,
    extraActions,
    title,
    disableTypography,
  }: RenderFunctionProps) => {
    return (
      <>
        <ConfirmDialog
          open={confirmOpen}
          title="Matches Will Be Lost"
          text="Are you sure you want to close this screen? Your matches will not be completed."
          confirm="Yes"
          cancel="Back"
          onConfirm={() => onClose()}
          onCancel={() => setConfirmOpen(false)}
        />
        <DialogTitleWithClose
          onClose={() => setConfirmOpen(true)}
          disableTypography={disableTypography}
        >
          {title}
        </DialogTitleWithClose>
        <DialogContent>{main}</DialogContent>
        <DialogActions className="px-7 py-5">{actions}</DialogActions>
        {!!extraActions && (
          <DialogActions className="px-7 pt-0 pb-5">
            {extraActions}
          </DialogActions>
        )}
      </>
    );
  };

  if (!unmatchedSales.length) {
    return (
      <CompleteUpload
        amount={
          Object.values(actions).filter((a) => !("delete" in a && a.delete))
            .length
        }
        onCancel={onClose}
        onSubmit={async () => {
          const batch = new FirebaseBatcher();
          for (const [id, action] of Object.entries(actions)) {
            const doc = db.collection("Sales").doc(id);

            if ("delete" in action && action.delete) {
              await batch.delete(doc);
            } else {
              const inventoryId = (action as any).inventoryId;
              if (inventoryId) {
                const act = { ...action };
                delete (act as any).inventoryId;

                await db.runTransaction(async (t) => {
                  const inventoryDoc = db
                    .collection("Inventory")
                    .doc(inventoryId);
                  const inventory = await t.get(inventoryDoc);
                  if (inventory.exists) {
                    const inventoryData = inventory.data();
                    const quantity = inventoryData?.quantity || 1;
                    if (quantity > 1) {
                      await t.update(inventoryDoc, {
                        quantity: quantity - 1,
                        purchase_price:
                          (inventoryData?.purchase_price || 0) -
                          ((act as any)?.purchase_price || 0),
                      });
                    } else {
                      await t.delete(inventoryDoc);
                    }
                  }
                  await t.update(doc, act);
                });
              } else {
                await batch.update(doc, action);
              }
            }
          }
          await batch.commit();
          await requestNOUpdate();
          onClose();
        }}
        render={render}
      />
    );
  }

  switch (step) {
    case 2:
      return (
        <AddMatchingInventory
          saleValues={currentSale}
          render={render}
          goBack={gotoManualMatch}
          onSave={saveManualMatch}
        />
      );
    case 1:
      return (
        <ManualSalesMatching
          render={render}
          onSave={saveManualMatch}
          gotoAddInventory={gotoAddInventory}
          gotoTable={gotoTable}
          noAutomaticMatchCount={noAutomaticMatchCount}
          selectableInventory={unamtchedSelectableInventory}
          inventoryMatches={inventoryMatches}
          unmatchedSales={unmatchedSales}
          onDelete={(id) =>
            setActions((a) => ({ ...a, [id]: { delete: true } }))
          }
          actions={actions}
          unreviewedSales={unreviewedSales}
        />
      );
    case 0:
    default:
      return (
        <SalesMatchingTable
          onSave={saveManualMatch}
          render={render}
          gotoManualMatch={gotoManualMatch}
          noAutomaticMatchCount={noAutomaticMatchCount}
          selectedIndices={selectedIndices}
          toggleSelectAll={toggleSelectAll}
          toggleSelectedIndex={toggleSelectedIndex}
          inventoryMatchesByItemTitle={inventoryMatchesByItemTitle}
          inventoryMatchesByItemSKU={inventoryMatchesByItemSKU}
        />
      );
  }
}

function SalesMatchingDialog({ open, unreviewedSales }: any) {
  const dispatch = useDispatch();
  const handleClose = (e, r) => {
    if (r) return;
    dispatch(setActiveDialog(""));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"body"}
      fullWidth={true}
      maxWidth="lg"
      disableEscapeKeyDown
    >
      <SalesMatchingDialogContent
        onClose={handleClose}
        unreviewedSales={unreviewedSales}
      />
    </Dialog>
  );
}

export default SalesMatchingDialog;
