import React, { useState } from "react";

import { Button, Typography } from "@material-ui/core";

import { StatefulLoadableButton } from "src/components/LoadableButton";

function CompleteUpload({ render, onCancel, onSubmit, amount }: any) {
  const [confirmCancel, setConfirmCancel] = useState(false);

  return render({
    title: "Confirm Matches",
    main: (
      <Typography variant="body1" paragraph>
        {confirmCancel
          ? "Are you sure you want to cancel the upload? All of your inventory matching work will be lost."
          : `Your ${amount} sales are ready to be matched to inventory. Click the “Submit” button to complete the matches.`}
      </Typography>
    ),
    actions: confirmCancel ? (
      <>
        <Button variant="contained" onClick={() => setConfirmCancel(false)}>
          No, Do Not Cancel
        </Button>
        <Button color="secondary" variant="contained" onClick={onCancel}>
          Cancel Match
        </Button>
      </>
    ) : (
      <>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setConfirmCancel(true)}
        >
          Cancel Upload
        </Button>
        <StatefulLoadableButton
          color="primary"
          variant="contained"
          onClick={onSubmit}
        >
          Submit
        </StatefulLoadableButton>
      </>
    ),
  });
}

export default CompleteUpload;
