import React, { useState, useEffect } from "react";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { StatefulLoadableButton } from "src/components/LoadableButton";

import { getMercariUser } from "src/utils/extension/mercari";
import { mercariIntegrationCreate } from "src/apiService/modules/mercari";

import DialogTitle from "./DialogTitle";
import type Step from "./Step";

interface MercariConfirmProps {
  onClose: () => void;
  onStepChange: (step: Step) => void;
}

function MercariConfirm({ onClose, onStepChange }: MercariConfirmProps) {
  const [mercariUserData, setMercariUserData] = useState<
    | {
        username: string;
        cookies: Record<string, string>;
      }
    | { error: string }
    | { loading: true }
  >({ loading: true });

  useEffect(() => {
    let cancel = false;
    (async () => {
      try {
        const data = await getMercariUser();
        if (cancel) return;
        setMercariUserData(data);
      } catch (e) {
        if (cancel) return;
        setMercariUserData({ error: (e as Error).message });
      }
    })();
    return () => {
      cancel = true;
    };
  }, []);

  return (
    <>
      <DialogTitle onClose={onClose}>Add Mercari integration</DialogTitle>
      <MuiDialogContent>
        <DialogContentText>
          {"username" in mercariUserData
            ? `The Mercari account "${mercariUserData.username}" will be added.`
            : "error" in mercariUserData
            ? `ERROR: ${mercariUserData.error}`
            : "Loading..."}
        </DialogContentText>
      </MuiDialogContent>
      <DialogActions>
        <Button onClick={() => onStepChange("mercari:do-you-sell-on-mercari")}>
          Cancel
        </Button>
        <StatefulLoadableButton
          variant="contained"
          disabled={
            !!(mercariUserData as any).error || (mercariUserData as any).loading
          }
          onClick={async () => {
            if ("cookies" in mercariUserData && mercariUserData.cookies) {
              await mercariIntegrationCreate({
                cookies: mercariUserData.cookies,
                username: mercariUserData?.username,
                lastSync: new Date().toISOString(),
              });
              onStepChange("mercari:import");
            }
          }}
          color="primary"
          autoFocus
        >
          Add
        </StatefulLoadableButton>
      </DialogActions>
    </>
  );
}

export default MercariConfirm;
