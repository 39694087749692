import { createSelector } from "reselect";
import { AppState } from "..";
import { InventoryState } from "../../interfaces/inventoryState.interface";
import { getVendors } from "src/store/vendor/selector";

const getInventoryState = (state: AppState) => state.inventory;

export const inventoriesSelector = createSelector(
  [getInventoryState, getVendors],
  (inventory: InventoryState, vendors) => {
    return {
      ...inventory,
      items: inventory.items.map((item) => {
        if (item.vendor)
          return {
            ...item,
            vendorName: vendors.find((v) => v.id === item.vendor)?.name || "",
          };

        return item;
      }),
    };
  }
);
export const getInventory = createSelector(
  getInventoryState,
  (state) => state.items
);

export const getEarliestInventoryYear = createSelector(
  getInventory,
  (inventory) => {
    const minDate = inventory.reduce((result, item) => {
      if (!item.purchase_date) {
        return result;
      }
      const purchaseDate = new Date(item.purchase_date);
      if (isNaN(purchaseDate.valueOf())) {
        return result;
      }
      if (purchaseDate < result) {
        return purchaseDate;
      }
      return result;
    }, new Date());
    return +minDate.getFullYear();
  }
);
