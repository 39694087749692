import React, { useState } from "react";
import Tour from "reactour";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import { userDontShowDialogsSelector } from "src/store/system/selector";
import DontShowThisMessageAgainCheckbox from "src/components/DontShowThisMessageAgainCheckbox";
import { useWalkThroughMarker } from "src/utils/WalkThroughMarker";

const Key = "walk_through_auto_matching_sales";

const Steps = [
  {
    selector: "#sales-matching-table-title",
    content: (props) => (
      <>
        <DialogTitle>Auto-Matching Sales - 1/6</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Welcome to the auto-matcher! On this page, sold items will be
            matched to inventory based on the item title. If the titles are the
            same, a match is made. You’ll see the details of the match in the
            table.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(1)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#sales-matching-table-toggle-title-sku",
    content: (props) => (
      <>
        <DialogTitle>Auto-Matching Sales - 2/6</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can also view matches by SKU by clicking the “SKU” option in the
            upper right.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(0)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(2)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#sales-matching-table-header",
    content: (props) => (
      <>
        <DialogTitle>Auto-Matching Sales - 3/6</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can see information about the sale and the inventory to help
            confirm that the match is correct.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(1)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(3)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#sales-matching-table-checks",
    content: (props) => (
      <>
        <DialogTitle>Auto-Matching Sales - 4/6</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select matches by checking the boxes on the far left, or you can
            select all the matches by clicking the box in the header.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(2)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(4)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#sales-matching-table-actions-match-sales-button",
    content: (props) => (
      <>
        <DialogTitle>Auto-Matching Sales - 5/6</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Click the “Match Sales to Inventory” button to confirm the selected
            matches.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(3)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(5)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#sales-matching-table-actions-go-to-button",
    content: (props) => (
      <>
        <DialogTitle>Auto-Matching Sales - 6/6</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If an auto-match is not correct or there are items that didn’t match
            automatically, click the “Go to Manual Sales Matching” button
          </DialogContentText>
          <DontShowThisMessageAgainCheckbox dialog={Key} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(4)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.close()}
          >
            Close
          </Button>
        </DialogActions>
      </>
    ),
  },
];

const useStyles = makeStyles((theme) => ({
  helper: {
    maxWidth: "80%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "500px",
    },
    padding: 0,
  },
}));

function WalkThrough() {
  const [open, setOpen] = useState(true);
  const userDontShowDialogs = useSelector(userDontShowDialogsSelector);

  const classes = useStyles();
  const isOpen =
    open && userDontShowDialogs && !userDontShowDialogs?.includes(Key);

  useWalkThroughMarker(
    !!isOpen,
    "sales-matching-table-checks",
    ".sales-matching-table-cell-checkbox",
    "#sales-matching-table"
  );

  return (
    <Tour
      startAt={0}
      closeWithMask={false}
      disableFocusLock
      steps={Steps}
      isOpen={isOpen}
      onRequestClose={() => {
        setOpen(false);
      }}
      showButtons={false}
      showCloseButton={false}
      showNavigation={false}
      showNavigationNumber={false}
      showNumber={false}
      disableDotsNavigation
      rounded={5}
      className={classes.helper}
      disableInteraction
    />
  );
}

export default WalkThrough;
