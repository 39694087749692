import React, { type Dispatch, type SetStateAction } from "react";
import { toast } from "react-toastify";
import { compose } from "redux";
import { useSelector } from "react-redux";

import { getMercariIntegrations } from "src/store/plaidIntegration/selector";
import { mercariIntegrationCreate } from "src/apiService/modules/mercari";
import { getMercariUser } from "src/utils/extension/mercari";
import {
  MissingExtensionError,
  OutdatedExtensionError,
} from "src/utils/extension";
import withOnlyDevelopment from "src/utils/withOnlyDevelopment";
import withFeatureFlag from "src/utils/withFeatureFlag";

import type { DialogState } from "../types";
import PlatformIntegrationsTableRow from "../PlatformIntegrationsTableRow";

import Row from "./Row";

// fix-vim-highlight = }

interface MercariProps {
  hideInventory: boolean;
  setDialog: Dispatch<SetStateAction<DialogState>>;
}

function Mercari({ setDialog, hideInventory }: MercariProps) {
  const mercariIntegrations = useSelector(getMercariIntegrations);
  const handleMercariAdd = async () => {
    try {
      const data = await getMercariUser();
      setDialog({
        type: "mercari-confirm",
        props: {
          username: data.username,
          platform: "Mercari",
          onConfirm: async () => {
            if (data?.cookies)
              await mercariIntegrationCreate({
                cookies: data.cookies,
                username: data.username,
              });
            setDialog({});
          },
        },
      });
    } catch (e) {
      if (e instanceof MissingExtensionError) {
        setDialog({
          type: "mercari-add-extension",
          props: { platform: "Mercari" },
        });
        return;
      }
      if (e instanceof OutdatedExtensionError) {
        setDialog({
          type: "mercari-add-extension",
          props: { platform: "Mercari", update: true },
        });
        return;
      }
      toast.error((e as Error).message);
    }
  };
  if (mercariIntegrations.length === 0)
    return (
      <PlatformIntegrationsTableRow
        name="Mercari"
        platform="Mercari"
        isRunning={false}
        sync={false}
        inventory={false}
        onImport={handleMercariAdd}
        hideInventory={hideInventory}
        onPullDataNow={async () => handleMercariAdd()}
        onSyncChanged={handleMercariAdd}
        onAdd={handleMercariAdd}
      />
    );
  return (
    <>
      {mercariIntegrations.map((integration) => (
        <Row
          key={integration.id}
          integration={integration}
          hideInventory={hideInventory}
          setDialog={setDialog}
        />
      ))}
    </>
  );
}

export default compose<typeof Mercari>(
  withOnlyDevelopment,
  withFeatureFlag("mercari")
)(Mercari);
