import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import LoadableButton from "src/components/LoadableButton";

import { setActiveDialog } from "src/store/adminHtml/actions";
import { userPlanSelector } from "src/store/system/selector";
import useStripeLink from "src/utils/useStripeLink";

function UpgradePlanDialogContent({ onClose }) {
  const link = useStripeLink();
  const plan = useSelector(userPlanSelector);
  const text =
    plan === "premium"
      ? "You are currently on our Premium plan. For just $5 more/month, you can get the most automation that MRG has to offer including eBay integration, bulk sales matching, and more!"
      : "You are currently on our Basic plan. For just $5 more/month, you can upgrade to Premium and have the ability to automatically track your expenses with our bank integration, get amazing insights into your business with our sales analytics, and more! Or for just $10 more/month, you can have all that Premium has as well as eBay integration, bulk sales matching, and more with the Ultimate plan!";

  return (
    <>
      <DialogTitle>Want to unlock more features?</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>No thanks</Button>
        <LoadableButton
          color="primary"
          autoFocus
          variant="contained"
          loading={!link}
          href={link}
          {...({ target: "_blank" } as any)}
        >
          Upgrade
        </LoadableButton>
      </DialogActions>
    </>
  );
}

function UpgradePlanDialog({ open }) {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setActiveDialog(""));
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <UpgradePlanDialogContent onClose={handleClose} />
    </Dialog>
  );
}

export default UpgradePlanDialog;
