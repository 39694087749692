import firebase from "firebase/app";

import axiosClient from "../axiosClient";
import { formatDate } from "../../utils";
import { getUserId } from "../../config/storage";
import { requestNOUpdate } from "./numericOverview";

import type { Inventory } from "src/interfaces/inventory.interface";
import type { Sale } from "src/interfaces/sale.interface";
import chunk from "lodash/chunk";
import { MaximumBatchSize } from "src/config/firestore";
import firestore from "src/apiService/firestore";
import storage from "src/apiService/storage";

import convertValue from "src/utils/convertValue";

const db = firestore();

export const fetchSalesFirebase = async (payload: any) => {
  const userId = await getUserId();
  const results: any[] = [];
  const snapshot = await db
    .collection("Sales")
    .where("user", "==", userId)
    .get();
  snapshot.forEach((doc) => results.push(doc.data()));
  return { data: results, length: snapshot.size, total: snapshot.size };
};

export const uploadSalesApi = async (url: string, filename: string) => {
  const { data } = await axiosClient.post("sales", { url, filename });
  return data;
};

export const deleteSalesApi = async () => {
  // TODO: type
  const { data } = await axiosClient.delete("sales");
  return data;
};

function calculateGrossProfit(data: {
  sale_price?: number;
  purchase_price?: number;
  shipping_cost?: number;
  shipping_cost_analytics?: number;
  other_fees?: number;
  transaction_fees?: number;
  extra_shipping_cost?: number;
  extra_transaction_fees?: number;
}): number {
  return (
    (+(data.sale_price || 0) || 0) -
    (+(data.purchase_price || 0) || 0) -
    (+(data.shipping_cost || 0) || 0) -
    (+(data.shipping_cost_analytics || 0) || 0) -
    (+(data.other_fees || 0) || 0) -
    (+(data.transaction_fees || 0) || 0) -
    (+(data.extra_shipping_cost || 0) || 0) -
    (+(data.extra_transaction_fees || 0) || 0)
  );
}

function payloadToSale(
  userId: string,
  docId: string,
  payload: any,
  income_tax_rate: number
) {
  const data = {
    ...payload,
    purchase_date: convertValue(payload.purchase_date, "date"),
    list_date: convertValue(payload.list_date, "date"),
    purchase_price: convertValue(payload.purchase_price, "numeric") || 0,
    sale_date: convertValue(payload.sale_date, "date"),
    sale_price: convertValue(payload.sale_price, "numeric") || 0,
    shipping_cost: convertValue(payload.shipping_cost, "numeric") || 0,
    transaction_fees: convertValue(payload.transaction_fees, "numeric") || 0,
    shipping_cost_analytics:
      convertValue(payload.shipping_cost_analytics, "numeric") || 0,
    other_fees: convertValue(payload.other_fees, "numeric") || 0,
    extra_shipping_cost:
      convertValue(payload.extra_shipping_cost, "numeric") || 0,
    extra_transaction_fees:
      convertValue(payload.extra_transaction_fees, "numeric") || 0,
    sales_tax: convertValue(payload.sales_tax, "numeric") || 0,
    liable_to_pay: convertValue(payload.liable_to_pay, "boolean"),
    brand: payload.brand || "",
    category: payload.category || "",
    sub_category: payload.sub_category || "",
    department: payload.department || "",
    item_option: null,
    days_on_platform: 0,
    item_title: payload.item_title || "",
    location: payload.location || "",
    net_profit: 0,
    notes: payload.notes || "",
    other_business_costs: 0,
    gross_profit: 0,
    sale_tax_paid: null,
    user: userId,
    is_return: payload.is_return || false,
    vendor: payload.vendor || "",
    id: docId,
  };

  for (const key of Object.keys(data)) {
    if (data[key] === undefined) delete data[key];
  }

  if (payload.return_date)
    payload.return_date = convertValue(payload.return_date, "date");

  data.days_on_platform = daysOnPlatform(data.sale_date, data.list_date);

  // Calculate gross profit
  data.gross_profit = calculateGrossProfit(data);

  // Calculate estimates income tax
  data.estimated_income_tax = data.gross_profit * income_tax_rate;

  // Calculate net profit from calculated gross profit and estimates income tax
  data.net_profit =
    data.gross_profit - data.estimated_income_tax - data.other_business_costs;

  return data;
}

export const updateSaleApi = async (payload: any) => {
  if (!payload.id) return;

  const userId = await getUserId();
  return await db.runTransaction(async (transaction) => {
    const userData = await transaction.get(db.collection("Users").doc(userId));
    const income_tax_rate = userData.data()?.income_tax_rate || 0;
    const ref = db.collection("Sales").doc(payload.id);
    const doc = await transaction.get(ref);

    const data = payloadToSale(
      userId,
      payload.id,
      {
        ...doc.data(),
        ...payload,
      },
      income_tax_rate
    );
    await transaction.set(ref, data);

    return {
      success: true,
      statusCode: 200,
      message: "Sale has been updated successfully",
    };
  });
};

export async function moveSaleApi(payload: Sale) {
  if (!payload.id) return;

  const user = await getUserId();
  const keys = [
    "brand",
    "category",
    "department",
    "item_option",
    "item_title",
    "list_date",
    "location",
    "notes",
    "platforms_listed",
    "purchase_date",
    "purchase_price",
    "sku",
    "sub_category",
    "vendor",
  ];
  const data = { user };

  for (const k of keys) {
    if (k in payload && payload[k] !== undefined) data[k] = payload[k];
  }

  return await db.runTransaction(async (transaction) => {
    const doc = db.collection("Inventory").doc();
    await transaction.set(doc, {
      ...data,
      id: doc.id,
    });
    await transaction.delete(db.collection("Sales").doc(payload.id));
    return doc.id;
  });
}

export const moveBundledSalesApi = async (payload: any) => {
  const { data } = await axiosClient.put("move-bundle-to-inventory", {
    items: payload.map((item) => ({
      ...item,
      list_date: item.list_date
        ? formatDate(item.list_date, "YYYY-MM-DD")
        : undefined,
      purchase_date: item.purchase_date
        ? formatDate(item.purchase_date, "YYYY-MM-DD")
        : undefined,
      sale_date: item.sale_date
        ? formatDate(item.sale_date, "YYYY-MM-DD")
        : undefined,
    })),
  });
  return data;
};

export async function addSaleApi(payload: any) {
  const userId = await getUserId();
  return await db.runTransaction(async (transaction) => {
    const userData = await transaction.get(db.collection("Users").doc(userId));
    const income_tax_rate = userData.data()?.income_tax_rate || 0;
    const doc = db.collection("Sales").doc();
    const data = payloadToSale(userId, doc.id, payload, income_tax_rate);
    await transaction.set(doc, data);

    return {
      success: true,
      statusCode: 201,
      message: `${doc.id} has been added successfully`,
      id: doc.id,
    };
  });
}

function daysOnPlatform(saleDate, listDate) {
  const days = Math.floor((saleDate - listDate) / (1000 * 60 * 60 * 24));
  return days === 0 ? days : days || null;
}

export async function addSaleFromInventory(
  inventoryId: string,
  payload: Omit<Sale, "id">
) {
  const userId = await getUserId();

  return await db.runTransaction(async (transaction) => {
    const userData = await transaction.get(db.collection("Users").doc(userId));
    const income_tax_rate = userData.data()?.income_tax_rate || 0;
    const doc = db.collection("Sales").doc();
    const data = {
      ...payload,
      sale_price: payload.sale_price || 0,
      purchase_price: payload.purchase_price || 0,
      shipping_cost: payload.shipping_cost || 0,
      transaction_fees: payload.transaction_fees || 0,
      extra_shipping_cost: payload.extra_shipping_cost || 0,
      extra_transaction_fees: payload.extra_transaction_fees || 0,
      gross_profit: 0,
      net_profit: 0,
      item_option: null,
      other_business_costs: 0,
      sale_tax_paid: null,
      user: userId,
      is_return: payload.is_return || false,
      vendor: payload.vendor || "",
      days_on_platform: daysOnPlatform(payload.sale_date, payload.list_date),
      id: doc.id,
    };

    // Calculate gross profit
    data.gross_profit = calculateGrossProfit(data);

    // Calculate estimates income tax
    data.estimated_income_tax = data.gross_profit * income_tax_rate;

    // Calculate net profit from calculated gross profit and estimates income tax
    data.net_profit =
      data.gross_profit - data.estimated_income_tax - data.other_business_costs;

    const inventoryDoc = db.collection("Inventory").doc(inventoryId);
    const inventory = await transaction.get(inventoryDoc);
    if (inventory.exists) {
      const inventoryData = inventory.data();
      const quantity = inventoryData?.quantity || 1;

      if (quantity > 1) {
        await transaction.update(inventoryDoc, {
          quantity: quantity - 1,
          purchase_price:
            (inventoryData?.purchase_price || 0) -
            (payload.purchase_price || 0),
        });
      } else {
        await transaction.delete(inventoryDoc);
      }
    }

    await transaction.set(doc, data);
    return doc.id;
  });
}

export const updateBundledSalesApi = async (payload: any) => {
  const { data } = await axiosClient.put("bundled_sales", {
    items: payload.map((item) => ({
      ...item,
      sale_date: item.sale_date
        ? formatDate(item.sale_date, "YYYY-MM-DD")
        : undefined,
      list_date: item.list_date
        ? formatDate(item.list_date, "YYYY-MM-DD")
        : undefined,
      purchase_date: item.purchase_date
        ? formatDate(item.purchase_date, "YYYY-MM-DD")
        : undefined,
    })),
  });
  return data;
};

export const deleteBundledSalesApi = async (payload: any) => {
  const { data } = await axiosClient.delete(
    `bundled_sales?ids=${payload.map((item) => item.id).join(",")}`
  );
  return data;
};

export const deleteSaleApi = async (payload: string) => {
  const { data } = await axiosClient.delete(`sale?id=${payload}`);
  return data;
};

export const deleteSale = async (id: string) => {
  await db.collection("Sales").doc(id).delete();
  return await requestNOUpdate();
};

export async function deleteReturn(returnId: string) {
  if (!returnId) return;

  await db.runTransaction(async (transaction) => {
    const returnRef = db.collection("Sales").doc(returnId);
    const doc = await transaction.get(returnRef);
    if (!doc.exists) throw new Error(`Return #${returnId} doesn't exist`);
    const data = doc.data();
    if (data?.original_id) {
      const originalRef = db.collection("Sales").doc(data.original_id);
      try {
        const original = await transaction.get(originalRef);
        if (original.exists) {
          transaction.update(originalRef, {
            return_id: firebase.firestore.FieldValue.delete(),
          });
        }
      } catch (e) {
        console.warn(
          `Couldn't delete return_id in #${data.original_id} (deleted return ${returnId})`,
          e
        );
      }
    }
    transaction.delete(returnRef);
  });

  return await requestNOUpdate();
}

export const uploadSalesCsv = async (payload: File | Blob, userId: string) => {
  const storageRef = storage().ref();
  const timestampedFilename: string = `sales/${userId}/${
    File.name + Date.now()
  }`;
  const uploadRef = storageRef.child(timestampedFilename);
  const snapshot = await uploadRef.put(payload);
  if (snapshot.state === firebase.storage.TaskState.SUCCESS) {
    const url = await uploadRef.getDownloadURL();
    return { url };
  }
  throw new Error("upload failed");
};

export const consolidateEbaySaleShippingAndAnalyticsCost = async (
  orderIds: Array<string>
) => {
  const { data } = await axiosClient.post(`sales/consolidate`, {
    order_ids: orderIds,
  });
  return data;
};

export async function deleteManySales(ids: Sale["id"][]) {
  if (!ids.length) return;

  for (const c of chunk(ids, MaximumBatchSize)) {
    const batch = db.batch();
    for (const id of c) {
      const doc = db.collection("Sales").doc(id);
      batch.delete(doc);
    }
    await batch.commit();
  }

  return await requestNOUpdate();
}

export async function reviewManySales(
  ids: Sale["id"][],
  unreviewed = false,
  vendors: Record<string, string> = {}
) {
  if (!ids.length) return;

  for (const c of chunk(ids, MaximumBatchSize)) {
    const batch = db.batch();
    for (const id of c) {
      const doc = db.collection("Sales").doc(id);
      const data: { unreviewed: boolean; vendor?: string } = { unreviewed };
      if (vendors[id]) data.vendor = vendors[id];
      batch.update(doc, data);
    }
    await batch.commit();
  }
}

export async function updateSaleFirestore(
  sale: Pick<Sale, "id"> & Partial<Sale>
) {
  let doc = db.collection("Sales").doc(sale.id);
  await doc.update(sale);
}

export async function uploadSalesFile(rows, metadata = {}) {
  const f = firebase.functions().httpsCallable("upload-uploadSales");

  return (await f({ rows, metadata })).data;
}

export async function bulkEditSales(rows, metadata = {}) {
  const f = firebase.functions().httpsCallable("upload-bulkEditSales");
  const ret = (await f({ rows, metadata })).data;
  await requestNOUpdate();
  return ret;
}

export async function addBundledSales(sales: Sale[]) {
  if (sales.length === 0) return;
  const userId = await getUserId();
  return await db.runTransaction(async (transaction) => {
    const userData = await transaction.get(db.collection("Users").doc(userId));
    const income_tax_rate = userData.data()?.income_tax_rate || 0;
    const items: Record<
      string,
      {
        ref: firebase.firestore.DocumentReference;
        data: Inventory;
        amount: number;
        totalPurchasePrice: number;
      }
    > = {};

    // Get all inventory information
    for (const sale of sales) {
      // id is from inventory
      const { id: inventoryId, purchase_price } = sale;
      if (!inventoryId) continue;
      if (items[inventoryId]) {
        items[inventoryId].amount = items[inventoryId].amount + 1;
        items[inventoryId].totalPurchasePrice =
          items[inventoryId].totalPurchasePrice + (purchase_price || 0);
        continue;
      }

      const ref = db.collection("Inventory").doc(inventoryId);
      const doc = await transaction.get(ref);
      if (!doc.exists) continue;
      const data = doc.data() as Inventory;
      if (!data) continue;

      items[inventoryId] = {
        ref,
        data,
        amount: 1,
        totalPurchasePrice: purchase_price || 0,
      };
    }

    // Update or delete inventory
    for (const item of Object.values(items)) {
      const { ref, data, amount, totalPurchasePrice } = item;
      const quantity = data?.quantity || 1;

      if (quantity > amount) {
        const purchasePrice = data?.purchase_price || 0;
        const all = totalPurchasePrice
          ? totalPurchasePrice
          : Math.round((amount * purchasePrice * 100) / amount) / 100;
        await transaction.update(ref, {
          quantity: quantity - amount,
          purchase_price: Math.round((purchasePrice - all) * 100) / 100,
        });
      } else {
        await transaction.delete(ref);
      }
    }

    // Create sales
    const ids: string[] = [];
    for (const sale of sales) {
      const doc = db.collection("Sales").doc();
      const data = payloadToSale(userId, doc.id, sale, income_tax_rate);
      await transaction.set(doc, data);
      ids.push(doc.id);
    }

    return ids;
  });
}
