import React, { useRef, useState, useEffect, type ComponentType } from "react";
import TextField, { type TextFieldProps } from "@material-ui/core/TextField";
import { type InputProps } from "@material-ui/core/Input";

// fix-vim-highlight = }

interface BaseProps {
  type?: InputProps["type"];
  onBlur?: InputProps["onBlur"];
  inputProps?: {
    inputMode?: InputProps["inputMode"];
  };
  value?: unknown;
  onChange?: InputProps["onChange"];
}

export type NumberFieldProps<T extends BaseProps = TextFieldProps> = Omit<
  T,
  "onChange"
> & {
  onChange?: (ev: { target: { value?: number; name?: string } }) => void;
  allowNegative?: boolean;
  Component?: ComponentType<T>;
  fixed?: number;
};

function hasMoreThanOneDot(value?: any): boolean {
  if (!value || typeof value !== "string") return false;

  let dots = 0;
  for (let i = 0; i < value.length; i++) {
    if (value[i] === ".") {
      dots++;
      if (dots > 1) return true;
    }
  }

  return false;
}

function hasLeadingZero(value?: any): boolean {
  if (!value || typeof value !== "string") return false;

  if (value.length > 1 && value[0] === "0" && value[1] !== ".") return true;
  return false;
}

const NumericCharacters = {
  negative: /[^.0-9-]/g,
  nonNegative: /[^.0-9]/g,
};

function isNonNumeric(value: any, allowNegative: boolean): boolean {
  if (!value || typeof value !== "string") return false;
  return !!value.match(
    NumericCharacters[allowNegative ? "negative" : "nonNegative"]
  );
}

function hasInvalidDash(value?: any): boolean {
  if (!value || typeof value !== "string" || value.length < 2) return false;
  for (let i = 1; i < value.length; i++) {
    if (value[i] === "-") return true;
  }
  return false;
}

function replaceAt(str: string, index: number, replacement: string): string {
  return `${str.substring(0, index)}${replacement}${str.substring(
    index + replacement.length
  )}`;
}

function toFixed(num: string, fixed: number): string {
  if (isNaN(parseFloat(num)) || num.match(/[^0-9.]/)) return "NaN";
  let [integer, decimal] = num.split(".");
  if ((decimal || "").length <= fixed) return num;

  const extra = parseInt(decimal[fixed], 10);
  decimal = decimal.substring(0, fixed);

  if (extra >= 5) {
    let hasIncreased = false;

    for (let i = fixed - 1; i >= 0; i--) {
      const current = parseInt(decimal[i], 10);
      if (9 > current) {
        decimal = replaceAt(decimal, i, `${current + 1}`);
        hasIncreased = true;
        break;
      }
      decimal = replaceAt(decimal, i, "0");
    }

    if (!hasIncreased) integer = `${parseInt(integer, 10) + 1}`;
  }

  return `${integer}.${decimal}`;
}

function initValue(value: unknown, fixed?: number) {
  if (fixed && value) {
    const v = toFixed(`${value}`, fixed);
    if (v !== "NaN" && `${value}`.length > v.length) return v;
  }
  return value;
}

function NumberField<T extends BaseProps = TextFieldProps>({
  type,
  onChange,
  value: realValue,
  onBlur,
  allowNegative = false,
  Component = TextField,
  fixed,
  inputProps = {},
  ...props
}: NumberFieldProps<T>) {
  const [value, setValue] = useState(() => initValue(realValue, fixed));
  const valueRef = useRef(value);
  valueRef.current = value;

  useEffect(() => {
    const real = initValue(realValue, fixed);
    if (
      // eslint-disable-next-line eqeqeq
      real != valueRef.current &&
      !(realValue === undefined && valueRef.current === "-")
    ) {
      setValue(real);
    }
  }, [realValue, fixed]);

  const passedProps = onChange
    ? {
        value: value === undefined ? "" : value,
        onChange: (event) => {
          let value = event.target.value;
          if (isNonNumeric(value, allowNegative)) return;
          if (hasMoreThanOneDot(value)) return;
          if (hasInvalidDash(value)) return;

          while (hasLeadingZero(value)) {
            value = value.substring(1);
          }

          value = initValue(value, fixed);

          setValue(value);

          if (!value.endsWith(".") && value !== "-") {
            if (value === "") value = undefined;
            else value = +value;

            onChange({ target: { value, name: event.target?.name } });
          }
        },
        ...props,
      }
    : props;
  const C = Component as any;

  return (
    <C
      type="text"
      onBlur={(...args) => {
        if (onChange) {
          // eslint-disable-next-line eqeqeq
          if (value != realValue) {
            const parsedValue = +(value as any);
            onChange({
              target: {
                value: isNaN(parsedValue) ? undefined : parsedValue,
                name: args[0]?.target.name,
              },
            });
          }
        }

        if (onBlur) (onBlur.apply as any)(null, args);
      }}
      inputProps={{
        inputMode: "decimal",
        ...inputProps,
      }}
      {...passedProps}
    />
  );
}

export default NumberField;
