import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector } from "react-redux";
import { GridCellParams } from "@material-ui/x-grid";
import g from "lodash/get";

import { getVendor } from "src/store/vendor/selector";

export interface VendorCellProps {
  params: GridCellParams;
}

const style: React.CSSProperties = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export function VendorCell({ params }: VendorCellProps) {
  const vendorId = g(params.row, params.field);
  const vendor = useSelector((s: any) => getVendor(s, vendorId))?.name || "";
  return (
    <Tooltip title={vendor}>
      <span style={style}>{vendor}</span>
    </Tooltip>
  );
}

function vendorRenderCell(params: GridCellParams) {
  return <VendorCell params={params} />;
}

export default vendorRenderCell;
