import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LoadableButton from "src/components/LoadableButton";

import SearchIcon from "@material-ui/icons/Search";

import { getUser } from "src/store/support/actions";
import {
  supportGetUserIsLoading,
  supportGetUser,
} from "src/store/support/selector";
import { SupportStateUserDataError } from "src/interfaces/supportState.interface";

import Form from "./Form";

const useStyles = makeStyles({
  textField: {
    flex: 1,
    marginRight: 8,
  },
});

function Empty() {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={4}>
      <SearchIcon fontSize="large" />
      <Typography variant="h6" component="span">
        Search for an user
      </Typography>
    </Box>
  );
}

function User() {
  const classes = useStyles();
  const history = useHistory();
  const { uid } = useParams<{ uid?: string }>();
  const handleSubmit = (e) => {
    e.preventDefault();
    const input = e.target.querySelector('input[name="username"]');
    if (!input) return;
    const username = input.value;
    if (username === uid) dispatch(getUser(username));
    else history.push(`/support/user/${username}`);
  };
  const dispatch = useDispatch();
  const user = useSelector((state) => supportGetUser(state as any, uid || ""));
  const isLoading = useSelector(supportGetUserIsLoading);

  useEffect(() => {
    if (!uid) return;
    dispatch(getUser(uid));
  }, [uid, dispatch]);


  return (
    <Box m={2}>
      <Box component={Paper} pb={1}>
        <Box
          display="flex"
          mx={2}
          pt={1}
          alignItems="center"
          component="form"
          onSubmit={handleSubmit}
        >
          <TextField
            key={uid}
            name="username"
            variant="outlined"
            label="Username"
            className={classes.textField}
            size="small"
            defaultValue={uid}
            disabled={isLoading}
          />
          <LoadableButton
            variant="contained"
            color="primary"
            type="submit"
            loading={isLoading}
          >
            Search User
          </LoadableButton>
        </Box>
        {uid && user && !(user as SupportStateUserDataError).error ? (
          <Form key={uid} username={uid} user={user} />
        ) : (
          <Empty />
        )}
      </Box>
    </Box>
  );
}

export default User;
