import produce from "immer";
import { handleActions } from "redux-actions";

import { secondsToDate } from "../../utils";

import { SaleState } from "../../interfaces";
import { METHOD_TYPE, SALE_FILTER } from "../../enums";
import { LOG_OUT } from "../system/actions";
import {
  SET_EBAY_EXTRA_ROWS,
  SET_TIME_FILTER,
  SET_PENDING_UPLOADS,
  SET_UPLOAD_FILENAME,
  SET_UPLOADS_FROM_EBAY,
  SHIFT_PENDING_UPLOAD,
  UPDATE_PENDING_UPLOAD,
} from "./actions";
import {
  START,
  FAIL,
  SUCCESS,
  UPLOAD_ITEMS,
  DELETE_ITEMS,
  MOVE_ITEM,
  FS_CREATE_ITEMS,
  FS_UPDATE_ITEMS,
  FS_DELETE_ITEMS,
  CREATE_ITEM,
  DELETE_ITEM,
  UPDATE_ITEM,
  RESET,
  SALE,
  RETURN_SALE,
  BUNDLED_SALES,
  SELECT_ITEMS,
  SET_FILTER,
  CLEAR_UPDATE_METHOD,
  handleError,
  handleSuccess,
} from "../common"

const initialState: SaleState = {
  items: [],
  total: 0,
  start: 1,
  end: 1,
  limit: 25,
  pendingUploads: [],
  uploadFilename: "",
  ebayExtraRows: [],
  ebayReturns: [],
  ebayUploads: false,

  method: METHOD_TYPE.LIST,
  loading: false,
  loaded: false,
  error: null,
  message: "",

  selected: [],
  filter: {
    items: []
  },
  timeFilter: SALE_FILTER.ALL_TIME,
};

export const saleReducer = handleActions<SaleState, any>(
  {
    [SALE + RESET]: (state) => initialState,
    [SET_PENDING_UPLOADS]: (state, { payload }) => ({
      ...state,
      pendingUploads: payload.filter((sale) => {
        if (!sale.sale_date) return false;
        if (sale.transaction_id) {
          if (state.items.find(s => s.transaction_id === sale.transaction_id))
            return false;
        }

        return true;
      }),
    }),
    [UPDATE_PENDING_UPLOAD]: (state, { payload }) =>
      produce(state, (draft) => {
        let index = -1;
        for (const key of ['uuid', 'transaction_id', 'item_title', 'sku']) {
          if (payload[key])
            index = state.pendingUploads.findIndex(item => payload[key] === item[key]);

          if (index >= 0) break;
        }

        if (index >= 0) draft.pendingUploads[index] = payload;
      }),
    [SHIFT_PENDING_UPLOAD]: (state, { payload }) =>
      produce(state, (draft) => {
        const firstIndex = state.pendingUploads.findIndex((item) => item.unmatched);
        if (firstIndex >= 0) {
          draft.pendingUploads.splice(firstIndex, 1);
        }
      }),
    [SET_UPLOAD_FILENAME]: (state, { payload }) => ({ ...state, uploadFilename: payload }),
    [SALE + UPLOAD_ITEMS + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.UPLOAD;
        draft.loading = true;
        draft.loaded = false;
      }),
    [SALE + UPLOAD_ITEMS + SUCCESS]: handleSuccess,
    [SALE + UPLOAD_ITEMS + FAIL]: handleError,
    [SALE + DELETE_ITEMS + START]: (state) => ({
      ...state,
      method: METHOD_TYPE.DELETE_ALL,
      loaded: false,
      loading: true,
    }),
    [SALE + DELETE_ITEMS + SUCCESS]: (state, { payload }) => ({
      ...state,
      items: [],
      loaded: true,
      loading: false,
    }),
    [SALE + DELETE_ITEMS + FAIL]: handleError,
    [BUNDLED_SALES + CREATE_ITEM + START]: (state) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.CREATE;
        draft.loading = true;
        draft.error = null;
      }),
    [RETURN_SALE + CREATE_ITEM + START]: (state) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.UPDATE;
        draft.loading = true;
        draft.error = null;
      }),
    [SALE + CREATE_ITEM + START]: (state) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.CREATE;
        draft.loading = true;
        draft.error = null;
      }),
    [SALE + CREATE_ITEM + SUCCESS]: handleSuccess,
    [SALE + CREATE_ITEM + FAIL]: handleError,
    [SALE + UPDATE_ITEM + START]: (state) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.UPDATE;
        draft.loading = true;
        draft.error = null;
      }),
    [SALE + UPDATE_ITEM + SUCCESS]: handleSuccess,
    [BUNDLED_SALES + UPDATE_ITEM + START]: (state) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.UPDATE;
        draft.loading = true;
        draft.error = null;
      }),
    [SALE + UPDATE_ITEM + FAIL]: handleError,
    [BUNDLED_SALES + MOVE_ITEM + START]: (state) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.MOVE;
        draft.loading = true;
        draft.error = null;
      }),
    [SALE + MOVE_ITEM + START]: (state) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.MOVE;
        draft.loading = true;
        draft.error = null;
      }),
    [SALE + MOVE_ITEM + SUCCESS]: handleSuccess,
    [SALE + MOVE_ITEM + FAIL]: handleError,
    [BUNDLED_SALES + DELETE_ITEM + START]: (state) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.DELETE;
        draft.loading = true;
        draft.error = null;
      }),
    [RETURN_SALE + DELETE_ITEM + START]: (state) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.DELETE;
        draft.loading = true;
        draft.error = null;
      }),
    [SALE + DELETE_ITEM + START]: (state) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.DELETE;
        draft.loading = true;
        draft.error = null;
      }),
    [SALE + DELETE_ITEM + SUCCESS]: handleSuccess,
    [SALE + DELETE_ITEM + FAIL]: handleError,
    [SALE + SELECT_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.selected = payload;
      }),
    [SALE + SET_FILTER]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.filter = payload;
      }),
    [SALE + SET_TIME_FILTER]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.timeFilter = payload;
        draft.filter = { items: [] };
      }),
    [CLEAR_UPDATE_METHOD]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.LIST;
      }),
    [SET_UPLOADS_FROM_EBAY]: (state, { payload }) => ({
      ...state, ebayUploads: payload
    }),
    [SET_UPLOADS_FROM_EBAY]: (state, { payload }) => ({
      ...state, ebayUploads: payload
    }),
    [SET_EBAY_EXTRA_ROWS]: (state, { payload }) => ({
      ...state, ebayExtraRows: payload.extraRows, ebayReturns:  payload.returns,
    }),
    [SALE + FS_CREATE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items?.sort((a, b) => {
          const legend = [-1, 0, 1];
          return legend[+((a?.sale_date as Date) < (b?.sale_date as Date))];
        });
        const mapped = payload.map((e: any) => {
          const dateValue = secondsToDate(e.sale_date).valueOf();
          if (!dateValue) {
            console.log({ sale: e, saleDate: { seconds: e.sale_date, output: secondsToDate(e.sale_date) }});
          }
          return {
            ...e,
            platforms_listed: typeof e.platforms_listed === "string" ? 
              e.platforms_listed.split(",") : e.platforms_listed,
            purchase_date: e.purchase_date ? secondsToDate(e.purchase_date) : undefined,
            list_date: e.list_date ? secondsToDate(e.list_date) : undefined,
            sale_date: e.sale_date ? secondsToDate(e.sale_date) : undefined,
            return_date: e.return_date ? secondsToDate(e.return_date) : undefined,
          };
        });
        mapped.sort((a, b) => b.sale_date - a.sale_date);
        draft.items.unshift(...mapped);
        draft.loading = false;
        draft.loaded = true;
      }),
    [SALE + FS_UPDATE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items.forEach((item) => {
          const updated = payload.find((newItem) => newItem.id === item.id);
          if (updated) {
            const mapped = {
              ...updated,
              platforms_listed: typeof updated.platforms_listed === "string" ? 
                updated.platforms_listed.split(",") : updated.platforms_listed,
              purchase_date: updated.purchase_date ? secondsToDate(updated.purchase_date) : undefined,
              list_date: updated.list_date ? secondsToDate(updated.list_date) : undefined,
              sale_date: updated.sale_date ? secondsToDate(updated.sale_date) : undefined,
              return_date: updated.return_date ? secondsToDate(updated.return_date) : undefined,
            };
            Object.assign(item, mapped);
          }
        });
      }),
    [SALE + FS_DELETE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items = state.items.filter((item) => !payload.find((deleted) => deleted.id === item.id));
      }),
    [LOG_OUT]: () => initialState,
  },
  initialState
);
