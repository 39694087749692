import { useRef, useEffect, type EffectCallback } from "react";
import { useSelector } from "react-redux";

import { selectUser } from "src/store/system/selector";

import { User } from "src/interfaces/systemState.interface";

function useUserEffect(
  f: (user: User) => ReturnType<EffectCallback>,
  deps?: any[]
) {
  const user = useSelector(selectUser);
  const ref = useRef({
    user,
    f,
  });
  ref.current.user = user;
  ref.current.f = f;

  useEffect(() => {
    const { user, f } = ref.current;
    if (user) return f(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user !== undefined, ...(deps || [])]);
}

export default useUserEffect;
