import { createSelector } from "reselect";
import { AppState } from "..";
import { ItemOptionsState } from "../../interfaces/itemOptionsState.interface";
import {
  analyticsGroupingsSelector,
  analyticsGroupingsListSelector,
} from "../system/selector";
import { capitalize } from "src/apiService/modules/analyticsGroupings";

const getItemOptionsState = (state: AppState) => state.itemOptions;

export const itemOptionsSelector = createSelector(
  [
    getItemOptionsState,
    analyticsGroupingsSelector,
    analyticsGroupingsListSelector,
  ],
  (
    itemOptions: ItemOptionsState,
    analyticsGroupings,
    analyticsGroupingsList
  ) => {
    if (!itemOptions.items.length) return itemOptions;

    return {
      ...itemOptions,
      items: [
        ...itemOptions.items,
        ...analyticsGroupingsList.map((g) => ({
          Department: g.department,
          Category: g.category,
          "Sub-Category": g.subcategory,
        })),
      ],
      departments: [
        ...itemOptions.departments,
        ...Object.keys(analyticsGroupings).map(capitalize),
      ],
    };
  }
);

export const departmentsSelector = createSelector(
  [itemOptionsSelector],
  ({ items }) => {
    if (!items?.length) return [];
    const departments = [
      ...new Set(items.map((option) => option["Department"]).filter(Boolean)),
    ];
    departments.sort();
    return departments;
  }
);

export const categoriesSelector = createSelector(
  [itemOptionsSelector, (_, { department }) => department],
  ({ items }, department) => {
    if (!items?.length || !department) return [];
    const categories = [
      ...new Set(
        items
          .filter(
            (option) => option.Department === department && option.Category
          )
          .map((option) => option["Category"])
      ),
    ];
    categories.sort();
    return categories;
  }
);

export const subCategoriesSelector = createSelector(
  [
    itemOptionsSelector,
    (_, { department }) => department,
    (_, { category }) => category,
  ],
  ({ items }, department, category) => {
    if (!items?.length || !department || !category) return [];
    const subCategories = [
      ...new Set(
        items
          .filter(
            (option) =>
              option.Department === department &&
              option.Category === category &&
              option["Sub-Category"]
          )
          .map((option) => option["Sub-Category"])
      ),
    ];
    subCategories.sort();
    return subCategories;
  }
);
