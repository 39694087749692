import React, { useState } from "react";
import { Redirect } from "react-router";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import {
  userGetStartedHasFinishedSelector,
  userGetStartedStepSelector,
} from "src/store/system/selector";
import { updateUserApi } from "src/apiService/modules/system";

import Dialog from "./Dialog";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    padding: `72px 96px`,
  },
  button: {
    marginTop: 32,
    padding: `12px 32px`,
  },
});

function Content() {
  const classes = useStyles();
  const [open, setOpen] = useState(
    document.location.pathname === "/get-started" && !!document.location.hash
  );
  const hasFinished = useSelector(userGetStartedHasFinishedSelector);
  const step = useSelector(userGetStartedStepSelector);
  const hasStarted =
    step && step !== "inventory:how-do-you-account-for-your-inventory";

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} />
      {hasFinished && !open ? (
        <Redirect to="/integrations" />
      ) : (
        <Paper className={classes.root}>
          <Typography align="center" component="p" variant="subtitle1">
            {hasStarted
              ? "Welcome back! Click the button below to continue setting up your Genie."
              : "Welcome to My Reseller Genie -- we’re excited to help you get your reselling business organized! Click the button below to get started."}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              if (!hasStarted) updateUserApi({ getStartedStart: new Date() });
              setOpen(true);
            }}
          >
            {hasStarted ? "Resume Set Up" : "Start Set Up"}
          </Button>
        </Paper>
      )}
    </>
  );
}

export default Content;
