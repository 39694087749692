import React from "react";

import {
  Box,
  Button,
  TextField,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  makeStyles,
  Typography,
} from "@material-ui/core";

import { colors } from "src/theme";
import { formatDollars } from "src/utils/formatter";

import NumberField from "src/components/NumberField";

const useStyles = makeStyles({
  header: {
    fontWeight: 500,
    color: "rgba(24, 68, 97, 1)",
  },
  row: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
    width: 915,
  },
  totalLabel: {
    fontWeight: 600,
  },
  match: {
    fontWeight: 600,
    color: colors.greenColor,
  },
  nomatch: {
    fontWeight: 600,
    color: colors.errorColor,
  },
});

const EditBundleAllocationsDialog = ({
  allocationValues,
  setAllocationValues,
  bundleValues,
  loading,
  goToBundle,
  handleSave,
}) => {
  const classes = useStyles();
  const handleChangeItem = (index: number, field: string) => (e: any) => {
    const value = e.target.value;
    const newValues = [...allocationValues];
    newValues[index] = { ...newValues[index], [field]: value };
    setAllocationValues(newValues);
  };

  const allocationTotals = React.useMemo(() => {
    let sale_price = 0;
    let transaction_fees = 0;
    let shipping_cost = 0;
    allocationValues.forEach((item) => {
      sale_price += +item.sale_price || 0;
      transaction_fees += +item.transaction_fees || 0;
      shipping_cost += +item.shipping_cost || 0;
    });
    return {
      sale_price: Math.floor(sale_price * 100) / 100,
      transaction_fees: Math.floor(transaction_fees * 100) / 100,
      shipping_cost: Math.floor(shipping_cost * 100) / 100,
    };
  }, [allocationValues]);

  const renderAllocationRow = (item, index) => (
    <Box className={classes.row} key={`${item.id}-${index}`}>
      <Box className="w-1/3 mr-2"><Typography>{item.item_title}</Typography></Box>
      <Box className="w-1/6 mr-2"><Typography>{formatDollars(item.purchase_price)}</Typography></Box>
      <Box className="w-1/6 mr-2">
        <NumberField
          // error={errors.sale_price ? true : false}
          // helperText={errors.sale_price}
          variant="outlined"
          margin="dense"
          label="Sale Price"
          required
          value={item.sale_price}
          disabled={loading}
          onChange={handleChangeItem(index, 'sale_price')}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Box>
      <Box className="w-1/6 mr-2">
        <NumberField
          // error={errors.sale_price ? true : false}
          // helperText={errors.sale_price}
          variant="outlined"
          margin="dense"
          label="Transaction Fees"
          required
          value={item.transaction_fees}
          disabled={loading}
          onChange={handleChangeItem(index, 'transaction_fees')}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Box>
      <Box className="w-1/6 mr-2">
        <NumberField
          // error={errors.sale_price ? true : false}
          // helperText={errors.sale_price}
          variant="outlined"
          margin="dense"
          label="Shipping Cost"
          required
          value={item.shipping_cost}
          disabled={loading}
          onChange={handleChangeItem(index, 'shipping_cost')}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Box>
    </Box>
  );

  let allocationsComplete = true;
  if (Math.round(+allocationTotals.sale_price * 100) / 100 !== Math.round(+bundleValues.sale_price * 100) / 100 ||
      Math.round(+allocationTotals.transaction_fees * 100) / 100 !== Math.round(+bundleValues.transaction_fees * 100) / 100 ||
      Math.round(+allocationTotals.shipping_cost * 100) / 100 !== Math.round(+bundleValues.shipping_cost * 100) / 100) {
    allocationsComplete = false;
  }

  const renderTotalsRow = () => (
    <Box className={classes.row}>
      <Box className="w-1/3 mr-2"><Typography className={classes.totalLabel}>Allocation Totals</Typography></Box>
      <Box className="w-1/6 mr-2" />
      <Box className="w-1/6 mr-2">
        <Typography
          className={Math.round(+allocationTotals.sale_price * 100) / 100 === Math.round(+bundleValues.sale_price * 100) / 100 ? classes.match : classes.nomatch}
        >{formatDollars(allocationTotals.sale_price)} / {formatDollars(bundleValues.sale_price)}
        </Typography>
      </Box>
      <Box className="w-1/6 mr-2">
        <Typography
          className={Math.round(+allocationTotals.transaction_fees * 100) / 100 === Math.round(+bundleValues.transaction_fees * 100) / 100 ? classes.match : classes.nomatch}
        >{formatDollars(allocationTotals.transaction_fees)} / {formatDollars(bundleValues.transaction_fees)}
        </Typography>
      </Box>
      <Box className="w-1/6 mr-2">
        <Typography
          className={Math.round(+allocationTotals.shipping_cost * 100) / 100 === Math.round(+bundleValues.shipping_cost * 100) / 100 ? classes.match : classes.nomatch}
        >{formatDollars(allocationTotals.shipping_cost)} / {formatDollars(bundleValues.shipping_cost)}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <>
      <DialogTitle id="add-sale relative">
        Allocate Bundled Sales
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          component="div"
          className="relative"
          tabIndex={-1}
        >
          <Box className={classes.row}>
            <Box className="w-1/3"><Typography className={classes.header}>Item Title</Typography></Box>
            <Box className="w-1/6 mr-2"><Typography className={classes.header}>Purchase Price</Typography></Box>
            <Box className="w-1/6 mr-2"><Typography className={classes.header}>Sale Price</Typography></Box>
            <Box className="w-1/6 mr-2"><Typography className={classes.header}>Transaction Fees</Typography></Box>
            <Box className="w-1/6 mr-2"><Typography className={classes.header}>Shipping Cost</Typography></Box>
          </Box>
          {allocationValues.map(renderAllocationRow)}
          {renderTotalsRow()}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="px-7 py-5">
        <Button onClick={goToBundle} color="secondary" variant="contained" disabled={loading}>
          {loading && <CircularProgress size={15} className="mr-3" />}
          Back
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained" disabled={!allocationsComplete || loading}>
          {loading && <CircularProgress size={15} className="mr-3" />}
          Submit
        </Button>
      </DialogActions>
    </>
  );
}

export default EditBundleAllocationsDialog;
