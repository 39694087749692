function convertValue(value: any, type: "date" | "numeric" | "boolean") {
  switch (type) {
    case "date":
      if (value) {
        const v = new Date(value);
        v.setHours(12);
        return v;
      }
      return null;
    case "numeric":
      if (value && !isNaN(value)) {
        return +value;
      }
      return 0;

    case "boolean":
      if (
        typeof value !== "undefined" &&
        value != null &&
        (value === "Yes" ||
          value === "yes" ||
          value === "y" ||
          value === "Y" ||
          value === "true" ||
          value === "True" ||
          value === true)
      )
        return true;
      return false;

    default:
      if (typeof value === "undefined") return null;
      return value;
  }
}

export default convertValue;
