import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import clsx from "clsx";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { loadNewSyncSales } from "src/store/sale/actions";
import { setActiveDialog } from "src/store/adminHtml/actions";
import { markErrorAlertViewed } from "src/apiService";
import type {
  Alert,
  AlertEBay,
  AlertPoshmark,
} from "src/interfaces/alert.interface";
import { getEBayOAuthUrl } from "src/apiService/modules/ebay";
import { MissingExtensionError } from "src/utils/extension";
import { getPoshmarkUser } from "src/utils/extension/poshmark";
import PoshmarkConfirmDialog from "src/components/PoshmarkConfirmDialog";
import { poshmarkIntegrationCreate } from "src/apiService/modules/poshmark";
import AddPoshmarkExtensionDialog from "src/components/AddPoshmarkExtensionDialog";

import WalkThrough from "./WalkThroughNotificationsDialog";
import getAlertSelector from "./getAlertSelector";

export interface NotificationsDialogProps {
  open: boolean;
  onClose: () => void;
  items: Alert[];
}

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    selectedRow: {
      backgroundColor: "#ccccff",
    },
    notificationItem: {
      border: "1px solid #eeeeff",
      minHeight: 60,
      "&:hover": {
        backgroundColor: "#eeeeff",
        cursor: "pointer",
      },
    },
  })
);

function EBayRefreshTokenDialogContent({ integrationId }) {
  useEffect(() => {
    if (!integrationId) return;
    let cancel = false;

    (async () => {
      const params: { integrationId: string; integrationName?: string } = {
        integrationId,
      };

      if (integrationId === "ebay") params.integrationName = "eBay";
      const {
        data: { url },
      } = await getEBayOAuthUrl(params);

      if (cancel) return;

      (window.location as any) = url;
    })();

    return () => {
      cancel = true;
    };
  }, [integrationId]);

  return (
    <DialogContent>
      <Box display="flex" justifyContent="center" p={1}>
        <CircularProgress />
      </Box>
    </DialogContent>
  );
}

function EBayRefreshTokenDialog({
  integrationId,
  onClose,
}: {
  integrationId?: string;
  onClose: () => void;
}) {
  return (
    <Dialog open={!!integrationId} onClose={onClose}>
      <DialogTitle>Refresh eBay integration</DialogTitle>
      <EBayRefreshTokenDialogContent integrationId={integrationId} />
    </Dialog>
  );
}

function NotificationsDialogContent({
  onClose,
  items,
}: Omit<NotificationsDialogProps, "open">) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [ebayRefreshTokenIntegrationId, setEbayRefreshTokenIntegrationId] =
    useState<string>();
  const [poshmarkConfirmDialog, setPoshmarkConfirmDialog] =
    useState<{ jwt: string; username: string; integrationId: string }>();
  const [addPoshmarkExtensionDialogOpen, setAddPoshmarkExtensionDialogOpen] =
    useState(false);

  const handleClick = (item: Alert) => {
    switch (item.type) {
      case "plaid_error":
        dispatch(setActiveDialog("update_expense_account"));
        onClose();
        break;

      case "error":
        markErrorAlertViewed(item.id);
        onClose();
        break;

      case "returns":
        dispatch(setActiveDialog("returns_review"));
        onClose();
        break;

      case "inventory":
        dispatch(setActiveDialog("inventory_sync"));
        onClose();
        break;

      case "sales":
        dispatch(loadNewSyncSales());
        dispatch(setActiveDialog("match_sales"));
        onClose();
        break;

      case "review_sales":
        dispatch(setActiveDialog("review_sales"));
        onClose();
        break;

      case "expenses":
        dispatch(setActiveDialog("categorize_expenses"));
        onClose();
        break;

      case "cash_activities":
        dispatch(setActiveDialog("cash_activities"));
        onClose();
        break;

      case "ebay_error":
        setEbayRefreshTokenIntegrationId((item as AlertEBay).integrationId);
        break;

      case "poshmark_error":
        getPoshmarkUser()
          .then((data) => {
            setPoshmarkConfirmDialog({
              ...data,
              integrationId: (item as AlertPoshmark).integrationId,
            });
          })
          .catch((err) => {
            if (err instanceof MissingExtensionError) {
              setAddPoshmarkExtensionDialogOpen(true);
              return;
            }
            toast.error(err.message);
          });
        break;

      default:
        break;
    }
  };

  return (
    <>
      <DialogTitle className="relative">Notifications</DialogTitle>
      <DialogContent>
        {items.length ? (
          <List>
            {items.map((item) => (
              <ListItem
                key={JSON.stringify(item)}
                onClick={() => handleClick(item)}
                className={clsx(classes.notificationItem, getAlertSelector(item.type))}
              >
                {item.message}
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="h6" className="mb-4">
            No current notifications.
          </Typography>
        )}
      </DialogContent>
      <EBayRefreshTokenDialog
        integrationId={ebayRefreshTokenIntegrationId}
        onClose={() => setEbayRefreshTokenIntegrationId(undefined)}
      />
      <PoshmarkConfirmDialog
        reconnect
        username={poshmarkConfirmDialog?.username}
        onConfirm={async () => {
          if (
            poshmarkConfirmDialog?.jwt &&
            poshmarkConfirmDialog?.integrationId
          )
            await poshmarkIntegrationCreate({
              jwt: poshmarkConfirmDialog.jwt,
              integrationId: poshmarkConfirmDialog.integrationId,
              username: poshmarkConfirmDialog?.username,
            });
          setPoshmarkConfirmDialog(undefined);
        }}
        onCancel={() => setPoshmarkConfirmDialog(undefined)}
      />
      <AddPoshmarkExtensionDialog
        open={addPoshmarkExtensionDialogOpen}
        onClose={() => setAddPoshmarkExtensionDialogOpen(false)}
      />
    </>
  );
}

function NotificationsDialog({
  open,
  onClose,
  items,
}: NotificationsDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={"body"}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <NotificationsDialogContent onClose={onClose} items={items} />
      <WalkThrough alerts={items} />
    </Dialog>
  );
}

export default NotificationsDialog;
