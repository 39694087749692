import React from "react";
import Tour from "reactour";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

const Steps = [
  {
    content: (props) => (
      <>
        <DialogTitle>Bundle Sales - 1/4</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The bundle sale feature allows you to record a sale of multiple
            items.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(1)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#report-sale-bundle-page-1-content",
    content: (props) => (
      <>
        <DialogTitle>Bundle Sales - 2/4</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the details for the sale here -- all amounts should be the
            totals for the entire sale.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(0)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(2)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#report-sale-bundle-page-1-line-items",
    content: (props) => (
      <>
        <DialogTitle>Bundle Sales - 3/4</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add items from the sale by clicking “Add Item” and searching for the
            item in your inventory. If you sold multiple of an item, add a line
            for each item that sold (if you sold two, then add two line items).
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(1)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(3)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#report-sale-bundle-page-1-allocate-button",
    content: (props) => (
      <>
        <DialogTitle>Bundle Sales Overview- 4/4</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Once you’ve added all the sale details and the items sold, click
            “Allocate”.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(2)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.close()}
          >
            Done
          </Button>
        </DialogActions>
      </>
    ),
  },
];

const useStyles = makeStyles((theme) => ({
  helper: {
    maxWidth: "80%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "500px",
    },
    padding: 0,
  },
}));

export interface WalkThroughProps {
  open: boolean;
  onClose: () => void;
}

export function WalkThrough({ open, onClose }: WalkThroughProps) {
  const classes = useStyles();
  return (
    <Tour
      startAt={0}
      closeWithMask={false}
      disableFocusLock
      steps={Steps}
      isOpen={open}
      onRequestClose={() => {
        onClose();
      }}
      showButtons={false}
      showCloseButton={false}
      showNavigation={false}
      showNavigationNumber={false}
      showNumber={false}
      disableDotsNavigation
      rounded={5}
      className={classes.helper}
      disableInteraction
    />
  );
}

export default WalkThrough;
