export enum METHOD_TYPE {
  DELETE = "delete",
  UPDATE = "update",
  MOVE = "move",
  GET = "get",
  UPLOAD = "upload",
  LIST = "list",
  DELETE_ALL = "delete_all",
  CREATE = "create"
}

export enum USER_PLAN {
  BASIC = "basic",
  PREMIUM = "premium",
  ULTIMATE = "ultimate"
}
