import { createSelector } from "reselect";
import { AppState } from "..";
import {
  selectUser,
  ebayIntegrationSelector,
  userIdSelector,
} from "../system/selector";
import type {
  EBayIntegration,
  EtsyIntegration,
  PoshmarkIntegration,
  AmazonIntegration,
  MercariIntegration,
} from "src/interfaces/plaidIntegration.interface";

const getPlaidIntegrationState = (state: AppState) => state.plaidIntegration;

export const integrationsSelector = createSelector(getPlaidIntegrationState, state => state.items);

export const getPlaidIntegrations = createSelector(
  getPlaidIntegrationState,
  (state) =>
    // Really don't know what are the Bank Integrations types
    state.items.filter(
      (i) =>
        i.type !== "ebay" &&
        i.type !== "poshmark" &&
        i.type !== "mercari" &&
        i.type !== "etsy" &&
        i.type !== "amazon"
    )
);

export const getEBayIntegrations = createSelector(
  [
    getPlaidIntegrationState,
    (state) => (selectUser(state) as any)?.ebayRefreshToken,
    ebayIntegrationSelector,
    userIdSelector,
    (state) => (selectUser(state) as any)?.ebayError,
    (state) => (selectUser(state) as any)?.ebayIntegrationInventory,
    (state) => (selectUser(state) as any)?.ebayIntegrationSalesIsRunning,
    (state) => (selectUser(state) as any)?.ebayIntegrationInventoryIsRunning,
  ],
  (
    state,
    oldRefreshToken,
    ebayIntegration,
    user,
    ebayError,
    ebayIntegrationInventory,
    ebayIntegrationSalesIsRunning,
    ebayIntegrationInventoryIsRunning
  ): EBayIntegration[] => {
    const ebayIntegrations: EBayIntegration[] = [];

    if (oldRefreshToken) {
      ebayIntegrations.push({
        id: "ebay",
        sync: ebayIntegration,
        type: "ebay",
        name: "eBay",
        username: "",
        user,
        error: ebayError,
        inventory: ebayIntegrationInventory,
        salesIsRunning: ebayIntegrationSalesIsRunning,
        inventoryIsRunning: ebayIntegrationInventoryIsRunning,
      });
    }

    return [
      ...ebayIntegrations,
      ...(state.items.filter(
        (i) => i.type === "ebay"
      ) as unknown as EBayIntegration[]),
    ];
  }
);

export const getPoshmarkIntegrations = createSelector(
  getPlaidIntegrationState,
  (state) =>
    state.items.filter(
      (i) => i.type === "poshmark"
    ) as unknown as PoshmarkIntegration[]
);

export const getAmazonIntegrations = createSelector(
  getPlaidIntegrationState,
  (state) =>
    state.items.filter(
      (i) => i.type === "amazon"
    ) as unknown as AmazonIntegration[]
);

export const getMercariIntegrations = createSelector(
  getPlaidIntegrationState,
  (state) =>
    state.items.filter(
      (i) => i.type === "mercari"
    ) as unknown as MercariIntegration[]
);

export const getEtsyIntegrations = createSelector(
  getPlaidIntegrationState,
  (state) =>
    state.items.filter((i) => i.type === "etsy") as unknown as EtsyIntegration[]
);
