import React, {
  useState,
  type PropsWithChildren,
  type ChangeEvent,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import Papa from "papaparse";
import capitalize from "lodash/capitalize";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Link from "@material-ui/core/Link";
import Alert from "@material-ui/lab/Alert";

import { getVendors } from "src/store/vendor/selector";
import { setActiveDialog } from "src/store/adminHtml/actions";
import { userIdSelector } from "src/store/system/selector";
import {
  setPendingUploads,
  setUploadFilename,
  setUploadsFromEbay,
} from "src/store/sale/actions";
import { transformSale, daysOnPlatform } from "src/utils";

import {
  preprocessInput,
  checkHeaders,
  transformInput,
} from "src/utils/uploadTransform";

import Uploader from "src/components/Uploader";
import { USER_PLAN as UserPlan } from "src/enums/common.enum";
import ConditionalTooltip from "src/components/ConditionalTooltip";
import {
  userPlanSelector,
  userGetInventoryTypeSelector,
} from "src/store/system/selector";
import { processMercariFile } from "src/utils/mercari";
import { getUserId } from "src/config/storage";
import { uploadSalesCsv, uploadSalesApi } from "src/apiService/modules/sales";
import { requestNOUpdate } from "src/apiService/modules/numericOverview";

import {
  poshmarkToSales,
  rawToPoshmarkData,
  saleToUpload,
} from "src/utils/poshmark";
import type { SaleUploadRow } from "src/interfaces/sale.interface";
import { uploadSalesFile } from "src/apiService/modules/sales";

// fix-vim-highlight = }

function findVendor(vendors, vendor) {
  if (!vendors || !vendor) return;
  const name = `${vendor}`.trim();
  return vendors.find((v) => v.name === name);
}

function toDate(v: string) {
  if (!v) return null;
  const [m, d, y] = v.split("/");
  return new Date(
    `${[parseInt(y, 10) + 2000, m, d]
      .map((n) => `${n}`.padStart(2, "0"))
      .join("-")}T12:00:00.000Z`
  );
}

function mapUploaderSales(rows: any[], user: string, vendors) {
  return rows.map((row, i) => {
    const sale = {
      ...row,
      quantity: parseInt(row.quantity, 10) || 1,
      list_date: toDate(row.list_date),
      purchase_price: parseFloat(row.purchase_price),
      purchase_date: toDate(row.purchase_date),
      sale_date: toDate(row.sale_date),
      other_fees: parseFloat(row.other_fees) || 0,
      sale_price: parseFloat(row.sale_price) || 0,
      sales_tax: parseFloat(row.sales_tax) || 0,
      shipping_cost_analytics: parseFloat(row.shipping_cost_analytics) || 0,
      shipping_cost:
        parseFloat(row.shipping_costs) || parseFloat(row.shipping_cost) || 0,
      transaction_fees: parseFloat(row.transaction_fees) || 0,
      uploadIndex: i,
      uuid: uuidv4(),
      unmatched: true,
      user,
    };

    const vendor = findVendor(vendors, row.vendor);
    if (vendor) sale.vendor = vendor;

    const liableToPay = row.liable_to_pay;
    row.liable_to_pay =
      typeof liableToPay === "boolean"
        ? liableToPay
        : (liableToPay || "").toLowerCase() === "true";

    sale.days_on_platform = daysOnPlatform(sale.sale_date, sale.list_date);

    return sale;
  });
}

const useStyles = makeStyles((theme) => ({
  errorAlert: {
    marginTop: theme.spacing(2),
    whiteSpace: "pre-line",
  },
  downloadLink: {
    color: "rgb(68, 112, 147)",
    cursor: "pointer",
    display: "block",
    fontSize: "18px",
    margin: "0 auto",
  },
}));

export function UploadSalesReportDialog({
  title,
  children,
  onBack,
  onSubmit,
  next = "Next",
  back = "Go Back",
}: PropsWithChildren<{
  title?: string;
  onBack: () => void;
  onSubmit: (data: string, file: File) => Promise<void>;
  back?: string;
  next?: string;
}>) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
    setError("");
    const input: HTMLInputElement = e.target;
    if (input.files && input.files.length) {
      setFile(input.files[0]);
    }
  };

  const handleSubmit = async () => {
    if (!file) return;
    setLoading(true);
    try {
      const data = await new Promise<string>((rs) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.addEventListener("load", (e) => {
          rs((reader.result as string) || "");
        });
      });
      await onSubmit(data, file);
    } catch (e) {
      console.error(e);
      setError((e as Error).message || (e as Error).toString());
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {title ? <DialogTitle>Upload Sales Report</DialogTitle> : null}
      <DialogContent>
        {children}
        <label className={classes.downloadLink}>
          Click to Upload a File
          <input
            accept="text/csv"
            style={{ display: "none" }}
            type="file"
            onChange={handleFile}
            disabled={loading}
          />
        </label>
        {file ? (
          <Typography variant="body1">Selected file: {file.name}</Typography>
        ) : null}
        {error ? (
          <Alert className={classes.errorAlert} severity="error">
            {error}
          </Alert>
        ) : null}
      </DialogContent>
      <DialogActions className="px-7 py-5">
        <Button color="primary" onClick={onBack} disabled={loading}>
          {back}
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!file || !!error || loading}
          onClick={handleSubmit}
        >
          {next}
        </Button>
      </DialogActions>
    </>
  );
}

async function handleMatchingPoshmarkMercariSubmit(
  vendors: { name: string }[],
  fileType: "poshmark" | "mercari",
  csvString: string,
  file: File
): Promise<any[]> {
  let newSales: SaleUploadRow[] = [];
  if (fileType === "poshmark") {
    const data = rawToPoshmarkData(csvString);
    if (!data.length) throw new Error("The uploaded file is empty");
    newSales = (await poshmarkToSales(data)).map(saleToUpload);
  } else {
    const splitData = csvString.split("\n");
    const rawRows = preprocessInput("mercariSales", splitData);
    const trimmedString = rawRows.join("\n");
    const parseResults = Papa.parse(trimmedString, { header: true });
    const parsedData = parseResults.data;

    if (!parsedData.length) throw new Error("The uploaded file is empty");
    const missingHeaders: string[] = [];
    if (
      !checkHeaders("mercariSales", Object.keys(parsedData[0]), missingHeaders)
    ) {
      throw new Error(
        `Looks like you didn’t upload a ${capitalize(
          fileType
        )} sales report. The file is missing the following headers: ${missingHeaders.join(
          ", "
        )}`
      );
    }

    newSales = transformInput("mercariSales", parsedData, false).map((s) =>
      transformSale(s)
    );
  }
  const user = await getUserId();
  const mappedSales = newSales.map((s, i) => {
    const sale = {
      ...s,
      unmatched: true,
      user,
      uploadIndex: i,
      uuid: uuidv4(),
    };

    const vendorName = (s as any).Vendor || s.vendor;
    if (vendorName) {
      const vendor = findVendor(vendors, vendorName);
      if (vendor) sale.vendor = vendor.id;
    }

    return sale;
  });

  return mappedSales;
}

function PoshmarkUploadSalesReportDialog({
  onBack,
  match,
}: {
  onBack: () => void;
  match: boolean;
}) {
  const vendors = useSelector(getVendors);
  const dispatch = useDispatch();

  const handleSubmit = async (csvString: string, file: File) => {
    if (match) {
      const mappedSales = await handleMatchingPoshmarkMercariSubmit(
        vendors,
        "poshmark",
        csvString,
        file
      );

      dispatch(setPendingUploads(mappedSales));
      dispatch(setUploadsFromEbay(false));
      dispatch(setUploadFilename(file.name));
      dispatch(setActiveDialog("match_sales"));
    } else {
      const data = rawToPoshmarkData(csvString);
      if (!data.length) throw new Error("The uploaded file is empty");
      const sales = await poshmarkToSales(data);
      const uploadSales = sales.map(saleToUpload);
      const ret = await uploadSalesFile(uploadSales, { filename: file.name });
      if (ret.errors)
        throw new Error(
          ret.errors.map((e) => `Line ${e.index + 1}: ${e.message}.`).join("\n")
        );
      await requestNOUpdate();
      dispatch(setActiveDialog(""));
      toast.success("Sales uploaded successfully.");
    }
  };

  return (
    <UploadSalesReportDialog
      title="Upload Sales Report"
      onSubmit={handleSubmit}
      onBack={onBack}
    >
      <DialogContentText>
        Upload your Poshmark sales report here. If you need to upload a
        different report, click the “Go Back” button.
      </DialogContentText>
    </UploadSalesReportDialog>
  );
}

function MercariUploadSalesReportDialog({
  onBack,
  match,
}: {
  onBack: () => void;
  match: boolean;
}) {
  const vendors = useSelector(getVendors);
  const dispatch = useDispatch();

  const handleSubmit = async (csvString: string, file: File) => {
    if (match) {
      const mappedSales = await handleMatchingPoshmarkMercariSubmit(
        vendors,
        "mercari",
        csvString,
        file
      );

      dispatch(setPendingUploads(mappedSales));
      dispatch(setUploadsFromEbay(false));
      dispatch(setUploadFilename(file.name));
      dispatch(setActiveDialog("match_sales"));
    } else {
      const processed = processMercariFile(csvString);
      if ("error" in processed) throw new Error(processed.error);

      const userId = await getUserId();
      const { url } = await uploadSalesCsv(processed.result as Blob, userId);
      await uploadSalesApi(url, (file as File).name);
      await requestNOUpdate();
      dispatch(setActiveDialog(""));
    }
  };

  return (
    <UploadSalesReportDialog
      title="Upload Sales Report"
      onSubmit={handleSubmit}
      onBack={onBack}
    >
      <DialogContentText>
        You can upload sales reports from Mercari.{" "}
        <Link
          href="https://www.youtube.com/watch?v=p590Q-oaRCs"
          target="_blank"
          rel="noopener noreferrer"
        >
          Download a sales report from Mercari
        </Link>
        , attach it below, and click “Next”. If you need to upload a different
        report, click the “Go Back” button.
      </DialogContentText>
    </UploadSalesReportDialog>
  );
}

interface SelectUploadTypeDialogProps {
  onNext: (match: boolean) => void;
  onBack: () => void;
}

function SelectUploadTypeDialog({
  onBack,
  onNext,
}: SelectUploadTypeDialogProps) {
  const classes = useStylesSelectTypeDialog();
  const [value, setValue] = useState<"match" | "no-match">();
  const plan = useSelector(userPlanSelector);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    if (value === "match" || value === "no-match") setValue(value);
  };
  const notUltimate = plan !== UserPlan.ULTIMATE;

  return (
    <>
      <DialogTitle>Upload Type</DialogTitle>
      <DialogContent>
        <Box m={1} mb={0}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Would you like to match these sales to items in inventory or
              upload the sales straight into your sales detail?
            </FormLabel>
            <RadioGroup
              onChange={handleChange}
              value={value || ""}
              className={classes.radioGroup}
            >
              <ConditionalTooltip
                condition={notUltimate}
                title="This option is only available on the Ultimate plan. To upgrade, you can click the  “Upgrade” button in the lower left of the screen."
              >
                <FormControlLabel
                  value="match"
                  control={<Radio />}
                  label="Match sales to inventory"
                  disabled={notUltimate}
                />
              </ConditionalTooltip>
              <FormControlLabel
                value="no-match"
                control={<Radio />}
                label="Upload without matching to inventory"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions className="px-7 py-5">
        <Button color="primary" onClick={onBack}>
          Go Back
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!value}
          onClick={() => {
            if (value) onNext(value === "match");
          }}
        >
          Next
        </Button>
      </DialogActions>
    </>
  );
}

interface SelectReportTypeDialogProps {
  onBack: () => void;
  onNext: (response: "mercari" | "usecsv" | "poshmark") => void;
  match: boolean;
}

const useStylesSelectTypeDialog = makeStyles((theme) => ({
  radioGroup: {
    margin: theme.spacing(1, 0, 0, 4),
  },
}));

function SelectReportTypeDialog({
  onBack,
  onNext,
  match,
}: SelectReportTypeDialogProps) {
  const classes = useStylesSelectTypeDialog();
  const [value, setValue] = useState<"mercari" | "usecsv" | "poshmark">();
  const dispatch = useDispatch();
  const vendors = useSelector(getVendors);
  const userId = useSelector(userIdSelector);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    if (value === "mercari" || value === "usecsv" || value === "poshmark")
      setValue(value);
  };

  return (
    <>
      <DialogTitle>Report Type</DialogTitle>
      <DialogContent>
        <Box m={1} mb={0}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Which kind of report are you uploading?
            </FormLabel>
            <RadioGroup
              onChange={handleChange}
              value={value || ""}
              className={classes.radioGroup}
            >
              <FormControlLabel
                value="poshmark"
                control={<Radio />}
                label="Poshmark"
              />
              <FormControlLabel
                value="mercari"
                control={<Radio />}
                label="Mercari"
              />
              <FormControlLabel
                value="usecsv"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions className="px-7 py-5">
        <Button color="primary" onClick={onBack}>
          Go Back
        </Button>
        {value === "usecsv" ? (
          <Uploader
            importerType="sales"
            onClose={() => {
              onBack();
            }}
            {...(match
              ? {
                  uploader: async (type, rows, metadata) => {
                    const mappedSales = mapUploaderSales(rows, userId, vendors);

                    dispatch(setPendingUploads(mappedSales));
                    dispatch(setUploadsFromEbay(false));
                    dispatch(setUploadFilename(metadata.fileName));
                    dispatch(setActiveDialog("match_sales"));

                    // XXX: Ugly hack to close useCSV
                    for (const c of document.querySelectorAll(
                      ".usecsv_container"
                    )) {
                      c.parentNode?.removeChild(c);
                    }

                    return { success: true };
                  },
                }
              : {
                  onClose: () => {
                    dispatch(setActiveDialog(""));
                  },
                })}
          />
        ) : (
          <Button
            color="primary"
            variant="contained"
            disabled={!value}
            onClick={() => {
              if (value) onNext(value);
            }}
          >
            Next
          </Button>
        )}
      </DialogActions>
    </>
  );
}

const Initial = { step: "upload-type" as const };

function UploadMatchedSalesReportDialog({ onBack }) {
  const inventoryTypeIsCash =
    useSelector(userGetInventoryTypeSelector) === "cash";
  const [state, setState] = useState<{
    step: "upload-type" | "report-type" | "mercari" | "usecsv" | "poshmark";
    match?: boolean;
  }>(Initial);
  const step =
    inventoryTypeIsCash && state.step === "upload-type"
      ? "report-type"
      : state.step;
  const match = inventoryTypeIsCash ? false : !!state.match;
  const handleReturn = () => {
    onBack();
    setState(Initial);
  };

  switch (step) {
    case "poshmark":
      return (
        <PoshmarkUploadSalesReportDialog
          match={match}
          onBack={() => {
            setState((s) => ({ ...s, step: "report-type" }));
          }}
        />
      );

    case "mercari":
      return (
        <MercariUploadSalesReportDialog
          match={match}
          onBack={() => {
            setState((s) => ({ ...s, step: "report-type" }));
          }}
        />
      );

    case "report-type":
    case "usecsv":
      return (
        <SelectReportTypeDialog
          match={match}
          onBack={() => {
            if (inventoryTypeIsCash) {
              handleReturn();
            } else {
              setState((s) => ({ ...s, step: "upload-type" }));
            }
          }}
          onNext={(step) => {
            setState((s) => ({ ...s, step }));
          }}
        />
      );

    default:
      return (
        <SelectUploadTypeDialog
          onBack={() => {
            handleReturn();
          }}
          onNext={(match) => {
            setState((s) => ({ ...s, step: "report-type", match }));
          }}
        />
      );
  }
}

export default UploadMatchedSalesReportDialog;
