import firebase from "firebase/app";
import "firebase/functions";

import firestore from "src/apiService/firestore";
import { getUserId } from "src/config/storage";
import { USER_PLAN as UserPlan } from "src/enums/common.enum";

export async function exchangeAmazonOAuthCode({
  code,
  integrationId,
}: {
  code: string;
  integrationId?: string;
}) {
  const f = firebase
    .functions()
    .httpsCallable("amazon-exchangeAmazonOAuthCode");

  return await f({ code, integrationId });
}

export async function amazonIntegrationDelete(id: string) {
  const db = firestore();

  const ref = db.collection("Plaid_Integrations").doc(id);
  const doc = await ref.get();
  if (doc.exists) {
    await ref.delete();
  }
}

export async function amazonSyncApi(integrationId: string) {
  const f = firebase.functions().httpsCallable("amazon-syncNow");
  const result = await f({ integrationId });
  return result.data;
}

export async function getAmazonOAuthUrl(arg?: { back?: string }) {
  const f = firebase.functions().httpsCallable("amazon-getAmazonAuthUrl");
  return await f(arg);
}

export async function amazonDirectImportInventory(integrationId: string) {
  const f = firebase.functions().httpsCallable("amazon-directImportInventory");
  const result = await f({ integrationId });
  return result.data;
}

export async function amazonIntegrationSyncChange(
  id: string,
  sync: boolean,
  inventory: boolean = true
) {
  const userId = await getUserId();
  const db = firestore();

  await db.runTransaction(async (transaction) => {
    const user = await transaction.get(db.collection("Users").doc(userId));
    await transaction.update(db.collection("Plaid_Integrations").doc(id), {
      sync: sync && user.data()?.plan === UserPlan.ULTIMATE,
      inventory: sync && inventory,
    });
  });
}
