import React, { useState } from "react";
import Tour from "reactour";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import { userDontShowDialogsSelector } from "src/store/system/selector";
import DontShowThisMessageAgainCheckbox from "src/components/DontShowThisMessageAgainCheckbox";

const Key = "walk_through_review_refunds";

const Steps = [
  {
    content: (props) => (
      <>
        <DialogTitle>Review Refunds - 1/4</DialogTitle>
        <DialogContent>
          <DialogContentText>
            When a new refund is pulled from a sale platform, you’ll review it
            on this screen. This could include a partial refund, a return, or a
            cancelled sale.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(1)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#review-returns-dialog-updated-sale-question",
    content: (props) => (
      <>
        <DialogTitle>Review Refunds - 2/4</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This question is most important if you use the accrual inventory
            method. If you track item cost and purchase date, make sure that
            your sales have been matched to inventory and have the purchase
            price and purchase date attached.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(0)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(2)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#review-returns-dialog-received-item-question",
    content: (props) => (
      <>
        <DialogTitle>Review Refunds - 3/4</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you don’t track inventory, you should always mark this as “No”.
            If you do track inventory, then only mark as “Yes” if the item was
            returned in a sellable condition.
          </DialogContentText>
          <DialogContentText>
            If you mark yes, the item will be added back to inventory.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(1)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(3)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#review-returns-dialog-confirm-button",
    content: (props) => (
      <>
        <DialogTitle>Review Refunds - 4/4</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Once you’ve answered the above questions, click “Confirm” to
            finalize the refund.
          </DialogContentText>
          <DontShowThisMessageAgainCheckbox dialog={Key} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(2)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.close()}
          >
            Done
          </Button>
        </DialogActions>
      </>
    ),
  },
];

const useStyles = makeStyles((theme) => ({
  helper: {
    maxWidth: "80%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "500px",
    },
    padding: 0,
  },
}));

export function WalkThrough() {
  const [open, setOpen] = useState(true);
  const userDontShowDialogs = useSelector(userDontShowDialogsSelector);

  const classes = useStyles();
  const isOpen =
    open && userDontShowDialogs && !userDontShowDialogs?.includes(Key);

  return (
    <Tour
      startAt={0}
      closeWithMask={false}
      disableFocusLock
      steps={Steps}
      isOpen={isOpen}
      onRequestClose={() => {
        setOpen(false);
      }}
      showButtons={false}
      showCloseButton={false}
      showNavigation={false}
      showNavigationNumber={false}
      showNumber={false}
      disableDotsNavigation
      rounded={5}
      className={classes.helper}
      disableInteraction
    />
  );
}

export default WalkThrough;
