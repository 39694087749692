import React, { useRef, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";

import { ImportDialogContent } from "./ImportDialog";
import { getPoshmarkIntegrations } from "src/store/plaidIntegration/selector";
import { userGetInventoryTypeSelector } from "src/store/system/selector";
import { getUploads } from "src/store/uploads/selector";
import { usePreventImportPoshmark } from "./usePreventImportPoshmark";
import {
  poshmarkSyncInventory,
  poshmarkSyncSales,
} from "src/store/integrationSync/actions/poshmark";

import DialogTitle from "./DialogTitle";
import type Step from "./Step";

interface PoshmarkImportProps {
  onClose: () => void;
  onStepChange: (step: Step) => void;
}

function getHasAlreadyImported(
  integration,
  uploads,
  key: "poshmarkInventory" | "poshmarkSales"
) {
  const isRunningKey =
    key === "poshmarkInventory" ? "inventoryIsRunning" : "salesIsRunning";
  if (integration?.[isRunningKey]) return true;
  const t = key === "poshmarkInventory" ? "inventory" : "sales";
  return uploads.some((upload) => {
    if (integration?.id && upload.integrationId) {
      if (integration.id !== upload.integrationId) return false;
    }

    return t === upload.type && upload.filename?.includes(key);
  });
}

export function useIntegration() {
  const integration = useSelector((s) => {
    const integrations = getPoshmarkIntegrations(s as any);
    if (integrations) {
      let integration = integrations[0];
      for (const i of integrations) {
        if ((i.createdAt || 0) > (integration.createdAt || 0)) integration = i;
      }
      return integration;
    }
  });
  const integrationId = integration?.id;
  const uploads = useSelector(getUploads);
  const loading = integration?.loading;
  const hasAlreadyImportedInventory =
    getHasAlreadyImported(integration, uploads, "poshmarkInventory") ||
    loading === "Inventory";
  const hasAlreadyImportedSales =
    getHasAlreadyImported(integration, uploads, "poshmarkSales") ||
    loading === "Sales";

  return {
    integrationId,
    hasAlreadyImportedInventory,
    hasAlreadyImportedSales,
  };
}

function PoshmarkImport({ onClose, onStepChange }: PoshmarkImportProps) {
  const dispatch = useDispatch();
  const {
    integrationId,
    hasAlreadyImportedInventory,
    hasAlreadyImportedSales,
  } = useIntegration();
  const inventoryType = useSelector(userGetInventoryTypeSelector);
  const onCloseRef = useRef(onClose);
  onCloseRef.current = onClose;

  const dialogTitle = useMemo(() => {
    return (props) => (
      <DialogTitle onClose={() => onCloseRef.current()} {...props} />
    );
  }, []);
  const [memory] = useState(() => ({
    integrationId,
    inventoryType,
    hasAlreadyImportedInventory,
    hasAlreadyImportedSales,
  }));

  return (
    <>
      <ImportDialogContent
        DialogTitle={dialogTitle}
        onClose={() => {
          onStepChange("poshmark:import-in-process");
        }}
        providerName="Poshmark"
        integrationId={memory.integrationId || ""}
        actions={
          <Button
            color="primary"
            onClick={() => onStepChange("mercari:do-you-sell-on-mercari")}
          >
            Continue Set-up
          </Button>
        }
        disableInventory={
          memory.inventoryType === "cash" || memory.hasAlreadyImportedInventory
        }
        disableSales={memory.hasAlreadyImportedSales}
        fetchListings={async (integrationId) => {
          await dispatch(poshmarkSyncInventory(integrationId));
          return { success: true };
        }}
        fetchTransactions={async (integrationId, start, end) => {
          await dispatch(poshmarkSyncSales(integrationId, { start, end }));
          return { success: true };
        }}
        usePreventImport={usePreventImportPoshmark}
      />
    </>
  );
}

export default PoshmarkImport;
