import { webRequest, getExtensionId } from "src/utils/extension";

export interface FetchOptions {
  retries?: number;
  wait?: number;
}

class ServiceClient {
  extensionId: string;

  constructor() {
    this.extensionId = getExtensionId();
  }

  /*
   * { status, statusText, headers, data }
   */
  async fetch(
    url: string,
    opts: RequestInit = {},
    { retries = 5, wait = 500 }: FetchOptions = {}
  ) {
    const res = await webRequest(this.extensionId, {
      url,
      init: {
        method: "GET",
        credentials: "include",
        ...opts,
      },
    });

    if (!res) {
      if (retries <= 0) throw new Error(`Undefined res: '${url}'`);
      // Something failed in the extension, just re-try it
      await new Promise((rs) => setTimeout(rs, wait));
      return await this.fetch(url, opts, {
        retries: retries - 1,
        wait: wait * 2,
      });
    }

    return {
      ...res.result,
      ok: 200 <= res.result.status && res.result.status < 300,
    };
  }
}

export default ServiceClient;
