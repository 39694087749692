import React, { type ComponentType } from "react";
import { useSelector } from "react-redux";
import { featureFlagSelector } from "src/store/system/selector";
import type { FeatureFlag } from "src/interfaces/systemState.interface";

// fix-vim-highlight = }

type Decorator<T extends {}> = (Component: ComponentType<T>) => ComponentType<T>;

function withFeatureFlag<T extends {}>(key: FeatureFlag): Decorator<T> {
  return (Component: ComponentType<T>) => {
    function HighOrderComponent(props: T) {
      const flag = useSelector((state) => featureFlagSelector(state, key));
      if (flag) return <Component {...props} />;
      return null;
    }

    HighOrderComponent.displayName = `WithFeatureFlag(flag: ${key})(${
      Component.displayName || Component.name || "Component"
    })`;

    return HighOrderComponent;
  };
}

export default withFeatureFlag;
