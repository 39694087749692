import { createAction } from "redux-actions";
import { 
  TRANSACTION,
  START,
  FETCH_ITEMS,
  DELETE_ITEM,
  SELECT_ITEMS,
  SET_FILTER,
  SET_PAGE_SIZE,
  RESET,
  GL_ACCOUNTS,
  FS_CREATE_ITEMS,
  FS_DELETE_ITEMS,
  FS_UPDATE_ITEMS,
} from "../common";

export const fetchItems = createAction(TRANSACTION + FETCH_ITEMS + START);
export const fetchGlAccounts = createAction(GL_ACCOUNTS + FETCH_ITEMS + START);

export const deleteItem = createAction(TRANSACTION + DELETE_ITEM + START);

export const selectItems = createAction(TRANSACTION + SELECT_ITEMS);
export const setFilter = createAction(TRANSACTION + SET_FILTER);
export const setPageSize = createAction(TRANSACTION + SET_PAGE_SIZE);
export const reset = createAction(TRANSACTION + RESET);

export const addTransactionRecords = createAction(TRANSACTION + FS_CREATE_ITEMS);
export const updateTransactionRecords = createAction(TRANSACTION + FS_UPDATE_ITEMS);
export const deleteTransactionRecords = createAction(TRANSACTION + FS_DELETE_ITEMS);
