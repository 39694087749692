import React from "react";
import { useSelector } from "react-redux";

import { userPlanSelector } from "src/store/system/selector";
import Wrapper from "src/components/Wrapper";

import { GraphContent } from "./GraphContent";
import { TableContent } from "./TableContent";

const SalesPage = () => {
  const plan = useSelector(userPlanSelector);

  return (
    <Wrapper>
      {(plan === "premium" || plan === "ultimate") && <GraphContent />}
      <TableContent />
    </Wrapper>
  );
};

export default SalesPage;
