import React, { useState } from "react";

import {
  Button,
  TextField,
  Grid,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  makeStyles,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Autocomplete from "@material-ui/lab/Autocomplete";

import DeleteIcon from "@material-ui/icons/Delete";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import InfoTooltip from "src/components/InfoTooltip";
import NumberField from "src/components/NumberField";
import SalePlatformField from "src/components/SalePlatformField";
import GoldIconButton from "src/components/GoldIconButton";

import WalkThrough from "./BundleSalePage1WalkThrough";
import ReportSaleItemSoldInput from "./ReportSaleItemSoldInput";

const useStyles = makeStyles({
  itemsHeader: {
    fontSize: 16,
    fontWeight: 500,
    marginTop: 20,
    marginBottom: 20,
  },
  noItems: {
    marginBottom: 20,
  },
});

const ReportBundleDialog = ({
  errors,
  handleChange,
  values,
  setValues,
  loading,
  inventories,
  saleStates,
  renderOption,
  allocationValues,
  setAllocationValues,
  allocationsInitialized,
  setAllocationsInitialized,
  addItemToBundle,
  removeItemFromBundle,
  setHasChanged,
  goToRoot,
  goToAllocations,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const availableInventories = (currentValue) => {
    const summedAllocations = allocationValues.reduce((acc, allocation) => {
      if (allocation?.inventory?.id) {
        const id = allocation?.inventory?.id;
        if (!acc[id]) acc[id] = 0;
        acc[id] = acc[id] + 1;
      }

      return acc;
    }, {});

    return inventories.filter((item) => {
      if (item.id === currentValue?.inventory?.id) return true;
      const id = item.id;
      if (id) {
        const quantity = item.quantity || 1;
        if (!summedAllocations[id]) return true;
        return summedAllocations[id] < quantity;
      }
      return true;
    });
  };

  const setAllocation = (index: number, inventory: any) => {
    const newValues = [...allocationValues];
    if (inventory) {
      newValues[index] = {
        ...newValues[index],
        item_title: inventory.item_title,
        purchase_price:
          Math.round(
            (inventory.purchase_price / (inventory.quantity || 1)) * 100
          ) / 100,
        inventory,
      };
    } else {
      newValues.splice(index, 1);
    }
    setAllocationValues(newValues);
    setAllocationsInitialized(false);
  };

  const renderItemTitleRow = (currentValue, index) => {
    const options = availableInventories(currentValue);
    return (
      <Box className="flex" key={`${currentValue?.inventory}-${index}`}>
        <ReportSaleItemSoldInput
          fullWidth
          inventories={options}
          values={{
            inventory: currentValue?.inventory || null,
          }}
          setValues={(f) => {
            const inventory = f({}).inventory;
            setAllocation(index, {
              ...inventory,
              purchase_price: inventory.purchase_price_total,
            });
          }}
        />
        <IconButton onClick={() => removeItemFromBundle(index)}>
          <DeleteIcon />
        </IconButton>
      </Box>
    );
  };

  return (
    <>
      <WalkThrough open={open} onClose={() => setOpen(false)} />
      <DialogTitle>Report Bundle Sale</DialogTitle>
      <DialogContent>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={2} id="report-sale-bundle-page-1-content">
            <Grid item xs={12} sm={6} className="flex justify-between">
              <InfoTooltip
                text="The total revenue collected from a customer. This includes the price of the item and additional money collected for shipping. This does not include sales tax."
                size={18}
              />
              <NumberField
                error={errors.sale_price ? true : false}
                helperText={errors.sale_price}
                className="w-11/12"
                variant="outlined"
                margin="dense"
                label="Sale Price"
                required
                value={values.sale_price}
                disabled={loading}
                onChange={(e) => {
                  setAllocationsInitialized(false);
                  handleChange("sale_price")(e);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6} className="flex justify-between">
              <InfoTooltip
                text="The amount you paid to ship the sale. This amount will be included in the P&L and Management Dashboard. If your shipping is accounted for in the expense detail, don’t add the cost here as well – this will result in duplicate shipping expenses."
                size={18}
              />
              <NumberField
                error={errors.shipping_cost ? true : false}
                helperText={errors.shipping_cost}
                className="w-11/12"
                variant="outlined"
                margin="dense"
                label="Shipping Cost"
                value={values.shipping_cost}
                disabled={loading}
                onChange={(e) => {
                  setAllocationsInitialized(false);
                  handleChange("shipping_cost")(e);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="flex justify-between">
              <InfoTooltip
                text="The amount the platform charged you to sell the item."
                size={18}
              />
              <NumberField
                error={errors.transaction_fees ? true : false}
                helperText={errors.transaction_fees}
                className="w-11/12"
                variant="outlined"
                margin="dense"
                label="Transaction Fees"
                value={values.transaction_fees}
                disabled={loading}
                onChange={(e) => {
                  setAllocationsInitialized(false);
                  handleChange("transaction_fees")(e);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="flex justify-between">
              <InfoTooltip text="The sales tax paid by the buyer." size={18} />
              <NumberField
                error={errors.sales_tax ? true : false}
                helperText={errors.sales_tax}
                className="w-11/12"
                variant="outlined"
                margin="dense"
                label="Sales Tax"
                value={values.sales_tax}
                disabled={loading}
                onChange={handleChange("sales_tax")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="flex justify-end">
              <Autocomplete
                freeSolo
                options={saleStates}
                value={values.sale_state}
                onChange={(_e, value) => {
                  setValues({ ...values, sale_state: value || "" });
                }}
                className="w-11/12"
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onInput={(e: any) => {
                      let value = e.target?.value;
                      let res = value ? value.toUpperCase().substr(0, 2) : "";
                      setValues({ ...values, sale_state: res });
                      e.target.value = res;
                    }}
                    margin="dense"
                    label="Sale State"
                    className="w-full"
                    variant="outlined"
                  />
                )}
                renderOption={renderOption}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="flex justify-between">
              <InfoTooltip
                text="If you aren’t responsible for filing sales tax, mark this field as ‘No’. If you are responsible for filing sales tax, mark this field as ‘Yes’."
                size={18}
              />
              <Autocomplete
                options={["No", "Yes"]}
                value={values.liable_to_pay ? "Yes" : "No"}
                autoHighlight
                className="w-11/12"
                onChange={(_e, value) => {
                  setValues({
                    ...values,
                    liable_to_pay: value === "Yes" ? true : false,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={errors.liable_to_pay ? true : false}
                    helperText={errors.liable_to_pay}
                    margin="dense"
                    required
                    label="Liable to Pay"
                    className="w-full"
                    variant="outlined"
                  />
                )}
                renderOption={renderOption}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="flex justify-between">
              <InfoTooltip
                text={`Record the platform where you sold this item. If the platform doesn't appear in the dropdown, you can add platforms in your profile settings or by clicking "Add New Platform" in the "Sale Platform" dropdown."`}
                size={18}
              />
              <SalePlatformField
                value={values.sale_platform}
                onChange={(value) => {
                  setValues((values) => ({
                    ...values,
                    sale_platform: value || "",
                  }));
                }}
                margin="dense"
                error={!!errors.sale_platform}
                helperText={errors.sale_platform}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="flex justify-between">
              <InfoTooltip
                text="The date that you sold these items."
                size={18}
              />
              <KeyboardDatePicker
                disableToolbar
                className="w-11/12"
                format="MM/dd/yyyy"
                margin="dense"
                inputVariant="outlined"
                label="Sale Date"
                autoOk={true}
                required
                error={!!errors.sale_date}
                helperText={errors.sale_date}
                value={values.sale_date || null}
                disabled={loading}
                onChange={(e: any, t: any) => {
                  setHasChanged(true);
                  setValues({ ...values, sale_date: new Date(e) });
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
        <div id="report-sale-bundle-page-1-line-items">
          <Typography
            variant="h3"
            className={`${classes.itemsHeader} text-blue`}
          >
            Line Items
          </Typography>
          {!allocationValues || allocationValues.length <= 0 ? (
            <Typography className={classes.noItems}>
              (Add items to continue)
            </Typography>
          ) : (
            allocationValues.map(renderItemTitleRow)
          )}
          <Button
            onClick={addItemToBundle}
            color="primary"
            variant="contained"
            disabled={loading}
          >
            Add Item
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        <Box mt={-1.5}>
          <Tooltip title="Need help? Click here.">
            <GoldIconButton onClick={() => setOpen(true)} />
          </Tooltip>
        </Box>
        <Box flex={1} />
        <Button onClick={goToRoot} color="secondary" variant="contained">
          Back to Single Sale
        </Button>
        <Button
          onClick={goToAllocations}
          color="primary"
          variant="contained"
          disabled={!allocationValues.length || !allocationValues[0].item_title}
          id="report-sale-bundle-page-1-allocate-button"
        >
          Allocate
        </Button>
      </DialogActions>
    </>
  );
};

export default ReportBundleDialog;
