import React, { useState } from "react";

import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";

import { colors } from "src/theme";
import { formatDollars } from "src/utils/formatter";
import NumberField from "src/components/NumberField";
import GoldIconButton from "src/components/GoldIconButton";

import getAllocations from "./getAllocations";
import WalkThrough from "./BundleSalePage2WalkThrough";

const useStyles = makeStyles({
  header: {
    fontWeight: 500,
    color: "rgba(24, 68, 97, 1)",
  },
  row: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  totalLabel: {
    fontWeight: 600,
  },
  match: {
    fontWeight: 600,
    color: colors.greenColor,
  },
  nomatch: {
    fontWeight: 600,
    color: colors.errorColor,
  },
});

const BundleAllocationsDialog = ({
  allocationValues,
  setAllocationValues,
  bundleValues,
  loading,
  goToBundle,
  handleSave,
  values,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleChangeItem = (index: number, field: string) => (e: any) => {
    const value = e.target.value !== "" ? parseFloat(e.target.value) : 0;
    if (isNaN(value)) return;
    const newValues = [...allocationValues];
    newValues[index] = { ...newValues[index], [field]: value };
    if (field === "purchase_price") {
      setAllocationValues(getAllocations(values, newValues));
    } else {
      setAllocationValues(newValues);
    }
  };

  const allocationTotals = React.useMemo(() => {
    let sale_price = 0;
    let transaction_fees = 0;
    let shipping_cost = 0;
    allocationValues.forEach((item) => {
      sale_price += +item.sale_price || 0;
      transaction_fees += +item.transaction_fees || 0;
      shipping_cost += +item.shipping_cost || 0;
    });
    return { sale_price, transaction_fees, shipping_cost };
  }, [allocationValues]);

  const renderAllocationRow = (item, index) => (
    <Box className={classes.row} key={index}>
      <Box className="w-1/3 mr-2">
        <Typography>{item.item_title}</Typography>
      </Box>
      <Box className="w-1/6 mr-2 report-sale-bundle-page-2-table-value report-sale-bundle-page-2-purchase-price-field">
        <NumberField
          variant="outlined"
          margin="dense"
          label="Purchase Price"
          required
          value={item.purchase_price}
          disabled={loading}
          onChange={handleChangeItem(index, "purchase_price")}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          {...(index === 0 ? { autoFocus: true } : {})}
        />
      </Box>
      <Box className="w-1/6 mr-2 report-sale-bundle-page-2-table-value">
        <NumberField
          // error={errors.sale_price ? true : false}
          // helperText={errors.sale_price}
          variant="outlined"
          margin="dense"
          label="Sale Price"
          required
          value={item.sale_price}
          disabled={loading}
          onChange={handleChangeItem(index, "sale_price")}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Box>
      <Box className="w-1/6 mr-2 report-sale-bundle-page-2-table-value">
        <NumberField
          // error={errors.sale_price ? true : false}
          // helperText={errors.sale_price}
          variant="outlined"
          margin="dense"
          label="Transaction Fees"
          required
          value={item.transaction_fees}
          disabled={loading}
          onChange={handleChangeItem(index, "transaction_fees")}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Box>
      <Box className="w-1/6 mr-2 report-sale-bundle-page-2-table-value">
        <NumberField
          // error={errors.sale_price ? true : false}
          // helperText={errors.sale_price}
          variant="outlined"
          margin="dense"
          label="Shipping Cost"
          required
          value={item.shipping_cost}
          disabled={loading}
          onChange={handleChangeItem(index, "shipping_cost")}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Box>
    </Box>
  );

  const renderTotalsRow = () => (
    <Box className={classes.row}>
      <Box className="w-1/3 mr-2">
        <Typography className={classes.totalLabel}>
          Allocation Totals
        </Typography>
      </Box>
      <Box className="w-1/6 mr-2" />
      <Box className="w-1/6 mr-2">
        <Typography
          className={
            Math.round(+allocationTotals.sale_price * 100) / 100 ===
            Math.round(+(bundleValues.sale_price || 0) * 100) / 100
              ? classes.match
              : classes.nomatch
          }
        >
          {formatDollars(allocationTotals.sale_price)} /{" "}
          {formatDollars(bundleValues.sale_price || 0)}
        </Typography>
      </Box>
      <Box className="w-1/6 mr-2">
        <Typography
          className={
            Math.round(+allocationTotals.transaction_fees * 100) / 100 ===
            Math.round(+(bundleValues.transaction_fees || 0) * 100) / 100
              ? classes.match
              : classes.nomatch
          }
        >
          {formatDollars(allocationTotals.transaction_fees)} /{" "}
          {formatDollars(bundleValues.transaction_fees || 0)}
        </Typography>
      </Box>
      <Box className="w-1/6 mr-2">
        <Typography
          className={
            Math.round(+allocationTotals.shipping_cost * 100) / 100 ===
            Math.round(+(bundleValues.shipping_cost || 0) * 100) / 100
              ? classes.match
              : classes.nomatch
          }
        >
          {formatDollars(allocationTotals.shipping_cost)} /{" "}
          {formatDollars(bundleValues.shipping_cost || 0)}
        </Typography>
      </Box>
    </Box>
  );

  let allocationsComplete = true;
  if (
    Math.round(+allocationTotals.sale_price * 100) / 100 !==
      Math.round(+(bundleValues.sale_price || 0) * 100) / 100 ||
    Math.round(+allocationTotals.transaction_fees * 100) / 100 !==
      Math.round(+(bundleValues.transaction_fees || 0) * 100) / 100 ||
    Math.round(+allocationTotals.shipping_cost * 100) / 100 !==
      Math.round(+(bundleValues.shipping_cost || 0) * 100) / 100
  ) {
    allocationsComplete = false;
  }

  return (
    <>
      <WalkThrough open={open} onClose={() => setOpen(false)} />
      <DialogTitle>Allocate Bundled Sales</DialogTitle>
      <DialogContent>
        <Box className={classes.row}>
          <Box className="w-1/3">
            <Typography className={classes.header}>Item Title</Typography>
          </Box>
          <Box className="w-1/6 mr-2 report-sale-bundle-page-2-table-header report-sale-bundle-page-2-purchase-price-header">
            <Typography className={classes.header}>Purchase Price</Typography>
          </Box>
          <Box className="w-1/6 mr-2 report-sale-bundle-page-2-table-header">
            <Typography className={classes.header}>Sale Price</Typography>
          </Box>
          <Box className="w-1/6 mr-2 report-sale-bundle-page-2-table-header">
            <Typography className={classes.header}>Transaction Fees</Typography>
          </Box>
          <Box className="w-1/6 mr-2 report-sale-bundle-page-2-table-header">
            <Typography className={classes.header}>Shipping Cost</Typography>
          </Box>
        </Box>
        {allocationValues.map(renderAllocationRow)}
        {renderTotalsRow()}
      </DialogContent>
      <DialogActions>
        <Box mt={-1.5}>
          <Tooltip title="Need help? Click here.">
            <GoldIconButton onClick={() => setOpen(true)} />
          </Tooltip>
        </Box>
        <Box flex={1} />
        <Button
          onClick={goToBundle}
          color="secondary"
          variant="contained"
          disabled={loading}
        >
          {loading && <CircularProgress size={15} className="mr-3" />}
          Back
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          disabled={!allocationsComplete || loading}
        >
          {loading && <CircularProgress size={15} className="mr-3" />}
          Submit
        </Button>
      </DialogActions>
    </>
  );
};

export default BundleAllocationsDialog;
