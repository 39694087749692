import React from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import LoadableButton from "src/components/LoadableButton";
import { Vendor } from "src/interfaces/vendor.interface";
import usePrevious from "src/utils/usePrevious";
import { getVendor } from "src/store/vendor/selector";
import { addVendor, updateVendor } from "src/apiService/modules/vendors";
import NumberField from "src/components/NumberField";

const InitialVendor: Partial<Vendor> = {
  name: "",
  contractor: false,
  // address: "",
  phone: [],
  email: [],
};

function VendorDialogContent({
  id,
  onClose,
}: {
  id: string;
  onClose: (vendorId?: string) => void;
}) {
  const vendor = useSelector((s: any) => getVendor(s, id));
  const formik = useFormik({
    initialValues: vendor || InitialVendor,
    onSubmit: async (values) => {
      if (!values.name) return;
      try {
        let newId: string | undefined = id;
        if (id && vendor) {
          await updateVendor(id, values);
        } else {
          newId = await addVendor(values);
        }
        onClose(newId);
        toast.success(`Vendor ${vendor ? "editted" : "created"} successfully.`);
      } catch (e) {
        toast.error(`There was an error: ${(e as Error).toString()}`);
      }
    },
    validate: (values) => {
      const errors: Record<string, string> = {};
      if (!values.name) errors.name = "Required field";
      if (values.consignor) {
        if (!values.commission) {
          errors.commission = "Required field";
        } else if (values.commission < 0) {
          errors.commission = "Can't be lower than 0";
        } else if (values.commission > 100) {
          errors.commission = "Can't be greater than 100%";
        }
      }

      return errors;
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogTitle>{vendor ? "Update" : "Add"} Vendor</DialogTitle>
      <DialogContent>
        <Box display="flex">
          <Box flex={1}>
            <TextField
              error={!!formik.errors.name}
              helperText={formik.errors.name}
              disabled={formik.isSubmitting}
              margin="dense"
              label="Vendor Name"
              value={formik.values.name}
              name="name"
              autoComplete="off"
              onChange={formik.handleChange}
              fullWidth
              required
            />
          </Box>
          <Box display="flex" ml={1}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={formik.isSubmitting}
                  checked={formik.values.contractor}
                  name="contractor"
                  onChange={formik.handleChange}
                  color="primary"
                />
              }
              label="1099 Contractor?"
            />
          </Box>
        </Box>
        {/*<TextField
          disabled={formik.isSubmitting}
          margin="dense"
          label="Vendor Address"
          value={formik.values.address}
          name="address"
          autoComplete="off"
          onChange={formik.handleChange}
          fullWidth
        />*/}
        <Box display="flex">
          <Box>
            <TextField
              disabled={formik.isSubmitting}
              margin="dense"
              label="Vendor Phone 1"
              onChange={formik.handleChange}
              name="phone[0]"
              value={formik.values.phone?.[0] || ""}
              fullWidth
              type="phone"
            />
          </Box>
          <Box ml={1}>
            <TextField
              disabled={formik.isSubmitting}
              margin="dense"
              label="Vendor Phone 2"
              onChange={formik.handleChange}
              name="phone[1]"
              value={formik.values.phone?.[1] || ""}
              fullWidth
              type="phone"
            />
          </Box>
        </Box>
        <Box display="flex">
          <Box>
            <TextField
              disabled={formik.isSubmitting}
              margin="dense"
              label="Vendor Email 1"
              onChange={formik.handleChange}
              name="email[0]"
              value={formik.values.email?.[0] || ""}
              fullWidth
              type="email"
            />
          </Box>
          <Box ml={1}>
            <TextField
              disabled={formik.isSubmitting}
              margin="dense"
              label="Vendor Email 2"
              onChange={formik.handleChange}
              name="email[1]"
              value={formik.values.email?.[1] || ""}
              fullWidth
              type="email"
            />
          </Box>
        </Box>
        <Box display="flex">
          <Box flex={1}>
            <NumberField
              error={!!formik.errors.commission}
              helperText={formik.errors.commission}
              disabled={!formik.values.consignor || formik.isSubmitting}
              margin="dense"
              label="% Commission"
              value={formik.values.commission}
              name="commission"
              autoComplete="off"
              onChange={formik.handleChange}
              fullWidth
            />
          </Box>
          <Box display="flex" ml={1}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={formik.isSubmitting}
                  checked={formik.values.consignor || false}
                  name="consignor"
                  onChange={formik.handleChange}
                  color="primary"
                />
              }
              label="Consignor?"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose()}
          color="primary"
          disabled={formik.isSubmitting}
        >
          Cancel
        </Button>
        <LoadableButton
          color="primary"
          type="submit"
          variant="contained"
          loading={formik.isSubmitting}
        >
          {vendor ? "Save" : "Add"}
        </LoadableButton>
      </DialogActions>
    </form>
  );
}

function VendorDialog({
  id,
  onClose,
}: {
  id?: string;
  onClose: (vendorId?: string) => void;
}) {
  const memory = usePrevious(id);
  const value = id || memory;
  return (
    <Dialog open={!!id} onClose={() => onClose()}>
      {value ? <VendorDialogContent id={value} onClose={onClose} /> : null}
    </Dialog>
  );
}

export default VendorDialog;
