import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Tour from "reactour";
import Typography from "@material-ui/core/Typography";

import { paymentMethodSelector } from "src/store/system/selector";
import { setActiveDialog } from "src/store/adminHtml/actions";

const style = {
  maxWidth: "600px",
  width: "600px",
};

const steps = [
  {
    selector: "#launcher",
    content: () => (
      <div>
        <Typography color="inherit" variant="subtitle1" className="mx-2">
          You can now search for support articles right here in the app! Not
          finding what you need? Leave us a message and we’ll email you back in
          24 hrs or less.
        </Typography>
      </div>
    ),
  },
];

const accentColor = "rgb(4, 76, 38)";

export interface ZendeskHelpTourProps {
  open: boolean;
}

function ZendeskHelpTour({ open }: ZendeskHelpTourProps) {
  const dispatch = useDispatch();
  const requirePaymentMethod = useSelector(paymentMethodSelector);
  const handleClose = () => {
    dispatch(setActiveDialog(""));
  };

  return (
    <Tour
      startAt={0}
      closeWithMask={false}
      disableDotsNavigation
      steps={steps}
      isOpen={open && !requirePaymentMethod}
      rounded={5}
      accentColor={accentColor}
      onRequestClose={handleClose}
      style={style}
      showCloseButton
      CustomHelper={null}
      showNumber={false}
      showButtons={false}
      showNavigation={false}
    />
  );
}

export default ZendeskHelpTour;
