import React from "react";
import Tour from "reactour";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import { userDontShowDialogsSelector } from "src/store/system/selector";
import { setActiveDialog } from "src/store/adminHtml/actions";
import useUserEffect from "src/utils/useUserEffect";
import DontShowThisMessageAgainCheckbox from "src/components/DontShowThisMessageAgainCheckbox";

const Key = "walk_through_notifications_icon" as const;
const Steps = [
  {
    selector: "#notifications-icon",
    content: (props) => (
      <>
        <DialogTitle>Notifications</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Looks like you have some new notifications. Always make sure to
            click here when you see the red circle. This means there’s something
            that needs your attention.
          </DialogContentText>
          <DontShowThisMessageAgainCheckbox dialog={Key} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.close()}
          >
            Done
          </Button>
        </DialogActions>
      </>
    ),
  },
];

const useStyles = makeStyles((theme) => ({
  helper: {
    maxWidth: "80%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "500px",
    },
    padding: 0,
  },
}));

function WalThroughNotifications({ open }: { open: boolean }) {
  const userDontShowDialogs = useSelector(userDontShowDialogsSelector);
  const dispatch = useDispatch();
  const isOpen =
    open && userDontShowDialogs && !userDontShowDialogs.includes(Key);
  const handleClose = () => {
    dispatch(setActiveDialog(""));
  };
  const classes = useStyles();

  useUserEffect((user) => {
    if (user.dontShowDialogs?.includes(Key)) dispatch(setActiveDialog(""));
  }, []);

  return (
    <Tour
      startAt={0}
      closeWithMask={false}
      steps={Steps}
      isOpen={isOpen}
      onRequestClose={handleClose}
      showButtons={false}
      showCloseButton={false}
      showNavigation={false}
      showNavigationNumber={false}
      showNumber={false}
      disableDotsNavigation
      rounded={5}
      className={classes.helper}
      disableInteraction
    />
  );
}

export default WalThroughNotifications;
