import produce from "immer";
import { handleActions } from "redux-actions";

import { SaleSateState } from "../../interfaces";

import { METHOD_TYPE } from "../../enums";
import { LOG_OUT } from "../system/actions";

import {
  START,
  FAIL,
  SUCCESS,
  FETCH_ITEMS,
  CREATE_ITEM,
  RESET,
  SALE_STATE,
} from "../common";

const initialState: SaleSateState = {
  items: [],

  method: METHOD_TYPE.LIST,
  loading: false,
  loaded: false,
  error: null,
  message: "",
};

export const saleStateReducer = handleActions<SaleSateState, any>(
  {
    [SALE_STATE + RESET]: (state) =>
      produce(state, (draft) => {
        draft = initialState;
      }),
    [SALE_STATE + FETCH_ITEMS + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.method = METHOD_TYPE.LIST;
        draft.error = null;
      }),
    [SALE_STATE + FETCH_ITEMS + SUCCESS]: (state, { payload: { data, length } }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = false;
        draft.items = data.ret.map((e:any) => e.Sale_State);
        draft.error = null;
      }),
    [SALE_STATE + FETCH_ITEMS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),
    [SALE_STATE + CREATE_ITEM + START]: (state, {payload: { start }}) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.CREATE;
        draft.loading = true;
        draft.error = null;
      }),
    [SALE_STATE + CREATE_ITEM + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = false;
        draft.items = state.items.concat(payload);
        draft.error = null;
      }),
    [SALE_STATE + CREATE_ITEM + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),
    [LOG_OUT]: () => initialState,
  },
  initialState
);
