import produce from "immer";
import { handleActions } from "redux-actions";

import { secondsToDate } from "../../utils";

import { InventoryState } from "../../interfaces";
import { METHOD_TYPE } from "../../enums";
import { LOG_OUT } from "../system/actions";
import {
  START,
  FAIL,
  SUCCESS,
  FETCH_ITEMS,
  UPLOAD_ITEMS,
  DELETE_ITEMS,
  FETCH_ITEM,
  CREATE_ITEM,
  DELETE_ITEM,
  UPDATE_ITEM,
  FS_CREATE_ITEMS,
  FS_UPDATE_ITEMS,
  FS_DELETE_ITEMS,
  RESET,
  INVENTORY,
  SET_FILTER,
  SELECT_ITEMS,
  SET_PAGE_SIZE,
  CLEAR_UPDATE_METHOD,
} from "../common";

const initialState: InventoryState = {
  items: [],
  total: 0,
  start: 1,
  end: 1,
  limit: 25,

  method: METHOD_TYPE.LIST,
  loading: false,
  loaded: false,
  error: null,
  message: "",

  selected: [],
  filter: {
    items: [],
  }
};

export const inventoryReducer = handleActions<InventoryState, any>(
  {
    [INVENTORY + RESET]: (state) =>
      produce(state, (draft) => {
        draft = initialState;
      }),
    [INVENTORY + UPLOAD_ITEMS + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.UPLOAD;
        draft.loading = true;
        draft.loaded = false;
      }),
    [INVENTORY + UPLOAD_ITEMS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = true;
        draft.error = null;
      }),
    [INVENTORY + UPLOAD_ITEMS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),
    [INVENTORY + DELETE_ITEMS + START]: (state) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.DELETE_ALL;
        draft.loading = true;
        draft.loaded = false;
      }),
    [INVENTORY + DELETE_ITEMS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = true;
        draft.items = [];
      }),
    [INVENTORY + DELETE_ITEMS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),

    [INVENTORY + CREATE_ITEM + START]: (state) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.CREATE;
        draft.loading = true;
        draft.error = null;
        draft.loaded = false;
      }),
    [INVENTORY + CREATE_ITEM + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = true;
        draft.error = null;
      }),
    [INVENTORY + CREATE_ITEM + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),

    [INVENTORY + UPDATE_ITEM + START]: (state) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.UPDATE;
        draft.loading = true;
        draft.error = null;
      }),
    [INVENTORY + UPDATE_ITEM + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = false;
        draft.error = null;
      }),
    [INVENTORY + UPDATE_ITEM + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),
    [INVENTORY + DELETE_ITEM + START]: (state) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.DELETE;
        draft.loading = true;
        draft.error = null;
      }),
    [INVENTORY + DELETE_ITEM + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = false;
        draft.error = null;
      }),
    [INVENTORY + DELETE_ITEM + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),
    [INVENTORY + SELECT_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.selected = payload;
      }),
    [INVENTORY + SET_FILTER]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.filter = payload;
      }),
    [INVENTORY + SET_PAGE_SIZE]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.limit = payload;
      }),
    [CLEAR_UPDATE_METHOD]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.LIST;
      }),
    [INVENTORY + FS_CREATE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        payload?.sort((a, b) => {
          const legend = [-1, 0, 1];
          return legend[+((a?.purchase_date as Date) < (b?.purchase_date as Date))];
        });
        draft.items.unshift(...payload.map((e: any) => ({
          ...e,
          platforms_listed: typeof e.platforms_listed === "string" ? 
            e.platforms_listed.split(",") : e.platforms_listed,
          purchase_date: e.purchase_date ? secondsToDate(e.purchase_date) : undefined,
          list_date: e.list_date ? secondsToDate(e.list_date) : undefined,
        })));
        draft.loading = false;
        draft.loaded = true;
      }),
    [INVENTORY + FS_UPDATE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items.forEach((item) => {
          const updated = payload.find((newItem) => newItem.id === item.id);
          if (updated) {
            const mapped = {
              ...updated,
              platforms_listed: typeof updated.platforms_listed === "string" ? 
                updated.platforms_listed.split(",") : updated.platforms_listed,
              purchase_date: updated.purchase_date ? secondsToDate(updated.purchase_date) : undefined,
              list_date: updated.list_date ? secondsToDate(updated.list_date) : undefined,
            };
            Object.assign(item, mapped);
          }
        });
      }),
    [INVENTORY + FS_DELETE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items = state.items.filter((item) => !payload.find((deleted) => deleted.id === item.id));
      }),
    [LOG_OUT]: () => initialState,
  },
  initialState
);
