import React, { useState } from "react";
import {
  AppBar,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import { Theme } from "src/theme";
import { setSupportLoginToken } from "src/store/support/actions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme: Theme) => ({
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  menuContainer: {
    padding: "20px 30px",
  },
  iconButtonAvatar: {
    padding: 0,
    size: "50px",
  },
}));

interface Props {
  onDrawerToggle?: () => void;
  title?: string;
  description?: string;
}

function Support({ onDrawerToggle, title, description }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [menuAnchor, setMenuAnchor] = useState<any>(null);
  const openMenu = (e) => setMenuAnchor(e.currentTarget);
  const closeMenu = () => setMenuAnchor(null);

  return (
    <AppBar color="primary" position="static" elevation={0}>
      <Toolbar>
        <Grid container spacing={1} alignItems="center">
          <Hidden smUp>
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
          <Grid item xs></Grid>
          <Grid item>
            <IconButton
              aria-controls="user-menu"
              color="inherit"
              className={classes.iconButtonAvatar}
              onClick={openMenu}
            >
              <AccountCircleIcon fontSize="large" />
            </IconButton>
            <Menu
              id="user-menu"
              anchorEl={menuAnchor}
              keepMounted
              open={!!menuAnchor}
              onClose={closeMenu}
            >
              <MenuItem
                onClick={() => {
                  dispatch(setSupportLoginToken(""));
                }}
              >
                Log out
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Toolbar>
      <Grid item xs className={classes.menuContainer}>
        <Typography color="inherit" variant="h5">
          {title}
        </Typography>
        <Typography color="inherit" variant="subtitle1">
          {description}
        </Typography>
      </Grid>
    </AppBar>
  );
}

export default Support;
