import * as React from "react";
import { withRouter } from "react-router-dom";

import { Dispatch } from "redux";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import { setActiveDialog } from "../../store/adminHtml/actions";
import { connect } from "react-redux";
import { AppState } from "../../store";
import { navigation } from "src/config/navigation";

import { LOGO } from "../../assets/svgs";

import {
  Button,
  Drawer,
  DrawerProps,
  Link as MuiLink,
  withStyles,
  WithStyles,
  createStyles,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

import { User } from "../../interfaces";
import { USER_PLAN } from "../../enums";
import { userPlanSelector } from "src/store/system/selector";

import type { ActiveDialog } from "src/interfaces/adminHtmlState.interface";

import NavigatorItem from "./NavigatorItem";

import type { Theme } from "src/theme";

export const navigatorStyles = (theme: Theme) =>
  createStyles({
    list: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    item: {
      backgroundColor: "#fff",
      paddingTop: 15,
      paddingBottom: 15,
      justifyContent: "center",
    },
    expander: {
      flex: 1,
    },
    upgrade: {
      alignSelf: "center",
    },
    bottomPush: {
      textAlign: "center",
      paddingBottom: 10,
      justifyContent: "center",
    },
    rightPadding: {
      paddingRight: "10px",
    },
  });

interface NavigatorOwnProps extends WithStyles<typeof navigatorStyles> {
  title: string;
  subTitle: string;
  variant?: "permanent" | "persistent" | "temporary";
  open?: boolean;
}

export interface NavigatorStateProps {
  user?: User;
  plan?: string;
}

interface NavigatorDispatchProps {
  onItemClick: (id: string) => void;
  dispatchSetActiveDialog: (dialog: ActiveDialog) => void;
}

export type NavigatorProps = NavigatorStateProps &
  NavigatorOwnProps &
  DrawerProps &
  NavigatorDispatchProps &
  any;

class Navigator extends React.Component<NavigatorProps, any> {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {
    const { location, onItemClick } = this.props;
    const nav = navigation.find(
      (e) => "href" in e && e.href === location.pathname
    );
    if (nav && nav.id) {
      onItemClick(nav.id);
    }
  }

  render() {
    const {
      title,
      subTitle,
      variant,
      classes,
      user,
      onItemClick,
      onClose,
      staticContext,
      plan,
      dispatchSetActiveDialog,
      ...other
    } = this.props;
    return (
      <Drawer variant={variant} {...other} onClose={() => onClose()}>
        <List disablePadding className={classes.list}>
          <ListItem className={classes.item}>
            <img src={LOGO} alt={title} className="h-24" />
          </ListItem>
          {navigation
            .map((nav) => {
              if ("dialog" in nav) {
                if ("render" in nav && nav.render)
                  return (
                    <React.Fragment key={nav.id}>
                      {nav.render({ entry: nav, dispatch: dispatchSetActiveDialog })}
                    </React.Fragment>
                  );
                return (
                  <NavigatorItem
                    key={nav.id}
                    onClick={() => {
                      dispatchSetActiveDialog(nav.dialog);
                    }}
                    Icon={nav.Icon}
                    primary={nav.title}
                  />
                );
              }

              const active =
                "href" in nav && other.location.pathname.startsWith(nav.href);

              if ("render" in nav && nav.render)
                return (
                  <React.Fragment key={nav.id}>
                    {nav.render({ active, entry: nav })}
                  </React.Fragment>
                );

              return (
                <NavigatorItem
                  id={nav.id}
                  key={nav.id}
                  Icon={nav.Icon}
                  primary={nav.title}
                  to={nav.href}
                  active={other.location.pathname.startsWith(nav.href)}
                />
              );
            })}
          <ListItem className={classes.expander} />
          {plan !== "ultimate" && (
            <Tooltip title="There are lots more features that My Reseller Genie has to make your accounting even easier!">
              <Button
                variant="contained"
                color="primary"
                className={classes.upgrade}
                startIcon={<LockOpenIcon />}
                onClick={() =>
                  this.props.dispatchSetActiveDialog("upgrade_plan")
                }
              >
                Upgrade Plan
              </Button>
            </Tooltip>
          )}
          <ListItem className={classes.bottomPush}>
            <MuiLink
              href="https://www.myresellergenie.com/terms-of-use"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.rightPadding}
            >
              Terms of Use
            </MuiLink>
            <MuiLink
              href="https://www.myresellergenie.com/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </MuiLink>
          </ListItem>
        </List>
      </Drawer>
    );
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: NavigatorOwnProps
): NavigatorDispatchProps => {
  return {
    onItemClick: (id: string) => {
      const nav = navigation.find((e) => e.id === id);
      if (!nav) return;

      if ("href" in nav) {
      } else if ("dialog" in nav) {
        dispatch(setActiveDialog(nav.dialog));
      }
    },
    dispatchSetActiveDialog: (dialog: ActiveDialog) =>
      dispatch(setActiveDialog(dialog)),
  };
};

const mapStateToProps = (state: AppState): NavigatorStateProps => ({
  user: state.system.user,
  plan: userPlanSelector(state),
});

export default withStyles(navigatorStyles as any)(
  connect<
    NavigatorStateProps,
    NavigatorDispatchProps,
    NavigatorOwnProps,
    AppState
  >(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(Navigator))
);
