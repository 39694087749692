import { Sale } from "src/interfaces/sale.interface";
import { Transaction } from "src/interfaces/transaction.interface";
import { User } from "src/interfaces/systemState.interface";
import { Inventory } from "src/interfaces/inventory.interface";
import { Vendor } from "src/interfaces/vendor.interface";

import {
  TransactionFees,
  CostOfSoldInventory,
  ShippingCost,
} from "./constants";

export interface DateParametersProfitLoss {
  startDate: Date;
  endDate: Date;
  compareStartDate?: Date;
  compareEndDate?: Date;
}
export interface DateParametersInventory {
  year: number;
}

export interface ParametersPlatformTransaction {
  startDate: Date;
  endDate: Date;
  platform?: string;
}

export interface ParametersConsignmentCommission {
  startDate: Date;
  endDate: Date;
  consignor?: Vendor;
}

export type ReportParameters = DateParametersProfitLoss &
  DateParametersInventory &
  ParametersPlatformTransaction &
  ParametersConsignmentCommission;

function renderOnlyDate(date: Date): string {
  return date.toLocaleString("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
}

function pushOrCreate<T>(
  obj: Record<string, T[]>,
  section: string,
  item: T
): void {
  if (!obj[section]) obj[section] = [];

  obj[section].push(item);
}

export interface ReportDetails {
  title: string;
  titleFull: string;
  filename: string;
  dateRange: string;
}

export interface ProfitLossReportData {
  reportDetails: ReportDetails;
  salesByDepartment: Record<string, number>;
  COGSDetail: Record<string, number>;
  expenseDetail: any; // TODO
  totalSales: number;
  totalCOGS: number;
  totalExpenses: number;
  grossProfit: number;
  estimatedIncomeTax: number;
  netProfit: number;
  netProfitNoIncomeTax: number;
  salesListByDepartment: Record<string, Sale[]>;
  salesListByExpense: Record<string, Sale[]>;
  transactionsListByExpense: Record<string, Transaction[]>;
}

export interface ProfitLossReport {
  type: "profit/loss";
  data: ProfitLossReportData[];
}

type SaleOrInventory = Sale | Inventory;
export interface InventoryReportData {
  reportDetails: ReportDetails;
  yearEndBalance: number;
  yearEndBalanceItems: SaleOrInventory[];
  yearStartBalance: number;
  yearStartBalanceItems: SaleOrInventory[];
  purchasesDuringYear: number;
  purchasesDuringYearItems: SaleOrInventory[];
  inventoryCost: number;
  inventoryCostItems: SaleOrInventory[];
}

export interface InventoryReport {
  type: "inventory";
  data: InventoryReportData;
}

export interface PlatformTransactionRow {
  id: string;
  date: Date;
  transactionId: string;
  title: string;
  revenue?: number;
  expenses?: number;
}

export interface PlatformTransactionReportData {
  reportDetails: ReportDetails;
  rows: PlatformTransactionRow[];
  totals: {
    sales: number;
    returns: number;
    expenses: number | Record<string, number>;
  };
  platform: string;
}

export interface PlatformTransactionReport {
  type: "platform-transaction";
  data: PlatformTransactionReportData;
}

export interface ConsignmentCommissionReportData {
  reportDetails: ReportDetails;
  consignor: Vendor;
  sales: Sale[];
  totals: {
    sales: number;
    returns: number;
    expenses: number;
    grossProfit: number;
    commission: number;
  };
}

export interface ConsignmentCommissionReport {
  type: "consignment-commission";
  data: ConsignmentCommissionReportData;
}

export type Reports =
  | InventoryReport
  | ProfitLossReport
  | PlatformTransactionReport
  | ConsignmentCommissionReport;
export type ReportTypes =
  | InventoryReport["type"]
  | ProfitLossReport["type"]
  | PlatformTransactionReport["type"]
  | ConsignmentCommissionReport["type"];

export function buildProfitLossReportData(
  reportType: "profit/loss",
  parameters: DateParametersProfitLoss,
  customLedgerAccounts: { type: string; name: string }[],
  sales: Sale[],
  transactions: Transaction[],
  user?: User
): ProfitLossReportData {
  const reportDetails = {
    title: "Profit/Loss Statement",
    titleFull: "",
    filename: "",
    dateRange: `${renderOnlyDate(parameters.startDate)} to ${renderOnlyDate(
      parameters.endDate
    )}`,
  };

  reportDetails.titleFull = `${reportDetails.title} from ${reportDetails.dateRange}`;
  reportDetails.filename =
    `${reportDetails.title}-${reportDetails.dateRange}.pdf`
      .toLowerCase()
      .replace(/[-/]/g, "-");

  const salesByDepartment: Record<string, number> = {};
  const COGSDetail = {
    [TransactionFees]: 0,
    [CostOfSoldInventory]: 0,
    [ShippingCost]: 0,
    "Shipping Supplies": 0,
    "Shipping Fees": 0,
    "Sourcing Mileage/Transportation": 0,
    "Listing/Platform Fees": 0,
    "Inventory Purchases": 0,
  };

  for (const account of customLedgerAccounts) {
    if (account.type === "Other COGS") {
      COGSDetail[`${account.name}`] = 0;
    }
  }

  const expenseDetail: any = {};
  let totalSales = 0;
  let totalCOGS = 0;
  let totalExpenses = 0;

  const startDate = new Date(parameters.startDate);
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);
  startDate.setMilliseconds(0);

  const endDate = new Date(parameters.endDate);
  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  endDate.setMilliseconds(999);

  const salesListByDepartment: Record<string, Sale[]> = {};
  const salesListByExpense: Record<string, Sale[]> = {};
  const transactionsListByExpense: Record<string, Transaction[]> = {};

  for (const sale of sales) {
    const saleDate = new Date(
      (sale.original_id || sale.is_return
        ? sale.return_date
        : sale.sale_date) || 0
    );
    if (!saleDate.valueOf() || saleDate < startDate || saleDate > endDate)
      continue;

    totalSales += +sale.sale_price;

    let platform = sale.sale_platform || "Uncategorized";
    if (sale.original_id || sale.is_return) platform = `${platform} (Refunds)`;

    if (!salesByDepartment[platform]) salesByDepartment[platform] = 0;

    salesByDepartment[platform] += +sale.sale_price;

    COGSDetail[TransactionFees] += +sale.transaction_fees || 0;
    COGSDetail[TransactionFees] += +(sale.extra_transaction_fees || 0);
    if (sale.transaction_fees || sale.extra_transaction_fees)
      pushOrCreate(salesListByExpense, TransactionFees, sale);

    COGSDetail[CostOfSoldInventory] += +sale.purchase_price || 0;
    if (sale.purchase_price)
      pushOrCreate(salesListByExpense, CostOfSoldInventory, sale);

    COGSDetail[ShippingCost] += +sale.shipping_cost || 0;
    COGSDetail[ShippingCost] += +(sale.extra_shipping_cost || 0);
    if (sale.shipping_cost || sale.extra_shipping_cost)
      pushOrCreate(salesListByExpense, ShippingCost, sale);

    totalCOGS += +sale.transaction_fees || 0;
    totalCOGS += +(sale.extra_transaction_fees || 0);
    totalCOGS += +sale.purchase_price || 0;
    totalCOGS += +sale.shipping_cost || 0;
    totalCOGS += +(sale.extra_shipping_cost || 0);

    pushOrCreate(salesListByDepartment, platform, sale);
  }

  for (const transaction of transactions) {
    const transactionDate = new Date(transaction.date || 0);
    if (transactionDate < startDate || transactionDate > endDate) continue;

    if (transaction.account in COGSDetail) {
      COGSDetail[transaction.account] += Number(transaction.amount);

      if (transaction.amount)
        pushOrCreate(
          transactionsListByExpense,
          transaction.account,
          transaction
        );
      totalCOGS += +transaction.amount;
    } else {
      if (!expenseDetail[transaction.account])
        expenseDetail[transaction.account] = 0;

      expenseDetail[transaction.account] += transaction.amount;
      totalExpenses += +transaction.amount;

      if (transaction.amount)
        pushOrCreate(
          transactionsListByExpense,
          transaction.account,
          transaction
        );
    }
  }

  const grossProfit = totalSales - totalCOGS;
  const estimatedIncomeTax =
    (grossProfit - totalExpenses) * (user?.income_tax_rate || 0);
  const netProfit = grossProfit - totalExpenses - estimatedIncomeTax;
  const netProfitNoIncomeTax = grossProfit - totalExpenses;

  return {
    salesByDepartment,
    COGSDetail: Object.keys(COGSDetail).reduce((obj, key) => {
      if (COGSDetail[key]) obj[key] = COGSDetail[key];
      return obj;
    }, {}),
    expenseDetail,
    totalSales,
    totalCOGS,
    totalExpenses,
    grossProfit,
    estimatedIncomeTax,
    netProfit,
    netProfitNoIncomeTax,
    reportDetails,
    salesListByDepartment,
    salesListByExpense,
    transactionsListByExpense,
  };
}

export function buildProfitLossReport(
  reportType: ProfitLossReport["type"],
  parameters: DateParametersProfitLoss,
  customLedgerAccounts: { type: string; name: string }[],
  sales: Sale[],
  transactions: Transaction[],
  user?: User
): ProfitLossReport {
  const data = [
    buildProfitLossReportData(
      reportType,
      parameters,
      customLedgerAccounts,
      sales,
      transactions,
      user
    ),
  ];

  if (parameters.compareStartDate && parameters.compareEndDate) {
    data.push(
      buildProfitLossReportData(
        reportType,
        {
          startDate: parameters.compareStartDate,
          endDate: parameters.compareEndDate,
        },
        customLedgerAccounts,
        sales,
        transactions,
        user
      )
    );
  }

  return {
    type: "profit/loss",
    data,
  };
}

export function reportIsProfitLoss(
  report: Reports
): report is ProfitLossReport {
  return (report as ProfitLossReport).type === "profit/loss";
}

export function reportIsInventory(report: Reports): report is InventoryReport {
  return (report as InventoryReport).type === "inventory";
}

export function reportIsPlatformTransaction(
  report: Reports
): report is PlatformTransactionReport {
  return (report as PlatformTransactionReport).type === "platform-transaction";
}

export function reportIsConsignmentCommission(
  report: Reports
): report is ConsignmentCommissionReport {
  return (
    (report as ConsignmentCommissionReport).type === "consignment-commission"
  );
}

export function buildInventoryReport(
  reportType: InventoryReport["type"],
  parameters: DateParametersInventory,
  sales?: Sale[],
  inventories?: Inventory[]
): InventoryReport {
  const year = parameters.year;
  const title = "Inventory Report";
  const reportDetails: ReportDetails = {
    title,
    titleFull: `${title} for ${year}`,
    filename: `${title}-${year}.pdf`.toLowerCase().replace(/[-/]/g, "-"),
    dateRange: `${year}`,
  };

  const firstOfYear = new Date();
  firstOfYear.setFullYear(year);
  firstOfYear.setMonth(0);
  firstOfYear.setDate(1);
  firstOfYear.setHours(0);
  firstOfYear.setMinutes(0);
  firstOfYear.setSeconds(0);
  firstOfYear.setMilliseconds(0);
  const lastOfYear = new Date(firstOfYear);
  lastOfYear.setFullYear(firstOfYear.getFullYear() + 1);
  firstOfYear.setMilliseconds(-1);

  const allRecords = [...(sales || []), ...(inventories || [])];
  const yearStartBalanceItems: any[] = [];
  const purchasesDuringYearItems: any[] = [];
  const yearEndBalanceItems: any[] = [];
  const inventoryCostItems: any[] = [];
  const yearStartBalance = allRecords.reduce((result, item: any) => {
    if (
      (item.purchase_date || 0) < firstOfYear &&
      (!item.sale_date || item.sale_date >= firstOfYear)
    ) {
      yearStartBalanceItems.push(item);
      return result + (parseFloat(item.purchase_price) || 0);
    }
    return result;
  }, 0);
  const purchasesDuringYear = allRecords.reduce((result, item) => {
    const date = item.purchase_date || 0;
    if (date >= firstOfYear && date <= lastOfYear) {
      purchasesDuringYearItems.push(item);
      return result + (parseFloat(item.purchase_price as any) || 0);
    }
    return result;
  }, 0);
  const yearEndBalance = allRecords.reduce((result, item: any) => {
    const date = item.purchase_date || 0;
    if (
      date <= lastOfYear &&
      (!item.sale_date || item.sale_date > lastOfYear)
    ) {
      yearEndBalanceItems.push(item);
      return result + (parseFloat(item.purchase_price) || 0);
    }
    return result;
  }, 0);
  const inventoryCost = allRecords.reduce((result, item: any) => {
    const date =
      (item.original_id || item.is_return
        ? item.return_date
        : item.sale_date) || 0;
    if (date >= firstOfYear && date <= lastOfYear) {
      inventoryCostItems.push(item);
      return result + (parseFloat(item.purchase_price) || 0);
    }
    return result;
  }, 0);

  return {
    type: "inventory",
    data: {
      reportDetails,
      yearEndBalance,
      yearEndBalanceItems,
      yearStartBalance,
      yearStartBalanceItems,
      purchasesDuringYear,
      purchasesDuringYearItems,
      inventoryCostItems,
      inventoryCost,
    },
  };
}

export function buildPlatformTransactionReport(
  reportType: "platform-transaction",
  parameters: ParametersPlatformTransaction,
  sales: Sale[],
  transactions: Transaction[]
): PlatformTransactionReport {
  if (!parameters.platform) {
    return {
      type: "platform-transaction",
      data: {
        reportDetails: {
          title: "",
          titleFull: "",
          filename: "",
          dateRange: "",
        },
        rows: [],
        totals: {
          sales: 0,
          returns: 0,
          expenses: 0,
        },
        platform: "",
      },
    };
  }
  const startDate = new Date(parameters.startDate);
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);
  startDate.setMilliseconds(0);

  const endDate = new Date(parameters.endDate);
  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  endDate.setMilliseconds(999);

  const totals = {
    sales: 0,
    returns: 0,
    expenses: 0,
  };
  const rows: PlatformTransactionRow[] = [];

  for (const sale of sales) {
    if (sale.sale_platform !== parameters.platform) continue;

    if (sale.original_id || sale.is_return) {
      if (!sale.return_date) continue;
      const date = new Date(sale.return_date || 0);
      if (date < startDate || date > endDate) continue;

      totals.returns += sale.sale_price;
      const expenses = -(
        (sale.transaction_fees || 0) + (sale.shipping_cost || 0)
      );
      totals.expenses += expenses;

      rows.push({
        id: sale.id,
        transactionId: sale.transaction_id || "",
        date,
        title: sale.item_title,
        revenue: sale.sale_price,
        expenses,
      });
    } else {
      if (!sale.sale_date) continue;
      const date = new Date(sale.sale_date || 0);
      if (date < startDate || date > endDate) continue;

      totals.sales += sale.sale_price;
      const expenses = -(
        (sale.transaction_fees || 0) + (sale.shipping_cost || 0)
      );
      totals.expenses += expenses;

      rows.push({
        id: sale.id,
        transactionId: sale.transaction_id || "",
        date,
        title: sale.item_title,
        revenue: sale.sale_price,
        expenses,
      });
    }
  }

  for (const transaction of transactions) {
    if (transaction.integration_name !== parameters.platform) continue;
    if (!transaction.date) continue;
    const transactionDate = new Date(transaction.date);
    if (transactionDate < startDate || transactionDate > endDate) continue;
    const expenses = -transaction.amount;
    totals.expenses += expenses;
    rows.push({
      date: transaction.date,
      title: transaction.name || transaction.description,
      id: transaction.id,
      transactionId:
        transaction.transaction_id ||
        (transaction as any).transactionId ||
        (transaction as any).orderId ||
        "",
      expenses,
    });
  }

  rows.sort(
    (a: PlatformTransactionRow, b: PlatformTransactionRow) =>
      a.date.getTime() - b.date.getTime()
  );

  const title = "Platform Transaction Report";
  const dateRange = `${renderOnlyDate(
    parameters.startDate
  )} to ${renderOnlyDate(parameters.endDate)}`;
  return {
    type: "platform-transaction",
    data: {
      reportDetails: {
        title,
        titleFull: `${title} from ${dateRange} - ${parameters.platform}`,
        filename: `${title}-${dateRange}-${parameters.platform}.pdf`
          .toLowerCase()
          .replace(/[-/ ]/g, "-"),
        dateRange,
      },
      rows,
      totals,
      platform: parameters.platform,
    },
  };
}

export function buildConsignmentCommissionReport(
  reportType: "consignment-commission",
  parameters: ParametersConsignmentCommission,
  sales: Sale[]
): ConsignmentCommissionReport {
  if (!parameters.consignor) {
    return {
      type: "consignment-commission",
      data: {
        reportDetails: {
          title: "",
          titleFull: "",
          filename: "",
          dateRange: "",
        },
        consignor: {
          id: "",
          name: "",
          contractor: false,
          address: "",
          phone: [],
          email: [],
          user: "",
        },
        totals: {
          sales: 0,
          returns: 0,
          expenses: 0,
          grossProfit: 0,
          commission: 0,
        },
        sales: [],
      },
    };
  }

  const startDate = new Date(parameters.startDate);
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);
  startDate.setMilliseconds(0);

  const endDate = new Date(parameters.endDate);
  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  endDate.setMilliseconds(999);

  const totals = {
    sales: 0,
    returns: 0,
    expenses: 0,
    grossProfit: 0,
    commission: 0,
  };

  const filteredSales: Sale[] = [];

  for (const sale of sales) {
    if (sale.vendor !== parameters.consignor.id) continue;
    if (sale.original_id || sale.is_return) {
      if (!sale.return_date) continue;
      const date = new Date(sale.return_date || 0);
      if (date < startDate || date > endDate) continue;
      filteredSales.push(sale);
      totals.returns += +sale.sale_price || 0;
      totals.expenses +=
        +(sale.purchase_price || 0) +
        +(sale.transaction_fees || 0) +
        +(sale.shipping_cost || 0) +
        +(sale.other_fees || 0) +
        +(sale.shipping_cost_analytics || 0) +
        +(sale.extra_shipping_cost || 0) +
        +(sale.extra_transaction_fees || 0);
    } else {
      if (!sale.sale_date) continue;
      const date = new Date(sale.sale_date || 0);
      if (date < startDate || date > endDate) continue;
      filteredSales.push(sale);
      totals.sales += +sale.sale_price || 0;
      totals.expenses +=
        +(sale.purchase_price || 0) +
        +(sale.transaction_fees || 0) +
        +(sale.shipping_cost || 0) +
        +(sale.other_fees || 0) +
        +(sale.shipping_cost_analytics || 0);
    }
  }

  totals.grossProfit = totals.sales + totals.returns - totals.expenses;
  totals.commission =
    (totals.grossProfit * (parameters.consignor?.commission || 0)) / 100;

  const title = "Consignment Commission Report";
  const dateRange = `${renderOnlyDate(
    parameters.startDate
  )} to ${renderOnlyDate(parameters.endDate)}`;
  return {
    type: "consignment-commission",
    data: {
      reportDetails: {
        title,
        titleFull: `${title} from ${dateRange} - ${parameters.consignor.name}`,
        filename: `${title}-${dateRange}-${parameters.consignor.name}.pdf`
          .toLowerCase()
          .replace(/[-/ ]/g, "-"),
        dateRange,
      },
      sales: filteredSales,
      totals,
      consignor: parameters.consignor,
    },
  };
}
