import Papa from "papaparse";

import { checkHeaders, preprocessInput, transformInput } from "src/utils";
const ErrorMessage =
  "It looks like you might have the wrong report, double check the instructions to download the report. If you're still having trouble, email support@myresellergenie.com and we'll help you out!";

export function processMercariFile(
  data: string
): { result?: Blob } | { error: string } {
  try {
    let splitCharacter = "\n";
    if (data.indexOf(splitCharacter) < 0) splitCharacter = "\r";

    const splitData = data
      .replaceAll(
        'Very high item not as described" fee"',
        'Very high "item not as described" fee'
      )
      .split(splitCharacter);

    const reportType = "Sales";
    if (!reportType)
      return {
        error: "Invalid file",
      };

    const platformKey = "mercariSales" as const;
    const rawRows = preprocessInput(platformKey, splitData);
    const trimmedData = rawRows.join("\n");
    const parseResults = Papa.parse(trimmedData, { header: true });
    const parsedData = parseResults.data;

    for (const item of parsedData) {
      if (item['Very high item not as described" fee"']) {
        item['Very high "item not as described" fee'] =
          item['Very high item not as described" fee"'];
      }
    }

    if (parsedData.length) {
      let transformed = "";
      if (!checkHeaders(platformKey, Object.keys(parsedData[0])))
        return { error: ErrorMessage };
      transformed = transformInput(`mercari${reportType}`, parsedData);
      const result = new Blob([transformed], { type: "text/csv" });

      return {
        result,
      };
    }
  } catch (e) {
    console.error(e);
    return { error: `There was an error processing your input file: ${e}` };
  }

  return { error: ErrorMessage };
}
