import React from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

import LoadableButton from "src/components/LoadableButton";
import { setActiveDialog } from "src/store/adminHtml/actions";
import { addGeneralLedgerAccount } from "src/apiService/modules/generalLedgerAccounts";
import InfoTooltip from "src/components/InfoTooltip";
import { GeneralLedgerAccount } from "src/interfaces/generalLedgerAccount.interface";

const useStyles = makeStyles({
  typeDropdown: {
    alignSelf: "end",
  },
  tooltip: {
    float: "left",
  },
});

function Content({
  onClose,
}: {
  onClose: (account?: GeneralLedgerAccount) => void;
}) {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      name: "",
      type: "Other COGS",
    },
    onSubmit: async (values) => {
      if (!values.name || !values.type) return;
      try {
        const id = await addGeneralLedgerAccount(values.name, values.type);
        onClose(id);
        toast.success(`General Ledger Account created successfully.`);
      } catch (e) {
        toast.error(`There was an error: ${(e as Error).toString()}`);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogTitle>Add General Ledger Account</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Account Name"
              variant="standard"
              name="name"
              value={formik.values.name}
              required={true}
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={6} className={classes.typeDropdown}>
            <Select
              value={formik.values.type}
              onChange={formik.handleChange}
              label="Account Type"
              disabled={formik.isSubmitting}
              name="type"
            >
              <MenuItem key={"Other COGS"} value={"Other COGS"}>
                {"Other COGS"}
              </MenuItem>
              <MenuItem
                key={"Other Business Costs"}
                value={"Other Business Costs"}
              >
                {"Other Business Costs"}
              </MenuItem>
            </Select>
            <InfoTooltip
              text="Select the account type based on what type of expense this account will hold. If the expense is directly related to selling, choose 'Other COGS'. If the expense is not directly related to selling, choose 'Other Business Costs'"
              size={18}
              className={classes.tooltip}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadableButton
          color="primary"
          variant="contained"
          loading={formik.isSubmitting}
          type="submit"
        >
          Add
        </LoadableButton>
      </DialogActions>
    </form>
  );
}

export function AddGeneralLedgerAccountDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: (account?: GeneralLedgerAccount) => void;
}) {
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      scroll="body"
      fullWidth={true}
      maxWidth="sm"
    >
      <Content onClose={onClose} />
    </Dialog>
  );
}

function AddGeneralLedgerAccountRootDialog({ open }: { open: boolean }) {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setActiveDialog(""));
  };

  return <AddGeneralLedgerAccountDialog open={open} onClose={handleClose} />;
}

export default AddGeneralLedgerAccountRootDialog;
