import React, { useEffect, useState, type PropsWithChildren } from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { userGetInventoryTypeSelector } from "src/store/system/selector";

import InfoTooltip from "src/components/InfoTooltip";
import NumberField from "src/components/NumberField";
import GoldIconButton from "src/components/GoldIconButton";

import WalkThrough from "./RecordReturnWalkThrough";

// fix-vim-highligh = }

function TooltipWrapper({ children }: PropsWithChildren<{}>) {
  return (
    <Box
      display="flex"
      minWidth="34px"
      justifyContent="center"
      alignItems="center"
    >
      {children}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  tooltip: {
    padding: theme.spacing(0.5),
  },
  titleTooltip: {
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  bold: {
    fontWeight: 500,
  },
}));

function getDefaultReturnValues(data) {
  return {
    return_date: new Date(),
    sale_price: data?.sale_price || 0,
    shipping_cost: data?.shipping_cost || 0,
    transaction_fees: data?.transaction_fees || 0,
    sales_tax: data?.sales_tax || 0,
    extra_shipping_cost: 0,
    extra_transaction_fees: 0,
    received_item: "y",
  };
}

const RecordReturnDialog = ({ data, loading, goToRoot, handleSave }) => {
  const [open, setOpen] = useState(false);
  const isInventoryDisabled = useSelector(
    (s: any) => userGetInventoryTypeSelector(s) === "cash"
  );
  const classes = useStyles();
  const [values, setValues] = useState(() => getDefaultReturnValues(data));

  useEffect(() => {
    setValues(getDefaultReturnValues(data));
  }, [data]);

  const handleChange = (prop: string) => (event, t?: any) => {
    let value = event.target.value;
    setValues({ ...values, [prop]: value });
  };

  const save = () => handleSave(values);

  return (
    <>
      <WalkThrough
        open={open}
        onClose={() => setOpen(false)}
        isInventoryDisabled={isInventoryDisabled}
      />
      <DialogTitle>Record Refund</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6} id="record-return-details-section">
            <Typography variant="subtitle1" className={classes.bold}>
              Refund Details
            </Typography>
            <Typography variant="body2" gutterBottom>
              ‎
            </Typography>
            <Box display="flex">
              <TooltipWrapper />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  className="w-full"
                  variant="inline"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  autoOk={true}
                  margin="dense"
                  label="Refund Date"
                  required
                  value={values.return_date || null}
                  disabled={loading}
                  onChange={(e: any, t: any) => {
                    setValues({ ...values, return_date: new Date(e) });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Box display="flex">
              <TooltipWrapper>
                <InfoTooltip
                  text="The amount that you refunded to the customer (enter as a positive number)."
                  size={18}
                  className={classes.tooltip}
                />
              </TooltipWrapper>
              <NumberField
                fixed={2}
                fullWidth
                variant="outlined"
                margin="dense"
                label="Refund Amount"
                required
                value={values.sale_price}
                disabled={loading}
                onChange={handleChange("sale_price")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box display="flex">
              <TooltipWrapper>
                <InfoTooltip
                  text="Any fees refunded to you by the platform (enter as a positive number)."
                  size={18}
                  className={classes.tooltip}
                />
              </TooltipWrapper>
              <NumberField
                fixed={2}
                fullWidth
                variant="outlined"
                margin="dense"
                label="Fees Refunded"
                value={values.transaction_fees}
                disabled={loading}
                onChange={handleChange("transaction_fees")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box display="flex">
              <TooltipWrapper>
                <InfoTooltip
                  text="If your shipping costs were refunded, enter them here (enter as a positive number)."
                  size={18}
                  className={classes.tooltip}
                />
              </TooltipWrapper>
              <NumberField
                fixed={2}
                fullWidth
                variant="outlined"
                margin="dense"
                label="Shipping Cost Refunded"
                value={values.shipping_cost}
                disabled={loading}
                onChange={handleChange("shipping_cost")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box display="flex">
              <TooltipWrapper>
                <InfoTooltip
                  text="Sales tax that was refunded to the customer (enter as a positive number)."
                  size={18}
                  className={classes.tooltip}
                />
              </TooltipWrapper>
              <NumberField
                fixed={2}
                fullWidth
                variant="outlined"
                margin="dense"
                label="Sales Tax Refunded"
                value={values.sales_tax}
                disabled={loading}
                onChange={handleChange("sales_tax")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            {isInventoryDisabled ? null : (
              <Box id="record-return-item-back-section">
                <Box display="flex" alignItems="center">
                  <Typography variant="subtitle1" className={classes.bold}>
                    Did you receive the item back?
                  </Typography>
                  <InfoTooltip
                    text="If you received the item back and are able to relist and sell it, then mark this question as 'Yes'. If you didn't receive the item back or the item is unable to be sold, then mark this question as 'No'. If you answer this question as 'Yes', then we will add this item back to inventory after recording the return. If you answer 'No', then the item will not be added to inventory."
                    size={18}
                    className={classes.titleTooltip}
                  />
                </Box>
                <RadioGroup
                  aria-label="received_item"
                  name="received_item"
                  value={values.received_item || "y"}
                  onChange={handleChange("received_item")}
                >
                  <FormControlLabel
                    value="y"
                    control={<Radio size="small" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="n"
                    control={<Radio size="small" />}
                    label="No"
                  />
                </RadioGroup>
              </Box>
            )}
            <Box id="record-return-additional-fees-section">
              <Box>
                <Typography variant="subtitle1" className={classes.bold}>
                  Additional Fees
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Any additional costs related to this refund
                </Typography>
              </Box>
              <NumberField
                fixed={2}
                fullWidth
                variant="outlined"
                margin="dense"
                label="Shipping Cost"
                value={values.extra_shipping_cost}
                disabled={loading}
                onChange={handleChange("extra_shipping_cost")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
              <NumberField
                fixed={2}
                fullWidth
                variant="outlined"
                margin="dense"
                label="Transaction Fees"
                value={values.extra_transaction_fees}
                disabled={loading}
                onChange={handleChange("extra_transaction_fees")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box mt={-1.5}>
          <Tooltip title="Need help? Click here.">
            <GoldIconButton onClick={() => setOpen(true)} />
          </Tooltip>
        </Box>
        <Box flex={1} />
        <Button onClick={goToRoot} color="secondary" variant="contained">
          Back to Sale
        </Button>
        <Button
          onClick={save}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          Add
        </Button>
      </DialogActions>
    </>
  );
};

export default RecordReturnDialog;
