import firebase from "firebase/app";

import axiosClient from "../axiosClient";
import { getUserId } from "src/config/storage";
import firestore from "src/apiService/firestore";
import auth from "src/apiService/auth";
import { isSalesFilterValid } from "src/enums/sale.enum";

export const fetchLoginApi = async (email: string, password: string) => {
  const userCredential: any = await auth().signInWithEmailAndPassword(email, password);
  const user: firebase.User = userCredential.user;
  const token: string = await user.getIdToken();
  return { token, uid: user.uid };
};

export const signupApi = async (email: string, password: string) => {
  const userCredential: any = await auth().createUserWithEmailAndPassword(email, password);
  const user: firebase.User = userCredential.user;
  const token: string = await user.getIdToken();
  return { token, uid: user.uid };
};

export const loginWithTokenApi = async (token: string) => {
  const userCredential: any = await auth().signInWithCustomToken(token);
  const user: firebase.User = userCredential.user;
  return { token, uid: user.uid };
};

export const logoutApi = () => auth().signOut();

export const resetPasswordApi = (email: string) => {
  return auth().sendPasswordResetEmail(email);
};

export async function updateUserApi(payload: any) {
  const db = firestore();
  const userId = await getUserId();
  const doc = db.collection('Users')
    .doc(userId);

  // Taken from POST `/user`:
  const data: any = {};

  if (payload.income_tax_rate)
    data.income_tax_rate = payload.income_tax_rate;

  if (payload.customer_id)
    data.customer_id = payload.customer_id;

  if (payload.pm_id)
    data.pm_id = payload.pm_id;

  if (payload.platforms)
    data.platforms = payload.platforms;

  if (isSalesFilterValid(payload.sales_filter)) {
    data.sales_filter = payload.sales_filter;
  }

  if ('salesQuickView' in payload)
    data.salesQuickView = payload.salesQuickView;

  if ('getStartedStep' in payload)
    data.getStartedStep = payload.getStartedStep;

  if ('inventoryType' in payload)
    data.inventoryType = payload.inventoryType;

  if ('checklist_completed' in payload)
    data.checklist_completed = payload.checklist_completed;

  if ('checklist_items' in payload)
    data.checklist_items = payload.checklist_items;

  if ('getStartedStart' in payload)
    data.getStartedStart = payload.getStartedStart;

  if ('getStartedEnd' in payload)
    data.getStartedEnd = payload.getStartedEnd;

  if ('dontShowDialogs' in payload)
    data.dontShowDialogs = payload.dontShowDialogs;

  return await doc.update(data);
}

export const fetchStripeLink = async () => {
  const RETURN_URL = document.location.origin;
  const { data } = await axiosClient.post("/portal", { return_url: RETURN_URL });
  return data;
};

export const getUserStatus = async (email: string) => {
  const { data } = await axiosClient.get(`/bubblecheck?email=${email}`);
  return data;
};

export const updateUserStatusApi = async (email: string) => {
  const { data } = await axiosClient.put(`/bubblereset?email=${email}`);
  return data;
};

export async function deleteAccountData() {
  const f = firebase.functions().httpsCallable("deleteAccountData");
  return await f();
}
