import { createAction } from "redux-actions";
import { 
  INVENTORY,
  START,
  FETCH_ITEMS,
  UPLOAD_ITEMS,
  DELETE_ITEMS,
  FETCH_ITEM,
  CREATE_ITEM,
  DELETE_ITEM,
  UPDATE_ITEM,
  SELECT_ITEMS,
  SET_FILTER,
  SET_PAGE_SIZE,
  RESET,
  FS_CREATE_ITEMS,
  FS_DELETE_ITEMS,
  FS_UPDATE_ITEMS,
} from "../common";

export const LOAD_SYNC_INVENTORY = "LOAD_SYNC_INVENTORY";

export const fetchItems = createAction(INVENTORY + FETCH_ITEMS + START);
export const deleteItems = createAction(INVENTORY + DELETE_ITEMS + START);

export const fetchItem = createAction(INVENTORY + FETCH_ITEM + START);
export const createItem = createAction(INVENTORY + CREATE_ITEM + START);
export const deleteItem = createAction(INVENTORY + DELETE_ITEM + START);
export const updateItem = createAction(INVENTORY + UPDATE_ITEM + START);

export const selectItems = createAction(INVENTORY + SELECT_ITEMS);
export const setFilter = createAction(INVENTORY + SET_FILTER);
export const setPageSize = createAction(INVENTORY + SET_PAGE_SIZE);
export const reset = createAction(INVENTORY + RESET);
export const loadNewSyncInventory = createAction(LOAD_SYNC_INVENTORY + START);

export const addInventoryRecords = createAction(INVENTORY + FS_CREATE_ITEMS);
export const updateInventoryRecords = createAction(INVENTORY + FS_UPDATE_ITEMS);
export const deleteInventoryRecords = createAction(INVENTORY + FS_DELETE_ITEMS);
