import React from "react";

function ReportExplanationText() {
  return (
    <>
      <p>
        A profit and loss statement summarizes and organizes your business
        revenue and expenses. The first part of the P&amp;L includes income
        (revenue/sales) and cost of goods sold (COGS).
      </p>
      <p>
        COGS are the expenses that are directly associated with your sales. This
        includes the cost of inventory that sold, transaction fees, shipping
        your sales, etc. This is different from cost of goods sold on your tax
        return, which only includes the cost of inventory that sold. The reason
        a P&amp;L combines these costs into COGS is to help you manage your
        business. By combining all the costs directly associated with how you
        make money, you can see how profitable your sales process is. Income
        minus COGS equals gross profit.
      </p>
      <p>
        The next section of the P&amp;L includes gross profit, other business
        expenses and income tax. Other business expenses are your business
        expenses that don’t directly relate to sales. Subtracting other business
        expenses and estimated income tax from gross profit gives you your net
        profit. Net profit is the total amount that you take home after expenses
        and taxes.
      </p>
    </>
  );
}

export default ReportExplanationText;
