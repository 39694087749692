import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Wrapper from "src/components/Wrapper";
import AdminHtml from "src/layouts/AdminHtml";

import Content from "./Content";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
});

function AdminHtmlGetStarted() {
  const classes = useStyles();
  return (
    <AdminHtml
      pageTitle="Get Started"
      pageDescription="How to set up your Genie"
    >
      <Wrapper className={classes.wrapper}>
        <Content />
      </Wrapper>
    </AdminHtml>
  );
}
export default AdminHtmlGetStarted;
