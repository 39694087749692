import firebase from "firebase/app";
import "firebase/storage";

let st: firebase.storage.Storage | null = null;

function storage(): firebase.storage.Storage {
  if (!st) {
    st = firebase.storage();

    if (
      process.env.NODE_ENV !== "production" &&
      typeof window !== "undefined" &&
      window.location.hostname === "localhost" &&
      process.env.REACT_APP_FIREBASE_FUNCTIONS_URL?.startsWith(
        "http://localhost"
      )
    ) {
      st.useEmulator("localhost", 9199);
    }
  }

  return st;
}

export default storage;
