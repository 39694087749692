import firebase from "firebase/app";
import "firebase/storage";
import {getUserId} from "../../config";
import firestore from "src/apiService/firestore";

const db = firestore();

export const acceptUserTerms = () => {
	const acceptUserTerms = firebase.functions().httpsCallable('updateUsersTOS');
	return getUserId().then((userId) => {
		return acceptUserTerms({userId: userId}).then((result) => {
			return true;
		}).catch((err) => {
			console.log(err);
			return false;
		})
	});
}

export async function increaseTourCounter() {
  const userId = await getUserId();
  await db.collection("Users")
    .doc(userId)
    .update({ tour_count: firebase.firestore.FieldValue.increment(1) });
}
