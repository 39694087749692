import React, { useMemo } from "react";
import { useLocation } from "react-router";

import { navigation } from "../../config/supportNavigation";
import { LOGO } from "../../assets/svgs";

import {
  Drawer,
  DrawerProps,
  List,
  ListItem,
  makeStyles,
} from "@material-ui/core";
import NavigatorItem from "./NavigatorItem";
import type { Theme } from "src/theme";

interface NavigatorOwnProps {
  title: string;
  subTitle: string;
  variant?: "permanent" | "persistent" | "temporary";
  open?: boolean;
}

interface NavigatorDispatchProps {
  updateUserChecklist: (update: any) => void;
}

export type NavigatorProps = NavigatorOwnProps &
  DrawerProps &
  NavigatorDispatchProps &
  any;

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    backgroundColor: "#fff",
    paddingTop: 15,
    paddingBottom: 15,
    justifyContent: "center",
  },
}));

function Navigator({
  title,
  subTitle,
  variant,
  onClose,
  updateUserChecklist,
  staticContext,
  ...other
}: NavigatorProps) {
  const classes = useStyles();
  const location = useLocation();
  const sorted = useMemo(() => {
    const sorted = [...navigation];
    sorted.sort((a, b) => a.order - b.order);
    return sorted;
  }, []);

  return (
    <Drawer
      variant={variant}
      {...other}
      ModalProps={{ ...other?.ModalProps, onBackdropClick: onClose }}
    >
      <List disablePadding>
        <ListItem className={classes.item} key="1">
          <img src={LOGO} alt={title} className="h-24" />
        </ListItem>
        <>
          {sorted.map(({ id: childId, Icon, title, href }) => {
            return (
              <NavigatorItem
                key={childId}
                primary={title}
                id={childId}
                Icon={Icon}
                to={href}
                active={location.pathname === href}
              />
            );
          })}
        </>
      </List>
    </Drawer>
  );
}

export default Navigator;
