import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

import {
  Button,
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import InfoTooltip from "src/components/InfoTooltip";
import { platformSelector } from "src/store/platform/selector";
import { itemOptionsSelector } from "src/store/itemOptions/selector";
import { inventoriesSelector } from "src/store/inventory/selector";

import { fetchItems as fetchPlatforms } from "src/store/platform/actions";
import { fetchItems as fetchItemOptions } from "src/store/itemOptions/actions";

import { setActiveDialog } from "src/store/adminHtml/actions";
import { clearUpdateMethod } from "src/store/common";

import { Inventory, InventoryFormError } from "src/interfaces";
import { METHOD_TYPE } from "src/enums";
import { userPlatformsSelector } from "src/store/system/selector";
import VendorField from "src/components/VendorField";
import NumberField from "src/components/NumberField";

const initFormValues: Inventory = {
  id: "",
  brand: "",
  category: "",
  department: "",
  item_title: "",
  location: "",
  notes: "",
  platforms_listed: [],
  sku: "",
  sub_category: "",
  purchase_price: 0,
  purchase_date: undefined,
  list_date: undefined,
  quantity: 1,
}

const AddMatchingInventory = ({ goBack, onSave, saleValues, render }: any) => {
  const dispatch = useDispatch();

  const userPlatforms = useSelector(userPlatformsSelector);
  const { items: platforms, loading: platformsLoading }: any = useSelector(platformSelector);
  const { items: itemOptions, loading: itemOptionsLoading }: any = useSelector(itemOptionsSelector);
  const { loading, loaded, method, error: apiError }: any = useSelector(inventoriesSelector);

  const [values, setValues] = useState<Inventory>(initFormValues);
  const [errors, setErrors] = useState<InventoryFormError>({});

  const [departments, setDepartments] = useState<Array<string>>([]);
  const [categories, setCategories] = useState<Array<string>>([]);
  const [subCategories, setSubCategories] = useState<Array<string>>([]);

  useEffect(() => {
    if (!platforms || !platforms.length) {
      dispatch(fetchPlatforms());
    }

    if (!itemOptionsLoading && (!itemOptions || !itemOptions.length)) {
      dispatch(fetchItemOptions({}));
    }
  }, []);

  useEffect(() => {
    if (loaded && !apiError && method === METHOD_TYPE.CREATE) {
      dispatch(clearUpdateMethod());
      dispatch(setActiveDialog(""));
    }
  }, [loaded])

  // useEffect(() => {
  //   if (apiError && !loading && (method == METHOD_TYPE.CREATE)) {
  //     toast.error(apiError);
  //   }
  // }, [apiError]);

  useEffect(() => {
    updateCategories();
  }, [values.department]);

  useEffect(() => {
    updateSubCategories();
  }, [values.category]);

  useEffect(() => {
    if (itemOptions && itemOptions.length) {
      setSubCategories([]);
      setCategories([]);
      const data = itemOptions
        .map((option:any) => option["Department"])
        .filter((d, i) => {
          const j = itemOptions.findIndex(e => e["Department"] === d);
          return i === j;
        })
        .sort();
      setDepartments(data);
    }
  }, [itemOptions]);

  useEffect(() => {
    setValues({ ...initFormValues, ...saleValues });
  }, [saleValues]);

  const updateSubCategories = () => {
    let data = itemOptions
      .filter((option:any) => option.Department === values.department && option.Category === values.category)
      .map((option:any) => option["Sub-Category"])
    data = data.filter((e, i) => {
      const j = data.indexOf(e);
      return i === j;
    }).sort();
    setSubCategories(data);
    if (data.indexOf(values.sub_category) === -1) {
      setValues({ ...values, sub_category: "" });
    }
  }

  const updateCategories = () => {
    let data = itemOptions
      .filter((option:any) => option.Department === values.department)
      .map((option:any) => option["Category"])
    data = data.filter((e, i) => {
      const j = data.indexOf(e);
      return i === j;
    }).sort();
    setSubCategories([]);
    setCategories(data);
    if (data.indexOf(values.category) === -1) {
      setValues({ ...values, category: "" });
    }
  }


  const handleChange = (prop: keyof Inventory) => (event, t?: any) => {
    let value = event.target.value;
    if ("department" === prop) {
      value = departments[Number(value)];
    } else if ("category" === prop) {
      value = categories[Number(value)];
    } else if ("sub_category" === prop) {
      value = subCategories[Number(value)];
    } else if ("platforms_listed" === prop) {
      value = t;
    } else if ("purchase_price" === prop) {
      value = +value || 0;
    }
    setValues({ ...values, [prop]: value });
  };

  const renderOption = (option, { inputValue }) => {
    const matches = match(option, inputValue);
    const parts = parse(option, matches);

    return (
      <div>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    );
  };

  const save = () => {
    onSave(values);
    goBack();
  };

  return render({
    title: "Add Inventory Information",
    main: (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} className="flex justify-end">
            <TextField
              error={errors.item_title ? true : false}
              helperText={errors.item_title}
              className="w-11/12"
              variant="outlined"
              margin="dense"
              label="Item Title"
              value={values.item_title}
              disabled={loading}
              onChange={handleChange("item_title")}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} className="flex justify-between">
            <InfoTooltip
              text="Source where you purchased the item."
              size={18}
            />
            <VendorField
              className="w-11/12"
              margin="dense"
              disabled={loading}
              value={values.vendor}
              onChange={(vendor) => {
                setValues((values) => ({
                  ...values,
                  vendor: vendor || "",
                }));
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="flex justify-between">
            <InfoTooltip text="Departments help to organize your inventory, they are the largest grouping to set inventory/sales apart." size={18} />
            <Autocomplete
              disabled={itemOptionsLoading}
              options={departments}
              value={values.department}
              className="w-11/12"
              autoHighlight
              onChange={(_e, value) => {
                setValues({ ...values, department: value || "" });
              }}
              renderInput={(params) =>
                <TextField
                  {...params}
                  error={errors.department ? true : false}
                  helperText={errors.department}
                  margin="dense"
                  label="Department"
                  className="w-full"
                  variant="outlined"
                />
              }
              renderOption={renderOption}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="flex justify-between">
            <InfoTooltip text="Categories help to organize your inventory, they are the second largest grouping to set inventory/sales apart." size={18} />
            <Autocomplete
              disabled={itemOptionsLoading}
              options={categories}
              value={values.category}
              autoHighlight
              className="w-11/12"
              onChange={(_e, value) => {
                setValues({ ...values, category: value || "" });
              }}
              renderInput={(params) =>
                <TextField
                  {...params}
                  error={errors.category ? true : false}
                  helperText={errors.category}
                  margin="dense"
                  label="Category"
                  className="w-full"
                  variant="outlined"
                />
              }
              renderOption={renderOption}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="flex justify-between">
            <InfoTooltip text="Sub-categories help to organize your inventory, they are the most specific grouping to set inventory/sales apart." size={18} />
            <Autocomplete
              disabled={itemOptionsLoading}
              options={subCategories}
              value={values.sub_category}
              autoHighlight
              className="w-11/12"
              onChange={(_e, value) => {
                setValues({ ...values, sub_category: value || "" });
              }}
              renderInput={(params) =>
                <TextField
                  {...params}
                  error={errors.sub_category ? true : false}
                  helperText={errors.sub_category}
                  margin="dense"
                  label="Sub category"
                  className="w-full"
                  variant="outlined"
                />
              }
              renderOption={renderOption}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="flex justify-end">
            <TextField
              error={errors.brand ? true : false}
              helperText={errors.brand}
              className="w-11/12"
              variant="outlined"
              margin="dense"
              label="Brand"
              value={values.brand}
              disabled={loading}
              onChange={handleChange("brand")}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="flex justify-between">
            <InfoTooltip text="Where you have stored the item." size={18} />
            <TextField
              error={errors.location ? true : false}
              helperText={errors.location}
              className="w-11/12"
              variant="outlined"
              margin="dense"
              label="Location"
              value={values.location}
              disabled={loading}
              onChange={handleChange("location")}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="flex justify-end">
            <TextField
              error={errors.sku ? true : false}
              helperText={errors.sku}
              className="w-11/12"
              variant="outlined"
              margin="dense"
              label="SKU"
              value={values.sku}
              disabled={loading}
              onChange={handleChange("sku")}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="flex justify-between">
            <InfoTooltip text="The amount you paid for the inventory." size={18} />
            <NumberField
              error={errors.purchase_price ? true : false}
              helperText={errors.purchase_price}
              className="w-11/12"
              variant="outlined"
              margin="dense"
              label="Purchase Price"
              value={values.purchase_price || ""}
              disabled={loading}
              onChange={handleChange("purchase_price")}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="flex justify-end">
            <Autocomplete
              multiple
              className="w-11/12"
              disabled={platformsLoading}
              options={[...platforms, ...(userPlatforms as [] || [])]}
              value={values.platforms_listed ? values.platforms_listed : []}
              onChange={handleChange("platforms_listed")}
              autoHighlight
              renderOption={renderOption}
              renderInput={(params) =>
                <TextField
                  {...params}
                  margin="dense"
                  label="Platforms Listed"
                  variant="outlined"
                  className="w-full"
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} className="flex justify-between">
            <InfoTooltip text="Enter any other information that you need in here." size={18} />
            <TextField
              error={errors.notes ? true : false}
              helperText={errors.notes}
              className="w-11/12"
              variant="outlined"
              margin="dense"
              label="Notes"
              value={values.notes}
              disabled={loading}
              onChange={handleChange("notes")}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="flex justify-between">
            <InfoTooltip text="The date that you purchased this item." size={18} />
            <KeyboardDatePicker
              disableToolbar
              className="w-11/12"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              margin="dense"
              label="Purchase Date"
              autoOk={true}
              required
              error={!!errors.purchase_date}
              helperText={errors.purchase_date}
              value={values.purchase_date || null}
              disabled={loading}
              onChange={(e: any, t: any) => {
                setValues({ ...values, purchase_date: new Date(e) });
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="flex justify-between">
            <InfoTooltip text="The date that you listed this item for sale." size={18} />
            <KeyboardDatePicker
              disableToolbar
              className="w-11/12"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              margin="dense"
              label="List Date"
              autoOk={true}
              value={values.list_date || null}
              disabled={loading}
              onChange={(e: any, t: any) => {
                setValues({ ...values, list_date: new Date(e) });
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="flex justify-between">
            <InfoTooltip text="The number of these items you’d like to create." size={18} />
            <TextField
              error={errors.quantity ? true : false}
              helperText={errors.quantity}
              className="w-11/12"
              variant="outlined"
              margin="dense"
              required
              label="Quantity"
              type="number"
              value={values.quantity}
              disabled={loading}
              onChange={handleChange("quantity")}
              InputProps={{
                inputProps: { min: 1 },
              }}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    ),
    actions: (
      <>
        <Button
          variant="contained"
          onClick={goBack}
        >Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={save}
        >Match
        </Button>
      </>
    ),
  });
};

export default AddMatchingInventory;
