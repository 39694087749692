import React, { useMemo } from "react";
import Tour from "reactour";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

const Steps = [
  {
    selector: "#record-return-details-section",
    content: (i, steps) => (props) =>
      (
        <>
          <DialogTitle>
            Record Refund - {i + 1}/{steps.length}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Add the details for the refund here. These fields will
              automatically pull the values from the sale. You may need to
              change these based on your refund’s details.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.goTo(i + 1)}
            >
              Next
            </Button>
          </DialogActions>
        </>
      ),
  },
  {
    skipIfInventoryIsDisabled: true,
    selector: "#record-return-item-back-section",
    content: (i, steps) => (props) =>
      (
        <>
          <DialogTitle>
            Record Refund - {i + 1}/{steps.length}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              If you don’t track inventory, you should always mark this as “No”.
              If you do track inventory, then only mark as “Yes” if the item was
              returned in a sellable condition.
            </DialogContentText>
            <DialogContentText>
              If you mark “Yes”, the item will be added back to inventory.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.goTo(i - 1)}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.goTo(i + 1)}
            >
              Next
            </Button>
          </DialogActions>
        </>
      ),
  },
  {
    selector: "#record-return-additional-fees-section",
    content: (i, steps) => (props) =>
      (
        <>
          <DialogTitle>
            Record Refund - {i + 1}/{steps.length}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              If you paid additional fees or paid for return shipping*, add
              these amounts here.
            </DialogContentText>
            <DialogContentText variant="caption">
              *An exception is if the shipping expense is already recorded in
              your “Expense Detail”. (Example: if you paid for the shipping
              through Pirate Ship and that cost is recorded in the “Expense
              Detail”, then leave the “Shipping Cost” blank)
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.goTo(i - 1)}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.close()}
            >
              Done
            </Button>
          </DialogActions>
        </>
      ),
  },
];

const useStyles = makeStyles((theme) => ({
  helper: {
    maxWidth: "80%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "500px",
    },
    padding: 0,
  },
}));

export interface WalkThroughProps {
  open: boolean;
  onClose: () => void;
  isInventoryDisabled: boolean;
}

export function WalkThrough({
  open,
  onClose,
  isInventoryDisabled,
}: WalkThroughProps) {
  const classes = useStyles();
  const steps = useMemo(() => {
    const steps = isInventoryDisabled
      ? Steps.filter(
          ({ skipIfInventoryIsDisabled }) => !skipIfInventoryIsDisabled
        )
      : Steps;
    return steps.map((s, i) => ({ ...s, content: s.content(i, steps) }));
  }, [isInventoryDisabled]);
  return (
    <Tour
      startAt={0}
      closeWithMask={false}
      disableFocusLock
      steps={steps}
      isOpen={open}
      onRequestClose={() => {
        onClose();
      }}
      showButtons={false}
      showCloseButton={false}
      showNavigation={false}
      showNavigationNumber={false}
      showNumber={false}
      disableDotsNavigation
      rounded={5}
      className={classes.helper}
      disableInteraction
    />
  );
}

export default WalkThrough;
