import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Dialog,
  DialogContent,
} from "@material-ui/core";

import { setActiveDialog, setWalkthroughId } from "../../store/adminHtml/actions";
import { adminHtmlSelector } from "../../store/adminHtml/selector";


interface DialogProps {
  open: boolean;
}

const WalkthroughDialog = ({ open } : DialogProps) => {
  const { walkthroughId }: any = useSelector(adminHtmlSelector);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setActiveDialog(""));
    dispatch(setWalkthroughId(""));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"body"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogContent>
        <iframe
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${walkthroughId}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </DialogContent>
    </Dialog>
  );
};

export default WalkthroughDialog;
