import { useEffect } from "react";

class WalkThroughMarker {
  canceled = false;
  div?: HTMLDivElement;
  parent?: HTMLElement;
  parentSelector?: string;
  selector: string;
  id: string;

  constructor(id: string, selector: string, parentSelector?: string) {
    this.id = id;
    this.selector = selector;
    this.parentSelector = parentSelector;
  }

  render(retries = 50) {
    if (this.canceled) return;

    const parent = this.parentSelector
      ? document.querySelector<HTMLElement>(this.parentSelector)
      : document.body;

    if (!parent) {
      if (retries <= 0) return;
      setTimeout(() => this.render(retries - 1), 100);
      return;
    }

    this.parent = parent;
    const elements = parent.querySelectorAll<HTMLElement>(this.selector);
    if (elements.length <= 0) {
      if (retries <= 0) return;
      setTimeout(() => this.render(retries - 1), 100);
      return;
    }
    let top = 0;
    let left = 0;

    let right = 0;
    let bottom = 0;

    for (const element of elements) {
      const rect = element.getBoundingClientRect();
      if (!this.isElementInViewport(element, rect, 5)) continue;
      if (!top || top > rect.top) top = rect.top;
      if (!left || left > rect.left) left = rect.left;
      const elementRight = window.innerWidth - rect.right;
      if (!right || right > elementRight) right = elementRight;
      const elementBottom = window.innerHeight - rect.bottom;
      if (!bottom || bottom > elementBottom) bottom = elementBottom;
    }

    const div = document.createElement("div");
    div.id = this.id;
    div.style.position = "fixed";
    div.style.top = `${top}px`;
    div.style.right = `${right}px`;
    div.style.bottom = `${bottom}px`;
    div.style.left = `${left}px`;
    div.style.display = "block";
    parent.appendChild(div);
    this.div = div;
  }

  isElementInViewport(element: HTMLElement, rect?: DOMRect, deep = 0): boolean {
    if (!rect) rect = element.getBoundingClientRect();
    if (
      rect.top < 0 ||
      rect.left < 0 ||
      rect.bottom >
        (window.innerHeight || document.documentElement.clientHeight) ||
      rect.right > (window.innerWidth || document.documentElement.clientWidth)
    )
      return false;

    let parent = element.parentNode as HTMLElement;
    for (let d = deep; d > 0; d--) {
      if (!parent) break;

      const parentRect = parent.getBoundingClientRect();
      if (
        rect.top < parentRect.top ||
        rect.left < parentRect.left ||
        rect.bottom > parentRect.bottom ||
        rect.right > parentRect.right
      )
        return false;

      parent = parent.parentNode as HTMLElement;
    }

    return true;
  }

  cancel() {
    if (this.div && this.parent) this.parent.removeChild(this.div);
    this.canceled = true;
  }
}

export function useWalkThroughMarker(
  open: boolean,
  id: string,
  selector: string,
  parentSelector?: string
) {
  useEffect(() => {
    if (!open) return;
    const marker = new WalkThroughMarker(id, selector, parentSelector);
    marker.render();
    return () => {
      marker.cancel();
    };
  }, [open, id, selector, parentSelector]);
}

export default WalkThroughMarker;
