import React, { type Dispatch, type SetStateAction } from "react";
import { compose } from "redux";
import { useSelector } from "react-redux";

import { getAmazonIntegrations } from "src/store/plaidIntegration/selector";
import withOnlyDevelopment from "src/utils/withOnlyDevelopment";
import withFeatureFlag from "src/utils/withFeatureFlag";

import type { DialogState } from "../types";
import PlatformIntegrationsTableRow from "../PlatformIntegrationsTableRow";

import { getAmazonOAuthUrl } from "src/apiService/modules/amazon";
import AmazonIntegrationTableRow from "./Row";

interface AmazonProps {
  hideInventory: boolean;
  setDialog: Dispatch<SetStateAction<DialogState>>;
}

function Amazon({ setDialog, hideInventory }: AmazonProps) {
  const amzonIntegrations = useSelector(getAmazonIntegrations);

  const handleAmazonAdd = async (integrationId?: string) => {
    const authResponse = await getAmazonOAuthUrl();
    window.location.href = authResponse.data.url;
  };

  if (amzonIntegrations.length === 0)
    return (
      <PlatformIntegrationsTableRow
        name="Amazon"
        platform="Amazon"
        isRunning={false}
        sync={false}
        inventory={false}
        onImport={handleAmazonAdd}
        hideInventory={hideInventory}
        onPullDataNow={handleAmazonAdd}
        onAdd={handleAmazonAdd}
        onSyncChanged={(sync) => handleAmazonAdd}
      />
    );
  return (
    <>
      {amzonIntegrations.map((integration) => (
        <AmazonIntegrationTableRow
          key={integration.id}
          integration={integration}
          hideInventory={hideInventory}
          setDialog={setDialog}
        />
      ))}
    </>
  );
}

export default compose<typeof Amazon>(
  withOnlyDevelopment,
  withFeatureFlag("amazon")
)(Amazon);
