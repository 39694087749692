import moment from "moment";
import { DateInSeconds } from "../interfaces/dateInSeconds.interface";

export const formatPhone = (value: string, previousValue?: string) => {
  if (!value) {
    return value;
  }
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) {
      return currentValue;
    }
    if (cvLength < 7) {
      return `(${currentValue.slice(0, 3)})${currentValue.slice(3)}`;
    }
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
};

export const formatBirthday = (date: string) => {
  return moment(date).format("MM/DD/yyyy");
};

export const formatCurrency = (params: any) => {
  const value = +params.value || 0;
  return value.toLocaleString(
    "en-US",
    {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      currency: "USD",
      style: "currency",
    }
  );
};

export const formatDollars = (amount: number) => {
  if (isNaN(amount)) {
    return "";
  }
  return `${amount < 0 ? "-" : ""}$${Math.abs(amount).toLocaleString(
    undefined, { minimumFractionDigits: 2, maximumFractionDigits:2 })}`;
};

export const formatDate = (dt: any, format = "MM/DD/yyyy") => {
  return moment(dt).format(format);
};

export const secondsToDate = (
  dateInSeconds: DateInSeconds | undefined | null
) => {
  if (dateInSeconds === undefined || dateInSeconds === null) return new Date();
  return moment((dateInSeconds.seconds || dateInSeconds._seconds) * 1000).toDate();
};
