import React, { useEffect, useRef } from "react";
import { Route, Redirect } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import "firebase/storage";
import "firebase/firestore";
import Tap from "@tapfiliate/tapfiliate-js";

import { TAPFILIATE_ACCOUNT_ID } from "../config";
import { getUserId } from "../config";
import AdminHtml from "../layouts/AdminHtml";
import LogOutPage from "../pages/LogOutPage";

import GetStartedPage from "../pages/GetStarted";
import ManagementPage from "../pages/ManagementPage";
import SalesPage from "../pages/SalesPage";
import InventoryPage from "../pages/InventoryPage";
import CashReconciliationPage from "../pages/CashReconciliationPage";
import ReportsPage from "../pages/ReportsPage";
import TransactionPage from "../pages/TransactionPage";
import IntegrationsPage from "../pages/Integrations";

import AddInventoryDialog from "../pages/RootDialogs/AddInventoryDialog";
import EnterPaymentMethodDialog from "../pages/RootDialogs/EnterPaymentMethodDialog";
import EmptyTablesDialog from "../pages/RootDialogs/EmptyTablesDialog";
import ZendeskHelpTour from "src/pages/RootDialogs/ZendeskHelpTour";
import DuplicateInventoryChecker from "../pages/RootDialogs/DuplicateInventoryChecker";
import ReportSaleDialog from "../pages/RootDialogs/ReportSaleDialog";
import ManageUploadsDialog from "../pages/RootDialogs/Uploads/ManageUploadsDialog";
import SalesMatching from "src/pages/RootDialogs/SalesMatching";
import SalesReview from "../pages/RootDialogs/SalesReview";

import WalkthroughDialog from "../pages/RootDialogs/WalkthroughDialog";
import UpgradePlanDialog from "src/pages/RootDialogs/UpgradePlanDialog";
import SetUpFeatureDialog from "src/pages/RootDialogs/SetUpFeatureDialog";
import MaintenanceActivitiesDialog from "src/pages/RootDialogs/MaintenanceActivitiesDialog";
import AddExpenseDialog from "src/pages/RootDialogs/AddExpenseDialog";
import WalkThroughNotificationsIconDialog from "src/pages/RootDialogs/WalkThroughNotificationsIconDialog";
import IntegrationSyncerDialog from "src/pages/RootDialogs/IntegrationSyncerDialog";

import { setActiveDialog } from "../store/adminHtml/actions";
import { WALKTHROUGH_IDS } from "../store/adminHtml/data";
// import { adminHtmlSelector } from "../store/adminHtml/selector";
import { activeDialogSelector } from "../store/adminHtml/selector";
import {
  addBankStatementRecords,
  updateBankStatementRecords,
  deleteBankStatementRecords,
} from "../store/bankStatement/actions";
import {
  addCashActivityRecords,
  updateCashActivityRecords,
  deleteCashActivityRecords,
} from "../store/cashActivity/actions";
import {
  addSalesRecords,
  updateSalesRecords,
  deleteSalesRecords,
  setTimeFilterIfNecessary as setTimeFilter,
} from "../store/sale/actions";
import {
  addInventoryRecords,
  updateInventoryRecords,
  deleteInventoryRecords,
} from "../store/inventory/actions";
import {
  addNumericOverviewRecords,
  updateNumericOverviewRecords,
  deleteNumericOverviewRecords,
} from "../store/numericOverview/actions";
import {
  addTransactionRecords,
  updateTransactionRecords,
  deleteTransactionRecords,
} from "../store/transaction/actions";
import {
  addUploadRecords,
  updateUploadRecords,
  deleteUploadRecords,
  addDownloadRecords,
  updateDownloadRecords,
  deleteDownloadRecords,
} from "../store/uploads/actions";

import { Switch } from "react-router-dom";
import {
  tapfiliateSelector,
  userCustomerIdSelector,
  userErrorSelector,
  selectUser,
} from "../store/system/selector";
import { clearSignupStatus, setUser } from "../store/system/actions";
import { fixSalesFilter } from "../enums";
import {addErrorAlertRecords, deleteErrorAlertRecords, updateErrorAlertRecords} from "../store/errorAlerts/actions";
import {
  addPlaidIntegrationRecords,
  deletePlaidIntegrationRecords,
  updatePlaidIntegrationRecords
} from "../store/plaidIntegration/actions";
import CategorizeExpensesDialog from "../pages/RootDialogs/CategorizeExpensesDialog";
import AddCashActivitiesDialog from "../pages/RootDialogs/AddCashActivitiesDialog";
import AddIntegrationAccountDialog from "../pages/RootDialogs/AddIntegrationAccountDialog";
import AcceptTermsDialog from "../pages/RootDialogs/AcceptTermsDialog";
import AddGeneralLedgerAccountDialog from "../pages/RootDialogs/AddGeneralLedgerAccountDialog";
import {
    addGeneralLedgerAccounts,
    deleteGeneralLedgerAccounts,
    updateGeneralLedgerAccounts
} from "../store/glAccounts/actions";
import {
    addVendors,
    deleteVendors,
    updateVendors
} from "src/store/vendor/actions";
import ReviewReturnsDialog from "src/pages/RootDialogs/ReviewReturnsDialog";
import UpdateIntegrationAccountDialog from "src/pages/RootDialogs/UpdateIntegrationAccountDialog";
import { increaseTourCounter } from "src/apiService/modules/users";
import firestore from "src/apiService/firestore";

import { userGetStartedHasFinishedSelector } from "src/store/system/selector";
import { userChecklistCompletedSelector } from "src/store/system/selector";

const db = firestore();

function DefaultRedirection() {
  const hasFinished = useSelector(userGetStartedHasFinishedSelector);
  const checklistCompleted = useSelector(userChecklistCompletedSelector);
  const user = useSelector(selectUser);
  if (!user) return null;
  return <Redirect to={hasFinished || checklistCompleted ? "/management" : "/get-started"} />
}

const PrivateRouter = () => {
  const dispatch = useDispatch();
  const salesListener = useRef<any>(null);
  const inventoryListener = useRef<any>(null);
  const bankStatementListener = useRef<any>(null);
  const cashActivityListener = useRef<any>(null);
  const transactionListener = useRef<any>(null);
  const numericOverviewListener = useRef<any>(null);
  const uploadListener = useRef<any>(null);
  const downloadListener = useRef<any>(null);
  const accountListener = useRef<any>(null);
  const vendorListener = useRef<any>(null);
  const userListener = useRef<any>(null);
  const errorAlertListener = useRef<any>(null);
  const plaidIntegrationListener = useRef<any>(null);
  const activeDialog = useSelector(activeDialogSelector);
  const tapfiliateReady = useSelector(tapfiliateSelector);
  const userNumericOverviewError = useSelector(userErrorSelector);
  const userCustomerId = useSelector(userCustomerIdSelector);
  const tourDataRef = useRef({
    displayed: false,
    tour_count: 0,
  });

  const startListeners = async () => {
    const userId = await getUserId();

    await new Promise<void>(rs => {
      userListener.current = db.collection('Users').doc(userId).onSnapshot(
        (doc: any) => {
          if (!doc.exists) {
            console.warn(`User document '${userId}' doesn't exist`);
            return;
          }

          const receivedUser = doc.data();
          tourDataRef.current.tour_count = receivedUser.tour_count || 0;

          rs();
          if(!receivedUser.acceptedTOS){
            dispatch(setActiveDialog("accept_terms"));
          }

          if (receivedUser && !userNumericOverviewError && receivedUser.noError) {
            toast.error("Your financials report couldn't be calculated because some of your data is off. Reach out to support@myresellergenie.com to resolve this issue.");
          }

          receivedUser.sales_filter = fixSalesFilter(receivedUser.sales_filter);

          dispatch(setUser(receivedUser));
          dispatch(setTimeFilter(receivedUser.sales_filter));
        }, (_) => {
          console.error(`Snapshot error when reading user data for user ${userId}.`);
        });
    });

    const startListener = (
      ref,
      collectionName,
      addAction,
      updateAction,
      deleteAction,
      callback?,
    ) => {
      let first = true;
      return new Promise<void>((rs, rj) => {
        ref.current = db.collection(collectionName)
          .where("user", "==", userId).onSnapshot(
            (snapshot) => {
              console.log(`received ${collectionName} snapshot`);
              const adds: any[] = [];
              const changes: any[] = [];
              const deletes: any[] = [];
              snapshot.docChanges().forEach((change) => {
                const data = (collectionName === "Downloads") ? { ...change.doc.data(), id: change.doc.id } :
                  change.doc.data();
                if (change.type === "added") {
                  adds.push(data);
                } else if (change.type === "modified") {
                  changes.push(data);
                } else if (change.type === "removed") {
                  deletes.push(data);
                }
              });
              if (adds.length) {
                dispatch(addAction(adds));
              }
              if (updateAction && changes.length) {
                dispatch(updateAction(changes));
              }
              if (deletes.length) {
                dispatch(deleteAction(deletes));
              }

              if (callback)
                callback(!adds.length && !changes.length && !deletes.length);
              if (first) {
                first = false;
                rs();
              }
            }, (_) => {
              console.error(`Snapshot error when reading from ${collectionName} collection as user ${userId}`);
              if (first) {
                first = false;
                rj(_);
              }
            });
      });
    }
    console.log('listening');
    await startListener(salesListener, "Sales", addSalesRecords, updateSalesRecords, deleteSalesRecords);
    await startListener(inventoryListener, "Inventory", addInventoryRecords, updateInventoryRecords, deleteInventoryRecords);
    await startListener(bankStatementListener, "Bank_Statements", addBankStatementRecords, updateBankStatementRecords, deleteBankStatementRecords);
    await startListener(cashActivityListener, "Cash_Activities", addCashActivityRecords, updateCashActivityRecords, deleteCashActivityRecords);
    await startListener(transactionListener, "Transactions", addTransactionRecords, updateTransactionRecords, deleteTransactionRecords);
    await startListener(
      numericOverviewListener,
      "Numeric_Overview",
      addNumericOverviewRecords,
      updateNumericOverviewRecords,
      deleteNumericOverviewRecords,
      (empty) => {
        // XXX: this isn't exactly the best approach, it should be probably fixed in the future
        if (tourDataRef.current.displayed) return;
        tourDataRef.current.displayed = true;

        if (empty) {
          dispatch(addNumericOverviewRecords([]));
          dispatch(setActiveDialog("empty_tables"));
        } else if (tourDataRef.current.tour_count < 3) {
          tourDataRef.current.tour_count++;
          increaseTourCounter();
          dispatch(setActiveDialog("zendesk_help_tour"));
        }
      },
    );
    await startListener(uploadListener, "Uploads", addUploadRecords, updateUploadRecords, deleteUploadRecords);
    await startListener(downloadListener, "Downloads", addDownloadRecords, updateDownloadRecords, deleteDownloadRecords);
    await startListener(accountListener, "GL_Accounts", addGeneralLedgerAccounts, updateGeneralLedgerAccounts, deleteGeneralLedgerAccounts);
    await startListener(plaidIntegrationListener, "Plaid_Integrations", addPlaidIntegrationRecords, updatePlaidIntegrationRecords, deletePlaidIntegrationRecords);
    await startListener(errorAlertListener, "Error_Alerts", addErrorAlertRecords, updateErrorAlertRecords, deleteErrorAlertRecords);
    await startListener(vendorListener, "Vendors", addVendors, updateVendors, deleteVendors);
  };

 const stopListeners = () => {
   if (salesListener.current) {
     salesListener.current();
   }
   if (inventoryListener.current) {
     inventoryListener.current();
   }
   if (bankStatementListener.current) {
     bankStatementListener.current();
   }
   if (cashActivityListener.current) {
     cashActivityListener.current();
   }
   if (transactionListener.current) {
     transactionListener.current();
   }
   if (numericOverviewListener.current) {
     numericOverviewListener.current();
   }
   if (uploadListener.current) {
     uploadListener.current();
   }
   if (userListener.current) {
     userListener.current();
   }
   if (plaidIntegrationListener.current){
     plaidIntegrationListener.current();
   }
   if (errorAlertListener.current) {
       errorAlertListener.current();
   }
 }

  useEffect(() => {
    startListeners();
    return stopListeners;
  }, []);

  useEffect(() => {
    if (userCustomerId && tapfiliateReady) {
      const referralCode = localStorage.getItem("referralCode");
      if (referralCode) {
        Tap.init(TAPFILIATE_ACCOUNT_ID, { integration: "stripe" }, null, { referral_code: referralCode });
        Tap.trial(userCustomerId);
      }
      dispatch(clearSignupStatus());
    }
  })

  return (
    <>
      <Switch>
        <Route exact path="/logout" component={LogOutPage} />
        <Route path="/get-started" exact>
          <GetStartedPage />
        </Route>
        <Route path="/management" exact>
          <AdminHtml
            pageTitle="Management Dashboard"
            pageDescription="An overview of your numbers"
            walkthroughId={WALKTHROUGH_IDS.MANAGEMENT}
          >
            <ManagementPage />
          </AdminHtml>
        </Route>
        <Route path="/analytics" exact>
          <AdminHtml
            pageTitle="Sales"
            pageDescription="Powerful analytics (premium and ultimate only) and a detail of sales"
            walkthroughId={WALKTHROUGH_IDS.SALES}
          >
            <SalesPage />
          </AdminHtml>
        </Route>
        <Route path="/inventory" exact>
          <AdminHtml
            pageTitle="Inventory"
            pageDescription="A detail of inventory"
            walkthroughId={WALKTHROUGH_IDS.INVENTORY}
          >
            <InventoryPage />
          </AdminHtml>
        </Route>
        <Route path="/cash" exact>
          <AdminHtml
            pageTitle="Cash Reconciliation"
            pageDescription="Track the money your business has"
            walkthroughId={WALKTHROUGH_IDS.CASH_RECONCILIATION}
          >
            <CashReconciliationPage />
          </AdminHtml>
        </Route>
        <Route path="/transaction" exact>
          <AdminHtml
            pageTitle="Expenses"
            pageDescription="A detail of business expenses"
            walkthroughId={WALKTHROUGH_IDS.EXPENSE_DETAIL}
          >
            <TransactionPage />
          </AdminHtml>
        </Route>
        <Route path="/reports" exact>
          <AdminHtml pageTitle="Reseller Reports" pageDescription="Compile Your Business Data">
            <ReportsPage />
          </AdminHtml>
        </Route>
        <Route path="/integrations" exact>
          <IntegrationsPage />
        </Route>
        <Route component={DefaultRedirection} />
      </Switch>
      <WalkthroughDialog open={activeDialog === "walkthrough"} />
      <ReportSaleDialog open={activeDialog === "report_sale"} />
      <AddInventoryDialog open={activeDialog === "add_inventory"} />
      <ManageUploadsDialog open={activeDialog === "manage_uploads"} />
      <EmptyTablesDialog open={activeDialog === "empty_tables"} />
      <ZendeskHelpTour open={activeDialog === "zendesk_help_tour"} />
      <SalesMatching open={activeDialog === "match_sales"} />
      <SalesReview open={activeDialog === "review_sales"} />
      <DuplicateInventoryChecker open={activeDialog === "inventory_sync"} />
      <AddIntegrationAccountDialog open={activeDialog === "add_cash_account"} type={"cash"}/>
      <UpdateIntegrationAccountDialog open={activeDialog === "update_expense_account"} />
      <CategorizeExpensesDialog open={activeDialog === "categorize_expenses"}/>
      <AddCashActivitiesDialog open={activeDialog === "cash_activities"}/>
      <AcceptTermsDialog open={activeDialog === "accept_terms"}/>
      <ReviewReturnsDialog open={activeDialog === "returns_review"} />
      <AddGeneralLedgerAccountDialog open={activeDialog === "add_gl_account"} />
      <UpgradePlanDialog open={activeDialog === "upgrade_plan"} />
      <EnterPaymentMethodDialog />
      <SetUpFeatureDialog />
      <MaintenanceActivitiesDialog open={activeDialog === "maintenance_activities_dialog"} />
      <AddExpenseDialog open={activeDialog === "add_expense"} />
      <WalkThroughNotificationsIconDialog open={activeDialog === "walk_through_notifications_icon"} />
      <IntegrationSyncerDialog />
    </>
  );
};

export default PrivateRouter;
