import React, {
  useState,
  useEffect,
  useRef,
  type MutableRefObject,
  type HTMLAttributes,
  type ReactNode,
} from "react";
import Papa from "papaparse";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText, {
  DialogContentTextProps,
} from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import Alert from "@material-ui/lab/Alert";

import LoadableButton from "src/components/LoadableButton";

export interface BulkEditDialogProps<T> {
  open: boolean;
  onClose: () => void;
  bulkEdit: (
    rows: T[],
    metadata: object
  ) => Promise<{
    success?: boolean;
    errors: (
      | { message: string; index: number }
      | { message: string; id: string }
    )[];
  }>;
  title: string;
  label: string;
  button?: string;
  children?: ReactNode | undefined;
}

const useStyles = makeStyles((theme) => ({
  list: {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 0, 1.5, 0),
  },
  input: {
    display: "none",
  },
  label: {
    ...theme.typography.body1,
    color: theme.palette.primary.main,
    cursor: "pointer",
    display: "block",
    marginRight: theme.spacing(1),
  },
  link: {
    display: "block",
  },
}));

type ContentProps<T> = Omit<BulkEditDialogProps<T>, "open"> & {
  preventCloseRef: MutableRefObject<boolean>;
};

function Content<T>({
  onClose,
  preventCloseRef,
  bulkEdit,
  title,
  children,
  label,
  button = "Upload",
}: ContentProps<T>) {
  const classes = useStyles();
  const [file, setFile] = React.useState<File | null>(null);
  const [state, setState] = useState<
    undefined | "submitting" | { type: "error"; href: string; length: number }
  >();
  const submitting = state === "submitting";

  const handleSubmit = async () => {
    if (!file) return;
    preventCloseRef.current = true;
    setState("submitting");
    let clearState = true;
    try {
      const text = await new Promise((rs, rj) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => rs(reader.result as string));
        reader.addEventListener("error", (e) => rj(e));
        reader.readAsText(file);
      });
      const data = Papa.parse(text, { header: true, skipEmptyLines: true });
      if (data.errors?.length) {
        toast.error("Invalid csv file");
      } else {
        if (data.data.length) {
          // upload
          const ret = await bulkEdit(data.data, {
            name: file.name,
            type: file.type,
            lastModified: file.lastModified,
            size: file.size,
          });
          if (ret.success) {
            toast.success("Bulk Edit has been processed successfully!");
            onClose();
          } else if (ret.errors) {
            const errorsByRow: Record<number, string[]> = {};
            for (const error of ret.errors) {
              const index =
                "index" in error
                  ? error.index
                  : data.data.findIndex((r) => r.id === error.id);
              if (!errorsByRow[index]) errorsByRow[index] = [];
              errorsByRow[index].push(error.message);
            }

            clearState = false;
            setState({
              type: "error",
              href: `data:text/plain;charset=utf-8,${encodeURIComponent(
                Papa.unparse(
                  Object.entries(errorsByRow).map(([index, errors]) => ({
                    ...data.data[index],
                    error: errors.join(", "),
                  }))
                )
              )}`,
              length: Object.keys(errorsByRow).length,
            });
          }
        }
      }
    } catch (e) {
      toast.error((e as any).message);
    }
    preventCloseRef.current = false;
    if (clearState) setState(undefined);
  };

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    // setError("");
    const input: HTMLInputElement = e.target;
    if (input.files && input.files.length) {
      setFile(input.files[0]);
    }
  };

  useEffect(() => {
    preventCloseRef.current = false;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isError =
    typeof state === "object" && "type" in state && state.type === "error";

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      {isError ? (
        <>
          <DialogContent>
            <Alert severity="error">
              {state.length > 1
                ? `${state.length} rows have errors and were not imported.`
                : `${state.length} row has errors and was not imported.`}
              <Link
                variant="body2"
                href={state.href}
                download="rejected-rows.csv"
                className={classes.link}
              >
                Download .csv with rows that were not imported
              </Link>
            </Alert>
          </DialogContent>
          <DialogActions>
            <LoadableButton
              color="primary"
              variant="contained"
              onClick={onClose}
            >
              Close
            </LoadableButton>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent>
            {children}
            <Box display="flex">
              <label className={classes.label}>
                <input
                  disabled={submitting}
                  accept="text/csv"
                  type="file"
                  onChange={handleFile}
                  className={classes.input}
                />
                {label}
              </label>
              {file ? (
                <Typography variant="body1">- {file.name}</Typography>
              ) : null}
            </Box>
          </DialogContent>
          <DialogActions>
            <LoadableButton
              color="primary"
              variant="contained"
              disabled={!file}
              onClick={handleSubmit}
              loading={submitting}
            >
              {button}
            </LoadableButton>
          </DialogActions>
        </>
      )}
    </>
  );
}

function BulkEditDialog<T>({
  open,
  onClose,
  ...props
}: BulkEditDialogProps<T>) {
  const preventCloseRef = useRef(false);

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (preventCloseRef.current) return;
        onClose();
      }}
      scroll="body"
      fullWidth={true}
      maxWidth="sm"
    >
      <Content onClose={onClose} preventCloseRef={preventCloseRef} {...props} />
    </Dialog>
  );
}

const useContentTextStyles = makeStyles({
  root: {
    marginBottom: 0,
  },
});

export function BulkEditDialogText(props: DialogContentTextProps) {
  const classes = useContentTextStyles();
  return <DialogContentText className={classes.root} {...props} />;
}

const useUlStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 0, 1.5, 0),
  },
}));

export function BulkEditDialogTextList(props: HTMLAttributes<HTMLUListElement>) {
  const classes = useUlStyles();
  return <ul className={classes.root} {...props} />;
}

export function BulkEditDialogTextListItem(
  props: HTMLAttributes<HTMLLIElement>
) {
  return <li {...props} />;
}

export default BulkEditDialog;
