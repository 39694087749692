import { createSelector } from "reselect";
import { AppState } from "..";
import { defaultGeneralLedgerAccountSelector } from "../transaction/selector";
import { userGetInventoryTypeSelector } from "../system/selector";

const InventoryPurchaseGeneralLedgerAccount = {
  "General Ledger Account": "Inventory Purchases",
  Type: "Other COGS",
};
const getGeneralLedgerAccountState = (state: AppState) => state.glAccounts;

export const getGeneralLedgerAccounts = createSelector(
  getGeneralLedgerAccountState,
  (state) => state.items
);

export const generalLedgerAccountsSelector = createSelector(
  [
    defaultGeneralLedgerAccountSelector,
    getGeneralLedgerAccounts,
    userGetInventoryTypeSelector,
  ],
  (defaultGeneralLedgerAccounts, userGeneralLedgerAccounts, inventoryType) => {
    return [
      ...(defaultGeneralLedgerAccounts || []),
      ...(inventoryType === "cash"
        ? [InventoryPurchaseGeneralLedgerAccount]
        : []),
      ...(userGeneralLedgerAccounts || []).map((ugla) => ({
        id: ugla.id,
        "General Ledger Account": ugla.name,
        Type: ugla.type,
      })),
    ];
  }
);
