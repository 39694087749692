import firebase from "firebase/app";
import { deleteIntegration } from "./plaid";

export async function etsyRequestAuthorizationCode(args?: { back: string }) {
  const f = firebase.functions().httpsCallable("etsy-requestAuthorizationCode");
  return await f(args);
}

export async function etsyRequestAccessToken(args: {
  authorizationCode: string;
  state: string;
}) {
  const f = firebase.functions().httpsCallable("etsy-requestAccessToken");
  return await f(args);
}

export async function etsyDeleteIntegration() {
  const f = firebase.functions().httpsCallable("etsy-deleteIntegration");
  return await f();
}

export async function etsyFetchInventory() {
  const f = firebase.functions().httpsCallable("etsy-inventoryImport");
  return await f();
}
