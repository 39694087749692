import React from "react";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { GridColumnHeaderParams } from "@material-ui/x-grid";
import { Color } from "src/components/InfoTooltip";

const IconStyle = { fontSize: "0.875rem", margin: "8px", color: Color };

function renderHeaderLabel(params: GridColumnHeaderParams) {
  const tooltip = params.colDef.description;

  return (
    <Box
      className="MuiDataGrid-columnHeaderTitle"
      display="flex"
      alignItems="center"
    >
      {params.colDef.headerName}
      {tooltip ? (
        <Tooltip title={tooltip}>
          <InfoIcon style={IconStyle} />
        </Tooltip>
      ) : null}
    </Box>
  );
}

export default renderHeaderLabel;
