import ServiceClient from "src/utils/extension/ServiceClient";
import { getPoshmarkCookies } from "src/utils/extension/poshmark";

const PMVersion = "2024.34.0";
const AppVersion = "5.4.0";

export class NotLoggedError extends Error {
  constructor() {
    super("You must be logged in Poshmark to perform the sync process.");
  }
}

export type FetchInventoryStatus =
  | "all"
  | "available"
  | "coming_soon"
  | "sold_out";

export interface FetchInventoryParams {
  summarize?: boolean;
  appVersion?: string;
  pmVersion?: string;
  maxId?: string;
  count?: number;
  sortBy?: string;
  inventoryStatus?: FetchInventoryStatus[];
}

class PoshmarkClient extends ServiceClient {
  async getUserId() {
    const cookies = await getPoshmarkCookies(this.extensionId);
    const jwt = cookies.find((c) => c.name === "jwt")?.value;
    if (!jwt) throw new NotLoggedError();
    try {
      const userId = JSON.parse(atob(jwt.split(".")[1])).user_id;
      return userId;
    } catch (e) {
      throw new Error("Invalid poshmark JWT", e as Error);
    }
  }

  async fetchUser(uid: string) {
    const res = await this.fetch(`https://poshmark.com/vm-rest/users/${uid}`, {
      headers: {
        Accept: "application/json",
      },
      method: "GET",
    });

    if (!res.ok) throw new Error(`Couldn't get user ${uid} (not ok)`);

    const json = res.data;

    if (json.error || !json.data)
      throw new Error(`Couldn't get user ${uid} (error / no data)`);

    return json;
  }

  async fetchSales(
    uid: string,
    {
      maxId,
      pmVersion = PMVersion,
      boughtDate,
      status,
    }: {
      maxId?: string;
      pmVersion?: string;
      boughtDate?: string;
      status?: string[];
    } = {}
  ) {
    const request = {
      filters: {
        // bought_date: { start: "2023-08-04T00:00:00-03:00", end: "2023-10-04T00:00:00-03:00" }
        ...(boughtDate ? { bought_date: boughtDate } : {}),
        ...(status ? { status } : {}),
      },
      ...(maxId ? { max_id: maxId } : {}),
    };
    const params = {
      request: JSON.stringify(request),
      pm_version: pmVersion,
    };

    const url = `https://poshmark.com/vm-rest/users/${uid}/orders/sales?${Object.entries(
      params
    )
      .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
      .join("&")}`;
    const res = await this.fetch(url, {
      headers: {
        Accept: "application/json",
      },
      method: "GET",
    });

    if (!res.ok) {
      console.error(res);
      console.error("(poshmark) getSale failed (status code)", {
        url,
        body: res.data,
        status: res.status,
      });

      throw new Error("(poshmark) getSale Failed (status code)");
    }

    const json = res.data;

    if (json.error) {
      console.error("(poshmark) getSale failed (error property)", {
        url,
        json,
      });

      throw new Error("(poshmark) getSale Failed (error property)");
    }

    return json;
  }

  async *fetchSalesFull(poshmarkUserId: string, extra = {}) {
    let maxId;
    do {
      const sales = await this.fetchSales(poshmarkUserId, { ...extra, maxId });
      if (sales.error) {
        console.error("(poshmark) fetchSalesFull failed", {
          poshmarkUserId,
          extra,
          maxId,
          sales,
        });
        throw new Error("(poshmark) fetchSalesFull failed");
      }

      yield {
        sales: sales.data.sales_summary,
        meta: sales.meta,
        response: sales,
      };

      maxId =
        sales.meta && sales.meta.next_max_id ? sales.meta.next_max_id : null;
    } while (maxId);
  }

  async fetchSaleDetail(saleId: string) {
    const url = `https://poshmark.com/order/sales/${saleId}`;
    const res = await this.fetch(url, {
      headers: {
        Accept: "*/*",
      },
      method: "GET",
    });

    let text = res.data;
    if (!res.ok) {
      throw new Error(
        `getSalesDetail failed (url=${url} status=${res.status})`
      );
    }

    let needle = "<script>window.__INITIAL_STATE__=";
    let index = text.indexOf(needle);
    text = text.substring(index + needle.length);
    index = text.indexOf("</script>");
    text = text.substring(0, index);
    needle = "};";
    index = text.indexOf(needle);
    text = text.substring(0, index + needle.length - 1);
    return JSON.parse(text).$_order_details;
  }

  async fetchInventoryItem(
    id,
    { appVersion = AppVersion, pmVersion = PMVersion } = {}
  ) {
    const params = {
      app_version: appVersion,
      pm_version: pmVersion,
    };
    const res = await this.fetch(
      `https://poshmark.com/vm-rest/posts/${id}?${Object.entries(params)
        .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
        .join("&")}`,
      {
        headers: {
          Accept: "application/json",
        },
        method: "GET",
      }
    );
    const json = res.data;
    return json;
  }

  async fetchInventory(
    username: string,
    {
      summarize = true,
      appVersion = AppVersion,
      pmVersion = PMVersion,
      maxId,
      count = 50,
      inventoryStatus = ["all"],
      sortBy,
    }: FetchInventoryParams = {}
  ) {
    const request = {
      filters: {
        department: "All",
        inventory_status: inventoryStatus,
      },
      query_and_facet_filters: { creator_id: username },
      experience: "all",
      ...(maxId ? { max_id: maxId } : {}),
      ...(sortBy ? { sort_by: sortBy } : {}),
      count,
    };
    // summarize=true&app_version=2.55&pm_version=2023.38.0
    const params = {
      request: JSON.stringify(request),
      ...(summarize ? { summarize: true } : {}),
      app_version: appVersion,
      pm_version: pmVersion,
    };

    const res = await this.fetch(
      `https://poshmark.com/vm-rest/users/${username}/posts/filtered?${Object.entries(
        params
      )
        .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
        .join("&")}`,
      {
        headers: {
          Accept: "application/json",
          Referer: `https://poshmark.com/closet/${username}`,
        },
        method: "GET",
      }
    );

    const json = res.data;
    return json;
  }

  async *fetchInventoryFull(
    username: string,
    params: FetchInventoryParams = {}
  ) {
    const items = [];
    let maxId;
    do {
      const inventory = await this.fetchInventory(username, {
        ...params,
        maxId,
      });

      if (inventory.error) {
        throw new Error("(poshmark) getInventoryFull failed");
      }

      yield {
        items: inventory.data,
        response: inventory,
      };
      maxId =
        inventory.more && inventory.more.next_max_id
          ? inventory.more.next_max_id
          : null;
    } while (maxId);

    return items;
  }
}

export default PoshmarkClient;
