import * as React from "react";
import { useLocation } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import queryString from 'query-string';

import Container from "@material-ui/core/Container";

export const AuthCallbackPage = () => {
  const location = useLocation();
  const search = location.search;

  React.useEffect(() => {
    const query: {code?: string} = queryString.parse(search);
    if (query.code) {
      if (window.opener) {
        window.opener.postMessage({ source: 'eBay', code: query.code }, '*');
        window.close();
      }
    }
  }, [search])

  return (
    <Container component="main" maxWidth="xs">
      <Box className="flex justify-center items-center h-screen">
        <CircularProgress size={50} />
      </Box>
    </Container>
  );
}

export default AuthCallbackPage;
