import React from "react";
import { useSelector } from "react-redux";

import Tooltip from "@material-ui/core/Tooltip";
import HomeIcon from "@material-ui/icons/Home";
import BusinessIcon from "@material-ui/icons/Business";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AddBoxIcon from "@material-ui/icons/AddBox";
import ReceiptIcon from "@material-ui/icons/Receipt";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CreditCardIcon from "@material-ui/icons/CreditCard";

import {
  userGetStartedStepSelector,
  userGetInventoryTypeSelector,
  userChecklistCompletedSelector,
  userGetStartedHasFinishedSelector,
} from "src/store/system/selector";
import type {
  NavigatorEntry,
  NavigatorEntryHrefRendererProps,
} from "src/interfaces/navigator";

import NavigatorItem from "src/components/Navigator/NavigatorItem";

function GetStartedNavigatorItem({
  active,
  entry,
}: NavigatorEntryHrefRendererProps) {
  const hasFinished = useSelector(userGetStartedHasFinishedSelector);
  const initialStep = useSelector(userGetStartedStepSelector);
  const checklistCompleted = useSelector(userChecklistCompletedSelector);

  if (hasFinished) return null;
  if (checklistCompleted) return null;

  return (
    <Tooltip title="Click here to begin the set-up process">
      <NavigatorItem
        id={entry.id}
        Icon={entry.Icon}
        primary={
          initialStep &&
          initialStep !== "inventory:how-do-you-account-for-your-inventory"
            ? "Resume Set Up"
            : entry.title
        }
        to={entry.href}
        active={active}
      />
    </Tooltip>
  );
}

function InventoryNavigatorItem({ active, entry }) {
  const inventoryType = useSelector(userGetInventoryTypeSelector);

  if (inventoryType === "cash") return null;

  return (
    <NavigatorItem
      id={entry.id}
      Icon={entry.Icon}
      primary={entry.title}
      to={entry.href}
      active={active}
    />
  );
}

function AddInventoryNavigatorItem({ entry, dispatch }) {
  const inventoryType = useSelector(userGetInventoryTypeSelector);

  if (inventoryType === "cash") return null;

  return (
    <NavigatorItem
      onClick={() => {
        dispatch(entry.dialog);
      }}
      Icon={entry.Icon}
      primary={entry.title}
    />
  );
}

export const navigation: Array<NavigatorEntry> = [
  {
    id: "get-started",
    title: "Get Started",
    Icon: CheckBoxIcon,
    href: "/get-started",
    render: (props) => <GetStartedNavigatorItem {...props} />,
  },
  {
    id: "management",
    title: "Management",
    Icon: HomeIcon,
    href: "/management",
  },
  {
    id: "analytics",
    title: "Sales",
    Icon: AttachMoneyIcon,
    href: "/analytics",
  },
  {
    id: "inventory",
    title: "Inventory",
    Icon: AssignmentIcon,
    href: "/inventory",
    render: (props) => <InventoryNavigatorItem {...props} />,
  },
  {
    id: "cash_reconciliation",
    title: "Cash Reconciliation",
    Icon: AccountBalanceIcon,
    href: "/cash",
  },
  {
    id: "transaction",
    title: "Expense Detail",
    Icon: ReceiptIcon,
    href: "/transaction",
  },
  {
    id: "reseller_reports",
    title: "Reseller Reports",
    Icon: BusinessIcon,
    href: "/reports",
  },
  {
    id: "report_sale",
    dialog: "report_sale",
    title: "Report Sale",
    Icon: TrendingUpIcon,
  },
  {
    id: "add_inventory",
    dialog: "add_inventory",
    title: "Add Inventory",
    Icon: AddBoxIcon,
    render: (props) => <AddInventoryNavigatorItem {...props} />,
  },
  {
    id: "add_expense",
    dialog: "add_expense",
    title: "Add Expense",
    Icon: CreditCardIcon,
  },
  {
    id: "integrations",
    title: "Integrations",
    Icon: CheckBoxIcon,
    href: "/integrations",
  },
];
