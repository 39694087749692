import React, { forwardRef } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import GoldIcon from "src/components/GoldIcon";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";

const ActiveColor = "#184461";

function concatenateAnimation(
  animationName: string,
  durationSeconds: number,
  delay: number,
  times: number,
  betweenDelay: number,
  cycles: number
) {
  let initialDelay = 0;
  const animation: string[] = [];
  for (let cycle = 0; cycle < cycles; cycle++) {
    for (let time = 0; time < times; time++) {
      animation.push(
        `${durationSeconds}s linear ${initialDelay}s ${animationName}`
      );
      initialDelay += delay;
    }

    initialDelay += betweenDelay;
  }

  return animation.join(", ");
}

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: ActiveColor,
    position: "relative",
    overflow: "hidden",
    "&:hover": {
      backgroundColor: lighten(ActiveColor, 0.25),
    },
    "&:after": {
      content: "''",
      position: "absolute",
      top: "-110%",
      left: "-210%",
      width: "200%",
      height: "200%",
      transform: "rotate(30deg)",
      background:
        "linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0.0) 100%)",
      transitionProperty: "left, top, opacity",
      transitionDuration: "0.7s, 0.7s, 0.15s",
      transitionTimingFunction: "ease",

      animation: concatenateAnimation("$shine", 2.5, 4, 3, 60, 5),
    },
  },
  icon: {
    width: 32,
    height: 32,
    animation: concatenateAnimation("$shake", 4, 4, 3, 60, 5),
  },
  "@keyframes shake": {
    "0%": {
      transform: "rotate(-15deg)",
    },
    "4%": {
      transform: "rotate(15deg)",
    },
    "8%, 24%": {
      transform: "rotate(-18deg)",
    },
    "12%, 28%": {
      transform: "rotate(18deg)",
    },
    "16%": {
      transform: "rotate(-22deg)",
    },
    "20%": {
      transform: "rotate(22deg)",
    },
    "32%": {
      transform: "rotate(-12deg)",
    },
    "36%": {
      transform: "rotate(12deg)",
    },
    "40%, 100%": {
      transform: "rotate(0deg)",
    },
  },
  "@keyframes shine": {
    "0%": {
      top: "-110%",
      left: "-210%",
    },
    "50%, 100%": {
      top: "-30%",
      left: "-30%",
    },
  },
}));

function GoldIconButtonRender({ className, ...props }: IconButtonProps, ref) {
  const classes = useStyles();
  return (
    <IconButton
      className={clsx(classes.button, className)}
      {...props}
      ref={ref}
    >
      <GoldIcon className={classes.icon} />
    </IconButton>
  );
}

const GoldIconButton = forwardRef(GoldIconButtonRender);

export default GoldIconButton;
