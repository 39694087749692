import { createSelector } from "reselect";
import { AppState } from "..";

const getZendeskState = (state: AppState) => state.zendesk;
const getZendeskSearches = createSelector(
  getZendeskState,
  (zendesk) => zendesk.searches
);

export const getSearchByQuery = createSelector(
  [getZendeskSearches, (_, { query }) => query, (_, { page = 1 }) => page],
  (searches, query, page) => {
    for (const search of searches) {
      if (search.query === query && search.page === page) return search;
    }
    return undefined;
  }
);

export const getArticleById = (state, id) =>
  getZendeskState(state).articles[id];
