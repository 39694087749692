export class MissingExtensionError extends Error {
  constructor() {
    super(
      "Looks like you don’t have the My Reseller Genie extension installed. Click profile icon > Web Extension and download the extension in order to connect your account. Also, make sure to sign into prior to attempting to connect."
    );
  }
}

export class OutdatedExtensionError extends Error {
  constructor() {
    super(
      "Looks like you have an old My Reseller Genie extension installed. Click profile icon > Web Extension and update the extension in order to connect your account. Also, make sure to sign into prior to attempting to connect."
    );
  }
}

const ExtensionIdAttributeName = "mrg-web-extension-id";
const ExtensionVersionAttributeName = "mrg-web-extension-version";

export function getExtensionId() {
  const extensionId = document.body.getAttribute(ExtensionIdAttributeName);
  if (!extensionId) throw new MissingExtensionError();

  return extensionId;
}

export function getExtensionVersion() {
  const extensionVersion = document.body.getAttribute(
    ExtensionVersionAttributeName
  );
  if (!extensionVersion) throw new MissingExtensionError();

  return extensionVersion;
}

export function checkExtensionVersion(requiredVersion = "1.0.1") {
  const current = getExtensionVersion()
    .split(".")
    .map((s) => parseInt(s.trim(), 10));
  const required = requiredVersion
    .split(".")
    .map((s) => parseInt(s.trim(), 10));

  for (let i = 0; i < required.length; i++) {
    if (required[i] < current[i]) return true;
    if (required[i] > current[i]) throw new OutdatedExtensionError();
  }

  if (current.length > required.length) throw new OutdatedExtensionError();

  return true;
}

export function sendMessage<M = any, R = any>(
  extensionId: string,
  message: M
): Promise<R> {
  return new Promise<R>((rs) =>
    chrome.runtime.sendMessage(extensionId, message, (r) => rs(r))
  );
}

export interface Cookie {
  name: string;
  value: string;
}

export async function getCookies(
  extensionId: string,
  payload?: { domain?: string }
): Promise<Cookie[]> {
  const cookies = await sendMessage(extensionId, {
    name: "getCookies",
    payload,
  });
  return cookies?.result || [];
}

// https://developer.chrome.com/docs/extensions/reference/windows/#method-create
export async function createWindow(
  extensionId: string,
  payload: { focused?: boolean; type: "popup"; url: string }
) {
  return await sendMessage(extensionId, {
    name: "windowsCreate",
    payload,
  });
}

export async function waitOnRemoveWindow(
  extensionId: string,
  payload: { windowId: string }
) {
  return await sendMessage(extensionId, {
    name: "windowsOnRemove",
    payload,
  });
}

export async function removeWindow(
  extensionId: string,
  payload: { windowId: string }
) {
  return await sendMessage(extensionId, {
    name: "windowsRemove",
    payload,
  });
}

export async function webRequest(
  extensionId: string,
  payload: {
    url: string;
    init?: RequestInit;
  }
) {
  return await sendMessage(extensionId, {
    name: "webRequest",
    payload,
  });
}
