import { all, fork } from "redux-saga/effects";
import { appSagas } from "./system/saga";

import { inventorySagas } from "./inventory/saga";
import { saleSagas } from "./sale/saga";
import { bankStatementSagas } from "./bankStatement/saga";
import { itemOptionsSagas } from "./itemOptions/saga";
import { platformSagas } from "./platform/saga";
import { saleStateSagas } from "./saleState/saga";
import { transactionSagas } from "./transaction/saga";

export default function* rootSaga() {
  yield all([
    fork(appSagas),

    fork(inventorySagas),
    fork(saleSagas),
    fork(bankStatementSagas),
    fork(itemOptionsSagas),
    fork(platformSagas),
    fork(saleStateSagas),
    fork(transactionSagas),
  ]);
}
