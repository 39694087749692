import React, { useState, useEffect } from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import NumberField, { NumberFieldProps } from "src/components/NumberField";

const noop = () => {};

function TextFieldOnBlur({ onChange, value, ...props }: TextFieldProps) {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <TextField
      {...props}
      value={localValue}
      onChange={(e) => setLocalValue(e.target.value)}
      onBlur={onChange ? (...args) => onChange(...args) : noop}
    />
  );
}

export function NumberFieldOnBlur({
  onChange,
  value,
  ...props
}: NumberFieldProps) {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <NumberField
      {...props}
      value={localValue}
      onChange={(e) => setLocalValue(e.target.value)}
      onBlur={onChange ? (...args) => onChange.apply(null, args as any) : noop}
    />
  );
}

export default TextFieldOnBlur;
