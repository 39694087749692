import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import LoadableButton from "src/components/LoadableButton";

import { updateUser } from "src/store/support/actions";

function Loading() {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={4}>
      <CircularProgress size={35} />
      <Typography variant="h6" component="span">
        Loading...
      </Typography>
    </Box>
  );
}

function Form({ username, user }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleSubmit = async (ev) => {
    ev.preventDefault();
    const data = new FormData(ev.target);
    const email = data.get("email");
    const shouldRedirect = username !== user.uid && user.email !== email;
    await dispatch(
      updateUser(username, {
        ...(!!email && { email: email.toString() }),
        emailVerified: !!data.get("emailVerified"),
        disabled: !!data.get("disabled"),
      })
    );
    if (shouldRedirect) history.push(`/support/user/${email}`);
  };

  if (!user.uid && user.loading) return <Loading />;
  if (user.error) return <div>error</div>;

  return (
    <Box px={2} pt={4} component="form" onSubmit={handleSubmit}>
      <Box pb={2}>
        <TextField
          label="User ID"
          fullWidth
          defaultValue={user.uid}
          InputProps={{
            readOnly: true,
          }}
          disabled={user.loading}
        />
      </Box>
      <Box pb={2}>
        <TextField
          label="Last Sign In Time"
          fullWidth
          defaultValue={user.metadata.lastSignInTime}
          InputProps={{
            readOnly: true,
          }}
          disabled={user.loading}
        />
      </Box>
      <Box pb={2}>
        <TextField
          label="E-Mail"
          name="email"
          fullWidth
          defaultValue={user.email}
          disabled={user.loading}
        />
      </Box>
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              name="emailVerified"
              value={true}
              defaultChecked={user.emailVerified}
              disabled={user.loading}
            />
          }
          label="E-Mail verified"
        />
      </Box>
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              name="disabled"
              value={true}
              defaultChecked={user.disabled}
              disabled={user.loading}
            />
          }
          label="Disabled"
        />
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <LoadableButton
          type="submit"
          variant="contained"
          color="primary"
          disabled={user.loading}
        >
          Update
        </LoadableButton>
      </Box>
    </Box>
  );
}

export default Form;
