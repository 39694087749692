import React, { useState } from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { userSalesQuickViewSelector } from "src/store/system/selector";

import LoadableButton from "src/components/LoadableButton";
import { updateUserApi } from "src/apiService/modules/system";
import GoldIconButton from "src/components/GoldIconButton";

import ReportSaleBasicQuickContent from "./ReportSaleBasicQuickContent";
import ReportSaleBasicAdvancedContent from "./ReportSaleBasicAdvancedContent";
import WalkThrough from "./BasicWalkThrough";

const useStyles = makeStyles((theme) => ({
  title: {
    position: "relative",
  },
  quick: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

const ReportSaleBasicDialog = ({
  inventories,
  values,
  setValues,
  errors,
  renderOption,
  loading,
  handleChange,
  saleStates,
  platformsLoading,
  allPlatforms,
  handleSave,
  goToBundle,
  goToUpload,
}) => {
  const salesQuickView = useSelector(userSalesQuickViewSelector);
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <>
      <WalkThrough open={open} onClose={() => setOpen(false)} />
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h6">Report Sale</Typography>
        <FormControlLabel
          id="report-sale-dialog-quick-view-switch"
          className={classes.quick}
          control={
            <Switch
              color="secondary"
              checked={salesQuickView}
              onChange={(event) =>
                updateUserApi({ salesQuickView: event.target.checked })
              }
            />
          }
          label="Quick View"
        />
      </DialogTitle>
      <DialogContent>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {salesQuickView ? (
            <ReportSaleBasicQuickContent
              values={values}
              setValues={setValues}
              loading={loading}
              errors={errors}
              handleChange={handleChange}
              inventories={inventories}
              renderOption={renderOption}
            />
          ) : (
            <ReportSaleBasicAdvancedContent
              errors={errors}
              values={values}
              handleChange={handleChange}
              setValues={setValues}
              loading={loading}
              renderOption={renderOption}
              inventories={inventories}
            />
          )}
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <Box mt={-1.5}>
          <Tooltip title="Need help? Click here.">
            <GoldIconButton onClick={() => setOpen(true)} />
          </Tooltip>
        </Box>
        <Box flex={1} />
        <Button
          id="report-sale-dialog-record-bundle-sale-button"
          onClick={goToBundle}
          color="secondary"
          variant="contained"
          disabled={loading}
        >
          Record Bundle Sale
        </Button>
        <Button
          id="report-sale-dialog-bulk-upload-button"
          onClick={goToUpload}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          Bulk Upload
        </Button>
        <LoadableButton
          onClick={() => handleSave(false)}
          color="primary"
          variant="contained"
          loading={loading}
        >
          Add and Next
        </LoadableButton>
        <LoadableButton
          onClick={() => handleSave()}
          color="primary"
          variant="contained"
          loading={loading}
        >
          Add
        </LoadableButton>
      </DialogActions>
    </>
  );
};

export default ReportSaleBasicDialog;
