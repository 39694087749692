import React, { useMemo } from "react";
import { XGrid, XGridProps } from "@material-ui/x-grid";
import produce from "immer";

function cleanValueFormatter({ value }): string {
  return `${value}`.replace(/\s/g, " ").trim();
}

function CSVFormattedXGrid({ columns, ...props }: XGridProps) {
  const formattedColumns = useMemo(() => {
    return produce(columns, (columns) => {
      for (const col of columns) {
        if (!col.valueFormatter && !col.type)
          col.valueFormatter = cleanValueFormatter;
      }
    });
  }, [columns]);

  return <XGrid columns={formattedColumns} {...props} />;
}

export default CSVFormattedXGrid;
