import React from "react";

import Link from "@material-ui/core/Link";
import DialogContentText from "@material-ui/core/DialogContentText";

import DialogTitle from "./DialogTitle";
import type Step from "./Step";

import { UploadSalesReportDialog } from "src/pages/RootDialogs/ReportSaleDialog/UploadMatchedSalesReportDialog";
import { uploadSalesCsv, uploadSalesApi } from "src/apiService/modules/sales";
import { requestNOUpdate } from "src/apiService/modules/numericOverview";
import { getUserId } from "src/config/storage";
import { processMercariFile } from "src/utils/mercari";

interface MercariUploadReportyProps {
  onClose: () => void;
  onStepChange: (step: Step) => void;
}

function MercariUploadReport({
  onClose,
  onStepChange,
}: MercariUploadReportyProps) {
  return (
    <>
      <DialogTitle onClose={onClose}>Upload Mercari Report</DialogTitle>
      <UploadSalesReportDialog
        onSubmit={async (csvString: string, file: File) => {
          const processed = processMercariFile(csvString);
          if ("error" in processed) throw new Error(processed.error);

          const userId = await getUserId();
          const { url } = await uploadSalesCsv(
            processed.result as Blob,
            userId
          );
          await uploadSalesApi(url, (file as File).name);
          await requestNOUpdate();
          onStepChange("other:do-you-sell");
        }}
        onBack={() => {
          onStepChange("mercari:do-you-sell-on-mercari");
        }}
        back="Go Back"
      >
        <DialogContentText>
          You can upload sales reports from Mercari.{" "}
          <Link
            href="https://www.youtube.com/watch?v=p590Q-oaRCs"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download a sales report from Mercari
          </Link>
          , attach it below, and click “Upload”.
        </DialogContentText>
      </UploadSalesReportDialog>
    </>
  );
}

export default MercariUploadReport;
