import React, { useState } from "react";
import { omit } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Papa from "papaparse";
import Bluebird from "bluebird";
import firestore from "src/apiService/firestore";

import { Button, Typography } from "@material-ui/core";

import {
  addTransaction,
  markDownloadViewed,
  consolidateEbaySaleShippingAndAnalyticsCost,
} from "src/apiService";
import { setActiveDialog } from "src/store/adminHtml/actions";
import { setUploadsFromEbay, setPendingUploads } from "src/store/sale/actions";
import {
  getEbayExtraRows,
  getIsEbayImport,
  getPendingSalesUploads,
} from "src/store/sale/selector";
import { userIncomeTaxRateSelector } from "src/store/system/selector";
import { getNewSyncSalesDownloads } from "src/store/uploads/selector";
import { daysOnPlatform, downloadCsv, returnsHeaders } from "src/utils";
import { getUserIdSync } from "src/config/storage";

const db = firestore();

async function insertSale(sale) {
  const doc = db.collection("Sales").doc();
  return await doc.set({
    ...sale,
    id: doc.id,
  });
}

const CompleteUpload = ({ render }: any) => {
  const dispatch = useDispatch();
  const incomeTaxRate = useSelector(userIncomeTaxRateSelector);
  const uploads: any[] = useSelector(getPendingSalesUploads);
  const isEbayImport = useSelector(getIsEbayImport);
  const salesDownloads = useSelector(getNewSyncSalesDownloads);
  const { extraRows, returns } = useSelector(getEbayExtraRows);

  const [loading, setLoading] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);

  const handleClose = () => {
    setLoading(false);
    dispatch(setActiveDialog(""));
    setConfirmCancel(false);
  };

  const handleExpenseTransactions = (expenseRows) => {
    let promises: Promise<any>[] = [];
    let ebayExpensesOrderIdArray: Array<string> = [];
    const user = getUserIdSync();
    expenseRows.forEach((expense) => {
      let formattedExpense = {
        ...expense,
        reviewed: false,
        user,
      };
      promises.push(addTransaction(formattedExpense));
      if (formattedExpense.orderId) {
        ebayExpensesOrderIdArray.push(formattedExpense.orderId);
      }
    });

    return Bluebird.all(promises).tap(async () => {
      try {
        await consolidateEbaySaleShippingAndAnalyticsCost(
          ebayExpensesOrderIdArray
        );
      } catch (e) {
        console.log(
          "sales consolidation failed for: ",
          ebayExpensesOrderIdArray
        );
      }
    });
  };

  const cleanup = async () => {
    if (isEbayImport) {
      salesDownloads.forEach((download) => markDownloadViewed(download.id));
      if (extraRows && extraRows.length) {
        await handleExpenseTransactions(extraRows);
        dispatch(setActiveDialog("categorize_expenses"));
      }
      if (returns && returns.length) {
        const returnsCsv = Papa.unparse(returns, { columns: returnsHeaders });
        downloadCsv(
          new Blob([returnsCsv], { type: "text/csv" }),
          "eBayReturns"
        );
      }
    }
  };

  const submitUpload = async () => {
    const user = getUserIdSync();
    if (!uploads.length) {
      handleClose();
      return await cleanup();
    }
    handleClose();
    let salePromises: Array<Promise<any>> = uploads.map((sale) => {
      sale = omit(sale, ["uuid", "return_id"]); // uuid is only for matching process, return_id is from inventory and should be omitted
      const grossProfit =
        (sale.sale_price || 0) -
        (sale.purchase_price || 0) -
        (sale.transaction_fees || 0) -
        (sale.other_fees || 0) -
        (sale.shipping_cost || 0) -
        (sale.shipping_cost_analytics || 0);
      const estimatedTax = grossProfit * +(incomeTaxRate || 0);
      const newItem = {
        ...sale,
        days_on_platform: daysOnPlatform(
          sale.sale_date?.valueOf() || 0,
          sale.list_date?.valueOf() || 0
        ),
        estimated_income_tax: estimatedTax,
        gross_profit: grossProfit,
        net_profit: grossProfit - estimatedTax - sale.other_business_costs,
        user,
      };
      Object.keys(newItem).forEach((key) => {
        if (newItem[key] === undefined) {
          console.log({ keyError: { newItem, key } });
          newItem[key] = null;
        }
      });
      const inventoryId = newItem.inventoryId;
      delete newItem.inventoryId;
      return inventoryId
        ? db.runTransaction(async (t) => {
            const doc = db.collection("Sales").doc();
            const inventoryDoc = db.collection("Inventory").doc(inventoryId);
            const inventory = await t.get(inventoryDoc);
            if (inventory.exists) {
              const inventoryData = inventory.data();
              const quantity = inventoryData?.quantity || 1;
              if (quantity > 1) {
                await t.update(inventoryDoc, {
                  quantity: quantity - 1,
                  purchase_price:
                    (inventoryData?.purchase_price || 0) -
                    (sale.purchase_price || 0),
                });
              } else {
                await t.delete(inventoryDoc);
              }
            }
            await t.set(doc, {
              ...newItem,
              id: doc.id,
            });
          })
        : insertSale(newItem);
    });
    return Promise.all(salePromises).then(async () => {
      dispatch(setPendingUploads([]));
      await cleanup();
      if (isEbayImport) {
        dispatch(setUploadsFromEbay(false));
      }
      toast.success("Sales uploaded successfully.");
    });
  };

  return render({
    title: "Complete Upload",
    main: (
      <Typography variant="body1" paragraph>
        {confirmCancel
          ? "Are you sure you want to cancel the upload? All of your inventory matching work will be lost."
          : `Your upload of ${uploads.length} sales is ready to submit.`}
      </Typography>
    ),
    actions: confirmCancel ? (
      <>
        <Button variant="contained" onClick={() => setConfirmCancel(false)}>
          No, Do Not Cancel
        </Button>
        <Button color="secondary" variant="contained" onClick={handleClose}>
          Yes, Cancel Upload
        </Button>
      </>
    ) : (
      <>
        <Button
          color="secondary"
          disabled={loading}
          variant="contained"
          onClick={() => setConfirmCancel(true)}
        >
          Cancel Upload
        </Button>
        <Button color="primary" variant="contained" onClick={submitUpload}>
          Submit
        </Button>
      </>
    ),
  });
};

export default CompleteUpload;
