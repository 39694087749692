import React, { forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem, { ListItemProps } from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText, {
  ListItemTextProps,
} from "@material-ui/core/ListItemText";
import { LinkProps } from "react-router-dom";
import clsx from "clsx";

import { AdapterLink } from "src/utils";
import type { Theme } from "src/theme";

export interface NavigatorItemProps {
  Icon: React.ComponentType;
  primary: ListItemTextProps["primary"];
  to?: LinkProps["to"];
  active?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "&:hover": {
      backgroundColor: theme.palette.colors.lightGray,
    },
    color: theme.palette.colors.blue,
  },
  active: {
    "&:hover": {
      backgroundColor: theme.palette.colors.lightGreen,
    },
    borderRadius: theme.shape.borderRadius,
    color: "#fff",
    backgroundColor: theme.palette.colors.green,
  },
}));

function NavigatorItemRender(
  {
    Icon,
    primary,
    active = false,
    className,
    ...props
  }: NavigatorItemProps & Omit<ListItemProps, "button" | "dense" | "component">,
  ref
) {
  const classes = useStyles();

  return (
    <ListItem
      ref={ref}
      button
      dense
      className={clsx(className, classes.root, active && classes.active)}
      {...(props.to ? { component: AdapterLink } : undefined)}
      {...(props as any)}
    >
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={primary} />
    </ListItem>
  );
}

const NavigatorItem = forwardRef(NavigatorItemRender);

export default NavigatorItem;
