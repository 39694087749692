import firebase from "firebase/app";
import "firebase/firestore";

let db: firebase.firestore.Firestore | null = null;

function firestore(): firebase.firestore.Firestore {
  if (!db) {
    db = firebase.firestore();

    if (
      process.env.NODE_ENV !== "production" &&
      typeof window !== "undefined" &&
      window.location.hostname === "localhost" &&
      process.env.REACT_APP_FIREBASE_FUNCTIONS_URL?.startsWith(
        "http://localhost"
      )
    ) {
      db.useEmulator("localhost", 8080);
    }
  }

  return db;
}

export default firestore;
