import React, { type Dispatch, type SetStateAction } from "react";

import type { EtsyIntegration } from "src/interfaces/plaidIntegration.interface";

import PlatformIntegrationsTableRow from "../PlatformIntegrationsTableRow";
import useIsRunning from "../useIsRunning";
import type { DialogState } from "../types";
import { etsyDeleteIntegration } from "src/apiService/modules/etsy";

// fix-vim-highlight = }

const IsRunningThreshold = 10 * 60 * 1000; // 10 min

function EtsyIntegrationTableRow({
  integration,
  setDialog,
  hideInventory,
}: {
  integration: EtsyIntegration;
  setDialog: Dispatch<SetStateAction<DialogState>>;
  hideInventory: boolean;
}) {
  const isRunning = useIsRunning(
    integration.salesIsRunning,
    integration.inventoryIsRunning,
    IsRunningThreshold
  );

  return (
    <PlatformIntegrationsTableRow
      key={integration.id}
      name={integration.username || "Etsy"}
      username={integration.username}
      platform="Etsy"
      isRunning={isRunning}
      sync={integration.sync}
      inventory={integration.sync && integration.inventory !== false}
      onPullDataNow={async () => {
        // TODO:
        // implement pullDataNow
      }}
      onImport={() => {
        // TODO:
        // implement onImport
      }}
      onSyncChanged={async (sync, inventory) => {
        // TODO:
        // implemen sync change
      }}
      hideInventory={hideInventory}
      onDelete={() =>
        setDialog({
          type: "delete",
          props: {
            integration,
            deleteIntegration: etsyDeleteIntegration,
          },
        })
      }
    />
  );
}

export default EtsyIntegrationTableRow;
