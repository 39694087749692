import React, { useState } from "react";
import Tour from "reactour";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import { userDontShowDialogsSelector } from "src/store/system/selector";
import DontShowThisMessageAgainCheckbox from "src/components/DontShowThisMessageAgainCheckbox";

const Key = "walk_through_categorize_expenses";

const Steps = [
  {
    content: (props) => (
      <>
        <DialogTitle>Categorize Expenses - 1/4</DialogTitle>
        <DialogContent>
          <DialogContentText>
            When new expenses are imported into MRG, you’ll be prompted to
            review them. On this screen, you can modify the general ledger
            account, vendor, and description.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(1)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    observe: ".categorize-expenses-table",
    mutationObservables: [
      ".categorize-expenses-table .categorize-expenses-table-account-header",
      ".categorize-expenses-table .MuiDataGrid-renderingZone",
    ],
    selector: ".categorize-expenses-table-account-header",
    highlightedSelectors: [
      ".categorize-expenses-table-account-header",
      ".MuiDataGrid-row:first-child .categorize-expenses-table-account-cell",
      ".categorize-expenses-table-vendor-header",
      ".MuiDataGrid-row:first-child .categorize-expenses-table-vendor-cell",
      ".categorize-expenses-table-description-header",
      ".MuiDataGrid-row:first-child .categorize-expenses-table-description-cell",
    ],
    content: (props) => (
      <>
        <DialogTitle>Categorize Expenses - 2/4</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Set the general ledger account and vendor using the dropdown menus.
            Add a description by double clicking the description box.
          </DialogContentText>
          <DialogContentText>
            For expenses imported from selling platforms, MRG will automatically
            categorize these for you.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(0)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(2)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    observe: ".categorize-expenses-table",
    mutationObservables: [
      ".categorize-expenses-table .categorize-expenses-table-hasrule-header",
      ".categorize-expenses-table .MuiDataGrid-renderingZone",
    ],
    selector:
      ".categorize-expenses-table .categorize-expenses-table-hasrule-header",
    highlightedSelectors: [
      ".categorize-expenses-table .categorize-expenses-table-hasrule-header",
      ".MuiDataGrid-row:first-child .categorize-expenses-table-hasrule-cell",
    ],
    content: (props) => (
      <>
        <DialogTitle>Categorize Expenses - 3/4</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Once you’ve set the general ledger account, vendor, and description,
            you can create an expense rule by checking this box*.
          </DialogContentText>
          <DialogContentText>
            For any future transactions with a matching charge description, the
            general ledger account, vendor, and description from this
            transaction will be assigned to those transactions.
          </DialogContentText>
          <DialogContentText variant="caption">
            *You cannot create expense rules for expenses imported from selling
            platforms.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(1)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(3)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#categorize-expenses-mark-reviewed-button",
    content: (props) => (
      <>
        <DialogTitle>Categorize Expenses - 4/4</DialogTitle>
        <DialogContent>
          <DialogContentText>
            After reviewing and categorizing expenses, select them and click the
            “Mark Reviewed” button. This will clear them from the “Categorize
            Expenses” screen.
          </DialogContentText>
          <DontShowThisMessageAgainCheckbox dialog={Key} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(2)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.close()}
          >
            Close
          </Button>
        </DialogActions>
      </>
    ),
  },
];

const useStyles = makeStyles((theme) => ({
  helper: {
    maxWidth: "80%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "500px",
    },
    padding: 0,
  },
}));

export function WalkThrough({ apiRef }) {
  const [open, setOpen] = useState(true);
  const userDontShowDialogs = useSelector(userDontShowDialogsSelector);

  const classes = useStyles();
  const isOpen =
    open && userDontShowDialogs && !userDontShowDialogs?.includes(Key);

  return (
    <Tour
      startAt={0}
      closeWithMask={false}
      disableFocusLock
      steps={Steps}
      isOpen={isOpen}
      onRequestClose={() => {
        setOpen(false);
        if (apiRef)
          apiRef.current.scrollToIndexes({
            colIndex: 0,
          });
      }}
      showButtons={false}
      showCloseButton={false}
      showNavigation={false}
      showNavigationNumber={false}
      showNumber={false}
      disableDotsNavigation
      rounded={5}
      className={classes.helper}
      disableInteraction
      getCurrentStep={(step) => {
        if (apiRef) {
          switch (step) {
            case 1:
              apiRef.current.scrollToIndexes({
                colIndex: 5,
              });
              break;
            case 2:
              apiRef.current.scrollToIndexes({
                colIndex: 6,
              });
              break;
          }
        }
      }}
    />
  );
}

export default WalkThrough;
