import React from "react";
import Tour from "reactour";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

const Steps = [
  {
    content: (props) => (
      <>
        <DialogTitle>Report Sale - 1/5</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This form allows you to record new sales manually or by uploading
            them.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(1)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#report-sale-dialog-quick-view-switch",
    content: (props) => (
      <>
        <DialogTitle>Report Sale - 2/5</DialogTitle>
        <DialogContent>
          <DialogContentText>
            By default, the “Quick View” is enabled. You can toggle it off to
            add more details to the sale when you record it.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(0)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(2)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#report-sale-dialog-item-sold-tooltip",
    content: (props) => (
      <>
        <DialogTitle>Report Sale - 3/5</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Learn more about each field by hovering over the info icons.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(1)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(3)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#report-sale-dialog-record-bundle-sale-button",
    content: (props) => (
      <>
        <DialogTitle>Report Sale - 4/5</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Click here to record a sale of multiple items.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(2)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(4)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#report-sale-dialog-bulk-upload-button",
    content: (props) => (
      <>
        <DialogTitle>Report Sale - 5/5</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Click here to upload a file with a list of sales.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(3)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.close()}
          >
            Done
          </Button>
        </DialogActions>
      </>
    ),
  },
];

const useStyles = makeStyles((theme) => ({
  helper: {
    maxWidth: "80%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "500px",
    },
    padding: 0,
  },
}));

export interface WalkThroughProps {
  open: boolean;
  onClose: () => void;
}

export function WalkThrough({ open, onClose }: WalkThroughProps) {
  const classes = useStyles();
  return (
    <Tour
      startAt={0}
      closeWithMask={false}
      disableFocusLock
      steps={Steps}
      isOpen={open}
      onRequestClose={() => {
        onClose();
      }}
      showButtons={false}
      showCloseButton={false}
      showNavigation={false}
      showNavigationNumber={false}
      showNumber={false}
      disableDotsNavigation
      rounded={5}
      className={classes.helper}
      disableInteraction
    />
  );
}

export default WalkThrough;
