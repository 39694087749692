import { createSelector } from "reselect";

import type { AppState } from "..";
import type { SyncStatus, IntegrationType } from "./types";

export function integrationSyncStateSelector(state: AppState) {
  return state.integrationSync;
}

export const integrationSyncEntriesSelector = createSelector(
  [integrationSyncStateSelector],
  (state) =>
    Object.entries(state).map(([integration, v]) => ({
      ...v,
      integration,
    })) as (SyncStatus & { integration: IntegrationType })[]
);

export const integrationSyncEntrySelector = createSelector(
  [integrationSyncStateSelector, (_, integrationType) => integrationType],
  (state, integrationType) => state[integrationType]
);
