import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  AppBar,
  Badge,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import InputIcon from "@material-ui/icons/Input";
import MenuIcon from "@material-ui/icons/Menu";
// import HelpIcon from '@material-ui/icons/Help';
import NotificationsIcon from "@material-ui/icons/Notifications";
import SettingsIcon from '@material-ui/icons/Settings';

import { Theme } from "src/theme";
import { AdapterLink } from "src/utils";
import { setActiveDialog, setWalkthroughId } from "src/store/adminHtml/actions";
import { alertsSelector } from "src/store/adminHtml/selector";
import { isLoggedInSelector } from "src/store/system/selector";
import InfoTooltip from "../InfoTooltip";
import NotificationsDialog from "./NotificationsDialog";
import ProfileSettingsDialog from "src/pages/RootDialogs/ProfileSettings";
import { getIsEbayImport } from "src/store/sale/selector";

import type { ActiveDialog } from "src/interfaces/adminHtmlState.interface";

const useStyles = makeStyles((theme: Theme) => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  menuContainer: {
    padding: "20px 30px",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.colors.light,
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
}));

export const AdminHeader = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const loggedIn = useSelector(isLoggedInSelector);
  const isEbayImport = useSelector(getIsEbayImport);
  const [menuAnchor, setMenuAnchor] = useState<any>(null);
  // const [helpMenuAnchor, setHelpMenuAnchor] = useState<any>(null);
  const [dialog, setDialog] = useState<'settings'|'notifications'>();

  const alerts = useSelector(alertsSelector);
  const onItemClick = (id: ActiveDialog) => dispatch(setActiveDialog(id));

  const openMenu = (e) => setMenuAnchor(e.currentTarget);
  // const openHelpMenu = (e: any) => setHelpMenuAnchor(e.currentTarget);

  const closeMenu = () => setMenuAnchor(null);
  // const closeHelpMenu = () => setHelpMenuAnchor(null);

  const openProfileSettings = () => {
    closeMenu();
    setDialog('settings');
  };

  const openUploadHistory = () => {
    closeMenu();
    onItemClick('manage_uploads');
  };

  

  const closeDialog = () => {
    setDialog(undefined);
  };

  const openWalkthroughDialog = () => {
    if (props.walkthroughId) {
      dispatch(setActiveDialog("walkthrough"));
      dispatch(setWalkthroughId(props.walkthroughId));
    }
  };

  const openNotificationsDialog = () => setDialog('notifications');

  const renderUserBar = () => {
    if (loggedIn) {
      return (
        <React.Fragment>
          {/*<Grid item>
            <IconButton color="inherit" onClick={openHelpMenu} id="new-signup-orientation-step-two">
              <HelpIcon />
            </IconButton>
            <ZendeskSearch onClose={closeHelpMenu} anchor={helpMenuAnchor} />
          </Grid>*/}
          <Grid item>
            <IconButton
              id="notifications-icon"
              color="inherit"
              onClick={openNotificationsDialog}
              disabled={isEbayImport}
            >
              <Badge
                invisible={!alerts.length}
                badgeContent={alerts.length}
                color="error"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              color="inherit"
              onClick={openProfileSettings}
            >
              <SettingsIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              color="inherit"
              onClick={openMenu}
            >
              <AccountCircleIcon fontSize="large" />
            </IconButton>
            <Menu
              anchorEl={menuAnchor}
              keepMounted
              open={!!menuAnchor}
              onClose={closeMenu}
            >
              <MenuItem
                component={AdapterLink}
                href="#"
                to="/logout"
              >Log out
              </MenuItem>
              <MenuItem
                onClick={openUploadHistory}
              >Upload history
              </MenuItem>
              <MenuItem
                component="a"
                href="https://myresellergenie.zendesk.com/hc/en-us"
                target="_blank"
              >Help Center
              </MenuItem>
            </Menu>
          </Grid>
          <ProfileSettingsDialog
            open={dialog === 'settings'}
            onClose={closeDialog}
          />
          <NotificationsDialog
            items={alerts}
            open={dialog === 'notifications'}
            onClose={closeDialog}
          />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Grid item>
          <Tooltip title="Log In">
            <IconButton
              color="inherit"
              component={AdapterLink}
              to="/login"
              href="#"
            >
              <InputIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <AppBar color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={props.onDrawerToggle}
                  className={classes.menuButton}
                  href="#"
                ><MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs></Grid>
            {renderUserBar()}
          </Grid>
        </Toolbar>
        <Grid item xs className={classes.menuContainer}>
          <Typography color="inherit" variant="h5">
            {props.title}
            {!!props.walkthroughId ? (
              <InfoTooltip
                color="inherit"
                text={""}
                onClick={openWalkthroughDialog}
                />
            ) : null}
          </Typography>
          <Typography color="inherit" variant="subtitle1">
            {props.description}
          </Typography>
        </Grid>
      </AppBar>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="secondary"
        position="static"
        elevation={0}
      />
    </React.Fragment>
  );
}

export default AdminHeader;
