import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import pick from "lodash/pick";

import Dialog, { DialogProps } from "@material-ui/core/Dialog";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { inventoriesSelector } from "../../store/inventory/selector";
import { platformSelector } from "../../store/platform/selector";
import { itemOptionsSelector } from "../../store/itemOptions/selector";
import { saleStateSelector } from "../../store/saleState/selector";
import { salesSelector } from "../../store/sale/selector";
import { fetchItemsIfNeeded as fetchPlatforms } from "src/store/platform/actions";
import { fetchItemsIfNeeded as fetchItemOptions } from "src/store/itemOptions/actions";
import {
  fetchItemsIfNeeded as fetchSaleStates,
  createItem as addSaleState,
} from "../../store/saleState/actions";

import { Sale, SaleFormError, Inventory } from "../../interfaces";
import { userPlatformsSelector, userGetInventoryTypeSelector } from "../../store/system/selector";
import {
  departmentsSelector,
  categoriesSelector,
  subCategoriesSelector,
} from "src/store/itemOptions/selector";

import EditBundleDialog from "./EditBundleDialog";
import EditReturnDialog from "./EditReturnDialog";
import EditSaleBasicDialog from "./EditSaleBasicDialog";
import EditBundleAllocationsDialog from "./EditBundleAllocationsDialog";
import RecordReturnDialog from "./RecordReturnDialog";

import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

interface IEditDialogProps {
  data: Sale | undefined;
  loading: boolean;
  onSave: Function;
  onSaveBundle: Function;
  onReturn: Function;
  onDelete: Function;
  onDeleteBundle: Function;
  onDeleteReturn: Function;
  onMove: Function;
  onMoveBundle: Function;
  editReturn: Function;
}

const initFormValues: Sale = {
  gross_profit: 0,
  purchase_price: 0,
  list_date: undefined,
  department: "",
  item_option: "",
  net_profit: 0,
  location: "",
  transaction_fees: 0,
  sale_price: 0,
  sub_category: "",
  other_business_costs: 0,
  brand: "",
  estimated_income_tax: 0,
  sales_tax: 0,
  sale_platform: "",
  sale_tax_paid: "",
  sku: "",
  sale_state: "",
  sale_date: undefined,
  days_on_platform: 0,
  item_title: "",
  id: "",
  platforms_listed: "",
  liable_to_pay: false,
  category: "",
  shipping_cost: 0,
  purchase_date: undefined,
  notes: "",
  return_id: "",
};

export function Content({
  data,
  loading,
  onSave,
  onSaveBundle,
  onReturn,
  onDelete,
  onDeleteBundle,
  onDeleteReturn,
  onMove,
  onMoveBundle,
  editReturn,
}: IEditDialogProps) {
  const isInventoryDisabled = useSelector((s: any) => userGetInventoryTypeSelector(s) === "cash");
  const dispatch = useDispatch();

  const userPlatforms = useSelector(userPlatformsSelector);
  const { items: platforms, loading: platformsLoading } =
    useSelector(platformSelector);
  const { loading: itemOptionsLoading } = useSelector(itemOptionsSelector);
  const { items: saleStates } = useSelector(saleStateSelector);
  const { items: sales } = useSelector(salesSelector);
  const { items: inventories } = useSelector(inventoriesSelector);

  const allPlatforms = useMemo(
    () => [...platforms, ...(userPlatforms || [])],
    [platforms, userPlatforms]
  );

  const [page, setPage] = useState(0);
  const [values, setValues] = useState<Sale>(() => {
    if (!data?.bundle_id || data.original_id) {
      return data || initFormValues;
    } else {
      let totalSalePrice = 0;
      let totalTransactionFees = 0;
      let totalShippingCost = 0;
      let totalSalesTax = 0;

      for (const item of sales) {
        if (item.bundle_id !== data.bundle_id || item.original_id) continue;
        totalSalePrice += item.sale_price;
        totalTransactionFees += item.transaction_fees;
        totalShippingCost += item.shipping_cost;
        totalSalesTax += item.sales_tax;
      }

      return {
        ...data,
        // idk why they are summing up that EPSILON constant, but, well, I'll leave it like this
        sale_price: Math.round((totalSalePrice + Number.EPSILON) * 100) / 100,
        transaction_fees:
          Math.round((totalTransactionFees + Number.EPSILON) * 100) / 100,
        shipping_cost:
          Math.round((totalShippingCost + Number.EPSILON) * 100) / 100,
        sales_tax: Math.round((totalSalesTax + Number.EPSILON) * 100) / 100,
      };
    }
    // return initFormValues;
  });
  const [allocationValues, setAllocationValues] = useState<any>(() => {
    if (!data?.bundle_id || data.original_id) return [];
    return sales.filter(
      (item) => item.bundle_id === data?.bundle_id && !item.original_id
    );
  });
  const [errors, setErrors] = useState<SaleFormError>({});
  const [shouldReallocate, setShouldReallocate] = useState(false);

  const departments = useSelector(departmentsSelector);
  const categories = useSelector((state: any) =>
    categoriesSelector(state, values)
  );
  const subCategories = useSelector((state: any) =>
    subCategoriesSelector(state, values)
  );

  const goToRoot = () => setPage(0);
  const goToBundle = () => setPage(1);
  const goToReturn = () => setPage(3);
  const goToAllocations = () => {
    setPage(2);
    if (shouldReallocate) {
      setAllocationsFromValues();
      setShouldReallocate(false);
    }
  };

  const setAllocationsFromValues = () => {
    const totalPurchasePrice = allocationValues.reduce(
      (result, item) => result + (item.purchase_price || 0),
      0
    );
    let remainingSalePrice = values.sale_price;
    let remainingTrxFees = values.transaction_fees;
    let remainingShippingCost = values.shipping_cost;
    const newAllocations = allocationValues.map((item, index) => {
      const allocationPercent = item.purchase_price / totalPurchasePrice;
      const result = {
        ...item,
        sale_price:
          Math.round(values.sale_price * allocationPercent * 100) / 100,
        transaction_fees:
          Math.round(values.transaction_fees * allocationPercent * 100) / 100,
        shipping_cost:
          Math.round(values.shipping_cost * allocationPercent * 100) / 100,
      };
      remainingSalePrice -= result.sale_price;
      remainingTrxFees -= result.transaction_fees;
      remainingShippingCost -= result.shipping_cost;
      if (index === allocationValues.length - 1) {
        if (remainingSalePrice) {
          result.sale_price += remainingSalePrice;
        }
        if (remainingTrxFees) {
          result.transaction_fees += remainingTrxFees;
        }
        if (remainingShippingCost) {
          result.shipping_cost += remainingShippingCost;
        }
      }
      result.sale_price =
        Math.round((result.sale_price + Number.EPSILON) * 100) / 100;
      result.transaction_fees =
        Math.round((result.transaction_fees + Number.EPSILON) * 100) / 100;
      result.shipping_cost =
        Math.round((result.shipping_cost + Number.EPSILON) * 100) / 100;
      return result;
    });
    setAllocationValues(newAllocations);
  };

  useEffect(() => {
    dispatch(fetchPlatforms());
    dispatch(fetchSaleStates());
    dispatch(fetchItemOptions());
  }, [dispatch]);

  useEffect(() => {
    if (data?.original_id) {
      setValues(data);
      setErrors({});
    }
  }, [data]);

  const handleDelete = () => {
    onDelete();
  };

  const handleSaveBundle = () => {
    if (validateBundle()) {
      let remainingSalesTax = values.sales_tax;
      const updatedSales = allocationValues.map((allocation, index) => {
        const salePriceRatio = allocation.sale_price / values.sale_price;
        let salesTax = Math.min(
          Math.round(remainingSalesTax * 100) / 100,
          Math.round(values.sales_tax * salePriceRatio * 100) / 100
        );
        remainingSalesTax -= salesTax;
        if (index === allocationValues.length - 1 && remainingSalesTax) {
          salesTax += remainingSalesTax;
        }
        salesTax = Math.round(salesTax * 100 + Number.EPSILON) / 100;
        return {
          ...allocation,
          shipping_cost: allocation.shipping_cost,
          sale_price: allocation.sale_price,
          sale_platform: values.sale_platform,
          liable_to_pay: values.liable_to_pay,
          sale_state: values.sale_state,
          sale_date: values.sale_date,
          sales_tax: salesTax,
          transaction_fees: allocation.transaction_fees,
        };
      });
      onSaveBundle(updatedSales);
    } else {
      toast.error("Some required information is missing or incomplete.");
    }
  };

  const handleSaveSingle = () => {
    const index = saleStates.indexOf(values.sale_state);
    if (index === -1 && values.sale_state) {
      dispatch(addSaleState(values.sale_state));
    }
    if (validateForm()) {
      if (values.bundle_id) {
        const saleUpdate = {
          ...data,
          ...pick(values, [
            "item_title",
            "department",
            "category",
            "sub_category",
            "brand",
            "location",
            "sku",
            "purchase_price",
            "purchase_date",
            "notes",
            "list_date",
            "extra_shipping_cost",
            "extra_transaction_fees",
            "return_date",
            "shipping_cost_analytics",
            "other_fees",
            "vendor",
          ]),
        };
        onSave(saleUpdate);
      } else {
        onSave(values);
      }
    } else {
      toast.error("Some required information is missing or incomplete.");
    }
  };

  const handleSave = () => {
    if (values.bundle_id) {
      return handleSaveBundle();
    }
    return handleSaveSingle();
  };

  const handleSaveReturn = (returnValues) => {
    if (!data) {
      return;
    }

    if (isInventoryDisabled) {
      returnValues = {
        ...returnValues,
        received_item: 'n',
      };
    }

    const returnId = "" + Date.now();

    const returnSale = {
      ...data,
      ...returnValues,
      purchase_date: returnValues.return_date || data.purchase_date,
      extra_shipping_cost: +returnValues.extra_shipping_cost,
      extra_transaction_fees: +returnValues.extra_transaction_fees,
      sale_price: -returnValues.sale_price,
      shipping_cost: -returnValues.shipping_cost,
      transaction_fees: -returnValues.transaction_fees,
      sales_tax: -returnValues.sales_tax,
      is_return: true,
      original_id: data.id,
      id: returnId,
      purchase_price:
        returnValues.received_item === "y" ? -data.purchase_price : 0,
      notes: data.notes,
    };

    const updatedSale = {
      ...data,
      return_id: returnId,
    };
    const inventory: Partial<Inventory> | null =
      returnValues.received_item === "y"
        ? {
            brand: data.brand,
            category: data.category,
            department: data.department,
            item_option: null,
            item_title: data.item_title,
            list_date: data.list_date,
            location: data.location,
            notes: data.notes,
            platforms_listed: data.platforms_listed,
            purchase_date: returnSale.purchase_date,
            purchase_price: data.purchase_price,
            quantity: 1,
            sku: data.sku,
            sub_category: data.sub_category,
          }
        : null;

    if (data.vendor) {
      returnSale.vendor = data.vendor;

      if (inventory) inventory.vendor = data.vendor;
    }

    onReturn(returnSale, updatedSale, inventory);
  };

  const handleMove = () => {
    if (validateForm()) {
      onMove(values);
    } else {
      toast.error("Some required information is missing or incomplete");
    }
  };

  const handleDeleteBundle = () => onDeleteBundle(allocationValues);

  const handleDeleteReturn = () => {
    const originalSale = sales.find((item) => item.id === data?.original_id);
    if (!originalSale) {
      console.log("no original sale!");
      return handleDelete();
    }
    const updatedOriginalSale = { ...originalSale, return_id: null };
    const inventory = inventories.find((item) => item.return_id === data?.id);
    const inventoryId = inventory ? inventory.id : null;
    return onDeleteReturn({
      returnId: data?.id,
      originalSale: updatedOriginalSale,
      inventoryId,
    });
  };

  const moveBundleToInventory = () => {
    onMoveBundle(allocationValues);
  };

  const handleChange =
    (prop: keyof Sale, numeric?: boolean) =>
    (event: React.ChangeEvent<any>, t?: any) => {
      let value = event.target.value;
      if (numeric) {
        value = +value || 0;
      }
      if (
        ["sale_price", "shipping_cost", "transaction_fees"].indexOf(prop) >= 0
      ) {
        setShouldReallocate(true);
      }
      if (prop === "department") {
        value = departments[Number(value)];
      } else if (prop === "category") {
        value = categories[Number(value)];
      } else if (prop === "sub_category") {
        value = subCategories[Number(value)];
      } else if (prop === "sale_platform") {
        value = allPlatforms[Number(value)];
      } else if (prop === "sale_state") {
        value = value === undefined ? "" : saleStates[Number(value)];
      } else if (prop === "liable_to_pay") {
        value = !!value;
      } else if (prop === "platforms_listed") {
        value = t;
      }
      setValues({ ...values, [prop]: value });
    };

  const handleChangeNegative =
    (prop: keyof Sale, numeric?: boolean) =>
    (event: React.ChangeEvent<any>, t?: any) => {
      let value = event.target.value;
      if (numeric) {
        value = +value || 0;
      }
      value = -value;
      setValues({ ...values, [prop]: value });
    };

  const isValidDate = (d: any) => {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d.getTime())) {
        // d.valueOf() could also work
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const validateForm = () => {
    let hasError = false;
    /* rules */
    let newErrors: SaleFormError = { ...errors };
    if (values.item_title === undefined || values.item_title.trim() === "") {
      newErrors.item_title = "Required field!";
      hasError = true;
    } else {
      newErrors.item_title = undefined;
    }

    if (values.sale_price === undefined) {
      newErrors.sale_price = "Required field!";
      hasError = true;
    } else {
      newErrors.sale_price = undefined;
    }

    if (
      !values.sale_platform ||
      (typeof values.sale_platform === "string" &&
        values.sale_platform.trim() === "")
    ) {
      hasError = true;
      newErrors.sale_platform = "Required field!";
    } else {
      newErrors.sale_platform = undefined;
    }

    if (!values.original_id && !isValidDate(values.purchase_date)) {
      hasError = true;
      newErrors.purchase_date = "Invalid date format";
    } else {
      newErrors.purchase_date = undefined;
    }

    if (!isValidDate(values.sale_date)) {
      hasError = true;
      newErrors.sale_date = "Invalid date format";
    } else {
      newErrors.sale_date = undefined;
    }

    setErrors(newErrors);

    return !hasError;
  };

  const validateBundle = () => {
    let hasError = false;
    /* rules */
    let newErrors: SaleFormError = { ...errors };

    if (values.sale_price === undefined) {
      newErrors.sale_price = "Required field!";
      hasError = true;
    } else {
      newErrors.sale_price = undefined;
    }

    if (
      !values.sale_platform ||
      (typeof values.sale_platform === "string" &&
        values.sale_platform.trim() === "")
    ) {
      hasError = true;
      newErrors.sale_platform = "Required field!";
    } else {
      newErrors.sale_platform = undefined;
    }

    if (!isValidDate(values.sale_date)) {
      hasError = true;
      newErrors.sale_date = "Invalid date format";
    } else {
      newErrors.sale_date = undefined;
    }

    setErrors(newErrors);

    return !hasError;
  };

  const renderContent = () => {
    switch (page) {
      case 1:
        return (
          <EditBundleDialog
            errors={errors}
            handleChange={handleChange}
            onDelete={handleDeleteBundle}
            values={values}
            setValues={setValues}
            loading={loading}
            saleStates={saleStates}
            renderOption={renderOption}
            platformsLoading={platformsLoading}
            allPlatforms={allPlatforms}
            allocationValues={allocationValues}
            setAllocationValues={setAllocationValues}
            goToRoot={goToRoot}
            goToAllocations={goToAllocations}
            moveBundleToInventory={moveBundleToInventory}
          />
        );
      case 2:
        return (
          <EditBundleAllocationsDialog
            allocationValues={allocationValues}
            setAllocationValues={setAllocationValues}
            bundleValues={values}
            loading={loading}
            goToBundle={goToBundle}
            handleSave={handleSave}
          />
        );
      case 3:
        return (
          <RecordReturnDialog
            data={data}
            loading={loading}
            goToRoot={goToRoot}
            handleSave={handleSaveReturn}
          />
        );
      case 0:
      default:
        if (values?.original_id) {
          return (
            <EditReturnDialog
              values={values}
              setValues={setValues}
              loading={loading}
              handleSave={handleSaveSingle}
              handleChange={handleChange}
              handleChangeNegative={handleChangeNegative}
              handleDelete={handleDeleteReturn}
            />
          );
        }
        return (
          <EditSaleBasicDialog
            handleDelete={handleDelete}
            loading={loading}
            errors={errors}
            values={values}
            setValues={setValues}
            handleChange={handleChange}
            itemOptionsLoading={itemOptionsLoading}
            departments={departments}
            renderOption={renderOption}
            categories={categories}
            subCategories={subCategories}
            saleStates={saleStates}
            platformsLoading={platformsLoading}
            allPlatforms={allPlatforms}
            handleMove={handleMove}
            goToBundle={goToBundle}
            goToReturn={goToReturn}
            handleSave={handleSaveSingle}
            editReturn={editReturn}
          />
        );
    }
  };

  const renderOption = (option, { inputValue }) => {
    const matches = match(option, inputValue);
    const parts = parse(option, matches);

    return (
      <div>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    );
  };

  return renderContent();
}

export function EditDialog({
  open,
  onClose,
  ...props
}: IEditDialogProps & { open: boolean; onClose: DialogProps["onClose"] }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={"body"}
      fullWidth={true}
      maxWidth={"md"}
    >
      <Content {...props} />
    </Dialog>
  );
}
