import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import queryString from "query-string";

import Container from "@material-ui/core/Container";
import { etsyRequestAccessToken } from "src/apiService/modules/etsy";
import { toast } from "react-toastify";

interface EtsyQueryResponse {
  code?: string;
  state?: string;
  error?: string;
  error_description?: string;
  error_uri?: string;
}

export const EtsyAuthCallbackPage = () => {
  const history = useHistory();
  const location = useLocation();
  const search = location.search;

  const requestAccessToken = async (code, state) => {
    return await etsyRequestAccessToken({
      authorizationCode: code,
      state: state,
    });
  };

  React.useEffect(() => {
    let cancel = false;
    (async () => {
      const query: EtsyQueryResponse = queryString.parse(search);
      if (query.code && query.state) {
        try {
          const resp = await requestAccessToken(query.code, query.state);
          if (cancel) return;
          if (resp.data.success) {
            if (resp.data.back) {
              history.push(resp.data.back);
            } else {
              history.push("/integrations");
            }
          }
        } catch (e) {
          toast.error(
            `There was an error authenticating with Etsy: ${(
              e as Error
            ).toString()}. Please try again.`
          );
        }
      } else if (query.error_description) {
        toast.error(
          `There was an error authenticating with Etsy: ${query.error_description}. Please try again.`
        );
      } else {
        toast.error(`Unknown error response from Etsy. Please try again.`);
      }
    })();
    return () => {
      cancel = true;
    };
  }, [search]);

  return (
    <Container component="main" maxWidth="xs">
      <Box className="flex justify-center items-center h-screen">
        <CircularProgress size={50} />
      </Box>
    </Container>
  );
};

export default EtsyAuthCallbackPage;
