import firebase from "firebase/app";
import "firebase/functions";

function supportLogin(username: string, password: string) {
  const fn = firebase.functions().httpsCallable("support-login");
  return fn({ username, password });
}

function supportGenerateToken(token: string, username: string) {
  const fn = firebase.functions().httpsCallable("support-generateToken");
  return fn({ token, username });
}

async function supportGetUser(token: string, username: string) {
  const fn = firebase.functions().httpsCallable("support-getUser");
  return await fn({ token, username });
}

export interface SupportUpdateUser {
  email?: string;
  emailVerified?: boolean;
  disabled?: boolean;
}

async function supportUpdateUser(
  token: string,
  username: string,
  data: SupportUpdateUser
) {
  const fn = firebase.functions().httpsCallable("support-updateUser");
  return await fn({ ...data, username, token });
}

export {
  supportLogin,
  supportGenerateToken,
  supportGetUser,
  supportUpdateUser,
};
