import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";

import Uploader from "src/components/Uploader";
import { userGetInventoryTypeSelector } from "src/store/system/selector";

import DialogTitle from "./DialogTitle";
import type Step from "./Step";

interface ExpenseDoYouHaveOtherProps {
  onClose: () => void;
  onStepChange: (step: Step) => void;
}

type Options = "yes" | "no";

const useStyles = makeStyles((theme) => ({
  content: {
    margin: "auto",
  },
  radioGroup: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(4),
  },
}));

function ExpensesDoYouHaveOther({
  onClose,
  onStepChange,
}: ExpenseDoYouHaveOtherProps) {
  const classes = useStyles();
  const inventoryType = useSelector(userGetInventoryTypeSelector);
  const [value, setValue] = useState<Options>();

  return (
    <>
      <DialogTitle onClose={onClose}>Set Up Questions - 7/8</DialogTitle>
      <MuiDialogContent className={classes.content}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            Do you have other expenses that you would like to upload?
          </FormLabel>
          <RadioGroup
            value={value || null}
            onChange={(event) => {
              setValue((event.target as HTMLInputElement).value as Options);
            }}
            className={classes.radioGroup}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </MuiDialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onStepChange("plaid:would-you-like-to-connect");
          }}
          color="primary"
        >
          Go Back
        </Button>
        {value === "yes" ? (
          <Uploader
            importerType="expenses"
            onClose={() => {
              onStepChange("expenses:do-you-have-more");
            }}
          />
        ) : (
          <Button
            variant="contained"
            disabled={!value}
            onClick={() => {
              if (value === "no") {
                onStepChange(
                  inventoryType === "accrual"
                    ? "bulk-edit:instructions"
                    : "complete-set-up"
                );
                return;
              }
            }}
            color="primary"
            autoFocus
          >
            Next
          </Button>
        )}
      </DialogActions>
    </>
  );
}

export default ExpensesDoYouHaveOther;
