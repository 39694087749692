import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, FormikHelpers as FormikActions, FormikProps } from "formik";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";

import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import {
  clearSignupStatus,
  fetchLogin,
  loginWithToken,
} from "../../store/system/actions";
import { LOGO } from "../../assets/svgs";
import { isLoggedInSelector, loadingSelector, systemError } from "../../store/system/selector";

interface MyFormValues {
  email: string;
  password: string;
  token: string;
}

export const LoginPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loggedIn = useSelector(isLoggedInSelector);
  const loading = useSelector(loadingSelector);
  const error = useSelector(systemError);

  const [tokenLogin, setTokenLogin] = React.useState(false);

  const toggleTokenLogin = () => {
    dispatch(clearSignupStatus());
    setTokenLogin((current) => !current);
  };

  const onFormikSubmit = async (values: MyFormValues, { setSubmitting }: FormikActions<MyFormValues>) => {
    if (!values.email && !values.token) {
      return;
    }
    if (values.token) {
      dispatch(loginWithToken(values.token));
    } else if (values.email && values.password && !loading) {
      dispatch(fetchLogin({ ...values }));
    }
  }

  if (loggedIn) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Box className="mb-5 h-36">
          <img src={LOGO} alt="My Reseller Genie" className="h-full" />
        </Box>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Formik
          initialValues={{
            password: "",
            email: "",
            token: "",
          }}
          onSubmit={onFormikSubmit}
        >
          {(formikBag: FormikProps<MyFormValues>) => (
            <form className={classes.form} noValidate onSubmit={formikBag.handleSubmit}>
              {tokenLogin &&
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="token"
                  label="Token"
                  name="token"
                  autoComplete="token"
                  autoFocus
                  onChange={formikBag.handleChange}
                  onBlur={formikBag.handleBlur}
                  value={formikBag.values.token}
                />
              }
              {!tokenLogin &&
                <>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={formikBag.handleChange}
                    onBlur={formikBag.handleBlur}
                    value={formikBag.values.email}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={formikBag.handleChange}
                    onBlur={formikBag.handleBlur}
                    value={formikBag.values.password}
                  />
                </>
              }
              {!!error && !loading && <Alert severity="error">{error}</Alert>}
              <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary" className={classes.submit}
                    disabled={(!formikBag.values.email && !formikBag.values.token) || loading}>
                    {!loading && "Sign In"}
                    {loading && <CircularProgress color="inherit" className="w-6 h-6" />}
                  </Button>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs>
                  <Link to="/forgotpassword">Forgot password?</Link>
                </Grid>
                <Grid item>
                  <Link to="/signup" onClick={() => dispatch(clearSignupStatus())}>
                    Don't have an account? Sign Up
                  </Link>
                </Grid>
              </Grid>
              <Box className="mt-2 flex justify-end">
                <Link to="#" onClick={toggleTokenLogin}>{tokenLogin ? "Login with email and password" : "Login with token"}</Link>
              </Box>
            </form>
          )}
        </Formik>
      </div>
    </Container>
  );
}

export default LoginPage;

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: 12,
  },
  ebay: {
    padding: 12,
    margin: theme.spacing(1, 0, 3),
  }
}));
