import produce from "immer";
import { handleActions } from "redux-actions";

import { secondsToDate } from "../../utils";

import { UploadState } from "../../interfaces";
import { METHOD_TYPE } from "../../enums";
import { LOG_OUT } from "../system/actions";
import {
  START,
  FAIL,
  SUCCESS,
  FS_CREATE_ITEMS,
  FS_DELETE_ITEMS,
  FS_UPDATE_ITEMS,
  RESET,
  UPLOAD,
  DOWNLOAD,
} from "../common"

const initialState: UploadState = {
  items: [],
  downloads: [],
};

export const uploadReducer = handleActions<UploadState, any>(
  {
    [UPLOAD + RESET]: (state) =>
      produce(state, (draft) => {
        draft = initialState;
      }),
    [UPLOAD + FS_CREATE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items.push(...payload);
      }),
    [UPLOAD + FS_DELETE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items = state.items.filter((item) => !payload.find((deleted) => deleted.id === item.id));
      }),
    [UPLOAD + FS_UPDATE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        for (const newItem of payload) {
          const index = draft.items.findIndex(item => item.id === newItem.id);
          if (index !== -1)
            draft.items[index] = newItem;
        }
      }),
    [DOWNLOAD + FS_CREATE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.downloads.push(...payload);
      }),
    [DOWNLOAD + FS_DELETE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.downloads = state.downloads.filter((item) => !payload.find((deleted) => deleted.id === item.id));
      }),
    [DOWNLOAD + FS_UPDATE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.downloads.forEach((item) => {
          const updated = payload.find((newItem) => {
            return newItem.id === item.id;
          });
          if (updated) {
            Object.assign(item, updated);
          }
        });
      }),
    [LOG_OUT]: () => initialState,
  },
  initialState
);
