import type { Inventory } from "src/interfaces/inventory.interface";

export interface AllocationValue {
  item_title: string;
  inventory: null | Inventory;
  purchase_price: number;
  sale_price: number;
  transaction_fees: number;
  shipping_cost: number;
}

export function getPurchasePrice(item: AllocationValue): number {
  if (!item) return 0;
  if ("purchase_price" in item) return item.purchase_price || 0;
  return (
    ((item as any)?.inventory?.purchase_price || 0) / ((item as any)?.inventory?.quantity || 1)
  );
}

function getAllocations(
  values: {
    sale_price?: number;
    shipping_cost?: number;
    transaction_fees?: number;
  },
  allocationValues: AllocationValue[]
): AllocationValue[] {
  const totalPurchasePrice = allocationValues.reduce(
    (result, item) => result + getPurchasePrice(item),
    0
  );
  const salePrice = values.sale_price || 0;
  const transactionFees = values.transaction_fees || 0;
  const shippingCost = values.shipping_cost || 0;

  let currentSalePrice = 0;
  let currentTransactionFees = 0;
  let currentShippingCost = 0;
  return allocationValues.map((item, index) => {
    const last = index >= allocationValues.length - 1;
    const allocation = (getPurchasePrice(item) * 100) / totalPurchasePrice;
    const result = {
      ...item,
      sale_price: Math.round(salePrice * allocation) / 100,
      transaction_fees: Math.round(transactionFees * allocation) / 100,
      shipping_cost: Math.round(shippingCost * allocation) / 100,
    };

    if (last) {
      result.sale_price =
        Math.round((salePrice - currentSalePrice) * 100) / 100;
      result.transaction_fees =
        Math.round((transactionFees - currentTransactionFees) * 100) / 100;
      result.shipping_cost =
        Math.round((shippingCost - currentShippingCost) * 100) / 100;
    } else {
      currentSalePrice += result.sale_price;
      currentTransactionFees += result.transaction_fees;
      currentShippingCost += result.shipping_cost;
    }
    return result;
  });
}

export default getAllocations;
