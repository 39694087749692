import lodashCapitalize from "lodash/capitalize";
import { getUserIdSync } from "src/config/storage";
import { AnalyticsGroupingItem } from "src/interfaces/systemState.interface";
import firestore from "src/apiService/firestore";

export function normalize(str: string): string {
  return str.trim().replace(/ +/, " ").toLowerCase();
}

export function capitalize(str: string): string {
  return str.split(" ").map(lodashCapitalize).join(" ");
}

export type UpdelsertTransactionRuleParams =
  | { delete: AnalyticsGroupingItem; insert: AnalyticsGroupingItem }
  | { delete: AnalyticsGroupingItem }
  | { insert: AnalyticsGroupingItem };

export async function updelsertAnalyticGrouping(
  params: UpdelsertTransactionRuleParams
) {
  const user = getUserIdSync();
  const userRef = firestore().collection("Users").doc(user);
  await firestore().runTransaction(async (t) => {
    const doc = await t.get(userRef);
    if (!doc?.exists) throw new Error("User doesn't exist");
    const data = doc.data();
    if (!data) throw new Error("User doesn't exist");
    const analyticsGroupings = data.analytics_groupings || {};

    if ("insert" in params && params.insert) {
      const department = normalize(params.insert.department);
      if (!analyticsGroupings[department]) analyticsGroupings[department] = {};
      const category = normalize(params.insert.category);
      if (!analyticsGroupings[department][category])
        analyticsGroupings[department][category] = [];
      const subcategory = normalize(params.insert.subcategory);
      analyticsGroupings[department][category].push(subcategory);
    }

    if ("delete" in params && params.delete) {
      const department = normalize(params.delete.department);
      if (analyticsGroupings[department]) {
        const category = normalize(params.delete.category);
        if (analyticsGroupings[department][category]) {
          const subcategory = normalize(params.delete.subcategory);
          analyticsGroupings[department][category] = analyticsGroupings[
            department
          ][category].filter((s) => s !== subcategory);

          if (analyticsGroupings[department][category].length <= 0) {
            delete analyticsGroupings[department][category];

            if (Object.keys(analyticsGroupings[department]).length <= 0)
              delete analyticsGroupings[department];
          }
        }
      }
    }

    return await t.update(userRef, {
      analytics_groupings: analyticsGroupings,
    });
  });

  if ("insert" in params && params.insert)
    return {
      department: capitalize(normalize(params.insert.department)),
      category: capitalize(normalize(params.insert.category)),
      subcategory: capitalize(normalize(params.insert.subcategory)),
    };

  return;
}
