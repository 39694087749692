import { handleActions } from "redux-actions";
import { LOG_OUT } from "../system/actions";
import {ErrorAlertState} from "../../interfaces/errorAlertState.interface";
import {ERROR_ALERTS, FS_CREATE_ITEMS, FS_DELETE_ITEMS, FS_UPDATE_ITEMS, RESET} from "../common";
import produce from "immer";

const initialState: ErrorAlertState = {
  items: [],
};

export const errorAlertsReducer = handleActions<ErrorAlertState, any>(
    {
        [ERROR_ALERTS + RESET]: (state) =>
            produce(state, (draft) => {
                draft = initialState;
            }),
        [ERROR_ALERTS + FS_CREATE_ITEMS]: (state, {payload}) =>
            produce(state, (draft) => {
                draft.items.push(...payload);
            }),
        [ERROR_ALERTS + FS_DELETE_ITEMS]: (state, {payload}) =>
            produce(state, (draft) => {
                draft.items = state.items.filter((item) => !payload.find((deleted) => deleted.id === item.id));
            }),
        [ERROR_ALERTS + FS_UPDATE_ITEMS]: (state, { payload }) =>
            produce(state, (draft) => {
                draft.items.forEach((item) => {
                    const updated = payload.find((newItem) => {
                        return newItem.id === item.id;
                    });
                    if (updated) {
                        Object.assign(item, updated);
                    }
                });
            }),
        [LOG_OUT]: () => initialState,
    },
  initialState
);
