import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";

import DialogTitle from "./DialogTitle";
import type Step from "./Step";

interface EBayDoYouSellOnEBayProps {
  onClose: () => void;
  onStepChange: (step: Step) => void;
}

type Options = "yes" | "no";

const useStyles = makeStyles((theme) => ({
  content: {
    margin: "auto",
  },
  radioGroup: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(4),
  },
}));

function EBayDoYouSellOnEBay({
  onClose,
  onStepChange,
}: EBayDoYouSellOnEBayProps) {
  const classes = useStyles();
  const [value, setValue] = useState<Options>();

  return (
    <>
      <DialogTitle onClose={onClose}>Set Up Questions - 2/8</DialogTitle>
      <MuiDialogContent className={classes.content}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Do you sell on eBay?</FormLabel>
          <RadioGroup
            value={value || null}
            onChange={(event) => {
              setValue((event.target as HTMLInputElement).value as Options);
            }}
            className={classes.radioGroup}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </MuiDialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onStepChange("inventory:how-do-you-account-for-your-inventory");
          }}
          color="primary"
        >
          Go Back
        </Button>
        <Button
          variant="contained"
          disabled={!value}
          onClick={() => {
            onStepChange(
              value === "yes"
                ? "ebay:add-new-store"
                : "poshmark:do-you-sell-on-poshmark"
            );
          }}
          color="primary"
          autoFocus
        >
          Next
        </Button>
      </DialogActions>
    </>
  );
}

export default EBayDoYouSellOnEBay;
