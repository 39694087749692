import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import InputAdornment from "@material-ui/core/InputAdornment";

import {
  supportGenerateTokenIsLoading,
  supportGetValidUserTokens,
} from "src/store/support/selector";
import { generateUserToken } from "src/store/support/actions";

const useStyles = makeStyles({
  textField: {
    flex: 1,
    marginRight: 8,
  },
  button: {
    position: "relative",
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

function CopyInput({ value }) {
  const handleClick = () => {
    navigator.clipboard.writeText(value);
  };

  return (
    <FormControl fullWidth>
      <Input
        readOnly
        type="text"
        value={value}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={handleClick}>
              <FileCopyIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
}

function Token() {
  const classes = useStyles();
  const isLoading = useSelector(supportGenerateTokenIsLoading);
  const userTokens = useSelector(supportGetValidUserTokens);
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    const input = e.target.querySelector('input[name="username"]');
    if (!input) return;
    const username = input.value;
    dispatch(generateUserToken(username));
  };

  return (
    <Box m={2}>
      <TableContainer component={Paper}>
        <Box
          display="flex"
          mx={2}
          mt={1}
          alignItems="center"
          component="form"
          onSubmit={handleSubmit}
        >
          <TextField
            name="username"
            variant="outlined"
            label="Username"
            className={classes.textField}
            size="small"
            disabled={isLoading}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoading}
            className={classes.button}
          >
            Generate token
            {isLoading && (
              <CircularProgress size={24} className={classes.progress} />
            )}
          </Button>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Expires</TableCell>
              <TableCell>Token</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userTokens.map((token) => (
              <TableRow key={token.username}>
                <TableCell>{token.username}</TableCell>
                <TableCell>
                  {new Date(token.exp * 1000).toLocaleString()}
                </TableCell>
                <TableCell>
                  <CopyInput value={token.value} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default Token;
