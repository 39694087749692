import React, { PropsWithChildren } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import { Theme } from "src/theme";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    padding: "0 30px",
    backgroundColor: "transparent",
    position: "relative",
    marginBottom: 88,
  },
  background: {
    position: "absolute",
    width: "100%",
    height: "150px",
    backgroundColor: theme.palette.colors.lightBlue,
    left: 0,
    zIndex: -1,
  },
}));

function Wrapper({
  className,
  children,
  ...props
}: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) {
  const classes = useStyles();

  return (
    <main className={clsx(className, classes.root)} {...props}>
      <span className={classes.background} />
      {children}
    </main>
  );
}

export default Wrapper;
