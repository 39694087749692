import logo from "src/assets/svgs/logo.svg";

const DefaultStyle = "margin: 24px";

function renderReport(
  title: string,
  content: string,
  center = true,
  style = DefaultStyle
) {
  if (center)
    content = `<div style="width: 480px; margin: 0 auto;">${content}</div>`;
  else content = `<div style="width: 100%; margin: 0">${content}</div>`;

  return `
<div style="${style}">
  <div style="display: flex; align-items: center">
    <h1 style="font-size: 24px">${title}</h1>
    <div style="flex: 1"></div>
    <img src="${logo}" style="max-height: 96px" />
  </div>
  ${content}
</div>`;
}

export default renderReport;
