import { SupportState } from "src/interfaces/supportState.interface";

import {
  SupportActionTypes,
  SET_SUPPORT_LOGIN_TOKEN,
  SET_SUPPORT_USER_TOKEN,
  SET_SUPPORT_USER_DATA,
} from "./actions";

const initialState: SupportState = {
  token: "",
  userTokens: {},
  userData: {},
};

export function supportReducer(
  state = initialState,
  action: SupportActionTypes
): SupportState {
  switch (action.type) {
    case SET_SUPPORT_LOGIN_TOKEN:
      return {
        ...state,
        token: action.token,
      };

    case SET_SUPPORT_USER_TOKEN:
      return {
        ...state,
        userTokens: {
          ...state.userTokens,
          [action.payload.username]: action.payload,
        },
      };

    case SET_SUPPORT_USER_DATA:
      return {
        ...state,
        userData: {
          ...state.userData,
          [action.payload.username]: action.payload,
        },
      };

    default:
      return state;
  }
}
