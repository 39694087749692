import produce from "immer";
import { handleActions } from "redux-actions";

import { NumericOverviewState } from "../../interfaces";
import { METHOD_TYPE } from "../../enums";
import { LOG_OUT } from "../system/actions";
import {
  NUMERIC_OVERVIEW,
  FS_CREATE_ITEMS,
  FS_UPDATE_ITEMS,
  FS_DELETE_ITEMS,
  SELECT_ITEMS,
  SET_FILTER,
  SET_VISIBLE,
  CLEAR_UPDATE_METHOD,
} from "../common"

const initialState: NumericOverviewState = {
  items: [],
  total: 0,
  start: 1,
  end: 1,
  limit: 25,

  method: METHOD_TYPE.LIST,
  loading: true,
  loaded: false,
  error: null,
  message: "",

  selected: [],
  visible: [],
  filter: {
    items: [],
  }
};

export const numericOverviewReducer = handleActions<NumericOverviewState, any>(
  {
    [NUMERIC_OVERVIEW + SELECT_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.selected = payload;
      }),
    [NUMERIC_OVERVIEW + SET_FILTER]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.filter = payload;
      }),
    [NUMERIC_OVERVIEW + SET_VISIBLE]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.visible = payload;
      }),
    [CLEAR_UPDATE_METHOD]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.LIST;
      }),
    [NUMERIC_OVERVIEW + FS_CREATE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items.push(...payload.sort((a, b) => {
          return (a.date._seconds || a.date.seconds) - (b.date._seconds || b.date.seconds);
        }));
        draft.visible = draft.items.map(e => e.id);
        draft.loading = false;
        draft.loaded = true;
      }),
    [NUMERIC_OVERVIEW + FS_UPDATE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items.forEach((item) => {
          const updated = payload.find((newItem) => newItem.id === item.id);
          Object.assign(item, updated);
        });
      }),
    [NUMERIC_OVERVIEW + FS_DELETE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items = state.items.filter((item) => !payload.find((deleted) => deleted.id === item.id));
      }),
    [LOG_OUT]: () => initialState,
  },
  initialState
);
