import React, { useState, type PropsWithChildren } from "react";

import Dialog, { type DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText, {
  type DialogContentTextProps,
} from "@material-ui/core/DialogContentText";
import DialogTitle, {
  type DialogTitleProps,
} from "@material-ui/core/DialogTitle";
import Button, { type ButtonProps } from "@material-ui/core/Button";

// fix-vim-higlight = }

export interface ConfirmDialogProps {
  open: DialogProps["open"];
  title: DialogTitleProps["children"];
  onCancel: () => void;
  onConfirm: () => Promise<void> | void;
  text: DialogContentTextProps["children"];
  cancel?: ButtonProps["children"];
  confirm?: ButtonProps["children"];
}

type ConfirmDialogPropsWithChildren = PropsWithChildren<ConfirmDialogProps>;

export function ConfirmDialogContent({
  text,
  onCancel,
  onConfirm,
  cancel,
  confirm,
  children,
}: Omit<ConfirmDialogPropsWithChildren, "open" | "title">) {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <DialogContent>
        {text ? <DialogContentText>{text}</DialogContentText> : null}
      </DialogContent>
      {children}
      <DialogActions>
        <Button onClick={onCancel} disabled={loading}>
          {cancel || "No"}
        </Button>
        <Button
          disabled={loading}
          onClick={async () => {
            const ret = onConfirm();
            if (ret && "then" in ret && ret["then"]) {
              setLoading(true);
              await ret;
              setLoading(false);
            }
          }}
          color="primary"
          variant="contained"
        >
          {confirm || "Yes"}
        </Button>
      </DialogActions>
    </>
  );
}

function ConfirmDialog({
  open,
  title,
  onCancel,
  ...rest
}: ConfirmDialogPropsWithChildren) {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <ConfirmDialogContent onCancel={onCancel} {...rest} />
    </Dialog>
  );
}

export default ConfirmDialog;
