import firebase from "firebase/app";
import chunk from "lodash/chunk";
import firestore from "src/apiService/firestore";

import axiosClient from "../axiosClient";
import { Inventory } from "../../interfaces";
import { getUserId } from "src/config/storage";
import { requestNOUpdate } from "./numericOverview";

import { MaximumBatchSize } from "src/config/firestore";

export const fetchInventoriesApi = async (payload: any) => {
  const { data } = await axiosClient.get(`inventories?&limit=0`);
  return data;
};

export const deleteInventoriesApi = async () => { // TODO: type
  const { data } = await axiosClient.delete("inventories");
  return data;
};

export async function updateInventory(payload: Inventory) {
  if (!payload?.id) return;
  const db = firestore();
  const user = await getUserId();
  payload = {
    ...payload,
    user,
  };

  for (const key of Object.keys(payload)) {
    if (payload[key] === undefined)
      delete payload[key];
  }

  await db
    .collection("Inventory")
    .doc(payload.id)
    .update(payload);
}

export const deleteInventoryApi = async (inventoryId: string) => {
  if (!inventoryId) return;
  return firestore().collection("Inventory").doc(inventoryId).delete();
};

export async function deleteBulkInventoryApi(ids: string[]) {
  if (!ids.length) return;
  const db = firestore();
  for (const c of chunk(ids, MaximumBatchSize)) {
    const batch = db.batch();
    for (const id of c) {
      batch.delete(db.collection("Inventory").doc(id));
    }
    await batch.commit();
  }
}

export async function addInventory({ quantity, ...payload }: any): Promise<string> { // TODO: type
  const user = await getUserId();
  const db = firestore();
  quantity = parseInt(quantity, 10) || 1;

  for (const k of Object.keys(payload)) {
    if (payload[k] === undefined)
      delete payload[k];
  }

  const doc = db.collection("Inventory").doc();
  await doc.set({
    ...payload,
    quantity,
    return_id: payload.return_id || null,
    user,
    item_option: null,
    id: doc.id,
  });

  return doc.id;
}

export async function updateInventoryItems(items: (Pick<Inventory, "id"> & Partial<Inventory>)[]) {
  if (!items.length) return;
  const db = firestore();
  for (const c of chunk(items, MaximumBatchSize)) {
    const batch = db.batch();
    for (const item of c) {
      const doc = db.collection("Inventory").doc(item.id);
      batch.update(doc, item);
    }
    await batch.commit();
  }
}

export async function uploadInventoryFile(rows, metadata = {}) {
  const f = firebase.functions().httpsCallable("upload-uploadInventory");

  return (await f({ rows, metadata })).data;
}

export async function bulkEditInventory(rows, metadata = {}) {
  const f = firebase.functions().httpsCallable("upload-bulkEditInventory");
  const ret = (await f({ rows, metadata })).data;
  await requestNOUpdate();
  return ret;
}
