import React, { useState } from "react";
import Tour from "reactour";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import { userDontShowDialogsSelector } from "src/store/system/selector";
import DontShowThisMessageAgainCheckbox from "src/components/DontShowThisMessageAgainCheckbox";

const Key = "walk_through_inventory_import";

const Steps = [
  {
    content: (props) => (
      <>
        <DialogTitle>Inventory Import - 1/4</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Every morning, new listings will be pulled from your integrations if
            the “Pull Inventory” box is checked on the “Integrations” tab. These
            items are held for your review instead of being added immediately.
          </DialogContentText>
          <DialogContentText>
            During this step, you can add purchase price, purchase date, and
            more.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(1)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#inventory-import-review-all-switch",
    content: (props) => (
      <>
        <DialogTitle>Inventory Import - 2/4</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you would like to review fewer inventory items, deselect the
            “Review All” toggle.
          </DialogContentText>
          <DialogContentText>
            If you went through the duplicate checking process, the toggle can’t
            be reset on this screen.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(0)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(2)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: ".inventory-import-copy-button:enabled",
    content: (props) => (
      <>
        <DialogTitle>Inventory Import - 3/4</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can copy the data from the item above by clicking this button.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(1)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(3)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#inventory-import-actions",
    content: (props) => (
      <>
        <DialogTitle>Inventory Import - 4/4</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Once you are ready to add all the inventory on this page to your
            inventory list, click “Add”. If you want to delete all the listings
            instead, click “Remove All”.
          </DialogContentText>
          <DontShowThisMessageAgainCheckbox dialog={Key} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(2)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.close()}
          >
            Close
          </Button>
        </DialogActions>
      </>
    ),
  },
];

const useStyles = makeStyles((theme) => ({
  helper: {
    maxWidth: "80%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "500px",
    },
    padding: 0,
  },
}));

export function WalkThrough() {
  const [open, setOpen] = useState(true);
  const userDontShowDialogs = useSelector(userDontShowDialogsSelector);

  const classes = useStyles();
  const isOpen =
    open && userDontShowDialogs && !userDontShowDialogs?.includes(Key);

  return (
    <Tour
      startAt={0}
      closeWithMask={false}
      disableFocusLock
      steps={Steps}
      isOpen={isOpen}
      onRequestClose={() => {
        setOpen(false);
      }}
      showButtons={false}
      showCloseButton={false}
      showNavigation={false}
      showNavigationNumber={false}
      showNumber={false}
      disableDotsNavigation
      rounded={5}
      className={classes.helper}
      disableInteraction
    />
  );
}

export default WalkThrough;
