import { createAction } from "redux-actions";
import { getSearchByQuery } from "./selector";
import { FAIL, START, SUCCESS } from "../common";

export const FetchArticles = "FetchArticles";

export const fetchArticlesStart = createAction<{ query: string; page: number }>(
  FetchArticles + START
);
export const fetchArticlesSuccess = createAction<any>(FetchArticles + SUCCESS);
export const fetchArticlesFail = createAction<{ query: string; page: number }>(
  FetchArticles + FAIL
);

export function searchZendesk(query: string, page = 1) {
  return (dispatch, getState) => {
    if (!query) return;
    const state = getState();
    const search = getSearchByQuery(state, { query, page });
    if (search) return;

    dispatch(fetchArticlesStart({ query, page }));

    fetch(
      `https://myresellergenie.zendesk.com/api/v2/help_center/articles/search?query=${encodeURIComponent(
        query
      )}&page=${page}&per_page=5`
    )
      .then((r) => {
        if (r.ok) return r.json();
        return Promise.reject(r);
      })
      .then((json) => {
        dispatch(
          fetchArticlesSuccess({
            query,
            page,
            hasNext: !!json.next_page,
            results: json.results,
          })
        );
      })
      .catch((e) => {
        dispatch(
          fetchArticlesFail({
            query,
            page,
          })
        );
      });
  };
}
