import type { Alert } from "src/interfaces/alert.interface";

export default function getSelector(type: Alert["type"]): string | undefined {
  switch (type) {
    case "review_sales":
      return "walkthrough-notifications-review-new-sales";
    case "inventory":
      return "walkthrough-notifications-review-pending-inventory";
    case "expenses":
      return "walkthrough-notifications-review-new-expenses";
    case "returns":
      return "walkthrough-notifications-review-new-funds";
  }
}
