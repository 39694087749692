import React, {useState} from "react";

import { useDispatch } from "react-redux";

import {
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Link as MuiLink,
    Typography,
} from "@material-ui/core";

import { setActiveDialog } from "../../store/adminHtml/actions";
import 'react-toastify/dist/ReactToastify.css';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {acceptUserTerms} from "../../apiService/modules/users";
import {toast} from "react-toastify";


const AcceptTermsDialog = ({ open }: any) => {
    const dispatch = useDispatch();

    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        //do nothing;
    };

    const handleChange = (isChecked) => {
        setChecked(isChecked);
    }

    const acceptTerms = () => {
        setLoading(true);
        acceptUserTerms().then((results) => {
            setLoading(false);
            if(results){
                dispatch(setActiveDialog(""));
            } else{
                toast.error("There was an error accepting the terms. Please try again. If the problem persists, try refreshing your browser.");
            }
        })
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll={"body"}
            aria-labelledby="welcome-dialog"
            fullWidth={true}
            maxWidth={"sm"}
        >
            <DialogTitle id="welcome-dialog">
                Please review the Terms of Service and Privacy Policy
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    className="relative"
                    tabIndex={-1}
                ><Typography variant="body1">We have recently updated our Privacy Policy and Terms of Use. Please review and confirm that you agree to these terms by checking the box and clicking the "Confirm" button.</Typography>
                </DialogContentText>
                <FormControlLabel
                    checked={checked}
                    name="accepted"
                    onChange={(_, isChecked) => handleChange(isChecked)}
                    control={<Checkbox value="accepted" color="primary" />}
                    label={<div>I agree to the <MuiLink href="https://www.myresellergenie.com/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</MuiLink> and <MuiLink href="https://www.myresellergenie.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</MuiLink>.</div>}
                />
            </DialogContent>
            <DialogActions className="px-7 py-5">
                <Button
                    disabled={!checked}
                    onClick={acceptTerms}
                >
                    {loading && <CircularProgress size={15} className="mr-3" />}
                    {!loading && <span>Confirm</span>}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AcceptTermsDialog;
