import React, { useState, cloneElement, useMemo } from "react";
import Tour from "reactour";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Paper from "@material-ui/core/Paper";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import useUserEffect from "src/utils/useUserEffect";
import { userDontShowDialogsSelector } from "src/store/system/selector";
import DontShowThisMessageAgainCheckbox from "src/components/DontShowThisMessageAgainCheckbox";
import type { DontShowDialog } from "src/interfaces/systemState.interface";
import type { Alert } from "src/interfaces/alert.interface";

import getAlertSelector from "./getAlertSelector";

function Dialog({
  title,
  text,
  dialog,
  onClick,
}: {
  title: string;
  text: string;
  dialog: DontShowDialog;
  onClick: () => void;
}) {
  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
        <DontShowThisMessageAgainCheckbox dialog={dialog} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClick}>
          Done
        </Button>
      </DialogActions>
    </>
  );
}
function Wrapper(props) {
  return (
    <Dialog
      title={props.title}
      text={props.text}
      dialog={props.dialog}
      onClick={() => props.next()}
    />
  );
}
const Steps = [
  {
    key: "walk_through_notifications_review_new_sales" as const,
    selector: ".walkthrough-notifications-review-new-sales",
    content: (props) => (
      <Wrapper
        {...props}
        title="Review New Sales"
        text="Congrats on your new sales! You’ll see this notification when new sales have been pulled in from your integrations. Click on it to review them."
        dialog="walk_through_notifications_review_new_sales"
        key="sales"
      />
    ),
  },
  {
    key: "walk_through_notifications_review_pending_inventory" as const,
    selector: ".walkthrough-notifications-review-pending-inventory",
    content: (props) => (
      <Wrapper
        {...props}
        title="Review Pending Inventory"
        text="Looks like some new listings have been created. These are not automatically imported because they need inventory cost information attached and duplicates need to be filtered out. Click here to review the new listings."
        dialog="walk_through_notifications_review_pending_inventory"
        key="inventory"
      />
    ),
  },
  {
    key: "walk_through_notifications_review_new_expenses" as const,
    selector: ".walkthrough-notifications-review-new-expenses",
    content: (props) => (
      <Wrapper
        {...props}
        title="Review New Expenses"
        text="You have new expenses that imported from one of your integrations. Click here to review and categorize them."
        dialog="walk_through_notifications_review_new_expenses"
        key="expenses"
      />
    ),
  },
  {
    key: "walk_through_notifications_review_new_funds" as const,
    selector: ".walkthrough-notifications-review-new-funds",
    content: (props) => (
      <Wrapper
        {...props}
        title="Review New Refunds"
        text="You have new returns/refunds/cancellations that imported from one of your integrations. Click here to review them."
        dialog="walk_through_notifications_review_new_funds"
        key="refunds"
      />
    ),
  },
];

const Keys: DontShowDialog[] = Steps.map((s) => s.key);

const useStyles = makeStyles((theme) => ({
  helper: {
    maxWidth: "80%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "500px",
    },
    padding: 0,
  },
}));

function CustomHelper(props) {
  return (
    <>
      {props.children}
      <Paper>
        {cloneElement(props.content, {
          next: () => {
            const n = props.current + 1;
            if (n < props.totalSteps) props.gotoStep(n);
            else props.close();
          },
        })}
      </Paper>
    </>
  );
}
function WalThroughNotifications({ alerts }: { alerts: Alert[] }) {
  const [open, setOpen] = useState(true);
  const userDontShowDialogs = useSelector(userDontShowDialogsSelector);
  const [memory, setMemory] = useState<DontShowDialog[]>();

  useUserEffect((user) => {
    setMemory(user.dontShowDialogs);
  }, []);

  const classes = useStyles();
  const steps = useMemo(() => {
    return Steps.filter((step) => {
      if (memory?.includes(step.key)) return false;
      return alerts.some(
        (a) => `.${getAlertSelector(a.type)}` === step.selector
      );
    });
  }, [memory, alerts]);
  const isOpen =
    open &&
    userDontShowDialogs &&
    !Keys.every((k) => userDontShowDialogs?.includes(k)) &&
    steps.length > 0;

  return (
    <Tour
      startAt={0}
      closeWithMask={false}
      disableFocusLock
      steps={steps}
      isOpen={isOpen}
      onRequestClose={() => {
        setOpen(false);
      }}
      showButtons={false}
      showCloseButton={false}
      showNavigation={false}
      showNavigationNumber={false}
      showNumber={false}
      disableDotsNavigation
      rounded={5}
      CustomHelper={CustomHelper}
      className={classes.helper}
      disableInteraction
    />
  );
}

export default WalThroughNotifications;
