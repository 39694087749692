import type { ComponentType } from "react";
import NullComponent from "src/components/NullComponent";

function withOnlyDevelopment<T = {}>(
  Component: ComponentType<T>
): ComponentType<T> {
  if (process.env.REACT_APP_MODE === "development") return Component;
  return NullComponent;
}

export default withOnlyDevelopment;
