import { handleActions } from "redux-actions";
import { LOG_OUT } from "../system/actions";

import {
  FS_CREATE_ITEMS,
  FS_DELETE_ITEMS,
  FS_UPDATE_ITEMS,
  VENDORS,
} from "../common";
import produce from "immer";
import { VendorState } from "src/interfaces/vendor.interface";

const initialState: VendorState = {
  items: [],
};

export const vendorReducer = handleActions<VendorState, any>(
  {
    [VENDORS + FS_CREATE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items.push(...payload);
      }),
    [VENDORS + FS_DELETE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items = state.items.filter(
          (item) => !payload.find((deleted) => deleted.id === item.id)
        );
      }),
    [VENDORS + FS_UPDATE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items.forEach((item) => {
          const updated = payload.find((newItem) => {
            return newItem.id === item.id;
          });
          if (updated) {
            Object.assign(item, updated);
          }
        });
      }),
    [LOG_OUT]: () => initialState,
  },
  initialState
);
