import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { updateUserApi } from "src/apiService/modules/system";
import { userGetStartedStepSelector } from "src/store/system/selector";

import type Step from "./Step";
import InventoryHowDoYouAccount from "./InventoryHowDoYouAccount";
import InventoryCashVsAccrual from "./InventoryCashVsAccrual";
import InventoryImportantTip from "./InventoryImportantTip";
import EBayDoYouSellOnEBay from "./EBayDoYouSellOnEBay";
import EBayAddNewEBayStore from "./EBayAddNewEBayStore";
import EBayImport from "./EBayImport";
import EBayImportInProcess from "./EBayImportInProcess";
import EBayAdditionalStores from "./EBayAdditionalStores";
import PoshmarkDoYouSellOnPoshmark from "./PoshmarkDoYouSellOnPoshmark";
import PoshmarkAddExtension from "./PoshmarkAddExtension";
import PoshmarkConfirm from "./PoshmarkConfirm";
import PoshmarkImport from "./PoshmarkImport";
import PoshmarkImportInProcess from "./PoshmarkImportInProcess";
import MercariDoYouSellOnMercari from "./MercariDoYouSellOnMercari";
import MercariUploadReport from "./MercariUploadReport";
import MercariAddExtension from "./MercariAddExtension";
import MercariConfirm from "./MercariConfirm";
import MercariImport from "./MercariImport";
import MercariImportInProcess from "./MercariImportInProcess";
import AmazonDoYouSellOnAmazon from "./AmazonDoYouSellOnAmazon";
import AmazonAddNewAmazonStore from "./AmazonAddNewAmazonStore";
import AmazonImport from "./AmazonImport";
import AmazonImportInProcess from "./AmazonImportInProcess";
import EtsyDoYouSellOnEtsy from "./etsy/EtsyDoYouSellOnEtsy";
import EtsyAddNewEtsyStore from "./etsy/EtsyAddNewEtsyStore";
import OtherDoYouSell from "./OtherDoYouSell";
import OtherUpload from "./OtherUpload";
import OtherDoYouHaveAdditional from "./OtherDoYouHaveAdditional";
import PlaidWouldYouLikeToConnect from "./PlaidWouldYouLikeToConnect";
import PlaidAddNew from "./PlaidAddNew";
import ExpensesDoYouHaveOther from "./ExpensesDoYouHaveOther";
import ExpensesDoYouHaveMore from "./ExpensesDoYouHaveMore";
import BulkEditInstructions from "./BulkEditInstructions";
import BulkEditHaveYouFinished from "./BulkEditHaveYouFinished";
import CompleteSetUp from "./CompleteSetUp";

export interface DialogContentProps {
  onClose: () => void;
}

const SaveSteps: Step[] = [
  "ebay:do-you-sell-on-ebay",
  "ebay:import",
  "ebay:additional-stores",
  "poshmark:do-you-sell-on-poshmark",
  "poshmark:import",
  "mercari:do-you-sell-on-mercari",
  "mercari:import",
  "other:do-you-sell",
  "other:do-you-have-additional",
  "plaid:would-you-like-to-connect",
  "expenses:do-you-have-other",
  "expenses:do-you-have-more",
  "bulk-edit:instructions",
  "complete-set-up",
];

function DialogContent({ onClose }: DialogContentProps) {
  const initialStep = useSelector(userGetStartedStepSelector);
  const [{ step, previous }, setStep] = useState<{
    step: Step;
    previous: Step;
  }>({
    step:
      (document.location.hash
        ? (document.location.hash.split("#")[1] as Step)
        : "") ||
      initialStep ||
      "inventory:how-do-you-account-for-your-inventory",
    previous: "inventory:how-do-you-account-for-your-inventory",
  });
  const handleStepChange = (step: Step) => {
    if (SaveSteps.includes(step)) updateUserApi({ getStartedStep: step });
    setStep((b) => ({
      step,
      previous: b.step,
    }));
  };
  const history = useHistory();

  useEffect(() => {
    // We remove the hash in case there was an initial one
    if (history.location.hash)
      history.replace(history.location.pathname, history.location.state);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  switch (step) {
    case "inventory:how-do-you-account-for-your-inventory":
      return (
        <InventoryHowDoYouAccount
          onClose={onClose}
          onStepChange={handleStepChange}
        />
      );

    case "inventory:cash-vs-accrual-inventory":
      return (
        <InventoryCashVsAccrual
          onClose={onClose}
          onStepChange={handleStepChange}
        />
      );

    case "inventory:important-tip":
      return (
        <InventoryImportantTip
          onClose={onClose}
          onStepChange={handleStepChange}
        />
      );

    case "ebay:do-you-sell-on-ebay":
      return (
        <EBayDoYouSellOnEBay
          onClose={onClose}
          onStepChange={handleStepChange}
        />
      );

    case "ebay:add-new-store":
      return (
        <EBayAddNewEBayStore
          onClose={onClose}
          onStepChange={handleStepChange}
          previous={previous}
        />
      );

    case "ebay:import":
      return <EBayImport onClose={onClose} onStepChange={handleStepChange} />;

    case "ebay:import-in-process":
      return (
        <EBayImportInProcess
          onClose={onClose}
          onStepChange={handleStepChange}
        />
      );

    case "ebay:additional-stores":
      return (
        <EBayAdditionalStores
          onClose={onClose}
          onStepChange={handleStepChange}
        />
      );

    case "poshmark:do-you-sell-on-poshmark":
      return (
        <PoshmarkDoYouSellOnPoshmark
          onClose={onClose}
          onStepChange={handleStepChange}
        />
      );

    case "poshmark:add-extension":
      return (
        <PoshmarkAddExtension
          onClose={onClose}
          onStepChange={handleStepChange}
        />
      );

    case "poshmark:confirm":
      return (
        <PoshmarkConfirm onClose={onClose} onStepChange={handleStepChange} />
      );

    case "poshmark:import":
      return (
        <PoshmarkImport onClose={onClose} onStepChange={handleStepChange} />
      );

    case "poshmark:import-in-process":
      return (
        <PoshmarkImportInProcess
          onClose={onClose}
          onStepChange={handleStepChange}
        />
      );

    case "mercari:do-you-sell-on-mercari":
      return (
        <MercariDoYouSellOnMercari
          onClose={onClose}
          onStepChange={handleStepChange}
        />
      );

    case "mercari:upload-report":
      return (
        <MercariUploadReport
          onClose={onClose}
          onStepChange={handleStepChange}
        />
      );

    case "mercari:add-extension":
    case "mercari:update-extension":
      return (
        <MercariAddExtension
          onClose={onClose}
          onStepChange={handleStepChange}
          update={step === "mercari:update-extension"}
        />
      );

    case "mercari:confirm":
      return (
        <MercariConfirm onClose={onClose} onStepChange={handleStepChange} />
      );

    case "mercari:import":
      return (
        <MercariImport onClose={onClose} onStepChange={handleStepChange} />
      );

    case "mercari:import-in-process":
      return (
        <MercariImportInProcess
          onClose={onClose}
          onStepChange={handleStepChange}
        />
      );

    case "amazon:do-you-sell-on-amazon":
      return (
        <AmazonDoYouSellOnAmazon
          onClose={onClose}
          onStepChange={handleStepChange}
        />
      );

    case "amazon:add-new-store":
      return (
        <AmazonAddNewAmazonStore
          onClose={onClose}
          onStepChange={handleStepChange}
          previous={previous}
        />
      );

    case "amazon:import":
      return <AmazonImport onClose={onClose} onStepChange={handleStepChange} />;

    case "amazon:import-in-process":
      return (
        <AmazonImportInProcess
          onClose={onClose}
          onStepChange={handleStepChange}
        />
      );
    case "etsy:do-you-sell-on-etsy":
      return (
        <EtsyDoYouSellOnEtsy
          onClose={onClose}
          onStepChange={handleStepChange}
        />
      );

    case "etsy:add-new-store":
      return (
        <EtsyAddNewEtsyStore
          onClose={onClose}
          onStepChange={handleStepChange}
        />
      );

    case "other:do-you-sell":
      return (
        <OtherDoYouSell onClose={onClose} onStepChange={handleStepChange} />
      );

    case "other:upload":
      return <OtherUpload onClose={onClose} onStepChange={handleStepChange} />;

    case "other:do-you-have-additional":
      return (
        <OtherDoYouHaveAdditional
          onClose={onClose}
          onStepChange={handleStepChange}
        />
      );

    case "plaid:would-you-like-to-connect":
      return (
        <PlaidWouldYouLikeToConnect
          onClose={onClose}
          onStepChange={handleStepChange}
        />
      );

    case "plaid:add-new":
      return <PlaidAddNew onClose={onClose} onStepChange={handleStepChange} />;

    case "expenses:do-you-have-other":
      return (
        <ExpensesDoYouHaveOther
          onClose={onClose}
          onStepChange={handleStepChange}
        />
      );

    case "expenses:do-you-have-more":
      return (
        <ExpensesDoYouHaveMore
          onClose={onClose}
          onStepChange={handleStepChange}
        />
      );

    case "bulk-edit:instructions":
      return (
        <BulkEditInstructions
          onClose={onClose}
          onStepChange={handleStepChange}
        />
      );

    case "bulk-edit:have-you-finished":
      return (
        <BulkEditHaveYouFinished
          onClose={onClose}
          onStepChange={handleStepChange}
        />
      );

    case "complete-set-up":
      return (
        <CompleteSetUp onClose={onClose} onStepChange={handleStepChange} />
      );
  }

  return null;
}

export default DialogContent;
