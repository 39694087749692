import React, { useState, useEffect } from "react";
import Tour from "reactour";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import { userDontShowDialogsSelector } from "src/store/system/selector";
import DontShowThisMessageAgainCheckbox from "src/components/DontShowThisMessageAgainCheckbox";
import { useWalkThroughMarker } from "src/utils/WalkThroughMarker";

const Key = "walk_through_duplicate_inventory_checker";

const Steps = [
  {
    content: (props) => (
      <>
        <DialogTitle>Duplicate Checker - 1/5</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Each day, newly created listings will be pulled if the “Pull
            Inventory” box is checked on the “Integrations” tab. If you list or
            relist an item that is already in your inventory, the duplicate
            checker helps to prevent you from adding this item into inventory
            again.
          </DialogContentText>
          <DialogContentText>
            The duplicate checker will first look for title matches (first
            screen). Next, it will look for SKU matches (second screen).
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(1)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#duplicate-inventory-checker-review-all-switch",
    content: (props) => (
      <>
        <DialogTitle>Duplicate Checker - 2/5</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Each day, MRG will pull new listings and hold them for review -- if
            it’s been a while since you reviewed the pending listings, you can
            review one day at a time by turning off the “Review All” toggle.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(0)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(2)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector:
      ".duplicate-inventory-checker-x-grid .MuiDataGrid-columnHeaderWrapper",
    content: (props) => (
      <>
        <DialogTitle>Duplicate Checker - 3/5</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Hover over column titles to read descriptions of the information in
            the column.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(1)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(3)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#duplicate-inventory-checker-table-walkthough-check",
    content: (props) => (
      <>
        <DialogTitle>Duplicate Checker - 4/5</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select individual items using the boxes on the left, or select all
            items by clicking the box in the header.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(2)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(4)}
          >
            Next
          </Button>
        </DialogActions>
      </>
    ),
  },
  {
    selector: "#duplicate-inventory-checker-actions",
    content: (props) => (
      <>
        <DialogTitle>Duplicate Checker - 5/5</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Once you are ready, click “Add to import” or “Remove from import” to
            tell MRG if you want to add these items to be imported or not.
          </DialogContentText>
          <DontShowThisMessageAgainCheckbox dialog={Key} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.goTo(3)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.close()}
          >
            Close
          </Button>
        </DialogActions>
      </>
    ),
  },
];

const useStyles = makeStyles((theme) => ({
  helper: {
    maxWidth: "80%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "500px",
    },
    padding: 0,
  },
}));

export function WalkThrough() {
  const [open, setOpen] = useState(true);
  const userDontShowDialogs = useSelector(userDontShowDialogsSelector);

  const classes = useStyles();
  const isOpen =
    open && userDontShowDialogs && !userDontShowDialogs?.includes(Key);

  useWalkThroughMarker(
    !!isOpen,
    "duplicate-inventory-checker-table-walkthough-check",
    "div > .MuiDataGrid-columnHeaderCheckbox .MuiIconButton-label, div > .MuiDataGrid-cellCheckbox:first-child",
    ".duplicate-inventory-checker-x-grid"
  );

  return (
    <Tour
      startAt={0}
      closeWithMask={false}
      disableFocusLock
      steps={Steps}
      isOpen={isOpen}
      onRequestClose={() => {
        setOpen(false);
      }}
      showButtons={false}
      showCloseButton={false}
      showNavigation={false}
      showNavigationNumber={false}
      showNumber={false}
      disableDotsNavigation
      rounded={5}
      className={classes.helper}
      disableInteraction
    />
  );
}

function DuplicateInventoryCheckerWalkThrough({
  loading,
}: {
  loading: boolean;
}) {
  const [render, setRender] = useState(!loading);
  useEffect(() => {
    if (loading) return;
    const interval = setTimeout(() => {
      setRender(true);
    });
    return () => {
      clearInterval(interval);
    };
  }, [loading]);
  if (!render) return null;
  return <WalkThrough />;
}

export default DuplicateInventoryCheckerWalkThrough;
