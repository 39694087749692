import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Grid,
  Paper,
  ClickAwayListener,
} from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

import { LicenseInfo } from "@material-ui/x-grid";
import XGrid from "src/components/CSVFormattedXGrid";
import { createTableToolbar } from "src/components/TableToolbar";

import { toast } from "react-toastify";

import { /* withStyles, createStyles, */ makeStyles /* , Theme */ } from "@material-ui/core/styles";
import { fetchItems as fetchItemOptions } from "src/store/itemOptions/actions";

import InfoTooltip from "../../components/InfoTooltip";
import { EditBankStatementDialog } from "./EditBankStatementDialog";
import { StatefulLoadableButton } from "src/components/LoadableButton";
import { bankStatementDeleteLast } from "src/apiService/modules/bankStatement";

import {
  updateItem,
  setFilter,
  selectItems,
  createItem,
} from "../../store/bankStatement/actions";
import { bankStatementSelector } from "../../store/bankStatement/selector";
import { clearUpdateMethod } from "../../store/common";
// import { loginVerifiedSelector } from "../../store/system/selector";

import { formatCurrency } from "../../utils"
import { BankStatement } from "../../interfaces";
import { METHOD_TYPE } from "../../enums";
import { X_GRID_LICENSE_KEY } from "../../config";

LicenseInfo.setLicenseKey(X_GRID_LICENSE_KEY);

interface ConfirmDeleteProps {
  open: boolean;
  onClose: () => void;
}

function ConfirmDelete({
  open,
  onClose,
}: ConfirmDeleteProps) {
  return(
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Confirm</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to delete the last Bank Statement?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          No
        </Button>
        <StatefulLoadableButton
          onClick={async () => {
            const result = await bankStatementDeleteLast();
            if (result.error) {
              toast.error(result.message);
              return;
            }
            toast.success("Bank statement deleted");
            onClose();
          }}
          color="primary"
          autoFocus
        >
          Yes
        </StatefulLoadableButton>
      </DialogActions>
    </Dialog>
  );
}

const tableColumnInfo = {
  bank_statement_name: "The name you create to identify the bank statement.",
  bank_statement_total: "The total ending balance on the bank statement.",
  reconciled: "Shows whether or not the cash activities reconcile to the bank statement total.",
  reconciliation_amount: "Shows the amount of cash that has been reconciled.",
};

const renderHeaderLabel = (params) => {
  const tooltip = tableColumnInfo[params.field];
  return (
    <div className="MuiDataGrid-columnHeaderTitle">
      {params.colDef.headerName}
      {!!tooltip && <InfoTooltip size="small" text={tooltip} />}
    </div>
  );
}

const columns = [
  { field: "bank_statement_name", headerName: "Name", width: 150, renderHeader: renderHeaderLabel },
  { field: "bank_statement_total", headerName: "Total", type: "number", width: 150, valueFormatter: formatCurrency, renderHeader: renderHeaderLabel },
  { field: "bank_statement_date", headerName: "Date", type: "date", width: 150 },
  { field: "reconciled", headerName: "Reconciled", type: "boolean", width: 180, renderHeader: renderHeaderLabel },
  { field: "reconciliation_amount", headerName: "Reconciliation Amount", type: "number", width: 245, valueFormatter: formatCurrency, renderHeader: renderHeaderLabel },
];

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-root": {
      minHeight: "300px",
      height: "calc(50vh - 300px)"
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      borderRight: `1px solid #f0f0f0`,
      padding: "0px 10px",
      fontSize: "12px",
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      borderBottom: `1px solid #f0f0f0`
    },
    "& .MuiDataGrid-cell": {
      color: `rgba(0,0,0,.85)`,
    },
    "& .MuiDataGrid-iconSeparator": {
      opacity: 0,
    },
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#fafafa",
      borderTop: "1px solid #f0f0f0",
      fontSize: "12px",
    },
    "& .MuiDataGrid-colCell": {
      borderRight: `1px solid #f0f0f0`,
      padding: "0px 10px",
    }
  },
});

const TableToolbar = createTableToolbar({
  options:{ fileName: "My Reseller Genie - Bank Statements" }
});

export const BankStatementContent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  
  // const loginVerified = useSelector(loginVerifiedSelector);
  const {
    items,
    method,
    loading,
    // loaded,
    error,
    // total,
    filter: filterModel,
    selected: selectionModel,
  } = useSelector(bankStatementSelector);

  const selectedItem = !selectionModel?.length ? null : (items || []).find((item) => item.id === selectionModel[0]);
  const editDisabled = !selectedItem || selectedItem.bank_statement_name === "Unreconciled";

  const descriptionElementRef = useRef<HTMLElement>(null);

  const [openEdit, setOpenEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  // useEffect(() => {
  //   if ((!items || !items.length) && !loading && loginVerified) {
  //     dispatch(fetchItems({}));
  //   }
  // }, [dispatch]);

  // useEffect(() => {
  //   if (error && !loading) {
  //     toast.error(error);
  //   }
  // }, [error]);

  useEffect(() => {
    if (openEdit) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    
  }, [openEdit]);

  useEffect(() => {
    fetchItemOptions();
  }, [])

  useEffect(() => {
    if (!error && !loading) {
      setOpenEdit(false);
      dispatch(clearUpdateMethod());
      dispatch(selectItems([]));
    }
  }, [loading]);

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleClickOpenCreate = () => {
    dispatch(selectItems([]));
    setOpenEdit(true);
  }

  const handleCloseEdit = () => {
    if ((method === METHOD_TYPE.UPDATE || method === METHOD_TYPE.CREATE) && loading) return;
    setOpenEdit(false);
  };

  const handleSelectItem = (e: any) => {
    dispatch(selectItems(e));
  }

  const getSelectedItem = (sel: any[]) => {
    if (!sel || !sel.length) return;
    return items.find(item => item.id === sel[0]);
  }


  const handleSaveItem = (item: BankStatement, isUpdate: boolean) => {
    if (isUpdate) {
      dispatch(updateItem(item));
    } else {
      dispatch(createItem(item));
    }
  }

  const handleSelectAndEdit = (e: any) => {
    const unreconciledItem = items.find((item) => item.bank_statement_name === "Unreconciled");
    const selectedId = e.row.id;
    if (unreconciledItem?.id !== selectedId) {
      dispatch(selectItems([selectedId]));
      setOpenEdit(true);
    } else {
      console.log({ unreconciledItem, selectedId });
    }
  }

  const handleChangeFilterModel = (e: any) => {
    dispatch(setFilter(e));
  }

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <ClickAwayListener onClickAway={(e) => {
          const element = e.target as HTMLElement;
          if (element.tagName !== "BODY") {
            dispatch(selectItems([]));
          }
        }}>
          <Paper className="p-5 h-auto">
            <div className="flex justify-between flex-wrap mb-3">
              <h2 className="text-left text-blue mb-0">
                Bank Statements
                <InfoTooltip text="A detail of the statements your bank sends each month." />
              </h2>
              <div className={"flex items-center"}>
                <Button
                  variant="contained"
                  color="primary"
                  className="h-9 ml-3 mt-1"
                  onClick={handleClickOpenCreate}
                >Add
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className="h-9 ml-3 mt-1"
                  disabled={loading || editDisabled}
                  onClick={handleClickOpenEdit}
                >Edit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className="ml-3 mt-1"
                  disabled={items.length < 2 || loading}
                  onClick={() => {
                    setShowDelete(true);
                  }}
                >
                  Delete Last Entry
                </Button>
              </div>
            </div>
            <XGrid
              rows={items}
              columns={columns}
              headerHeight={35}
              rowHeight={35}
              pageSize={10}
              rowsPerPageOptions={[10, 25,  50, 100]}
              pagination
              loading={loading && method === METHOD_TYPE.LIST}
              components={{ Toolbar: TableToolbar }}
              filterModel={filterModel}
              selectionModel={selectionModel}
              onSelectionModelChange={handleSelectItem}
              onCellDoubleClick={handleSelectAndEdit}
              onFilterModelChange={handleChangeFilterModel}
            />
            <EditBankStatementDialog
              data={getSelectedItem(selectionModel)}
              open={openEdit}
              loading={loading}
              onClose={handleCloseEdit}
              onSave={handleSaveItem}
              onDelete={ () => { setShowDelete(true) } }
            />
            <ConfirmDelete
              open={showDelete}
              onClose={() => {
                setShowDelete(false);
                dispatch(selectItems([]));
              }}
            />
          </Paper>
        </ClickAwayListener>
      </Grid>
    </Grid>
  );
};
