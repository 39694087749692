import React, { useState, useRef, useMemo } from "react";
import { useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { userGetInventoryTypeSelector } from "src/store/system/selector";
import Uploader, { ImporterType } from "src/components/Uploader";

import DialogTitle from "./DialogTitle";
import type Step from "./Step";

interface BulkImportsDialogContentProps {
  onClose: () => void;
  DialogTitle: React.ComponentType;
}

export function BulkImportsDialogContent({
  onClose,
  DialogTitle,
}: BulkImportsDialogContentProps) {
  const isInventoryDisabled = useSelector(
    (s: any) => userGetInventoryTypeSelector(s) === "cash"
  );
  const handleClose = () => onClose();
  const [importerType, setImporterType] = useState<ImporterType>();

  return (
    <>
      <DialogTitle>File Type</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You can upload a csv file of inventory, sales (from any platform), or
          expenses here. Select the file type below and click “Next”.
        </DialogContentText>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>File Type</InputLabel>
          <Select
            value={importerType || ""}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setImporterType(event.target.value as ImporterType);
            }}
            label="File Type"
          >
            <MenuItem value="expenses">Expenses</MenuItem>
            <MenuItem value="sales">Sales</MenuItem>
            {isInventoryDisabled ? null : (
              <MenuItem value="inventory">Inventory</MenuItem>
            )}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Uploader
          importerType={importerType}
          onClose={() => {
            onClose();
          }}
        />
      </DialogActions>
    </>
  );
}

interface OtherUploadProps {
  onClose: () => void;
  onStepChange: (step: Step) => void;
}

function OtherUpload({ onClose, onStepChange }: OtherUploadProps) {
  const onCloseRef = useRef(onClose);
  onCloseRef.current = onClose;
  const dialogTitle = useMemo(() => {
    return (props) => (
      <DialogTitle onClose={() => onCloseRef.current()} {...props} />
    );
  }, []);

  return (
    <BulkImportsDialogContent
      onClose={() => {
        onStepChange("other:do-you-have-additional");
      }}
      DialogTitle={dialogTitle}
    />
  );
}

export default OtherUpload;
