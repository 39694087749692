import React, { PropsWithChildren } from "react";
import { makeStyles } from "@material-ui/core/styles";

import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import CloseIcon from "@material-ui/icons/Close";

export interface DialogTitleProps {
  onClose?: () => void;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

function DialogTitle({
  onClose,
  children,
}: PropsWithChildren<DialogTitleProps>) {
  const classes = useStyles();

  if (onClose) {
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6" component="p">
          {children}
        </Typography>
        {onClose ? (
          <Tooltip title="Save and finish later">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        ) : null}
      </MuiDialogTitle>
    );
  }

  return <MuiDialogTitle>{children}</MuiDialogTitle>;
}

export default DialogTitle;
