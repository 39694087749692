import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from 'clsx';

import {
  Box,
  Button,
  Grid,
  Paper,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import Plot from 'react-plotly.js';

import InfoTooltip from "../../components/InfoTooltip";
import { SALE_FILTER, SaleFilter } from "../../enums";
import { setTimeFilter } from "../../store/sale/actions";
import { updateUser } from "../../store/system/actions";
import { getSales, salesTimeFilterSelector, getTimeFilteredInventory, visibleSalesSelector, salesSelector } from "../../store/sale/selector";
import { formatDollars } from "src/utils/formatter";
import SvgGradientDef, { makeBarFill, makePieFill, GradientsByName } from "src/components/SvgGradientDef";
import { userGetInventoryTypeSelector } from "src/store/system/selector";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartContainer: {
      height: 450,
      padding: 15,
      position: 'relative',
    },
    formControl: {
      marginRight: 15,
      width: 250,
      position: 'absolute',
      top: 15,
      zIndex: 10,
      right: 1,
      backgroundColor: 'white'
    },
    plot: {
      '& g.cartesianlayer g.plot g.barlayer.mlayer g.trace.bars g.points g.point path': {
        clipPath: 'inset(0% 0% 0% 0% round 8px 8px 0px 0px)',
      },
    },
    departmentPlot: makeBarFill([GradientsByName.Green]),
    categoryPlot: makeBarFill([GradientsByName.Blue]),
    subCategoryPlot: makeBarFill([GradientsByName.LightBlue]),
    brandPlot: makeBarFill([GradientsByName.Grey]),
    piePlot: makePieFill([
      GradientsByName.Green,
      GradientsByName.LightGreen,
      GradientsByName.Blue,
      GradientsByName.LightBlue,
      GradientsByName.Grey,
      GradientsByName.LightGrey,
    ]),
    container: {
      marginTop: theme.spacing(-3),
      paddingTop: 0,
    },
    filter: {
      margin: theme.spacing(2, 2, 1, 0),
    },
    activeFilter: {
      backgroundColor: "rgb(4, 76, 38)",
      color: "#fff",
    },
    keyboardDatePicker: {
      "& input": {
        width: "6em",
      },
      margin: theme.spacing(2, 1, 1, 0),
    },
    inputRoot: {
      backgroundColor: '#e0e0e0',
    },
    inputInput: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    inputLabelRoot: {
      zIndex: 1100,
      transform: "translate(14px, 10px) scale(1) !important",
      // color: 'rgb(4, 76, 38) !important',
    },
    inputLabelShrink: {
      transform: "translate(14px, -16px) scale(0.75) !important",
      color: '#fff !important',
    },
  }),
);

function getPeriod(filter: SALE_FILTER) {
  switch (filter) {
    case SALE_FILTER.DAY: {
      const start = new Date();
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);
      start.setMilliseconds(0);

      const end = new Date();
      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);
      end.setMilliseconds(99);

      return {
        start,
        end,
      };
    };

    case SALE_FILTER.WEEK: {
      const start = new Date();
      start.setDate(start.getDate() - start.getDay());
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);
      start.setMilliseconds(0);

      const end = new Date(start);
      end.setDate(start.getDate() + 6);
      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);
      end.setMilliseconds(99);

      return {
        start,
        end,
      };
    };

    case SALE_FILTER.MONTH: {
      const start = new Date();
      start.setDate(1);
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);
      start.setMilliseconds(0);

      const end = new Date(start);
      end.setMonth(start.getMonth() + 1);
      end.setDate(0);
      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);
      end.setMilliseconds(99);

      return {
        start,
        end,
      };
    };


    case SALE_FILTER.ALL_TIME:
    case SALE_FILTER.YEAR:
    default: {
      const start = new Date();
      start.setMonth(0);
      start.setDate(1);
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);
      start.setMilliseconds(0);

      const end = new Date(start);
      end.setMonth(11);
      end.setDate(31);
      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);
      end.setMilliseconds(99);

      return {
        start,
        end,
      };
    };
  }
}

interface CustomDatePickersProps {
  saleFilter: SaleFilter;
  onTimeFilterChange: (f: SaleFilter) => void;
}

function CustomDatePickers({ saleFilter, onTimeFilterChange }: CustomDatePickersProps) {
  const classes = useStyles();
  const [period, setPeriod] = useState({
    start: saleFilter.period.start,
    end: saleFilter.period.end,
  });

  return (
   <MuiPickersUtilsProvider utils={DateFnsUtils}>
     <KeyboardDatePicker
       disableToolbar
       className={classes.keyboardDatePicker}
       InputProps={{
         classes: {
           root: classes.inputRoot,
           input: classes.inputInput,
         },
       }}
       InputLabelProps={{
         classes: {
           root: classes.inputLabelRoot,
           shrink: classes.inputLabelShrink,
         },
       }}
       inputVariant="outlined"
       variant="inline"
       format="MM/dd/yyyy"
       margin="dense"
       label="Start Date"
       autoOk={true}
       value={period.start || null}
       onChange={(e: any) => {
         setPeriod(p => ({ ...p, start: e }));
         if (e?.getTime && !isNaN(e.getTime())) {
           const start = new Date(e);
           start.setHours(0);
           start.setMinutes(0);
           start.setSeconds(0);
           start.setMilliseconds(0);

           onTimeFilterChange({
             filter: SALE_FILTER.CUSTOM,
             period: {
               ...period,
               start,
             },
           });
         }
       }}
       KeyboardButtonProps={{
         "aria-label": "Start date",
       }}
     />
     <KeyboardDatePicker
       disableToolbar
       className={classes.keyboardDatePicker}
       InputProps={{
         classes: {
           root: classes.inputRoot,
           input: classes.inputInput,
         },
       }}
       InputLabelProps={{
         classes: {
           root: classes.inputLabelRoot,
           shrink: classes.inputLabelShrink,
         },
       }}
       inputVariant="outlined"
       variant="inline"
       format="MM/dd/yyyy"
       margin="dense"
       label="End Date"
       autoOk={true}
       value={period.end || null}
       onChange={(e: any) => {
         setPeriod(p => ({ ...p, end: e }));
         if (e?.getTime && !isNaN(e.getTime())) {
           const end = new Date(e);
           end.setHours(23);
           end.setMinutes(59);
           end.setSeconds(59);
           end.setMilliseconds(999);
           onTimeFilterChange({
             filter: SALE_FILTER.CUSTOM,
             period: {
               ...period,
               end,
             },
           });
         }
       }}
       KeyboardButtonProps={{
         "aria-label": "End date",
       }}
     />
   </MuiPickersUtilsProvider>
  );
}

export function GraphContent() {
  const dispatch = useDispatch();
  const classes = useStyles();
const inventoryTypeIsCash =
    useSelector(userGetInventoryTypeSelector) === "cash";

  const {
    loading,
  } = useSelector(salesSelector);
  const visibleSales = useSelector(visibleSalesSelector);
  const sales = useSelector(getSales);
  const inventory = useSelector(getTimeFilteredInventory);
  const salesFilter = useSelector(salesTimeFilterSelector);
  const salesFilterType = (salesFilter as SaleFilter)?.filter || (salesFilter as SALE_FILTER);

  const getSalesStats = () => {
    const unreturnedSales = visibleSales.filter((sale) => !sale.return_id && !sale.original_id && !sale.is_return);
    const grossProfit = unreturnedSales.reduce((result, sale) => result + sale.gross_profit, 0);
    const totalSalePrice = unreturnedSales.reduce((result, sale) => result + sale.sale_price, 0);
    const inventoryLength = inventory.reduce((t, i) => {
      return t + ((i as any).quantity || 1);
    }, 0);
    return {
      grossProfit,
      averageSalePrice: totalSalePrice / unreturnedSales.length,
      sellThroughRate: !!inventory.length ? unreturnedSales.length / inventoryLength * 100 : "N/A",
    };
  };

  const {
    grossProfit: grossProfitForPeriod,
    averageSalePrice,
    sellThroughRate,
  } = getSalesStats();

  const displaySellThroughRate = sales.length === visibleSales.length ? sellThroughRate : "N/A";

  const fieldOptions = [
    { field: 'department', name: 'Department' },
    { field: 'category', name: 'Category' },
    { field: 'sub_category', name: 'Sub-Category' },
    { field: 'brand', name: 'Brand' },
  ]

  const [profitAndSalesData, setProfitAndSalesData] = useState<any[]>([]);
  const [fieldIndex, setFieldIndex] = useState(0);
  const [grossProfitData, setGrossProfitData] = useState<any[]>([]);
  const [grossProfitByPlatformData, setGrossProfitByPlatformData] = useState<any[]>([]);
  const [totalSalesData, setTotalSalesData] = useState<any[]>([]);

  const handleChangeFieldIndex = (e: any) => {
    setFieldIndex(e.target.value);
  }

  useEffect(() => {
    const rows = visibleSales.filter((e) => !e.return_id && !e.original_id);
    let d0_30 = 0, d31_60 = 0, d61_90 = 0, d91_120 = 0, d121_250 = 0, d251 = 0;
    let c0_30 = 0, c31_60 = 0, c61_90 = 0, c91_120 = 0, c121_250 = 0, c251 = 0;
    let s0_30 = 0, s31_60 = 0, s61_90 = 0, s91_120 = 0, s121_250 = 0, s251 = 0;
    let categories: any = {};
    let subCategories: any = {};
    let departments: any = {};
    let brands: any = {};

    rows.forEach(e => {
      const days = e.days_on_platform;
      const grossProfit = e.gross_profit;
      const salePrice = e.sale_price;
      if (days >= 0 && days <= 30) {
        d0_30 += grossProfit * 1.0;
        c0_30++;
        s0_30 += salePrice * 1.0;
      } else if (days > 30 && days <= 60) {
        d31_60 += grossProfit * 1.0;
        c31_60++;
        s31_60 += salePrice;
      } else if (days > 60 && days <= 90) {
        d61_90 += grossProfit * 1.0;
        c61_90++;
        s61_90 += salePrice * 1.0;
      } else if (days > 90 && days <= 120) {
        d91_120 += grossProfit * 1.0;
        c91_120++;
        s91_120 += salePrice * 1.0;
      } else if (days > 120 && days <= 250) {
        d121_250 += grossProfit * 1.0;
        c121_250++;
        s121_250 += salePrice * 1.0;
      } else if (days > 250) {
        d251 += grossProfit * 1.0;
        c251++;
        s251 += salePrice * 1.0;
      }

      const currentCategory = e.category || "Uncategorized";
      if (!categories[currentCategory]) {
        categories[currentCategory] = {
          count: 1,
          gross_profit: e.gross_profit
        };
      } else {
        categories[currentCategory].count++;
        categories[currentCategory].gross_profit += e.gross_profit * 1.0;
      }

      const currentSubCategory = e.sub_category || "Uncategorized";
      if (!subCategories[currentSubCategory]) {
        subCategories[currentSubCategory] = {
          count: 1,
          gross_profit: e.gross_profit
        };
      } else {
        subCategories[currentSubCategory].count++;
        subCategories[currentSubCategory].gross_profit += e.gross_profit * 1.0;
      }

      const currentDepartment = e.department || "Unknown";
      if (!departments[currentDepartment]) {
        departments[currentDepartment] = {
          count: 1,
          gross_profit: e.gross_profit
        };
      } else {
        departments[currentDepartment].count++;
        departments[currentDepartment].gross_profit += e.gross_profit * 1.0;
      }

      const currentBrand = e.brand || 'Unknown';
      if (!brands[currentBrand]) {
        brands[currentBrand] = {
          count: 1,
          gross_profit: e.gross_profit
        };
      } else {
        brands[currentBrand].count++;
        brands[currentBrand].gross_profit += e.gross_profit * 1.0;
      }
    });

    const x = ['0-30 days', '31-60 days', '61-90 days', '91-120 days', '121-250 days', '>250 days'];

    setTotalSalesData([
      {
        x,
        y: [c0_30, c31_60, c61_90, c91_120, c121_250, c251],
        name: 'Total Sales',
        type: 'scatter',
        mode: 'lines+markers',
        line: { shape: 'spline' },
        marker: { color: 'rgb(68, 112, 147)' },
        hovertemplate: 'Total Sales<br />%{x}: %{y}<extra></extra>',
      },
      {
        x,
        y: [s0_30 / (c0_30 || 1), s31_60 / (c31_60 || 1), s61_90 / (c61_90 || 1), s91_120 / (c91_120 || 1), s121_250 / (c121_250 || 1), s251 / (c251 || 1)],
        name: 'Average Sale Price',
        type: 'scatter',
        mode: 'lines+markers',
        line: { shape: 'spline' },
        marker: { color: 'rgb(4, 76, 38)' },
        hovertemplate: 'Average Sale Price<br />%{x}: %{y:,.2f}<extra></extra>',
        yaxis: 'y2',
      },
    ]);

    const pairs = [d0_30, d31_60, d61_90, d91_120, d121_250, d251]
      .map((value, i) => ({
        value,
        label: x[i],
      }))
      .filter(({ value }) => value >= 0);

    pairs.sort((a, b) => b.value - a.value);

    setGrossProfitData([{
      labels: pairs.map(p => p.label),
      values: pairs.map(p => p.value),
      type: 'pie',
      marker: {
        colors: [
          GradientsByName.Green.color,
          GradientsByName.LightGreen.color,
          GradientsByName.Blue.color,
          GradientsByName.LightBlue.color,
          GradientsByName.Grey.color,
          GradientsByName.LightGrey.color,
        ],
      },
      hole: 0.4,
      hovertemplate: "%{label}<br />%{value:$,.2f}<br />%{percent}<extra></extra>",
    }]);


    const brandsKeys = Object.keys(brands);
    brandsKeys.sort((a, b) => a.localeCompare(b));

    setProfitAndSalesData([
      [
        {
          x: Object.keys(departments),
          y: Object.keys(departments).map(e => departments[e].gross_profit),
          type: 'bar',
          marker: { color: GradientsByName.Green.color },
          name: 'Sum of Gross Profit',
          hovertemplate: 'Sum of Gross Profit<br />%{x}: %{y:$,.2f}<extra></extra>',
        },
        {
          x: Object.keys(departments),
          y: Object.keys(departments).map(e => departments[e].count),
          type: 'scatter',
          mode: 'lines+markers',
          line: { shape: 'spline' },
          marker: { color: 'rgb(215, 212, 219)' },
          hovertemplate: 'Count of Sales<br />%{x}: %{y}<extra></extra>',
          name: 'Count of Sales',
          yaxis: 'y2',
        },
      ],
      [
        {
          x: Object.keys(categories),
          y: Object.keys(categories).map(e => categories[e].gross_profit),
          type: 'bar',
          marker: { color: GradientsByName.Blue.color },
          name: 'Sum of Gross Profit',
          hovertemplate: 'Sum of Gross Profit<br />%{x}: %{y:$,.2f}<extra></extra>',
        },
        {
          x: Object.keys(categories),
          y: Object.keys(categories).map(e => categories[e].count),
          type: 'scatter',
          mode: 'lines',
          line: { shape: 'spline' },
          marker: { color: 'rgb(215, 212, 219)' },
          hovertemplate: 'Count of Sales<br />%{x}: %{y}<extra></extra>',
          name: 'Count of Sales',
          yaxis: 'y2',
        },
      ],
      [
        {
          x: Object.keys(subCategories),
          y: Object.keys(subCategories).map(e => subCategories[e].gross_profit),
          type: 'bar',
          marker: { color: GradientsByName.LightBlue.color },
          hovertemplate: 'Sum of Gross Profit<br />%{x}: %{y:$,.2f}<extra></extra>',
          name: 'Sum of Gross Profit',
        },
        {
          x: Object.keys(subCategories),
          y: Object.keys(subCategories).map(e => subCategories[e].count),
          type: 'scatter',
          mode: 'lines',
          line: { shape: 'spline' },
          marker: { color: 'rgb(215, 212, 219)' },
          hovertemplate: 'Count of Sales<br />%{x}: %{y}<extra></extra>',
          name: 'Count of Sales',
          yaxis: 'y2',
        },
      ],
      [
        {
          x: brandsKeys,
          y: brandsKeys.map(e => brands[e].gross_profit),
          type: 'bar',
          marker: { color: GradientsByName.Grey.color },
          name: 'Sum of Gross Profit',
          hovertemplate: 'Sum of Gross Profit<br />%{x}: %{y:$,.2f}<extra></extra>',
        },
        {
          x: brandsKeys,
          y: brandsKeys.map(e => brands[e].count),
          type: 'scatter',
          mode: 'lines+markers',
          line: { shape: 'spline' },
          marker: { color: 'rgb(215, 212, 219)' },
          hovertemplate: 'Count of Sales<br />%{x}: %{y}<extra></extra>',
          name: 'Count of Sales',
          yaxis: 'y2',
        },
      ],
    ]);

    let platforms = rows
      .map(e => e.sale_platform)
      .filter((e, i) => {
        const j = rows.findIndex(r => r.sale_platform === e)
        return i === j;
      });
    platforms.push("Others");

    let grossProfits = platforms.map(() => 0);
    let totalGrossProfits = 0;
    let otherProfits = 0;

    rows.forEach(e => {
      totalGrossProfits += e.gross_profit;
      let index = platforms.indexOf(e.sale_platform);
      if (index >= 0) {
        grossProfits[index] += e.gross_profit;
      } else {
        otherProfits += e.gross_profit;
      }
    });

    const otherIndices = new Set<number>([]);
    for (let i = 0; i < grossProfits.length; i++) {
      const value = grossProfits[i];
      if (value < totalGrossProfits / 20) {
        otherIndices.add(i);
        otherProfits += value;
      }
    }

    if (otherIndices.size) {
      grossProfits = grossProfits.filter((value, index) => !otherIndices.has(index));
      platforms = platforms.filter((value, index) => !otherIndices.has(index));
    }

    if (otherProfits) {
      platforms.push("Others");
      grossProfits.push(otherProfits);
    }

    const platformPairs = platforms
      .map((label, i) => ({
        label,
        value: grossProfits[i],
      }))
      .filter(({ value }) => value >= 0);
    platformPairs.sort((a, b) => b.value - a.value);

    setGrossProfitByPlatformData([{
      values: platformPairs.map(p => p.value),
      labels: platformPairs.map(p => p.label),
      type: 'pie',
      marker: {
        colors: [
          GradientsByName.Green.color,
          GradientsByName.LightGreen.color,
          GradientsByName.Blue.color,
          GradientsByName.LightBlue.color,
          GradientsByName.Grey.color,
          GradientsByName.LightGrey.color,
        ],
      },
      hole: 0.4,
      hovertemplate: "%{label}<br />%{value:$,.2f}<br />%{percent}<extra></extra>",
    }]);

  }, [visibleSales]);

  const updateTimeFilter = (newTimeFilter: SALE_FILTER | SaleFilter) => {
    if (newTimeFilter === SALE_FILTER.CUSTOM) {
      newTimeFilter = {
        filter: SALE_FILTER.CUSTOM,
        period: getPeriod(salesFilterType),
      };
    }
    dispatch(setTimeFilter(newTimeFilter));
    dispatch(updateUser({ sales_filter: newTimeFilter }));
  }

  return (
    <Grid container spacing={2} className={classes.container}>
      <SvgGradientDef />
      <Grid item xs={12}>
        <Box display="flex" mb={1} flexWrap="wrap">
          <Button
            onClick={() => updateTimeFilter(SALE_FILTER.DAY)}
            className={clsx(classes.filter, salesFilterType === SALE_FILTER.DAY && classes.activeFilter)}
            variant="contained"
          >Current day
          </Button>
          <Button
            onClick={() => updateTimeFilter(SALE_FILTER.WEEK)}
            className={clsx(classes.filter, salesFilterType === SALE_FILTER.WEEK && classes.activeFilter)}
            variant="contained"
          >Current week
          </Button>
          <Button
            onClick={() => updateTimeFilter(SALE_FILTER.MONTH)}
            className={clsx(classes.filter, salesFilterType === SALE_FILTER.MONTH && classes.activeFilter)}
            variant="contained"
          >Current month
          </Button>
          <Button
            onClick={() => updateTimeFilter(SALE_FILTER.YEAR)}
            className={clsx(classes.filter, salesFilterType === SALE_FILTER.YEAR && classes.activeFilter)}
            variant="contained"
          >Current year
          </Button>
          <Button
            onClick={() => updateTimeFilter(SALE_FILTER.ALL_TIME)}
            className={clsx(classes.filter, salesFilterType === SALE_FILTER.ALL_TIME && classes.activeFilter)}
            variant="contained"
          >All time
          </Button>
          <Button
            onClick={() => updateTimeFilter(SALE_FILTER.CUSTOM)}
            className={clsx(classes.filter, salesFilterType === SALE_FILTER.CUSTOM && classes.activeFilter)}
            variant="contained"
          >Custom
          </Button>
          {(salesFilter as SaleFilter)?.filter === SALE_FILTER.CUSTOM && (
            <CustomDatePickers saleFilter={salesFilter as SaleFilter} onTimeFilterChange={updateTimeFilter} />
          )}
        </Box>
        <Box className="mt-2 mb-4 flex">
          <Paper className="p-5 h-20 mr-3 w-52 text-center">
            <Typography>Gross Profit</Typography>
            <Box className="flex items-center justify-center">
              <Typography>{formatDollars(grossProfitForPeriod)}</Typography>
              <InfoTooltip
                size="small"
                text="The gross profit includes the gross profit of each sale displayed in the “Sales Detail” except for sales that have a refund attached to them."
              />
            </Box>
          </Paper>
          <Paper className="p-5 h-20 mr-3 w-52 text-center">
            <Typography>Average Sale Price</Typography>
            <Box className="flex items-center justify-center">
              <Typography>{formatDollars(averageSalePrice)}</Typography>
              <InfoTooltip
                size="small"
                text="The average sale price is the total of sale prices/number of sales displayed in the “Sales Detail” that do not have a refund attached to them."
              />
            </Box>
          </Paper>
          { inventoryTypeIsCash ? null : (
            <Paper className="p-5 h-20 w-52 text-center">
              <Typography>Sell Through Rate</Typography>
              <Box className="flex items-center justify-center">
                <Typography>{displaySellThroughRate === "N/A" ? displaySellThroughRate : `${(+displaySellThroughRate).toFixed(2)}%`}</Typography>
                <InfoTooltip
                  size="small"
                  color={visibleSales.length !== sales.length ? "error" : undefined}
                  text={visibleSales.length === sales.length ? "Sell Through Rate is a key performance indicator (KPI) for inventory-based businesses. It tells you how much inventory you moved during a period compared to how much you purchased. This is calculated as # of items sold/# of items purchased during a period of time." : 
                    "Sell Through Rate cannot be calculated accurately when filtering sales."}
                />
              </Box>
            </Paper>
          )}
        </Box>
        <Paper className={classes.chartContainer}>
          <div className="flex justify-between absolute z-10 w-full">
            <h2 className="w-full text-left text-blue">
              Profit and Sales Detail
              <InfoTooltip text="This chart shows two pieces of information. The green bar shows the sum of the gross profit. The white line shows the number of sales. Each of these is divided based on ‘Department’, and you can see more detail by changing the dropdown to ‘Category’ or ‘Sub-Category’." />
            </h2>
          </div>
          <FormControl className={classes.formControl}>
            <Select
              id="field-selector"
              value={fieldIndex}
              onChange={handleChangeFieldIndex}
              variant="outlined" margin="dense"
            >
              {fieldOptions.map((e, i) =>
                <MenuItem value={i} key={i}>{e.name}</MenuItem>
              )}
            </Select>
          </FormControl>
          {loading ? <div className="w-full flex items-center justify-center h-full">
            <CircularProgress />
          </div> :
            <Plot
              className={clsx(
                classes.plot,
                fieldIndex === 0 && classes.departmentPlot,
                fieldIndex === 1 && classes.categoryPlot,
                fieldIndex === 2 && classes.subCategoryPlot,
                fieldIndex === 3 && classes.brandPlot,
              )}
              data={profitAndSalesData[fieldIndex]}
              config={{
                displaylogo: false,
                responsive: true,
              }}
              layout={{
                autosize: true,
                legend: {
                  orientation: 'h',
                  xanchor: 'center',
                  x: 0.5,
                  y: 1.1,
                },
                yaxis: {
                  tickprefix: "$"
                },
                yaxis2: {
                  overlaying: 'y',
                  side: 'right'
                }
              }}
              style={{ width: '100%', height: '100%' }}
            />}
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper className={classes.chartContainer}>
          <h2 className="w-full text-left text-blue absolute z-10">
            Gross Profit By Days Listed
            <InfoTooltip text="This chart divides your gross profit by the number of days an item sits before selling. This allows you to see how fast your inventory is selling." />
          </h2>
          {loading ? <div className="w-full flex items-center justify-center h-full">
            <CircularProgress />
          </div> :
            <Plot
              className={classes.piePlot}
              data={grossProfitData}
              config={{
                displaylogo: false,
                responsive: true,
              }}
              layout={{
                autosize: true,
                yaxis: {
                  tickprefix: "$"
                }
              }}
              style={{ width: '100%', height: '100%' }}
            />}
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper className={classes.chartContainer}>
          <h2 className="w-full text-left text-blue absolute z-10">
            Gross Profit by Platform
            <InfoTooltip text="This chart divides your gross profit by sales platform, so you can see where you make the most profit." />
          </h2>
          {loading ? <div className="w-full flex items-center justify-center h-full">
            <CircularProgress />
          </div> :
            <Plot
              className={classes.piePlot}
              data={grossProfitByPlatformData}
              config={{
                displaylogo: false,
                responsive: true,
              }}
              layout={{
                autosize: true,
                yaxis: {
                  tickprefix: "$"
                }
              }}
              style={{ width: '100%', height: '100%' }}
            />}
        </Paper>
      </Grid>
      <Grid item xs={12} md={12}>
        <Paper className={classes.chartContainer}>
          <h2 className="w-full text-left text-blue absolute z-10">
            Total Sales By Days Listed
            <InfoTooltip text="This chart shows two pieces of information. This green line shows the average sales price. The blue line shows the number of sales. Both are divided based on the number of days the item was listed before selling." />
          </h2>
          <Plot
            data={totalSalesData}
            config={{
              displaylogo: false,
              responsive: true,
            }}
            layout={{
              autosize: true,
              legend: {
                orientation: 'h',
                xanchor: 'center',
                x: 0.5,
                yanchor: 'top',
                y: -0.1,
              },
              yaxis2: {
                tickprefix: "$",
                overlaying: 'y',
                side: 'right'
              }
            }}
            style={{ width: '100%', height: '100%' }}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
