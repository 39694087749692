import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Grid,
  Paper,
  ClickAwayListener,
} from "@material-ui/core";

import {
  LicenseInfo,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  useGridApiRef,
} from '@material-ui/x-grid';
import XGrid from "src/components/CSVFormattedXGrid";


import { makeStyles } from '@material-ui/core/styles';

import EditDialog from "./EditDialog";

import ConfirmDialog from "src/components/ConfirmDialog";
import InfoTooltip from "../../components/InfoTooltip";

import { clearUpdateMethod } from "../../store/common";
import {
  setFilter,
  setVisible,
  selectItems,
} from "../../store/numericOverview/actions";
import { numericOverviewSelector } from "../../store/numericOverview/selector";

import { NumericOverviewFormState } from "../../interfaces";
import { METHOD_TYPE } from "../../enums";

import { formatCurrency, formatDate } from "../../utils"

import { X_GRID_LICENSE_KEY } from "../../config";
import {clearNumericOverviews} from "../../apiService";
import { manualRefreshNumericOverviews } from "src/apiService/modules/numericOverview";

import { StatefulLoadableButton } from "src/components/LoadableButton";
import { updateNumericOverview } from "src/apiService/modules/numericOverview";

LicenseInfo.setLicenseKey(X_GRID_LICENSE_KEY);

const tableColumnInfo = {
  inventory_balance: "The total dollar value of your inventory at the end of the month.",
  sales: "The total dollar value of your sales for the month.",
  COGS: "COGS stands for ‘cost of goods sold’. This is the sum of purchase price, transaction fees, and shipping costs for all items sold, as well as the total ‘Other COGS’ for the month.",
  other_COGS: "The sum of expenses entered in the ‘Expense Detail’ which are directly associated with the sales of your products (ex. Shipping Supplies, Sourcing Mileage/Transportation).",
  gross_profit: "Your gross profit = sales - cost of goods sold. Use this number to assess the profitability of your sales process (from the time you purchase inventory to the time you ship it).",
  other_business_costs: "This shows the total of business expenses recorded in the ‘Expense Detail’ that aren’t directly associated with the sales of your products (ex. Office Supplies, Cell Phone).",
  net_profit: "Your net profit = gross profit – other business costs – estimated income tax. This tells you how much you made after all expenses and taxes are subtracted.",
  bank_cash_balance: "The sum of “Business Activity” from cash activities recorded for the current and prior months. This shows the amount of money your business has in the bank.",
  cash_in_reselling_app_accounts: "The total amount of money in your reselling accounts that hasn’t been deposited into your bank. This is a number that you can enter manually.",
  cash_balance: "The sum of ‘Bank Cash Balance’ and ‘Cash In Reselling App Accounts’.",
  estimated_income_tax_liability: "The estimated amount of income tax owed for the current month. This is based on the income tax rate set in your profile settings.",
  estimated_income_tax_liability_paid: "These are income tax payments, they reduce your ‘Estimated Income Tax Liability Balance’. This is a number that you can manually enter.",
  estimated_income_tax_liability_balance: "This is a balance calculated from the sum of current and prior ‘Estimated Income Tax Liability’ and the sum of current and prior ‘Income Tax Payments’.",
  sales_tax: "This is the total sales tax for the month where you recorded that you were ‘Liable to Pay’.",
  sales_tax_liability_balance: "This is a balance calculated from the sum of current and prior ‘Sales Tax Liability’ and the sum of current and prior ‘Sales Tax Payments’.",
  sales_tax_payments: "These are sales tax payments, they reduce your ‘Sales Tax Liability Balance’. This is a number that you can manually enter.",
}

const renderHeaderLabel = (params) => {
  const tooltip = tableColumnInfo[params.field];
  return (
    <div className="MuiDataGrid-columnHeaderTitle">
      {params.colDef.headerName}
      {!!tooltip && <InfoTooltip size="small" text={tooltip} />}
    </div>
  );
}

export const columns = [
  { field: 'period', headerName: 'Period', type: 'date', width: 120, valueFormatter: (e => formatDate(e.value, 'M/YYYY')) },
  { field: 'inventory_balance', headerName: 'Inventory Balance', type: 'number', width: 220, valueFormatter: formatCurrency, renderHeader: renderHeaderLabel },
  { field: 'sales', headerName: 'Sales', type: 'number', width: 150, valueFormatter: formatCurrency, renderHeader: renderHeaderLabel },
  { field: 'COGS', headerName: 'COGS', type: 'number', width: 150, valueFormatter: formatCurrency, renderHeader: renderHeaderLabel },
  { field: 'other_COGS', headerName: 'Other COGS', type: 'number', width: 185, valueFormatter: formatCurrency, renderHeader: renderHeaderLabel },
  { field: 'gross_profit', headerName: 'Gross Profit', type: 'number', width: 195, valueFormatter: formatCurrency, renderHeader: renderHeaderLabel },
  { field: 'other_business_costs', headerName: 'Other Business Costs', type: 'number', width: 240, valueFormatter: formatCurrency, renderHeader: renderHeaderLabel },
  { field: 'net_profit', headerName: 'Net Profit', type: 'number', width: 225, valueFormatter: formatCurrency, renderHeader: renderHeaderLabel },
  { field: 'bank_cash_balance', headerName: 'Bank Cash Balance', type: 'number', width: 225, valueFormatter: formatCurrency, renderHeader: renderHeaderLabel },
  { field: 'cash_in_reselling_app_accounts', headerName: 'Cash In Reselling App Accounts', type: 'number', width: 305, valueFormatter: formatCurrency, renderHeader: renderHeaderLabel },
  { field: 'cash_balance', headerName: 'Cash Balance', type: 'number', width: 195, valueFormatter: formatCurrency, renderHeader: renderHeaderLabel },
  { field: 'estimated_income_tax_liability', headerName: 'Estimated Income Tax Liability', type: 'number', width: 305, valueFormatter: formatCurrency, renderHeader: renderHeaderLabel },
  { field: 'estimated_income_tax_liability_paid', headerName: 'Income Tax Payment', type: 'number', width: 230, valueFormatter: formatCurrency, renderHeader: renderHeaderLabel },
  { field: 'estimated_income_tax_liability_balance', headerName: 'Estimated Income Tax Liability Balance', type: 'number', width: 345, valueFormatter: formatCurrency, renderHeader: renderHeaderLabel },
  { field: 'sales_tax', headerName: 'Sales Tax', type: 'number', width: 175, valueFormatter: formatCurrency, renderHeader: renderHeaderLabel },
  { field: 'sales_tax_liability_balance', headerName: 'Sales Tax Liability Balance', type: 'number', width: 275, valueFormatter: formatCurrency, renderHeader: renderHeaderLabel },
  { field: 'sales_tax_payments', headerName: 'Sales Tax Payments', type: 'number', width: 230, valueFormatter: formatCurrency, renderHeader: renderHeaderLabel },
];

const useStyles = makeStyles({
  root: {
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
      borderRight: `1px solid #f0f0f0`,
      padding: '0px 10px',
      fontSize: '12px',
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      borderBottom: `1px solid #f0f0f0`
    },
    '& .MuiDataGrid-cell': {
      color: `rgba(0,0,0,.85)`,
    },
    '& .MuiDataGrid-iconSeparator': {
      opacity: 0,
    },
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: '#fafafa',
      borderTop: '1px solid #f0f0f0',
      fontSize: '12px',
    },
    '& .MuiDataGrid-colCell': {
      borderRight: `1px solid #f0f0f0`,
      padding: '0px 10px',
    }
  },
});

const financialsReportText = "Shows the information from the charts above in a table. This information is calculated for the last 13 months.";

const CustomToolbar = ()  => (
  <GridToolbarContainer>
    <GridToolbarFilterButton />
    <GridToolbarColumnsButton />
    <GridToolbarDensitySelector />
    <GridToolbarExport />
  </GridToolbarContainer>
);

export const TableContent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    items: initialItems,
    method,
    loading,
    error,
    filter: filterModel,
    selected: selectionModel,
  } = useSelector(numericOverviewSelector);

  const [editId, setEditId] = useState<string | undefined>(undefined);
  const [showDelete, setShowDelete] = useState(false);
  const [items, setItems] = useState(Array.from(initialItems).sort(
    (a, b) => {
      const legend = [-1, 0, 1];
      return legend[+(a?.period < b?.period)];
    }
  ));
  const gridRef = useGridApiRef();

  // useEffect(() => {
  //   if ((!items || !items.length) && !loading && loginVerified) {
  //     dispatch(fetchItems({}));
  //   }
  // }, [dispatch, loginVerified]);

  useEffect(() => {
    const itemsToSet = Array.from(initialItems || []).sort(
      (a, b) => {
        const legend = [-1, 0, 1];
        return legend[+(a?.period < b?.period)];
      }
    );
    setItems(itemsToSet);
  }, [initialItems]);

  // useEffect(() => {
  //   if (error && !loading && method == METHOD_TYPE.UPDATE) {
  //     toast.error(error);
  //   }
  // }, [error]);

  useEffect(() => {
    const visibleItems = Array.from(gridRef.current.getVisibleRowModels().keys());
    dispatch(setVisible(visibleItems));
  }, [filterModel]);

  useEffect(() => {
    if (!error && !loading && (method === METHOD_TYPE.UPDATE || method === METHOD_TYPE.UPLOAD)) {
      setEditId(undefined);
      // dispatch(fetchItems({}));
      if (method === METHOD_TYPE.UPDATE) {
        dispatch(clearUpdateMethod());
      }
    }
  }, [loading]);

  const handleClickOpenEdit = () => {
    if (selectionModel[0]) setEditId(`${selectionModel[0]}`);
  };

  const handleClickManualRefresh = async () => {
    await manualRefreshNumericOverviews();
  };

  const handleClearNOs = async () => {
    let totalOverviewsDeleted = await clearNumericOverviews();
    console.log(totalOverviewsDeleted + " numerical overviews deleted.");
  }

  const handleCloseEdit = () => {
    if ((method === METHOD_TYPE.UPDATE || method === METHOD_TYPE.DELETE) && loading) return;
    setEditId(undefined);
  };

  const handleSelectItem = (e: any) => {
    dispatch(selectItems(e));
  }

  const handleSaveItem = async (item: NumericOverviewFormState) => {
    if (item.id)
      await updateNumericOverview(item.id, item);
  }

  const handleSelectAndEdit = (e: any) => {
    dispatch(selectItems([e.row.id]));
    setEditId(e.row.id);
  }

  const handleChangeFilterModel = (e: any) => {
    dispatch(setFilter(e));
  }

  const handleDeleteItem = () => {
    setShowDelete(false);
    if (selectionModel && selectionModel[0]) {
      // dispatch(deleteSale(selectionModel[0]));
      setEditId(undefined);
      dispatch(selectItems([]));
    }
  }

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <ClickAwayListener onClickAway={(e) => {
          const element = e.target as HTMLElement;
          if (element.tagName !== "BODY") {
            dispatch(selectItems([]));
          }
        }}>
          <Paper className="p-5 h-auto">
            <div className="flex justify-between flex-wrap mb-3">
              <h2 className="text-left text-blue mb-2">
                Financials Report
                <InfoTooltip text={financialsReportText} />
              </h2>
              <div className={"flex items-center"}>
                <Button
                  color="primary"
                  variant="contained"
                  className="mr-3"
                  disabled={loading || !selectionModel || !selectionModel[0] || selectionModel?.length > 1}
                  onClick={handleClickOpenEdit}
                >
                  Edit
                </Button>
                <StatefulLoadableButton
                  color="primary"
                  variant="contained"
                  className="mr-3"
                  disabled={loading}
                  onClick={handleClickManualRefresh}
                >
                  Manual Refresh
                </StatefulLoadableButton>
                <Button
                    color="secondary"
                    variant="contained"
                    className="mr-3"
                    onClick={handleClearNOs}
                >
                  Delete Periods
                </Button>
                {/*<Button color="primary" variant="contained" onClick={handleClickOpenSetTaxRates}>
                  Set Estimated Income Tax Rate
                </Button>*/}
              </div>
            </div>
            <XGrid
              className="h-table"
              apiRef={gridRef}
              rows={items}
              columns={columns}
              headerHeight={35}
              rowHeight={35}
              components={{
                Toolbar: CustomToolbar,
              }}
              // pageSize={10}
              // rowsPerPageOptions={[10, 25, 25, 50, 100]}
              // pagination
              filterModel={filterModel}
              selectionModel={selectionModel}
              onSelectionModelChange={handleSelectItem}
              onCellDoubleClick={handleSelectAndEdit}
              onFilterModelChange={handleChangeFilterModel}
              loading={loading && method === METHOD_TYPE.LIST}
            />
            <EditDialog
              id={editId}
              onClose={handleCloseEdit}
            />
            <ConfirmDialog
              open={showDelete}
              title="Confirm"
              text="Are you sure you want to delete the sale?"
              onCancel={() => setShowDelete(false)}
              onConfirm={handleDeleteItem}
            />
          </Paper>
        </ClickAwayListener>
      </Grid>
    </Grid>
  );
};
