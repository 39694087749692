import React, { Dispatch, SetStateAction, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Button, TextField } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/OutlinedInput";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from "@material-ui/pickers";

import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles, Theme } from "@material-ui/core/styles";

import { Transaction, TransactionFormError } from "../../interfaces";
import {
  transactionSelector,
  allIntegrationNamesSelector,
} from "../../store/transaction/selector";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { setActiveDialog } from "../../store/adminHtml/actions";
import { generalLedgerAccountsSelector } from "src/store/glAccounts/selector";
import { getMileage, getRate } from "./mileage";

import NumberField from "src/components/NumberField";
import VendorField from "src/components/VendorField";
import Uploader from "src/components/Uploader";
import LoadableButton from "src/components/LoadableButton";
import { addTransaction } from "src/apiService/modules/transactions";
import InfoTooltip from "src/components/InfoTooltip";
import GoldIconButton from "src/components/GoldIconButton";

import WalkThrough from "./EditDialogWalkThrough";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  title: {
    position: "relative",
  },
  options: {
    position: "absolute",
    top: 0,
    right: 24,
    bottom: 0,
    display: "flex",
    alignItems: "center",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "34px 3fr",
    alignItems: "center",
    columnGap: theme.spacing(1),
    rowGap: theme.spacing(0.5),
  },
}));

const Dash = "\u2014";

export interface EditDialogContentProps {
  data: Transaction | undefined;
  loading: boolean;
  onSave: (transaction: Transaction, next?: boolean) => void;
  onDelete: Function;
}

export interface EditDialogProps {
  data?: Transaction | undefined;
  open: boolean;
  onClose: () => void;
  onDelete: Function;
  loading?: boolean;
}

export type EditDialogContentWrapperProps = Omit<EditDialogProps, "open">;

const initFormValues: Partial<Transaction> = {
  id: "",
  account: "",
  description: "",
  integration_name: "",
};

interface FormProps {
  errors: TransactionFormError;
  values: Partial<Transaction>;
  loading: boolean;
  handleChange: (
    prop: keyof Transaction,
    numeric?: true
  ) => (event: { target: { value?: any } }) => void;
  setValues: Dispatch<SetStateAction<Partial<Transaction>>>;
}

function isValidDate(d: any) {
  if (Object.prototype.toString.call(d) === "[object Date]") {
    // it is a date
    if (isNaN(d.getTime())) {
      // d.valueOf() could also work
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function DateInput({
  value,
  onChange,
  loading,
  ...props
}: {
  value?: Date;
  loading: boolean;
  onChange: (e: React.ChangeEvent<any>, t?: any) => void;
} & Partial<Omit<KeyboardDatePickerProps, "onChange">>) {
  return (
    <KeyboardDatePicker
      disableToolbar
      className="w-full"
      variant="inline"
      format="MM/dd/yyyy"
      margin="dense"
      label="Date"
      required
      autoOk={true}
      value={value || null}
      disabled={loading}
      onChange={(e: any, t: any) => {
        onChange({
          target: { value: new Date(e) },
        } as unknown as React.ChangeEvent<Date>);
      }}
      KeyboardButtonProps={{
        "aria-label": "change date",
      }}
      inputVariant="outlined"
      {...props}
    />
  );
}

function getMileageDescription(description?: string): string {
  if (!description) return "";
  const re = new RegExp(`(.*?) ${Dash} (\\d+(\\.\\d*)?) miles`);
  const match = description.match(re);
  if (match) return match[1];
  return description;
}

function MileageForm({
  errors,
  values,
  loading,
  handleChange,
  setValues,
}: FormProps) {
  const description = getMileageDescription(values.description);
  const [mileage, setMileage] = useState(
    getMileage(values.date, values.amount)
  );
  const integrationNames = useSelector(allIntegrationNamesSelector);
  const handleDescriptionChange = (description: string, mileage: number) => {
    setValues((v) => ({
      ...v,
      description: `${description} ${Dash} ${mileage} miles`,
    }));
  };
  const handleAmountChange = (mileage: number, date?: Date) => {
    if (!date) return;
    const rate = getRate(date);
    if (!rate) return;

    setValues((v) => ({
      ...v,
      amount: rate * mileage,
    }));
  };

  return (
    <>
      <div />
      <FormControl fullWidth variant="outlined" margin="dense">
        <InputLabel variant="outlined">Drove</InputLabel>
        <NumberField
          Component={Input}
          fullWidth
          disabled={loading}
          value={mileage || ""}
          onChange={(e) => {
            const value = e.target.value
              ? parseFloat(`${e.target.value}`) || 0
              : 0;
            setMileage(value);
            handleDescriptionChange(description, e.target.value || 0);
            handleAmountChange(value, values.date);
          }}
          endAdornment={<InputAdornment position="end">miles</InputAdornment>}
          type="number"
          label="Drove"
          fixed={2}
        />
      </FormControl>
      <div />
      <DateInput
        error={!!errors.date}
        helperText={errors.date}
        value={values.date}
        loading={loading}
        onChange={(e) => {
          const date = e.target.value;
          setValues({ ...values, date });
          handleAmountChange(mileage, date);
        }}
      />
      <InfoTooltip text="A note to remind you what was purchased." size={18} />
      <TextField
        error={errors.description ? true : false}
        helperText={errors.description}
        fullWidth
        variant="outlined"
        margin="dense"
        label="Description"
        value={description}
        disabled={loading}
        onChange={(e) => {
          handleDescriptionChange(e.target.value, mileage);
        }}
      />
      <InfoTooltip
        text="The card or account used to make the purchase."
        size={18}
      />
      <Autocomplete
        fullWidth
        freeSolo
        autoSelect
        disabled={loading}
        value={values.integration_name || ""}
        onChange={(ev, value) => {
          setValues((v) => {
            if (value) return { ...v, integration_name: value };
            const { integration_name, ...rest } = v;
            return rest;
          });
        }}
        options={integrationNames || []}
        renderInput={(params) => (
          <TextField
            {...params}
            disabled={loading}
            error={!!errors.integration_name}
            helperText={errors.integration_name}
            margin="dense"
            variant="outlined"
            label="Bank Account"
            fullWidth
          />
        )}
      />
    </>
  );
}

function DefaultForm({
  errors,
  values,
  loading,
  handleChange,
  setValues,
}: FormProps) {
  const integrationNames = useSelector(allIntegrationNamesSelector);
  return (
    <>
      <InfoTooltip text="A note to remind you what was purchased." size={18} />
      <TextField
        error={errors.description ? true : false}
        helperText={errors.description}
        fullWidth
        variant="outlined"
        margin="dense"
        label="Description"
        value={values.description}
        disabled={loading}
        onChange={handleChange("description")}
      />
      <div />
      <NumberField
        error={errors.amount ? true : false}
        helperText={errors.amount}
        fullWidth
        variant="outlined"
        margin="dense"
        label="Amount"
        value={values.amount}
        disabled={loading}
        onChange={handleChange("amount")}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        allowNegative
        fixed={2}
      />
      <div />
      <DateInput
        value={values.date}
        onChange={handleChange("date")}
        loading={loading}
        error={!!errors.date}
        helperText={errors.date}
      />
      <InfoTooltip
        text="The card or account used to make the purchase."
        size={18}
      />
      <Autocomplete
        fullWidth
        freeSolo
        autoSelect
        disabled={loading}
        value={values.integration_name || ""}
        onChange={(ev, value) => {
          setValues((v) => {
            if (value) return { ...v, integration_name: value };
            const { integration_name, ...rest } = v;
            return rest;
          });
        }}
        options={integrationNames || []}
        renderInput={(params) => (
          <TextField
            {...params}
            disabled={loading}
            error={!!errors.integration_name}
            helperText={errors.integration_name}
            margin="dense"
            variant="outlined"
            label="Bank Account"
            fullWidth
          />
        )}
      />
    </>
  );
}

export function EditDialogContent({
  data,
  loading,
  onSave,
  onDelete,
}: EditDialogContentProps) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loadingAccounts } = useSelector(transactionSelector);
  const generalLedgerAccounts = useSelector(generalLedgerAccountsSelector);

  const [values, setValues] = React.useState<Partial<Transaction>>(
    data || initFormValues
  );
  const isUpdate = !!values.id;
  const [errors, setErrors] = React.useState<TransactionFormError>({});
  const isMileage =
    values.account === "Non-Sourcing Mileage/Transportation" ||
    values.account === "Sourcing Mileage/Transportation";

  const handleDelete = () => {
    onDelete();
  };

  const handleSave = (event, next?) => {
    if (event?.preventDefault) event.preventDefault();

    if (validateForm(values)) {
      onSave(values, !!next);
    } else {
      toast.error("Some required information is missing or incomplete");
    }
  };

  const handleCopy = () => {
    setValues((v) => ({
      ...v,
      id: "",
    }));
  };

  const handleChange =
    (prop: keyof Transaction) => (event: { target: { value?: any } }) => {
      const value = event.target.value;
      setValues((values) => ({ ...values, [prop]: value }));
    };

  const validateForm = (
    values: Partial<Transaction>
  ): values is Transaction => {
    let hasError = false;
    /* rules */
    let newErrors: TransactionFormError = { ...errors };
    if (values.account === undefined || values.account.trim() === "") {
      hasError = true;
      newErrors.account = "Required field!";
    } else {
      newErrors.account = undefined;
    }

    if (!values.amount) {
      hasError = true;
      newErrors.amount = "Required field!";
    } else {
      newErrors.amount = undefined;
    }

    if (!isValidDate(values.date)) {
      hasError = true;
      newErrors.date = "Invalid date format";
    } else {
      if (isMileage && !getRate(values.date)) {
        hasError = true;
        newErrors.date =
          "We don't have the rate for the date you specified. Check back in later or send us a message to support@myresellergenie.com";
      } else {
        newErrors.date = undefined;
      }
    }

    setErrors(newErrors);
    return !hasError;
  };

  const renderOption = (option, { inputValue }) => {
    const matches = match(option, inputValue);
    const parts = parse(option, matches);
    return (
      <div>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    );
  };

  const setupLedgerAccounts = () => {
    const newAccounts = [
      {
        "General Ledger Account": "Add New Account",
        Type: "new",
      },
      ...generalLedgerAccounts,
    ];

    return (newAccounts || [])
      .map((e) => {
        if (e["General Ledger Account"]) {
          return e["General Ledger Account"];
        } else {
          return e.name;
        }
      })
      .filter((e) => !!e)
      .sort();
  };

  const Form = isMileage ? MileageForm : DefaultForm;

  return (
    <>
      <WalkThrough
        open={open}
        onClose={() => setOpen(false)}
        isUpdate={isUpdate}
      />
      <form noValidate autoComplete="off" onSubmit={handleSave}>
        <DialogTitle className={classes.title}>
          {isUpdate ? "Edit" : "Add"} Expense
          <span className={classes.options}>
            {isMileage && (
              <Tooltip title="The mileage entry form uses current US standard mileage rates provided by the IRS to calculate mileage expense.">
                <InfoOutlinedIcon />
              </Tooltip>
            )}
            {isUpdate && (
              <IconButton
                onClick={handleDelete}
                disabled={loading}
                color="secondary"
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            )}
          </span>
        </DialogTitle>
        <DialogContent>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={classes.grid}>
              <InfoTooltip
                text="The category of the transaction."
                size={18}
                id="transaction-edit-dialog-general-ledger-account-tooltip"
              />
              <Autocomplete
                fullWidth
                disabled={loadingAccounts || loading}
                options={setupLedgerAccounts()}
                value={values.account || null}
                onChange={(_e, value) => {
                  if (value === "Add New Account") {
                    return dispatch(setActiveDialog("add_gl_account"));
                  }
                  setValues({ ...values, account: value || "" });
                }}
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={errors.account ? true : false}
                    helperText={errors.account}
                    margin="dense"
                    required
                    label="General Ledger Account"
                    fullWidth
                    variant="outlined"
                    autoFocus
                  />
                )}
                renderOption={renderOption}
              />
              <InfoTooltip text="Where the purchase was made." size={18} />
              <VendorField
                margin="dense"
                disabled={loading}
                value={values.vendor}
                onChange={(vendor) => {
                  if (vendor) setValues((v) => ({ ...v, vendor }));
                  else setValues(({ vendor, ...v }) => v);
                }}
              />
              <Form
                values={values}
                errors={errors}
                handleChange={handleChange}
                loading={loading}
                setValues={setValues}
              />
            </div>
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Box mt={-1.5}>
            <Tooltip title="Need help? Click here.">
              <GoldIconButton onClick={() => setOpen(true)} />
            </Tooltip>
          </Box>
          <Box flex={1} />
          {isUpdate ? (
            <Button
              onClick={handleCopy}
              color="primary"
              variant="contained"
              disabled={loading}
              id="transaction-edit-dialog-copy-expense-button"
            >
              Copy Expense
            </Button>
          ) : (
            <>
              <Uploader
                id="transaction-edit-dialog-bulk-upload-button"
                importerType="expenses"
                label="Bulk Upload"
              />
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={() => handleSave({}, true)}
              >
                Add and Next
              </Button>
            </>
          )}
          <LoadableButton
            color="primary"
            variant="contained"
            loading={loading}
            type="submit"
          >
            {isUpdate ? "Update" : "Add"}
          </LoadableButton>
        </DialogActions>
      </form>
    </>
  );
}

export function EditDialogContentWrapper({
  onClose,
  data,
  onDelete,
  loading: _loading,
}: EditDialogContentWrapperProps) {
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(0);

  const handleSaveItem = async (item: Transaction, next?: boolean) => {
    setLoading(true);
    await addTransaction(item);
    if (item.id) toast.success("Expense has been updated successfully");
    else toast.success("Expense has been added successfully");
    setLoading(false);
    if (next) {
      setKey((k) => k + 1);
    } else {
      onClose();
    }
  };

  return (
    <EditDialogContent
      key={key}
      onSave={handleSaveItem}
      data={key === 0 ? data : undefined}
      loading={_loading || loading}
      onDelete={onDelete}
    />
  );
}

export function EditDialog({
  onClose,
  open,
  data,
  onDelete,
  loading,
}: EditDialogProps) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"sm"}>
      <EditDialogContentWrapper
        onClose={onClose}
        data={data}
        onDelete={onDelete}
        loading={loading}
      />
    </Dialog>
  );
}

export default EditDialog;
