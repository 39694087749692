import { getUserIdSync } from "src/config/storage";
import { TransactionRule } from "src/interfaces/transaction.interface";
import firestore from "src/apiService/firestore";

export async function upsertTransactionRule(rule: TransactionRule) {
  if (!rule.plaid_description) return;

  const user = getUserIdSync();
  const userRef = firestore().collection("Users").doc(user);
  await firestore().runTransaction(async (t) => {
    const doc = await t.get(userRef);
    if (!doc?.exists) throw new Error("User doesn't exist");
    const data = doc.data();
    if (!data) throw new Error("User doesn't exist");
    const expenseRules = (data.expense_rules || [])
      .map((s) => JSON.parse(s))
      .filter(
        ({ plaid_description }) =>
          plaid_description && plaid_description !== rule.plaid_description
      );

    expenseRules.push({
      plaid_description: rule.plaid_description,
      description: rule.description,
      account: rule.account,
      vendor: rule.vendor,
    });

    return await t.update(userRef, {
      expense_rules: expenseRules.map((r) => JSON.stringify(r)) as string[],
    });
  });
}

export async function deleteTransactionRule(plaid_description: string) {
  const user = getUserIdSync();
  const userRef = firestore().collection("Users").doc(user);
  await firestore().runTransaction(async (t) => {
    const doc = await t.get(userRef);
    if (!doc?.exists) throw new Error("User doesn't exist");
    const data = doc.data();
    if (!data) throw new Error("User doesn't exist");
    const expenseRules = (data.expense_rules || [])
      .map((s) => JSON.parse(s))
      .filter(
        (rule) =>
          rule.plaid_description && plaid_description !== rule.plaid_description
      );

    return await t.update(userRef, {
      expense_rules: expenseRules.map((r) => JSON.stringify(r)) as string[],
    });
  });
}
