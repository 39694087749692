import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import {
  GridColumns,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@material-ui/x-grid";
import XGrid from "src/components/CSVFormattedXGrid";
import Box from "@material-ui/core/Box";

import { formatCurrency } from "src/utils";
import { renderHeaderLabel } from "src/pages/SalesPage/TableContent";
import { valueGetterWithDefault } from "src/utils/valueGetter";
import { userGetInventoryTypeSelector } from "src/store/system/selector";

import {
  TransactionFees,
  CostOfSoldInventory,
  ShippingCost,
} from "./constants";

import { ProfitLossReportData } from "./report";

export interface StatementDrillSalesType {
  type: "sales";
  platform: string;
}

export interface StatementDrillExpenseType {
  type: "expense";
  name: string;
}

export interface StatementDrillTransactionType {
  type: "transaction";
  name: string;
}

export type StatementDrillType =
  | StatementDrillSalesType
  | StatementDrillExpenseType
  | StatementDrillTransactionType
  | null;

export interface StatementDrillProps {
  data: ProfitLossReportData;
  type?: StatementDrillType;
}

const ColumnsToHighlighByExpense = {
  [TransactionFees]: ["transaction_fees", "extra_transaction_fees"],
  [CostOfSoldInventory]: ["purchase_price"],
  [ShippingCost]: ["shipping_cost", "extra_shipping_cost"],
};

const SalesColumns: GridColumns = [
  {
    field: "item_title",
    headerName: "Item Title",
    width: 200,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "department",
    headerName: "Department",
    width: 150,
    renderHeader: renderHeaderLabel,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "category",
    headerName: "Category",
    width: 200,
    renderHeader: renderHeaderLabel,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "sub_category",
    headerName: "Sub Category",
    width: 200,
    renderHeader: renderHeaderLabel,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "brand",
    headerName: "Brand",
    width: 150,
    renderHeader: renderHeaderLabel,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "location",
    headerName: "Location",
    width: 200,
    renderHeader: renderHeaderLabel,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "sku",
    headerName: "SKU",
    width: 150,
    renderHeader: renderHeaderLabel,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "purchase_date",
    headerName: "Purchase Date",
    type: "date",
    width: 200,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "list_date",
    headerName: "List Date",
    type: "date",
    width: 200,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "return_date",
    headerName: "Return Date",
    type: "date",
    width: 200,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "sale_date",
    headerName: "Sale Date",
    type: "date",
    width: 200,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "days_on_platform",
    headerName: "Days On Platform",
    type: "number",
    width: 220,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "sale_price",
    headerName: "Sale Price",
    type: "number",
    width: 200,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "purchase_price",
    headerName: "Purchase Price",
    type: "number",
    width: 220,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "shipping_cost",
    headerName: "Shipping Costs",
    type: "number",
    width: 200,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "extra_shipping_cost",
    headerName: "Return Shipping Cost",
    type: "number",
    width: 200,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "extra_transaction_fees",
    headerName: "Return Transaction Fees",
    type: "number",
    width: 200,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "shipping_cost_analytics",
    headerName: "Shipping Costs (From Expense Detail)",
    type: "number",
    width: 285,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "transaction_fees",
    headerName: "Transaction Fees",
    type: "number",
    width: 220,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "other_fees",
    headerName: "Other Costs (From Expense Detail)",
    type: "number",
    width: 285,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "gross_profit",
    headerName: "Gross profit",
    type: "number",
    width: 200,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "sale_state",
    headerName: "Sale State",
    width: 200,
    renderHeader: renderHeaderLabel,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "sales_tax",
    headerName: "Sales Tax",
    type: "number",
    width: 200,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "liable_to_pay",
    headerName: "Liable To Pay",
    width: 200,
    type: "boolean",
    renderHeader: renderHeaderLabel,
  },
  {
    field: "platforms_listed",
    headerName: "Platforms Listed",
    width: 300,
    renderHeader: renderHeaderLabel,
    valueFormatter: ({ value }) => {
      if (Array.isArray(value)) {
        return `${value.join(", ")}`;
      }
      return "";
    },
  },
  {
    field: "sale_platform",
    headerName: "Sale Platform",
    width: 200,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "notes",
    headerName: "Notes",
    width: 200,
    renderHeader: renderHeaderLabel,
    valueGetter: valueGetterWithDefault(""),
  },
];
const SalesColumnsCashIgnored = [
  "purchase_date",
  "purchase_price",
  "platforms_listed",
];
const SalesColumnsCash = SalesColumns.filter(
  ({ field }) => !SalesColumnsCashIgnored.includes(field)
);

const TransactionsColumns: GridColumns = [
  {
    field: "account",
    headerName: "General Ledger Account",
    width: 250,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "name",
    headerName: "Charge Description",
    width: 200,
    type: "string",
  },
  {
    field: "amount",
    headerName: "Amount",
    width: 200,
    type: "number",
    valueFormatter: formatCurrency,
  },
  { field: "date", headerName: "Date", width: 200, type: "date" },
  {
    field: "description",
    headerName: "Description",
    width: 250,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "integration_name",
    headerName: "Bank Account",
    width: 200,
    valueGetter: valueGetterWithDefault(""),
  },
];

const DoubleClickWarningText =
  "You can't edit data on this screen. If you'd like to modify a record, navigate to the appropriate tab and edit there.";

function StatementGridTableToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  highlightedHeader: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  highlightedCell: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
}));

function ExpenseStatementDrill({
  data,
  name,
}: {
  data: ProfitLossReportData;
  name: string;
}) {
  const inventoryTypeIsCash =
    useSelector(userGetInventoryTypeSelector) === "cash";
  const classes = useStyles();
  const rowsSales = data.salesListByExpense?.[name];
  const rowsTransactions = data.transactionsListByExpense?.[name];
  const salesColumns = useMemo(() => {
    const highlight = ColumnsToHighlighByExpense[name];
    if (!highlight) return SalesColumns;
    return (inventoryTypeIsCash ? SalesColumnsCash : SalesColumns).map((c) => {
      if (highlight.includes(c.field)) {
        c = {
          ...c,
          headerClassName: classes.highlightedHeader,
          cellClassName: classes.highlightedCell,
        };
      }

      return c;
    });
  }, [
    name,
    classes.highlightedHeader,
    classes.highlightedCell,
    inventoryTypeIsCash,
  ]);

  return (
    <Box mt={2}>
      <h2 className="text-blue">Cost of Goods Sold - {name}</h2>
      <Box height="75vh">
        {!!rowsSales && (
          <Box height={rowsTransactions ? "50%" : "100%"}>
            <XGrid
              rows={rowsSales}
              columns={salesColumns}
              pagination
              disableSelectionOnClick
              components={{
                Toolbar: StatementGridTableToolbar,
              }}
              onCellDoubleClick={() => toast.warning(DoubleClickWarningText)}
            />
          </Box>
        )}
        {!!rowsTransactions && (
          <Box height={rowsSales ? "50%" : "100%"} mt={rowsSales ? 1 : 0}>
            <XGrid
              rows={rowsTransactions}
              columns={TransactionsColumns}
              pagination
              disableSelectionOnClick
              components={{
                Toolbar: StatementGridTableToolbar,
              }}
              onCellDoubleClick={() => toast.warning(DoubleClickWarningText)}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

function StatementDrill({ data, type }: StatementDrillProps) {
  const inventoryTypeIsCash =
    useSelector(userGetInventoryTypeSelector) === "cash";
  if (!type) return null;

  if (type.type === "sales" && type.platform) {
    const rows = data.salesListByDepartment?.[type.platform] || [];
    return (
      <Box mt={2}>
        <h2 className="text-blue">Income - {type.platform}</h2>
        <Box height="75vh">
          <XGrid
            rows={rows}
            columns={inventoryTypeIsCash ? SalesColumnsCash : SalesColumns}
            pagination
            disableSelectionOnClick
            components={{
              Toolbar: StatementGridTableToolbar,
            }}
            onCellDoubleClick={() => toast.warning(DoubleClickWarningText)}
          />
        </Box>
      </Box>
    );
  }

  if (type.type === "expense" && type.name)
    return <ExpenseStatementDrill data={data} name={type.name} />;

  if (type.type === "transaction" && type.name) {
    const rows = data.transactionsListByExpense?.[type.name] || [];
    return (
      <Box mt={2}>
        <h2 className="text-blue">Other Business Expenses - {type.name}</h2>
        <Box height="75vh">
          <XGrid
            rows={rows}
            columns={TransactionsColumns}
            pagination
            disableSelectionOnClick
            components={{
              Toolbar: StatementGridTableToolbar,
            }}
            onCellDoubleClick={() => toast.warning(DoubleClickWarningText)}
          />
        </Box>
      </Box>
    );
  }

  return null;
}

export default StatementDrill;
