import { useSelector } from "react-redux";
import { type DataType } from "./ImportDialog";
import { isPoshmarkAllowedToDirectImportSelector } from "src/store/uploads/selector";

export function usePreventImportPoshmark(dataType: DataType) {
  return !useSelector((state: any) =>
    isPoshmarkAllowedToDirectImportSelector(state, dataType)
  );
}

export default usePreventImportPoshmark;
