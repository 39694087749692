const FIREBASE_DUPLICATE_SIGNUP_ERROR = "auth/email-already-in-use";
const FIREBASE_USER_NOT_FOUND_ERROR = "auth/user-not-found";

export const parseError = (error: any) => { // TODO: update later
  const msg = "Something went wrong!";
  let data = error;
  if (error.response && error.response.data) {
    data = error.response.data;
  }
  if (data && data.message) {
    if (data.code === FIREBASE_USER_NOT_FOUND_ERROR) {
      return "ID token has expired. Get a fresh ID token from your client app and try again"
    } else if (data.code === FIREBASE_DUPLICATE_SIGNUP_ERROR) {
      return "The email address you entered is incorrect or the account does not exist"
    } else {
      return data.message;
    }
  } else {
    return msg
  }
};
