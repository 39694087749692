import { createAction } from "redux-actions";
import { 
  BANK_STATEMENT,
  START,
  FETCH_ITEMS,
  UPLOAD_ITEMS,
  DELETE_ITEMS,
  FETCH_ITEM,
  CREATE_ITEM,
  DELETE_ITEM,
  UPDATE_ITEM,
  SELECT_ITEMS,
  SET_FILTER,
  RESET,
  FS_CREATE_ITEMS,
  FS_DELETE_ITEMS,
  FS_UPDATE_ITEMS,
} from "../common";

export const fetchItems = createAction(BANK_STATEMENT + FETCH_ITEMS + START);
export const uploadItems = createAction(BANK_STATEMENT + UPLOAD_ITEMS + START);
export const deleteItems = createAction(BANK_STATEMENT + DELETE_ITEMS + START);

export const fetchItem = createAction(BANK_STATEMENT + FETCH_ITEM + START);
export const createItem = createAction(BANK_STATEMENT + CREATE_ITEM + START);
export const deleteItem = createAction(BANK_STATEMENT + DELETE_ITEM + START);
export const updateItem = createAction(BANK_STATEMENT + UPDATE_ITEM + START);

export const selectItems = createAction(BANK_STATEMENT + SELECT_ITEMS);
export const setFilter = createAction(BANK_STATEMENT + SET_FILTER);

export const reset = createAction(BANK_STATEMENT + RESET);

export const addBankStatementRecords = createAction(BANK_STATEMENT + FS_CREATE_ITEMS);
export const updateBankStatementRecords = createAction(BANK_STATEMENT + FS_UPDATE_ITEMS);
export const deleteBankStatementRecords = createAction(BANK_STATEMENT + FS_DELETE_ITEMS);
