import React from "react";
import { useLocation } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Hidden from "@material-ui/core/Hidden";
import { AdminHtmlState } from "src/interfaces/adminHtmlState.interface";
import AdminHeader from "../components/AdminHeader/Support";
import Navigator from "../components/Navigator/Support";

import { drawerWidth, styles, theme } from "../theme";
import { useSelector, useDispatch } from "react-redux";
import { toggleMobileOpen } from "../store/adminHtml/actions";
import { AppConfig } from "../config";
import { navigation } from "src/config/supportNavigation";

const useStyles = makeStyles(styles);

function Support({ children }) {
  const location = useLocation();
  const classes = useStyles();
  const mobileOpen = useSelector(
    (state: { adminHtml: AdminHtmlState }) => state.adminHtml.mobileOpen
  );
  const dispatch = useDispatch();
  const onDrawerToggle = () => {
    dispatch(toggleMobileOpen());
  };
  const activePage = navigation.find((e) =>
    location.pathname.startsWith(e.href)
  );

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <nav className={classes.drawer}>
          <Hidden smUp implementation="js">
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={onDrawerToggle}
              title={AppConfig.title}
              subTitle="Dashboard"
            />
          </Hidden>
          <Hidden xsDown implementation="css">
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              open={mobileOpen}
              variant="permanent"
              title={AppConfig.title}
              subTitle="Dashboard"
            />
          </Hidden>
        </nav>
        <div className={classes.appContent}>
          <AdminHeader
            onDrawerToggle={onDrawerToggle}
            title={activePage?.title || "Support"}
          />
          {children}
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Support;
