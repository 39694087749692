import React, { type Dispatch, type SetStateAction } from "react";

import { useSelector, useDispatch } from "react-redux";

import type { MercariIntegration } from "src/interfaces/plaidIntegration.interface";
import { type DataType } from "src/pages/GetStarted/ImportDialog";
import type MonthYear from "src/interfaces/monthYear.interface";
import { isMercariAllowedToDirectImportSelector } from "src/store/uploads/selector";
import {
  mercariIntegrationDelete,
  mercariIntegrationSyncChange,
} from "src/apiService/modules/mercari";
import {
  mercariSyncWithCheck,
  mercariSyncInventory,
  mercariSyncSales,
} from "src/store/integrationSync/actions/mercari";

import PlatformIntegrationsTableRow from "../PlatformIntegrationsTableRow";
import useIsRunning from "../useIsRunning";
import type { DialogState } from "../types";

// fix-vim-highlight = }

export function usePreventImportMercari(dataType: DataType) {
  return !useSelector((state: any) =>
    isMercariAllowedToDirectImportSelector(state, dataType)
  );
}

const IsRunningThreshold = 1000; // TODO;

function MercariIntegrationTableRow({
  integration,
  setDialog,
  hideInventory,
}: {
  integration: MercariIntegration;
  setDialog: Dispatch<SetStateAction<DialogState>>;
  hideInventory: boolean;
}) {
  const dispatch = useDispatch();
  const isRunning = useIsRunning(
    integration.salesIsRunning,
    integration.inventoryIsRunning,
    IsRunningThreshold
  );

  return (
    <PlatformIntegrationsTableRow
      key={integration.id}
      name={integration.username || "Poshmark"}
      username={integration.username}
      platform="Mercari"
      isRunning={isRunning}
      sync={integration.sync}
      inventory={integration.sync && integration.inventory !== false}
      onPullDataNow={async () => {
        await dispatch(mercariSyncWithCheck(integration.id, true));
      }}
      onImport={() => {
        setDialog({
          type: "import",
          props: {
            integrationId: integration.id,
            fetchListings: (integrationId) =>
              dispatch(mercariSyncInventory(integrationId)),
            fetchTransactions: async (
              integrationId: string,
              start: MonthYear,
              end: MonthYear
            ) => dispatch(mercariSyncSales(integrationId, { start, end })),
            providerName: "Mercari",
            usePreventImport: usePreventImportMercari,
          },
        });
      }}
      onSyncChanged={async (sync, inventory) => {
        await mercariIntegrationSyncChange(integration.id, sync, inventory);
        if ((inventory === undefined || inventory) && sync)
          await dispatch(mercariSyncWithCheck(integration.id, true));
      }}
      hideInventory={hideInventory}
      onDelete={() =>
        setDialog({
          type: "delete",
          props: {
            integration,
            deleteIntegration: mercariIntegrationDelete,
          },
        })
      }
    />
  );
}

export default MercariIntegrationTableRow;
