import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import capitalize from "lodash/capitalize";

import { userPlanSelector } from "src/store/system/selector";
import {
  getPoshmarkIntegrations,
  getMercariIntegrations,
} from "src/store/plaidIntegration/selector";
import type { IntegrationType } from "src/store/integrationSync/types";
import { updateSyncStatus } from "src/store/integrationSync/actions";
import { clearBeforeUnloadHandler } from "src/store/integrationSync/actions/utils";
import { syncWithCheck } from "src/store/integrationSync/actions";
import { integrationSyncEntriesSelector } from "src/store/integrationSync/selector";
import AddPoshmarkExtensionDialog from "src/components/AddPoshmarkExtensionDialog";

import PoshmarkLogInDialog from "./LogInDialog";
import StatusesContainer from "./StatusesContainer";
import Status, { StatusProps } from "./Status";

const CheckInterval = 1 * 60 * 1000; // 1 min

function Syncer({ integrationId }: { integrationId: string }) {
  const dispatch = useDispatch();
  const dispatchRef = useRef(dispatch);

  dispatchRef.current = dispatch;

  useEffect(() => {
    let timeout;
    async function effect() {
      timeout = undefined;

      await dispatchRef.current(syncWithCheck(integrationId));

      timeout = setTimeout(effect, CheckInterval);
    }

    effect();

    return () => {
      if (timeout) clearTimeout(timeout);
      clearBeforeUnloadHandler();
    };
  }, [integrationId]);

  return null;
}

function IntegrationSyncerDialog() {
  const plan = useSelector(userPlanSelector);
  const poshmarkIntegrations = useSelector(getPoshmarkIntegrations);
  const mercariIntegrations = useSelector(getMercariIntegrations);
  const entries = useSelector(integrationSyncEntriesSelector);
  const dispatch = useDispatch();
  let manualDialog:
    | {
        integration: IntegrationType;
        type: "install-extension" | "update-extension" | "user-not-logged-in";
      }
    | undefined;

  const snackbars = (entries || []).map((state) => {
    const snackbarProps: StatusProps & { integration: string } = {
      integration: state.integration,
      open: false,
    };

    if (state?.type === "syncing") {
      snackbarProps.open = true;
      snackbarProps.message = `Your ${capitalize(
        state.integration
      )} account is syncing. Please don’t close your browser until this process completes.`;
    } else if (state?.type === "error") {
      snackbarProps.open = true;
      snackbarProps.message = state.message || "Error";
      snackbarProps.severity = "warning";
      snackbarProps.onClose = () =>
        dispatch(
          updateSyncStatus({
            integration: state.integration,
            status: undefined,
          })
        );
      if (
        state.dispatcher === "manual" &&
        (state.code === "install-extension" ||
          state.code === "update-extension" ||
          state.code === "user-not-logged-in")
      ) {
        snackbarProps.open = false;
        manualDialog = {
          type: state.code,
          integration: state.integration,
        };
      }
    }

    return snackbarProps;
  });

  return (
    <>
      <AddPoshmarkExtensionDialog
        open={
          manualDialog?.type === "install-extension" ||
          manualDialog?.type === "update-extension"
        }
        platform={manualDialog?.integration}
        update={manualDialog?.type === "update-extension"}
        onClose={() => {
          if (!manualDialog) return;
          dispatch(
            updateSyncStatus({
              integration: manualDialog.integration,
              status: undefined,
            })
          );
        }}
      />
      <PoshmarkLogInDialog
        open={manualDialog?.type === "user-not-logged-in"}
        platform={manualDialog?.integration}
        onClose={() => {
          if (!manualDialog) return;
          dispatch(
            updateSyncStatus({
              integration: manualDialog.integration,
              status: undefined,
            })
          );
        }}
      />
      {plan === "ultimate"
        ? [...poshmarkIntegrations, ...mercariIntegrations].map(
            (integration) => {
              if (!integration.sync) return null;

              return (
                <Syncer key={integration.id} integrationId={integration.id} />
              );
            }
          )
        : null}
      <StatusesContainer>
        {snackbars.map(({ integration, ...snackbarProps }) => (
          <Status key={integration} {...snackbarProps} />
        ))}
      </StatusesContainer>
    </>
  );
}

export default IntegrationSyncerDialog;
