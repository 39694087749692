import React from "react";
import { toast } from "react-toastify";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";

import { StatefulLoadableButton } from "src/components/LoadableButton";
import Step from "../Step";
import DialogTitle from "../DialogTitle";
import { etsyRequestAuthorizationCode } from "../../../apiService/modules/etsy";

interface EtsyAddNewEtsyIntegrationProps {
  onClose: () => void;
  onStepChange: (step: Step) => void;
}

function EtsyAddNewEtsyStore({
  onClose,
  onStepChange,
}: EtsyAddNewEtsyIntegrationProps) {
  return (
    <>
      <DialogTitle onClose={onClose}>Connect to Etsy</DialogTitle>
      <MuiDialogContent>
        <DialogContentText>
          Click connect to be taken to Etsy's website to authenticate. Please
          follow the prompts in order to connect your Etsy account.
        </DialogContentText>
      </MuiDialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onStepChange("etsy:do-you-sell-on-etsy");
          }}
          color="primary"
          autoFocus
        >
          Go Back
        </Button>
        <StatefulLoadableButton
          variant="contained"
          color="primary"
          onClick={async () => {
            try {
              const authResponse = await etsyRequestAuthorizationCode({
                back: "/get-started#other:do-you-sell",
              });
              window.location.href = authResponse.data.etsyURL;
            } catch (e) {
              toast.error(`There was an error: ${(e as Error).toString()}`);
            }
          }}
        >
          Connect
        </StatefulLoadableButton>
      </DialogActions>
    </>
  );
}

export default EtsyAddNewEtsyStore;
