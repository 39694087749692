import { createAction } from "redux-actions";
import { 
  CASH_ACTIVITY,
  SET_FILTER,
  FS_CREATE_ITEMS,
  FS_DELETE_ITEMS,
  FS_UPDATE_ITEMS,
} from "../common";

export const setFilter = createAction(CASH_ACTIVITY + SET_FILTER);

export const addCashActivityRecords = createAction(CASH_ACTIVITY + FS_CREATE_ITEMS);
export const updateCashActivityRecords = createAction(CASH_ACTIVITY + FS_UPDATE_ITEMS);
export const deleteCashActivityRecords = createAction(CASH_ACTIVITY + FS_DELETE_ITEMS);
