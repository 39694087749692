import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";

import { setActiveDialog } from "src/store/adminHtml/actions";

import {
  getUnreviewedReturnSales,
  getSaleById,
  getSalesLoading,
} from "src/store/sale/selector";

import {
  updateItem,
  updateBundledItems,
  moveItem,
  moveBundledItems,
  recordReturn,
} from "src/store/sale/actions";
import { EditDialog } from "src/pages/SalesPage/EditDialog";
import { Sale } from "src/interfaces/sale.interface";

import ConfirmReturnDetails from "./ConfirmReturnDetails";

function SaleEditDialog({
  returnSale,
  onClose,
}: {
  returnSale?: Sale;
  onClose: () => void;
}) {
  const dispatch = useDispatch();
  const editSale = useSelector((state: any) =>
    getSaleById(state, returnSale?.original_id)
  );
  const loading = useSelector(getSalesLoading);

  return (
    <EditDialog
      loading={loading}
      open={!!editSale}
      data={editSale}
      onClose={onClose}
      onSave={(item) => dispatch(updateItem(item))}
      onSaveBundle={(items) => dispatch(updateBundledItems(items))}
      onMove={(item) => dispatch(moveItem(item))}
      onMoveBundle={(items) => dispatch(moveBundledItems(items))}
      onReturn={(returnRecord: any, sale: any, inventory: any) =>
        dispatch(recordReturn({ returnRecord, sale, inventory }))
      }
      onDelete={() =>
        console.error(
          "onDelete - should never happen (user shouldn't be allow to do this action)"
        )
      }
      onDeleteBundle={() =>
        console.error(
          "onDeleteBundle - should never happen (user shouldn't be allow to do this action)"
        )
      }
      onDeleteReturn={() =>
        console.error(
          "onDeleteReturn - should never happen (user shouldn't be allow to do this action)"
        )
      }
      editReturn={() => onClose()}
    />
  );
}

function ReviewReturnsDialogContent({ onClose }: { onClose: () => void }) {
  const unreviewedReturns = useSelector(getUnreviewedReturnSales);
  const [editReturnSale, setEditReturnSale] = useState<Sale>();

  return (
    <>
      <SaleEditDialog
        returnSale={editReturnSale}
        onClose={() => setEditReturnSale(undefined)}
      />
      {unreviewedReturns.length > 0 && (
        <ConfirmReturnDetails
          onClose={onClose}
          unreviewedReturn={unreviewedReturns[0]}
          unreviewedReturns={unreviewedReturns.length}
          onGoToSale={(r) => setEditReturnSale(r)}
        />
      )}
      {unreviewedReturns.length === 0 && (
        <>
          <DialogTitle>Confirm Refund Details</DialogTitle>
          <DialogContent>
            <DialogContentText className="relative" tabIndex={-1}>
              <Typography variant="body1" paragraph>
                Congratulations!
              </Typography>
              <Typography variant="body1" paragraph>
                You have finished reviewing all the refunds.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="px-7 py-5">
            <Button color="primary" variant="contained" onClick={onClose}>
              Done
            </Button>
          </DialogActions>
        </>
      )}
    </>
  );
}

function ReviewReturnsDialog({ open }: { open: boolean }) {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setActiveDialog(""));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="body"
      fullWidth
      maxWidth="sm"
    >
      <ReviewReturnsDialogContent onClose={handleClose} />
    </Dialog>
  );
}

export default ReviewReturnsDialog;
