import { createSelector } from "reselect";
import { AppState } from "..";
import { TransactionState } from "../../interfaces/transactionState.interface";
import { getVendors } from "src/store/vendor/selector";

const getTransactionState = (state: AppState) => state.transaction;

export const transactionSelector = createSelector(
  getTransactionState,
  (transaction: TransactionState) => transaction
);
export const getTransactions = createSelector(
  [getTransactionState, getVendors],
  (transactions, vendors) => {
    const items = transactions.items.map((item) => {
      if (item.vendor)
        return {
          ...item,
          vendorName: vendors.find((v) => v.id === item.vendor)?.name || "",
        };

      return item;
    });
    const reviewedTransactions = items.filter(
      (item) => item.reviewed || item.reviewed == null
    );
    const unreviewedTransactions = items.filter(
      (item) => !item.reviewed && item.reviewed != null
    );
    return {
      ...transactions,
      items,
      reviewedTransactions,
      unreviewedTransactions,
    };
  }
);

export const defaultGeneralLedgerAccountSelector = createSelector(
  [transactionSelector],
  (transactions) => transactions.accounts
);

export const allIntegrationNamesSelector = createSelector(
  getTransactionState,
  (transactions) => {
    const integrationNames = [
      ...new Set(
        transactions.items
          .map(({ integration_name }) => integration_name)
          .filter(Boolean)
      ),
    ] as string[];
    integrationNames.sort((a, b) => a.localeCompare(b));
    return integrationNames;
  }
);
