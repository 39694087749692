import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  type MutableRefObject,
} from "react";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { XGrid, type GridCellEditCommitParams } from "@material-ui/x-grid";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Dialog from "@material-ui/core/Dialog";
import Checkbox from "@material-ui/core/Checkbox";

import { setActiveDialog } from "src/store/adminHtml/actions";
import {
  getTransactions,
  transactionSelector,
} from "src/store/transaction/selector";
import { getGeneralLedgerAccounts } from "src/store/glAccounts/selector";
import {
  setTransactionsReviewed,
  updateTransactionAccount,
  updateTransactionDescription,
  updateTransactionHasRule,
  deleteTransactions,
  updateTransactionVendor,
} from "src/apiService/modules/transactions";
import { upsertTransactionRule } from "src/apiService/modules/transactionRules";
import { Transaction } from "src/interfaces/transaction.interface";
import { getVendors, getVendor } from "src/store/vendor/selector";
import VendorDialog from "src/pages/RootDialogs/ProfileSettings/VendorDialog";
import { AddGeneralLedgerAccountDialog } from "src/pages/RootDialogs/AddGeneralLedgerAccountDialog";
import LoadableButton from "src/components/LoadableButton";
import ConfirmDialog from "src/components/ConfirmDialog";
import DialogTitleWithClose from "src/components/DialogTitleWithClose";
import WalkThrough from "./WalkThrough";

// fix-vim-highlight = }

const StyledSelect = withStyles({
  root: {
    width: 260,
  },
})(Select);

const StyledMenuItem = withStyles({
  root: {
    width: 260,
  },
})(MenuItem);

function NewExpenseRuleDialogContent({
  transaction: _transaction,
  onClose,
  preventBackdropDuringLoading,
}: {
  transaction?: Transaction | null;
  onClose: () => void;
  preventBackdropDuringLoading: MutableRefObject<boolean | null>;
}) {
  const [transaction] = useState(_transaction);
  const [loading, setLoading] = useState(false);
  const vendor = useSelector((s: any) => getVendor(s, transaction?.vendor));
  const handleSave = async () => {
    preventBackdropDuringLoading.current = true;
    setLoading(true);
    try {
      if (transaction) {
        await upsertTransactionRule({
          plaid_description: (transaction as unknown as { name: string }).name,
          description: transaction.description,
          account: transaction.account,
          vendor: transaction.vendor,
        });
        await updateTransactionHasRule(transaction.id, true);
      }
      preventBackdropDuringLoading.current = false;
      onClose();
    } catch (e) {
      toast.error(
        "There has been an error creating the Transaction Rule. Try again later"
      );
      setLoading(false);
      preventBackdropDuringLoading.current = false;
    }
  };

  return (
    <>
      <DialogTitle>Categorize Expenses</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You’re automating your processes, congrats! Charges that say "
          {(transaction as unknown as { name: string })?.name}" will now be
          automatically assigned to the general ledger account "
          {transaction?.account}" with a description that says "
          {transaction?.description}"
          {vendor ? ` and "${vendor.name}" as the vendor` : ""}.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => onClose()}
          disabled={loading}
        >
          Back
        </Button>
        <LoadableButton
          color="primary"
          variant="contained"
          onClick={handleSave}
          loading={loading}
        >
          Confirm
        </LoadableButton>
      </DialogActions>
    </>
  );
}
function NewExpenseRuleDialog({
  open,
  transaction,
  onClose,
}: {
  open: boolean;
  transaction?: Transaction | null;
  onClose: () => void;
}) {
  const preventBackdropDuringLoading = useRef<boolean | null>(false);
  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!preventBackdropDuringLoading.current) onClose();
      }}
    >
      <NewExpenseRuleDialogContent
        preventBackdropDuringLoading={preventBackdropDuringLoading}
        transaction={transaction}
        onClose={onClose}
      />
    </Dialog>
  );
}

function CategorizeExpensesDialogContent({ onClose }: { onClose: () => void }) {
  const dispatch = useDispatch();
  const transactionState = useSelector(getTransactions);
  const [selectedTransactions, setSelectedTransactions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { accounts } = useSelector(transactionSelector);
  const [ruleTransaction, setRuleTransaction] = useState<Transaction | null>(
    null
  );
  const customLedgerAccounts = useSelector(getGeneralLedgerAccounts);
  const _vendors = useSelector(getVendors);
  const vendors = useMemo(() => {
    return [..._vendors].sort((a, b) => a.name.localeCompare(b.name));
  }, [_vendors]);
  const [openVendorDialogTransactionId, setOpenVendorDialogTransactionId] =
    useState<string>();
  const [
    openGeneralLedgerAccountDialogTransactionId,
    setOpenGeneralLedgerAccountDialogTransactionId,
  ] = useState<string>();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const allLedgerAccounts: string[] = useMemo(() => {
    const all: string[] = [
      ...new Set(
        [...customLedgerAccounts, ...accounts].map((account) => {
          if (account["General Ledger Account"])
            return account["General Ledger Account"];
          return account.name;
        })
      ),
    ];

    all.sort((a, b) => a.localeCompare(b));

    return all;
  }, [customLedgerAccounts, accounts]);

  useEffect(() => {
    if (transactionState.unreviewedTransactions.length === 0) {
      dispatch(setActiveDialog(""));
    }
  }, [transactionState, dispatch]);

  const toggleRow = (transactionId) => {
    const spot = selectedTransactions.indexOf(transactionId);
    if (spot === -1) {
      setSelectedTransactions((oldArray) => [...oldArray, transactionId]);
    } else {
      const newSelected = [...selectedTransactions];
      newSelected.splice(spot, 1);
      setSelectedTransactions(newSelected);
    }
  };

  const reviewSelected = () => {
    setLoading(true);
    setTransactionsReviewed(selectedTransactions).then((_) => {
      setLoading(false);
    });
    setSelectedTransactions([]);
  };

  const makeSelection = () => {
    if (selectedTransactions.length > 0) {
      setSelectedTransactions([]);
    } else {
      transactionState.unreviewedTransactions.forEach((transaction) => {
        toggleRow(transaction.id);
      });
    }
  };

  const handleGeneralLedgerChange = async (event, transactionId) => {
    event.stopPropagation();
    const newAccount = event.target.value;
    if (newAccount === "add-new-account") {
      setOpenGeneralLedgerAccountDialogTransactionId(transactionId);
      return;
    }

    await updateTransactionAccount(transactionId, newAccount);
  };

  const handleVendorChange = async (event, transactionId) => {
    event.stopPropagation();
    const vendorId = event.target.value;

    if (vendorId === "add-new-vendor") {
      setOpenVendorDialogTransactionId(transactionId);
      return;
    }

    await updateTransactionVendor(
      transactionId,
      vendorId !== "default" ? vendorId : ""
    );
  };
  const handleCellCommit = async (params: GridCellEditCommitParams) => {
    if (params.field === "description")
      await updateTransactionDescription(params.id, String(params.value || ""));
  };
  const xGridRef = useRef();

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 106,
      editable: false,
      sortable: true,
      valueFormatter: (params) => {
        const valueFormatted = moment(params.value).format("YYYY-MM-DD");
        return `${valueFormatted} `;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 127,
      editable: false,
      sortable: true,
      type: "number",
    },
    {
      field: "name",
      headerName: "Charge Description",
      description:
        "This field is pulled from the bank/credit card -- think of it like the description you would see on your bank statement/credit card statement.",
      minWidth: 210,
      flex: 1,
      editable: false,
      sortable: true,
    },
    {
      field: "account",
      headerName: "General Ledger Account",
      description: "The category for the transaction.",
      headerClassName: "categorize-expenses-table-account-header",
      cellClassName: "categorize-expenses-table-account-cell",
      sortable: true,
      width: 215,
      renderCell: (params) => (
        <StyledSelect
          value={params.value || "Ask Accountant"}
          onChange={(event) => handleGeneralLedgerChange(event, params.id)}
        >
          <StyledMenuItem value="add-new-account">
            Add new account
          </StyledMenuItem>
          {allLedgerAccounts.map((account) => (
            <StyledMenuItem key={account} value={account}>
              {account}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      ),
    },
    {
      field: "vendor",
      headerName: "Vendor",
      description: "Where the purchase was made.",
      headerClassName: "categorize-expenses-table-vendor-header",
      cellClassName: "categorize-expenses-table-vendor-cell",
      sortable: true,
      width: 200,
      renderCell: (params) => (
        <StyledSelect
          value={params.value || "default"}
          onChange={(event) => handleVendorChange(event, params.id)}
        >
          <StyledMenuItem value="add-new-vendor">Add new vendor</StyledMenuItem>
          {vendors.map((vendor) => {
            return (
              <StyledMenuItem key={vendor.id} value={vendor.id}>
                {vendor.name}
              </StyledMenuItem>
            );
          })}
          <StyledMenuItem value="default">No Vendor</StyledMenuItem>
        </StyledSelect>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      description:
        "Use this field to write a helpful reminder about details for the transaction.",
      headerClassName: "categorize-expenses-table-description-header",
      cellClassName: "categorize-expenses-table-description-cell",
      sortable: true,
      editable: true,
      minWidth: 180,
      flex: 2,
    },
    {
      field: "has_rule",
      headerName: "Create Expense Rule",
      description:
        "Check the box to create a rule that applies the current general ledger account, vendor, and description to all future transactions with a matching charge description.",
      sortable: false,
      editable: false,
      filterable: false,
      resizable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      width: 150,
      headerClassName: "categorize-expenses-table-hasrule-header",
      cellClassName: "categorize-expenses-table-hasrule-cell",
      renderCell: (params) => {
        return (
          <Checkbox
            className="categorize-expenses-table-hasrule-checkbox"
            disabled={!params.row.name}
            style={{ margin: "auto" }}
            checked={params.value || false}
            onClick={() => {
              setRuleTransaction(params.row);
            }}
          />
        );
      },
    },
  ];
  return (
    <>
      <WalkThrough apiRef={xGridRef} />
      <ConfirmDialog
        open={confirmOpen && selectedTransactions.length > 0}
        title="Confirm"
        text={`Are you sure you want to delete ${selectedTransactions.length} expense(s)?`}
        onCancel={() => {
          setConfirmOpen(false);
        }}
        onConfirm={async () => {
          try {
            await deleteTransactions(selectedTransactions);
            setSelectedTransactions([]);
            setConfirmOpen(false);
          } catch (e) {
            toast.error(
              `There was an error deleting the transactions: ${(
                e as Error
              ).toString()}`
            );
          }
        }}
      />
      <VendorDialog
        id={openVendorDialogTransactionId ? "add-new-vendor" : undefined}
        onClose={async (vendorId) => {
          if (openVendorDialogTransactionId && vendorId)
            await updateTransactionVendor(
              openVendorDialogTransactionId,
              vendorId
            );
          setOpenVendorDialogTransactionId(undefined);
        }}
      />
      <AddGeneralLedgerAccountDialog
        open={!!openGeneralLedgerAccountDialogTransactionId}
        onClose={async (account) => {
          if (openGeneralLedgerAccountDialogTransactionId && account?.name)
            await updateTransactionAccount(
              openGeneralLedgerAccountDialogTransactionId,
              account.name
            );

          setOpenGeneralLedgerAccountDialogTransactionId(undefined);
        }}
      />

      <NewExpenseRuleDialog
        open={!!ruleTransaction}
        transaction={ruleTransaction}
        onClose={() => {
          setRuleTransaction(null);
        }}
      />

      <DialogTitleWithClose onClose={onClose}>
        Categorize Expenses
      </DialogTitleWithClose>
      <DialogContent>
        <XGrid
          apiRef={xGridRef as any}
          density="compact"
          rows={transactionState.unreviewedTransactions}
          columns={columns}
          rowHeight={60}
          selectionModel={selectedTransactions}
          onSelectionModelChange={(e) => toggleRow(e[0])}
          onCellEditCommit={handleCellCommit}
          className="categorize-expenses-table"
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          disabled={selectedTransactions.length === 0}
          onClick={() => {
            setConfirmOpen(true);
          }}
        >
          Delete Selected
        </Button>
        <Button color="primary" variant="contained" onClick={makeSelection}>
          {selectedTransactions.length === 0 && <span>Select All</span>}
          {selectedTransactions.length !== 0 && <span>Deselect All</span>}
        </Button>
        <LoadableButton
          color="primary"
          variant="contained"
          disabled={selectedTransactions.length === 0}
          onClick={reviewSelected}
          id="categorize-expenses-mark-reviewed-button"
          loading={loading}
        >
          Mark Reviewed
        </LoadableButton>
      </DialogActions>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    height: "100%",
  },
}));

function CategorizeExpensesDialog({ open }: { open: boolean }) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setActiveDialog(""));
  };
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="xl"
      classes={classes}
    >
      <CategorizeExpensesDialogContent onClose={handleClose} />
    </Dialog>
  );
}

export default CategorizeExpensesDialog;
