import React from "react";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";

import DialogTitle from "./DialogTitle";
import type Step from "./Step";

interface InventoryCashVsAccrualProps {
  onClose?: () => void;
  onStepChange: (step: Step) => void;
}

function InventoryCashVsAccrual({
  onClose,
  onStepChange,
}: InventoryCashVsAccrualProps) {
  return (
    <>
      <DialogTitle onClose={onClose}>Cash vs Accrual Inventory</DialogTitle>
      <MuiDialogContent>
        <Typography align="center" display="block" gutterBottom paragraph>
          Great question, this article provides a thorough explanation:
        </Typography>
        <Typography align="center" display="block" gutterBottom paragraph>
          <Link
            href="https://myresellergenie.zendesk.com/hc/en-us/articles/14483257510427-What-is-the-difference-between-the-cash-inventory-method-and-the-accrual-inventory-method-"
            target="_blank"
          >
            What is the difference between the cash inventory method and the
            accrual inventory method?
          </Link>
        </Typography>
        <Typography align="center" display="block" gutterBottom paragraph>
          If you don’t have an established method and can’t afford a CPA,
          accountants generally consider the accrual method better for
          resellers. You can always adjust this setting after set-up if you
          need.
        </Typography>
      </MuiDialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() =>
            onStepChange("inventory:how-do-you-account-for-your-inventory")
          }
          color="primary"
          autoFocus
        >
          Go Back
        </Button>
      </DialogActions>
    </>
  );
}

export default InventoryCashVsAccrual;
