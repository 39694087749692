import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import LoadableButton from "src/components/LoadableButton";

export interface ConfirmDeleteAccountDataDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
}

function ConfirmDeleteAccountDataDialogActions({
  onCancel,
  onConfirm,
  disabled,
}: Omit<ConfirmDeleteAccountDataDialogProps, "open"> & { disabled?: boolean }) {
  const [loading, setLoading] = useState(false);

  return (
    <DialogActions>
      <Button disabled={loading} onClick={onCancel} color="primary">
        Cancel
      </Button>
      <LoadableButton
        disabled={disabled}
        loading={loading}
        onClick={async () => {
          setLoading(true);
          await onConfirm();
        }}
        color="secondary"
        variant="contained"
      >
        Delete Account Data
      </LoadableButton>
    </DialogActions>
  );
}

function ConfirmDeleteAccountDataDialogContent({
  onCancel,
  onConfirm,
}: Omit<ConfirmDeleteAccountDataDialogProps, "open">) {
  const [value, setValue] = useState("");
  return (
    <>
      <DialogTitle>Delete Account Data</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will delete all the data in your account – this cannot be undone.
          If you would like to delete all the data in your account, type
          “DELETE ALL DATA” in the box below and click the “Delete Account Data” button.
        </DialogContentText>
        <TextField
          variant="outlined"
          fullWidth
          onChange={(e) => setValue(e.target.value)}
        />
      </DialogContent>
      <ConfirmDeleteAccountDataDialogActions
        onCancel={onCancel}
        onConfirm={onConfirm}
        disabled={value !== "DELETE ALL DATA"}
      />
    </>
  );
}
function ConfirmDeleteAccountDataDialog({
  onCancel,
  onConfirm,
  open,
}: ConfirmDeleteAccountDataDialogProps) {
  return (
    <Dialog open={open}>
      <ConfirmDeleteAccountDataDialogContent
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    </Dialog>
  );
}

export default ConfirmDeleteAccountDataDialog;
