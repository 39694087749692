import React, { useState, useMemo, useEffect } from "react";
import capitalize from "lodash/capitalize";

import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

import { toast } from "react-toastify";

import InputAdornment from "@material-ui/core/InputAdornment";

import {
  userIncomeTaxRateSelector,
  userPlatformsSelector,
  userPlanSelector,
} from "src/store/system/selector";
import { XGrid } from "@material-ui/x-grid";
import { getGeneralLedgerAccounts } from "src/store/glAccounts/selector";
import { getVendors } from "src/store/vendor/selector";
import { setActiveDialog } from "src/store/adminHtml/actions";
import {
  deleteGeneralLedgerAccount,
  updateGeneralLedgerAccount,
} from "src/apiService/modules/generalLedgerAccounts";
import { expenseRulesSelector } from "src/store/system/selector";
import { deleteTransactionRule } from "src/apiService/modules/transactionRules";
import { deleteVendor } from "src/apiService/modules/vendors";
import { getSubscription } from "src/apiService/modules/stripe";
import vendorRenderCell from "src/utils/vendorRenderCell";
import LoadableButton from "src/components/LoadableButton";
import { updateUserApi } from "src/apiService/modules/system";
import InventoryAccountingMethodSelect from "./InventoryAccountingMethodSelect";
import RestartButton from "./RestartButton";

import DeleteButton from "./DeleteButton";
import AnalyticsGroupings from "./AnalyticsGroupings";
import VendorDialog from "./VendorDialog";
import SalesPlatformDialog from "./SalesPlatformDialog";
import ManageSubscriptionButton from "./ManageSubscriptionButton";
import WalkThroughTopicsList from "./WalkThroughTopicsList";

const VendorColumns = [
  {
    field: "name",
    headerName: "Vendor Name",
    flex: 1,
    sortable: true,
  },
  {
    field: "contractor",
    headerName: "1099 Contractor",
    width: 190,
    sortable: true,
    type: "boolean",
  },
  {
    field: "consignor",
    headerName: "Consignor",
    width: 190,
    sortable: true,
    type: "boolean",
  },
  {
    field: "id",
    headerName: "Remove",
    width: 110,
    editable: false,
    sortable: false,
    renderCell: (params) => {
      return (
        <DeleteButton
          onClick={async () => {
            try {
              const ret = await deleteVendor(params.id);
              if (ret.error)
                toast.error(
                  `This vendor can't be deleted since there are ${
                    Array.isArray(ret.error)
                      ? ret.error.join("/")
                      : "inventory/sales/expenses"
                  } with the vendor assigned to them. Remove the vendor from those transactions to delete the vendor.`
                );
            } catch (e) {
              toast.error(`Error: ${(e as Error).toString()}`);
            }
          }}
        />
      );
    },
  },
];

const useEstimatedIncomeTaxRowStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1, 0, "auto", 1),
    height: 40,
  },
}));

function EstimatedIncomeTaxRow() {
  const classes = useEstimatedIncomeTaxRowStyles();
  const incomeTaxRate = useSelector(userIncomeTaxRateSelector);
  const formik = useFormik({
    initialValues: {
      incomeTaxRate: Number(incomeTaxRate) * 100 || 0,
    },
    validate: (values) => {
      const errors: Record<string, string> = {};
      if (
        values.incomeTaxRate === undefined ||
        values.incomeTaxRate < 0 ||
        values.incomeTaxRate > 100
      ) {
        errors.incomeTaxRate = "Must be 0 ~ 100";
      }

      return errors;
    },
    onSubmit: async (values) => {
      if (!values.incomeTaxRate) return;
      if (
        values.incomeTaxRate === undefined ||
        values.incomeTaxRate < 0 ||
        values.incomeTaxRate > 100
      )
        return;
      try {
        await updateUserApi({
          income_tax_rate: values.incomeTaxRate / 100,
        });
        toast.success("Saved successfully!");
      } catch (e) {
        toast.error(`There was an error: ${(e as Error).toString()}`);
      }
    },
  });

  return (
    <Box
      display="flex"
      component="form"
      {...({ onSubmit: formik.handleSubmit } as any)}
    >
      <TextField
        fullWidth
        error={!!formik.errors.incomeTaxRate}
        helperText={formik.errors.incomeTaxRate}
        variant="outlined"
        margin="dense"
        label="Estimated income tax rate"
        type="number"
        value={formik.values.incomeTaxRate}
        disabled={formik.isSubmitting}
        name="incomeTaxRate"
        onChange={formik.handleChange}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />
      <LoadableButton
        className={classes.button}
        type="submit"
        color="primary"
        variant="contained"
        loading={formik.isSubmitting}
        disabled={Object.values(formik.errors).length > 0}
      >
        Save
      </LoadableButton>
    </Box>
  );
}

function renewalDateToString(date: Date | undefined): string {
  if (!date) return "--/--/----";
  return date.toLocaleDateString("en-US");
}

interface StripeSubscriptionResponse {
  loading?: boolean;
  error?: string;
  subscription?: {
    current_period_end: Date;
  };
}

function useStripeSubscription(): StripeSubscriptionResponse {
  const [subscription, setSubscription] = useState<StripeSubscriptionResponse>({
    loading: true,
  });
  useEffect(() => {
    let cancel = false;
    (async () => {
      try {
        const data = await getSubscription();
        if (cancel) return;
        if (data.error) {
          setSubscription({ error: data.error });
        } else {
          setSubscription({
            subscription: {
              ...data,
              current_period_end: new Date(data.current_period_end * 1000),
            },
          });
        }
      } catch (e) {
        setSubscription({
          error: (e as Error).message || (e as Error).toString(),
        });
      }
    })();
    return () => {
      cancel = true;
    };
  }, []);
  return subscription;
}

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: "bold",
  },
  separate: {
    marginTop: theme.spacing(2),
  },
}));

function ProfileSettingsDialogContent() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const platformsList = useSelector(userPlatformsSelector);
  const glAccounts = useSelector(getGeneralLedgerAccounts);
  const expenseRules = useSelector(expenseRulesSelector);
  const vendors = useSelector(getVendors);
  const platforms = useMemo(() => {
    if (!platformsList) return [];
    return platformsList.map((p) => ({
      id: p,
      title: p,
    }));
  }, [platformsList]);
  const [vendor, setVendor] = React.useState<string | undefined>(undefined);
  const [openSalePlatform, setOpenSalePlatform] = useState(false);
  const plan = useSelector(userPlanSelector) || "basic";
  const subscription = useStripeSubscription();

  const platformColumns = [
    {
      field: "title",
      headerName: "Platform Title",
      minWidth: 250,
      flex: 1,
      editable: true,
      sortable: true,
    },
    {
      field: "id",
      headerName: "Remove",
      width: 110,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <DeleteButton
            onClick={async () => {
              try {
                if (platformsList) {
                  await updateUserApi({
                    platforms: platformsList.filter((p) => p !== params.id),
                  });
                }
                toast.success("Platform deleted successfully!");
              } catch (e) {
                toast.error(`There was an error: ${(e as Error).toString()}`);
              }
            }}
          />
        );
      },
    },
  ];
  const generalLedgerColumns = [
    {
      field: "name",
      headerName: "Account Name",
      minWidth: 250,
      flex: 1,
      editable: true,
      sortable: true,
    },
    {
      field: "type",
      headerName: "Type",
      width: 190,
      editable: true,
      sortable: true,
      renderCell: (params) => {
        return (
          <Select
            value={params.value}
            onChange={(event) =>
              updateLedgerRow(params.id, event.target.value, "type")
            }
            label="Account Type"
          >
            <MenuItem key={"Other COGS"} value={"Other COGS"}>
              {"Other COGS"}
            </MenuItem>
            <MenuItem
              key={"Other Business Costs"}
              value={"Other Business Costs"}
            >
              {"Other Business Costs"}
            </MenuItem>
          </Select>
        );
      },
    },
    {
      field: "id",
      headerName: "Remove",
      width: 110,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <DeleteButton
            onClick={async () => await deleteLedgerAccount(params.id)}
          />
        );
      },
    },
  ];
  const expenseRulesColumns = [
    {
      field: "plaid_description",
      headerName: "Charge Description",
      width: 250,
      editable: false,
      sortable: true,
    },
    {
      field: "description",
      headerName: "Expense Description",
      minWidth: 250,
      flex: 1,
      editable: false,
      sortable: true,
    },
    {
      field: "account",
      headerName: "General Ledger Account",
      width: 250,
      editable: false,
      sortable: true,
    },
    {
      field: "vendor",
      headerName: "Vendor",
      width: 200,
      renderCell: vendorRenderCell,
    },
    {
      field: "delete",
      headerName: "Remove",
      width: 100,
      editable: false,
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      renderCell: (params) => (
        <DeleteButton
          onClick={async () =>
            await deleteTransactionRule(params.row.plaid_description)
          }
        />
      ),
    },
  ];

  const updateLedgerRow = (id, value, field) => {
    updateGeneralLedgerAccount(id, value, field).then((_) => {
      console.log("Ledger account updated.");
    });
  };

  const deleteLedgerAccount = (id) => {
    return deleteGeneralLedgerAccount(id).then((_) => {
      console.log("Ledger account deleted.");
    });
  };

  return (
    <>
      <VendorDialog id={vendor} onClose={() => setVendor(undefined)} />
      <SalesPlatformDialog
        open={openSalePlatform}
        onClose={() => setOpenSalePlatform(false)}
      />
      <DialogTitle>Profile settings</DialogTitle>
      <DialogContent>
        <DialogContentText className="relative" tabIndex={-1} component="div">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                variant="outlined"
                label="Subscription"
                value={capitalize(plan)}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Box position="relative">
                <TextField
                  disabled={!!subscription.loading}
                  error={!!subscription.error}
                  margin="dense"
                  variant="outlined"
                  label="Renewal Date"
                  value={renewalDateToString(
                    subscription.subscription?.current_period_end
                  )}
                  helperText={subscription.error}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
                {subscription.loading ? (
                  <Box
                    position="absolute"
                    display="flex"
                    top="50%"
                    left="50%"
                    mt={-1.5}
                    ml={-1.5}
                  >
                    <CircularProgress size={24} />
                  </Box>
                ) : null}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" width="100%">
                <ManageSubscriptionButton />
              </Box>
            </Grid>
            <Grid item xs={12} className={classes.separate}>
              <InventoryAccountingMethodSelect />
            </Grid>
            <Grid item xs={12} className={classes.separate}>
              <EstimatedIncomeTaxRow />
            </Grid>
            <Grid item xs={12} className={classes.separate}>
              <WalkThroughTopicsList />
            </Grid>
            <Grid item xs={12} className={classes.separate}>
              <Typography className={classes.bold}>Sales Platform</Typography>
            </Grid>
            <Grid item xs={12}>
              <XGrid
                rows={platforms}
                columns={platformColumns}
                headerHeight={35}
                rowHeight={35}
                style={{ height: 300, width: "100%" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={(_) => {
                  setOpenSalePlatform(true);
                }}
                color="primary"
                variant="contained"
                style={{
                  backgroundColor: "green",
                }}
              >
                Add
              </Button>
            </Grid>
            <AnalyticsGroupings className={classes.separate} />
            <Grid item xs={12} className={classes.separate}>
              <Typography className={classes.bold}>Expense Rules</Typography>
            </Grid>
            <Grid item xs={12}>
              <XGrid
                getRowId={(r) =>
                  `${r.plaid_description}-${r.description}-${r.account}`
                }
                rows={expenseRules}
                columns={expenseRulesColumns}
                headerHeight={35}
                rowHeight={35}
                style={{ height: 300, width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} className={classes.separate}>
              <Typography className={classes.bold}>
                General Ledger Accounts
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <XGrid
                rows={glAccounts}
                columns={generalLedgerColumns}
                headerHeight={35}
                rowHeight={35}
                style={{ height: 300, width: "100%" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={(_) => {
                  dispatch(setActiveDialog("add_gl_account"));
                }}
                color="primary"
                variant="contained"
                style={{
                  backgroundColor: "green",
                }}
              >
                Add
              </Button>
            </Grid>
            <Grid item xs={12} className={classes.separate}>
              <Typography className={classes.bold}>Vendors</Typography>
            </Grid>
            <Grid item xs={12}>
              <XGrid
                disableSelectionOnClick
                rows={vendors}
                columns={VendorColumns}
                headerHeight={35}
                rowHeight={35}
                style={{ height: 300, width: "100%" }}
                onCellDoubleClick={(params) => {
                  setVendor(`${params.id}`);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={() => {
                  setVendor("add-new-vendor");
                }}
                color="primary"
                variant="contained"
                style={{
                  backgroundColor: "green",
                }}
              >
                Add
              </Button>
            </Grid>
            <Grid item xs={12} className={classes.separate}>
              <RestartButton />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </>
  );
}

interface ProfileSettingsDialogProps {
  open: boolean;
  onClose: () => void;
}

function ProfileSettingsDialog({ open, onClose }: ProfileSettingsDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={"body"}
      fullWidth={true}
      maxWidth={"md"}
    >
      <ProfileSettingsDialogContent />
    </Dialog>
  );
}

export default ProfileSettingsDialog;
