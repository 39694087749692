import firestore from "src/apiService/firestore";

import { NumericOverview } from "src/interfaces/numericOverview.interface";
import { getUserId } from "src/config/storage";

export const requestNOUpdate = async () => {
  const userId = await getUserId();
  const db = firestore();
  return db.collection("Users").doc(userId).update({ updateNO: true });
};

export const clearNumericOverviews = async () => {
  let total = 0;
  const userId = await getUserId();
  const db = firestore();
  let querySnapshot = await db
    .collection("Numeric_Overview")
    .where("user", "==", userId)
    .get();
  if (querySnapshot && querySnapshot.docs.length > 0) {
    total += querySnapshot.docs.length;
    for (let i = 0; i < querySnapshot.docs.length; i++) {
      let doc = querySnapshot.docs[i];
      await doc.ref.delete();
    }
  }
  return total;
};

export async function manualRefreshNumericOverviews() {
  const userId = await getUserId();
  const db = firestore();
  await db.collection("Users").doc(userId).update({ updateNO: false });
  await db.collection("Users").doc(userId).update({ updateNO: true });
}

export async function updateNumericOverview(
  id: string,
  { id: _, user, ...payload }: Partial<NumericOverview>
) {
  const db = firestore();
  const doc = db.collection("Numeric_Overview").doc(id);
  await doc.update(payload);
  await requestNOUpdate();
}
