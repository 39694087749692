import React, { useEffect, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import LoadableButton from "src/components/LoadableButton";

import { makeStyles } from "@material-ui/core/styles";

import { checkCouponApi, subscriptionApi } from "../../apiService";
import { clearSubscriptionResponse, logOut } from "../../store/system/actions";
import { paymentMethodSelector, selectUser } from "../../store/system/selector";
import { colors } from "../../theme";

import { toast } from "react-toastify";

const COUPON_ERROR = "Coupon code not found.";

const useStyles = makeStyles((theme) => ({
  nameField: {
    width: "100%",
    margin: 0,
  },
  couponField: {
    width: "100%",
    marginTop: 0,
  },
  applyButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(-1),
  },
  applyButtonWrapperWithError: {
    marginTop: theme.spacing(-4),
  },
  flex: {
    display: "flex",
  },
  qty: {
    marginTop: theme.spacing(1.5),
  },
  cardElementContainer: {
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.87)",
    },
    "&.StripeElement--focus": {
      borderColor: "rgb(68, 112, 147)",
      borderWidth: 2,
      padding: "9.5px 13px",
    },
    "&.StripeElement--invalid": {
      borderColor: "#f44336",
    },
    borderRadius: 8,
    width: "100%",
    borderColor: "rgba(0, 0, 0, 0.23)",
    borderWidth: "1px",
    borderStyle: "solid",
    padding: "10.5px 14px",
  },
}));

const CardElementStyle = {
  base: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "16px",
    letterSpacing: "0.15008px",
  },
};

enum PLANS {
  BASIC = "basic",
  PREMIUM = "premium",
  ULTIMATE = "ultimate",
}

const plans = [PLANS.BASIC, PLANS.PREMIUM, PLANS.ULTIMATE];
const planPrices = {
  [PLANS.BASIC]: 9.99,
  [PLANS.PREMIUM]: 14.99,
  [PLANS.ULTIMATE]: 19.99,
};
const EBayIntegrationPrice = 5;

function getPrice(
  plan: PLANS.BASIC | PLANS.PREMIUM | PLANS.ULTIMATE,
  ebayIntegrationsQuantity: number
): number {
  if (plan !== PLANS.ULTIMATE) return planPrices[plan];
  return (
    planPrices[plan] +
    (ebayIntegrationsQuantity > 1
      ? (ebayIntegrationsQuantity - 1) * EBayIntegrationPrice
      : 0)
  );
}

const EnterPaymentMethodDialog = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector(selectUser);
  const requirePaymentMethod = useSelector(paymentMethodSelector);
  const stripe = useStripe();
  const elements = useElements();
  const [selectedPlan, setPlan] = useState(PLANS.BASIC);
  const [coupon, setCoupon] = useState("");
  const [name, setName] = useState<{
    first: string;
    errorFirst?: string;
    last: string;
    errorLast?: string;
  }>({
    first: "",
    last: "",
  });
  const [checkingCoupon, setCheckingCoupon] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [flatDiscount, setFlatDiscount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [couponError, setCouponError] = useState("");
  const [mustSubscribe, setMustSubscribe] = useState(requirePaymentMethod);
  useEffect(() => {
    if (!loading || !user?.subscriptionResponse) {
      return;
    }
    if (user?.subscriptionResponse?.success) {
      toast.success("Successfully updated!");
      setMustSubscribe(false);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(
        user?.subscriptionResponse?.message || "Something went wrong!"
      );
    }
  }, [loading, user?.subscriptionResponse]);

  const [ebayIntegrationsQuantity, setEbayIntegrationsQuantity] = useState(1);
  useEffect(() => {
    if (selectedPlan === PLANS.ULTIMATE) setEbayIntegrationsQuantity(1);
  }, [selectedPlan]);

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!name.first || !name.last) {
      setName((n) => {
        const name = { ...n };
        if (!n.first) name.errorFirst = "Required";
        if (!n.last) name.errorLast = "Required";
        return name;
      });
      return;
    }
    dispatch(clearSubscriptionResponse());
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      card: cardElement,
      type: "card",
    });

    if (error || !paymentMethod) {
      toast.error(error && error.message);
      console.error(error);
      setLoading(false);
    } else {
      subscriptionApi({
        pm_id: paymentMethod.id,
        plan: selectedPlan,
        coupon,
        firstName: name.first,
        lastName: name.last,
        ebayIntegrationsQuantity:
          selectedPlan !== PLANS.ULTIMATE ? 1 : ebayIntegrationsQuantity,
      }).catch((error) => {
        setLoading(false);
        const message = error?.response?.data?.message || error?.message;
        if (message === COUPON_ERROR) {
          setCouponError(message);
        } else {
          toast.error(message);
        }
      });
    }
  };

  const checkCoupon = async (code) => {
    if (!code) return;

    setCheckingCoupon(true);
    setDiscount(0);
    setCouponError("");

    try {
      const result = await checkCouponApi(code);
      setCheckingCoupon(false);
      const percentOff = result?.data?.coupon?.percent_off;
      const amountOff = result?.data?.coupon?.amount_off;
      if (percentOff) {
        setDiscount(percentOff);
        setFlatDiscount(0);
      } else if (amountOff) {
        setFlatDiscount(amountOff);
        setDiscount(0);
      } else {
        setCouponError("Invalid coupon");
        setDiscount(0);
      }
    } catch (e) {
      console.error(e);
      setCouponError("Error checking coupon");
    }
  };

  const handleLogout = () => dispatch(logOut());

  let price = getPrice(selectedPlan, ebayIntegrationsQuantity);
  if (discount) {
    price = Math.round(planPrices[selectedPlan] * (100 - discount)) / 100;
  } else if (flatDiscount) {
    price = Math.max(price - flatDiscount / 100, 0);
  }
  const displayPrice = price.toFixed(2);

  let couponIcon: JSX.Element | undefined = undefined;
  if (discount || flatDiscount) {
    couponIcon = (
      <InputAdornment position="end">
        <CheckCircleIcon style={{ color: colors.lightGreenColor }} />
      </InputAdornment>
    );
  } else if (checkingCoupon) {
    couponIcon = (
      <InputAdornment position="end">
        <CircularProgress size={18} />
      </InputAdornment>
    );
  }

  return (
    <Dialog
      open={requirePaymentMethod || mustSubscribe}
      scroll={"body"}
      aria-modal
      fullWidth
      maxWidth={"xs"}
    >
      <DialogTitle>Confirm Subscription</DialogTitle>
      <DialogContent>
        <DialogContentText className="relative" tabIndex={-1} component="div">
          <form noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  className={classes.nameField}
                  variant="outlined"
                  label="First Name"
                  margin="dense"
                  value={name.first}
                  onChange={(e) => {
                    const first = e.target.value;
                    setName(({ errorFirst, ...n }) => {
                      if (first) return { ...n, first };
                      else return { ...n, errorFirst, first: "" };
                    });
                  }}
                  error={!!name.errorFirst}
                  helperText={name.errorFirst || undefined}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className={classes.nameField}
                  variant="outlined"
                  label="Last Name"
                  margin="dense"
                  value={name.last}
                  onChange={(e) => {
                    const last = e.target.value;
                    setName(({ errorLast, ...n }) => {
                      if (last) return { ...n, last };
                      else return { ...n, errorLast, last: "" };
                    });
                  }}
                  error={!!name.errorLast}
                  helperText={name.errorLast || undefined}
                />
              </Grid>
              <Grid item xs={12}>
                <CardElement
                  className={classes.cardElementContainer}
                  options={{
                    style: CardElementStyle,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl variant="outlined" className="w-full">
                  <TextField
                    className={classes.couponField}
                    variant="outlined"
                    label="Coupon code"
                    error={!!couponError}
                    helperText={couponError || undefined}
                    value={coupon}
                    onChange={(e) => {
                      setCoupon(e.target.value);
                      if (!e.target.value) setCouponError("");
                    }}
                    onKeyDown={(ev) => {
                      if (ev.key === "Enter" || ev.keyCode === 13) {
                        ev.preventDefault();
                        checkCoupon(coupon);
                      }
                    }}
                    margin="dense"
                    disabled={loading}
                    InputProps={{ endAdornment: couponIcon }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                className={clsx(
                  classes.applyButtonWrapper,
                  couponError && classes.applyButtonWrapperWithError
                )}
              >
                <LoadableButton
                  variant="contained"
                  loading={checkingCoupon}
                  disabled={loading}
                  onClick={() => checkCoupon(coupon)}
                >
                  Apply Coupon
                </LoadableButton>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl variant="outlined" className="w-full mt-2">
                  <InputLabel id="select-plan">Plan</InputLabel>
                  <Select
                    labelId="select-plan"
                    id="select-plan-input"
                    value={selectedPlan}
                    onChange={(e) => setPlan(e.target.value as PLANS)}
                    label="Plan"
                    margin="dense"
                    disabled={loading}
                  >
                    {plans.map((plan) => {
                      return (
                        <MenuItem key={plan} value={plan}>
                          {capitalizeFirstLetter(plan)} (${planPrices[plan]}
                          /month)
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {selectedPlan === PLANS.ULTIMATE && (
                  <TextField
                    className={classes.qty}
                    variant="outlined"
                    label="Number of eBay Stores"
                    margin="dense"
                    type="number"
                    fullWidth
                    value={ebayIntegrationsQuantity}
                    onChange={(ev) => {
                      const value = parseInt(ev.target.value, 10) || 1;
                      setEbayIntegrationsQuantity(value <= 0 ? 1 : value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="The Ultimate plan comes with one eBay store by default. You can increase this number to integrate additional eBay stores for an extra $5/month/store.">
                            <InfoOutlinedIcon color="action" />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </form>
          <Typography className="mt-2">
            Amount to be charged today: ${displayPrice}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="px-7 py-5">
        <LoadableButton
          onClick={handleLogout}
          color="secondary"
          variant="contained"
          loading={loading}
        >
          Logout
        </LoadableButton>
        <LoadableButton
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          loading={loading}
        >
          Submit
        </LoadableButton>
      </DialogActions>
    </Dialog>
  );
};

export default EnterPaymentMethodDialog;
